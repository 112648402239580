import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UpdateCommunityCommand } from '../../../models/identity/update-community-command.model';
import { regexPatterns } from './../../../constants/regex-patterns';
import { ToastrHelper } from './../../../helpers/toastr.helper';
import { Community, COMMUNITY_NAME_MAX_LENGTH } from './../../../models/identity/community.model';
import { CreateCommunityGroupCommand } from './../../../models/identity/create-community-group-command.model';
import { AccountsService } from './../../../services/identity/accounts.service';
import { CommunityGroupService } from './../../../services/identity/community-group.service';
import { CommunityService } from './../../../services/identity/community.service';

@Component({
    selector: 'app-community-settings',
    templateUrl: './community-settings.component.html',
    styleUrls: ['./community-settings.component.scss'],
})
export class CommunitySettingsComponent implements OnInit {
    public COMMUNITY_NAME_MAX_LENGTH = COMMUNITY_NAME_MAX_LENGTH;
    public communities: Community[];
    public community: Community;
    public communityForm: FormGroup;
    public communityGroupForm: FormGroup;
    public communityGroupIdSelected: string;
    public displayCommunityGroupDiv = true;
    public filteredCommunities: Community[];
    public isAdmin: boolean;
    public isCreateCommunityGroupModalVisible: boolean;
    public isCreateMode: boolean;
    public isDeleteCommunityGroupModalVisible: boolean;
    public isUpdateCommunityGroupModalVisible: boolean;
    public originalParentIds: string[];
    public selectedMetaCommunities: Community[];
    public submitButtonLabel: string;

    constructor(
        protected readonly accountsService: AccountsService,
        private readonly fb: FormBuilder,
        private communityService: CommunityService,
        private communityGroupService: CommunityGroupService,
        private readonly toastrHelper: ToastrHelper,
        public readonly router: Router,
        private readonly translate: TranslateService,
    ) {}

    public closeModal() {
        this.isCreateCommunityGroupModalVisible = false;
        this.isUpdateCommunityGroupModalVisible = false;
        this.isDeleteCommunityGroupModalVisible = false;
    }

    public initForm() {
        this.community = this.isCreateMode ? new Community() : this.accountsService.currentCommunity;
        this.communityForm = this.fb.group({
            displayName: this.fb.control(this.community?.displayName, [Validators.required, Validators.maxLength(COMMUNITY_NAME_MAX_LENGTH)]),
            name: this.fb.control(null),
            parentsIds: this.fb.control(null),
            id: this.fb.control(null, [Validators.pattern(regexPatterns.guid), Validators.maxLength(36)]),
            canCreateClub: this.fb.control(this.community.canCreateClub),
            canCreateProject: this.fb.control(this.community.canCreateProject),
            canCreateEvent: this.fb.control(this.community.canCreateEvent),
            hasAccessToDashboard: this.fb.control(this.community.hasAccessToDashboard),
            hasActivateIntelligentNewsLetter: this.fb.control(this.community.hasActivateIntelligentNewsLetter),
        });
    }

    public loadMetaCommunities() {
        this.selectedMetaCommunities = [];

        this.filteredCommunities = Object.assign([], window.history.state.communities);

        if (this.filteredCommunities == null || this.filteredCommunities.length == 0) {
            this.filteredCommunities = JSON.parse(localStorage.getItem('communities'));
        } else {
            localStorage.setItem('communities', JSON.stringify(this.filteredCommunities));
        }

        if (!this.isCreateMode) {
            this.filteredCommunities.splice(
                this.filteredCommunities.findIndex((community) => community.id == this.community.id),
                1,
            );
        }

        if (this.community.parentsIds != null) {
            this.community.parentsIds.forEach((id) => {
                this.selectedMetaCommunities.push(this.filteredCommunities[this.filteredCommunities.findIndex((community) => community.id == id)]);
                this.originalParentIds = this.selectedMetaCommunities.map((community) => community.id);
            });
        }
    }

    public ngOnInit(): void {
        this.isCreateMode = this.router.url.includes('/create');
        this.submitButtonLabel = this.isCreateMode
            ? this.translate.instant('components.button.create')
            : this.translate.instant('components.button.save');

        this.communities = Object.assign([], window.history.state.communities);

        this.isAdmin = this.accountsService.isAdmin();
        this.initForm();
        this.loadMetaCommunities();
        this.accountsService.currentCommunityChange.subscribe(() => {
            this.initForm();
            this.communityGroupIdSelected = '';
            this.resetCommunityGroupVirtualScroller();
            this.loadMetaCommunities();
        });
    }

    public onCreateOrUpdateCommunityGroup() {
        if (this.isCreateCommunityGroupModalVisible) {
            const createCommand: CreateCommunityGroupCommand = this.communityGroupForm.getRawValue();
            createCommand.name = createCommand.displayName;
            this.communityGroupService.createCommunityGroup(createCommand).subscribe((result) => {
                this.community.communitiesGroups ? null : (this.community.communitiesGroups = []);
                this.community.communitiesGroups.push(result);
                this.accountsService.changeCurrentCommunity(this.community);
                this.resetCommunityGroupVirtualScroller();
                this.toastrHelper.showSuccess(this.translate.instant('toastMessages.communityGroupCreated'));
            });
        } else if (this.isUpdateCommunityGroupModalVisible) {
            this.communityGroupService
                .updateCommunityGroup(this.communityGroupIdSelected, this.communityGroupForm.getRawValue())
                .subscribe((result) => {
                    this.community.communitiesGroups.find((group) => group.id == result.id).displayName = result.displayName;
                    this.accountsService.changeCurrentCommunity(this.community);
                    this.resetCommunityGroupVirtualScroller();
                    this.toastrHelper.showSuccess(this.translate.instant('toastMessages.communityGroupUpdated'));
                });
        }
        this.closeModal();
    }

    public onDeleteCommunityGroup() {
        this.communityGroupService.deleteCommunityGroup(this.communityGroupIdSelected).subscribe(() => {
            this.community.communitiesGroups = this.community.communitiesGroups.filter((group) => group.id != this.communityGroupIdSelected);
            this.accountsService.changeCurrentCommunity(this.community);
            this.resetCommunityGroupVirtualScroller();
            this.toastrHelper.showSuccess(this.translate.instant('toastMessages.communityGroupDeleted'));
        });
        this.closeModal();
    }

    public onMetaCommunitySelect(event: any) {
        const selectedMetaCommunity = event.itemValue as Community;

        if (this.community.parentsIds != null) {
            if (this.community.parentsIds.indexOf(selectedMetaCommunity.id) > -1) {
                this.community.parentsIds.splice(this.community.parentsIds.indexOf(selectedMetaCommunity.id), 1);
                return;
            }
            this.community.parentsIds.push(selectedMetaCommunity.id);
        } else {
            this.community.parentsIds = [];
            this.community.parentsIds.push(selectedMetaCommunity.id);
        }
    }

    public onSubmit() {
        if (this.isCreateMode) {
            this.communityForm.controls.name.setValue(this.communityForm.controls.displayName.value);
            this.communityForm.controls.parentsIds.setValue(this.community.parentsIds);
            this.communityService.createCommunity(this.communityForm.getRawValue()).subscribe(
                (community: Community) => {
                    this.communities.push(community);
                    this.onSubmitCompletedSuccessfully(community);
                    this.router.navigate(['community', 'settings'], { state: { communities: this.communities } });
                },
                (error) => {
                    this.community.parentsIds = this.originalParentIds;
                    this.loadMetaCommunities();
                },
            );
        } else {
            const command = new UpdateCommunityCommand(this.accountsService.currentCommunity);
            command.displayName = this.communityForm.controls.displayName.value;
            command.parentsIds = this.community.parentsIds;
            command.canCreateClub = this.communityForm.controls.canCreateClub.value;
            command.canCreateProject = this.communityForm.controls.canCreateProject.value;
            command.canCreateEvent = this.communityForm.controls.canCreateEvent.value;
            command.hasAccessToDashboard = this.communityForm.controls.hasAccessToDashboard.value;
            command.hasActivateIntelligentNewsLetter = this.communityForm.controls.hasActivateIntelligentNewsLetter.value;
            this.communityService.updateCommunity(this.accountsService.currentCommunity.id, command).subscribe(
                (community: Community) => {
                    this.community.parentsIds = community.parentsIds;
                    this.onSubmitCompletedSuccessfully(community);
                },
                (error) => {
                    this.community.parentsIds = this.originalParentIds;
                    this.loadMetaCommunities();
                },
            );
        }
    }

    public onSubmitCompletedSuccessfully(community: Community) {
        this.accountsService.changeCurrentCommunity(community);
        this.accountsService.refreshCurrentUser();
        this.accountsService.saveCurrentUser();
        this.toastrHelper.showSuccess(
            this.isCreateMode ? this.translate.instant('toastMessages.communityCreated') : this.translate.instant('toastMessages.communityUpdated'),
        );
    }

    public openCreateOrUpdateCommunityGroupModal(isCreateMode: boolean) {
        if (isCreateMode) {
            this.communityGroupForm = this.fb.group({
                displayName: this.fb.control('', [Validators.required, Validators.maxLength(COMMUNITY_NAME_MAX_LENGTH)]),
                communityId: this.fb.control(this.community.id, [Validators.required]),
            });
            this.isCreateCommunityGroupModalVisible = true;
        } else {
            this.communityGroupForm = this.fb.group({
                displayName: this.fb.control(
                    this.community?.communitiesGroups.find((group) => group.id == this.communityGroupIdSelected).displayName,
                    [Validators.required, Validators.maxLength(COMMUNITY_NAME_MAX_LENGTH)],
                ),
                communityId: this.fb.control(this.community.id, [Validators.required]),
            });
            this.isUpdateCommunityGroupModalVisible = true;
        }
    }

    public openDeleteCommunityGroupModal() {
        this.isDeleteCommunityGroupModalVisible = true;
    }

    //Use to force the virtualScroller to update its content
    public resetCommunityGroupVirtualScroller() {
        this.displayCommunityGroupDiv = false;
        setTimeout(() => {
            this.displayCommunityGroupDiv = true;
        }, 10);
    }
}
