<div *ngIf="!isStepLoaded" class="spinner-border text-info" role="status">
    <span class="sr-only">{{ 'projects.formStep1.loading' | translate }}</span>
</div>
<form *ngIf="stepForm" [formGroup]="stepForm" [hidden]="!isStepLoaded" (ngSubmit)="onSubmit()" class="col-12">
    <div class="card box-shadow overflow-visible">
        <div class="card-body">
            <app-step-header [user]="currentProfile"></app-step-header>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="fw-b primary-1" for="event-name"
                            >{{ 'events.formStep1.name.label' | translate }} *</label
                        >
                        <input
                            type="text"
                            formControlName="name"
                            id="event-name"
                            [placeholder]="'events.formStep1.name.placeholder' | translate"
                            class="form-control"
                        />
                        <div *ngIf="showControlError(stepForm.controls.name)" class="alert alert-danger">
                            <div *ngIf="stepForm.controls.name.errors.required">
                                {{ 'events.formStep1.name.required' | translate }}
                            </div>
                            <div *ngIf="stepForm.controls.name.errors.maxlength">
                                {{
                                    'events.formStep1.name.length'
                                        | translate : { maxLength: EVENT_TITLE_MAX_LENGTH, currLength: stepForm.controls.name.value.length }
                                }}
                            </div>
                            <div *ngIf="stepForm.controls.name.errors.unique">
                                {{ 'events.formStep1.name.unique' | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <label class="fw-b primary-1" for="type">{{ 'events.pages.details.type' | translate }} *</label>
                    <ng-select
                        [hideSelected]="true"
                        placeholder="{{ 'events.pages.details.selectType' | translate }}"
                        labelForId="type"
                        [searchable]="false"
                        formControlName="type"
                    >
                        <ng-option *ngFor="let eventType of eventTypes" [value]="eventType.value">
                            {{ eventType.label | wtranslate : 'eventTypes' }}
                        </ng-option>
                    </ng-select>
                    <div *ngIf="showControlError(stepForm.controls.type)" class="alert alert-danger mt-1">
                        <div *ngIf="stepForm.controls.type.errors.required">
                            {{ 'events.pages.details.toast.needType' | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <p class="d-block">
                                <span class="d-block float-left primary-1 fw-b mb-2">Description *</span>
                                <span class="d-block float-right">
                                    <span
                                        [class.text-danger]="stepForm.controls.description.errors?.maxlength"
                                        [class.font-weight-bold]="stepForm.controls.description.errors?.maxlength"
                                        >{{ descriptionCounter }}</span
                                    >/{{ EVENT_DESCRIPTION_MAX_LENGTH }}
                                </span>
                            </p>
                        </div>

                        <div class="col-12">
                            <div class="align-items-center">
                                <quill-editor
                                    formControlName="description"
                                    [placeholder]="'events.formStep1.description.placeholder' | translate"
                                    theme="snow"
                                    required="true"
                                    [modules]="quillEditorHelper.modules"
                                    [formats]="quillEditorHelper.formats"
                                    (onContentChanged)="onDescriptionChanged($event.editor)"
                                    class="notranslate"
                                >
                                </quill-editor>

                                <div *ngIf="showControlError(stepForm.controls.description)" class="alert alert-danger">
                                    <div *ngIf="stepForm.controls.description.errors.required">
                                        {{ 'events.formStep1.description.required' | translate }}
                                    </div>
                                    <div *ngIf="stepForm.controls.description.errors.maxlength">
                                        {{
                                            'events.formStep1.description.length'
                                                | translate : { maxLength: EVENT_DESCRIPTION_MAX_LENGTH }
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column">
                    <span class="d-block primary-1 fw-b mb-2"
                        >{{ 'components.imageCropModal.addPicture' | translate }} *</span
                    >
                    <div
                        *ngIf="stepForm.controls.picturePreviewUrl.value; else noImage"
                        (click)="imageCropModal.showModal()"
                        class="container-uploaded"
                    >
                        <img
                            [src]="stepForm.controls.picturePreviewUrl.value"
                            alt="{{ 'events.details.toast.imageLoaded' | translate }}"
                        />
                    </div>

                    <ng-template #noImage>
                        <div
                            (click)="imageCropModal.showModal()"
                            class="file-placeholder rounded flex-grow-1 d-flex flex-column justify-content-center align-items-center py-4 py-md-0"
                        >
                            <i class="pi pi-plus-circle mb-3"></i>

                            <span>{{ 'components.imageCropModal.acceptedFormats' | translate }} JPEG, PNG, WEBP</span>
                            <span>{{ 'components.imageCropModal.maxSize' | translate }} 2 Mo</span>
                        </div>
                    </ng-template>

                    <div *ngIf="showControlError(stepForm.controls.picturePreviewUrl)" class="alert alert-danger">
                        <div *ngIf="stepForm.controls.picturePreviewUrl.errors.required">
                            {{ 'events.formStep1.picture.required' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<image-crop-modal
    *ngIf="stepForm"
    [cropperParams]="cropperParams"
    [imageService]="imageService"
    (eventSavePicture)="savePicture()"
    [picture]="stepForm.controls.picturePreviewUrl.value"
></image-crop-modal>
