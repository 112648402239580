import { Injectable } from '@angular/core';
import { ColumnMatchingData } from '../../../community/components/import-member-column-matching/column-matching-information';
import {
    emailProperty,
    lastNameProperty,
    organizationNameProperty,
    planProperty,
    surnameProperty,
    zipCodeProperty,
} from '../../../community/components/import-member-column-matching/import-member-column-names.constants';
import { CsvHelper } from '../../../helpers/csv.helper';
import { FileHelper } from '../../../helpers/file.helper';
import { PrimengDropdownItem } from '../../../shared/interfaces/primeng-dropdown-item.interface';
import * as stringSimilarity from 'string-similarity';
@Injectable({
    providedIn: 'root',
})
export class ImportMemberColumnMatchingService {
    async generateColumnMatchingDataFromFile(file: File): Promise<ColumnMatchingData> {
        if (!file) {
            return null;
        }

        const results = await CsvHelper.parse(file, await FileHelper.findTextFileEncoding(file));

        if (
            Object.values(results.data.slice(-1)[0]).every(
                (property) => property === null || property === '' || property === undefined,
            )
        ) {
            results.data.pop();
        }

        const data = new ColumnMatchingData({
            wudoProperties: [
                lastNameProperty,
                surnameProperty,
                emailProperty,
                planProperty,
                organizationNameProperty,
                // zipCodeProperty,
            ],
            csvRecords: results.data,
        });

        const availableWudoProperties = data.wudoProperties;

        data.csvColumnInformation = [];
        for (const csvColumnTitle of results.meta?.fields) {
            let selectedWudoProperty: PrimengDropdownItem = null;

            const bestMatch = stringSimilarity.findBestMatch(csvColumnTitle, availableWudoProperties);
            if (bestMatch.bestMatch.rating > 0.4) {
                selectedWudoProperty = { label: bestMatch.bestMatch.target, code: bestMatch.bestMatch.target };
                availableWudoProperties.slice(bestMatch.bestMatchIndex, 1);
            }

            data.csvColumnInformation.push({
                valid: false,
                title: csvColumnTitle,
                sample: results.data.slice(0, 3).map((item) => item[csvColumnTitle]),
                selectedWudoProperty,
                availableWudoProperties: [],
                skipped: false,
            });
        }

        return data;
    }
}
