import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { ImportMemberColumnMatchingService } from '../../../community/components/import-member-column-matching/import-member-column-matching.service';
import { ColumnMatchingData } from '../../../community/components/import-member-column-matching/column-matching-information';
import { User } from '../../../models/identity/user.model';
import { PrimengDropdownItem } from '../../../shared/interfaces/primeng-dropdown-item.interface';
import {
    emailProperty,
    lastNameProperty,
    organizationNameProperty,
    planProperty,
    surnameProperty,
    zipCodeProperty,
} from '../../../community/components/import-member-column-matching/import-member-column-names.constants';
import { ToastrHelper } from '../../../helpers/toastr.helper';

@Component({
    selector: 'app-import-member-column-matching',
    templateUrl: './import-member-column-matching.component.html',
    styleUrls: ['./import-member-column-matching.component.scss'],
})
export class ImportMemberColumnMatchingComponent implements OnChanges {
    @Output() usersChanged: EventEmitter<User[]> = new EventEmitter();

    @Input() file: File;

    @Output() userChanges: EventEmitter<User[]> = new EventEmitter();

    columnMatchingData: ColumnMatchingData;

    constructor(
        private readonly componentService: ImportMemberColumnMatchingService,
        private readonly toasterHelper: ToastrHelper,
    ) {}

    async ngOnChanges() {
        this.columnMatchingData = await this.componentService.generateColumnMatchingDataFromFile(this.file);
        this.populateAvailableDropdownProperties();
        this.propagateUserChanges();
    }

    handleMatchingDropdownChange() {
        this.populateAvailableDropdownProperties();
        this.propagateUserChanges();
    }

    skipColumn(title: string) {
        const item = this.columnMatchingData.csvColumnInformation.find((info) => info.title == title);
        item.skipped = true;
        item.selectedWudoProperty = null;
        this.populateAvailableDropdownProperties();
        this.propagateUserChanges();
    }

    undoSkipColumn(title: string) {
        const item = this.columnMatchingData.csvColumnInformation.find((info) => info.title == title);
        item.skipped = false;
        this.populateAvailableDropdownProperties();
        this.propagateUserChanges();
    }

    private populateAvailableDropdownProperties(): void {
        const selectedProperties: string[] = [];
        for (const item of this.columnMatchingData.csvColumnInformation) {
            if (item.selectedWudoProperty) {
                selectedProperties.push(item.selectedWudoProperty.code);
            }
            item.valid = !!item.selectedWudoProperty;
        }

        const availableProperties = this.columnMatchingData.wudoProperties.filter(
            (property) => !selectedProperties.includes(property),
        );

        let availableWudoProperties: PrimengDropdownItem[];
        for (const item of this.columnMatchingData.csvColumnInformation) {
            availableWudoProperties = availableProperties.map((property) => ({ label: property, code: property }));
            if (item.selectedWudoProperty) {
                availableWudoProperties.push(item.selectedWudoProperty);
                item.valid = true;
            }
            item.availableWudoProperties = availableWudoProperties;
        }
    }

    propagateUserChanges() {
        let error = false;

        const selectedProperties = this.columnMatchingData.csvColumnInformation.map(
            (item) => item?.selectedWudoProperty?.code,
        );

        if (!selectedProperties.some((property) => property == emailProperty)) {
            this.toasterHelper.showError(`La propriété «${emailProperty}» est requise`);
            error = true;
        }

        if (!selectedProperties.some((property) => property == surnameProperty)) {
            this.toasterHelper.showError(`La propriété «${surnameProperty}» est requise`);
            error = true;
        }

        if (!selectedProperties.some((property) => property == lastNameProperty)) {
            this.toasterHelper.showError(`La propriété «${lastNameProperty}» est requise`);
            error = true;
        }

        if (this.columnMatchingData.csvColumnInformation.some((record) => !record.valid) || error) {
            this.userChanges.emit(null);
            return;
        }

        const firstNameColumnName = this.columnMatchingData.csvColumnInformation.find(
            (record) => record.selectedWudoProperty.code == surnameProperty,
        )?.title;

        const lastNameColumnName = this.columnMatchingData.csvColumnInformation.find(
            (record) => record.selectedWudoProperty.code == lastNameProperty,
        )?.title;

        const emailColumnName = this.columnMatchingData.csvColumnInformation.find(
            (record) => record.selectedWudoProperty.code == emailProperty,
        )?.title;

        const planColumnName = this.columnMatchingData.csvColumnInformation.find(
            (record) => record.selectedWudoProperty.code == planProperty,
        )?.title;

        const organizationNameColumnName = this.columnMatchingData.csvColumnInformation.find(
            (record) => record.selectedWudoProperty.code == organizationNameProperty,
        )?.title;

        const organizationZipCodeColumnName = this.columnMatchingData.csvColumnInformation.find(
            (record) => record.selectedWudoProperty.code == zipCodeProperty,
        )?.title;

        this.userChanges.emit(
            this.columnMatchingData.csvRecords.map(
                (record) =>
                    new User({
                        firstName: firstNameColumnName ? record[firstNameColumnName] : null,
                        lastName: lastNameColumnName ? record[lastNameColumnName] : null,
                        email: emailColumnName ? record[emailColumnName] : null,
                    }),
            ),
        );
    }
}
