interface String {
    toCaseInsensitive(): string;
    normalizeString(): string;
}

String.prototype.toCaseInsensitive = function (): string {
    return this?.toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
};

String.prototype.normalizeString = function (): string {
    return this?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
