import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BasicUser } from '../../models/identity/basic-user.model';
import { CommunityGroup } from '../../models/identity/community-group.model';
import { CreateOrGetOrganizationCommand } from '../../models/identity/create-or-get-organization-command.model';
import { CreateOrganizationCommand } from '../../models/identity/create-organization-command.model';
import { OrganizationBase } from '../../models/identity/organization-base.model';
import { OrganizationFilters } from '../../models/identity/organization-filters.model';
import { OrganizationType } from '../../models/identity/organization-type.model';
import { UpdateOrganizationCommand } from '../../models/identity/update-organization-command.model';
import { OrganizationDirectoryFilterDto } from '../../models/organizations/organization-id-name-dto.model';
import { Community } from './../../models/identity/community.model';
import { Organization, ResponseFromBatchInsertOrganization } from './../../models/identity/organization.model';
import { UpdateManyOrganizationsPropertyCommand } from './../../models/identity/update-many-organizations-property-command.model';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    private organizationApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.organizationsApi.baseUrl}`;
    private filteredOrganizationApiUrl = `${this.organizationApiUrl}/${environment.microservices.identity.organizationsApi.filters}`;
    private organizationBatchInsertApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.organizationsBatchInsertApi.baseUrl}`;
    private organizationTypeApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.organizationTypesApi.baseUrl}`;
    private organizationsByIdsApiUrl = `${this.organizationApiUrl}/${environment.microservices.identity.organizationsApi.getOrganizationsByIds}`;
    private updateOrganizationMinimalInfosApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.organizationsApi.baseUrl}/${environment.microservices.identity.organizationsApi.editMinimalInfos}`;
    private updateOrganizationsPropertyApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.organizationsApi.baseUrl}/${environment.microservices.identity.organizationsApi.updateManyOrganizationsProperty}`;

    public isCreate = false;

    constructor(private http: HttpClient) {}

    public batchInsertOrganization(organization: Organization[], communities: Community[]): Observable<ResponseFromBatchInsertOrganization> {
        const body: string = this.createBatchInsertOrganizationBody(organization, communities);

        return this.http.post<ResponseFromBatchInsertOrganization>(this.organizationBatchInsertApiUrl, JSON.parse(body));
    }

    public createOrGetOrganization(command: CreateOrGetOrganizationCommand): Observable<Organization> {
        return this.http.post<Organization>(this.organizationApiUrl + '/CreateOrGetOrganization', command);
    }

    public createOrganization(command: CreateOrganizationCommand): Observable<Organization> {
        return this.http.post<Organization>(`${this.organizationApiUrl}`, this.getFormData(command));
    }

    // Get all employees (partial users informations) in organization
    public getEmployees(organisationId: string): Observable<BasicUser[]> {
        return this.http.get<BasicUser[]>(`${this.organizationApiUrl}/${organisationId}/Employees`);
    }

    public getFilteredOrganizations(filters: OrganizationFilters): Observable<Organization[]> {
        return this.http.post<Organization[]>(`${this.filteredOrganizationApiUrl}`, filters);
    }

    public getFormData(command: CreateOrganizationCommand): FormData {
        const formData = new FormData();
        console.log('Command before FormData:', JSON.parse(JSON.stringify(command)));

        formData.append('organizationName', command.organizationName);
        formData.append('active', command.active.toString());
        formData.append('organizationTypeId', command.organizationTypeId);
        formData.append('businessAreaId', command.businessAreaId);
        formData.append('geographicalAreaId', command.geographicalAreaId);
        formData.append('city', command.city);
        formData.append('postalCode', command.postalCode);

        if (command.address1) {
            formData.append('address1', command.address1);
        }

        if (command.address2) {
            formData.append('address2', command.address2);
        }

        if (command.phoneNumber) {
            formData.append('phoneNumber', command.phoneNumber);
        }

        if (command.email) {
            formData.append('email', command.email);
        }

        if (command.webSite) {
            formData.append('webSite', command.webSite);
        }

        if (command.linkedIn) {
            formData.append('linkedIn', command.linkedIn);
        }

        if (command.description) {
            formData.append('description', command.description);
        }

        if (command.picture) {
            formData.append('picture', command.picture);
        }

        if (command.location) {
            formData.append('location', JSON.stringify(command.location));
        }

        if (command.skills && command.skills.length > 0) {
            command.skills.forEach((skill, index) => {
                formData.append(`skills[${index}][id]`, skill.id);
                formData.append(`skills[${index}][name]`, skill.name);
                formData.append(`skills[${index}][displayName]`, skill.displayName);
                formData.append(`skills[${index}][domainId]`, skill.domainId);
                formData.append(`skills[${index}][domainName]`, skill.domainName);
                formData.append(`skills[${index}][domainDisplayName]`, skill.domainDisplayName);
            });
        }

        if (command.communities && command.communities.length > 0 && command.primaryCommunityId) {
            command.communities.forEach((community, i) => {
                formData.append(`communities[${i}][id]`, community.id);
                formData.append(`communities[${i}][name]`, community.name);
                formData.append(`communities[${i}][displayName]`, community.displayName);
                formData.append(`communities[${i}][isPrimary]`, command.primaryCommunityId == community.id ? 'true' : 'false');

                if (community.communitiesGroups) {
                    community.communitiesGroups.forEach((group: CommunityGroup, j) => {
                        formData.append(`communities[${i}][communitiesGroups][${j}][id]`, group.id);
                        formData.append(`communities[${i}][communitiesGroups][${j}][name]`, group.name);
                        formData.append(`communities[${i}][communitiesGroups][${j}][displayName]`, group.displayName);
                        formData.append(`communities[${i}][communitiesGroups][${j}][communityId]`, group.communityId);
                    });
                }
            });
        }

        return formData;
    }

    public getMyOrganization(): Observable<Organization> {
        return this.http.get<Organization>(`${this.organizationApiUrl}/Mine`);
    }

    // Get One Organization
    public getOrganizationById(id: string): Observable<Organization> {
        return this.http.get<Organization>(this.organizationApiUrl + '/' + id);
    }

    public getOrganizationByName(query: string): Observable<Organization[]> {
        return this.http.get<Organization[]>(`${this.organizationApiUrl}/search/?customSearch=${query}`);
    }

    // List of All Organization Types
    public getOrganizationTypes(): Observable<OrganizationType[]> {
        return this.http.get<OrganizationType[]>(this.organizationTypeApiUrl);
    }

    // List All Organizations and Entreprises
    public getOrganizations(): Observable<Organization[]> {
        return this.http.get<Organization[]>(this.organizationApiUrl);
    }

    public getOrganizationsFilterListByCommunityId(communityId: string): Observable<OrganizationDirectoryFilterDto[]> {
        return this.http.get<OrganizationDirectoryFilterDto[]>(`${this.organizationApiUrl}/communities/${communityId}/filters`);
    }

    public getUserOrganization(userId: string): Observable<OrganizationBase> {
        return this.http.get<Organization>(`${this.organizationApiUrl}/His/${userId}`);
    }

    public updateManyOrganizationsProperty(command: UpdateManyOrganizationsPropertyCommand): Observable<Organization[]> {
        return this.http.put<Organization[]>(this.updateOrganizationsPropertyApiUrl, command);
    }

    public updateOrganization(command: UpdateOrganizationCommand): Observable<Organization> {
        const formData = this.getFormData(command);

        formData.append('organizationId', command.organizationId);
        return this.http.put<Organization>(this.organizationApiUrl, formData);
    }

    public updateOrganizationMinimalInfos(command: UpdateOrganizationCommand) {
        const formData = this.getFormData(command);
        formData.append('organizationId', command.organizationId);
        return this.http.put(this.updateOrganizationMinimalInfosApiUrl, formData);
    }

    private createBatchInsertOrganizationBody(organization: Organization[], communities: Community[]): string {
        return `{"Organizations": ${JSON.stringify(organization)}, "Communities": ${JSON.stringify(communities)},
    "Skills": [],
    "Interests": [],
    "SkillDomains": [],
    "InterestDomains": [],
    "OrganizationsTypes":[]}`;
    }
}
