<div *ngIf="!isStepLoaded" class="spinner-border text-info" role="status">
    <span class="sr-only">{{ 'projects.formStep1.loading' | translate }}</span>
</div>
<form *ngIf="stepForm" [formGroup]="stepForm" [hidden]="!isStepLoaded" (ngSubmit)="onSubmit()" class="col-12">
    <div class="card box-shadow overflow-visible">
        <div class="card-body">
            <app-step-header [user]="currentProfile"></app-step-header>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <span class="fw-b primary-1 mb-2 d-block"
                        >{{ 'filters.event.inscriptionModalities' | translate }} *</span
                    >
                    <div class="form-check mt-3">
                        <input
                            class="form-check-input"
                            type="radio"
                            formControlName="registrationPolicy"
                            id="unlimited"
                            [value]="EventRegistrationPolicyEnum.Unlimited"
                        />
                        <label class="form-check-label" for="unlimited">
                            {{ 'filters.event.inscriptionFreeUnlimited' | translate }}
                        </label>
                    </div>
                    <div class="form-check d-flex align-items-center mt-3">
                        <input
                            class="form-check-input"
                            type="radio"
                            formControlName="registrationPolicy"
                            id="limited"
                            [value]="EventRegistrationPolicyEnum.Limited"
                        />
                        <label class="form-check-label d-flex align-items-center" for="limited">
                            <span>{{ 'filters.event.inscriptionlimitedPart1' | translate }}</span>
                            <input
                                type="number"
                                (keydown)="onMaxAttendeeKeydown($event)"
                                (paste)="onMaxAttendeePaste($event)"
                                formControlName="maxAttendeesCount"
                                class="form-control mx-2 max-attendees-count-input"
                            />
                            <span
                                *ngIf="
                                    stepForm.controls.maxAttendeesCount.value === null ||
                                    stepForm.controls.maxAttendeesCount.value < 1
                                "
                                >{{ 'filters.event.inscriptionlimitedPart2' | translate }}</span
                            >
                            <span *ngIf="stepForm.controls.maxAttendeesCount.value === 1">{{
                                'filters.event.inscriptionEndSingular' | translate
                            }}</span>
                            <span *ngIf="stepForm.controls.maxAttendeesCount.value > 1">{{
                                'filters.event.inscriptionEndPlural' | translate
                            }}</span>
                            <i
                                class="pi pi-info-circle ml-2 text-muted"
                                tooltipPosition="bottom"
                                pTooltip="{{ 'filters.event.inscriptionLimitedTooltip' | translate }}"
                            ></i>
                        </label>
                    </div>
                    <div *ngIf="showControlError(stepForm.controls.maxAttendeesCount)" class="alert alert-danger mt-2">
                        <div *ngIf="stepForm.controls.maxAttendeesCount.errors.required">
                            {{ 'events.formStep3.maxAttendeesCount.required' | translate }}
                        </div>
                        <div *ngIf="stepForm.controls.maxAttendeesCount.errors.min">
                            {{ 'events.formStep3.maxAttendeesCount.min' | translate }}
                        </div>
                        <div *ngIf="stepForm.controls.maxAttendeesCount.errors.max">
                            {{ 'events.formStep3.maxAttendeesCount.max' | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <app-scope-selection
                class="d-block mt-4"
                label="{{ 'filters.event.targetEvent' | translate }}"
                [joinableType]="JoinableType.Event"
                [form]="visibility"
                [formSubmitAttempt]="formSubmitAttempt"
                [userCommunities]="userCommunities"
                [allCommunities]="allCommunities"
                [canCreateAllCommunitiesProject]="canCreateAllCommunitiesProject"
                [hideAllCommunitiesVisibility]="!canCreateAllCommunitiesProject"
                [showAllCommunitiesVisibility]="canCreateAllCommunitiesProject"
            ></app-scope-selection>
        </div>
    </div>
</form>
