<div class="card-body">
    <div class="row align-items-center">
        <div class="col-auto">
            <img
                src="./assets/images/pwa-update.svg"
                class="card-img-top"
                alt="Mettre à jour l'application sur l'écran d'accueil"
            />
        </div>
        <div class="col">
            <p>Une nouvelle version de l'application est disponible !</p>
        </div>
    </div>
    <div class="d-flex justify-content-end mt-2">
        <button type="button" (click)="reload()" class="btn btn-primary">Mettre à jour</button>
    </div>
</div>
