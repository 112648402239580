<div class="row form-header pb-2">
    <div class="col-12">
        <div class="row">
            <div class="d-flex align-items-center">
                <button (click)="goBack()" class="btn btn-link btn-back">
                    <em class="icon icon-arrow-right1"></em>
                    <span class="d-inline my-2">{{ 'components.button.back' | translate }}</span>
                </button>
            </div>
        </div>
        <app-event-form-stepper
            [currentStep]="currentStep"
            (stepOneClicked)="onWizardStepOne()"
            (stepTwoClicked)="onWizardStepTwo()"
            (stepThreeClicked)="onWizardStepThree()"
        ></app-event-form-stepper>

        <div class="row">
            <div class="col">
                <h1 class="view-name">{{ 'events.pages.createEvent.newEvent' | translate }}</h1>
            </div>

            <div class="col-auto mr-auto mt-4">
                <button *ngIf="currentStep < 3" class="btn btn-primary" type="button" (click)="onStepNext()">
                    <em class="icon icon-arrow-left1 d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonNext' | translate }}</span>
                </button>
                <button *ngIf="currentStep === 3" class="btn btn-primary" (click)="onPublishRequestClick()">
                    <em class="icon icon-publish d-sm-inline d-md-none"></em>
                    <span class="d-none d-md-inline">{{ 'components.button.publish' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<app-event-form-step-one
    class="row"
    [hidden]="currentStep !== 1"
    [eventCommand]="eventCommand"
    [currentProfile]="accountService.currentUser"
    [isCurrentUserOwner]="true"
    (submitted)="onStepOneSubmitted()"
    #eventFormStepOne
>
</app-event-form-step-one>

<app-event-form-step-two
    class="row"
    [hidden]="currentStep !== 2"
    [eventCommand]="eventCommand"
    [currentProfile]="accountService.currentUser"
    [isCurrentUserOwner]="true"
    (submitted)="onStepTwoSubmitted()"
    #eventFormStepTwo
>
</app-event-form-step-two>

<app-event-form-step-three
    class="row"
    [hidden]="currentStep !== 3"
    [eventCommand]="eventCommand"
    [currentProfile]="accountService.currentUser"
    [isCurrentUserOwner]="true"
    (submitted)="onStepThreeSubmitted()"
    #eventFormStepThree
>
</app-event-form-step-three>

<ngx-spinner color="var(--primary-color)" type="ball-scale-ripple"></ngx-spinner>

<ng-template id="publishModal" #publishModal>
    <div class="modal-header">
        <h5 class="modal-title" id="publishModalLabel">
            {{ 'events.pages.createEvent.publishModal.title' | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="closePublishModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-image">
        <img src="{{ eventFormStepOne.picturePreviewUrl }}" [alt]="'events.shared.picture.alt' | translate" />
    </div>
    <div class="modal-body">
        <h6 class="text-center">
            {{ 'events.pages.createEvent.publishModal.subtitle' | translate }}
        </h6>
        <p class="text-muted text-center">
            {{ 'events.pages.createEvent.publishModal.body' | translate }}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closePublishModal()">
            {{ 'components.button.buttonCancel' | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="onPublishConfirmed()">
            {{ 'components.button.buttonYes' | translate }}
        </button>
    </div>
</ng-template>
