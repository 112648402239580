import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { messages } from '../constants/messages';
import { Error } from '../models/shared/error.model';

@Injectable({
    providedIn: 'root',
})
export class ToastrHelper {
    constructor(private readonly toastr: ToastrService) {}

    public showAnnouncement(message: string) {
        this.toastr.show(
            message,
            '',
            {
                closeButton: true,
                disableTimeOut: true,
            },
            'announcement-change-colors',
        );
    }

    public showError(message: string) {
        this.toastr.error(message, '', {
            closeButton: true,
            timeOut: 5000,
            enableHtml: true,
        });
    }

    public showGenericError(error: Error) {
        if (error?.statusText === messages.unknownError) {
            this.showError(messages.apiGenericError);
        } else if (error.code === HttpStatusCode.ServiceUnavailable.toString()) {
            // Infrastructure exception
            this.showError(messages.unknownError);
        }
    }

    public showSuccess(message: string) {
        this.toastr.success(message);
    }

    public showWarning(message: string) {
        this.toastr.warning(message, '', {
            closeButton: true,
            timeOut: 3000,
        });
    }
}
