import * as jschardet from 'jschardet';

export class FileHelper {
    public static async findTextFileEncoding(textBlob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                resolve(jschardet.detect(fileReader.result as Buffer).encoding);
            };
            fileReader.onerror = reject;
            fileReader.readAsText(textBlob);
        });
    }
}
