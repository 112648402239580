import { AfterViewInit, Directive, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseFiltersComponent } from '../../filters/shared/base-filters/base-filters.component';
import { ToastrHelper } from '../../helpers/toastr.helper';
import { PwaService } from '../../services/pwa/pwa.service';

@Directive()
export abstract class BaseFilteredList<TModel, TFiltersModel, TFiltersComponent extends BaseFiltersComponent<TFiltersModel>>
    implements AfterViewInit
{
    // TODO: Move this outside of the event module

    @ViewChild('filters', { static: false })
    protected filtersComponent: TFiltersComponent;

    /**
     * Is component loaded.
     */
    public isLoaded = false;

    /**
     * Is first load complete.
     */
    public isInitiated = false;

    public items: TModel[];
    private loadItemsSubscription: Subscription;
    public itemsLoaded: boolean;

    protected page = 1;
    private nextPage: boolean;

    public areFiltersOpened: boolean;
    private filtersReadyBeforeInit = false;

    protected abstract get savedFilters(): TFiltersModel;
    protected abstract set savedFilters(value: TFiltersModel);

    public get isOnMobile(): boolean {
        return this.pwaService.isMobile && !this.pwaService.isTablet;
    }

    constructor(private readonly pwaService: PwaService, private readonly toastrHelper: ToastrHelper) {}

    public ngAfterViewInit(): void {
        if (this.filtersReadyBeforeInit) {
            this.onFiltersReady();
        }
    }

    public onScroll() {
        if (!this.nextPage || !this.itemsLoaded) {
            return;
        }

        this.page++;
        this.loadItems(true);
    }

    public onFiltersReady() {
        // Filters are ready before this view is initiated
        if (this.filtersComponent === undefined) {
            this.filtersReadyBeforeInit = true;
            return;
        }

        this.nextPage = false;
        this.loadItems(false);
    }

    public onFiltersSelectionChanged() {
        this.savedFilters = null;
        this.nextPage = false;
        this.loadItems(false);
        window.scroll(0, 0);
    }

    public openFilters() {
        this.areFiltersOpened = true;
    }

    public onFiltersClosed() {
        this.areFiltersOpened = false;
    }

    private loadItems(isPushMode: boolean): void {
        if (!isPushMode) {
            this.page = 1;
            this.items = [];
        }

        if (this.loadItemsSubscription && !this.itemsLoaded) {
            this.loadItemsSubscription.unsubscribe();
        }

        this.itemsLoaded = false;

        this.loadItemsSubscription = this.getFilteredItemsSubscription().subscribe(
            (data: TModel[] | null) => {
                // null if no content
                if (data === null) {
                    return;
                }

                this.nextPage = data.length > 0;
                data.forEach((item) => this.items.push(item));
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.itemsLoaded = true;
                this.isInitiated = true;
            },
        );
    }

    protected abstract loadFilters(): TFiltersModel;
    protected abstract getFilteredItemsSubscription(): Observable<TModel[]>;
}
