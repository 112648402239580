import { Event } from '../../models/events/event.model';

export enum EventJoinStatus {
    CanJoin,
    Done,
    Canceled,
    Full,
    Ongoing,
}

export function getEventJoinStatus(event: Event): EventJoinStatus {
    if (event.isCanceled) {
        return EventJoinStatus.Canceled;
    }

    if (event.isFinished) {
        return EventJoinStatus.Done;
    }

    if (event.isOngoing) {
        return EventJoinStatus.Ongoing;
    }

    if (event.isFull) {
        return EventJoinStatus.Full;
    }

    return EventJoinStatus.CanJoin;
}
