<div class="completion-score">
    <div>
        <span
            >{{ 'user.completionScore.yourScore' | translate }}
            <span class="primary-color">{{ progressItems.score }}/100</span>
        </span>
        <i class="pi pi-info-circle ml-2" style="cursor: pointer" (click)="showInformationModal()"></i>
    </div>
    <div class="mt-1">
        <p-progressBar
            [value]="progressItems.score"
            [style]="{ width: '100%', marginRight: '10px', height: '0.5em' }"
            [showValue]="false"
        ></p-progressBar>
    </div>
</div>

<p-dialog
    header="Header"
    [(visible)]="isModalVisible"
    [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [style]="{ width: '50vw', 'max-width': '650px' }"
    appendTo="body"
>
    <ng-template pTemplate="header">
        <span class="font-weight-bold">{{ 'user.completionScore.calculTitle' | translate }}</span>
    </ng-template>

    <p class="description-label p-p-2 p-mt-1 p-mb-2">
        <span class="primary-color"
            >📢 <strong>{{ 'user.completionScore.important' | translate }}</strong></span
        >
        <br />
        {{ 'user.completionScore.calculDescription' | translate }}
    </p>

    <div class="profile-progress">
        <ul class="no-padding-left">
            <li *ngFor="let item of progressItems.items" class="progress-item" (click)="onItemClicked(item)">
                <div class="progress-info">
                    <app-check-icon [isChecked]="item.completed"></app-check-icon>
                    <span [ngClass]="{ 'text-not-completed': !item.completed, 'text-completed': item.completed }">
                        {{ item.label | translate }}
                    </span>
                </div>
                <div class="progress-points">
                    <strong>{{ item.points }} {{ 'user.completionScore.points' | translate }}</strong>
                </div>
            </li>
        </ul>
    </div>

    <ng-template pTemplate="footer">
        <button class="p-button" (click)="hideInformationModal()">
            {{ 'components.button.buttonClose' | translate }}
        </button>
    </ng-template>
</p-dialog>
