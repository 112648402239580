import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-step-progress-bar',
    templateUrl: './step-progress-bar.component.html',
    styleUrls: ['./step-progress-bar.component.scss'],
})
export class StepProgressBarComponent {
    constructor() {}

    @Input() progress = 0;

    incrementProgress() {
        this.progress++;
    }

    decrementProgress() {
        this.progress--;
    }
}
