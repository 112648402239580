import { ClubMember } from './club-member.model';
import { ClubRoles } from './club-roles.model';

export class Club {
    public communityId: string;
    public coverPictureUrl: string;
    public creationDate: Date;
    public description: string;
    public id: string;
    public isCommunityPresentationRoom: boolean;
    public lastUpdateDate: Date;
    public members: ClubMember[];
    public parentClubId: string;
    public privacy: number;
    public profilePictureUrl: string;
    public projectId: string;
    public publicationsAreLimited: boolean;
    public rooms: Club[];
    public title: string;
    public visibility: number;

    constructor(object?: Partial<Club>) {
        Object.assign(this, object);
    }
}

export class ClubMembers {
    public members: Array<any>;
    public role: ClubRoles;
}

export const CLUB_NAME_MAX_LENGTH = 50;
export const DESCRIPTION_MAX_LENGTH = 2000;
