import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { User } from '../../../../models/identity/user.model';
import { ProfileProgressItem } from '../../../../models/users/profile/profile-progress-item';
import { ProfileProgressList } from '../../../../models/users/profile/profile-progress-list';

@Component({
    selector: 'app-user-completion-score',
    templateUrl: './user-completion-score.component.html',
    styleUrls: ['./user-completion-score.component.scss'],
})
export class UserCompletionScoreComponent implements OnInit, OnChanges {
    public isModalVisible: boolean;
    public progressItems: ProfileProgressList;
    @Input() public user: User;
    @Output() public itemClicked = new EventEmitter<string>();

    constructor() {}

    public hideInformationModal() {
        this.isModalVisible = false;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.user && this.progressItems) {
            this.progressItems = new ProfileProgressList(this.user);
        }
    }

    public ngOnInit(): void {
        this.progressItems = new ProfileProgressList(this.user);
    }

    public showInformationModal() {
        this.isModalVisible = true;
    }

    public onItemClicked(item: ProfileProgressItem) {
        if (item.completed) {
            return;
        }

        this.hideInformationModal();
        setTimeout(() => {
            this.itemClicked.emit(item.inputName);
        }, 300);
    }
}
