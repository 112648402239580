import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { User } from '../../../models/identity/user.model';

@Injectable({ providedIn: 'root' })
export class UserInfosService {
    constructor(private http: HttpClient) {}

    public setPersonalInfos(user: User): Observable<any> {
        return this.http.put(
            `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.usersApi.baseUrl}/${user.userId}/infos`,
            user,
        );
    }
}
