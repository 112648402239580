import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AddressResult } from '../../../../azure/map/models/address.model';
import { AddressSearchService } from '../../../../azure/map/services/address.service';

@Component({
    selector: 'app-address-autocomplete',
    template: `
        <div class="form-group">
            <label for="address_user">{{ 'user.personalInfo.address.title' | translate }} <span *ngIf="isRequired">*</span></label>
            <input
                type="text"
                [formControl]="searchControl"
                (focus)="isOpen = true"
                class="form-control d-block w-100"
                id="address_user"
                [required]="isRequired"
                [placeholder]="'user.personalInfo.address.placeholder' | translate"
            />
            <ul *ngIf="isOpen && suggestions$ | async as suggestions" class="suggestions-list w-100">
                <li *ngFor="let suggestion of suggestions" (click)="selectAddress(suggestion)">
                    {{ addressService.formatAddress(suggestion) }}
                </li>
            </ul>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AddressAutocompleteComponent,
            multi: true,
        },
    ],
    styles: [
        `
            .form-group {
                width: 100%;
                position: relative;
            }

            .form-control {
                width: 100%;
                display: block;
            }

            .suggestions-list {
                list-style: none;
                padding: 0;
                margin: 0;
                border: 1px solid #ddd;
                border-top: none;
                max-height: 200px;
                overflow-y: auto;
                position: absolute;
                background: white;
                z-index: 1000;
                width: 100%;
                left: 0;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }

            li {
                padding: 8px 12px;
                cursor: pointer;
                border: none;
                background: white;
            }

            li:hover {
                background-color: #f5f5f5;
            }
        `,
    ],
})
export class AddressAutocompleteComponent implements OnInit, ControlValueAccessor {
    @Input() isRequired: boolean = false;
    @Output() addressSelected = new EventEmitter<AddressResult>();
    isOpen = true;
    searchControl = new FormControl('');

    suggestions$ = this.searchControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        filter((query) => query?.length >= 2 || !query),
        switchMap((query) =>
            query
                ? this.addressService.searchAddresses(query, {
                      countrySet: ['FR'],
                  })
                : of([]),
        ),
    );

    constructor(public addressService: AddressSearchService) {}

    ngOnInit(): void {
        this.searchControl.valueChanges.subscribe((value) => {
            this.onChange(value);
        });
    }

    onChange = (_: any) => {};
    onTouched = () => {};

    writeValue(value: any): void {
        if (value) {
            this.searchControl.setValue(value, { emitEvent: false });
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    selectAddress(suggestion: AddressResult): void {
        const formattedAddress = this.addressService.formatAddress(suggestion);
        this.searchControl.setValue(formattedAddress, { emitEvent: true });
        this.isOpen = false;
        this.onChange(formattedAddress);
        this.onTouched();
        this.addressSelected.emit(suggestion);
    }
}
