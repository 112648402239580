import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QuillEditorHelper } from '../../../helpers/quill-editor.helper';
import { SpinnerHelper } from '../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { Club, DESCRIPTION_MAX_LENGTH, CLUB_NAME_MAX_LENGTH } from '../../../models/clubs/club.model';
import { ClubsService } from '../../../services/clubs/clubs.service';
import { messages } from './../../../constants/messages';
import { ClubRoles } from './../../../models/clubs/club-roles.model';
import { AccountsService } from './../../../services/identity/accounts.service';
import { ClubsStore } from './../../clubs.store';

@Component({
    selector: 'create-club-modal-button',
    styleUrls: ['./create-club-modal-button.component.scss'],
    templateUrl: './create-club-modal-button.component.html',
})
export class CreateClubModalButtonComponent implements OnInit {
    @Input() isEditMode: boolean;
    @Input() club: Club = null;
    @Input() showButton = true;

    messages = messages;
    CLUB_NAME_MAX_LENGTH = CLUB_NAME_MAX_LENGTH;
    DESCRIPTION_MAX_LENGTH = DESCRIPTION_MAX_LENGTH;

    isModalDisplayed: boolean;
    isTitleEditable = true;

    currentUserInfos = {
        id: this.accountService.currentUser.userId,
        role: ClubRoles.Admin,
    };

    members = new FormControl(null, [Validators.required]);

    descriptionCounter = 0;

    get headerTitle() {
        return this.isEditMode
            ? this.translate.instant('clubs.createClubButton.clubParameters', { clubName: this.club?.title })
            : this.translate.instant('clubs.createClubButton.createClub');
    }

    form = this.fb.group({
        id: [null, []],
        title: [null, [Validators.required, Validators.maxLength(CLUB_NAME_MAX_LENGTH)]],
        description: [null],
        members: this.members,
    });

    displayInFrench: boolean;

    constructor(
        private readonly router: Router,
        private readonly accountService: AccountsService,
        private readonly fb: FormBuilder,
        private readonly toastrHelper: ToastrHelper,
        private readonly spinnerHelper: SpinnerHelper,
        private readonly clubService: ClubsService,
        private readonly clubsStore: ClubsStore,
        public readonly quillEditorHelper: QuillEditorHelper,
        private translate: TranslateService,
    ) {
        this.members.setValue([this.currentUserInfos]);
    }
    ngOnInit(): void {
        this.displayInFrench = this.translate.currentLang.indexOf('fr') !== -1;
    }

    public openModal() {
        if (this.isEditMode) {
            // /!\ récupérer le club actuel depuis le store
            // car this.club, correspondant au salon "Général" est "bindé" en amont avec la class ClubRoom,
            // et ne comprend pas toutes les informations nécessaires du club actuel
            const isDisplayedClub = this.club.id === this.clubsStore.displayedClub?.id;
            if (isDisplayedClub) {
                this.club = this.clubsStore.displayedClub;
            }
            this.form.controls.title.setValue(this.club.title);
            this.form.controls.description.setValue(this.club.description);
            this.descriptionCounter = this.quillEditorHelper.getPlainTextLength(this.club.description);
            // Le nom d'un club non communautaire est modifiable par les admins
            // Le nom d'un club communautaire n'est pas modifiable (hérité du nom de la communauté)
            this.isTitleEditable =
                this.isEditMode &&
                this.clubsStore.isAdminOfDisplayedClub &&
                !this.club?.communityId &&
                !this.club?.projectId;
        }

        this.quillEditorHelper.setQuillControlError(
            this.form.controls.description,
            this.descriptionCounter,
            DESCRIPTION_MAX_LENGTH,
        );
        this.isModalDisplayed = true;
    }

    closeModal() {
        this.isModalDisplayed = false;
        //this.form.reset(); // TODO better?
        this.form.controls.title.setValue('');
        this.form.controls.description.setValue('');
        this.members.setValue([this.currentUserInfos]);
    }

    async handleOnSubmit() {
        if (!this.clubsStore.isAdminOfDisplayedClub && this.isEditMode) {
            const msg = this.translate.instant('clubs.createClubButton.toast.unauthorizedRoomSettingsAccess');
            this.toastrHelper.showWarning(msg);
            return;
        }

        if (!this.isFormValid()) return;

        try {
            this.spinnerHelper.showSpinner();
            await this.createOrUpdateClubAsync();
            this.closeModal();
            this.spinnerHelper.hideSpinner();
        } catch (error) {}
    }

    private isFormValid(): boolean {
        this.spinnerHelper.hideSpinner();

        if (this.form.invalid) {
            const warningMessages = [];

            if (this.form.controls.title.errors.required) {
                const msg = this.translate.instant('clubs.createClubButton.roomNameRequired');
                warningMessages.push(msg);
            }

            if (this.form.controls.title.errors.maxlength) {
                const msg = this.translate.instant('clubs.createClubButton.roomTitleLength', {
                    maxLength: CLUB_NAME_MAX_LENGTH,
                });
                warningMessages.push(msg);
            }

            if (this.form.controls.description.errors.maxlength) {
                const msg = this.translate.instant('clubs.createClubButton.descriptionLength', { maxLength: 2000 });
                warningMessages.push(msg);
            }
            warningMessages.forEach((msg) => {
                this.toastrHelper.showWarning(msg);
            });
            return warningMessages.length == 0;
        }

        return true;
    }

    private async createOrUpdateClubAsync() {
        let club: Club = null;

        if (this.isEditMode) {
            this.form.controls.id.setValue(this.club.id);
            club = await this.clubService.updateClub(this.form.value).toPromise();
            const msg = this.translate.instant('clubs.createClubButton.toast.successfullyUpdated', {
                clubName: club.title,
            });
            this.toastrHelper.showSuccess(msg);
        } else {
            club = await this.clubService.createClub(this.form.value).toPromise();
            const msg = this.translate.instant('clubs.createClubButton.toast.successfullyCreated', {
                clubName: club.title,
            });
            this.toastrHelper.showSuccess(msg);
        }

        await this.clubsStore.loadClub(club.id);
        return club;
    }

    onDescriptionChange(editor: any) {
        this.descriptionCounter = this.quillEditorHelper.getPlainTextLength(editor.container.innerHTML);
        this.quillEditorHelper.setQuillControlError(
            this.form.controls.description,
            this.descriptionCounter,
            DESCRIPTION_MAX_LENGTH,
        );
    }
}
