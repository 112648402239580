import { PasswordConst } from './../constants/password';
import { messages } from '../constants/messages';
import { ToastrHelper } from './toastr.helper';

export class PasswordHelper {
    public static isPasswordValid(
        toastrHelper: ToastrHelper,
        password: string,
        confirmation: string,
        sendMessage = false,
    ): boolean {
        const isPasswordValid = password.match(PasswordConst.passwordValidregex);
        const isConfirmationIdentical = password === confirmation;

        if (!isPasswordValid && sendMessage) {
            const digits = '0123456789';

            const isPasswordLong = password.length >= 8;
            const digitsFlag = PasswordHelper.contains(password, digits);
            const specialCharsFlag = PasswordHelper.contains(password, PasswordConst.specialCharAuthorized);
            const containsUpperCase = !!password.match(/[A-Z]/);
            const containsLowerCase = !!password.match(/[a-z]/);

            if (sendMessage) {
                PasswordHelper.sendErrorMessages(
                    toastrHelper,
                    isPasswordLong,
                    digitsFlag,
                    specialCharsFlag,
                    isConfirmationIdentical,
                    containsUpperCase,
                    containsLowerCase,
                );
            }
        }

        if (!isPasswordValid || !isConfirmationIdentical) return false;
        else return true;
    }

    private static contains(password: string, allowedChars: string): boolean {
        for (let i = 0; i < password.length; i++) {
            const char = password.charAt(i);
            if (allowedChars.indexOf(char) >= 0) {
                return true;
            }
        }

        return false;
    }

    private static sendErrorMessages(
        toastrHelper: ToastrHelper,
        isPasswordLong: boolean,
        digitsFlag: boolean,
        specialCharsFlag: boolean,
        isConfirmationIdentical: boolean,
        containsUpperCase: boolean,
        containsLowerCase: boolean,
    ) {
        if (!isPasswordLong) {
            toastrHelper.showError(messages.passwordLengthError);
        }

        if (!digitsFlag) {
            toastrHelper.showError(messages.passwordNumberError);
        }

        if (!specialCharsFlag) {
            toastrHelper.showError(messages.passwordCharacterError);
        }

        if (!isConfirmationIdentical) {
            toastrHelper.showError(messages.matchingPasswordsError);
        }

        if (!containsUpperCase) {
            toastrHelper.showError(messages.passwordDoesNotContainAnyUppercaseError);
        }

        if (!containsLowerCase) {
            toastrHelper.showError(messages.passwordDoesNotContainAnyLowercaseError);
        }
    }
}
