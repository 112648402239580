<div (click)="openPdfModal()" class="p-card mb-2 card-style cursor">
    <div class="card-body">
        <div class="d-flex flex-row align-items-center">
            <i class="pi pi-file-pdf fs-3"></i>
            <div class="d-flex flex-column justify-content-center ml-2">
                <span class="text-muted">
                    {{ 'community.pages.description.communityPresentation' | translate }}
                </span>
            </div>
        </div>
    </div>
</div>

<p-dialog
    (click)="closePdfModal()"
    [modal]="true"
    header="{{ pdfName }}"
    [(visible)]="visibleModal"
    [style]="{ width: '50rem', height: '90vh' }"
    [resizable]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
>
    <iframe
        id=" pdfViewer"
        frameborder="0"
        scrolling="no"
        type=" application/pdf"
        [src]="safePdfUrl"
        style="border: none; width: 100%; height: 95%"
        allowfullscreen
    ></iframe>
</p-dialog>
