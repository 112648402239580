<input #imageInput type="file" hidden [accept]="acceptedFormats.join(',')" (change)="fileChangeEvent($event)" />
<div class="wudo-image-cropper">
    <div class="image-container">
        <image-cropper
            *ngIf="hasImage"
            class="image-cropper"
            [(imageURL)]="imageURLGetter"
            [format]="format"
            [maintainAspectRatio]="maintainAspectRatio"
            [aspectRatio]="aspectRatio"
            [resizeToWidth]="resizeToWidth"
            [resizeToHeight]="resizeToHeight"
            [imageChangedEvent]="imageChangedEvent"
            (imageCropped)="imageCropped($event)"
            [onlyScaleDown]="true"
        ></image-cropper>
        <div
            *ngIf="!hasImage"
            appDragDrop
            class="doted-container"
            (onFileDropped)="fileDroppedEvent($event)"
            (mousedown)="imageInput.click()"
        >
            <div class="instructions">
                {{ 'components.imageCropModal.uploadFile' | translate }}
                <br />
                <small>{{ 'components.imageCropModal.or' | translate }}</small>
                <br />
                <small>{{ 'components.imageCropModal.clickToSelect' | translate }}</small>
            </div>
        </div>
        <button
            *ngIf="hasImage"
            pRipple
            pButton
            class="p-button-rounded p-button-sm p-button-secondary p-button-text change-image-button"
            (click)="imageInput.click()"
            label="{{ 'components.imageCropModal.uploadAnother' | translate }}"
            icon="pi pi-upload"
        ></button>
        <div class="restrictions">
            <div class="property" *ngIf="displayedMaximalSize">
                <span class="property-label">{{ 'components.imageCropModal.maxSize' | translate }} </span>
                <span class="property-value"> {{ displayedMaximalSize }}</span>
            </div>
            <div class="property" *ngIf="acceptedFormats?.length > 0">
                <span class="property-label">{{ 'components.imageCropModal.acceptedFormats' | translate }} </span>
                <span class="property-value">
                    <div class="property-value-item" *ngFor="let format of acceptedFormats">
                        {{ format }}
                    </div>
                </span>
            </div>
            <div class="property" *ngIf="resizeToWidth && resizeToWidth">
                <span class="property-label">{{ 'components.imageCropModal.recommendedDimensions' | translate }}</span>
                <span class="property-value">
                    {{
                        'components.imageCropModal.sizeExample'
                            | translate: { resizeToWidth: resizeToWidth, resizeToHeight: resizeToHeight }
                    }}</span
                >
            </div>
        </div>
    </div>
</div>
