<span
    class="circle"
    *ngIf="shouldDisplayUsersStatus"
    [ngClass]="{
        active: currentUserStatusType == userStatusTypes.Active,
        inactive: currentUserStatusType == userStatusTypes.Inactive,
        disabled: currentUserStatusType == userStatusTypes.Disabled
    }"
    pTooltip="{{ tooltip }}"
></span>
<span class="ml-1" *ngIf="shouldDisplayUsersStatus && displayLabel">{{ label }}</span>
