import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProjectType } from '../../models/projects/project-type.model';
import { CacheService } from '../shared/cache.service';

@Injectable({ providedIn: 'root' })
export class ProjectTypesService {
    private projectTypesApiUrl = `${environment.microservices.projects.baseUrl}/${environment.microservices.projects.projectTypesApi.baseUrl}`;

    // Key of the projects types in the cache
    private projectsTypesCacheKey = 'currentProjectsTypes';

    constructor(private http: HttpClient, private readonly cacheService: CacheService) {}

    getProjectTypes() {
        return this.cacheService.get(this.projectsTypesCacheKey, this.http.get<ProjectType[]>(this.projectTypesApiUrl));
    }
}
