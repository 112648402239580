<div *ngIf="!isOnMobile">
    <a (click)="onPreviewClicked(link)">
        <div class="link-preview p-grid p-mx-4" *ngIf="metadatas">
            <div class="p-col-4 p-p-0 p-my-auto">
                <img
                    class="link-preview-picture p-mx-auto"
                    src="{{ metadatas.pictureLink !== null ? metadatas.pictureLink : alternativePicture }}"
                    alt="url preview"
                />
            </div>
            <div class="p-col-8 link-preview-content">
                <p class="link-preview-title p-mb-2 p-mx-1">
                    {{ decodeHTMLEntities(metadatas.title) | truncateText : 100 }}
                </p>
                <p class="link-preview-description p-mb-2 p-mx-1">
                    {{ decodeHTMLEntities(metadatas.description) | truncateText : 150 }}
                </p>
                <p class="link-preview-url p-mb-auto">
                    {{ metadatas.baseUrl }}
                </p>
            </div>
        </div>
    </a>
</div>
<div *ngIf="isOnMobile">
    <a (click)="onPreviewClicked(link)" target="_blank">
        <div class="link-preview p-mx-2" *ngIf="metadatas">
            <div class="">
                <img
                    class="link-preview-picture link-preview-picture-mobile p-mx-auto"
                    src="{{ metadatas.pictureLink !== null ? metadatas.pictureLink : alternativePicture }}"
                    alt="url preview"
                />
            </div>
            <div class="link-preview-content-mobile p-px-2">
                <p class="link-preview-title">
                    {{ decodeHTMLEntities(metadatas.title) | truncateText : 100 }}
                </p>
                <p class="link-preview-description">
                    {{ decodeHTMLEntities(metadatas.description) | truncateText : 150 }}
                </p>
                <p class="link-preview-url p-mb-0">{{ metadatas.baseUrl }}</p>
            </div>
        </div>
    </a>
</div>

<app-app-youtube-player-modal [visible]="isYoutubeModalOpen" [link]="link" (modalHidden)="this.toggleYoutubeVideoModal()">
</app-app-youtube-player-modal>
