import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticatedUser } from '../models/identity/authenticated-user.model';
import { AccountsService } from '../services/identity/accounts.service';
import { WordingService } from '../services/shared/wording.service';
import { environment } from './../../environments/environment';
import { localStorageKeys } from './../constants/local-storage-keys';

export type OAuthProviders = {
    name: string;
    enable: boolean;
    authenticationUrl: string;
    clientId: string;
};

@Component({
    templateUrl: './login.component.html',
})
export class BaseLoginComponent {
    public oAuthProviders: { [providerName: string]: OAuthProviders };
    public oAuthRedirectUrl: string = environment.sso.oAuth?.redirectUrl;
    public oAuthStateParameter: string;
    public returnUrl: string;
    @ViewChild('termsOfUseModal', { static: false })
    public termsOfUseModalRef: TemplateRef<any>;

    constructor(
        protected readonly route: ActivatedRoute,
        protected readonly router: Router,
        protected readonly accountsService: AccountsService,
        protected readonly modalService: NgbModal,
        protected readonly translate: TranslateService,
        private wording: WordingService,
    ) {}

    public defineCetimOAuth2LoginUrl(): string {
        if (this.oAuthProviders['cetim']) {
            return `${this.oAuthProviders['cetim'].authenticationUrl}?client_id=${this.oAuthProviders['cetim'].clientId}&scope=openid&response_type=code&redirect_uri=${this.oAuthRedirectUrl}?provider=CETIM&state=${this.oAuthStateParameter}`;
        }
        return '';
    }

    public defineGifasOAuth2LoginUrl(): string {
        if (this.oAuthProviders['gifas']) {
            return `${this.oAuthProviders['gifas'].authenticationUrl}?client_id=${this.oAuthProviders['gifas'].clientId}&response_type=code&redirect_uri=${this.oAuthRedirectUrl}&state=${this.oAuthStateParameter}`;
        }
        return '';
    }

    public defineIkigaiOAuth2LoginUrl(): string {
        if (this.oAuthProviders['ikigai']) {
            return `${this.oAuthProviders['ikigai'].authenticationUrl}?redirectUri=${this.oAuthRedirectUrl}&state=${this.oAuthStateParameter}`;
        }
        return '';
    }

    public definePSIOAuthLoginUrl(): string {
        if (this.oAuthProviders['psi']) {
            return `${this.oAuthProviders['psi'].authenticationUrl}?client_id=${this.oAuthProviders['psi'].clientId}&response_type=code&redirect_uri=${this.oAuthRedirectUrl}&state=${this.oAuthStateParameter}`;
        }
        return '';
    }

    public generateOAuth2Providers() {
        this.oAuthProviders = {};
        environment.sso.oAuth.providers.forEach((payload: OAuthProviders) => {
            const provider = payload;
            if (!provider?.enable) {
                return;
            }
            this.oAuthProviders[provider.name] = provider;
        });
    }

    public generateOAuth2StateParameter() {
        localStorage.removeItem(localStorageKeys.oAuthStateParameter);
        this.oAuthStateParameter = this.accountsService.generateOAuthStateParameter();
        localStorage.setItem(localStorageKeys.oAuthStateParameter, this.oAuthStateParameter);
    }

    public onLogedinSuccessfully(user: AuthenticatedUser) {
        const mainCommunity = user.communities.find((comm) => comm.isPrimary);
        this.wording.loadTranslationsForCommunity(mainCommunity);

        // Open modal if terms of use aren't accepted
        if (!user.termsOfUse) {
            this.modalService.open(this.termsOfUseModalRef, {
                size: 'lg',
                centered: true,
                scrollable: false,
            });
            return;
        }

        this.initReturnUrl();

        this.returnUrl = this.getReturnUrl() || '/';
        this.router.navigateByUrl(this.returnUrl, { replaceUrl: true });
    }

    public onLoggedInFail() {
        this.router.navigate(['login']);
    }

    protected initReturnUrl() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
        if (this.returnUrl) {
            // Save the returnUrl in local storage or session storage
            sessionStorage.setItem('returnUrl', this.returnUrl);
        }
    }

    protected getReturnUrl(): string {
        const returnUrl = sessionStorage.getItem('returnUrl');
        if (returnUrl) {
            sessionStorage.removeItem('returnUrl');
        }
        return returnUrl || '';
    }
}
