<div
    class="card p-shadow-24"
    (click)="handleClick()"
    [class.button-onboarding]="!isDisabled"
    [class.button-onboarding-disable]="isDisabled"
>
    <div class="p-m-1 p-grid p-ai-center vertical-container">
        <div class="p-col-2">
            <p-avatar image="{{ sourceImage }}" size="large" shape="circle"></p-avatar>
        </div>
        <div class="p-col">
            {{ title }}
        </div>
        <div class="p-col-1 float-right">
            <em class="icon icon-arrow-left1"></em>
        </div>
    </div>
</div>
