import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { FiltersItem } from '../../../models/shared/filters-item.model';
import { SkillDomain } from '../../../models/skills/skill-domain.model';
import { Skill } from '../../../models/skills/skill.model';
import { SkillsService } from '../../../services/skills/skills.service';
import { WudoTranslatePipe } from '../../../pipes/wudo-translate.pipe';

@Component({
    selector: 'app-skills',
    templateUrl: './skills.component.html',
    styleUrls: ['./skills.component.scss'],
})
export class SkillsComponent implements OnInit {
    // Set this variable to 'profile' or 'project' to change component titles.
    @Input() context = 'default';

    @Input() hideTitle = false;
    @Input() currentSkills: Skill[] = [];
    @Input() maxSkills = 10;
    @Output() changed = new EventEmitter<Skill[]>();
    @Input() showSpecificCommunitySkills = false;

    @Input() skillsDomainPlaceholder = '';
    @Input() skillsPlaceHolder = '';

    dataLoaded: Promise<boolean>;
    get maximumSkills(): boolean {
        return this.currentSkills?.length >= this.maxSkills;
    }

    private skills: Skill[];
    skillsFilterItems: FiltersItem[];
    private skillDomains: SkillDomain[] = [];
    skillDomainsFilterItems: FiltersItem[];

    get visibleSkills(): FiltersItem[] {
        return this.skillsFilterItems.filter((option) => option.visible);
    }

    get visibleSkillDomains(): FiltersItem[] {
        return this.skillDomainsFilterItems.filter((option) => option.visible);
    }

    constructor(
        private readonly toastrHelper: ToastrHelper,
        private readonly skillService: SkillsService,
        private readonly wtranslate: WudoTranslatePipe,
    ) {}

    ngOnInit() {
        forkJoin(this.skillService.getAllSkillsDomains(), this.skillService.getAllSkills()).subscribe(
            ([skillsDomain, skills]) => {
                if (this.showSpecificCommunitySkills) {
                    //Add a virtual domain
                    var skilldomainItem = new SkillDomain();
                    skilldomainItem.displayName = 'Boost French Fab';
                    skilldomainItem.id = '1';

                    this.skillDomains.push(skilldomainItem);
                    skillsDomain.forEach((item) => this.skillDomains.push(item));
                } else {
                    this.skillDomains = skillsDomain;
                }
                this.skillDomainsFilterItems = this.skillDomains.map(
                    (domain) =>
                        new FiltersItem(domain.id, this.wtranslate.transform(domain.displayName, 'skillDomains')),
                );
                this.skillDomainsFilterItems.forEach((item) => (item.visible = true));

                this.skills = skills;

                this.skillsFilterItems = this.skills.map(
                    (skill) => new FiltersItem(skill.id, this.wtranslate.transform(skill.displayName, 'skills')),
                );
                if (this.showSpecificCommunitySkills) {
                    this.skillDomainsFilterItems.find((item) => item.key === '1').check();
                    this.onCheckedSkillDomain();
                }
                this.dataLoaded = Promise.resolve(true);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
        );
    }

    onSkillDomainSearchTextChanged(searchText: string) {
        this.skillDomainsFilterItems.forEach((skillDomain) => {
            skillDomain.visible =
                !searchText ||
                skillDomain.checked ||
                skillDomain.value.trim().toLowerCase().includes(searchText.trim().toLowerCase());
        });
    }

    onSkillSearchTextChanged(searchText: string) {
        if (!this.skillDomainsFilterItems.some((option) => option.checked)) {
            this.skillsFilterItems.forEach((item) => {
                item.visible = item.value.trim().toLowerCase().includes(searchText.trim().toLowerCase()) || !searchText;
            });
        } else {
            this.skillsFilterItems.forEach((item) => {
                item.visible =
                    this.skillDomainsFilterItems
                        .filter((option) => option.checked)
                        .map((domain) => domain.key)
                        .includes(this.skills.find((skill) => skill.id === item.key).domainId) &&
                    item.value.trim().toLowerCase().includes(searchText.trim().toLowerCase());
            });

            if (
                this.showSpecificCommunitySkills === true &&
                this.skillDomainsFilterItems.find((item) => item.key === '1').checked == true
            ) {
                environment.bffSkilsItems.forEach((item) => {
                    if (
                        this.skillsFilterItems.find(
                            (skill) =>
                                skill.value === item.displayName &&
                                skill.value.trim().toLowerCase().includes(searchText.trim().toLowerCase()),
                        ) != undefined
                    )
                        this.skillsFilterItems.find(
                            (skill) =>
                                skill.value === item.displayName &&
                                skill.value.trim().toLowerCase().includes(searchText.trim().toLowerCase()),
                        ).visible = true;
                });
            }
        }
    }

    onCheckedSkillDomain() {
        if (!this.skillDomainsFilterItems.some((option) => option.checked)) {
            this.skillsFilterItems.forEach((item) => (item.visible = true));
        } else {
            this.skillsFilterItems.forEach((item) => {
                item.visible = this.skillDomainsFilterItems
                    .filter((option) => option.checked)
                    .map((domain) => domain.key)
                    .includes(this.skills.find((skill) => skill.id === item.key).domainId);
            });
            if (
                this.showSpecificCommunitySkills === true &&
                this.skillDomainsFilterItems.find((item) => item.key === '1').checked == true
            ) {
                environment.bffSkilsItems.forEach((item) => {
                    this.skillsFilterItems.find((skill) => skill.value === item.displayName).visible = true;
                });
            }
        }
    }

    checkIfHasSkill(skillId: string): boolean {
        return this.currentSkills?.some((skill) => skill.id === skillId) ?? false;
    }

    addSkill(skillId: string) {
        const skillFound = this.skills.find((skill) => skill.id === skillId);
        if (skillFound) {
            if (!this.currentSkills) {
                this.currentSkills = [];
            }
            this.currentSkills.push(skillFound);
            this.currentSkills.sort((a, b) => a.name.localeCompare(b.name));
            this.changed.emit(this.currentSkills);
        }
    }

    removeSkill(skillId: string) {
        this.currentSkills = this.currentSkills?.filter((skill) => skill.id !== skillId) || [];
        this.changed.emit(this.currentSkills);
    }
}
