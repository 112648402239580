import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AddressResult, GeoJsonPoint } from '../../../../azure/map/models/address.model';
import { regexPatterns } from '../../../../constants/regex-patterns';
import { QuillEditorHelper } from '../../../../helpers/quill-editor.helper';
import { RouterHelper } from '../../../../helpers/router.helper';
import { SpinnerHelper } from '../../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../../helpers/toastr.helper';
import { BusinessArea } from '../../../../models/identity/business-area.model';
import { CommunityGroup } from '../../../../models/identity/community-group.model';
import { CommunityWithPrimary } from '../../../../models/identity/community-with-primary.model';
import { Community } from '../../../../models/identity/community.model';
import { GeographicalArea } from '../../../../models/identity/geographical-area.model';
import { OrganizationType } from '../../../../models/identity/organization-type.model';
import { ORGANIZATION_DESCRIPTION_MAX_LENGTH, ORGANIZATION_SKILLS_MAX, Organization } from '../../../../models/identity/organization.model';
import { UserPermissions } from '../../../../models/identity/user-permission.model';
import { CropperParams } from '../../../../models/shared/cropper-params.model';
import { Skill } from '../../../../models/skills/skill.model';
import { BusinessAreaService } from '../../../../services/identity/business-area.service';
import { CommunityService } from '../../../../services/identity/community.service';
import { GeographicalAreaService } from '../../../../services/identity/geographical-area.service';
import { OrganizationService } from '../../../../services/identity/organization.service';
import { ImageService } from '../../../../services/shared/image.service';
import { PermissionsService } from '../../../../services/shared/permissions.service';
import { PermissionsState } from '../../../../states/permissions/permissions.state';
import { FormMode } from '../../../enums/form-mode.enum';
import { ImageCropModalComponent } from '../../image-crop-modal/image-crop-modal.component';
import { SkillsComponent } from '../../skills/skills.component';

@Component({
    selector: 'app-organization-form[formMode]',
    templateUrl: './organization-form.component.html',
    styleUrls: ['./organization-form.component.scss'],
})
export class OrganizationFormComponent implements OnInit {
    @ViewChild(ImageCropModalComponent) public imageCropModal: ImageCropModalComponent;
    @ViewChild('skillsComponent', { static: false }) skillsComponent: SkillsComponent;
    @Select(PermissionsState.getPermissions) permissions$: Observable<UserPermissions>;
    private permissions: UserPermissions;
    public applicationCode = environment.settings.code;
    @Input() formMode: FormMode;
    @Input() id: string;

    picture = new FormControl(null);
    name = new FormControl(null, [Validators.maxLength(250), Validators.required]);
    active = new FormControl(null, [Validators.required]);
    address1 = new FormControl(null, [Validators.maxLength(250), Validators.required]);
    address2 = new FormControl(null, [Validators.maxLength(250)]);
    geographicalAreaId = new FormControl(null, [Validators.required]);
    city = new FormControl(null, [Validators.maxLength(50), Validators.required]);
    postalCode = new FormControl(null, [Validators.maxLength(50), Validators.required]);
    phoneNumber = new FormControl(null, [Validators.maxLength(21), Validators.pattern(regexPatterns.phoneNumber)]);
    email = new FormControl(null, [Validators.maxLength(180), Validators.pattern(regexPatterns.email)]);
    webSite = new FormControl(null, [Validators.maxLength(2048), Validators.pattern(regexPatterns.fullUrl)]);
    linkedIn = new FormControl(null, [Validators.maxLength(100), Validators.pattern(regexPatterns.LinkedInCompanyUrl)]);
    businessAreaId = new FormControl(null, [Validators.required]);
    organizationTypeId = new FormControl(null, [Validators.required]);
    description = new FormControl(null, [this.isDescriptionSizeCorrect()]);
    skills = new FormControl(null);
    communities = new FormControl(null);
    primaryCommunityId = new FormControl(null, [Validators.required]);
    communitiesGroups = new FormArray([]);
    location = new FormControl(null);

    form = new FormGroup({
        id: new FormControl(),
        picture: this.picture,
        name: this.name,
        active: this.active,
        address1: this.address1,
        address2: this.address2,
        geographicalAreaId: this.geographicalAreaId,
        city: this.city,
        location: this.location,
        postalCode: this.postalCode,
        phoneNumber: this.phoneNumber,
        email: this.email,
        webSite: this.webSite,
        linkedIn: this.linkedIn,
        businessAreaId: this.businessAreaId,
        organizationTypeId: this.organizationTypeId,
        description: this.description,
        skills: this.skills,
        communities: this.communities,
        primaryCommunityId: this.primaryCommunityId,
        communitiesGroups: this.communitiesGroups,
    });

    ORGANIZATION_SKILLS_MAX = ORGANIZATION_SKILLS_MAX;
    ORGANIZATION_DESCRIPTION_MAX_LENGTH = ORGANIZATION_DESCRIPTION_MAX_LENGTH;
    availableOrganizationTypes: OrganizationType[];
    availableBusinessAreas: BusinessArea[];
    availableGeographicalAreas: GeographicalArea[];
    availableCommunities: Community[];
    descriptionCounter = 0;
    filtersLoaded: Promise<boolean>;
    cropperParams = new CropperParams();
    file: File;
    imageUrl: string | ArrayBuffer;
    organizationPictureLink: string;
    public readonly EMPTY_BLOB_PICTURE = new Blob([], { type: 'image/png' });
    public dataLoaded$: Promise<boolean>;

    get selectedCommunities(): CommunityWithPrimary[] {
        return this.communities.value as CommunityWithPrimary[];
    }

    get selectedCommunitiesWithGroups(): Community[] {
        return this.selectedCommunities?.filter((community) => community.communitiesGroups?.length > 0);
    }

    get selectedPrimaryCommunityId(): string {
        return this.selectedCommunities?.find((community) => community.isPrimary)?.id;
    }

    get skillsAsModel(): Skill[] {
        return (this.skills.value || []) as Skill[];
    }

    get isMyOrganization(): boolean {
        return this.formMode === FormMode.EditMine;
    }

    get isPicture(): boolean {
        return this.picture.value || this.organizationPictureLink || (this.file && !this.EMPTY_BLOB_PICTURE);
    }

    get isAdmin(): boolean {
        return this.permissions?.admin || false;
    }

    constructor(
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly spinnerHelper: SpinnerHelper,
        private readonly toastrHelper: ToastrHelper,
        private readonly routerHelper: RouterHelper,
        private readonly permissionsService: PermissionsService,
        private readonly organizationService: OrganizationService,
        private readonly geographicalAreaService: GeographicalAreaService,
        private readonly businessAreaService: BusinessAreaService,
        private readonly communityService: CommunityService,
        public readonly imageService: ImageService,
        public readonly quillEditorHelper: QuillEditorHelper,
        private translate: TranslateService,
    ) {
        this.permissions$.subscribe((permissions) => (this.permissions = permissions));
        this.cropperParams.maxSizeString = this.imageService.getMaxSizeInString();
        this.cropperParams.isRatioFix = false;
    }

    async ngOnInit() {
        if ((this.formMode === FormMode.Edit || this.formMode === FormMode.EditMine) && !this.id) {
            throw new TypeError("Input [formMode]='FormMode.Edit*' requires Input [id]");
        }

        if (!this.permissionsService.canEditOrganization(this.id)) {
            this.toastrHelper.showError(this.translate.instant('toastMessages.noRightsError'));
            this.router.navigate(['/']);
        }

        const observables: any[] = [
            this.businessAreaService.getBusinessAreas(),
            this.organizationService.getOrganizationTypes(),
            this.geographicalAreaService.getGeographicalAreas(),
            this.communityService.getCommunities(),
        ];

        let results;
        try {
            results = await forkJoin(observables).toPromise();
            this.availableBusinessAreas = results[0] as BusinessArea[];
            this.availableOrganizationTypes = results[1] as OrganizationType[];
            this.availableGeographicalAreas = results[2] as GeographicalArea[];
            this.availableCommunities = results[3] as Community[];
        } catch (error) {
            this.toastrHelper.showGenericError(error);
        }

        if (this.id) {
            const organization = await this.organizationService.getOrganizationById(this.id).toPromise();
            organization.name = organization.organizationName;
            this.initForm(organization);
            this.dataLoaded$ = Promise.resolve(true);
        } else {
            this.dataLoaded$ = Promise.resolve(true);
        }

        if (this.formMode === FormMode.EditMine && environment?.settings?.disabledProperties?.organization?.length > 0) {
            const organizationDisabledProperties = environment.settings.disabledProperties.organization;
            Object.keys(this.form.controls).forEach((key) => {
                if (organizationDisabledProperties.includes(key)) {
                    this.form.controls[key].disable();
                }
            });
        }

        this.filtersLoaded = Promise.resolve(true);
    }

    onUpdateClick() {
        if (this.form.invalid) {
            if (this.city.invalid) {
                this.city.markAsTouched();
            }
            if (this.geographicalAreaId.invalid) {
                this.geographicalAreaId.markAsTouched();
            }
            if (this.address1.invalid) {
                this.address1.markAsTouched();
            }
            if (this.email.invalid) {
                this.email.markAsTouched();
            }
        }
    }

    initForm(organization: Organization) {
        this.form.patchValue(organization);
        this.primaryCommunityId.setValue(this.selectedPrimaryCommunityId);
        this.selectedCommunities.forEach((community) => {
            this.communitiesGroups.push(
                this.formBuilder.group({
                    id: [community.id],
                    communitiesGroups: this.formBuilder.control(community.communitiesGroups?.map((group) => group.id) || []),
                }),
            );
        });
        this.organizationPictureLink = organization.pictureAccessLink;
        this.descriptionCounter = this.quillEditorHelper.getPlainTextLength(organization.description || '');
        this.cropperParams.urlImage = this.organizationPictureLink;
    }

    getCommunityGroupsControls(community: Community): FormGroup {
        return this.formBuilder.group({
            id: [community.id],
            communitiesGroups: [null],
        });
    }

    onSubmit() {
        if (!this.form.valid) return;

        this.communities.setValue(this.selectedCommunities);
        const organizationFormData = this.form.getRawValue();

        organizationFormData.communities = organizationFormData.communities.map((community: Community) => ({
            id: community.id,
            name: community.name,
            displayName: community.displayName,
            isPrimary: community.id === organizationFormData.primaryCommunityId,
            communitiesGroups: this.getCommunityGroupById(community.id).filter((_: CommunityGroup) =>
                (organizationFormData.communitiesGroups.find((formGroup: any) => formGroup.id === community.id)?.communitiesGroups || []).includes(
                    _.id,
                ),
            ),
        }));

        organizationFormData.picture = this.file;
        organizationFormData.organizationId = organizationFormData.id;
        organizationFormData.organizationName = organizationFormData.name;
        organizationFormData.active = organizationFormData.active;

        switch (this.formMode) {
            case FormMode.Add:
                this.createOrganization(organizationFormData);
                break;
            case FormMode.EditMine:
            case FormMode.Edit:
                this.updateOrganization(organizationFormData);
                break;
        }

        this.imageService.initCropperValues();
    }

    createOrganization(organizationFormData: any) {
        if (!this.permissions.admin) return;

        this.organizationService.createOrganization(organizationFormData).subscribe(
            (data) => {
                const message = this.translate.instant('shared.forms.organization.organizationCreationSuccess');
                this.toastrHelper.showSuccess(message);
                this.router.navigate([`/administration/organizations/${data.id}`]);
            },
            ({ error }) => {
                if (error) {
                    this.toastrHelper.showGenericError(error);
                } else {
                    this.toastrHelper.showError("Erreur lors de la création de l'organisation, vérifiez votre saisie.");
                }
            },
        );
    }

    updateOrganization(organizationFormData: any) {
        this.spinnerHelper.showSpinner();
        this.organizationService.updateOrganization(organizationFormData).subscribe(
            (updatedOrganization: Organization) => {
                const message = this.translate.instant('shared.forms.organization.organizationUpdateSuccess');
                this.toastrHelper.showSuccess(message);
                const refresh = (updatedOrganization.communities || []).length >= (this.selectedCommunities || []).length;
                if (refresh) {
                    this.routerHelper.reloadCurrentPage(this.router);
                }
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }

    goBack() {
        window.history.back();
    }

    onDescriptionChanged(editor: any) {
        this.descriptionCounter = this.quillEditorHelper.getPlainTextLength(editor.container.innerHTML);
        this.quillEditorHelper.setQuillControlErrorNotRequired(this.description, this.descriptionCounter, ORGANIZATION_DESCRIPTION_MAX_LENGTH);
    }

    deletePicture() {
        this.file = <File>this.EMPTY_BLOB_PICTURE;
        this.picture.setValue(null);
        this.organizationPictureLink = null;
    }

    uploadFile(files: FileList) {
        if (files.length === 0) return;

        const picture = files.item(0);
        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) === null) {
            this.toastrHelper.showError(this.translate.instant('toastMessages.pictureExtensionError'));
            return;
        }

        if (picture.size > this.imageService.maxSize) {
            this.toastrHelper.showError(this.translate.instant('toastMessages.pictureSizeError'));
            return;
        }

        this.file = picture;

        const reader = new FileReader();
        reader.readAsDataURL(picture);
        reader.onload = () => {
            this.imageUrl = reader.result;
        };
    }

    getPictureUrl() {
        if (this.file) {
            return this.imageUrl;
        }
        return this.organizationPictureLink;
    }

    savePicture() {
        const format = this.imageService.getFormatFromBase64(this.imageService.croppedImage as string);

        if (this.imageService.isInBadFormat(format)) {
            return;
        }

        if (this.imageService.isExceedingPictureMaxSize(this.imageService.getImageSizeFromBase64(this.imageService.croppedImage as string))) {
            return;
        }

        this.picture.setValue(this.imageService.croppedImage as string);

        this.file = this.imageService.getImageInFile();
        const picture = this.imageService.getImageInFile();
        const reader = new FileReader();
        reader.readAsDataURL(picture);
        reader.onload = () => {
            this.imageUrl = reader.result;
        };
    }

    getCommunityNameById(communityId: string): string {
        return this.availableCommunities.find((community) => community.id === communityId)?.displayName;
    }

    getCommunityGroupById(communityId: string): CommunityGroup[] {
        return this.availableCommunities.find((community) => community.id === communityId)?.communitiesGroups;
    }

    isDescriptionSizeCorrect(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            const descriptionLengthWithoutImage = control.value?.replace(/<img src=".*?>/g, '')?.replace(/<.*?>/g, '')?.length || 0;
            return descriptionLengthWithoutImage <= ORGANIZATION_DESCRIPTION_MAX_LENGTH && descriptionLengthWithoutImage >= 0
                ? null
                : { error: 'Description trop longue (' + descriptionLengthWithoutImage + ')' };
        };
    }

    isAnyPrimaryCommunitySelected() {
        return !this.selectedCommunities.some((community) => community.isPrimary);
    }

    onChangedSkills(data: Skill[]) {
        this.skills.setValue(data);
    }

    onAddCommunity($event: { id: string }) {
        if (this.getCommunityGroupById($event.id)?.length > 0) {
            this.communitiesGroups.push(
                this.formBuilder.group({
                    id: this.formBuilder.control($event.id),
                    communitiesGroups: this.formBuilder.control([]),
                }),
            );
        }
    }

    onRemoveCommunity($event: { value: { id: string } }) {
        const communitiesGroups = this.communitiesGroups.getRawValue();
        for (let index = 0; index < communitiesGroups.length; index++) {
            if (communitiesGroups[index].id === $event.value.id) {
                this.communitiesGroups.removeAt(index);
                break;
            }
        }
        if (this.primaryCommunityId.value === $event.value.id) {
            this.primaryCommunityId.setValue(null);
        }
    }

    onClearAllCommunities() {
        this.communitiesGroups.clear();
        this.primaryCommunityId.setValue(null);
    }

    onAddressSelected(address: AddressResult) {
        if (!address?.position?.lat || !address?.position?.lon) {
            return;
        }

        const geoJsonPoint: GeoJsonPoint = {
            type: 'Point',
            coordinates: [address.position.lon, address.position.lat],
        };

        this.form.patchValue({
            city: address.address.municipality,
            address1: address.address.freeformAddress,
            postalCode: address.address.postalCode,
            location: geoJsonPoint,
        });
    }
}
