import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { projectStatus } from '../../../../constants/project-status';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-list-project',
    templateUrl: './list-project.component.html',
})
export class ListProjectComponent implements OnInit {
    projectStatus = projectStatus;
    activeFragment: string;

    dictionary = environment.dictionary;

    constructor(private readonly route: ActivatedRoute) {}

    ngOnInit() {
        this.route.fragment.subscribe((fragment) => (this.activeFragment = fragment || projectStatus.published));
    }
}
