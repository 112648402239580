import { environment } from '../../../environments/environment';
import { GeoJsonPoint } from '../../azure/map/models';
import { Skill } from '../skills/skill.model';
import { BasicUser } from './basic-user.model';
import { BusinessArea } from './business-area.model';
import { CommunityWithPrimary } from './community-with-primary.model';
import { GeographicalArea } from './geographical-area.model';
import { OrganizationBase } from './organization-base.model';
import { OrganizationType } from './organization-type.model';

export class Organization extends OrganizationBase {
    public address1: string;
    public address2: string;
    public businessArea: BusinessArea;
    public businessAreaId: string;
    public city: string;
    public location: GeoJsonPoint;
    public communities: CommunityWithPrimary[];
    public description: string;
    public email: string;
    public geographicalArea: GeographicalArea;
    public geographicalAreaId: string;
    public linkedIn: string;
    public organizationType: OrganizationType;
    public organizationTypeId: string;
    public phoneNumber: string;
    public pictureAccessLink: string;

    public members: BasicUser[];
    public pictureReference: string;
    public postalCode: string;
    public primaryCommunity: CommunityWithPrimary;
    public skills: Skill[];
    public webSite: string;
}

export class ResponseFromBatchInsertOrganization {
    public errors: { [key: string]: string } = {};
    public rapport: string;
    public success: { [key: string]: string } = {};
}

export const ORGANIZATION_DESCRIPTION_MAX_LENGTH = 5000;
export const ORGANIZATION_SKILLS_MAX = environment.modelsConst.ORGANIZATION_SKILLS_MAX;
