<p-dialog
    [modal]="true"
    [(visible)]="visible"
    [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    (onHide)="onHide()"
>
    <iframe
        id="ytplayer"
        type="text/html"
        width="100%"
        height="360"
        [src]="videoSafeUrl"
        frameborder="0"
        allowfullscreen
    ></iframe>
</p-dialog>
