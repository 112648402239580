import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseEventCommand } from '../../models/events/base-event-command.model';
import { CreateEventCommand } from '../../models/events/create-event-command.model';
import { EventFilters } from '../../models/events/event-filters.model';
import { Event } from '../../models/events/event.model';
import { MyEventsFilters } from '../../models/events/my-event-filters.model';
import { ReportEventCommand } from '../../models/events/report-event-command.model';
import { UpdateEventCommand } from '../../models/events/update-event-command.model';

type FormDataValue = string | Blob | null | undefined;
type CommandRecord<T> = Record<keyof T, (formData: FormData, propertyKey: string) => void>;

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    private readonly eventsApiUrl = `${environment.microservices.projects.baseUrl}/${environment.microservices.projects.eventsApi.baseUrl}`;
    private readonly myEventsApiUrl = `${environment.microservices.projects.baseUrl}/${environment.microservices.projects.eventsApi.myEventsBaseUrl}`;

    private readonly eventExistsApiUrl = `${this.eventsApiUrl}/${environment.microservices.projects.eventsApi.exist}`;
    private readonly filteredEventsApiUrl = `${this.eventsApiUrl}/${environment.microservices.projects.eventsApi.filters}`;
    private readonly myFilteredEventsApiUrl = `${this.myEventsApiUrl}/${environment.microservices.projects.eventsApi.filters}`;

    constructor(private http: HttpClient) {}

    public doesEventExist(name: string): Observable<HttpResponse<void>> {
        return this.http.get<void>(`${this.eventExistsApiUrl}/${name}`, { observe: 'response' });
    }

    public getEventById(id: string): Observable<Event> {
        return this.http.get<Event>(`${this.eventsApiUrl}/${id}`);
    }

    public getEvents(): Observable<Event[] | null> {
        return this.http.get<Event[] | null>(this.eventsApiUrl);
    }

    public getFilteredEvents(filters: EventFilters): Observable<Event[]> {
        return this.http.post<Event[]>(`${this.filteredEventsApiUrl}`, filters);
    }

    public getMyFilteredEvents(filters: MyEventsFilters): Observable<Event[]> {
        return this.http.post<Event[]>(`${this.myFilteredEventsApiUrl}`, filters);
    }

    public createAndPublishEvent(command: CreateEventCommand): Observable<unknown> {
        return this.http.post(this.eventsApiUrl, this.getFormData(command));
    }

    public updateEvent(command: UpdateEventCommand): Observable<unknown> {
        return this.http.put(this.myEventsApiUrl, this.getFormData(command));
    }

    public cancelEvent(eventId: string): Observable<unknown> {
        return this.http.post(`${this.myEventsApiUrl}/${eventId}/${environment.microservices.projects.eventsApi.close}`, {});
    }

    public joinEvent(eventId: string): Observable<unknown> {
        return this.http.post(`${this.eventsApiUrl}/${eventId}/Join`, null);
    }

    public leaveEvent(eventId: string): Observable<unknown> {
        return this.http.post(`${this.eventsApiUrl}/${eventId}/Leave`, null);
    }
    public report(command: ReportEventCommand): Observable<string> {
        return this.http.post<string>(`${this.eventsApiUrl}/report`, command);
    }

    public deleteEvent(eventId: string) {
        return this.http.delete(`${this.eventsApiUrl}/${eventId}`);
    }

    private defaultAppendAction<T>(formData: FormData, propertyKey: keyof T, propertyValue: FormDataValue): void {
        if (propertyValue === null || propertyValue === undefined) {
            return;
        }

        formData.append(propertyKey.toString(), propertyValue);
    }

    private getFormData(command: BaseEventCommand): FormData {
        const commandRecord: CommandRecord<BaseEventCommand> = {
            name: (fd, pk) => this.defaultAppendAction(fd, pk, command.name),
            startDate: (fd, pk) => this.defaultAppendAction(fd, pk, command.startDate),
            endDate: (fd, pk) => this.defaultAppendAction(fd, pk, command.endDate),
            description: (fd, pk) => this.defaultAppendAction(fd, pk, command.description),
            type: (fd, pk) => this.defaultAppendAction(fd, pk, command.type.toString()),
            accessType: (fd, pk) => this.defaultAppendAction(fd, pk, command.accessType.toString()),
            eventLink: (fd, pk) => this.defaultAppendAction(fd, pk, command.eventLink),
            address: (fd, pk) => this.defaultAppendAction(fd, pk, command.address),
            addressComplement: (fd, pk) => this.defaultAppendAction(fd, pk, command.addressComplement),
            maxAttendeesCount: (fd, pk) => this.defaultAppendAction(fd, pk, command.maxAttendeesCount?.toString()),
            picture: (fd, pk) => this.defaultAppendAction(fd, pk, command.picture),
            organizationVisibility: (fd, pk) => this.defaultAppendAction(fd, pk, command.organizationVisibility.toString()),
            organization: (fd, _) => {
                if (command.organization) {
                    fd.append(`organization.id`, command.organization.id);
                    fd.append(`organization.name`, command.organization.name);
                }
            },
            allCommunitiesVisibility: (fd, pk) => this.defaultAppendAction(fd, pk, command.allCommunitiesVisibility.toString()),
            communities: (fd, pk) => {
                if (command.communities && command.communities.length > 0) {
                    command.communities.forEach((community, index) => {
                        fd.append(`communities[${index}][id]`, community.id);
                        fd.append(`communities[${index}][name]`, community.name);
                        fd.append(`communities[${index}][displayName]`, community.displayName);
                    });
                }
            },
            registrationLink: (fd, pk) => this.defaultAppendAction(fd, pk, command.registrationLink),
        };

        if (command instanceof UpdateEventCommand) {
            (commandRecord as CommandRecord<UpdateEventCommand>)['id'] = (fd, pk) => this.defaultAppendAction(fd, pk, command.id);
        }

        return this.getFormDataFromRecord<BaseEventCommand>(commandRecord);
    }

    private getFormDataFromRecord<T>(record: CommandRecord<T>): FormData {
        const formData = new FormData();

        for (const property in record) {
            record[<keyof T>property](formData, property);
        }

        return formData;
    }
}
