import { Community } from './../models/identity/community.model';
import { CommunityService } from './../services/identity/community.service';
import { AccountsService } from './../services/identity/accounts.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-community',
    templateUrl: './community.component.html',
})
export class CommunityComponent implements OnInit {
    public communities: Community[];

    activeFragment: string;

    constructor(
        public router: Router,
        protected readonly accountsService: AccountsService,
        protected readonly communityService: CommunityService,
    ) {}

    ngOnInit() {
        if (this.accountsService.isAdmin()) {
            this.communityService.getCommunities().subscribe((communities) => {
                this.communities = communities;
            });
        } else {
            this.communities = this.accountsService.getCommunitiesManagedByUser();
        }
    }
}
