import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { UpdatePermissions } from './permissions.actions';
import { UserPermissions } from '../../models/identity/user-permission.model';

export class PermissionsStateModel {
    permissions: UserPermissions;
}

@State<PermissionsStateModel>({
    name: 'permissions',
    defaults: {
        permissions: new UserPermissions(),
    },
})
@Injectable()
export class PermissionsState {
    @Selector()
    static getPermissions(state: PermissionsStateModel) {
        return state.permissions;
    }

    @Action(UpdatePermissions)
    update({ patchState }: StateContext<PermissionsStateModel>, { payload }: UpdatePermissions) {
        patchState({
            permissions: payload,
        });
    }
}
