import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { projectStatus } from '../../../constants/project-status';
import { regexPatterns } from '../../../constants/regex-patterns';
import { QuillEditorHelper } from '../../../helpers/quill-editor.helper';
import { SpinnerHelper } from '../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { UserPermissions } from '../../../models/identity/user-permission.model';
import { BaseProjectCommand } from '../../../models/projects/base-project-command.model';
import { CollaborationPlatformTypeEnum } from '../../../models/projects/collaboration-platform-type.enum.model';
import { ProjectLight } from '../../../models/projects/project-light.model';
import { ProjectType } from '../../../models/projects/project-type.model';
import {
    PROJECT_CONTEXT_MAX_LENGTH,
    PROJECT_CONTRIBUTIONS_MAX_LENGTH,
    PROJECT_DESCRIPTION_MAX_LENGTH,
    PROJECT_GOAL_MAX_LENGTH,
    PROJECT_SYNTHESIS_MAX_LENGTH,
} from '../../../models/projects/project.model';
import { CropperParams } from '../../../models/shared/cropper-params.model';
import { AccountsService } from '../../../services/identity/accounts.service';
import { ProjectsService } from '../../../services/projects/projects.service';
import { ImageService } from '../../../services/shared/image.service';
import { ImageCropModalComponent } from '../../../shared/components/image-crop-modal/image-crop-modal.component';
import { PermissionsState } from '../../../states/permissions/permissions.state';

@Component({
    selector: 'app-project-form-step-two',
    templateUrl: './project-form-step-two.component.html',
    styleUrls: ['./project-form-step-two.component.scss'],
})
export class ProjectFormStepTwoComponent implements OnInit {
    public PROJECT_CONTEXT_MAX_LENGTH = PROJECT_CONTEXT_MAX_LENGTH;
    public PROJECT_CONTRIBUTIONS_MAX_LENGTH = PROJECT_CONTRIBUTIONS_MAX_LENGTH;
    public PROJECT_DESCRIPTION_MAX_LENGTH = PROJECT_DESCRIPTION_MAX_LENGTH;
    public PROJECT_GOAL_MAX_LENGTH = PROJECT_GOAL_MAX_LENGTH;
    public PROJECT_SYNTHESIS_MAX_LENGTH = PROJECT_SYNTHESIS_MAX_LENGTH;
    public collaborationPlatformTypeEnum = CollaborationPlatformTypeEnum;
    public contextCounter = 0;
    public contributionsCounter = 0;
    public cropperParams: CropperParams = new CropperParams();
    public descriptionCounter = 0;
    public dictionary = environment.dictionary;
    @ViewChild('draftStepOneModal3', { static: false })
    public draftStepOneModal: TemplateRef<any>;
    public file: File;
    // Child Components
    @ViewChild(ImageCropModalComponent)
    public imageCropModal: ImageCropModalComponent;
    public imageUrl: string | ArrayBuffer;
    @Input() public isAdmin: boolean;
    @Input() public isDraft: boolean;
    @Input() public isEditMode: boolean;
    @Select(PermissionsState.getPermissions)
    permissions$: Observable<UserPermissions>;
    public previousCollaborativePlatform: CollaborationPlatformTypeEnum;
    public previousIsAllCommunitiesVisibilityChecked: boolean;
    @Output() public previousStep = new EventEmitter();
    @Input() public projectCommand: BaseProjectCommand;
    @Input() public projectLight: ProjectLight;
    // For edit mode.
    @Input() public projectPictureLink: string;
    @Input() public projectTypes: ProjectType[];
    @Output() public saved = new EventEmitter();
    public stepTwoForm = this.formBuilder.group({
        context: ['', Validators.required],
        goals: this.formBuilder.array([
            this.formBuilder.control('', Validators.maxLength(100)),
            this.formBuilder.control('', Validators.maxLength(100)),
            this.formBuilder.control('', Validators.maxLength(100)),
            this.formBuilder.control('', Validators.maxLength(100)),
            this.formBuilder.control('', Validators.maxLength(100)),
        ]),
        contributions: ['', Validators.required],
        synthesis: ['', [Validators.required, Validators.maxLength(200)]],
        description: ['', Validators.required],
        collaborationPlatformType: [CollaborationPlatformTypeEnum[CollaborationPlatformTypeEnum.None], Validators.required],
        collaborationPlatformUrl: [''],
    });
    @Output() public submitted = new EventEmitter();
    public title = environment.settings.title;

    constructor(
        public readonly quillEditorHelper: QuillEditorHelper,
        private readonly formBuilder: FormBuilder,
        private readonly toastrHelper: ToastrHelper,
        private readonly modalService: NgbModal,
        private readonly projectService: ProjectsService,
        public readonly imageService: ImageService,
        private readonly router: Router,
        private readonly spinnerHelper: SpinnerHelper,
        public readonly accountService: AccountsService,
        private translate: TranslateService,
    ) {}

    public get canUserAddAProjectClub(): boolean {
        const canCreateClub = this.accountService.currentUser.primaryCommunity.canCreateClub;
        const isCommunityManager = this.accountService.isUserManagingCommunity(this.accountService.currentUser.primaryCommunity);

        return canCreateClub || isCommunityManager;
    }

    public get featureClubProjectAvailable(): boolean {
        const isFeatureEnabled = !environment.features.clubProjectDisabledCommunitiesIds.includes(this.accountService.getUserPrimaryCommunity().id);

        return this.canUserAddAProjectClub && isFeatureEnabled;
    }

    public get goals() {
        return this.stepTwoForm.controls.goals as FormArray;
    }

    public get isAllCommunitiesVisibility(): boolean {
        return this.projectCommand.allCommunitiesVisibility ?? false;
    }

    public checkFormValidity() {
        if (this.stepTwoForm.invalid) {
            const msg = this.translate.instant('shared.forms.errors.checkAndRetry');
            this.toastrHelper.showError(msg);
            return false;
        }

        if (
            this.stepTwoForm.controls.collaborationPlatformType.value ==
                this.collaborationPlatformTypeEnum[this.collaborationPlatformTypeEnum.Mine] &&
            !(
                this.stepTwoForm.controls.collaborationPlatformUrl.value &&
                this.stepTwoForm.controls.collaborationPlatformUrl.value.match(regexPatterns.fullUrl)
            )
        ) {
            const msg = this.translate.instant('projects.formStep2.collaborationPlatform.required');
            this.toastrHelper.showError(msg);
            return false;
        }

        if (this.isGoalsInvalid()) {
            const msg = this.translate.instant('projects.formStep2.goals.required', {
                maxLength: PROJECT_GOAL_MAX_LENGTH,
            });
            this.toastrHelper.showError(msg);
            return false;
        }

        if (!this.file && !this.projectPictureLink) {
            const msg = this.translate.instant('projects.formStep2.picture.required', {
                maxLength: PROJECT_GOAL_MAX_LENGTH,
            });
            this.toastrHelper.showError(msg);
            return false;
        }

        return true;
    }

    public deleteProject() {
        this.spinnerHelper.showSpinner();
        this.projectService.disableProject(this.projectLight.id).subscribe(
            () => {
                const msg = this.translate.instant('projects.formStep2.toast.projectDeleted');
                this.toastrHelper.showSuccess(msg);
                const url = this.isAdmin ? '/administration/projects' : '/profile/my-projects';
                this.router.navigate([url]);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }

    public getFilledGoalsCount() {
        return this.goals.controls.filter((goal) => goal.value.length > 0).length;
    }

    public getPictureUrl() {
        if (this.file) {
            return this.imageUrl;
        }

        return this.projectPictureLink;
    }

    public goToPreviousStep() {
        this.updateProjectCommandFromStepTwoComponent();
        this.previousStep.emit();
    }

    public isGoalsInvalid() {
        return !this.goals.controls.some((goal) => goal.value.length > 0) || this.goals.controls.some((goal) => goal.value.length > 100);
    }

    public isRemovable() {
        return this.projectLight?.status === projectStatus.draft || this.projectLight?.status === projectStatus.unpublished;
    }

    public ngOnInit() {
        if (this.projectCommand) {
            this.stepTwoForm.controls.context.setValue(this.projectCommand.context || '');
            this.contextCounter = this.quillEditorHelper.getPlainTextLength(this.projectCommand.context);

            if (this.projectCommand.goals) {
                this.projectCommand.goals.forEach((goal, index) => this.goals.controls[index].setValue(goal));
            }

            this.stepTwoForm.controls.contributions.setValue(this.projectCommand.contributions || '');
            this.contributionsCounter = this.quillEditorHelper.getPlainTextLength(this.projectCommand.contributions);

            this.stepTwoForm.controls.synthesis.setValue(this.projectCommand.synthesis || '');

            this.stepTwoForm.controls.description.setValue(this.projectCommand.description || '');
            this.descriptionCounter = this.quillEditorHelper.getPlainTextLength(this.projectCommand.description);

            const collaborationPlatformType =
                this.projectCommand?.collaborationPlatformType != null
                    ? this.projectCommand.collaborationPlatformType
                    : this.featureClubProjectAvailable
                    ? CollaborationPlatformTypeEnum.Club
                    : CollaborationPlatformTypeEnum.None;

            this.stepTwoForm.controls.collaborationPlatformType.setValue(CollaborationPlatformTypeEnum[collaborationPlatformType]);

            this.stepTwoForm.controls.collaborationPlatformUrl.setValue(this.projectCommand.collaborationPlatformUrl || '');
        }
        this.cropperParams.ratio = 1080 / 300;
        this.cropperParams.isRatioFix = true;
        this.cropperParams.resizeToHeight = 300;
        this.cropperParams.resizeToWidth = 1080;
        this.cropperParams.urlImage = this.projectPictureLink;
        this.cropperParams.maxSizeString = this.imageService.getMaxSizeInString();
        this.previousCollaborativePlatform = this.projectCommand.collaborationPlatformType;
        this.previousIsAllCommunitiesVisibilityChecked = this.isAllCommunitiesVisibility;
    }

    public onClose() {
        this.modalService.dismissAll();
    }

    public onContextChanged(editor: any) {
        this.contextCounter = this.quillEditorHelper.getPlainTextLength(editor.container.innerHTML);
        this.quillEditorHelper.setQuillControlError(this.stepTwoForm.controls.context, this.contextCounter, PROJECT_CONTEXT_MAX_LENGTH);
    }

    public onContributionsChanged(editor: any) {
        this.contributionsCounter = this.quillEditorHelper.getPlainTextLength(editor.container.innerHTML);
        this.quillEditorHelper.setQuillControlError(
            this.stepTwoForm.controls.contributions,
            this.contributionsCounter,
            PROJECT_CONTRIBUTIONS_MAX_LENGTH,
        );
    }

    public onDescriptionChanged(editor: any) {
        this.descriptionCounter = this.quillEditorHelper.getPlainTextLength(editor.container.innerHTML);
        this.quillEditorHelper.setQuillControlError(this.stepTwoForm.controls.description, this.descriptionCounter, PROJECT_DESCRIPTION_MAX_LENGTH);
    }

    public onSave() {
        this.onClose();
        this.saved.emit();
    }

    public onSubmit() {
        if (this.checkFormValidity()) {
            this.updateProjectCommandFromStepTwoComponent();
            this.submitted.emit();
        }
    }

    public openConfirmePopupSaveDraft() {
        //if (this.checkFormValidity()) {
        this.modalService.open(this.draftStepOneModal, {
            size: 'lg',
            scrollable: false,
            backdrop: 'static',
        });
        //}
    }

    public savePicture() {
        const format = this.imageService.getFormatFromBase64(this.imageService.croppedImage as string);

        if (this.imageService.isInBadFormat(format)) {
            this.toastrHelper.showError(this.translate.instant('toastMessages.pictureExtensionError'));
            return;
        }

        // 625000 is the number of bits in 5Mb.
        if (this.imageService.isExceedingPictureMaxSize(this.imageService.getImageSizeFromBase64(this.imageService.croppedImage as string))) {
            this.toastrHelper.showError(this.translate.instant('toastMessages.profilePictureSizeError'));
            return;
        }

        this.file = this.imageService.getImageInFile();
        const picture = this.imageService.getImageInFile();
        const reader = new FileReader();
        reader.readAsDataURL(picture);
        reader.onload = () => {
            this.imageUrl = reader.result;
        };

        this.imageService.initCropperValues();
    }

    public updateProjectCommandFromStepTwoComponent() {
        const command: BaseProjectCommand = this.stepTwoForm.value;
        this.projectCommand.context = command.context?.trim();
        this.projectCommand.goals = command.goals.filter((goal) => goal.trim().length > 0).map((goal) => goal.trim());
        this.projectCommand.contributions = command.contributions?.trim();
        this.projectCommand.synthesis = command.synthesis?.trim();
        this.projectCommand.description = command.description?.trim();
        this.projectCommand.picture = this.file;
        this.projectCommand.picturePreviewUrl = this.getPictureUrl();
        this.projectCommand.collaborationPlatformType = command.collaborationPlatformType;
        this.projectCommand.collaborationPlatformUrl = command.collaborationPlatformUrl.trim();
    }

    public uploadFile(files: FileList) {
        if (files.length === 0) {
            return;
        }

        // Take only one image.
        const picture = files.item(0);
        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) === null) {
            this.toastrHelper.showError(this.translate.instant('toastMessages.pictureExtensionError'));
            return;
        }

        if (picture.size > this.imageService.maxSize) {
            const msg = this.translate.instant('projects.formStep2.toast.pictureSize', { maxSizeMB: 2 });
            this.toastrHelper.showError(msg);
            return;
        }

        this.file = picture;

        const reader = new FileReader();
        reader.readAsDataURL(picture);
        reader.onload = () => {
            this.imageUrl = reader.result;
        };
    }
}
