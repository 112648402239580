import { Component } from '@angular/core';
import {
    EventStatusFilterEnum,
    EventStatusFilterEnumLabelMap,
} from '../../../../models/events/event-status-filter.enum';
import { FiltersItem } from '../../../../models/shared/filters-item.model';
import { BaseFiltersCheckbox } from '../../../shared/base-filters-checkbox/base-filters-checkbox.directive';

@Component({
    selector: 'app-owned-events-ongoing-checkbox',
    templateUrl: './owned-events-ongoing-checkbox.component.html',
    providers: [{ provide: BaseFiltersCheckbox, useExisting: OwnedEventsOngoingCheckboxComponent }],
})
export class OwnedEventsOngoingCheckboxComponent extends BaseFiltersCheckbox {
    protected override createFilterItem(): FiltersItem {
        const value = EventStatusFilterEnum.IsOngoing;
        return new FiltersItem(
            `Owned.${EventStatusFilterEnum[value]}`,
            this.wtranslate.transform(EventStatusFilterEnumLabelMap[value], 'eventStatuses'),
        );
    }
}
