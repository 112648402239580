import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    SubscriptionType,
    SUBSCRIPTION_TYPE_ACCESS,
    SUBSCRIPTION_TYPE_BUSINESS,
    SUBSCRIPTION_TYPE_GRID,
    SUBSCRIPTION_TYPE_OPEN,
    SUBSCRIPTION_TYPE_UNDEFINED,
} from '../../models/identity/subscription-type';

@Injectable({ providedIn: 'root' })
export class SubscriptionTypeService {
    // private userTypeApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.plansApi.baseUrl}`;
    // constructor(private http: HttpClient) {}

    subscriptionTypes: SubscriptionType[];

    constructor() {
        this.subscriptionTypes = [
            {
                id: SUBSCRIPTION_TYPE_UNDEFINED,
                label: 'Aucun plan',
            } as SubscriptionType,
            {
                id: SUBSCRIPTION_TYPE_OPEN,
                label: 'Open',
            } as SubscriptionType,
            {
                id: SUBSCRIPTION_TYPE_BUSINESS,
                label: 'Business',
            } as SubscriptionType,
            {
                id: SUBSCRIPTION_TYPE_GRID,
                label: 'Grid',
            } as SubscriptionType,
            {
                id: SUBSCRIPTION_TYPE_ACCESS,
                label: 'Access',
            } as SubscriptionType,
        ];
    }

    getSubscriptionTypes(): Observable<SubscriptionType[]> {
        return of(this.subscriptionTypes);
        // return this.http.get<Plan[]>(this.userTypeApiUrl);
    }
}
