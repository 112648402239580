import { Component, Input } from '@angular/core';
import { ClubsStore } from '../../clubs.store';
import { TranslateService } from '@ngx-translate/core';
import { Club } from '../../../models/clubs/club.model';

@Component({
    selector: 'app-club-more-info-modal',
    styleUrls: ['./app-club-more-info-modal.component.scss'],
    templateUrl: './app-club-more-info-modal.component.html',
})
export class ClubMoreInfoModalComponent {
    isModalVisible = false;

    @Input() club: Club = null;

    constructor(public readonly clubsStore: ClubsStore, public readonly translate: TranslateService) {}

    toggleModal(club: Club = null) {
        if (club) {
            this.club = club;
        }

        this.isModalVisible = !this.isModalVisible;
    }
    getClubType(club: Club) {
        if (club.communityId) {
            return this.translate.instant('clubs.page.common.clubTypes.communityClub');
        }

        if (club.projectId) {
            return this.translate.instant('clubs.page.common.clubTypes.projectClub');
        }

        return this.translate.instant('clubs.page.common.clubTypes.simpleClub');
    }
}
