import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-nav-directory',
    templateUrl: './nav-directory.component.html',
    styleUrls: ['./nav-directory.component.scss'],
})
export class NavDirectoryComponent implements OnInit {
    isExpanded = false;

    dictionary = environment.dictionary;

    organizationDirectoryAvailable = environment.features.organizationDirectoryAvailable;

    constructor() {}

    ngOnInit(): void {}
    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}
