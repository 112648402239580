/* eslint-disable import/extensions */
import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'wudo-image-cropper',
    templateUrl: './wudo-image-cropper.component.html',
    styleUrls: ['./wudo-image-cropper.component.scss'],
})
export class WudoImageCropperComponent {
    croppedImage: any = '';

    @Input()
    imageURL: string; // The file you want to change (set to null to reset the cropper)

    @Input()
    format = 'webp'; // Output format (png, jpeg, webp, bmp, ico) (not all browsers support all types, png is always supported, others are optional)

    @Input()
    aspectRatio = 1; // The width / height ratio (e.g. 1 / 1 for a square, 4 / 3, 16 / 9 ...)

    @Input()
    maintainAspectRatio = true; // Keep width and height of cropped image equal according to the aspectRatio

    @Input()
    resizeToWidth: number; // Cropped image will be resized to at most this width (in px)

    @Input()
    resizeToHeight: number; // Cropped image will be resized to at most this height (in px)

    imageChangedEvent: any; // The change event from your file input (set to null to reset the cropper)

    @Input()
    displayedMaximalSize = '2 Mo';

    @Input()
    acceptedFormats = ['.png', '.jpeg', '.jpg', '.webp'];

    get imageURLGetter(): string {
        return this.imageChangedEvent ? null : this.imageURL;
    }

    get hasImage() {
        return this.imageChangedEvent || this.imageURL;
    }

    get croppedImageAsFileBlob() {
        return <File>base64ToFile(this.croppedImage);
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    fileDroppedEvent(event: any): void {
        const newEvent = {
            isTrusted: true,
            target: { accept: 'image/*', files: event },
        };
        this.imageChangedEvent = newEvent;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
}
