<!-- NAV DIRECTORY -->
<nav class="navbar navbar-expand-lg navbar-light subnav justify-content-center">
    <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active" *ngIf="organizationDirectoryAvailable">
            <a class="nav-link" [routerLink]="['/directory/organizations']">{{
                'directory.nav.organizationDirectory' | translate
            }}</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/directory/members']">{{
                'directory.nav.userDirectory' | translate
            }}</a>
        </li>
    </ul>
</nav>
<!-- /nav directory -->
