<form
    (change)="onChange()"
    [formGroup]="form"
    novalidate
    ngDefaultControl
    class="p-m-auto p-d-flex p-flex-column p-fluid"
>
    <h5 class="bold">{{ 'community.nav.theme.externalLinkTitle' | translate }}</h5>
    <span class="description-label p-p-2 p-mt-1 p-mb-2">
        {{ 'community.nav.theme.externalLinkDescription' | translate }}
    </span>

    <div class="p-grid">
        <div class="p-col-4">
            <h6 class="bold">{{ 'community.nav.theme.externalLinkLabel' | translate }}</h6>
        </div>
        <div class="p-col-8">
            <h6 class="bold">{{ 'community.nav.theme.externalLinkUrl' | translate }}</h6>
        </div>
    </div>

    <div formArrayName="externalLinks">
        <div *ngFor="let link of externalLinks.controls; let i = index">
            <div class="p-grid" [formGroupName]="i">
                <div class="p-col-4">
                    <input
                        formControlName="name"
                        type="text"
                        pInputText
                        placeholder="{{ 'community.nav.theme.externalLinkInputName' | translate }}"
                    />
                </div>
                <div class="p-col-8">
                    <div class="p-inputgroup">
                        <input formControlName="url" type="text" pInputText placeholder="https://" />
                        <button
                            pButton
                            type="button"
                            class="p-button-rounded p-button-danger p-button-text p-button-sm"
                            icon="pi pi-times"
                            (click)="removeExternalLink(i)"
                            style="margin-top: auto; margin-bottom: auto"
                        ></button>
                    </div>
                </div>
            </div>
        </div>
        <button
            class="btn btn-primary"
            (click)="addExternalLink()"
            [disabled]="externalLinks.length >= 5 || !form.valid"
        >
            {{ 'community.nav.theme.externalLinkAdd' | translate }}
        </button>
    </div>
</form>
