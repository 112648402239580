export const userTypes = {
    user: 'User',
    guest: 'Guest',
    communityManager: 'CommunityManager',
    admin: 'Admin',
    organizationLeader: 'OrganizationLeader',
    communityAnimator: 'CommunityAnimator',
    communityInfluencer: 'CommunityInfluencer',
};

export const rights = {
    // Globals rigths
    LEGAL_DOCUMENT_VIEW: 'LEGALDOCUMENTVIEW',
    DIRECTORY_VIEW: 'DIRECTORYVIEW',

    // Community rights
    ADMIN_PROJECT_PUBLISH: 'ADMINPROJECTPUBLISH',
    ADMIN_PROJECT_VIEW: 'ADMINPROJECTVIEW',
    ADMIN_COMMUNITY_MANAGE: 'ADMINCOMMUNITYMANAGE',

    // Organization rights
    ADMIN_ORGANIZATION_EDIT: 'ADMINORGANIZATIONEDIT',
    LAB_SERVICE_CREATE: 'LABSERVICECREATE',
};
