import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { userSortModes } from '../../../../constants/user-list-filters';
import { ToastrHelper } from '../../../../helpers/toastr.helper';
import { CommunityFilter } from '../../../../models/identity/community-filter.model';
import { CommunityGroup } from '../../../../models/identity/community-group.model';
import { CommunityWithRoles } from '../../../../models/identity/community-with-roles.model';
import { Community, CommunityCompute } from '../../../../models/identity/community.model';
import { Filters } from '../../../../models/identity/filters.model';
import { Organization } from '../../../../models/identity/organization.model';
import {
    SUBSCRIPTION_TYPE_BUSINESS,
    SUBSCRIPTION_TYPE_OPEN,
    SubscriptionType,
} from '../../../../models/identity/subscription-type';
import { RoleType, UserType } from '../../../../models/identity/user-type.model';
import { ResponseFromAccountActivationFollowUp, User } from '../../../../models/identity/user.model';
import { AccountsService } from '../../../../services/identity/accounts.service';
import { CommunityService } from '../../../../services/identity/community.service';
import { OrganizationService } from '../../../../services/identity/organization.service';
import { ProfileService } from '../../../../services/identity/profile.service';
import { SubscriptionTypeService } from '../../../../services/identity/subscription-type-service';
import { UserTypeService } from '../../../../services/identity/user-type.service';
import { UserService } from '../../../../services/identity/user.service';

@Component({
    selector: 'app-community-members',
    templateUrl: './community-members.component.html',
    styleUrls: ['./community-members.component.scss'],
})
export class CommunityMembersComponent implements OnInit {
    public importUsersAvailable = false;
    public exportUsersAvailable = false;
    public massEditUsersAvailable = false;

    userTypes: UserType[];
    availableSubscriptionType: SubscriptionType[];
    communityCompute: CommunityCompute;
    availableSubscriptionTypeCompute: number[] = [SUBSCRIPTION_TYPE_OPEN, SUBSCRIPTION_TYPE_BUSINESS];
    members: User[];
    selectedMembers: User[] = [];
    organizations: Organization[];
    loading = true;
    selectedCommunityId: string;
    selectedCommunityGroups: CommunityGroup[];
    pageSize = 10;
    page = 1;
    nbUsersTotalInCommunity: number;
    sortMode: string = userSortModes.lastName;
    sortOrder = 1;
    allSelectionMode = false;
    roleTypes = RoleType;
    userCurentlyEditedIds: string[] = [];
    readonly memberImportFileUrl = environment.memberImportFileUrl;
    dialogVisible = false;
    csvfileForm = this.fb.group({ file: [null, [Validators.required]] });
    importStep = 1;
    csvFile: File;
    csvUsers: User[];
    isSendingAccountActivationFollowUpNotification: boolean;
    public communities: Community[];
    manageUsersItems: MenuItem[];
    allUsersFollowUpModalVisible = false;
    selectedUsersFollowUpModalVisible = false;

    get nextButtonLabel(): string {
        if (this.importStep === 2) {
            return "Terminer l'import";
        }
        return 'Suivant';
    }

    get nextStepButtonDisabled(): boolean {
        switch (this.importStep) {
            case 1:
                return this.csvfileForm.invalid;
            case 2:
                return !this.csvUsers;
            default:
                return false;
        }
    }

    constructor(
        protected readonly accountsService: AccountsService,
        protected readonly profileService: ProfileService,
        private readonly fb: FormBuilder,
        protected readonly userService: UserService,
        protected readonly organizationService: OrganizationService,
        protected readonly userTypeService: UserTypeService,
        protected readonly subscriptionTypeService: SubscriptionTypeService,
        protected readonly communityService: CommunityService,
        private readonly toastrHelper: ToastrHelper,
        private translate: TranslateService,
    ) {
        this.importUsersAvailable = accountsService.isAdmin()
            ? environment.features.backOfficeAdminFeaturesAvailable.importUsers
            : environment.features.backOfficeCommunityManagerFeaturesAvailable.importUsers;
        this.exportUsersAvailable = accountsService.isAdmin()
            ? environment.features.backOfficeAdminFeaturesAvailable.exportUsers
            : environment.features.backOfficeCommunityManagerFeaturesAvailable.exportUsers;
        this.massEditUsersAvailable = accountsService.isAdmin()
            ? environment.features.backOfficeAdminFeaturesAvailable.massEditUsers
            : environment.features.backOfficeCommunityManagerFeaturesAvailable.massEditUsers;
    }

    ngOnInit(): void {
        if (this.accountsService.isAdmin()) {
            this.communityService.getCommunities().subscribe((communities) => {
                this.communities = communities;
            });
        } else {
            this.communities = this.accountsService.getCommunitiesManagedByUser();
        }

        this.selectedCommunityId = this.accountsService.currentCommunity.id;
        this.loadData();
        this.loadCommunityData();

        this.accountsService.currentCommunityChange.subscribe(() => {
            this.selectedCommunityId = this.accountsService.currentCommunity.id;
            this.loadCommunityData();
            this.page = 1;
            this.getCommunityMembers();
        });
        this.addManageMenuItems();
    }

    addManageMenuItems() {
        this.manageUsersItems = [];

        const accountActivationFollowUpAllItem: MenuItem = {
            label: 'Relancer tous les utilisateurs',
            command: () => {
                this.showAllUsersFollowUpModalModal();
            },
        };
        this.manageUsersItems.push(accountActivationFollowUpAllItem);

        const accountActivationFollowUpSelectedItem: MenuItem = {
            label: 'Relancer les utilisateurs sélectionnés',
            command: () => {
                this.showSelectedUsersFollowUpModalModal();
            },
            disabled: this.selectedMembers.length === 0,
        };
        this.manageUsersItems.push(accountActivationFollowUpSelectedItem);

        if (this.importUsersAvailable) {
            const importUserMenuItem: MenuItem = {
                label: 'Import des utilisateurs',
                command: () => {
                    this.showDialog();
                },
            };
            this.manageUsersItems.push(importUserMenuItem);
        }
    }

    loadData() {
        forkJoin([this.userTypeService.getUserTypes(), this.subscriptionTypeService.getSubscriptionTypes()]).subscribe(
            ([userTypes, subscriptionType]) => {
                this.userTypes = userTypes;
                this.availableSubscriptionType = subscriptionType;
            },
        );
    }

    loadCommunityData() {
        this.selectedCommunityGroups = this.accountsService.currentCommunity.communitiesGroups;
        this.communityService.getCommunityCompute(this.selectedCommunityId).subscribe((data) => {
            this.communityCompute = data;
        });
    }

    getCommunityMembers(userCustomSearch: string = null) {
        this.selectedMembers = [];
        this.loading = true;

        const filters: Filters = new Filters();

        filters.page = this.page;
        filters.pageSize = this.pageSize;
        filters.sortMode = this.sortMode;
        filters.sortOrder = this.sortOrder;

        filters.userCustomSearch = userCustomSearch ? userCustomSearch + '*' : '*';
        filters.communities = [new CommunityFilter(this.selectedCommunityId, [])];

        this.communityService.getUsersByCommunityId(this.selectedCommunityId, filters).subscribe((result) => {
            this.nbUsersTotalInCommunity = result.count;
            this.members = result.data;
            this.loading = false;
        });
    }

    loadMembersLazy(event: LazyLoadEvent) {
        this.pageSize = event.rows;
        this.page = event.first / this.pageSize + 1;
        this.sortMode = event.sortField ? event.sortField : userSortModes.lastName;
        this.sortOrder = event.sortOrder;
        this.getCommunityMembers(event.globalFilter);
    }

    onHeaderCheckboxToggle() {
        if (this.allSelectionMode) {
            this.allSelectionMode = !this.allSelectionMode;
            this.selectedMembers = [];
        }
    }

    onAllSelectionClick() {
        this.allSelectionMode = !this.allSelectionMode;
        if (!this.allSelectionMode) {
            this.selectedMembers = [];
        }
    }

    getOrganizationById(organizationId: string): Organization {
        return this.organizations?.find((organization) => organization.id == organizationId);
    }

    getUserTypeLibelle(userTypeId: string): string {
        return this.userTypes?.find((userType) => userType.id == userTypeId)?.libelle;
    }

    getUserTypesCategorized(roleType: RoleType): UserType[] {
        return this.userTypes?.filter((userType) => userType.roleType == roleType);
    }

    getUserSubscriptionTypeLibelle(subscriptionTypeId: number): string {
        return this.availableSubscriptionType?.find((subscriptionType) => subscriptionType.id == subscriptionTypeId)
            ?.label;
    }

    getUserCommunitySelected(communities: CommunityWithRoles[]): CommunityWithRoles {
        return communities.filter((community) => community.id == this.selectedCommunityId)[0];
    }

    onRowEditInit(member: User) {
        this.userCurentlyEditedIds.push(member.userId);
    }

    async onRowEditSave(member: User) {
        this.userService.updateUser({ ...member, pictureHasChanged: false }).subscribe(() => {
            this.toastrHelper.showSuccess(this.translate.instant('toastMessages.userUpdateSuccess'));
        });
        this.userCurentlyEditedIds = this.userCurentlyEditedIds.filter((id) => id != member.userId);
    }

    onRowEditCancel(member: User, index: number) {
        this.userCurentlyEditedIds = this.userCurentlyEditedIds.filter((id) => id != member.userId);
    }

    isUserCurentlyEdited(userId: string): boolean {
        return this.userCurentlyEditedIds.includes(userId);
    }

    isUserPrimaryCommunityId(userPrimaryCommunityId: string): boolean {
        return this.selectedCommunityId == userPrimaryCommunityId;
    }
    showDialog() {
        this.dialogVisible = true;
    }

    handleDialogCancelButtonClick() {
        this.csvfileForm.resetFormCleanly();
        this.dialogVisible = false;
        this.importStep = 1;
    }

    handleNextStepButtonClick() {
        switch (this.importStep) {
            case 1:
                this.importStep++;
                break;
            case 2:
                this.finishCsvImport();
                break;
            default:
                return false;
        }
    }

    handleCsvFileFormChange() {
        this.csvFile = this.csvfileForm.controls.file.value;
    }

    updateCsvUsers(users: User[]) {
        this.csvUsers = users;
    }

    private finishCsvImport() {
        return;
    }
    exportCommunityMembersDetailsInCSV() {
        this.communityService
            .getSelectedCommunityMembersDetailsForCSV(this.selectedCommunityId)
            .subscribe((response) => {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
                downloadLink.setAttribute(
                    'download',
                    'Membres de la communauté ' + this.communityCompute.displayName + '.csv',
                );
                document.body.appendChild(downloadLink);
                downloadLink.click();
            });
    }

    sendAccountActivationFollowUpNotification(sendToAll: boolean) {
        if (this.isSendingAccountActivationFollowUpNotification) return;

        this.isSendingAccountActivationFollowUpNotification = true;

        let selectedMembersIds: string[] = [];

        if (!sendToAll) {
            selectedMembersIds = this.selectedMembers.map((member) => member.userId);
        }

        this.communityService
            .sendAccountActivationFollowUpNotification(this.selectedCommunityId, selectedMembersIds)
            .subscribe(
                (response: ResponseFromAccountActivationFollowUp) => {
                    const blob = new Blob(['Succès : \n', response.success, '\n', 'Echecs : \n', response.errors], {
                        type: 'text/plain',
                    });
                    const now = new Date();
                    const fileName = `Relance pour activation de la communauté ${
                        this.accountsService.currentCommunity.displayName
                    } du ${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}.txt`;

                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(blob);
                    downloadLink.setAttribute('download', fileName);
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    const message = this.translate.instant(
                        'community.pages.members.toast.accountActivationFollowUpMails',
                    );
                    this.toastrHelper.showSuccess(message);

                    if ((response.errors || []).length > 0) {
                        const warning = this.translate.instant(
                            'community.pages.members.toast.accountActivationFollowUpMailsSomeErrors',
                        );
                        this.toastrHelper.showWarning(warning);
                    }

                    this.isSendingAccountActivationFollowUpNotification = false;
                },
                () => {
                    const message = this.translate.instant('common.unknownError');
                    this.toastrHelper.showError(message);
                },
            );
    }

    onChangeCommunity(event: string) {
        const community = this.communities.find((community) => community.id == event);

        if (community != null) {
            this.changeCommunityInService(community);
        }
    }

    changeCommunityInService(newCommunity: Community) {
        this.accountsService.changeCurrentCommunity(newCommunity);
    }

    showAllUsersFollowUpModalModal() {
        this.allUsersFollowUpModalVisible = true;
    }

    closeAllUsersFollowUpModal() {
        this.allUsersFollowUpModalVisible = false;
    }

    showSelectedUsersFollowUpModalModal() {
        this.selectedUsersFollowUpModalVisible = true;
    }

    closeSelectedUsersFollowUpModal() {
        this.selectedUsersFollowUpModalVisible = false;
    }
}
