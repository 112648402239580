import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltersItem } from '../../../models/shared/filters-item.model';

@Component({
    selector: 'app-custom-search',
    templateUrl: './custom-search-filter.component.html',
    styleUrls: ['./custom-search-filter.component.scss'],
})
export class CustomSearchFilterComponent implements OnInit {
    public customSearchItem: FiltersItem;
    @Input() public customSearchName = '';
    @Input() public placeholderText = '';
    @Output() public ready = new EventEmitter();
    @Output() public searchTextChanged = new EventEmitter();
    @Input() public userInput = '';
    @Input() public disabled = false;

    constructor() {}

    public ngOnInit(): void {
        this.customSearchItem = new FiltersItem('customSearch', this.customSearchName);
        this.ready.emit();
    }

    public onSearchTextChanged(text: string) {
        this.searchTextChanged.emit(text);
        this.userInput = text;
    }
}
