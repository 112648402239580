import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Community } from '../../../models/identity/community.model';
import { JoinableType } from '../../../models/shared/joinable-type.model';
import { ScopeSelectionFormGroup } from '../../forms/scope-selection.form';

@Component({
    selector: 'app-scope-selection',
    templateUrl: './scope-selection.component.html',
    styleUrls: ['./scope-selection.component.scss'],
})
export class ScopeSelectionComponent {
    @Input()
    public readonly label: string;

    @Input()
    public readonly joinableType: JoinableType;

    @Input()
    public readonly form: ScopeSelectionFormGroup;

    @Input()
    public readonly formSubmitAttempt: boolean;

    @Input()
    public readonly userCommunities: Community[];

    @Input()
    public readonly allCommunities: Community[];

    @Input()
    public readonly canCreateAllCommunitiesProject: boolean;

    @Input()
    public readonly hideAllCommunitiesVisibility: boolean;

    @Input()
    public readonly showAllCommunitiesVisibility: boolean;

    get scopeSelection(): string {
        return `${this.joinableType.toString()}ScopeSelection`;
    }

    constructor(private readonly translate: TranslateService) {}

    public getCurrentVisibilityRange() {
        let visibilityRange = this.translate.instant(`shared.${this.scopeSelection}.selectedVisibility.default`);

        if (this.form.organizationVisibility.value) {
            visibilityRange = this.translate.instant(`shared.${this.scopeSelection}.selectedVisibility.organization`);
        }

        if (this.form.myCommunitiesVisibility.value) {
            visibilityRange = this.translate.instant(`shared.${this.scopeSelection}.selectedVisibility.communities`);
        }

        if (this.form.organizationVisibility.value && this.form.myCommunitiesVisibility.value) {
            visibilityRange = this.translate.instant(
                `shared.${this.scopeSelection}.selectedVisibility.communityOrganization`,
            );
        }

        if (this.form.allCommunitiesVisibility.value) {
            visibilityRange = this.translate.instant(`shared.${this.scopeSelection}.selectedVisibility.allCommunities`);
        }

        return visibilityRange;
    }

    public onAllMyCommunitiesChange(isAllMyCommunitiesChecked: boolean) {
        isAllMyCommunitiesChecked ? this.form.selectAllUserCommunities() : this.form.deselectAllUserCommunities();
    }
}
