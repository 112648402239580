import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SkillDomain } from '../../models/skills/skill-domain.model';
import { Skill } from '../../models/skills/skill.model';
import { CacheService } from '../shared/cache.service';

@Injectable({
    providedIn: 'root',
})
export class SkillsService {
    private skillsApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.skills.skillsApi.baseUrl}`;
    private skillDomainsApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.skills.skillDomainsApi.baseUrl}`;

    // Key of the skills in the cache
    private skillsCacheKey = 'currentSkills';
    // Key of the skills domains in the cache
    private skillsDomainsCacheKey = 'currentSkillsDomains';

    constructor(private http: HttpClient, private readonly cacheService: CacheService) {}

    getAllSkills(): Observable<Skill[]> {
        return this.cacheService.get(this.skillsCacheKey, this.http.get<Skill[]>(this.skillsApiUrl));
    }

    getAllSkillsDomains(): Observable<SkillDomain[]> {
        return this.cacheService.get(this.skillsDomainsCacheKey, this.http.get<SkillDomain[]>(this.skillDomainsApiUrl));
    }

    public getTopSkillsByCommunity(communityId: string, limit: number): Observable<Skill[]> {
        return this.cacheService.get(
            'topCommunitySkills',
            this.http.get<Skill[]>(
                `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.communitiesApi.baseUrl}/${communityId}/skills/top?limit=${limit}`,
            ),
        );
    }
}
