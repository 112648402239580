import { Community } from '../identity/community.model';
import { OrganizationBase } from '../identity/organization-base.model';
import { Interest } from '../skills/interest.model';
import { Skill } from '../skills/skill.model';
import { BaseEditableEntity } from './../shared/base-editable-entity.model';
import { CollaborationPlatformTypeEnum } from './collaboration-platform-type.enum.model';
import { ProjectAttendee } from './project-attendee.model';
import { ProjectLight } from './project-light.model';
import { ProjectType } from './project-type.model';

export const PROJECT_GOAL_MAX_LENGTH = 100;
export const PROJECT_SYNTHESIS_MAX_LENGTH = 200;
export const PROJECT_CONTEXT_MAX_LENGTH = 750;
export const PROJECT_CONTRIBUTIONS_MAX_LENGTH = 750;
export const PROJECT_DESCRIPTION_MAX_LENGTH = 2500;
export const PROJECT_MOTIVATION_MAX_LENGTH = 2500;

export const PROJECT_MAX_SKILLS = 12;
export const PROJECT_MAX_INTERESTS = 5;

export class Project extends BaseEditableEntity {
    id: string;
    copyProjectId?: string;
    name: string;
    startDate: Date;
    endDate: Date;
    closingDate: Date;
    synthesis: string;
    context: string;
    status: string;
    goals: string[];
    contributions: string;
    description: string;
    publicationDate: Date;
    pictureReference: string;
    pictureAccessLink: string;
    collaborationPlatformType: CollaborationPlatformTypeEnum;
    collaborationPlatformUrl: string;
    allCommunitiesVisibility: boolean;
    organizationVisibility: boolean;
    projectType: ProjectType;
    owner: ProjectAttendee;
    attendees: ProjectAttendee[];
    pendingAttendees: ProjectAttendee[];
    organization: OrganizationBase;
    communities: Community[];
    searchedSkills: Skill[];
    associatedInterests: Interest[];
    pictureAsString: string | ArrayBuffer;
    motivation: string;
    isAnonymous: boolean;
    isAnonymized: boolean;
    primaryCommunityId: string;
    isPublic: boolean;
    publicJoinUrl: string;

    public static toProjectLight(project: Project): ProjectLight {
        const result = new ProjectLight();
        result.id = project?.id;
        result.status = project?.status;
        result.pictureAccessLink = project?.pictureAccessLink;
        return result;
    }
}
