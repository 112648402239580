<image-crop-modal [cropperParams]="cropperParams" [imageService]="imageService" (eventSavePicture)="savePicture()"></image-crop-modal>
<form [formGroup]="stepTwoForm" (ngSubmit)="onSubmit()" class="col-12 mt-2">
    <div class="card box-shadow">
        <div class="card-body">
            <h2 class="h4 primary-1">{{ 'projects.formStep2.projectDescription' | translate }}</h2>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="d-block h6">
                                <span class="d-block float-left primary-1">{{ 'projects.formStep2.context.title' | translate }}</span>
                                <span class="d-block float-right">
                                    <span
                                        [class.text-danger]="stepTwoForm.controls.context.errors?.maxlength"
                                        [class.font-weight-bold]="stepTwoForm.controls.context.errors?.maxlength"
                                        >{{ contextCounter }}</span
                                    >/{{ PROJECT_CONTEXT_MAX_LENGTH }}
                                </span>
                            </h3>
                        </div>

                        <div class="col-12">
                            <div class="align-items-center mb-2">
                                <quill-editor
                                    formControlName="context"
                                    [placeholder]="'projects.formStep2.context.placeholder' | translate"
                                    theme="snow"
                                    required="true"
                                    [modules]="quillEditorHelper.modules"
                                    [formats]="quillEditorHelper.formats"
                                    (onContentChanged)="onContextChanged($event.editor)"
                                    class="notranslate"
                                >
                                </quill-editor>

                                <div
                                    *ngIf="
                                        stepTwoForm.controls.context.invalid &&
                                        (stepTwoForm.controls.context.dirty || stepTwoForm.controls.context.touched)
                                    "
                                    class="alert alert-danger"
                                >
                                    <div *ngIf="stepTwoForm.controls.context.errors.required">
                                        {{ 'projects.formStep2.context.required' | translate }}
                                    </div>
                                    <div *ngIf="stepTwoForm.controls.context.errors.maxlength">
                                        {{ 'projects.formStep2.context.length' | translate: { maxLength: PROJECT_CONTEXT_MAX_LENGTH } }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="d-block h6">
                                <span class="d-block float-left primary-1">{{ 'projects.formStep2.objectifs.title' | translate }}</span>
                                <span class="d-block float-right">{{ getFilledGoalsCount() }}/5</span>
                            </h3>
                        </div>

                        <div class="col-12" formArrayName="goals">
                            <div class="align-items-center mb-2" *ngFor="let goal of goals.controls; let i = index">
                                <div *ngIf="i === 0">
                                    <input
                                        type="text"
                                        [formControlName]="i"
                                        placeholder="{{ 'projects.formStep2.objectifs.firstplaceholder' | translate }}"
                                        class="form-control"
                                    />
                                </div>
                                <div *ngIf="i != 0">
                                    <input
                                        type="text"
                                        [formControlName]="i"
                                        placeholder="{{ 'projects.formStep2.objectifs.otherplaceholder' | translate }}"
                                        class="form-control"
                                    />
                                </div>

                                <div *ngIf="goal.invalid && (goal.dirty || goal.touched)" class="alert alert-danger">
                                    <div
                                        *ngIf="goal.errors.maxlength"
                                        [innerHTML]="
                                            'projects.formStep2.objectifs.length'
                                                | translate: { maxLength: PROJECT_GOAL_MAX_LENGTH, currentLength: goal.value.length }
                                        "
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 mt-4">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="d-block h6">
                                <span class="d-block float-left primary-1">{{ 'projects.formStep2.expectedContributions.title' | translate }}</span>
                                <span class="d-block float-right">
                                    <span
                                        [class.text-danger]="stepTwoForm.controls.contributions.errors?.maxlength"
                                        [class.font-weight-bold]="stepTwoForm.controls.contributions.errors?.maxlength"
                                        >{{ contributionsCounter }}</span
                                    >/{{ PROJECT_CONTRIBUTIONS_MAX_LENGTH }}
                                </span>
                            </h3>
                        </div>

                        <div class="col-12">
                            <div class="align-items-center mb-2">
                                <quill-editor
                                    formControlName="contributions"
                                    placeholder="{{ 'projects.formStep2.expectedContributions.placeholder' | translate }}"
                                    theme="snow"
                                    required="true"
                                    [modules]="quillEditorHelper.modules"
                                    [formats]="quillEditorHelper.formats"
                                    (onContentChanged)="onContributionsChanged($event.editor)"
                                    class="notranslate"
                                >
                                </quill-editor>

                                <div
                                    *ngIf="
                                        stepTwoForm.controls.contributions.invalid &&
                                        (stepTwoForm.controls.contributions.dirty || stepTwoForm.controls.contributions.touched)
                                    "
                                    class="alert alert-danger"
                                >
                                    <div *ngIf="stepTwoForm.controls.contributions.errors.required">
                                        {{ 'projects.formStep2.expectedContributions.required' | translate }}
                                    </div>
                                    <div
                                        *ngIf="stepTwoForm.controls.contributions.errors.maxlength"
                                        [innerHTML]="
                                            'projects.formStep2.expectedContributions.length'
                                                | translate: { maxLength: PROJECT_CONTRIBUTIONS_MAX_LENGTH }
                                        "
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 mt-4">
                    <h3 class="h6">
                        <span class="d-block float-left primary-1">{{ 'projects.formStep2.synthesis.title' | translate }}</span>
                        <span class="d-block float-right">
                            <span
                                [class.text-danger]="stepTwoForm.controls.synthesis.errors?.maxlength"
                                [class.font-weight-bold]="stepTwoForm.controls.synthesis.errors?.maxlength"
                                >{{ stepTwoForm.controls.synthesis.value.length }}</span
                            >/{{ PROJECT_SYNTHESIS_MAX_LENGTH }}
                        </span>
                    </h3>
                    <textarea
                        formControlName="synthesis"
                        class="form-control synthesis-control"
                        placeholder="{{ 'projects.formStep2.synthesis.placeholder' | translate }}"
                        cols="30"
                        rows="8"
                        required
                    ></textarea>
                    <div
                        *ngIf="
                            stepTwoForm.controls.synthesis.invalid && (stepTwoForm.controls.synthesis.dirty || stepTwoForm.controls.synthesis.touched)
                        "
                        class="alert alert-danger"
                    >
                        <div *ngIf="stepTwoForm.controls.synthesis.errors.required">
                            {{ 'projects.formStep2.synthesis.required' | translate }}
                        </div>
                        <div *ngIf="stepTwoForm.controls.synthesis.errors.maxlength">
                            {{ 'projects.formStep2.synthesis.length' | translate: { maxLength: PROJECT_SYNTHESIS_MAX_LENGTH } }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mt-4">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="d-block h6">
                                <span class="d-block float-left primary-1">{{ 'projects.formStep2.detailledDescription.title' | translate }}</span>
                                <span class="d-block float-right">
                                    <span
                                        [class.text-danger]="stepTwoForm.controls.description.errors?.maxlength"
                                        [class.font-weight-bold]="stepTwoForm.controls.description.errors?.maxlength"
                                        >{{ descriptionCounter }}</span
                                    >/{{ PROJECT_DESCRIPTION_MAX_LENGTH }}
                                </span>
                            </h3>
                        </div>

                        <div class="col-12">
                            <div class="align-items-center mb-2">
                                <quill-editor
                                    formControlName="description"
                                    [placeholder]="'projects.formStep2.detailledDescription.placeholder' | translate"
                                    theme="snow"
                                    required="true"
                                    [modules]="quillEditorHelper.modules"
                                    [formats]="quillEditorHelper.formats"
                                    (onContentChanged)="onDescriptionChanged($event.editor)"
                                    class="notranslate"
                                >
                                </quill-editor>

                                <div
                                    *ngIf="
                                        stepTwoForm.controls.description.invalid &&
                                        (stepTwoForm.controls.description.dirty || stepTwoForm.controls.description.touched)
                                    "
                                    class="alert alert-danger"
                                >
                                    <div *ngIf="stepTwoForm.controls.description.errors.required">
                                        {{ 'projects.formStep2.detailledDescription.required' | translate }}
                                    </div>
                                    <div *ngIf="stepTwoForm.controls.description.errors.maxlength">
                                        {{
                                            'projects.formStep2.detailledDescription.length'
                                                | translate: { maxLength: PROJECT_DESCRIPTION_MAX_LENGTH }
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <button type="button" class="btn btn-primary center" (click)="imageCropModal.showModal()">
                        <em class="icon-edit d-sm-inline d-md-none"></em>
                        <span class="d-none d-sm-inline">{{ 'projects.formStep2.picture.title' | translate }}</span>
                    </button>
                </div>
                <br /><br />
                <div class="col-12 col-md-6">
                    <div class="container-uploaded">
                        <img
                            *ngIf="file || projectPictureLink; else noImage"
                            [src]="getPictureUrl()"
                            alt="{{ 'projects.formStep2.picture.loaded' | translate }}"
                        />
                        <ng-template #noImage>
                            <p>{{ 'projects.formStep2.picture.noImage' | translate }}</p>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card box-shadow mt-2" *ngIf="canUserAddAProjectClub">
        <div class="card-body">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="fw-b primary-1">{{ 'projects.formStep2.collaborationPlatform.title' | translate }}</div>
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="radio"
                            formControlName="collaborationPlatformType"
                            id="aucun_espace"
                            [value]="collaborationPlatformTypeEnum[collaborationPlatformTypeEnum.None]"
                            (click)="stepTwoForm.controls.collaborationPlatformUrl.setValue('')"
                        />
                        <label class="form-check-label" for="aucun_espace">
                            {{ 'projects.formStep2.collaborationPlatform.none' | translate }}
                        </label>
                    </div>
                    <div *ngIf="featureClubProjectAvailable" class="form-check">
                        <input
                            class="form-check-input"
                            type="radio"
                            formControlName="collaborationPlatformType"
                            id="espace_club"
                            [value]="collaborationPlatformTypeEnum[collaborationPlatformTypeEnum.Club]"
                            (click)="stepTwoForm.controls.collaborationPlatformUrl.setValue('')"
                        />
                        <label class="form-check-label" for="espace_club">
                            {{ 'projects.formStep2.collaborationPlatform.club' | translate }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="radio"
                            formControlName="collaborationPlatformType"
                            id="espace_ok"
                            [value]="collaborationPlatformTypeEnum[collaborationPlatformTypeEnum.Mine]"
                            (click)="
                                projectCommand.collaborationPlatformType === collaborationPlatformTypeEnum.Mine
                                    ? stepTwoForm.controls.collaborationPlatformUrl.setValue(projectCommand.collaborationPlatformUrl)
                                    : stepTwoForm.controls.collaborationPlatformUrl.setValue('')
                            "
                        />
                        <label class="form-check-label" for="espace_ok">
                            {{ 'projects.formStep2.collaborationPlatform.provided' | translate }}
                        </label>
                        <div
                            class="url-espace mb-2"
                            *ngIf="
                                stepTwoForm.controls.collaborationPlatformType.value ===
                                collaborationPlatformTypeEnum[collaborationPlatformTypeEnum.Mine]
                            "
                        >
                            <input
                                type="url"
                                class="form-control"
                                placeholder="{{ 'projects.formStep2.collaborationPlatform.url' | translate }}"
                                formControlName="collaborationPlatformUrl"
                                maxlength="2048"
                            />
                        </div>
                    </div>
                    <div
                        class="form-check"
                        *ngIf="
                            (!atoliaDisable || isProjectWithAtolia) &&
                            (permissions$ | async)?.canViewAtolia &&
                            (!isAllCommunitiesVisibility || shouldKeepAtoliaForProjectWithAllCommunities)
                        "
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            formControlName="collaborationPlatformType"
                            id="espace_wudo"
                            [value]="collaborationPlatformTypeEnum[collaborationPlatformTypeEnum.WuDo]"
                            (click)="
                                projectCommand.collaborationPlatformType === collaborationPlatformTypeEnum.WuDo
                                    ? stepTwoForm.controls.collaborationPlatformUrl.setValue(projectCommand.collaborationPlatformUrl)
                                    : stepTwoForm.controls.collaborationPlatformUrl.setValue('')
                            "
                        />
                        <label class="form-check-label" for="espace_wudo">
                            {{ 'projects.formStep2.collaborationPlatform.atolia' | translate }}
                        </label>
                        <div
                            class="url-espace mb-2"
                            *ngIf="
                                isAdmin &&
                                stepTwoForm.controls.collaborationPlatformType.value ==
                                    collaborationPlatformTypeEnum[collaborationPlatformTypeEnum.WuDo]
                            "
                        >
                            {{ stepTwoForm.controls.collaborationPlatformUrl.value }}
                        </div>
                    </div>
                    <div
                        *ngIf="
                            stepTwoForm.controls.collaborationPlatformUrl.invalid &&
                            (stepTwoForm.controls.collaborationPlatformUrl.dirty || stepTwoForm.controls.collaborationPlatformUrl.touched)
                        "
                        class="alert alert-danger"
                    >
                        <div *ngIf="stepTwoForm.controls.collaborationPlatformUrl.errors.pattern">
                            {{ 'projects.formStep2.collaborationPlatform.urlFormatError' | translate }}
                        </div>
                        <div
                            *ngIf="stepTwoForm.controls.collaborationPlatformUrl.value.maxlength"
                            [innerHTML]="'projects.formStep2.collaborationPlatform.urlMaxLength' | translate"
                        ></div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="alert alert-primary" role="alert">
                        <div class="fw-b">
                            {{ 'projects.formStep2.collaborationPlatform.informationLabel' | translate }}
                        </div>
                        {{ 'projects.formStep2.collaborationPlatform.information' | translate: { title: title } }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12 footer-create-project">
            <div class="float-left">
                <button class="btn btn-secondary" type="button" (click)="goToPreviousStep()">
                    <em class="icon icon-arrow-right1 d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonPrevious' | translate }}</span>
                </button>
            </div>

            <div class="float-right">
                <!--Supprimer un projet-->
                <button *ngIf="isRemovable()" class="btn btn-danger" data-toggle="modal" data-target="#confirmDeleteModal" type="button">
                    <em class="icon icon-trash d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonDelete' | translate }}</span>
                </button>

                <button *ngIf="!isEditMode || (isEditMode && isDraft)" class="btn btn-secondary" type="button" (click)="openConfirmePopupSaveDraft()">
                    <em class="icon icon-save d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonSaveDraft' | translate }}</span>
                </button>

                <button class="btn btn-primary" type="submit">
                    <em class="icon icon-arrow-left1 d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonNext' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</form>

<!--Save draft-->
<ng-template id="modal" #draftStepOneModal3 let-modal class="modal fade">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
                {{ 'projects.formStep2.saveDraftModal.title' | translate }}
            </h5>
            <button type="button" class="close" (click)="onClose()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div *ngIf="projectCommand.picturePreviewUrl" class="modal-image">
            <img src="{{ projectCommand.picturePreviewUrl }}" [alt]="'projects.formStep2.projectPictureAlt' | translate" />
        </div>
        <div class="modal-body">
            <p class="ta-c lead">
                {{ 'projects.formStep2.saveDraftModal.confirm' | translate }}
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onClose()">
                {{ 'components.button.buttonCancel' | translate }}
            </button>
            <button type="button" class="btn btn-primary" (click)="onSave()">
                {{ 'components.button.buttonYes' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<!-- Delete Modal -->
<div
    class="modal fade bd-example-modal-lg"
    id="confirmDeleteModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteConfirmeModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmeCloseProjectModalLabel">
                    {{ 'projects.formStep2.deleteModal.title' | translate }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-image" *ngIf="projectLight?.pictureAccessLink">
                <img src="{{ projectLight?.pictureAccessLink }}" [alt]="'projects.formStep2.projectPictureAlt' | translate" />
            </div>
            <div class="modal-body">
                <p class="ta-c lead">
                    {{ 'projects.formStep2.deleteModal.confirm' | translate }}
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ 'components.button.buttonCancel' | translate }}
                </button>
                <button type="button" class="btn btn-danger" (click)="deleteProject()" data-dismiss="modal">
                    {{ 'components.button.buttonDelete' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
