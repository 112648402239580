<div class="main-card-filters top" [class.filters-show]="areFiltersOpened">
    <!-- TODO: Move this to a 'filters-header' component -->
    <div class="card card-filters box-shadow">
        <div class="close-filters">
            <button
                class="d-block d-sm-none float-right btn btn-link btn-close-filters"
                (click)="closeFilters()"
            >
                {{ 'components.button.buttonClose' | translate }}
                <em class="icon icon-close"></em>
            </button>
        </div>

        <div class="card-header">
            <div class="row align-items-center">
                <div class="col-auto">
                    <span class="fw-b primary-1">{{ 'filters.shared.titleFilters' | translate }}</span>
                </div>
                <div class="col-auto ml-auto">
                    <button
                        class="btn btn-reset btn-sm"
                        (click)="resetFilters()"
                    >
                        {{ 'filters.shared.resetFilters' | translate }}
                        <em class="icon icon-close"></em>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="environment.features.myEventsFilter === true">
        <div class="mt-1 card card-filters box-shadow">
            <div class="filter-section__heading">
                {{ 'filters.myEvents.owned' | translate }}
            </div>

            <app-owned-events-can-join-checkbox
                (selectionChanged)="onFiltersSelectionChanged()"
            ></app-owned-events-can-join-checkbox>

            <app-owned-events-canceled-checkbox
                (selectionChanged)="onFiltersSelectionChanged()"
            ></app-owned-events-canceled-checkbox>

            <app-owned-events-full-checkbox
                (selectionChanged)="onFiltersSelectionChanged()"
            ></app-owned-events-full-checkbox>

            <app-owned-events-ongoing-checkbox
                (selectionChanged)="onFiltersSelectionChanged()"
            ></app-owned-events-ongoing-checkbox>

            <app-owned-events-done-checkbox
                (selectionChanged)="onFiltersSelectionChanged()"
            ></app-owned-events-done-checkbox>
        </div>
    </ng-container>

    <div class="mt-1 card card-filters box-shadow">
        <div class="filter-section__heading">
            {{ 'filters.myEvents.joined' | translate }}
        </div>

        <app-joined-events-coming-checkbox
            (selectionChanged)="onFiltersSelectionChanged()"
        ></app-joined-events-coming-checkbox>

        <app-joined-events-canceled-checkbox
            (selectionChanged)="onFiltersSelectionChanged()"
        ></app-joined-events-canceled-checkbox>

        <app-joined-events-ongoing-checkbox
            (selectionChanged)="onFiltersSelectionChanged()"
        ></app-joined-events-ongoing-checkbox>

        <app-joined-events-done-checkbox
            (selectionChanged)="onFiltersSelectionChanged()"
        ></app-joined-events-done-checkbox>
    </div>
</div>
