import { CommunityGroup } from './community-group.model';
import { WudoApplicationType } from './enumerations/wudoApplicationType';
import { SubscriptionTypeCompute } from './subscription-type-compute';

export class Community {
    public applicationAccesses: WudoApplicationType[];
    public benefits: string;
    public communitiesGroups: CommunityGroup[];
    public communityLink: string;
    public displayName: string;
    public expectedContributions: string;
    public groups: string[];
    public id: string;
    public canCreateClub = false;
    public canCreateProject = false;
    public canCreateEvent = false;
    public hasAccessToDashboard = false;
    public hasActivateIntelligentNewsLetter = false;
    public mainspring: string;
    public name: string;
    public parentsIds: string[];

    constructor(object?: Partial<Community>) {
        Object.assign(this, object);
    }
}

export class CommunityCompute extends Community {
    public subscriptionsTypes: SubscriptionTypeCompute[];
}

export const COMMUNITY_NAME_MAX_LENGTH = 60;
