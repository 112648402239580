import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommunityWithRoles } from './../../../models/identity/community-with-roles.model';
import { Community } from './../../../models/identity/community.model';
import { AccountsService } from './../../../services/identity/accounts.service';

@Component({
    selector: 'app-list-communities',
    templateUrl: './list-communities.component.html',
    styleUrls: ['./list-communities.component.scss'],
})
export class ListCommunitiesComponent implements OnInit {
    @Input() communities: Community[] = [];
    @Input() selectedCommunityId: string;
    @Output() eventEmitter: EventEmitter<string> = new EventEmitter<string>();
    selectedCommunity: Community;

    constructor(private readonly accountsService: AccountsService) {}

    ngOnInit(): void {
        this.selectedCommunity = this.communities.find((community) => community.id == this.selectedCommunityId);

        this.accountsService.currentCommunityChange.subscribe((newCommunity) => {
            if (this.selectedCommunity.id != newCommunity.id) this.selectedCommunity = newCommunity;
        });
    }

    ngModelChange(event: CommunityWithRoles) {
        this.selectedCommunityId = event.id;
        for (const community of this.communities) {
            if (community.id == this.selectedCommunityId) {
                this.eventEmitter.emit(this.selectedCommunityId);
                return;
            }
        }
    }
}
