<p-dialog
    [header]="Report"
    [modal]="true"
    [(visible)]="isModalVisible"
    [draggable]="false"
    [resizable]="true"
    [closable]="false"
    [responsive]="true"
    styleClass="custom-report-modal"
>
    <ng-container>
        <div class="p-4">
            <div class="p-2 text-wrap d-flex flex-column">
                <span>
                    <strong>{{ entityOwner }} </strong>{{ 'shared.reportModal.reportAnonymous' | translate }}</span
                >
                <span>{{ 'shared.reportModal.notificationInformation' | translate }}</span>
            </div>
            <div class="d-flex flex-column p-2">
                <span style="font-weight: bold">{{ 'shared.reportModal.reportPattern' | translate }} </span>
                <textarea
                    id="content"
                    class="publication-textarea rounded"
                    placeholder="Indiquez la raison de votre signalement"
                    [(ngModel)]="reportContent"
                ></textarea>
            </div>
        </div>
    </ng-container>
    <p-footer>
        <button (click)="closeModal()" class="btn btn-secondary">
            {{ 'components.button.buttonCancel' | translate }}
        </button>
        <button (click)="sendReport()" class="btn btn-primary">
            {{ 'components.button.send' | translate }}
        </button>
    </p-footer>
</p-dialog>
