import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../services/shared/theme.service';
import { CurrentThemeStore } from '../stores/current-theme.store';

@Component({
    selector: 'app-top-bar-public',
    templateUrl: './top-bar-public.component.html',
    styleUrls: ['./top-bar-public.component.scss'],
})
export class TopBarPublicComponent implements OnInit {
    public logoSrc: string;

    constructor(private readonly themeService: ThemeService, private readonly ThemeStore: CurrentThemeStore) {}

    ngOnInit(): void {
        this.ThemeStore.currentCommunityId$.subscribe((communityId) => {
            if (communityId) {
                this.themeService.getThemeLogo(communityId).subscribe((platformLogo) => {
                    if (platformLogo) {
                        this.logoSrc = platformLogo.url;
                    }
                });
            }
        });
    }
}
