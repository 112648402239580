import { PasswordConst } from './password';
export const messages = {
    userCreationSuccess: "L'utilisateur a bien été créé",
    solutionCreationSuccess: 'La solution a bien été créée',
    userUpdateSuccess: "L'utilisateur a bien été modifié.",
    userRenewConfirmEmail: "Un E-mail d'activation de compte a bien été renvoyé à l'utilisateur.",
    solutionUpdateSuccess: 'La solution a bien été modifiée.',
    apiGenericError: "Problème de connexion avec l'API.",
    apiDataPersistenceError: "Error lors de l'enregistrement de vos modifications.",
    apiDataPersistenceSuccess: 'Vos modifications ont été prises en compte.',
    unknownError: 'Une erreur est survenue.',
    deleteProfilePictureSuccess: 'Votre photo a été supprimée avec succès.',
    updateProfilePasswordSuccess: 'Votre mot de passe a été enregistré avec succès.',
    matchingPasswordsError: 'Les mots de passe saisis ne sont pas identiques.',
    passwordRules:
        'Votre mot de passe doit contenir 8 caractères minimum dont 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.',
    passwordSpecialsCharactersAuthorized: `Les caractères spéciaux autorisés sont ${PasswordConst.specialCharAuthorizedDisplayable}`,
    passwordLengthError: 'Le mot de passe doit contenir au moins 8 caractères.',
    passwordNumberError: 'Le mot de passe doit contenir au moins 1 chiffre.',
    passwordDoesNotContainAnyUppercaseError: 'Le mot de passe doit contenir au moins 1 majuscule.',
    passwordDoesNotContainAnyLowercaseError: 'Le mot de passe doit contenir au moins 1 minuscule.',
    passwordCharacterError: 'Le mot de passe doit contenir au moins 1 caractère spécial.',
    modifyUserWalletBalanceSucces: 'Le solde a été mis à jour.',
    oldPasswordEntryError: 'Vous avez entré votre ancien mot de passe.',
    passwordForbiddenCharError: 'Attention, certains caractères spéciaux sont interdits (par exemple : !).',
    httpForbiddenMessage: "L'action est interdite.",
    userSkillsRequiredError: 'Une compétence est requise au minimum (10 au maximum).',
    userInterestsRequiredError: "Un centre d'intérêt est requis au minimum (10 au maximum).",
    pictureExtensionError: "Le fichier transmis n'est pas une image (PNG, JPG, JPEG ou GIF requis).",
    fileExtensionError: "Le format du fichier transmis n'est pas autorisé.",
    profilePictureSizeError: 'La photo de profil ne doit pas excéder ',
    pictureSizeError: "L'image est trop volumineuse",
    pictureNotValid: "La photo n'est pas valide.",
    noRightsError: "Vous n'avez pas les droits suffisants pour cette action !",
    confirmEmailError:
        'Une erreur est survenue lors de la création de votre mot de passe ! Veuillez contacter le support.',
    changeEmailError:
        "Une erreur est survenue lors de la modification de votre compte ! Cliquez de nouveau sur 'Mot de passe oublié' ou contactez le support en cas de récidive",
    folderDeleteMessage: " Le dossier et les ressources qu'il contient seront supprimés.",
    warningUrlNotValid: 'Veuillez fournir un lien Internet valide.',
    warningResourceName: 'Veuillez donner un nom à votre ressource.',
    warningFolderResourceName: 'Veuillez donner un nom à votre dossier.',
    successEditFolder: 'Votre dossier a été modifié !',
    successEditResource: 'Votre resource a été modifiée !',
    warningRoomHiddenVisibilityEnabled:
        "Vous êtes sur le point d'activer le mode masqué.\nCette action est irréversible afin de protéger la confidentialité des échanges entre membres.",
    inputUnmodifiable: "Ce champ n'est pas modifiable",
    disabledPostsModalMessage:
        "Le mode masqué a été activé par l'administrateur. De ce fait, vous ne pouvez plus commenter ou réagir aux publications antérieures à cette action. Les publications concernées se trouvent sous le bandeau d'indication.",
    formContainsError: 'Veuillez vérifier votre saisie et réessayer.',
    visibleRoomWelcomeMessage: 'Lancer la conversation !',
    fileMaxSizeError: 'Votre fichier est trop volumineux.',
    noFileNameError: "Votre fichier n'a pas de nom.",
    storageQuotaExceeded: 'Le quota de stockage est dépassé.',
    pictureMaxSizeError: 'Votre photo est trop lourde, la taille maximale autorisée est de 2 Mo.',
    communityUpdated: 'La communauté a bien été mise à jour !',
    communityCreated: 'La communauté a bien été créée !',
    communityGroupUpdated: 'Le groupe communautaire a bien été mis à jour !',
    communityGroupCreated: 'Le groupe communautaire a bien été créé !',
    communityGroupDeleted: 'Le groupe communautaire a bien été supprimé !',
    adminNoOrganizationWarning: "Les organisations de votre communauté apparaîtront ici après l'import de vos membres",
};
