import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Error } from '../models/shared/error.model';
import { ToastrHelper } from './toastr.helper';

@Injectable({
    providedIn: 'root',
})
export class ErrorHelper {
    constructor(private readonly toastrHelper: ToastrHelper) {}

    public getError(httpResponse: HttpErrorResponse): Error {
        const error = new Error();

        error.code = httpResponse.status.toString();
        error.statusText = httpResponse.statusText;

        if (typeof httpResponse.error === 'string') {
            error.messages.push(`${httpResponse.error}`);
            return error;
        }

        for (const key in httpResponse.error) {
            error.messages.push(`${httpResponse.error[key]}`);
        }

        return error;
    }

    public showError(httpResponse: any) {
        if (typeof httpResponse.error === 'string') {
            this.toastrHelper.showError(`${httpResponse.error}`);
            return;
        } else if (typeof httpResponse.error === 'object') {
            let errorsAreDisplayed = false;
            for (const error in httpResponse.error) {
                this.toastrHelper.showError(httpResponse.error[error][0]);
                errorsAreDisplayed = true;
            }
            if (!errorsAreDisplayed) this.toastrHelper.showError(`${"Une erreur s'est produite"}`);

            return;
        } else if (Array.isArray(httpResponse)) {
            this.toastrHelper.showError(`${httpResponse.join('\n')}`);
        } else if (typeof httpResponse === 'string') {
            this.toastrHelper.showError(`${httpResponse}`);
        }
    }
}
