import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Event } from '../../../models/events/event.model';
import { ReportModalComponent } from '../../../shared/components/report-modal/report-modal.component';
import { AccountsService } from './../../../services/identity/accounts.service';
import { PwaService } from './../../../services/pwa/pwa.service';

@Component({
    selector: 'app-gear-edit-event',
    templateUrl: './gear-edit-event.component.html',
    styleUrls: ['./gear-edit-event.component.scss'],
    providers: [ConfirmationService],
})
export class GearEditEventComponent implements OnInit {
    public ceOrCette: string;
    public editPost = false;
    public isOnMobile = false;
    public isReportModalVisible = false;
    public items: MenuItem[] = [];
    public leOrLa: string;
    public postType: string;
    @Input() public event: Event;
    public eventOwnerFullName = '';

    public displayModal = false;
    public reportItem: MenuItem;
    @ViewChild(ReportModalComponent)
    public reportModal: ReportModalComponent;

    constructor(
        private accountsService: AccountsService,
        private readonly pwaService: PwaService,
        private translate: TranslateService,
    ) {}

    public initiatePrimeNg() {
        if (this.accountsService.currentUser.userId !== this.event.owner.id) {
            this.reportItem = {
                label: `${this.translate.instant('events.pages.list.actions.report')}`,
                icon: 'icon-f-flag',
                command: () => {
                    this.isReportModalVisible = true;
                    this.reportModal.openModal();
                },
                styleClass: 'menuItemReport',
            };
            this.items.push(this.reportItem);
        }
    }

    public ngOnInit() {
        this.displayGear();
        this.initiatePrimeNg();
        this.isOnMobile = this.pwaService.isMobile;
        this.eventOwnerFullName = `${this.event.owner.firstName} ${this.event.owner.lastName}`;
    }

    displayGear() {
        const currentUserId = this.accountsService.currentUser.userId;
        const eventOwnerId = this.event.owner.id;

        // Ensure that both user IDs are defined
        if (!currentUserId || !eventOwnerId) {
            return false;
        }

        this.displayModal = currentUserId !== eventOwnerId;
        return this.displayModal;
    }
}
