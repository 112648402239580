import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserType } from '../../models/identity/user-type.model';
import { environment } from '../../../environments/environment';
import { CacheService } from '../shared/cache.service';

@Injectable({ providedIn: 'root' })
export class UserTypeService {
    private userTypeApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.userTypesApi.baseUrl}`;

    // Key of the projects types in the cache
    private usersTypesCacheKey = 'currentUsersTypes';

    constructor(private http: HttpClient, private readonly cacheService: CacheService) {}

    // List all user types
    getUserTypes(): Observable<UserType[]> {
        return this.cacheService.get(this.usersTypesCacheKey, this.http.get<UserType[]>(this.userTypeApiUrl));
    }
}
