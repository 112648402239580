import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { ClubsStore } from '../../clubs/clubs.store';
import { Member } from '../../models/clubs/member.model';
import { CommunityWithRoles } from '../../models/identity/community-with-roles.model';
import { User } from '../../models/identity/user.model';
import { ProfileProgressList } from '../../models/users/profile/profile-progress-list';
import { WelcomeStep } from '../../models/users/welcome/welcome-step';
import { CommunityService } from '../../services/identity/community.service';
import { AccountsService } from '../identity/accounts.service';
import { ProfileService } from '../identity/profile.service';
import { PwaService } from '../pwa/pwa.service';
import { TutorialService } from '../tutorial/tutorial.service';
import { TutorialMobileService } from '../tutorialMobile/tutorialMobile.service';
@Injectable({ providedIn: 'root' })
export class WelcomeService implements OnDestroy {
    private destroy$ = new Subject<void>();
    private managedCommunities: CommunityWithRoles[];
    private steps = new BehaviorSubject<WelcomeStep[]>([]);

    public presentationRoomId: string;
    steps$ = this.steps.asObservable();

    constructor(
        private readonly accountService: AccountsService,
        private readonly clubsStore: ClubsStore,
        private readonly communityService: CommunityService,
        private readonly profileService: ProfileService,
        private readonly pwaService: PwaService,
        private readonly router: Router,
        private readonly tutorialService: TutorialService,

        private readonly tutorialMobileService: TutorialMobileService,
    ) {
        this.initializeStepsSubscription();
        this.communityService.previewEnded$.pipe(takeUntil(this.destroy$)).subscribe(() => this.completeCommunityPresentation());
        this.tutorialService.tourEnded$.pipe(takeUntil(this.destroy$)).subscribe(() => this.completeProductTour());
        this.tutorialMobileService.tourEnded$.pipe(takeUntil(this.destroy$)).subscribe(() => this.completeProductTour());
    }

    private initializeStepsSubscription(): void {
        if (this.steps.getValue().length > 0) {
            return;
        }

        combineLatest([
            this.accountService.currentUser$.pipe(
                filter((user): user is User => !!user), // Use type guard to ensure user is not null
                distinctUntilChanged(),
            ),
            this.clubsStore.member$.pipe(
                filter((member): member is Member => !!member), // Use type guard to ensure member is not null
                distinctUntilChanged(),
            ),
        ])
            .pipe(takeUntil(this.destroy$))
            .subscribe(async ([user, member]) => {
                if (user.userId !== member.id) {
                    this.clubsStore.loadMember();
                }
                this.managedCommunities = this.accountService.getCommunitiesManagedByUser();
                this.initializeSteps();
                this.presentationRoomId = this.clubsStore.presentationRoomIdByCommunityId(user.primaryCommunity?.id);
            });
    }

    public getCompletedStepsPercentage(): number {
        const steps = this.steps.getValue();
        const totalSteps = steps.length;

        if (totalSteps === 0) {
            return 0; // Return 0% if steps are not initialized yet
        }

        const completedSteps = steps.filter((step) => step.completed).length;
        return Math.ceil((completedSteps / totalSteps) * 100);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private checkCommunityDescription(): boolean {
        let result = true;
        this.managedCommunities.forEach((community) => {
            result &&= !!community.mainspring && !!community.benefits && !!community.expectedContributions;
        });

        return result;
    }
    private completeCommunityPresentation(): void {
        const userId = this.accountService.currentUser.userId;
        this.profileService.completeCommunityPresentation(userId).subscribe({
            next: (user) => {
                this.accountService.currentUser.hasViewedCommunityInformation = user.hasViewedCommunityInformation;
                this.accountService.saveCurrentUser();
                this.accountService.refreshCurrentUser();
            },
        });
    }
    private completeProductTour(): void {
        const userId = this.accountService.currentUser.userId;
        this.profileService.completeProductTour(userId).subscribe({
            next: () => {
                this.accountService.currentUser.isProductTourCompleted = true;
                this.accountService.saveCurrentUser();
                this.accountService.refreshCurrentUser();
            },
        });
    }

    private initializeSteps(): void {
        const initialSteps: WelcomeStep[] = [];

        if (this.managedCommunities.length > 0) {
            // step: as a community manager, describe your community
            initialSteps.push({
                title: 'welcome.steps.describeCommunity.title',
                description: 'welcome.steps.describeCommunity.subtitle',
                completed: this.checkCommunityDescription(),
                buttonLabel: 'welcome.steps.describeCommunity.action',
                action: () => this.router.navigate(['community', 'description']),
            });
        } else {
            // step: as a community member, discover your community
            initialSteps.push({
                title: 'welcome.steps.previewCommunity.title',
                description: 'welcome.steps.previewCommunity.subtitle',
                completed: this.accountService.currentUser.hasViewedCommunityInformation,
                buttonLabel: 'welcome.steps.previewCommunity.action',
                action: () => this.communityService.openPreviewCommunity(this.accountService.currentUser.primaryCommunity),
            });
        }

        initialSteps.push(
            // step: complete your profile
            {
                title: 'welcome.steps.completeProfile.title',
                description: 'welcome.steps.completeProfile.subtitle',
                completed: new ProfileProgressList(this.accountService.currentUser).score >= 75,
                buttonLabel: 'welcome.steps.completeProfile.action',
                action: () => this.router.navigate(['profile', 'edit']),
            },
        );

        if (!this.pwaService.isMobile) {
            initialSteps.push(
                // step: discover the platform
                {
                    title: 'welcome.steps.productTour.title',
                    description: 'welcome.steps.productTour.subtitle',
                    completed: this.accountService.currentUser?.isProductTourCompleted ?? false,
                    buttonLabel: 'welcome.steps.productTour.action',
                    action: () => this.tutorialService.startTour(),
                },
            );
        } else {
            initialSteps.push(
                // step: discover the platform
                {
                    title: 'welcome.steps.productTour.title',
                    description: 'welcome.steps.productTour.subtitle',
                    completed: this.accountService.currentUser?.isProductTourCompleted ?? false,
                    buttonLabel: 'welcome.steps.productTour.action',
                    action: () => this.tutorialMobileService.startTour(),
                },
            );
        }

        initialSteps.push(
            // step: present yourself in the club
            {
                title: 'welcome.steps.presentation.title',
                description: 'welcome.steps.presentation.subtitle',
                completed: this.clubsStore.member.hasCompletedCommunityPresentation,
                buttonLabel: 'welcome.steps.presentation.action',
                action: () => this.router.navigate(['clubs', this.presentationRoomId]),
            },
        );

        this.steps.next(initialSteps);
    }
}
