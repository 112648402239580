<image-crop-modal [cropperParams]="cropperParams" [imageService]="imageService" (eventSavePicture)="savePicture()"></image-crop-modal>

<div *ngIf="!(filtersLoaded | async) || !(dataLoaded$ | async)" class="spinner-border text-info" role="status"></div>

<form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="filtersLoaded | async" class="container">
    <div class="row align-items-center justify-content-between form-header" *ngIf="dataLoaded$ | async">
        <div class="col-6 d-flex px-0">
            <h1 class="view-name mr-auto">{{ 'shared.forms.organization.title' | translate }}</h1>
        </div>

        <div class="col-6 d-flex px-0" (click)="onUpdateClick()">
            <button
                type="button"
                class="btn btn-primary ml-auto my-auto"
                data-toggle="modal"
                data-target="#updateOrganization"
                [disabled]="form?.invalid"
            >
                <em class="icon-save d-sm-inline d-md-none"></em>
                <span class="d-none d-sm-inline">{{ 'components.button.save' | translate }}</span>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mt-2 card box-shadow z-index-0">
            <div class="card-body px-0">
                <div class="row">
                    <div class="col-12 col-lg-6 d-flex flex-row">
                        <div class="d-flex flex-column w-50">
                            <div class="container-uploaded" appDragDrop (onFileDropped)="uploadFile($event)">
                                <input #fileInput hidden type="file" accept="image/*" (change)="uploadFile($event.target.files)" />
                                <div class="avatar">
                                    <app-organization-avatar [organizationName]="name.value" [pictureAccessLink]="isPicture ? getPictureUrl() : null">
                                    </app-organization-avatar>
                                </div>
                            </div>
                            <p>
                                <span class="fw-b">Recommandations</span><br />
                                <small>.png | .jpg | .jpeg<br />2 Mo max <br />500x500 pixels</small>
                            </p>
                        </div>

                        <div class="d-flex w-50 flex-column">
                            <div class="mx-auto my-1">
                                <button type="button" class="btn btn-primary btn-picture m-auto" (click)="imageCropModal.showModal()">
                                    <em class="icon-edit d-sm-inline d-md-none"></em>
                                    <span class="d-none d-sm-inline">Mettre à jour la photo</span>
                                </button>
                            </div>
                            <div class="mx-auto">
                                <button class="btn btn-secondary btn-picture m-auto" type="button" [disabled]="!isPicture" (click)="deletePicture()">
                                    <em class="icon-close d-sm-inline d-md-none"></em>
                                    <span class="d-none d-sm-inline">Supprimer la photo</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 m-auto form-group">
                        <label class="font-weight-bold" for="name"
                            >{{
                                (isMyOrganization ? 'shared.forms.organization.nameOwn' : 'shared.forms.organization.nameGeneric') | translate
                            }}*</label
                        >
                        <input
                            class="form-control"
                            name="name"
                            id="name"
                            [formControl]="name"
                            type="text"
                            [placeholder]="'shared.forms.organization.nameGeneric' | translate"
                            maxlength="250"
                            required
                        />
                        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="d-block alert alert-danger">
                            <div *ngIf="name.errors.required">
                                {{ 'shared.forms.organization.organizationNameRequired' | translate }}
                            </div>
                            <div *ngIf="name.errors.maxlength">
                                {{ 'shared.forms.organization.organizationNameMaxLength' | translate : { maxLength: 250 } }}
                            </div>
                        </div>

                        <label class="mt-4 font-weight-bold" for="status">Statut*</label>
                        <ng-select
                            autocomplete="off"
                            [hideSelected]="true"
                            placeholder="Sélectionner un statut"
                            labelForId="status"
                            [searchable]="false"
                            [formControl]="active"
                            required
                        >
                            <ng-option [value]="true">Activé</ng-option>
                            <ng-option [value]="false">Désactivé</ng-option>
                        </ng-select>
                        <div *ngIf="active.invalid && (active.dirty || active.touched)" class="alert alert-danger mt-1">
                            <div *ngIf="active.errors.required">Une sélection est requise.</div>
                        </div>
                    </div>
                </div>

                <hr class="mt-3" />

                <h2 class="h4 primary-1 mt-3">Coordonnées</h2>
                <div class="row">
                    <div class="col-12 col-md-6 form-group">
                        <app-address-autocomplete [formControl]="address1" [isRequired]="true" (addressSelected)="onAddressSelected($event)">
                        </app-address-autocomplete>
                        <div *ngIf="address1.invalid && (address1.dirty || address1.touched)" class="alert alert-danger">
                            <div *ngIf="address1.errors.maxlength">L'adresse ne doit pas dépasser 250 caractères.</div>
                            <div *ngIf="address1.errors.required">Une adresse est requise.</div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-bold" for="address2">Complément d'adresse</label>
                        <input
                            class="form-control"
                            name="address2"
                            id="address2"
                            type="text"
                            placeholder="Complément d'adresse"
                            [formControl]="address2"
                            maxlength="250"
                        />
                        <div *ngIf="address2.invalid && (address2.dirty || address2.touched)" class="alert alert-danger">
                            <div *ngIf="address2.errors.maxlength">Le complément d'adresse ne doit pas dépasser 250 caractères.</div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-bold" for="geographicalAreaId">Secteur géographique*</label>
                        <select class="form-control" name="geographicalAreaId" id="geographicalAreaId" [formControl]="geographicalAreaId" required>
                            <option value="" disabled selected>Secteur géographique</option>
                            <option *ngFor="let geographicalArea of availableGeographicalAreas" [value]="geographicalArea.id">
                                {{ geographicalArea.name }} ({{ geographicalArea.id }})
                            </option>
                        </select>
                        <div
                            *ngIf="geographicalAreaId.invalid && (geographicalAreaId.dirty || geographicalAreaId.touched)"
                            class="alert alert-danger"
                        >
                            <div *ngIf="geographicalAreaId.errors.required">Une sélection est requise.</div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-bold" for="city">Ville*</label>
                        <input
                            class="form-control"
                            name="city"
                            id="city"
                            type="text"
                            placeholder="Ville"
                            [formControl]="city"
                            maxlength="50"
                            required
                        />
                        <div *ngIf="city.invalid && (city.dirty || city.touched)" class="alert alert-danger">
                            <div *ngIf="city.errors.required">La ville est requise.</div>
                            <div *ngIf="city.errors.maxlength">La ville ne doit pas dépasser 50 caractères.</div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-bold" for="city">Code postal*</label>
                        <input
                            class="form-control"
                            name="postalCode"
                            id="postalCode"
                            type="text"
                            placeholder="Code postal"
                            [formControl]="postalCode"
                            maxlength="50"
                            required
                        />
                        <div *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)" class="alert alert-danger">
                            <div *ngIf="city.errors.required">Le code postal est requis.</div>
                            <div *ngIf="city.errors.maxlength">Le code postal ne doit pas dépasser 50 caractères.</div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-bold" for="phoneNumber">{{ 'user.personalInfo.phonePlaceholder' | translate }}</label>
                        <input
                            class="form-control"
                            name="phoneNumber"
                            id="phoneNumber"
                            type="text"
                            placeholder="{{ 'user.personalInfo.phonePlaceholder' | translate }}"
                            [formControl]="phoneNumber"
                            maxlength="21"
                        />
                        <div *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)" class="alert alert-danger">
                            <div *ngIf="phoneNumber.errors.pattern">Le format du N° de téléphone est incorrect.</div>
                            <div *ngIf="phoneNumber.errors.maxlength">Le N° de téléphone ne doit pas dépasser 21 caractères.</div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-bold" for="email">Email de contact</label>
                        <input
                            class="form-control"
                            name="email"
                            id="email"
                            type="email"
                            placeholder="Email de contact"
                            [formControl]="email"
                            maxlength="180"
                            (keyup)="email.setValue(email.value.toLowerCase())"
                        />
                        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                            <div *ngIf="email.errors.pattern">Le format de l'email est incorrect.</div>
                            <div *ngIf="email.errors.maxlength">L'email ne doit pas dépasser 180 caractères.</div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-bold" for="webSite">Site internet</label>
                        <input
                            class="form-control"
                            name="webSite"
                            id="webSite"
                            type="url"
                            placeholder="Site internet (ex. https://monsite.fr)"
                            [formControl]="webSite"
                            maxlength="2048"
                        />
                        <div *ngIf="webSite.invalid && (webSite.dirty || webSite.touched)" class="alert alert-danger">
                            <div *ngIf="webSite.errors.pattern">Le format de l'URL du site Web est incorrect.<br /></div>
                            <div *ngIf="webSite.value.maxlength">
                                L'URL du site Web ne doit pas dépasser
                                <strong>2048</strong> caractères.
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 form-group">
                        <label class="font-weight-bold" for="webSite">Lien vers la page LinkedIn de l'organisation</label>
                        <input
                            class="form-control"
                            name="linkedIn"
                            id="linkedIn"
                            type="text"
                            placeholder="Adresse web de la page"
                            [formControl]="linkedIn"
                            maxlength="100"
                        />
                        <div *ngIf="linkedIn.invalid && (linkedIn.dirty || linkedIn.touched)" class="alert alert-danger">
                            <div *ngIf="linkedIn.errors.maxlength">
                                {{ 'user.personalInfo.linkedInLength' | translate }}
                            </div>
                            <div *ngIf="linkedIn.errors.pattern">
                                {{ 'user.personalInfo.linkedInFormat' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br />

    <div class="row">
        <div class="col-12 mt-2 card box-shadow">
            <div class="card-body px-0">
                <h2 class="h4 primary-1">Informations</h2>
                <div class="row">
                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-bold" for="businessArea">{{ 'directory.pages.userProfile.businessArea' | translate }}*</label>
                        <select class="form-control" name="businessAreaId" id="businessAreaId" [formControl]="businessAreaId" required>
                            <option value="" disabled selected>
                                {{ 'directory.pages.userProfile.businessArea' | translate }}
                            </option>
                            <option *ngFor="let businessArea of availableBusinessAreas" [value]="businessArea.id">
                                {{ businessArea.name | wtranslate : 'businessAreas' }}
                            </option>
                        </select>
                        <div *ngIf="businessAreaId.invalid && (businessAreaId.dirty || businessAreaId.touched)" class="alert alert-danger">
                            <div *ngIf="businessAreaId.errors.required">Une sélection est requise.</div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="font-weight-bold" for="organizationType">{{ 'shared.forms.organization.type' | translate }}*</label>
                        <select class="form-control" name="organizationTypeId" id="organizationTypeId" [formControl]="organizationTypeId" required>
                            <option value="" disabled selected>
                                {{ 'shared.forms.organization.type' | translate }}
                            </option>
                            <option *ngFor="let organizationTypeItem of availableOrganizationTypes" [value]="organizationTypeItem.typeId">
                                {{ organizationTypeItem.name | wtranslate : 'organizationTypes' }}
                            </option>
                        </select>
                        <div
                            *ngIf="organizationTypeId.invalid && (organizationTypeId.dirty || organizationTypeId.touched)"
                            class="alert alert-danger"
                        >
                            <div *ngIf="organizationTypeId.errors.required">Une sélection est requise.</div>
                        </div>
                    </div>

                    <div class="col-12 form-group">
                        <div class="row">
                            <div class="col-12 d-block font-weight-bold">
                                <span class="float-left">{{ 'shared.forms.organization.description' | translate }}</span>
                                <span class="float-right">
                                    <span
                                        [class.text-danger]="description.errors?.maxlength"
                                        [class.font-weight-bold]="description.errors?.maxlength"
                                    >
                                        {{ descriptionCounter }} </span
                                    >/{{ ORGANIZATION_DESCRIPTION_MAX_LENGTH }}
                                </span>
                            </div>

                            <div class="col-12">
                                <quill-editor
                                    [placeholder]="'shared.forms.organization.descriptionPlaceholder' | translate"
                                    theme="snow"
                                    [modules]="quillEditorHelper.modules"
                                    [formats]="quillEditorHelper.formats"
                                    [formControl]="description"
                                    (onContentChanged)="onDescriptionChanged($event.editor)"
                                    class="notranslate"
                                >
                                </quill-editor>
                                <div *ngIf="description.invalid && (description.dirty || description.touched)" class="alert alert-danger">
                                    <div *ngIf="description.errors?.maxlength">
                                        {{
                                            'shared.forms.organization.descriptionMaxLength'
                                                | translate : { maxLength: ORGANIZATION_DESCRIPTION_MAX_LENGTH }
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12 mt-2" *ngIf="isAdmin">
                        <label for="communities">{{ 'filters.shared.communitiesFilters' | translate }}*</label>
                        <ng-select
                            autocomplete="off"
                            [items]="availableCommunities"
                            bindLabel="displayName"
                            labelForId="selectedCommunities"
                            [multiple]="true"
                            placeholder="Sélectionner des communautés"
                            clearAllText="Supprimer toutes les communautés"
                            [closeOnSelect]="false"
                            [hideSelected]="true"
                            [formControl]="communities"
                            (add)="onAddCommunity($event)"
                            (remove)="onRemoveCommunity($event)"
                            (clear)="onClearAllCommunities()"
                        >
                        </ng-select>
                        <div *ngIf="communities.invalid && (communities.dirty || communities.touched)" class="alert alert-danger mt-1">
                            <div *ngIf="communities.errors.required">Une sélection est requise.</div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12 mt-2" *ngIf="isAdmin">
                        <label for="primaryCommunity">Communauté principale*</label>
                        <ng-select
                            autocomplete="off"
                            [hideSelected]="true"
                            placeholder="Sélectionner une communauté principale"
                            labelForId="primaryCommunity"
                            [items]="selectedCommunities"
                            bindLabel="displayName"
                            bindValue="id"
                            [formControl]="primaryCommunityId"
                        >
                        </ng-select>
                        <div
                            *ngIf="primaryCommunityId.invalid && (primaryCommunityId.dirty || primaryCommunityId.touched)"
                            class="alert alert-danger mt-1"
                        >
                            <div *ngIf="primaryCommunityId.errors.required">Une sélection est requise.</div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="isAdmin && selectedCommunitiesWithGroups?.length">
                        <div class="form-row mt-2">
                            <div class="col-12 mt-1">
                                <span class="font-weight-bold">Groupes communautaires</span>
                            </div>
                            <div
                                class="col-md-6 col-sm-12 mt-1"
                                *ngFor="let communityGroupsControl of communitiesGroups?.controls"
                                [formGroup]="communityGroupsControl"
                            >
                                <span *ngIf="communityGroupsControl.value.communitiesGroups">
                                    <label for="communityGroups" class="text-center">
                                        {{ getCommunityNameById(communityGroupsControl.value.id) }}
                                    </label>
                                    <ng-select
                                        autocomplete="off"
                                        [hideSelected]="true"
                                        [multiple]="true"
                                        labelForId="communityGroups"
                                        clearAllText="Supprimer tous les groupes"
                                        placeholder="Sélectionnez un groupe"
                                        formControlName="communitiesGroups"
                                        [items]="getCommunityGroupById(communityGroupsControl.value.id)"
                                        bindLabel="displayName"
                                        bindValue="id"
                                    >
                                    </ng-select>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 form-group" *ngIf="!skills.disabled">
                        <div *ngIf="(skillsComponent.dataLoaded | async) === false" class="spinner-border text-info" role="status">
                            <span class="sr-only">Chargement des compétences...</span>
                        </div>
                        <app-skills
                            class="col-12 mt-4"
                            context="organization"
                            [currentSkills]="skillsAsModel"
                            [maxSkills]="ORGANIZATION_SKILLS_MAX"
                            (changed)="onChangedSkills($event)"
                            [skillsDomainPlaceholder]="'home.onboardingProfile.skills.domainplaceholder' | translate"
                            [skillsPlaceHolder]="'home.onboardingProfile.skills.skillsplaceholder' | translate"
                            #skillsComponent
                        >
                        </app-skills>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mt-2 px-0">
            <div class="float-left">
                <button type="button" class="btn btn-secondary" (click)="goBack()">
                    <i class="icon icon-close d-sm-inline d-md-none"></i>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonCancel' | translate }}</span>
                </button>
            </div>
            <div class="float-right">
                <button
                    type="button"
                    class="btn btn-primary btn-right"
                    data-toggle="modal"
                    data-target="#updateOrganization"
                    [disabled]="form.invalid"
                >
                    <i class="icon-save d-sm-inline d-md-none"></i>
                    <span class="d-none d-sm-inline">{{ 'components.button.save' | translate }}</span>
                </button>
            </div>
        </div>
    </div>

    <div class="modal fade bd-example-modal-lg" id="updateOrganization" tabindex="-1" role="dialog" aria-labelledby="modalConfirm" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalConfirm">Enregistrer vos modifications</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <p>
                        {{
                            (isMyOrganization ? 'shared.forms.organization.confirmSaveOwn' : 'shared.forms.organization.confirmSaveGeneric')
                                | translate
                        }}
                    </p>
                </div>

                <div class="modal-footer">
                    <input type="button" class="btn btn-secondary" data-dismiss="modal" value="{{ 'components.button.buttonCancel' | translate }}" />
                    <input type="button" class="btn btn-primary" data-dismiss="modal" value="Oui!" [disabled]="form.invalid" (click)="onSubmit()" />
                </div>
            </div>
        </div>
    </div>
</form>
