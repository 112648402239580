import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Filters } from '../../models/identity/filters.model';
import { Member } from '../../models/identity/member.model';
import { PwaStatus } from '../../models/identity/pwa-status.enum.model';
import { ResetPasswordCommand } from '../../models/identity/reset-password-command.model';
import { UpdateCompleteProfileCommand } from '../../models/identity/update-complete-profile-command.model';
import { UpdateProfileCommand } from '../../models/identity/update-profile-command.model';
import { UpdateProfilePictureCommand } from '../../models/identity/update-profile-picture-command';
import { User } from '../../models/identity/user.model';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private profilesApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.profilesApi.baseUrl}`;
    private projectTeamApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.projectsApi.baseUrl}`;

    constructor(private http: HttpClient) {}

    getUserProfileById(userId: string) {
        return this.http.get<User>(`${this.profilesApiUrl}/${userId}`);
    }

    getCurrentProfile() {
        return this.http.get<User>(`${this.profilesApiUrl}/${environment.microservices.identity.profilesApi.me}`);
    }

    getMultipleProfiles(userIds: string[], complete = true) {
        const params = new HttpParams().set('complete', complete.toString());

        return this.http.post<User[]>(`${this.profilesApiUrl}/${environment.microservices.identity.profilesApi.multipleProfiles}`, userIds, {
            params: params,
        });
    }

    getProjectTeam(id: string): Observable<Member[]> {
        return this.http.get<Member[]>(this.projectTeamApiUrl + '/' + id + '/Profiles');
    }

    getFilteredProfiles(filters: Filters): Observable<User[]> {
        return this.http.post<User[]>(`${this.profilesApiUrl}`, filters);
    }

    updateProfile(profile: UpdateProfileCommand): Observable<User> {
        console.log('return of request update profile', profile);
        return this.http.put<User>(this.profilesApiUrl, profile);
    }

    updateProfilePicture(picture: UpdateProfilePictureCommand): Observable<User> {
        return this.http.put<User>(
            `${this.profilesApiUrl}/${environment.microservices.identity.profilesApi.me}/${environment.microservices.identity.profilesApi.profilePicture}`,
            picture,
        );
    }

    updateCompleteProfile(completeProfile: UpdateCompleteProfileCommand): Observable<User> {
        return this.http.put<User>(
            `${this.profilesApiUrl}/${environment.microservices.identity.profilesApi.me}/${environment.microservices.identity.profilesApi.completeProfile}`,
            completeProfile,
        );
    }

    resetPassword(email: string) {
        const resetPasswordCommand = new ResetPasswordCommand();
        resetPasswordCommand.email = email;

        return this.http.post(`${this.profilesApiUrl}/${environment.microservices.identity.profilesApi.resetPassword}`, resetPasswordCommand);
    }

    reset(): Observable<any> {
        return this.http.get<any>(`${this.profilesApiUrl}/Me/Reset`);
    }

    deleteUser(userId: string): Observable<any> {
        return this.http.delete<any>(`${this.profilesApiUrl}/${userId}`);
    }

    completeProductTour(userId: string): Observable<any> {
        return this.http.post<any>(`${this.profilesApiUrl}/${userId}/complete-product-tour`, null);
    }
    setPwaStatus(status: PwaStatus): Observable<PwaStatus> {
        return this.http.post<PwaStatus>(`${this.profilesApiUrl}/${environment.microservices.identity.profilesApi.pwaStatus}/${status}`, null);
    }

    completeCommunityPresentation(userId: string): Observable<User> {
        return this.http.post<User>(`${this.profilesApiUrl}/${userId}/complete-community-presentation`, null);
    }
}
