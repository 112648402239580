<div *ngIf="user" class="header row d-flex align-items-center px-1 py-3 mx-0 mb-4">
    <div class="col-12 col-sm-auto">
        <div class="row">
            <div class="col-auto d-flex align-items-center pr-0">
                <app-user-avatar
                    [firstName]="user.firstName"
                    [lastName]="user.lastName"
                    [picture]="user.pictureAccessLink"
                ></app-user-avatar>
            </div>
            <div class="col">
                <strong class="d-block mb-1">{{ 'events.pages.details.eventInitiator' | translate }}</strong>
                <p>{{ user.firstName }} {{ user.lastName | uppercase }}</p>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-auto mt-4 mt-sm-0">
        <div class="row">
            <div class="col-auto d-flex align-items-center pr-0">
                <app-organization-avatar
                    [organizationName]="user.organization.name"
                    [pictureAccessLink]="user.organization.pictureAccessLink"
                >
                </app-organization-avatar>
            </div>
            <div class="col">
                <strong class="d-block mb-1">{{ 'user.personalInfo.organizationTitle' | translate }}</strong>
                <p>{{ user.organization.name }}</p>
            </div>
        </div>
    </div>
    <div class="col-12 col-md mt-4 mt-md-0">
        <strong>{{ 'user.personalInfo.informationTitle' | translate }}</strong>
        <p>
            {{ 'user.personalInfo.informationDescription' | translate }}
        </p>
    </div>
</div>
