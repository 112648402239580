<div>
    <div class="p-my-3 p-grid p-jc-between">
        <div class="p-col-5">
            <h2>Paramètres</h2>
        </div>
        <div class="p-col-2"></div>
        <div class="p-col-3 align-text-right">
            <p-button
                *ngIf="isAdmin && !isCreateMode"
                class="create-button"
                label="Créer une communauté"
                routerLink="/community/create"
                [state]="{ communities: communities }"
            ></p-button>
        </div>
        <div class="p-col-2 align-text-right">
            <p-button class="" label="{{ submitButtonLabel }}" (onClick)="onSubmit()" [disabled]="!communityForm.valid"></p-button>
        </div>
    </div>
    <div class="p-shadow-1 card">
        <form [formGroup]="communityForm" class="container user-form p-px-0" novalidate>
            <div class="p-grid p-mt-2 p-mx-4">
                <div class="p-col-12">
                    <div class="p-grid">
                        <div>
                            <h4 class="form-label" for="displayName">Nom de la communauté</h4>
                        </div>
                        <div class="p-ml-5 p-my-auto">
                            {{ communityForm.controls.displayName?.value?.length || '0' }}/{{ COMMUNITY_NAME_MAX_LENGTH }}
                        </div>
                    </div>
                    <input
                        pInputText
                        class="form-control community-name"
                        type="text"
                        name="displayName"
                        id="displayName"
                        formControlName="displayName"
                        aria-describedby="displayName"
                        placeholder="Nom de la communauté"
                        maxlength="60"
                        required
                    />
                    <div
                        class="alert alert-danger mt-1 ng-star-inserted"
                        *ngIf="communityForm.controls.displayName.touched && communityForm.controls.displayName.errors"
                    >
                        <div class="ng-star-inserted">Le nom de la communauté est obligatoire et ne doit pas dépasser 60 caractères.</div>
                    </div>
                </div>
                <div class="p-col-12">
                    <div class="p-grid">
                        <div>
                            <h4 class="form-label" for="parentsIds">Type de communauté</h4>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-6">
                            <div class="d-flex">
                                <p class="licences-label p-my-0 ml-4">
                                    Permettre la création de club additionnel?
                                    <p-checkbox binary="true" formControlName="canCreateClub"> </p-checkbox>
                                </p>
                            </div>
                            <div class="d-flex">
                                <p class="licences-label p-my-0 ml-4">
                                    Permettre la création de projets?
                                    <p-checkbox binary="true" formControlName="canCreateProject"> </p-checkbox>
                                </p>
                            </div>
                            <div class="d-flex">
                                <p class="licences-label p-my-0 ml-4">
                                    Permettre la création d'évènements?
                                    <p-checkbox binary="true" formControlName="canCreateEvent"> </p-checkbox>
                                </p>
                            </div>
                            <div class="d-flex">
                                <p class="licences-label p-my-0 ml-4">
                                    Module dashboard
                                    <p-checkbox binary="true" formControlName="hasAccessToDashboard"> </p-checkbox>
                                </p>
                            </div>
                        </div>
                        <div class="p-col-6 p-d-flex p-ai-start">
                            <p class="licences-label p-my-0">
                                Newsletter intelligente
                                <p-checkbox binary="true" formControlName="hasActivateIntelligentNewsLetter"> </p-checkbox>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12">
                    <div class="p-grid">
                        <div>
                            <h4 class="form-label" for="parentsIds">Communauté(s) parents</h4>
                        </div>
                    </div>
                    <p-multiSelect
                        [options]="filteredCommunities"
                        placeholder="Aucun"
                        optionLabel="displayName"
                        [(ngModel)]="selectedMetaCommunities"
                        [ngModelOptions]="{ standalone: true }"
                        (onChange)="onMetaCommunitySelect($event)"
                        [style]="{ width: '100%' }"
                    ></p-multiSelect>
                </div>

                <div class="p-col-12" *ngIf="isCreateMode">
                    <div class="p-grid">
                        <div>
                            <h4 class="form-label" for="displayName">Id de la communauté</h4>
                        </div>
                        <div class="p-ml-1 p-my-auto">(facultatif)</div>
                    </div>
                    <input
                        pInputText
                        class="form-control"
                        type="text"
                        name="id"
                        id="id"
                        formControlName="id"
                        aria-describedby="id"
                        placeholder="Id de la communauté"
                        maxlength="36"
                    />
                    <div class="alert alert-danger mt-1 ng-star-inserted" *ngIf="communityForm.controls.id.errors">
                        <div class="ng-star-inserted">
                            L'id de la communauté est facultative, mais, si elle est renseignée, doit respecter le format
                            XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX.
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-grid p-jc-between p-mt-2 p-mx-4" *ngIf="!isCreateMode">
                <div>
                    <h4 class="form-label" for="displayName">Groupes communautaires</h4>
                </div>
                <div class="p-grid p-my-auto edit-community-group-label">
                    <div class="p-mr-2 enabled" (click)="openCreateOrUpdateCommunityGroupModal(true)">Créer</div>
                    <div
                        class="p-mr-2"
                        [ngClass]="{ enabled: communityGroupIdSelected != null && communityGroupIdSelected !== '' }"
                        (click)="openCreateOrUpdateCommunityGroupModal(false)"
                    >
                        {{ 'components.button.modify' | translate }}
                    </div>
                    <div
                        class="p-mr-2"
                        [ngClass]="{ enabled: communityGroupIdSelected != null && communityGroupIdSelected !== '' }"
                        (click)="openDeleteCommunityGroupModal()"
                    >
                        {{ 'components.button.buttonDelete' | translate }}
                    </div>
                </div>
            </div>
            <div class="p-mt-2 p-mx-4" *ngIf="displayCommunityGroupDiv && !isCreateMode">
                <p-virtualScroller [value]="community.communitiesGroups" scrollHeight="10rem" class="community-group-list">
                    <ng-template let-group pTemplate="item" let-i="index">
                        <div
                            class="product-item p-p-2"
                            [ngClass]="{ 'community-group-selected': communityGroupIdSelected == group.id }"
                            (click)="communityGroupIdSelected = group.id"
                        >
                            <div class="product-list-detail">
                                {{ group.displayName }}
                            </div>
                        </div>
                    </ng-template>
                </p-virtualScroller>
            </div>
        </form>
    </div>
</div>

<p-dialog
    header="{{ isCreateCommunityGroupModalVisible ? 'Créer' : 'Modifier' }} un groupe communautaire"
    *ngIf="isCreateCommunityGroupModalVisible || isUpdateCommunityGroupModalVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    (onHide)="closeModal()"
    styleClass="custom-create-community-group-modal"
    appendTo="body"
>
    <form [formGroup]="communityGroupForm" class="container user-form p-px-0" novalidate>
        <div>Nom du groupe communautaire</div>
        <input
            pInputText
            type="text"
            name="displayName"
            id="displayName"
            formControlName="displayName"
            aria-describedby="displayName"
            placeholder="Nom du groupe communautaire"
            maxlength="60"
            required
            class="p-mt-2 community-group-name-input"
        />
    </form>
    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="closeModal()"
        ></button>

        <button
            pButton
            class="p-button p-button-sm p-button-custom-submit p-button-custom-validate"
            type="button"
            label="{{ 'components.button.buttonConfirm' | translate }}"
            [disabled]="!communityGroupForm.valid"
            (click)="onCreateOrUpdateCommunityGroup()"
        ></button>
    </p-footer>
</p-dialog>

<p-dialog
    header="Supprimer un groupe communautaire"
    *ngIf="isDeleteCommunityGroupModalVisible"
    [(visible)]="isDeleteCommunityGroupModalVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    class=""
    [closable]="false"
    appendTo="body"
>
    <div>Vous êtes sur le point de supprimer un groupe communautaire. Confirmez-vous cette action ?</div>
    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="closeModal()"
        ></button>

        <button
            pButton
            class="p-button p-button-sm p-button-custom-submit p-button-custom-validate"
            type="button"
            label="{{ 'components.button.buttonConfirm' | translate }}"
            (click)="onDeleteCommunityGroup()"
        ></button>
    </p-footer>
</p-dialog>
