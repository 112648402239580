import { Component, Input } from '@angular/core';

export enum AlertStatusEnum {
    None = 0,
    Success = 1,
    Error = 2,
    Info = 3,
}
const DEFAULT_MESSAGE_SUCCESS = 'Enregistré avec succès';
const DEFAULT_MESSAGE_ERROR = "Erreur survenue lors de l'enregistrement";

@Component({
    selector: 'app-alert-message',
    templateUrl: './alert-message.component.html',
})
export class AlertMessageComponent {
    private _status = AlertStatusEnum.None;
    @Input()
    set status(status: AlertStatusEnum) {
        this._status = status || AlertStatusEnum.None;
        switch (this._status) {
            case AlertStatusEnum.None: {
                this.message = null;
                break;
            }
            case AlertStatusEnum.Success: {
                this.message = DEFAULT_MESSAGE_SUCCESS;
                break;
            }
            case AlertStatusEnum.Error: {
                this.message = DEFAULT_MESSAGE_ERROR;
                break;
            }
        }
    }
    get status(): AlertStatusEnum {
        return this._status;
    }

    @Input() message: string;

    AlertStatusEnum = AlertStatusEnum;
}
