import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Club } from '../../models/clubs/club.model';

@Injectable({
    providedIn: 'root',
})
export class CommunityClubService {
    private clubsApiUrl = `${environment.microservices.clubs.baseUrl}/${environment.microservices.clubs.clubsApi.apiVersion}`;

    constructor(private http: HttpClient) {}

    public get(communityId: string): Observable<Club> {
        return this.http.get<Club>(`${this.clubsApiUrl}/communities/club/${communityId}`);
    }

    public setPresentationRoom(communityId: string, roomId: string): Observable<any> {
        const command = new SetDefaultRoomCommand(communityId, roomId);
        return this.http.post<Club>(`${this.clubsApiUrl}/communities/club/set-presentation-room`, command);
    }
}

class SetDefaultRoomCommand {
    communityId: string;
    roomId: string;

    constructor(communityId: string, roomId: string) {
        this.communityId = communityId;
        this.roomId = roomId;
    }
}
