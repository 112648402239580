<div class="terms-of-use" *ngIf="locale === 'fr'">
    <h1>Conditions Générales d'Utilisation</h1>

    <p>
        {{ societyDetails.name }} propose deux profils d'utilisateurs différents : le profil complet utilisé par défaut
        (standard), et le profil &laquo; invité &raquo; :
    </p>

    <ul>
        <li>
            Le profil complet est ouvert en vertu du droit d'accès contracté par le Client au profit des Utilisateurs
            désignés par lui.
        </li>
        <li>
            Le profil &laquo; invité &raquo;, aux droits limités, est ouvert directement par
            {{ societyDetails.name }} au profit de l'Utilisateur afin que celui-ci découvre la Plateforme et/ou y
            apporte son expertise, sans pouvoir créer {{ dictionary.minDeProjets }} ou demandes de compétences sur
            celle-ci. Les comptes &laquo; invités &raquo; ne sont accessibles que sur invitation expresse de
            {{ societyDetails.name }}, à titre gratuit, et sont susceptibles d'être révoqués à tout moment par décision
            unilatérale de {{ societyDetails.name }}, avec un préavis de 3 jours ouvrés communiqué par courriel, sans
            que {{ societyDetails.name }} ne soit tenu d'un quelconque dédommagement.
        </li>
    </ul>

    <p>
        Dans le cas d'un profil &laquo; invité &raquo;, votre inscription vaut acceptation des présentes Conditions
        Générales d'Utilisation, ainsi que des Conditions Générales de Vente et du Service Level Agreement, à titre
        personnel. Il est à noter que les dispositions suivantes ne vous sont pas applicables :
    </p>

    <ul>
        <li>Création de demandes de compétences et {{ dictionary.minProjets }} ;</li>
        <li>Programme de fidélité ;</li>
        <li>Durée du contrat.</li>
    </ul>

    <p class="font-weight-bold">
        Dans le but de valider votre inscription, vous devez prendre connaissance des Conditions Générales &laquo;
        Utilisateur &raquo; ci-dessous.
    </p>

    <p class="font-weight-bold">
        Votre inscription définitive sur la plateforme {{ societyDetails.name }} vaut acceptation des conditions
        stipulées ci-après.
    </p>

    <h2>1. Conditions de participation</h2>

    <p>L'Utilisateur accepte sans aucune réserve les termes des présentes conditions d'utilisation.</p>

    <p>
        Le Service est ouvert à toute personne ayant été autorisée à participer par son employeur (ce dernier étant
        défini comme le &laquo; Client &raquo;), ou toute personne ayant été invitée par {{ societyDetails.name }} SAS.
    </p>

    <p>L'Utilisateur s'engage à faire preuve de loyauté et de courtoisie à l'égard des autres Utilisateurs.</p>

    <p>
        Dans le cas où un Utilisateur contreviendrait à l'une quelconque de ses obligations,
        {{ societyDetails.name }} se réserve le droit d'exclure ledit Utilisateur.
    </p>

    <p>
        {{ societyDetails.name }} pourra, dans le cadre de dépôt {{ dictionary.minDeProjets }} sur sa plateforme,
        exercer un contrôle, tant sur le fond que sur la forme, relativement {{ dictionary.minAuProjet }} déposé.
        {{ societyDetails.name }} pourra notamment demander à l'Utilisateur de reformuler, autant de fois que
        nécessaire, le contenu de son {{ dictionary.minProjet }}, afin que ce dernier soit conforme à toute loi ou tout
        règlement applicable. Ce contrôle portera notamment afin d'interdire tout contenu diffamant, injurieux, raciste,
        discriminant, pédopornographique, &hellip;.
    </p>

    <h2>2. Propriété intellectuelle</h2>

    <h3>
        2.1. Propriété intellectuelle issue des échanges de compétences, groupes de travail et
        {{ dictionary.minProjets }} interentreprises via l'utilisation de la Plateforme
    </h3>

    <p>
        Toutes les créations originales, inventions, bases de données, dénominations, marques ou autres signes
        distinctifs ne peuvent être utilisés, reproduits et représentés par l'Utilisateur que :
    </p>

    <ul>
        <li>S'il détient les droits sur ces éléments ;</li>
        <li>Si ces éléments sont libres de droit ;</li>
        <li>S'il y est autorisé par celui qui détient les droits sur ces éléments (par un Client, par exemple).</li>
    </ul>

    <p>
        Toutes les créations originales, base de données, dénominations, marques ou autres signes distinctifs présents
        sur les pages de la plateforme demeurent la propriété exclusive de leur auteur ou de leur déposant. Cependant la
        reproduction et la représentation de tout ou partie de ces éléments sont autorisés à titre gracieux dans le
        strict cadre de la participation à l'activité de la Plateforme.
    </p>

    <p>
        {{ societyDetails.name }} n'exerce aucun contrôle sur la propriété des éléments et/ou échangés sur la
        plateforme.
    </p>

    <p>
        En aucun cas {{ societyDetails.name }} ne pourra voir sa responsabilité engagée par l'Utilisateur ou tout tiers
        concernant une question relative à la propriété intellectuelle {{ dictionary.minDesProjets }} et/ou de solutions
        issues {{ dictionary.minDesProjets }}. Les risques relatifs à la propriété intellectuelle et assimilés sont
        assumés exclusivement par l'Utilisateur etson employeur (Client) au sein {{ dictionary.minDesProjets }} auxquels
        les Utilisateurs participent.
    </p>

    <p>Sauf disposition contraire prévue dans un accord séparé :</p>

    <ul>
        <li>l'Utilisateur reste propriétaire des connaissances sur lesquelles il a des droits antérieurs ;</li>
        <li>
            l'Utilisateur reste propriétaire des connaissances nouvelles qui lui sont attribuables dans le cadre
            {{ dictionary.minDesProjets }} ;
        </li>
        <li>
            dans l'hypothèse où des résultats nouveaux ne constituant ni une œuvre de l'esprit, ni une invention, sont
            obtenus dans le cadre {{ dictionary.minDesProjets }}, l'Utilisateur reste propriétaire des résultats
            nouveaux qui lui sont attribuables dans le cadre desdits {{ dictionary.minProjets }} ;
        </li>
        <li>
            toute œuvre de l'esprit, créée dans le cadre d'un {{ dictionary.majProjet }} réunissant plusieurs
            contributions, sera qualifiée d'œuvre de collaboration au sens de l'article L113-2 du Code de Propriété
            Intellectuelle.
        </li>
    </ul>

    <p>
        Afin de déroger aux principes de la Plateforme, il revient aux Utilisateurs de mettre en place une convention de
        partenariat qui régira les droits et obligations de chaque Utilisateur intervenant {{ dictionary.minAuProjet }}.
        {{ societyDetails.name }} attire l'attention sur l'importance d'établir un tel accord lorsque
        {{ dictionary.minCeProjet }} est susceptible de donner naissance à des connaissances nouvelles, des inventions,
        des œuvres de l'esprit, du savoir-faire ou tout résultat nouveau de manière générale.
        {{ societyDetails.name }} propose des modèles de contrats aux porteurs {{ dictionary.minDeProjets }}, qu'ils
        peuvent librement amender et proposer aux contributeurs intervenant sur leurs {{ dictionary.minProjets }}. Ces
        contrats sont proposés à titre indicatif et n'engagent en rien {{ societyDetails.name }}. Dans le cas où les
        Utilisateurs, membres d'une équipe {{ dictionary.minProjet }}, ne parviennent pas à un accord,
        {{ societyDetails.name }} pourra intervenir en tant que médiateur. A défaut de résolution,
        {{ societyDetails.name }} se réserve le droit d'exclure un Utilisateur {{ dictionary.minDuProjet }} concerné, ou
        d'exclure {{ dictionary.minLeProjet }} de la Plateforme dans son ensemble.
    </p>

    <p>
        {{ societyDetails.name }} n'a aucun droit de préférence ou de priorité sur une éventuelle collaboration ou
        exploitation commerciale ou non commerciale {{ dictionary.minDesProjets }}. Chaque Utilisateur porteur
        {{ dictionary.minDeProjet }} fait son affaire de l'exploitation future potentielle de son
        {{ dictionary.minProjet }}, en accord avec les autres Utilisateurs participants.
    </p>

    <h3>2.2. Propriété intellectuelle attachée à l'utilisation de Plateforme</h3>

    <p>
        {{ societyDetails.name }}, ou tout tiers qu'il représente, et avec lequel un contrat a été signé, est et demeure
        titulaire des droits de propriété relatifs à tout élément de la Plateforme mise à disposition du Client, ainsi
        que plus généralement de l'infrastructure logicielle mise en œuvre ou développée dans le cadre du Contrat.
    </p>

    <p>Le Contrat ne confère au Client aucun droit de propriété sur la Plateforme.</p>

    <p>
        La mise à disposition temporaire de la Plateforme dans les conditions prévues au Contrat ne saurait être
        analysée comme la cession d'un quelconque droit de propriété intellectuelle au bénéfice du Client, au sens du
        Code français de la propriété intellectuelle.
    </p>

    <p>
        Le Client s'interdit de reproduire tout élément des Logiciels, ou toute Documentation les concernant, par
        quelque moyen que ce soit, sous quelque forme que ce soit et sur quelque support que ce soit.
    </p>

    <p>
        Le Client ne pourra céder tout ou partie des droits et obligations résultant du Contrat, que ce soit dans le
        cadre d'une cession temporaire, d'une sous-licence et de tout autre contrat prévoyant le transfert desdits
        droits et obligations.
    </p>

    <h3>
        2.3. Propriété intellectuelle des éléments présents sur le site {{ societyDetails.website }} et la Plateforme :
    </h3>

    <p>
        Tous les éléments qui composent le site
        <a href="{{ societyDetails.website }}">{{ societyDetails.website }}</a> et sa Plateforme, et notamment des
        marques, logos, textes, données, dessins, graphiques, photos, animations, sons, codes informatiques,
        agencements, assemblages de tout ou partie d'un élément du site, y compris des documents téléchargeables, sont
        et restent notre propriété ou celle de nos ayants droit.
    </p>

    <p>
        L'ensemble des contenus du site internet et de la Plateforme (images, textes, vidéos, base de données&hellip;)
        sont soumis à la législation française et internationale en vigueur en matière de propriété intellectuelle
        (droit d'auteur, droit des marques, droit des bases de données, etc.). Si vous constatez que des éléments
        publiés sur le site ou la plateforme portent atteinte à des droits d'auteur ou autres droits protégés, merci de
        contacter
        {{ societyDetails.name }} à l'adresse suivante : <a href="mailto:{{ emails.support }}">{{ emails.support }}</a
        >.
    </p>

    <p>
        Nous ne vous concédons qu'une autorisation de visualisation du contenu de notre site à titre personnel et privé,
        à l'exclusion de toute visualisation ou diffusion publique. L'autorisation de reproduction ne vous est concédée
        que sous forme numérique sur votre ordinateur de consultation aux fins de visualisation des pages consultées par
        votre logiciel de navigation. Toute autre utilisation (telle que notamment reproduction des marques et logos,
        extraction de tout ou partie des bases de données, etc.) est interdite sans notre autorisation préalable et
        expresse.
    </p>

    <h2>3. Programme de fidélité {{ societyDetails.name }}</h2>

    <p>
        {{ societyDetails.name }} a mis en place, à l'attention de ses Utilisateurs, un système de fidélité (ci-après le
        &laquo; Programme &raquo;) matérialisé par l'accumulation de &laquo; WuKoins &raquo; (ci-après les &laquo;
        WuKoins &raquo;).
    </p>

    <p>
        {{ societyDetails.name }}, agissant de bonne foi, se réserve le droit d'annuler ou de remplacer le Programme ou
        d'amender à tout moment le Programme, ses conditions, la structure d'accumulation des WuKoins, ou les procédures
        relatives au Programme. Un Utilisateur ou un Membre ne pourra en aucun cas former une demande d'indemnisation
        contre {{ societyDetails.name }} à la suite de modifications spécifiques imposées par la législation propre à
        chaque pays. {{ societyDetails.name }} se réserve le droit de faire des offres promotionnelles destinées à des
        groupes d'Utilisateurs en fonction de leurs activités dans le Programme.
    </p>

    <p>
        Les Utilisateurs ne peuvent pas percevoir de WuKoins pour leur participation en heures de missions à leurs
        propres {{ dictionary.minProjets }}, ou pour leur participation en heures de missions à
        {{ dictionary.minDesProjets }} portés par un Utilisateur attaché au même Client.
    </p>

    <p>
        Les WuKoins émis restent valables pendant une durée de deux (2) ans. {{ societyDetails.name }} pourra donc
        annuler les WuKoins reçus à l'issue d'une période d'inactivité de ces WuKoins pendant une durée de deux (2) ans.
    </p>

    <p>
        Les WuKoins accumulés par un Utilisateur ne peuvent en aucune circonstance être transférés, légués, cédés, ou
        vendus – que ce soit ou non à titre onéreux et quelle que soit la forme de ce transfert, en dehors du programme.
    </p>

    <p>
        L'utilisateur pourra, à l'aide de ses WuKoins, bénéficier d'avantages qui seront mis à jour sur la Plateforme et
        à l'intérieur d'un &laquo; catalogue de services &raquo; {{ societyDetails.name }}.
    </p>

    <h2>4. Traitement des données</h2>

    <p class="font-weight-bold">
        Il ressort de l'article 5 du Contrat-cadre signé avec le Client les définitions et obligations suivantes :
    </p>

    <p>
        <span class="font-weight-bold">&laquo; Données à Caractère Personnel &raquo;</span> désigne toute information se
        rapportant à une personne physique identifiée ou identifiable (ci-après dénommée &laquo; Personne Concernée
        &raquo;) ; est réputée être une personne physique identifiable une personne physique qui peut être identifiée,
        directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro
        d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments
        spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou
        sociale.
    </p>

    <p>
        <span class="font-weight-bold">&laquo; Responsable de Traitement &raquo;</span> désigne
        {{ societyDetails.name }} qui, seul, détermine les finalités et les moyens du traitement des Données à Caractère
        Personnel.
    </p>

    <p>
        <span class="font-weight-bold">&laquo; Sous-Traitant &raquo;</span> désigne l'entité qui agit pour le compte du
        Responsable de Traitement.
    </p>

    <p>
        <span class="font-weight-bold">&laquo; Transfert de Données à Caractère Personnel &raquo;</span> désigne le
        traitement, le transfert matériel ou l'accès à distance aux Données à Caractère Personnel de personnes établies
        hors de l'espace économique européen (EEE).
    </p>

    <p>
        <span class="font-weight-bold">&laquo; Violation de Données à Caractère Personnel &raquo;</span> désigne une
        violation de la sécurité entraînant, de manière accidentelle ou illicite, la destruction, la perte,
        l'altération, la divulgation non autorisée ou l'accès aux Données à Caractère Personnel du Client transmises,
        conservées ou autrement traitées.
    </p>

    <p>
        Pour exécuter le Contrat et/ou fournir les Services commandés, {{ societyDetails.name }} peut être amené à
        traiter des Données à Caractère Personnel concernant les Utilisateurs du Client, ou le Client (ci-après les
        &laquo; Personnes Concernées &raquo;), notamment. Ces Données à Caractère Personnel sont particulièrement
        sensibles pour l'image et le patrimoine des Personnes Concernées.
    </p>

    <p>
        Les Personnes Concernées, ainsi que {{ societyDetails.name }}, s'engagent à collecter et à traiter toute Donnée
        à Caractère Personnel en conformité avec toute réglementation en vigueur applicable au traitement de ces
        données, et notamment à la loi n&deg;78-17 du 6 janvier 1978 modifiée.
    </p>

    <p>
        {{ societyDetails.name }} doit garantir la mise en oeuvre de mesures techniques et organisationnelles
        appropriées pour assurer la sécurité des Données à Caractère Personnel et empêcher tout traitement non autorisé
        ou illégal des Données à Caractère Personnel et toute perte, destruction ou dommage accidentel aux Données à
        Caractère Personnel.
    </p>

    <p>Au regard de cette loi, le Client est responsable du Traitement réalisé au titre du Contrat.</p>

    <p>
        Dans le cadre du présent contrat, {{ societyDetails.name }} agissant en tant que sous-traitant au sens de la loi
        n&deg;78-17 du 6 janvier 1978 s'engage à :
    </p>

    <ul>
        <li>
            Respecter l'ensemble des obligations de Sécurité qui lui incombent au titre de l'article 34 de loi
            n&deg;78-17 du 6 Janvier 1978 ;
        </li>
        <li>
            {{ societyDetails.name }} prendra, et s'assure que son personnel prend, dans la mesure nécessaire à
            l'exécution des prestations ou au respect de ses autres obligations contractuelles, toute mesure nécessaire
            pour préserver et faire respecter l'intégrité et la confidentialité des Données Personnelles transmises par
            le Client ;
        </li>
        <li>
            Ne traiter les données à caractère personnel du Client que conformément aux instructions écrites du Client ;
        </li>
        <li>
            Se conformer et s'assurer que ses partenaires, ses sous-traitants ou toute personne agissant sous son
            autorité ou pour son compte se conforment aux mêmes obligations précitées ;
        </li>
        <li>
            Ne pas divulguer à des tiers, et ce y compris aux sous-traitants ou partenaires de
            {{ societyDetails.name }}, en dehors des cas prévus par une disposition légale ou réglementaire;
        </li>
        <li>
            Tenir à disposition des Clients ou Utilisateurs le registre compilant l'ensemble des traitements gérés par
            {{ societyDetails.name }}, via l'adresse courriel <a href="mailto:{{ emails.rgpd }}">{{ emails.rgpd }}</a
            >.
        </li>
    </ul>

    <p class="font-weight-bold">Sous-traitance et lieux de traitement</p>

    <p>
        {{ societyDetails.name }} s'engage à informer le Client de la localisation des lieux de traitements de Données
        Personnelles de quelque nature qu'ils soient (hébergement, backup, maintenance, administration, helpdesk…).
    </p>

    <p>
        A cet effet, {{ societyDetails.name }} s'engage à mettre à la charge de son (ou ses) Sous-traitant(s) toutes
        obligations prévues par le présent Contrat.
    </p>

    <p>
        En cas de transfert de Données Personnelles vers un pays tiers, {{ societyDetails.name }} s'engage à faire
        signer à ses Sous-traitants les clauses contractuelles types selon les modalités prévues par décision de la
        Commission Européenne, pour le transfert de Données Personnelles vers des Sous-traitants établis dans des pays
        tiers.
    </p>

    <p class="font-weight-bold">Conservation des Données Personnelles</p>

    <p>
        Au terme du Contrat, {{ societyDetails.name }} s'engage à restituer ou à détruire, à faire restituer et à faire
        détruire par tous ses partenaires ou Sous-traitants intervenant dans la réalisation des Services commandés,
        selon des procédés et modalités convenus préalablement entre les Parties, toutes les Données Personnelles du
        Client traitées pour le compte du Client de manière automatisée ou manuelle. Le Client se réserve le droit de
        procéder à toute vérification permettant d'évaluer le respect de cette obligation.
    </p>

    <p>Le présent article survivra à la résiliation ou à l'expiration du Contrat pour quelque cause que ce soit.</p>

    <p>
        Si les Données transmises aux fins d'utilisation des Services commandés comportent des Données Personnelles, le
        Client garantit au {{ societyDetails.name }} qu'il a procédé à l'ensemble des obligations qui lui incombent au
        terme de la loi du 6 janvier 1978 dite &laquo; Informatique & Libertés &raquo;, et qu'il a informé les personnes
        physiques concernées de l'usage qui est fait desdites Données Personnelles.
    </p>

    <p>
        A ce titre, le Client garantit {{ societyDetails.name }} contre tout recours, plainte ou réclamation émanant
        d'une personne physique dont les données personnelles seraient reproduites et hébergées via les Services
        commandés.
    </p>

    <p class="font-weight-bold">Exploitation des données</p>

    <p>
        Le Client assure la responsabilité éditoriale de l'utilisation de la Plateforme par ses utilisateurs désignés.
    </p>

    <p>
        Le Client est seul responsable de la qualité, de la licéité, de la pertinence des Données et contenus qu'il
        transmet via ses Utilisateurs aux fins d'utilisation des Services commandés.
    </p>

    <p>
        Il garantit en outre être titulaire des droits de propriété intellectuelle lui permettant d'utiliser les Données
        et contenus.
    </p>

    <p>
        En conséquence, {{ societyDetails.name }} dégage toute responsabilité en cas de non-conformité des Données et/ou
        des contenus aux lois et règlements, à l'ordre public ou encore aux besoins du Client.
    </p>

    <p>
        Le Client garantit {{ societyDetails.name }} à première demande contre tout préjudice qui résulterait de sa mise
        en cause par un tiers pour une violation de cette garantie.
    </p>

    <p>
        Plus généralement, Le Client est seul responsable des contenus et messages diffusés et/ou téléchargés via les
        Services commandés, par ses Utilisateurs.
    </p>

    <p>
        {{ societyDetails.name }} s'engage à respecter toute règlementation en matière de protection de données qui
        pourrait être applicable pour l'exécution du Contrat et en particulier (i) la Directive n&deg;95/46 relative à
        la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre
        circulation de ces données (Directive UE sur les Protection des Données), (ii) le Règlement européen
        n&deg;2016/679 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère
        personnel et à la libre circulation de ces données à compter de sa date d'application et ses compléments en
        droit national, et (iii) toute réglementation relative aux traitements de Données à Caractère Personnel
        applicable pendant la durée du Contrat (&laquo; Législation Applicable en matière de Protection des Données
        &raquo;).
    </p>

    <p>
        {{ societyDetails.name }} doit garantir la mise en œuvre de mesures techniques et organisationnelles appropriées
        pour assurer la sécurité des Données à Caractère Personnel et empêcher tout traitement non autorisé ou illégal
        des Données à Caractère Personnel et toute perte, destruction ou dommage accidentel aux Données à Caractère
        Personnel.
    </p>

    <p>Par ailleurs, {{ societyDetails.name }} est tenu de :</p>

    <ul>
        <li>
            Mettre en place des procédures pour assurer les Personnes Concernées de la conformité de
            {{ societyDetails.name }} à la législation applicable en matière de Protection des Données ;
        </li>
        <li>
            Dans la limite de ce qu'autorise la loi applicable, informer les Personnes Concernées de toute demande de
            communication des Données à Caractère Personnel qu'il recevrait d'un tiers, autorités publiques ou
            juridictions, et concernant les Personnes Concernées ;
        </li>
        <li>
            S'assurer que ses collaborateurs et ses sous-traitants sont en conformité avec la législation applicable en
            matière de Protection des Données, avec les mêmes obligations que celles définies ci-dessous et avec des
            obligations de confidentialité renforcées en garantissant qu'ils concluent un accord de confidentialité
            spécifique ;
        </li>
        <li>
            S'assurer que l'accès aux Données est limité aux collaborateurs de {{ societyDetails.name }} ayant
            strictement besoin d'en connaître ; l'accès étant limité aux Données nécessaires à l'accomplissement des
            obligations contractuelles par {{ societyDetails.name }} ; ses collaborateurs et ceux de ses sous-traitants
            ;
        </li>
        <li>
            S'assurer que tous les collaborateurs de {{ societyDetails.name }} et de ses sous-traitants qui ont accès
            aux Données sont dûment formés à leurs obligations lorsqu'ils traitent les Données à Caractère Personnel et
            notamment à celles relatives à la confidentialité de ces Données ;
        </li>
        <li>
            Mettre en place une procédure pour s'assurer qu'il informe les Personnes Concernées sans retard injustifié
            des demandes et/ou plaintes de personnes concernées qu'il recevrait concernant les Données à Caractère
            Personnel. En tout état de cause, {{ societyDetails.name }} s'engage à ne pas revenir vers les personnes
            concernées au sujet des Données à Caractère Personnel desdites Personnes Concernées sans leur accord exprès
            préalable. {{ societyDetails.name }} doit coopérer pleinement avec les Personnes Concernées pour toute
            demande effectuée par toute personne souhaitant avoir accès à des Données à Caractère Personnel ;
        </li>
        <li>
            S'engager à auditer de manière régulière ses Sous-traitants concernant les traitements de Données à
            Caractère Personnel des Personnes Concernées. {{ societyDetails.name }} doit ensuite fournir aux Personnes
            Concernées, sur demande, un rapport complet des audits conduits pour démontrer que les Données à Caractère
            Personnel sont traitées conformément aux obligations définies dans le Contrat et les conditions définies et
            approuvées par les Personnes Concernées dans le présent Contrat ;
        </li>
        <li>
            Coopérer activement avec les Personnes Concernées pour leur permettre d'évaluer et documenter la conformité
            des traitements de Données à Caractère Personnel résultant du présent Contrat.
        </li>
    </ul>

    <p>
        Lorsque {{ societyDetails.name }} entend se fonder sur les entités de son groupe situées dans des pays hors de
        l'espace économique européen ou sur ses sous-traitants, il doit :
    </p>

    <ul>
        <li>
            Ne pas transférer de Données à Caractère Personnel hors de l'Espace Economique Européen sans l'accord
            préalable et exprès des Personnes Concernées ;
        </li>
        <li>
            Notifier les Personnes Concernées avec un préavis raisonnable et comprenant toutes les informations
            pertinentes concernant la finalité d'un tel transfert et le pays où les Données à Caractère Personnel des
            Personnes Concernées seraient transférées.
        </li>
    </ul>

    <p>
        Dans le cas où {{ societyDetails.name }} a des raisons raisonnables de croire qu'il y a eu un accès non autorisé
        ou illégal potentiel ou effectif, ou une utilisation ou divulgation potentielle ou effective des Données à
        Caractère Personnel de Personnes Concernées, {{ societyDetails.name }} doit en informer les Personnes Concernées
        sans retard injustifié après la constatation de cette Violation de Données à Caractère Personnel et en tout état
        de cause dans les vingt-quatre (24) heures suivant la constatation de cette Violation de Données à Caractère
        Personnel.
    </p>

    <p>
        Dans de telles circonstances, {{ societyDetails.name }} s'engage à partager au moins les informations suivantes
        avec les Personnes Concernées :
    </p>

    <ul>
        <li>
            L'identité et les coordonnées du délégué à la protection des données ou d'un autre point de contact auprès
            de qui plus d'informations peuvent être obtenues ;
        </li>
        <li>
            La nature de la Violation de Données à Caractère Personnel, y compris les catégories de Personnes
            Concernées, les catégories d'enregistrements de données concernées ;
        </li>
        <li>
            Une description des mesures que les Personnes Concernées pourrait prendre pour en atténuer les éventuelles
            conséquences négatives de la Violation de Données à Caractère Personnel ;
        </li>
        <li>Les conséquences de la Violation de Données à Caractère Personnel ;</li>
        <li>Les mesures proposées ou prises pour remédier à la Violation de Données à Caractère Personnel.</li>
    </ul>

    <h2>5. Responsabilité des utilisateurs</h2>

    <p>
        Il est entendu que les Clients et les Utilisateurs, dans le cas où ces derniers viendraient à créer et/ou à
        traiter des données à caractère personnel, seront responsables de l'utilisation ainsi que du traitement de ces
        données. La responsabilité de {{ societyDetails.name }} se limitera en effet aux données dont
        {{ societyDetails.name }} est responsable.
    </p>

    <h2>6. Respect de la confidentialité des informations</h2>

    <p>
        Dans le cadre de son utilisation de la plate-forme, les Utilisateurs s'engagent à ne pas échanger ou divulguer
        des informations considérées comme confidentielles, notamment celles dont les Utilisateurs pourraient avoir
        connaissance en raison de l'application de leur contrat de travail avec leur employeur, sans l'autorisation de
        ce dernier.
    </p>

    <h2>7. Modification des conditions d'utilisation</h2>

    <p>
        Les modifications et amendements apportés aux présentes conditions générales seront notifiés par le biais de
        newsletters.
    </p>

    <p>
        Les conditions d'utilisation applicables sont celles disponibles en ligne au jour de l'utilisation de la
        plateforme par l'utilisateur.
    </p>
</div>

<div class="terms-of-use" *ngIf="locale === 'en'">
    <h1>Terms of Service</h1>

    <p>
        {{ societyDetails.name }} offers two different user profiles: the full profile used by default (standard), and
        the &raquo;guest&raquo; profile:
    </p>

    <ul>
        <li>
            The full profile is open under the right of access contracted by the customer for the benefit of users
            designated by him.
        </li>
        <li>
            The &raquo;guest&raquo; profile, with limited rights, is opened directly by {{ societyDetails.name }} for
            the benefit of the user so that he discovers the platform and/or provides his expertise, without being able
            to create projects or skills requests on it . The &raquo;guest&raquo; accounts are only accessible by
            express invitation of {{ societyDetails.name }}, free of charge, and are likely to be revoked at any time by
            unilateral decision of {{ societyDetails.name }}, with a notice of 3 working days communicated by email,
            without {{ societyDetails.name }} be held from any compensation.
        </li>
    </ul>

    <p>
        at any time by unilateral decision of {{ societyDetails.name }}, with a notice of 3 working days communicated by
        email, without {{ societyDetails.name }} be held from any compensation. In the case of a &raquo;guest&raquo;
        profile, your registration applies acceptance of these general conditions of use, as well as general conditions
        of sale and the service Level Agreement, on a personal basis. It should be noted that the following provisions
        are not applicable to you:
    </p>

    <ul>
        <li>Creation of skills and projects requests;</li>
        <li>Loyalty program ;</li>
        <li>Contract length.</li>
    </ul>

    <p class="font-weight-bold">
        In order to validate your registration, you must read the &raquo;User&raquo; general conditions below.
    </p>

    <p class="font-weight-bold">
        Your final registration on the {{ societyDetails.name }} platform is worth acceptance of the conditions
        stipulated below.
    </p>

    <h2>1. Conditions of participation</h2>

    <p>The user accepts without any reserve the terms of these conditions of use.</p>
    <p>
        The service is open to anyone who has been authorized to participate by their employer (the latter being defined
        as the &laquo;customer&laquo;), or anyone who has been invited by {{ societyDetails.name }} SAS.
    </p>
    <p>The user undertakes to show loyalty and courtesy with regard to other users.</p>
    <p>
        In the event that a user contravenes any of his obligations, {{ societyDetails.name }} reserves the right to
        exclude said User.
    </p>
    <p>
        {{ societyDetails.name }} will be able, within the framework of a project deposit on its platform, exercise
        control, both in substance and on the form, relative to the project submitted. {{ societyDetails.name }} may in
        particular ask the user to reformulate, as many times as necessary, the content of his project, so that the
        latter complies with any law or any applicable regulations. This control will bear in particular in order to
        prohibit any defamatory, abusive, racist, discriminating, child pornographic content,….
    </p>

    <h2>2. Intellectual property</h2>

    <h3>
        2.1. Intellectual property resulting from skills exchange, working groups and inter -company projects via the
        use of the platform
    </h3>

    <p>
        All original creations, inventions, databases, denominations, brands or other distinctive signs can only be
        used, reproduced and represented by the user:
    </p>

    <ul>
        <li>If he has the rights over these elements;</li>
        <li>If these elements are free of law;</li>
        <li>If it is authorized by the one who holds the rights on these elements (by a client, for example).</li>
    </ul>

    <p>
        All original creations, database, denominations, brands or other distinctive signs present on the pages of the
        platform remain the exclusive property of their author or depositor. However, the reproduction and
        representation of all or part of these elements are authorized free of charge in the strict framework of the
        participation in the activity of the platform.
    </p>

    <p>{{ societyDetails.name }} has no control over the ownership of the elements and/or exchanged on the platform.</p>

    <p>
        In no case will {{ societyDetails.name }} be responsible for the user or any third party concerning a question
        relating to the intellectual property of projects and/or solutions from projects. The risks relating to
        intellectual and assimilated property are assumed exclusively by the User and his Employer (Customer) within the
        projects in which users participate.
    </p>

    <p>Unless otherwise provided for in a separate agreement:</p>

    <ul>
        <li>The user remains the owner of the knowledge on which he has previous rights;</li>
        <li>The user remains the owner of the new knowledge attributable to him in the context of projects;</li>
        <li>
            In the event that new results constituting neither a work of the mind nor an invention, are obtained within
            the framework of projects, the user remains the owner of the new results which are attributable to him
            within the framework of said projects;
        </li>
        <li>
            Any work of the spirit, created within the framework of a project bringing together several contributions,
            will be qualified as a collaboration work within the meaning of article L113-2 of the Intellectual Property
            Code.
        </li>
    </ul>

    <p>
        In order to derogate from the principles of the platform, it is up to users to set up a partnership agreement
        that will governing the rights and obligations of each user involved in the project.
        {{ societyDetails.name }} draws attention to the importance of establishing such an agreement when this project
        is likely to give birth to new knowledge, inventions, works of the mind, know-how or any new result in general.
        {{ societyDetails.name }} offers models of contract leaders, which they can freely amend and offer contributors
        intervening on their projects. These contracts are proposed as an indication and do not engage in any way
        {{ societyDetails.name }}. In the event that users, members of a project team, do not reach an agreement,
        {{ societyDetails.name }} will be able to take place as a mediator. In the absence of a resolution,
        {{ societyDetails.name }} reserves the right to exclude a user from the project concerned, or to exclude the
        platform project as a whole.
    </p>

    <p>
        {{ societyDetails.name }} has no right of preference or priority on possible collaboration or commercial or non
        -commercial exploitation of projects. Each project carrier user does their business of the potential future
        exploitation of their project, in accordance with other participating users.
    </p>

    <h3>2.2. Intellectual property attached to the use of platforms</h3>

    <p>
        {{ societyDetails.name }}, or any third party it represents, and with which a contract has been signed, is and
        remains the holder of the property rights relating to any element of the platform made available to the
        Customer, as well as more generally of the software infrastructure in operation or developed within the
        framework of the contract.
    </p>
    <p>The contract does not give the Customer any property rights on the platform.</p>
    <p>
        The temporary provision of the platform under the conditions provided for in the contract cannot be analyzed as
        the transfer of any intellectual property right for the benefit of the Customer, within the meaning of the
        French Intellectual Property Code.
    </p>
    <p>
        The customer refrains from reproducing any element of the software, or any documentation concerning them, by any
        means whatsoever, in any form whatsoever and on any medium whatsoever.
    </p>
    <p>
        The Customer cannot assign all or part of the rights and obligations resulting from the contract, whether in the
        context of a temporary transfer, an underside and any other contract providing for the transfer of said rights
        and obligations.
    </p>

    <h3>
        2.3. Intellectual property of the elements present on the site {{ societyDetails.website }} and the platform:
    </h3>

    <p>
        All the elements that make up the site
        <a href="{{ societyDetails.website }}">{{ societyDetails.website }}</a> and its platform, and in particular
        brands, logos, texts, data, drawings, graphics, photos, animations, sounds, computer codes, layouts, assemblies
        of all or part of a Site element, including downloadable documents, are and remain our property or that of our
        beneficiaries.
    </p>
    <p>
        All the contents of the website and the platform (images, texts, videos, databases, etc.) are subject to French
        and international legislation in force in terms of intellectual property (copyright, brands law, law
        databases&hellip;). If you find that elements published on the site or platform affects copyright or other
        protected rights, please contact {{ societyDetails.name }} at the following address:
        <a href="mailto:{{ emails.support }}">{{ emails.support }}</a
        >.
    </p>
    <p>
        We only grant you an authorization to visualize the content of our site on a personal and private basis,
        excluding any visualization or public dissemination. The reproduction authorization is only granted to you in
        digital form on your consultation computer for visualization purposes of the pages consulted by your navigation
        software. Any other use (such as in particular reproduction of brands and logos, extraction of all or part of
        the databases, etc.) is prohibited without our prior and express authorization.
    </p>

    <h2>3. {{ societyDetails.name }} loyalty program</h2>

    <p>
        {{ societyDetails.name }} has implemented, for its users, a loyalty system (hereinafter the
        &laquo;program&laquo;) materialized by the accumulation of &laquo;wukoins&laquo; (hereinafter the
        &laquo;Wukoins&laquo;).
    </p>
    <p>
        {{ societyDetails.name }}, acting in good faith, reserves the right to cancel or replace the program or to amend
        the program, its conditions, the wukoin accumulation structure at any time, or procedures relating to the
        program. A user or a member may in no case form a compensation request against
        {{ societyDetails.name }} following specific modifications imposed by the legislation specific to each country.
        {{ societyDetails.name }} reserves the right to make promotional offers for user groups according to their
        activities in the program.
    </p>
    <p>
        Users cannot perceive Wukoins for their participation in missions of their own projects, or for their
        participation in missions hours in projects carried out by a user attached to the same customer.
    </p>
    <p>
        The emitted Wukoins remain valid for a period of two (2) years. {{ societyDetails.name }} will therefore be able
        to cancel the Wukoins received after a period of inactivity of these Wukoins for a period of two (2) years.
    </p>
    <p>
        The Wukoins accumulated by a user cannot in any circumstance be transferred, bequeathed, assigned, or sold -
        whether or not it is for consideration and regardless of the form of this transfer, outside the program.
    </p>
    <p>
        The user may, using his Wukoins, benefit from advantages that will be updated on the platform and inside a
        {{ societyDetails.name }} &laquo;service catalog&laquo;.
    </p>

    <h2>4. Data processing</h2>

    <p class="font-weight-bold">
        It appears from article 5 of the framework contract signed with the customer the following definitions and
        obligations:
    </p>

    <p>
        <span class="font-weight-bold">&laquo; Personal data &raquo;</span> means any information relating to an
        identified or identifiable natural person (hereinafter referred to as &laquo;concerned&laquo;); is deemed to be
        an identifiable natural person a natural person who can be identified, directly or indirectly, in particular by
        reference to an identifier, such as a name, an identification number, location data, an online identifier, or
        One or more specific elements specific to its physical, physiological, genetic, psychic, economic, cultural or
        social identity.
    </p>

    <p>
        <span class="font-weight-bold">&laquo;Manager of treatment&laquo;</span> means {{ societyDetails.name }} who,
        alone, determines the purposes and means of processing personal data.
    </p>

    <p>
        <span class="font-weight-bold">&laquo;Subcontractor&laquo;</span> designates the entity which acts on behalf of
        the controller.
    </p>

    <p>
        <span class="font-weight-bold">&laquo;Transfer of personal data&laquo;</span> means processing, material
        transfer or remote access to personal data from people established outside the European Economic Area (EEE).
    </p>

    <p>
        <span class="font-weight-bold">&laquo;Personal data violation&laquo;</span> designates a violation of security
        resulting in accidental or illicitment, destruction, loss, alteration, unauthorized disclosure or access to
        personal data from the customer transmitted, kept or otherwise treated.
    </p>

    <p>
        To execute the contract and/or provide the ordered services, {{ societyDetails.name }} may have to process
        personal data concerning customer users, or the customer (hereinafter the &laquo;persons concerned&laquo;), in
        particular. These personal data are particularly sensitive to the image and heritage of the persons concerned.
    </p>
    <p>
        The persons concerned, as well as {{ societyDetails.name }}, undertake to collect and deal with any personal
        data in accordance with any regulations in force applicable to the processing of these data, and in particular
        to law n ° 78-17 of January 6, 1978 modified .
    </p>
    <p>
        {{ societyDetails.name }} must guarantee the implementation of appropriate technical and organizational measures
        to ensure the security of personal data and prevent any unauthorized or illegal processing of personal data and
        any loss, destruction or accidental damage to personal data.
    </p>
    <p>In view of this law, the Customer is responsible for the treatment carried out under the contract.</p>
    <p>
        Within the framework of this contract, {{ societyDetails.name }} acting as a subcontractor within the meaning of
        law n ° 78-17 of January 6, 1978 undertakes:
    </p>

    <ul>
        <li>
            Respect all the security obligations incumbent upon it under article 34 of law n ° 78-17 of January 6, 1978;
        </li>
        <li>
            {{ societyDetails.name }} will take, and ensure that its staff takes, to the extent necessary for the
            execution of services or compliance with its other contractual obligations, any necessary measure to
            preserve and ensure the integrity and confidentiality of the personal data transmitted by the customer ;
        </li>
        <li>Processing personal data from the customer only in accordance with the customer's written instructions;</li>
        <li>
            Comply and ensure that its partners, subcontractors or anyone acting under their authority or on their
            behalf complies with the same aforementioned obligations;
        </li>
        <li>
            Do not disclose to third parties, including the subcontractors or partners of {{ societyDetails.name }},
            apart from the cases provided for by a legal or regulatory provision;
        </li>
        <li>
            Making customers or users available to the compiling register all the treatments managed by
            {{ societyDetails.name }}, via the email address rgpd@{{ societyDetails.name }}.io.
        </li>
    </ul>

    <p class="font-weight-bold">Subcontracting and treatment places</p>

    <p>
        {{ societyDetails.name }} undertakes to inform the customer of the location of personal data processing places
        of any kind (accommodation, backup, maintenance, administration, helpdesk, etc.).
    </p>
    <p>
        To this end, {{ societyDetails.name }} undertakes to charge his (s) subcontractor (s) all obligations provided
        for in this contract.
    </p>
    <p>
        In the event of the transfer of personal data to a third country, {{ societyDetails.name }} undertakes to have
        its subcontractors sign the standard contractual clauses according to the procedures provided for by the
        European Commission decision, for the transfer of personal data to subcontractors established in third
        countries.
    </p>

    <p class="font-weight-bold">Conservation of personal data</p>

    <p>
        At the end of the contract, {{ societyDetails.name }} undertakes to restore or destroy, have to restore and have
        destroyed by all its partners or subcontractors involved in the realization of the services ordered, according
        to processes and terms agreed beforehand between the parties, all The personal data of the customer processed on
        behalf of the customer in an automated or manual manner. The Customer reserves the right to carry out any
        verification to assess compliance with this obligation.
    </p>
    <p>This article will survive the termination or expiration of the contract for any cause whatsoever.</p>
    <p>
        If the data transmitted for the purposes of using the services ordered include personal data, the Customer
        guarantees to the {{ societyDetails.name }} that he has carried out all the obligations incumbent to him at the
        end of the law of January 6, 1978 known as &laquo;IT & Freedoms &laquo;, And that he informed the natural
        persons concerned of the use made of said personal data.
    </p>
    <p>
        As such, the customer guarantees {{ societyDetails.name }} against any appeal, complaint or complaint from a
        natural person whose personal data would be reproduced and hosted via the services ordered.
    </p>

    <p class="font-weight-bold">Data analysis</p>

    <p>The Customer provides editorial responsibility for using the platform by its designated users.</p>
    <p>
        The Customer is solely responsible for the quality, the lawfulness, the relevance of the data and content which
        he transmits via its users for the use of the services ordered.
    </p>
    <p>He also guarantees to hold intellectual property rights allowing him to use the data and content.</p>
    <p>
        Consequently, {{ societyDetails.name }} gives off any responsibility in the event of non-compliance of data
        and/or content to laws and regulations, public order or the needs of the customer.
    </p>
    <p>
        The Customer guarantees {{ societyDetails.name }} at first request against any damage which would result from
        his questioning by a third party for a violation of this guarantee.
    </p>
    <p>
        More generally, the customer is solely responsible for the content and messages broadcast and/or downloaded via
        the services ordered, by its users.
    </p>
    <p>
        {{ societyDetails.name }} undertakes to comply with any data protection regulations which could be applicable
        for the execution of the contract and in particular (i) Directive n ° 95/46 relating to the protection of
        natural persons with regard to treatment Personal data and the free movement of this data (EU data protection
        directive), (ii) European regulation n ° 2016/679 relating to the protection of natural persons with regard to
        data processing at Personal character and the free movement of this data from its date of application and its
        supplements under national law, and (iii) any regulation relating to the processing of personal data applicable
        during the duration of the contract (&laquo;Applicable Data Protection Legislation”).
    </p>
    <p>
        {{ societyDetails.name }} must guarantee the implementation of appropriate technical and organizational measures
        to ensure the security of personal data and prevent any unauthorized or illegal processing of personal data and
        any loss, destruction or accidental damage to personal data.
    </p>
    <p>In addition, {{ societyDetails.name }} is required to:</p>

    <ul>
        <li>
            Set up procedures to ensure the persons concerned with {{ societyDetails.name }} compliance with applicable
            data protection legislation;
        </li>
        <li>
            Within the limits of what the applicable law authorizes, inform the persons concerned of any request for
            communication of personal data that he would receive from a third party, public authorities or
            jurisdictions, and concerning the persons concerned;
        </li>
        <li>
            Ensure that its employees and subcontractors are in accordance with the applicable data protection
            legislation, with the same obligations as those defined below and with reinforced confidentiality
            obligations by ensuring that they conclude an agreement specific confidentiality;
        </li>
        <li>
            Ensure that access to data is limited to {{ societyDetails.name }} employees that strictly need to know;
            Access being limited to the data necessary for the fulfillment of contractual obligations by
            {{ societyDetails.name }}; his collaborators and those of his subcontractors;
        </li>
        <li>
            Ensure that all employees of {{ societyDetails.name }} and its subcontractors who have access to data are
            duly trained in their obligations when they process personal data and in particular those relating to the
            confidentiality of this data;
        </li>
        <li>
            Set up a procedure to ensure that he informs the persons concerned without unjustified delay of the requests
            and/or complaints of persons concerned which he would receive concerning personal data. In any event,
            {{ societyDetails.name }} undertakes not to return to the persons concerned about the personal data of the
            said persons concerned without their express prior agreement. {{ societyDetails.name }} must cooperate fully
            with the persons concerned for any request made by any person wishing to have access to personal data;
        </li>
        <li>
            Commit to regularly audit your subcontractors concerning the processing of personal data of the persons
            concerned. {{ societyDetails.name }} must then provide the persons concerned, on request, a complete report
            of the audits conducted to demonstrate that the personal data is processed in accordance with the
            obligations defined in the contract and the conditions defined and approved by the persons concerned in this
            contract;
        </li>
        <li>
            Actively cooperate with the persons concerned to enable them to assess and document the compliance of
            personal data processing resulting from this contract.
        </li>
    </ul>

    <p>
        When {{ societyDetails.name }} intends to base itself on the entities of its group located in countries outside
        the European Economic Area or on its subcontractors, it must:
    </p>

    <ul>
        <li>
            Do not transfer personal data outside the European Economic Space without the prior and express agreement of
            the persons concerned;
        </li>
        <li>
            Notify the persons concerned with reasonable notice and comprising all the relevant information concerning
            the purpose of such a transfer and the country where the personal data of the persons concerned would be
            transferred.
        </li>
    </ul>

    <p>
        In the event that {{ societyDetails.name }} has reasonable reasons to believe that there has been unauthorized
        or illegal access to potential or effective, or potential or effective use or disclosure of the personal data of
        persons concerned, {{ societyDetails.name }} must inform people Concerned without unjustified delay after the
        observation of this violation of personal data and in any event in the twenty-four (24) hours following the
        observation of this violation of personal data.
    </p>

    <p>
        In such circumstances, {{ societyDetails.name }} undertakes to share at least the following information with the
        people concerned:
    </p>

    <ul>
        <li>
            The identity and contact details of the data protection delegate or another contact point with which more
            information can be obtained;
        </li>
        <li>
            The nature of the violation of personal data, including the categories of persons concerned, the categories
            of data records concerned;
        </li>
        <li>
            A description of the measures that the persons concerned could take to mitigate the possible negative
            consequences of the violation of personal data;
        </li>
        <li>The consequences of the violation of personal data;</li>
        <li>The measures proposed or taken to remedy the violation of personal data.</li>
    </ul>

    <h2>5. User responsibility</h2>

    <p>
        It is understood that customers and users, in the event that they come to create and/or process personal data,
        will be responsible for the use as well as the processing of this data. {{ societyDetails.name }}'s
        responsibility will indeed be limited to the data for which {{ societyDetails.name }} is responsible.
    </p>

    <h2>6. Respect for the confidentiality of information</h2>

    <p>
        As part of its use of the platform, users undertake not to exchange or disclose information considered to be
        confidential, in particular those whose users may have knowledge due to the application of their employment
        contract with their employer, without the latter's authorization.
    </p>

    <h2>7. Modification of the conditions of use</h2>

    <p>The changes and amendments to these General Conditions will be notified through newsletters.</p>

    <p>The applicable conditions of use are those available online on the day of the use of the user platform.</p>
</div>
