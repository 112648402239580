import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CropperParams } from '../../../models/shared/cropper-params.model';
import { ImageService } from '../../../services/shared/image.service';

@Component({
    selector: 'image-crop-modal',
    templateUrl: './image-crop-modal.component.html',
    styleUrls: ['./image-crop-modal.component.scss'],
})
export class ImageCropModalComponent implements OnInit {
    @Input() public cropperParams: CropperParams;
    @Input() public customId: string;
    @Output() public eventSavePicture = new EventEmitter<string>();
    @Input() public imageService: ImageService;
    public isVisible: boolean;
    @Input() public picture: any;

    public ngOnInit(): void {
        this.isVisible = false;
    }

    public onSubmit() {
        this.eventSavePicture.emit('');
        this.hideModal();
    }

    showModal() {
        this.isVisible = true;
    }

    hideModal() {
        this.isVisible = false;
    }
}
