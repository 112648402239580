<div class="col-12 mb-2">
    <div class="card box-shadow" (click)="navigateToProject()" style="cursor: pointer">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-4 col-12">
                    <div class="img-container">
                        <img src="{{ project?.pictureAccessLink }}" alt="" />
                    </div>
                </div>

                <div class="col-sm-8 col-12">
                    <div class="row" style="height: 100%">
                        <div class="col-12">
                            <div class="h5 fw-b d-flex justify-content-between align-items-center">
                                <span>{{ project?.name }}</span>
                                <app-gear-edit-project [project]="project" (click)="$event.stopPropagation()"></app-gear-edit-project>
                            </div>

                            <div class="fw-b project-category">
                                {{ project?.projectType?.label | wtranslate: 'projectTypes' }}
                            </div>
                            <!-- Test XP usage pour Arnaud -->
                            <div class="project-info" [innerHTML]="project?.synthesis || project?.description?.substring(0, 200)"></div>
                            <!-- Synthèse du projet obligatoire et limité à 200.. le substring sur description n'est plus utile?! -->
                            <span *ngIf="!project?.synthesis && project?.description?.length > 200">...</span>
                            <!-- <p>
                {{
                  project?.synthesis || project?.description?.substring(0, 200)
                }}
                <span
                  *ngIf="
                    !project?.synthesis && project?.description?.length > 200
                  "
                  >...</span
                >
              </p> -->
                        </div>
                        <div class="col-12 mt-auto">
                            <div class="row">
                                <div class="col-sm col-12">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="fw-b project-info">
                                                {{ 'projects.projectDetails.expectedStartDate' | translate }}
                                            </div>
                                            <div class="project-info">{{ project?.startDate }}</div>
                                        </div>
                                        <div class="col-auto">
                                            <div class="fw-b project-info">
                                                {{ 'projects.projectDetails.expectedEndDate' | translate }}
                                            </div>
                                            <div class="project-info">{{ project?.endDate }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-auto mt-2 ml-auto">
                                    <!-- If an unpublished copy of project exists, edit it by default ; otherwise the original project! -->
                                    <a
                                        *ngIf="isEditable()"
                                        [routerLink]="[!isAdmin && project.copyProjectId ? project.copyProjectId : project.id]"
                                        class="btn btn-secondary"
                                        ><em class="icon icon-edit d-sm-inline d-md-none"></em>
                                        <span class="d-sm-inline">{{ 'components.button.modify' | translate }}</span></a
                                    >
                                    <div>
                                        <div class="more-info-button mr-1">
                                            <ng-container *ngIf="isUserParticipatingToProject(); else joinButton">
                                                <a [routerLink]="['/projects', project.id]" class="btn btn-secondary ml-1">
                                                    <em class="d-sm-inline d-md-none"></em>
                                                    <span class="d-sm-inline">{{ 'components.button.details' | translate }}</span>
                                                </a>
                                            </ng-container>

                                            <ng-template #joinButton>
                                                <a [routerLink]="['/projects', project.id]" class="btn btn-primary ml-1">
                                                    <em class="d-sm-inline d-md-none"></em>
                                                    <span class="d-sm-inline">{{ 'components.button.join' | translate }}</span>
                                                </a>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
