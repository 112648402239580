import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { projectStatus, projectTypeCategories } from '../../../constants/project-status';
import { regexPatterns } from '../../../constants/regex-patterns';
import { SpinnerHelper } from '../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { Community } from '../../../models/identity/community.model';
import { WudoApplicationType } from '../../../models/identity/enumerations/wudoApplicationType';
import { OrganizationBase } from '../../../models/identity/organization-base.model';
import { BaseProjectCommand } from '../../../models/projects/base-project-command.model';
import { CollaborationPlatformTypeEnum } from '../../../models/projects/collaboration-platform-type.enum.model';
import { ProjectLight } from '../../../models/projects/project-light.model';
import { ProjectType } from '../../../models/projects/project-type.model';
import { Attendee } from '../../../models/shared/attendee.model';
import { JoinableType } from '../../../models/shared/joinable-type.model';
import { ClubsService } from '../../../services/clubs/clubs.service';
import { CommunityService } from '../../../services/identity/community.service';
import { OrganizationService } from '../../../services/identity/organization.service';
import { ProjectsService } from '../../../services/projects/projects.service';
import { ScopeSelectionFormGroup } from '../../../shared/forms/scope-selection.form';
import { clubNameExistsValidator } from '../../validators/clubNameExistsValidator';

@Component({
    selector: 'app-project-form-step-one',
    templateUrl: './project-form-step-one.component.html',
    styleUrls: ['./project-form-step-one.component.scss'],
})
export class ProjectFormStepOneComponent implements OnInit {
    public JoinableType = JoinableType;
    public activeFragment: string;
    public allCommunities: Community[] = [];
    public bffCommunitiesIds: string[] = environment.features.projectTypeFranceRelanceCommunitiesIdsAvailable;
    public nvCommunitiesIds: string[] = environment.features.projectTypeNvCommunitiesIdsAvailable;
    @Input() public canCreateAllCommunitiesProject: boolean;
    public categoryProjectTypeAvailable = environment.features.categoryProjectTypeAvailable;
    public collaborationPlatformTypeEnum = CollaborationPlatformTypeEnum;
    @ViewChild('draftStepOneModal2', { static: false })
    public draftStepOneModal: TemplateRef<any>;
    public formSubmitAttempt = false;
    public ifgpCommunitiesIds: string[] = environment.features.projectTypeIfgpCommunitiesIdsAvailable;
    @Input() public isAdmin: boolean;
    @Input() public isDraft: boolean;
    @Input() public isEditMode: boolean;
    public isStepLoaded = false;
    public previousCollaborativePlatform: CollaborationPlatformTypeEnum;
    public previousIsAllCommunitiesVisibilityChecked: boolean;
    @Input() public projectCommand: BaseProjectCommand;
    @Input() public projectLight: ProjectLight;
    @Input() public projectOwner: Attendee;
    public projectTypeCategories = projectTypeCategories;
    @Input() public projectTypes: ProjectType[];
    @Output() public saved = new EventEmitter();
    public stepOneForm: FormGroup;
    @Output() public submitted = new EventEmitter();
    public uimmCommunitiesIds: string[] = environment.features.communitiesUimmIds;
    public userCommunities: Community[] = [];
    public userOrganization: OrganizationBase;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly communityService: CommunityService,
        private readonly organizationService: OrganizationService,
        private readonly toastrHelper: ToastrHelper,
        private readonly modalService: NgbModal,
        private readonly projectService: ProjectsService,
        private readonly router: Router,
        private readonly spinnerHelper: SpinnerHelper,
        private readonly route: ActivatedRoute,
        private translate: TranslateService,
        private readonly clubsService: ClubsService,
    ) {}

    public get hideAllCommunitiesVisibility() {
        return !this.canCreateAllCommunitiesProject;
    }

    public get showAllCommunitiesVisibility() {
        return this.canCreateAllCommunitiesProject;
    }

    public get visibility(): ScopeSelectionFormGroup {
        return this.stepOneForm.get('visibility') as ScopeSelectionFormGroup;
    }

    public checkFormValidity() {
        this.formSubmitAttempt = true;

        if (this.stepOneForm.invalid) {
            const msg = this.translate.instant('shared.forms.errors.checkAndRetry');
            this.toastrHelper.showError(msg);
            return false;
        }

        if (
            this.stepOneForm.controls.isPublic.value &&
            !(this.stepOneForm.controls.publicJoinUrl.value && this.stepOneForm.controls.publicJoinUrl.value.match(regexPatterns.fullUrl))
        ) {
            const msg = "Lien pour rejoindre la communauté n'est pas valide.";
            this.toastrHelper.showError(msg);
            return false;
        }

        const startDate = new Date(Date.parse(this.stepOneForm.controls.startDate.value));
        if ((!this.projectLight || this.projectLight.status === projectStatus.draft) && startDate.getTime() < Date.now()) {
            const msg = this.translate.instant('projects.formStep1.startDateBeforeNow');
            this.toastrHelper.showError(msg);
            return false;
        }

        const endDate = new Date(Date.parse(this.stepOneForm.controls.endDate.value));
        if (endDate.getTime() <= startDate.getTime()) {
            const msg = this.translate.instant('projects.formStep1.endDateBeforeStartDate');
            this.toastrHelper.showError(msg);
            return false;
        }

        // validate name

        return true;
    }

    public createForm() {
        this.stepOneForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(50)], [clubNameExistsValidator(this.clubsService)]],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            isAnonymous: ['', Validators.required],
            visibility: new ScopeSelectionFormGroup(this.formBuilder, this.userCommunities),
            projectTypeId: ['', Validators.required],
            isPublic: [false],
            publicJoinUrl: [''],
        });
    }

    public deleteProject() {
        this.spinnerHelper.showSpinner();
        this.projectService.disableProject(this.projectLight.id).subscribe(
            () => {
                const msg = this.translate.instant('projects.formStep1.toast.projectDeleted');
                this.toastrHelper.showSuccess(msg);
                const url = this.isAdmin ? '/administration/projects' : '/profile/my-projects';
                this.router.navigate([url]);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }

    public getDefaultEndDate() {
        const date = new Date();
        date.setDate(date.getDate() + 2);

        return this.getDateString(date);
    }

    public getDefaultStartDate() {
        const date = new Date();
        date.setDate(date.getDate() + 1);

        return this.getDateString(date);
    }

    public getMaxDate() {
        const date = new Date();
        date.setDate(date.getDate() + 5 * 365);

        return this.getDateString(date);
    }

    public getMinEndDate() {
        const startDate = new Date(Date.parse(this.stepOneForm.controls.startDate.value));
        startDate.setDate(startDate.getDate() + 1);

        const endDate = new Date(Date.parse(this.stepOneForm.controls.endDate.value));
        if (endDate < startDate) {
            this.stepOneForm.controls.endDate.setValue(this.getDateString(startDate));
        }

        return this.getDateString(startDate);
    }

    public getProjectsTypesForCategory(category: string): ProjectType[] {
        if (category == projectTypeCategories.all && this.userCommunities.length > 0) {
            const isUserInIummCommunity = this.userCommunities.find((community) => this.uimmCommunitiesIds.includes(community.id)) != null;
            const isUserInIfgpCommunity = this.userCommunities.find((community) => this.ifgpCommunitiesIds.includes(community.id)) != null;
            const isUserInBffCommunity = this.userCommunities.find((community) => this.bffCommunitiesIds.includes(community.id)) != null;
            const isUserInACommunityNotIumm = this.userCommunities.find((community) => !this.uimmCommunitiesIds.includes(community.id)) != null;
            const isUserInNvCommunity = this.userCommunities.find((community) => this.nvCommunitiesIds.includes(community.id)) != null;

            return this.projectTypes.filter(
                (projectType) =>
                    !projectType.category ||
                    (isUserInIummCommunity &&
                        projectType.category.find(
                            (name) => name.toLowerCase() == projectTypeCategories.rechercher || name.toLowerCase() == projectTypeCategories.proposer,
                        )) ||
                    (isUserInIfgpCommunity && projectType.category.find((name) => name.toLowerCase() == projectTypeCategories.ifgp)) ||
                    (isUserInNvCommunity && projectType.category.find((name) => name.toLowerCase() == projectTypeCategories.nv)) ||
                    (isUserInBffCommunity && projectType.category.find((name) => name.toLowerCase() == projectTypeCategories.francerelance)) ||
                    (isUserInACommunityNotIumm &&
                        projectType.category.find(
                            (name) =>
                                name.toLowerCase() == projectTypeCategories.business ||
                                name.toLowerCase() == projectTypeCategories.learning ||
                                name.toLowerCase() == projectTypeCategories.innovation,
                        )),
            );
        } else if (this.categoryProjectTypeAvailable) {
            return this.projectTypes.filter((projectType) => projectType.category.find((name) => name.toLowerCase() == category));
        }
    }

    public initData() {
        if (this.isEditMode) {
            this.initFormData();
        } else {
            this.stepOneForm.controls.startDate.setValue(this.getDefaultStartDate());
            this.stepOneForm.controls.endDate.setValue(this.getDefaultEndDate());
            this.stepOneForm.controls.isAnonymous.setValue(false);
        }

        this.isStepLoaded = true;
    }

    public initFormData() {
        this.stepOneForm.controls.name.setValue(this.projectCommand.name);
        this.stepOneForm.controls.startDate.setValue(this.parseDate(this.projectCommand.startDate.toString()));
        this.stepOneForm.controls.endDate.setValue(this.parseDate(this.projectCommand.endDate.toString()));
        this.stepOneForm.controls.isAnonymous.setValue(this.projectCommand.isAnonymous);
        this.stepOneForm.controls.projectTypeId.setValue(this.projectCommand.projectTypeId);

        this.visibility.selectedCommunities = this.projectCommand.communities;
        this.visibility.organizationVisibility.setValue(this.projectCommand.organizationVisibility);
        this.visibility.myCommunitiesVisibility.setValue(this.visibility.getUserSelectedCommunitiesCount() > 0);
        this.visibility.allCommunitiesVisibility.setValue(this.projectCommand.allCommunitiesVisibility);

        this.previousCollaborativePlatform = this.projectCommand.collaborationPlatformType;
        this.previousIsAllCommunitiesVisibilityChecked = this.projectCommand.allCommunitiesVisibility;

        this.stepOneForm.controls.isPublic.setValue(this.projectCommand.isPublic);
        this.stepOneForm.controls.publicJoinUrl.setValue(this.projectCommand.publicJoinUrl);
    }

    public isRemovable() {
        return this.projectLight?.status === projectStatus.draft || this.projectLight?.status === projectStatus.unpublished;
    }

    public ngOnInit() {
        forkJoin([
            this.communityService.getCommunities(),
            this.projectOwner ? this.communityService.getUserCommunities(this.projectOwner.id) : this.communityService.getMyCommunities(),
            this.projectOwner ? this.organizationService.getUserOrganization(this.projectOwner.id) : this.organizationService.getMyOrganization(),
        ]).subscribe(
            ([allCommunities, userCommunities, organization]: [Community[], Community[], OrganizationBase]) => {
                this.allCommunities = allCommunities.filter((community) => !community.applicationAccesses.includes(WudoApplicationType.Mobile));
                this.userCommunities = userCommunities.filter((community) => !community.applicationAccesses.includes(WudoApplicationType.Mobile));
                this.userOrganization = organization;

                this.createForm();
                this.initData();

                this.route.fragment.subscribe((fragment) => {
                    this.activeFragment = fragment || this.getDefaultFragment();
                });
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
        );
    }

    private getDefaultFragment(): string {
        if (this.isUserInCommunity(this.bffCommunitiesIds)) {
            return this.projectTypeCategories.francerelance;
        } else if (this.isUserInCommunity(this.ifgpCommunitiesIds)) {
            return this.projectTypeCategories.ifgp;
        } else if (this.isUserInCommunity(this.nvCommunitiesIds)) {
            return this.projectTypeCategories.nv;
        } else {
            return this.projectTypeCategories.all;
        }
    }

    private isUserInCommunity(communityIds: string[]): boolean {
        return this.userCommunities.some((community) => communityIds.includes(community.id));
    }

    public onClose() {
        this.modalService.dismissAll();
    }

    public onSave() {
        this.onClose();
        this.saved.emit();
    }

    public onSubmit() {
        if (this.checkFormValidity()) {
            this.updateProjectCommandFromStepOneComponent();
            this.submitted.emit();
        }
    }

    public openConfirmePopupSaveDraft() {
        if (this.checkFormValidity()) {
            this.modalService.open(this.draftStepOneModal, {
                size: 'lg',
                scrollable: false,
                backdrop: 'static',
            });
        }
    }

    public selectProjectType(projectType: ProjectType) {
        this.stepOneForm.controls.projectTypeId.setValue(projectType.id);
    }

    public updateProjectCommandFromStepOneComponent() {
        const command: BaseProjectCommand = this.stepOneForm.value;

        this.projectCommand.name = command.name.trim();
        this.projectCommand.startDate = command.startDate;
        this.projectCommand.endDate = command.endDate;
        this.projectCommand.isAnonymous = command.isAnonymous;
        this.projectCommand.organizationVisibility = this.visibility.organizationVisibility.value;
        this.projectCommand.organization = this.visibility.organizationVisibility.value ? this.userOrganization : null;
        this.projectCommand.allCommunitiesVisibility = this.visibility.allCommunitiesVisibility.value;
        this.projectCommand.communities = this.visibility.selectedCommunities;
        this.projectCommand.projectTypeId = this.stepOneForm.controls.projectTypeId.value;
        this.projectCommand.isPublic = command.isPublic;
        this.projectCommand.publicJoinUrl = command.publicJoinUrl;
    }

    private getDateString(date: Date) {
        // Months start from 0 not 1.
        const month = date.getMonth() + 1;
        return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    }

    private parseDate(dateString: string): string {
        const dateItems = dateString.split('/');
        const parsedDate = new Date(parseInt(dateItems[2]), parseInt(dateItems[1]) - 1, parseInt(dateItems[0]));

        // Important : getMonth() method get the month index (january is 0) !
        return `${parsedDate.getFullYear()}-${parsedDate.getMonth() + 1 < 10 ? '0' : ''}${parsedDate.getMonth() + 1}-${
            parsedDate.getDate() < 10 ? '0' : ''
        }${parsedDate.getDate()}`;
    }
}
