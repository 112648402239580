<p-dialog
    header="{{ headerTitle }}"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '50vw' }"
    [(visible)]="isModalDisplayed"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    appendTo="body"
    styleClass="custom-create-room-modal"
>
    <form [formGroup]="form" novalidate>
        <div class="input-information-frame" [ngClass]="{ 'disabled-div': !isTitleEditable }">
            <label class="content-label" for="name">{{ 'clubs.createClubButton.clubName' | translate }}</label>
            <div class="length-information">
                {{ form.controls.title?.value?.length || '0' }}/{{ CLUB_NAME_MAX_LENGTH }}
            </div>
        </div>
        <div
            class="resource-frame"
            [ngClass]="{ 'disabled-div': !isTitleEditable }"
            pTooltip="{{ 'toastMessages.inputUnmodifiable' | translate }}"
            tooltipPosition="top"
            [tooltipDisabled]="isTitleEditable"
        >
            <input
                type="text"
                name="title"
                id="title"
                [placeholder]="'clubs.createClubButton.namePlaceholder' | translate"
                class="resource-textarea"
                formControlName="title"
                [ngClass]="{ disabled: !isTitleEditable }"
            />
        </div>
        <div *ngIf="form.controls.title.invalid && (form.controls.title.dirty || form.controls.title.touched)">
            <small class="p-error" *ngIf="form.controls.title.errors.required">
                {{ 'clubs.createClubButton.nameRequired' | translate }}
            </small>
            <small class="p-error" *ngIf="form.controls.title.errors.maxlength">
                {{ 'clubs.createClubButton.nameLength' | translate : { maxLength: CLUB_NAME_MAX_LENGTH } }}
            </small>
        </div>

        <div>
            <div class="input-information-frame">
                <label class="content-label" for="description">{{
                    'clubs.createClubButton.description' | translate
                }}</label>
                <div class="length-information">{{ descriptionCounter }}/{{ DESCRIPTION_MAX_LENGTH }}</div>
            </div>
            <div>
                <quill-editor
                    formControlName="description"
                    [placeholder]="'clubs.createClubButton.descriptionPlaceholder' | translate"
                    theme="snow"
                    required="true"
                    [modules]="quillEditorHelper.modulesWithoutImage"
                    [formats]="quillEditorHelper.formatsWithoutImage"
                    (onContentChanged)="onDescriptionChange($event.editor)"
                    class="notranslate"
                    [ngClass]="{ 'ql-french': displayInFrench }"
                >
                </quill-editor>
                <div
                    *ngIf="
                        form.controls.description.invalid &&
                        (form.controls.description.dirty || form.controls.description.touched)
                    "
                >
                    <small id="username-help" class="p-error" *ngIf="form.controls.description.errors.maxlength">
                        {{ 'clubs.createClubButton.descriptionLength' | translate : { maxLength: 2000 } }}
                    </small>
                </div>
            </div>
        </div>
    </form>
    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="closeModal()"
        ></button>

        <button
            pButton
            [disabled]="form.invalid"
            class="p-button p-button-sm p-button-custom-submit"
            type="button"
            label="{{ (isEditMode ? 'components.button.save' : 'components.button.create') | translate }}"
            (click)="handleOnSubmit()"
        ></button>
    </p-footer>
</p-dialog>

<div class="create-club-modal-button" (click)="openModal()" *ngIf="!isEditMode && !club && showButton">
    <div class="label">
        <i class="pi pi-plus"></i>
    </div>
</div>
