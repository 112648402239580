<div class="row form-header pb-2">
    <div class="col-12">
        <div class="row wizard">
            <div
                class="col wizard__item"
                [class.wizard__item-active]="currentStep === 1"
                [class.wizard__item-past]="currentStep === 2"
                [class.wizard__item-finish]="currentStep === 3"
                [class.wizard__item-pointer]="currentStep > 1"
            >
                <a
                    class="wizard__node"
                    (click)="currentStep > 1 && onWizardStepOne()"
                >
                    <div *ngIf="currentStep < 2">1</div>
                    <em
                        *ngIf="currentStep > 1"
                        class="icon-check"
                    ></em>
                </a>
            </div>
            <div
                class="col wizard__item"
                [class.wizard__item-active]="currentStep === 2"
                [class.wizard__item-past]="currentStep === 3"
                [class.wizard__item-pointer]="currentStep !== 2"
            >
                <a
                    class="wizard__node"
                    (click)="currentStep !== 2 && onWizardStepTwo()"
                >
                    <div *ngIf="currentStep < 3">2</div>
                    <em
                        *ngIf="currentStep > 2"
                        class="icon-check"
                    ></em>
                </a>
            </div>
            <div
                class="col wizard__item"
                [class.wizard__item-active]="currentStep === 3"
                [class.wizard__item-pointer]="currentStep !== 3"
            >
                <a
                    class="wizard__node"
                    (click)="currentStep !== 3 && onWizardStepThree()"
                    >3</a
                >
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h1 class="view-name">
                    {{ 'community.pages.projects.edit.title' | translate }}
                    <strong *ngIf="projectOwner; else projectOwnerLoading">
                        {{ projectOwner.firstName }}
                        {{ projectOwner.lastName }}
                    </strong>
                </h1>
                <ng-template #projectOwnerLoading>
                    <strong class="loading-dots"></strong>
                </ng-template>
            </div>

            <div
                class="col-auto mr-auto mt-4"
                *ngIf="project"
            >
                <!--Supprimer un projet-->
                <button
                    class="btn btn-danger"
                    data-toggle="modal"
                    data-target="#confirmDeleteProjectModal"
                    type="button"
                >
                    <em class="icon icon-trash d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonDelete' | translate }}</span>
                </button>

                <button
                    *ngIf="currentStep < 3"
                    class="btn btn-primary"
                    type="button"
                    (click)="onStepNext()"
                >
                    <em class="icon icon-arrow-left1 d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonNext' | translate }}</span>
                </button>

                <ng-container *ngIf="currentStep === 3 && project.status !== 'draft'">
                    <div
                        *ngIf="project.status === 'unpublished'; else updateActionOnly"
                        class="btn-group"
                    >
                        <button
                            type="button"
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#confirmUpdateAndPublishModal"
                        >
                            <em class="icon icon-save d-sm-inline d-md-none"></em>
                            <span class="d-sm-inline d-md-none">&nbsp;/&nbsp;</span>
                            <em class="icon icon-publish d-sm-inline d-md-none"></em>
                            <span class="d-none d-md-inline">{{
                                'components.button.modifyAndPublish' | translate
                            }}</span>
                        </button>

                        <button
                            type="button"
                            class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span class="sr-only">{{ 'components.button.otherActions' | translate }}</span>
                        </button>

                        <div class="dropdown-menu">
                            <a
                                class="dropdown-item"
                                href="#"
                                data-toggle="modal"
                                data-target="#confirmUpdateModal"
                            >
                                <em class="icon icon-save d-sm-inline d-md-none"></em>
                                <span class="d-inline">{{ 'components.button.modify' | translate }}</span>
                            </a>

                            <a
                                class="dropdown-item"
                                href="#"
                                data-toggle="modal"
                                data-target="#confirmPublishModal"
                            >
                                <em class="icon icon-publish d-sm-inline d-md-none"></em>
                                <span class="d-inline">{{ 'components.button.publish' | translate }}</span>
                            </a>
                        </div>
                    </div>

                    <ng-template #updateActionOnly>
                        <button
                            class="btn btn-primary"
                            data-toggle="modal"
                            data-target="#confirmUpdateModal"
                        >
                            <em class="icon icon-save d-sm-inline d-md-none"></em>
                            <span class="d-none d-md-inline">{{ 'components.button.modify' | translate }}</span>
                        </button>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Delete Modal -->
<div
    class="modal fade bd-example-modal-lg"
    id="confirmDeleteProjectModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirmDeleteProjectModalLabel"
    aria-hidden="true"
>
    <div
        class="modal-dialog modal-lg"
        role="document"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    id="confirmeCloseProjectModalLabel"
                >
                    {{ 'community.pages.projects.edit.deleteModal.title' | translate }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-image">
                <img
                    src="{{ project?.pictureAccessLink }}"
                    alt=""
                />
            </div>
            <div class="modal-body">
                <p class="ta-c lead">
                    {{ 'community.pages.projects.edit.deleteModal.confirm' | translate }}
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ 'components.button.buttonCancel' | translate }}
                </button>
                <button type="button" class="btn btn-danger" (click)="deleteProject()" data-dismiss="modal">
                    {{ 'components.button.buttonDelete' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Edit and Publish Modal -->
<div
    class="modal fade bd-example-modal-lg"
    id="confirmUpdateAndPublishModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editAndPublishConfirmLabel"
    aria-hidden="true"
>
    <div
        class="modal-dialog modal-lg"
        role="document"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    id="editAndPublishConfirmLabel"
                >
                    {{ 'community.pages.projects.edit.editPublishModal.title' | translate }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-image">
                <img
                    src="{{ project?.pictureAccessLink }}"
                    [alt]="'community.pages.projects.edit.projectPictureAlt' | translate"
                />
            </div>
            <div class="modal-body">
                <p
                    *ngIf="project?.status === 'published'"
                    class="ta-c lead"
                >
                    <strong>
                        {{ 'community.pages.projects.edit.editPublishModal.warnRepublishProject' | translate }}
                    </strong>
                </p>
                <p
                    *ngIf="project?.allCommunitiesVisibility || project?.communities.length > 1"
                    class="ta-c lead"
                >
                    <strong>
                        {{ 'community.pages.projects.edit.editPublishModal.warnMulticomProject' | translate }}</strong
                    >
                </p>
                <p class="ta-c lead">
                    {{ 'community.pages.projects.edit.editPublishModal.confirm' | translate }}
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ 'components.button.buttonCancel' | translate }}
                </button>
                <button type="button" class="btn btn-primary" (click)="onUpdateAndPublish()" data-dismiss="modal">
                    {{ 'components.button.buttonYes' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Edit Modal -->
<div
    class="modal fade bd-example-modal-lg"
    id="confirmUpdateModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editConfirmeModalLabel"
    aria-hidden="true"
>
    <div
        class="modal-dialog modal-lg"
        role="document"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    id="exampleModalLabel"
                >
                    {{ 'community.pages.projects.edit.editModal.title' | translate }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-image">
                <img
                    src="{{ project?.pictureAccessLink }}"
                    [alt]="'community.pages.projects.edit.projectPictureAlt' | translate"
                />
            </div>
            <div class="modal-body">
                <p
                    *ngIf="project?.status === 'published'"
                    class="ta-c lead"
                >
                    <strong
                        >{{ 'community.pages.projects.edit.editModal.warnEditPublishedProject' | translate }}
                    </strong>
                </p>
                <p
                    *ngIf="project?.allCommunitiesVisibility || project?.communities.length > 1"
                    class="ta-c lead"
                >
                    <strong> {{ 'community.pages.projects.edit.editModal.warnMulticomProject' | translate }}</strong>
                </p>
                <p class="ta-c lead">
                    {{ 'community.pages.projects.edit.editModal.confirm' | translate }}
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ 'components.button.buttonCancel' | translate }}
                </button>
                <button type="button" class="btn btn-primary" (click)="onUpdate()" data-dismiss="modal">
                    {{ 'components.button.buttonYes' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Publish Modal -->
<div
    class="modal fade bd-example-modal-lg"
    id="confirmPublishModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="publishConfirmeModalLabel"
    aria-hidden="true"
>
    <div
        class="modal-dialog modal-lg"
        role="document"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    id="exampleModalLabel"
                >
                    {{ 'community.pages.projects.edit.publishModal.title' | translate }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-image">
                <img
                    src="{{ project?.pictureAccessLink }}"
                    [alt]="'community.pages.projects.edit.projectPictureAlt' | translate"
                />
            </div>
            <div class="modal-body">
                <p class="ta-c lead">
                    {{ 'community.pages.projects.edit.publishModal.confirm' | translate }}
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ 'components.button.buttonCancel' | translate }}
                </button>
                <button type="button" class="btn btn-primary" (click)="onPublish()" data-dismiss="modal">
                    {{ 'components.button.buttonYes' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div
    class="row"
    *ngIf="!updateProjectCommand"
>
    <div
        class="spinner-border text-info"
        role="status"
    >
        <span class="sr-only">{{ 'community.pages.projects.edit.loadingMessage' | translate }}</span>
    </div>
</div>

<app-project-form-step-one
    class="row"
    isEditMode="true"
    isAdmin="true"
    *ngIf="project"
    [hidden]="currentStep !== 1"
    [projectCommand]="updateProjectCommand"
    [projectTypes]="projectTypes"
    [projectOwner]="projectOwner"
    [isDraft]="project.status === 'draft'"
    [projectLight]="projectLight"
    [canCreateAllCommunitiesProject]="canCreateAllCommunitiesProject"
    (saved)="onSave()"
    (submitted)="onStepOneSubmit()"
    #projectFormStepOne
>
</app-project-form-step-one>

<app-project-form-abstract
    class="row"
    *ngIf="updateProjectCommand && currentStep > 1"
    [projectCommand]="updateProjectCommand"
    [projectTypes]="projectTypes"
>
</app-project-form-abstract>

<app-project-form-step-two
    class="row"
    isEditMode="true"
    isAdmin="true"
    *ngIf="project"
    [hidden]="currentStep !== 2"
    [projectCommand]="updateProjectCommand"
    [projectTypes]="projectTypes"
    [projectPictureLink]="projectPictureLink"
    [projectLight]="projectLight"
    [isDraft]="project.status === 'draft'"
    (saved)="onSave()"
    (submitted)="onStepTwoSubmit()"
    (previousStep)="goToPreviousStep()"
    #projectFormStepTwo
>
</app-project-form-step-two>

<app-project-form-step-three
    class="row"
    isEditMode="true"
    isAdmin="true"
    *ngIf="project"
    [hidden]="currentStep !== 3"
    [projectCommand]="updateProjectCommand"
    [projectTypes]="projectTypes"
    [isDraft]="project.status === 'draft'"
    [isPublished]="project.status === 'published'"
    [projectLight]="projectLight"
    (saved)="onSave()"
    (submitted)="onStepThreeSubmit($event)"
    (published)="onPublish()"
    (previousStep)="goToPreviousStep()"
    #projectFormStepThree
>
</app-project-form-step-three>
