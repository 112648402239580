<div
    class="card box-shadow"
    [class.reduced]="reduced"
>
    <div class="card-body ta-c">
        <div class="mt-4 mb-4">
            <img
                src="./assets/images/no-result.svg"
                alt="{{ 'defaultValues.noResultsToSearch' | translate }}"
            />
        </div>
    </div>
</div>
