import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PublicationHelper } from '../../helpers/publication.helper';
import { CreateMulitplePublicationCommand } from '../../models/clubs/create-multiple-publication.command';
import { Publication } from '../../models/clubs/publication.model';
import { ReportPublicationCommand } from '../../models/clubs/report-publication-command.model';
import { EditPublicationCommand } from './../../models/clubs/edit-publication.command';
import { Reaction } from './../../models/clubs/reaction.model';

export class EditMemberReactionResult {
    public previousReaction: Reaction;
    public publication: Publication;
}

@Injectable({
    providedIn: 'root',
})
export class PublicationService {
    private readonly clubsApiUrl = `${environment.microservices.clubs.baseUrl}/${environment.microservices.clubs.clubsApi.baseUrl}`;
    private readonly getClubPublicationsByPagesUrl = `${environment.microservices.clubs.clubsApi.getClubPublicationsByPages}`;
    private readonly getPublicationCommentByPagesApiUrl = `${environment.microservices.clubs.publicationApi.getPublicationCommentsByPages}`;
    private readonly getPublicationsByClubIdApiUrl = `${environment.microservices.clubs.clubsApi.getPublicationsByClubId}`;
    private readonly getPublicationsByParentIdApiUrl = `${environment.microservices.clubs.publicationApi.getPublicationsByParentId}`;
    private readonly interactWithPublicationReactionsApiUrl = `${environment.microservices.clubs.publicationApi.getPublicationReactions}`;
    private readonly publicationApiUrl = `${environment.microservices.clubs.baseUrl}/${environment.microservices.clubs.publicationApi.baseUrl}`;
    private readonly publicationMultipleApiUrl = `${environment.microservices.clubs.baseUrl}/${environment.microservices.clubs.publicationApi.baseUrl}/${environment.microservices.clubs.publicationApi.createMultiplePosts}`;
    private readonly repostAsAnnouncementApiUrl = `${environment.microservices.clubs.publicationApi.repostAsAnnouncement}`;

    constructor(private http: HttpClient) {}

    public createMultiplePublication(command: CreateMulitplePublicationCommand): Observable<Publication> {
        const formData = new FormData();
        formData.append('content', command.content);
        command.roomIds.forEach((roomId) => formData.append('roomIds', roomId));
        if (command.attachedDocumentId) {
            formData.append('attachedDocumentId', command.attachedDocumentId);
        }
        if (command.attachedUrl) {
            formData.append('attachedUrl', command.attachedUrl);
        }
        if ((command.mentionMembers || []).length > 0) {
            command.mentionMembers.forEach((mentionMember, index) => {
                formData.append(`mentionMembers[${index}][tagMember]`, mentionMember.tagMember);
                formData.append(`mentionMembers[${index}][userId]`, mentionMember.userId);
            });
        }
        formData.append('isAnAnnouncement', command.isAnAnnouncement ? String(command.isAnAnnouncement) : String(false));

        formData.append('picture', command.picture);

        return this.http.post<Publication>(this.publicationMultipleApiUrl, formData);
    }

    public deletePublication(publicationId: string): Observable<string> {
        return this.http.delete<string>(`${this.publicationApiUrl}/${publicationId}`);
    }

    public editMemberReactionToPublication(postId: string, reactionTypeId: string): Observable<EditMemberReactionResult> {
        return this.http.post<EditMemberReactionResult>(
            `${this.publicationApiUrl}/${postId}/${this.interactWithPublicationReactionsApiUrl}/${reactionTypeId}`,
            null,
        );
    }

    public editPublication(command: EditPublicationCommand): Observable<Publication> {
        const formData: FormData = PublicationHelper.getFormData(command);
        formData.append('Id', command.postId);
        formData.append('pictureHasChanged', String(command.pictureHasChanged));
        return this.http.put<Publication>(this.publicationApiUrl, formData);
    }

    public getClubPublicationsByPages(clubId: string, publicationsFlowType: number, lastVisitDate: Date, page: number): Observable<Publication[]> {
        const formatedDate = moment(lastVisitDate).format('YYYY-MM-DDTHH:mm:ss');

        return this.http.get<Publication[]>(`${this.clubsApiUrl}/${clubId}/${this.getClubPublicationsByPagesUrl}`, {
            params: {
                publicationsFlowTypeStr: publicationsFlowType.toString(),
                lastVisitDateStr: formatedDate,
                page: page.toString(),
                pageSize: environment.microservices.clubs.settings.publicationsPageSize.toString(),
            },
        });
    }

    public getPublicationById(publicationId: string): Observable<Publication> {
        return this.http.get<Publication>(`${this.publicationApiUrl}/${publicationId}`);
    }

    public getPublicationCommentByPages(publicationId: string, page: number): Observable<Publication[]> {
        const currentPageSize = environment.microservices.clubs.settings.commentsPageSize.toString();

        return this.http.get<Publication[]>(
            `${this.publicationApiUrl}/${this.getPublicationsByParentIdApiUrl}/${publicationId}/${this.getPublicationCommentByPagesApiUrl}`,
            {
                params: {
                    page: page.toString(),
                    pageSize: currentPageSize,
                },
            },
        );
    }

    public getPublicationsByClubId(clubId: string): Observable<Publication[]> {
        return this.http.get<Publication[]>(`${this.clubsApiUrl}/${clubId}/${this.getPublicationsByClubIdApiUrl}`);
    }

    public getReactionsByPublicationId(postId: string): Observable<Reaction[]> {
        return this.http.get<Reaction[]>(`${this.publicationApiUrl}/${postId}/${this.interactWithPublicationReactionsApiUrl}`);
    }

    public repostAsAnnouncement(publicationId: string) {
        return this.http.post<Publication>(`${this.publicationApiUrl}/${this.repostAsAnnouncementApiUrl}/${publicationId}`, null);
    }

    public report(command: ReportPublicationCommand): Observable<string> {
        return this.http.post<string>(`${this.publicationApiUrl}/report`, command);
    }
}
