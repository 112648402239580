import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { EventsService } from '../../../services/events/events.service';
import { AccountsService } from '../../../services/identity/accounts.service';
import { PwaService } from '../../../services/pwa/pwa.service';
import { DataStorageService } from '../../../services/shared/data-storage.service';
import { BaseFilteredList } from '../../directives/base-filtered-list.directive';
import { Event } from '../../../models/events/event.model';
import { MyEventsFiltersComponent } from '../../../filters/my-events-filters/my-events-filters.component';
import { MyEventsFilters } from '../../../models/events/my-event-filters.model';
@Component({
    templateUrl: './my-events-list.component.html',
    styleUrls: ['./my-events-list.component.scss']
})
export class MyEventsListComponent extends BaseFilteredList<Event, MyEventsFilters, MyEventsFiltersComponent> implements OnInit {

    constructor(
        private readonly eventService: EventsService,
        private readonly dataStorage: DataStorageService,
        private readonly accountService: AccountsService,
        toastrHelper: ToastrHelper,
        pwaService: PwaService,
    ) {
        super(pwaService, toastrHelper);
    }

    ngOnInit() {
        this.isLoaded = true;
    }

    protected override get savedFilters(): MyEventsFilters {
        return this.dataStorage.myEventsFilters;
    }

    protected override set savedFilters(value: MyEventsFilters) {
        this.dataStorage.myEventsFilters = value;
    }

    protected override loadFilters(): MyEventsFilters {
        let filters;

        if (this.savedFilters) {
            filters = this.savedFilters;
            this.filtersComponent.setSelectedFilters(filters);
        } else {
            filters = this.filtersComponent.extractSelectedFilters();
            this.savedFilters = filters;
        }

        filters.page = this.page;

        return filters;
    }

    protected override getFilteredItemsSubscription(): Observable<Event[]> {
        return this.eventService.getMyFilteredEvents(this.loadFilters());
    }
}
