import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventTypeEnumLabelMap } from '../../../models/events/event-type.enum.model';
import { Event } from '../../../models/events/event.model';
import { AccountsService } from '../../../services/identity/accounts.service';
import { formatDate } from '../../helpers/date.helper';
import { EventJoinStatus, getEventJoinStatus } from '../../helpers/event.helper';
@Component({
    selector: 'app-event-details-card',
    templateUrl: './event-details-card.component.html',
    styleUrls: ['./event-details-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDetailsCardComponent implements OnInit {
    @Input()
    event: Event;

    public eventJoinStatus: EventJoinStatus;

    EventJoinStatus = EventJoinStatus;
    EventTypeEnumLabelMap = EventTypeEnumLabelMap;
    formatDate = formatDate;

    constructor(private readonly accountsService: AccountsService, private readonly router: Router) {}

    ngOnInit(): void {
        this.eventJoinStatus = getEventJoinStatus(this.event);
    }

    get croppedDescription(): string {
        if (this.event.description.length <= 200) {
            return this.event.description;
        }

        return this.event.description.substring(0, 198) + '<span>...</span>';
    }

    isUserParticipatingToEvent(): boolean {
        const currentUserId = this.accountsService.currentUser.userId;
        if (!currentUserId || !this.event) {
            return false;
        }
        const isAttendee = this.event.attendees.some((attendee) => attendee.id === currentUserId);
        if (isAttendee) {
            return true;
        }
        return isAttendee;
    }
    public navigateToEvent(): void {
        this.router.navigate(['/events', this.event.id]);
    }
}
