import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AddressResult } from '../../../../azure/map/models/address.model';
import { AddressSearchService } from '../../../../azure/map/services/address.service';

@Component({
    selector: 'app-city-autocomplete',
    template: `
        <div class="form-group">
            <label for="city_user">{{ 'user.personalInfo.city.title' | translate }} <span *ngIf="isRequired">*</span></label>
            <input
                type="text"
                [formControl]="searchControl"
                (focus)="isOpen = true"
                class="form-control d-block w-100"
                id="city_user"
                [required]="isRequired"
                [placeholder]="'user.personalInfo.city.placeholder' | translate"
            />
            <ul *ngIf="isOpen && suggestions$ | async as suggestions" class="suggestions-list w-100">
                <li *ngFor="let suggestion of suggestions" (click)="selectCity(suggestion)">
                    {{ suggestion.address.municipality }}
                </li>
            </ul>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CityAutocompleteComponent,
            multi: true,
        },
    ],
    styles: [
        `
            .form-group {
                width: 100%;
                position: relative;
            }

            .form-control {
                width: 100%;
                display: block;
            }

            .suggestions-list {
                list-style: none;
                padding: 0;
                margin: 0;
                border: 1px solid #ddd;
                border-top: none;
                max-height: 200px;
                overflow-y: auto;
                position: absolute;
                background: white;
                z-index: 1000;
                width: 100%;
                left: 0;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }

            li {
                padding: 8px 12px;
                cursor: pointer;
                border: none;
                background: white;
            }

            li:hover {
                background-color: #f5f5f5;
            }
        `,
    ],
})
export class CityAutocompleteComponent implements OnInit, ControlValueAccessor {
    @Input() selectedCountry: AddressResult | null = null;
    @Input() isRequired: boolean = false;

    @Output() citySelected = new EventEmitter<AddressResult>();

    isOpen = true;
    searchControl = new FormControl('');

    suggestions$ = this.searchControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        filter((query) => query?.length >= 2 || !query),
        switchMap((query) => {
            if (!query) return of([]);

            const countrySet = this.selectedCountry?.address?.countryCode;
            return this.addressService.searchCity(query, {
                countrySet,
            });
        }),
    );

    constructor(private addressService: AddressSearchService) {}

    ngOnInit(): void {
        this.searchControl.valueChanges.subscribe((value) => {
            this.onChange(value);
        });
    }

    onChange = (_: any) => {};
    onTouched = () => {};

    writeValue(value: any): void {
        if (value) {
            this.searchControl.setValue(value, { emitEvent: false });
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    selectCity(suggestion: any): void {
        const cityName = suggestion.address.municipality;
        this.searchControl.setValue(cityName);
        this.isOpen = false;
        this.onChange(suggestion);
        this.citySelected.emit(suggestion);
    }
}
