import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import { localStorageKeys } from '../../constants/local-storage-keys';
import { rights, userTypes } from '../../constants/permissions';
import { JwtParser } from '../../helpers/jwt.parser';
import { AuthenticatedUser } from '../../models/identity/authenticated-user.model';
import { DecodedToken } from '../../models/identity/decoded-token.model';
import { UserPermissions } from '../../models/identity/user-permission.model';
import { UpdatePermissions } from '../../states/permissions/permissions.actions';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
    private currentUser: AuthenticatedUser;
    private decodedToken: DecodedToken;
    public role: string;
    private permissions: UserPermissions;
    private environment = environment;

    constructor(
        private readonly jwtParser: JwtParser,
        private readonly store: Store,
    ) {
        this.assignPermissions();
    }

    private hasRight(rights: Array<string>, rightCode: string) {
        return this.permissions.admin || (rights || []).some((right) => right === rightCode);
    }

    private assignDecodedTokenAndRole(): void {
        this.currentUser = JSON.parse(localStorage.getItem(localStorageKeys.currentUser));

        if (this.currentUser) {
            this.decodedToken = this.jwtParser.decodeToken(this.currentUser.accessToken);
            if (this.decodedToken != null) this.role = this.decodedToken.role;
            // this.permissions = this.decodedToken.permissions ? JSON.parse(this.decodedToken.permissions) : new UserPermissions();
            //  implements refresh token!! (store et get from claims identity)
        }
    }

    public assignPermissions(): void {
        this.assignDecodedTokenAndRole();

        // IMPORTANT : after implements refresh token!! (delete function reloadCurrentUserFromDataSource)
        // => this.permission will be correctly feed!
        // not need to get/refresh from this.currentUser!! => remove line
        this.permissions = this.currentUser?.permissions || new UserPermissions();
        this.permissions.admin = this.role === userTypes.admin;

        const flatCommunitiesRights = new Array<string>();
        Object.values(this.permissions.communitiesRights || {}).forEach((value) => {
            flatCommunitiesRights.push(...value);
        });

        const flatOrganizationsRights = new Array<string>();
        Object.values(this.permissions.organizationsRights || {}).forEach((value) => {
            flatOrganizationsRights.push(...value);
        });

        this.permissions.canViewDocuments = this.hasRight(this.permissions.globalRigths, rights.LEGAL_DOCUMENT_VIEW);

        this.permissions.canEditOrganization = this.hasRight(flatOrganizationsRights, rights.ADMIN_ORGANIZATION_EDIT);
        this.permissions.canCreateLabServices = this.hasRight(flatOrganizationsRights, rights.LAB_SERVICE_CREATE);

        this.permissions.canPublishProject = this.hasRight(flatCommunitiesRights, rights.ADMIN_PROJECT_PUBLISH);

        this.permissions.canViewContactsDirectory = this.hasRight(this.permissions.globalRigths, rights.DIRECTORY_VIEW);

        this.permissions.canAccessCommunityManagementComponents = this.hasRight(flatCommunitiesRights, rights.ADMIN_COMMUNITY_MANAGE);

        this.permissions.canViewCetimClub =
            this.currentUser?.communities?.some(
                (community) =>
                    community?.id === environment.communitiesId.boostFrenchFabId && community?.groups?.includes('Porteurs de projets industriels'),
            ) || false;

        const isNotTiCommunity = !this.currentUser?.communities?.some(
            (community) => community.id === this.environment.communitiesId.tiId && community.isPrimary,
        );

        this.permissions.canViewDocuments = this.permissions.canViewDocuments && isNotTiCommunity;

        this.store.dispatch(new UpdatePermissions(this.permissions));
    }

    /**
     * Check if user permissions authorize to edit the specific organization.
     * @param organizationId
     */
    public canEditOrganization(organizationId: string) {
        return (
            this.permissions.admin ||
            (this.permissions.organizationsRights || {})[organizationId]?.some((right: string) => right === rights.ADMIN_ORGANIZATION_EDIT) ||
            false
        );
    }

    public isUserCommunityInfluencer(idCommunity: string): boolean {
        for (const community of this.currentUser.communities)
            if (community.id == idCommunity && community.roles)
                for (const role of community.roles) if (role.code == userTypes.communityInfluencer) return true;

        return false;
    }

    public canSeeAllCommunities(): boolean {
        return this.decodedToken.allCommunitiesVisibility === 'True';
    }
}
