import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GeographicalArea } from '../../models/identity/geographical-area.model';
import { CacheService } from '../shared/cache.service';

@Injectable({ providedIn: 'root' })
export class GeographicalAreaService {
    private geographicalAreasApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.geographicalAreasApi.baseUrl}`;

    // Key of the geographical areas in the cache
    private geographicalAreasCacheKey = 'currentGeographicalAreas';

    constructor(private http: HttpClient, private readonly cacheService: CacheService) {}

    getGeographicalAreas(): Observable<GeographicalArea[]> {
        return this.cacheService.get(
            this.geographicalAreasCacheKey,
            this.http.get<GeographicalArea[]>(this.geographicalAreasApiUrl),
        );
    }
}
