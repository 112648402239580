import { Community } from '../identity/community.model';
import { Attendee } from '../shared/attendee.model';
import { BaseEditableEntity } from '../shared/base-editable-entity.model';
import { EventAccessTypeEnum } from './event-access-type.enum.model';
import { EventLight } from './event-light.model';
import { EventTypeEnum } from './event-type.enum.model';

export const EVENT_DESCRIPTION_MAX_LENGTH = 1500;
export const EVENT_TITLE_MAX_LENGTH = 150;
export class Event extends BaseEditableEntity {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    closingDate: string;
    description: string;
    type: EventTypeEnum;
    accessType: EventAccessTypeEnum;
    eventLink?: string;
    address?: string;
    addressComplement?: string;
    maxAttendeesCount?: number;
    pictureReference: string;
    pictureAccessLink: string;
    registrationLink: string;
    allCommunitiesVisibility: boolean;
    organizationVisibility: boolean;
    owner: Attendee;
    attendees: Attendee[];
    communities: Community[];
    isFull: boolean;
    hasStarted: boolean;
    isFinished: boolean;
    isOngoing: boolean;
    isCanceled: boolean;

    public static toEventLight(event: Event): EventLight {
        const result = new EventLight();
        result.id = event?.id;
        result.pictureAccessLink = event?.pictureAccessLink;
        return result;
    }

    public static canBeEdited(event: Event): boolean {
        return !event.isOngoing && !event.isFinished && !event.isCanceled;
    }

    public static isExternalEvent(event: Event): boolean {
        return !!event?.registrationLink && event?.registrationLink?.length > 0;
    }
}
