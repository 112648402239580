<form
    (change)="onChange()"
    [formGroup]="form"
    novalidate
    ngDefaultControl
    class="p-m-auto p-d-flex p-flex-column p-fluid"
>
    <h5 class="bold">Logo</h5>
    <h6 class="p-ml-3 input-label bold">{{ 'community.nav.theme.plateforme' | translate }}</h6>
    <app-file-selector
        buttonLabel="{{ 'community.nav.theme.addLogo' | translate }}"
        [information]="['Formats: JPG, PNG, WEBP', 'Taille max.: largeur 200px; hauteur 70 px', 'Poids max : 1 Mo']"
        [control]="form.controls.platformLogo"
    >
    </app-file-selector>

    <h5 class="bold p-mt-3">{{ 'community.nav.theme.colors' | translate }}</h5>
    <div class="p-grid p-mt-2">
        <div class="p-d-inline-flex p-col-12 p-lg-6">
            <app-color-selector class="p-my-auto" [control]="form.controls.primaryColor"></app-color-selector>
            <div class="color-label p-my-auto">{{ 'community.nav.theme.primaryColor' | translate }}</div>
        </div>

        <div class="p-d-inline-flex p-col-12 p-lg-6">
            <app-color-selector class="p-my-auto" [control]="form.controls.secondaryColor"></app-color-selector>
            <div class="color-label p-my-auto">{{ 'community.nav.theme.secondaryColor' | translate }}</div>
        </div>

        <div class="p-d-inline-flex p-col-12 p-lg-6">
            <app-color-selector class="p-my-auto" [control]="form.controls.button1TextColor"></app-color-selector>
            <div class="color-label p-my-auto">{{ 'community.nav.theme.primaryButton' | translate }}</div>
        </div>

        <div class="p-d-inline-flex p-col-12 p-lg-6">
            <app-color-selector
                class="p-my-auto"
                [control]="form.controls.button2TextAndBorderColor"
            ></app-color-selector>
            <div class="color-label p-my-auto">{{ 'community.nav.theme.secondaryButton' | translate }}</div>
        </div>

        <div class="p-d-inline-flex p-col-12 p-lg-6">
            <app-color-selector class="p-my-auto" [control]="form.controls.clickableLinkColor"></app-color-selector>
            <div class="color-label p-my-auto">{{ 'community.nav.theme.linkTextColor' | translate }}</div>
        </div>
    </div>
    <h5 class="bold p-mt-3">{{ 'community.nav.theme.email' | translate }}</h5>
    <h6 class="p-ml-3 input-label p-mt-2 bold">Image e-mail</h6>
    <app-file-selector
        buttonLabel="{{ 'community.nav.theme.addImage' | translate }}"
        [information]="['Formats: JPG, PNG', 'Taille max.: largeur 580px; hauteur 400 px', 'Poids max : 700 Ko']"
        [control]="form.controls.mailBanner"
    >
    </app-file-selector>

    <div class="p-d-inline-flex p-mt-2">
        <label for="mailSenderName" class="p-ml-3 input-label bold p-mr-auto">
            {{ 'community.nav.theme.emailExpeditorName' | translate }}
        </label>
        <div class="p-ml-auto p-mr-3 input-length bold p-my-auto">
            {{ form.controls.mailSenderName.value?.length ?? 0 }}/60
        </div>
    </div>
    <input id="mailSenderName" [formControl]="form.controls.mailSenderName" pInputText (keyup)="onChange()" />

    <div class="p-d-inline-flex p-mt-2">
        <label for="mailSenderEmail" class="p-ml-3 input-label bold p-mr-auto">
            {{ 'community.nav.theme.emailSenderName' | translate }}
        </label>
    </div>
    <div>
        <p-dropdown
            appendTo="body"
            id="mailSenderEmail"
            [options]="communityPotentialMailSender"
            [(ngModel)]="selectedMailSender"
            [ngModelOptions]="{ standalone: true }"
            optionLabel="email"
        >
        </p-dropdown>
    </div>

    <div class="p-d-inline-flex p-mt-2">
        <label for="mailSignature" class="p-ml-3 input-label bold p-mr-auto">
            {{ 'community.nav.theme.signature' | translate }}
        </label>
        <div class="p-ml-auto p-mr-3 input-length bold p-my-auto">
            {{ form.controls.mailSignature.value?.length ?? 0 }}/60
        </div>
    </div>
    <input id="mailSignature" [formControl]="form.controls.mailSignature" pInputText (keyup)="onChange()" />
</form>

<ng-template #loading>
    <wudo-spinner></wudo-spinner>
</ng-template>
