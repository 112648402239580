<div class="p-grid">
    <div class="p-col-12 p-d-flex">
        <h2 class="page-title p-my-auto p-mr-auto p-px-5">{{ 'community.nav.theme.title' | translate }}</h2>
        <button
            pButton
            class="p-button p-my-auto p-ml-auto p-mr-6"
            type="button"
            label="{{ 'components.button.save' | translate }}"
            (click)="this.updateCommunityName(); themeForm.onSubmit()"
        ></button>
    </div>
    <div class="p-grid p-col-12 p-px-5">
        <div class="p-col-12 p-md-6">
            <div class="card custom-card p-mb-2">
                <div [formGroup]="communityNameForm" class="p-px-3 p-p-5 row">
                    <h5 class="p-my-auto p-mx-2 bold">{{ 'community.nav.theme.updateCommunityName' | translate }}</h5>
                    <input
                        pInputText
                        class="p-mx-2"
                        type="text"
                        name="displayName"
                        id="displayName"
                        formControlName="displayName"
                        aria-describedby="displayName"
                        value="{{ this.accountsService.currentCommunity.displayName }}"
                        maxlength="60"
                        style="width: 68%"
                        required
                    />
                </div>
            </div>
            <div *ngIf="previewedTheme; else loading" class="card custom-card flex-order-1 p-mb-2">
                <div class="p-d-inline-flex p-mx-5 p-mt-3">
                    <h5 class="p-my-auto bold">{{ 'community.nav.theme.preview' | translate }}</h5>
                    <div class="p-mx-auto p-d-flex p-flex-column p-flex-lg-row p-my-auto p-my-lg-0">
                        <div
                            class="p-mx-auto p-ml-lg-auto p-mr-lg-4 template-selector"
                            (click)="switchPreview(true)"
                            (keydown)="switchPreview(true)"
                            [class.active]="displayPlatform"
                        >
                            Plateforme
                        </div>
                        <div
                            class="p-mt-2 p-mt-lg-0 p-mx-auto p-ml-lg-4 p-mr-lg-auto template-selector"
                            (click)="switchPreview(false)"
                            (keydown)="switchPreview(false)"
                            [class.active]="!displayPlatform"
                        >
                            {{ 'community.nav.theme.email' | translate }}
                        </div>
                    </div>
                </div>
                <div class="p-m-3 preview-zone">
                    <ng-container *ngIf="displayPlatform; then platformPreview; else mailPreview"></ng-container>
                </div>
            </div>
            <div class="card custom-card">
                <div class="p-d-inline-flex p-mx-5 p-mt-3 p-mb-3">
                    <app-community-links-form
                        [communityExternalLinks]="previewedTheme.externalLinks"
                        (communityExternalLinksChange)="onCommunityExternalLinksChange($event)"
                    ></app-community-links-form>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="card custom-card p-px-3 p-p-5">
                <app-theme-form #themeForm (themeChanged)="onThemeChanged($event)" [communityExternalLinks]="externalLinksForm"></app-theme-form>
            </div>
        </div>
    </div>
</div>

<ng-template #platformPreview>
    <app-platform-theme-preview [theme]="previewedTheme"></app-platform-theme-preview>
</ng-template>

<ng-template #mailPreview>
    <app-mail-theme-preview [theme]="previewedTheme"></app-mail-theme-preview>
</ng-template>

<ng-template #loading>
    <wudo-spinner></wudo-spinner>
</ng-template>
