import { GeoJsonPoint } from '../../azure/map/models';
import { Interest } from '../skills/interest.model';
import { Skill } from '../skills/skill.model';
import { User } from './user.model';

export class UpdateProfileCommand {
    public city: string;
    public country: string;
    public geographicalAreaId: string;
    public interests: Interest[];
    public jobTitle: string;
    public linkedIn: string;
    public description: string;
    public mobilePhone: string;
    public officePhone: string;
    public password: string;
    public skills: Skill[];
    public termsOfUse: boolean;
    public userId: string;

    public location: GeoJsonPoint;

    constructor(user: User) {
        this.userId = user.userId;
        this.jobTitle = user.jobTitle;
        this.city = user.city;
        this.country = user.country;
        this.location = user.location;
        this.officePhone = user.officePhone;
        this.mobilePhone = user.mobilePhone;
        this.geographicalAreaId = user.geographicalAreaId;
        this.password = user.password;
        this.termsOfUse = user.termsOfUse;
        this.skills = user.skills;
        this.interests = user.interests;
        this.linkedIn = user.linkedIn;
        this.description = user.description;
    }
}
