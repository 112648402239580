<p-dialog
    header="Header"
    [(visible)]="isVisible"
    [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [style]="{ width: '50vw', 'max-height': '75vw' }"
>
    <ng-template pTemplate="header">
        <h5 class="modal-title" id="modalConfirm">
            {{ 'components.imageCropModal.title' | translate }}
        </h5>
    </ng-template>

    <div class="modal-body">
        <div>
            <image-cropper-old
                *ngIf="isVisible"
                [format]="'png'"
                [imageChangedEvent]="imageService.imageChangedEvent"
                [imageBase64]="picture"
                [imageURL]="cropperParams.urlImage"
                [maintainAspectRatio]="cropperParams.isRatioFix"
                [containWithinAspectRatio]="imageService.containWithinAspectRatio"
                [aspectRatio]="cropperParams.ratio"
                [cropperMinWidth]="cropperParams.minWidth"
                [cropperMinHeight]="cropperParams.minHeight"
                [onlyScaleDown]="true"
                [roundCropper]="false"
                [canvasRotation]="imageService.canvasRotation"
                [alignImage]="cropperParams.alignImage"
                [style.display]="imageService.showCropper ? null : 'none'"
                [resizeToWidth]="cropperParams.resizeToWidth"
                [resizeToHeight]="cropperParams.resizeToHeight"
                (imageCropped)="imageService.imageCropped($event)"
                (imageLoaded)="imageService.imageLoaded()"
                (loadImageFailed)="imageService.loadImageFailed()"
            ></image-cropper-old>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-12 col-md-8 mr-auto">
            <h4>{{ 'components.imageCropModal.addPicture' | translate }}</h4>
            <div
                class="container-uploader drop-image"
                appDragDrop
                (onFileDropped)="imageService.fileDropedEvent($event)"
            >
                <input #fileInput hidden type="file" accept="image/*" (change)="imageService.fileChangeEvent($event)" />
                <br />

                <div>
                    <b> {{ 'components.imageCropModal.uploadFile' | translate }}</b>
                    <br />
                    {{ 'components.imageCropModal.or' | translate }}
                    <br />
                    <button type="button" class="btn btn-primary" (click)="fileInput.click()">
                        {{ 'components.imageCropModal.selectFile' | translate }}
                    </button>
                    <br /><br />
                </div>
            </div>
            <p>
                <span class="fw-b">{{ 'components.imageCropModal.maxSize' | translate }} </span>
                {{ cropperParams.maxSizeString }} <br />
                <span class="fw-b">{{ 'components.imageCropModal.acceptedFormats' | translate }} </span>
                {{ imageService.getAllAllowedFormat() }}<br />
                <span *ngIf="imageService.recomandedDimension" class="fw-b">{{
                    'components.imageCropModal.recommendedDimensions' | translate
                }}</span>
                {{ imageService.recomandedDimension }}
            </p>
        </div>

        <input
            type="button"
            class="btn btn-danger"
            (click)="hideModal()"
            value="{{ 'components.button.buttonCancel' | translate }}"
        />
        <input
            type="button"
            (click)="onSubmit()"
            class="btn btn-success success"
            value="{{ 'components.button.buttonConfirm' | translate }}"
        />
    </div>
</p-dialog>
