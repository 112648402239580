<div
    class="modal fade bd-example-modal-lg"
    id="resetPasswordModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="resetPasswordModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="resetPasswordModalLabel">Réinitialisation du mot de passe</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="space-light">
                    <p>
                        Souhaitez-vous demander un nouveau mot de passe qui vous sera envoyé à l'adresse :
                        <strong>{{ email }}</strong> ?
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Non merci</button>
                <button type="button" class="btn btn-primary" (click)="resetPassword()" data-dismiss="modal">
                    Oui
                </button>
            </div>
        </div>
    </div>
</div>
