<app-report-modal
    *ngIf="isReportModalVisible"
    [entityId]="project.id"
    [entityOwner]="projectOwnerFullName"
    [entityType]="'project'"
></app-report-modal>

<button
    *ngIf="showGear"
    type="button"
    pButton
    pRipple
    icon="icon-f-settings"
    label=""
    (click)="menu.toggle($event)"
    class="p-button-rounded p-button-text p-button-plain custom-gear"
    pTooltip="{{ 'projects.pages.list.manageProject' | translate }}"
    tooltipPosition="left"
></button>

<p-menu id="menu" #menu [popup]="true" [model]="items" styleClass="custom-menu"></p-menu>
