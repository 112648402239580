import { CommunityFilter } from './community-filter.model';

export class ManageCommunityFilter {
    public page: number;
    public pageSize: number;
    public sortMode: string;
    public sortOrder: number;
    public community: CommunityFilter;
    public userCustomSearch: string;
}
