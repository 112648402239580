<div class="row wizard">
    <div
        class="col wizard__item"
        [class.wizard__item-active]="currentStep === 1"
        [class.wizard__item-past]="currentStep === 2"
        [class.wizard__item-finish]="currentStep === 3"
        [class.wizard__item-pointer]="currentStep > 1"
    >
        <a
            class="wizard__node"
            (click)="currentStep > 1 && stepOneClicked.emit()"
        >
            <div *ngIf="currentStep < 2">1</div>
            <em
                *ngIf="currentStep > 1"
                class="icon-check"
            ></em>
        </a>
    </div>
    <div
        class="col wizard__item"
        [class.wizard__item-active]="currentStep === 2"
        [class.wizard__item-past]="currentStep === 3"
        [class.wizard__item-pointer]="currentStep !== 2"
    >
        <a
            class="wizard__node"
            (click)="currentStep !== 2 && stepTwoClicked.emit()"
        >
            <div *ngIf="currentStep < 3">2</div>
            <em
                *ngIf="currentStep > 2"
                class="icon-check"
            ></em>
        </a>
    </div>
    <div
        class="col wizard__item"
        [class.wizard__item-active]="currentStep === 3"
        [class.wizard__item-pointer]="currentStep === 2"
    >
        <a
            class="wizard__node"
            (click)="currentStep === 2 && stepThreeClicked.emit()"
            >3</a
        >
    </div>
</div>
