import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-organization-avatar',
    templateUrl: './organization-avatar.component.html',
    styleUrls: ['./organization-avatar.component.scss'],
})
export class OrganizationAvatarComponent implements OnChanges {
    @Input() organizationName: string;
    @Input() pictureAccessLink: string;
    @Input() routerLink: string;

    initials: string;

    constructor() {}

    ngOnChanges(): void {
        this.initials = `${(this.organizationName || '').charAt(0)}`.toLocaleUpperCase();
    }

    getColorFromString(input: string, mode: string) {
        if (!input) return;

        // Palette source: https://flatuicolors.com/palette/de
        var colors = [
            '#fc5c65',
            '#eb3b5a',
            '#45aaf2',
            '#2d98da',
            '#fd9644',
            '#fa8231',
            '#4b7bec',
            '#3867d6',
            '#fed330',
            '#f7b731',
            '#26de81',
            '#20bf6b',
            '#2bcbba',
            '#0fb9b1',
            '#778ca3',
        ];

        var hash = 0;

        for (var i = 0; i < input.length; i++) {
            hash = input.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash;
        }

        hash = ((hash % colors.length) + colors.length) % colors.length;

        let colour = colors[hash];
        return '#' + this.getLighterDarkerColour(colour.substring(1, colour.length), mode === 'lighten' ? 20 : -30);
    }

    getLighterDarkerColour(color: string, percent: number) {
        var num = parseInt(color, 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) + amt,
            B = ((num >> 8) & 0x00ff) + amt,
            G = (num & 0x0000ff) + amt;

        return (
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
            (G < 255 ? (G < 1 ? 0 : G) : 255)
        )
            .toString(16)
            .slice(1);
    }
}
