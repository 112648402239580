import { Component, Input } from '@angular/core';
import { Theme } from '../../../models/shared/theme.model';

@Component({
    selector: 'app-mail-theme-preview',
    templateUrl: './mail-theme-preview.component.html',
    styleUrls: ['./mail-theme-preview.component.scss'],
})
export class MailThemePreviewComponent {
    @Input() theme: Theme;
}
