import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { projectStatus } from '../../../constants/project-status';
import { SpinnerHelper } from '../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { BaseProjectCommand } from '../../../models/projects/base-project-command.model';
import { ProjectLight } from '../../../models/projects/project-light.model';
import { ProjectType } from '../../../models/projects/project-type.model';
import { PROJECT_MAX_INTERESTS, PROJECT_MAX_SKILLS } from '../../../models/projects/project.model';
import { Interest } from '../../../models/skills/interest.model';
import { Skill } from '../../../models/skills/skill.model';
import { AccountsService } from '../../../services/identity/accounts.service';
import { ProjectsService } from '../../../services/projects/projects.service';
import { InterestsComponent } from '../../../shared/components/interests/interests.component';
import { SkillsComponent } from '../../../shared/components/skills/skills.component';

@Component({
    selector: 'app-project-form-step-three',
    templateUrl: './project-form-step-three.component.html',
    styleUrls: ['./project-form-step-three.component.scss'],
})
export class ProjectFormStepThreeComponent {
    @ViewChild('skillsComponent', { static: false })
    skillsComponent: SkillsComponent;
    @ViewChild('interestsComponent', { static: false })
    interestsComponent: InterestsComponent;

    @ViewChild('publishRequestModal', { static: false })
    public publishRequestModal: TemplateRef<any>;

    @ViewChild('draftStepOneModal4', { static: false })
    public draftStepOneModal: TemplateRef<any>;

    @Input() projectCommand: BaseProjectCommand;
    @Input() projectTypes: ProjectType[];
    @Input() projectLight: ProjectLight;

    @Output() submitted = new EventEmitter<boolean>();
    @Output() saved = new EventEmitter();
    @Output() published = new EventEmitter();
    @Output() previousStep = new EventEmitter();

    @Input() isPublished: boolean;
    @Input() isEditMode: boolean;
    @Input() isDraft: boolean;
    @Input() isAdmin: boolean;

    PROJECT_MAX_SKILLS = PROJECT_MAX_SKILLS;
    PROJECT_MAX_INTERESTS = PROJECT_MAX_INTERESTS;

    dictionary = environment.dictionary;

    public preselectSkills: boolean;

    constructor(
        private readonly toastrHelper: ToastrHelper,
        private readonly modalService: NgbModal,
        private readonly projectService: ProjectsService,
        private readonly accountService: AccountsService,
        private readonly router: Router,
        private readonly spinnerHelper: SpinnerHelper,
        private translate: TranslateService,
    ) {
        this.preselectSkills = this.accountService.canPreselectBoostFrenchFabSkills();
    }

    onChangedSkills(data: Skill[]) {
        this.projectCommand.searchedSkills = data;
    }

    onChangedInterests(data: Interest[]) {
        this.projectCommand.associatedInterests = data;
    }

    public onPublishRequestClick() {
        if (this.checkFormValidity()) {
            this.modalService.open(this.publishRequestModal, { size: 'lg', scrollable: false, backdrop: 'static' });
        }
    }

    public onSubmit(withPublish: boolean) {
        if (this.checkFormValidity()) {
            this.onClose();
            this.submitted.emit(withPublish);
        }
    }

    public checkFormValidity() {
        if ((this.projectCommand.searchedSkills?.length || 0) < 1 || (this.projectCommand.associatedInterests?.length || 0) < 1) {
            const errorMessage = this.translate.instant('projects.formStep3.toast.selectInterestAndSkill');
            this.toastrHelper.showError(errorMessage);
            return false;
        }

        return true;
    }

    onSave() {
        this.onClose();
        this.saved.emit();
    }

    onPublish() {
        this.published.emit();
    }

    goToPreviousStep() {
        this.previousStep.emit();
    }

    onClose() {
        this.modalService.dismissAll();
    }

    public openConfirmePopupSaveDraft() {
        //if (this.checkFormValidity()) {
        this.modalService.open(this.draftStepOneModal, { size: 'lg', scrollable: false, backdrop: 'static' });
        //}
    }

    deleteProject() {
        this.spinnerHelper.showSpinner();
        this.projectService.disableProject(this.projectLight.id).subscribe(
            () => {
                const msg = this.translate.instant('projects.formStep3.toast.projectDeleted');
                this.toastrHelper.showSuccess(msg);
                const url = this.isAdmin ? '/administration/projects' : '/profile/my-projects';
                this.router.navigate([url]);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }
    isRemovable() {
        return this.projectLight?.status === projectStatus.draft || this.projectLight?.status === projectStatus.unpublished;
    }
}
