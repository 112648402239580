import { Component, Input } from '@angular/core';

@Component({
    selector: 'transparent-action-button',
    template: `<button pButton icon="{{ icon }}" pTooltip="{{ tooltip }}" tooltipPosition="top" class="p-button-transparent"></button>`,
    styles: [
        `
            .p-button-transparent {
                background-color: transparent !important;
                border: 1px solid #fff;
            }
        `,
    ],
})
export class TransparentActionButtonComponent {
    @Input() icon: string;
    @Input() tooltip: string;
}
