import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AddressResult } from '../models';
interface AzureMapsSearchOptions {
    limit?: string;
    typeahead?: string;
    countrySet?: string | string[];
    idxSet?: string;
    entityType?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AddressSearchService {
    private readonly baseUrl = environment.microservices.identity.baseUrl;
    private readonly ADDRESS_URL = `${this.baseUrl}/${environment.microservices.identity.azureMapsApi.baseUrl}/${environment.microservices.identity.azureMapsApi.addresses}`;
    private readonly CITY_SEARCH_URL = `${this.baseUrl}/${environment.microservices.identity.azureMapsApi.baseUrl}/${environment.microservices.identity.azureMapsApi.cities}`;
    private readonly COUNTRY_SEARCH_URL = `${this.baseUrl}/${environment.microservices.identity.azureMapsApi.baseUrl}/${environment.microservices.identity.azureMapsApi.countries}`;

    constructor(private http: HttpClient) {}

    private createSearchParams(query: string, options: AzureMapsSearchOptions = {}, additionalParams: AzureMapsSearchOptions = {}): HttpParams {
        return new HttpParams({
            fromObject: {
                query,
                limit: (options.limit || 5).toString(),
                typeahead: (options.typeahead ?? true).toString(),
                ...additionalParams,
            },
        });
    }

    private handleSearch(url: string, params: HttpParams): Observable<AddressResult[]> {
        if (!params.get('query')) return of([]);
        return this.http.get<AddressResult[]>(url, { params }).pipe(
            catchError((error) => {
                console.error('Search error:', error);
                return of([]);
            }),
        );
    }

    searchAddresses(query: string, options: AzureMapsSearchOptions = {}): Observable<AddressResult[]> {
        return this.handleSearch(this.ADDRESS_URL, this.createSearchParams(query, options, { countrySet: 'FR' }));
    }

    searchCity(query: string, options: AzureMapsSearchOptions = {}): Observable<AddressResult[]> {
        return this.handleSearch(
            this.CITY_SEARCH_URL,
            this.createSearchParams(query, options, {
                countrySet: options.countrySet || 'FR',
            }),
        );
    }

    searchCountries(query: string, options: AzureMapsSearchOptions = {}): Observable<AddressResult[]> {
        return this.handleSearch(this.COUNTRY_SEARCH_URL, this.createSearchParams(query, options));
    }

    formatAddress(result: AddressResult): string {
        const { freeformAddress } = result.address;
        return freeformAddress;
    }
}
