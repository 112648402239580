<a
    class="room-navigation-item classical-room"
    [ngClass]="{ categorized: hasCategory }"
    [routerLink]="['/clubs/', room.id]"
    (click)="onRoomSelected()"
>
    <div class="p-my-auto p-ml-1 pi room-icon">
        <i [ngClass]="room.parentClubId === null ? 'icon-f-open' : room.visibility === CLUB_VISIBILITIES.VISIBLE ? 'icon-f-open' : 'icon-f-mask'"></i>
    </div>
    <div class="p-my-auto p-ml-3 p-mr-auto room-title" [ngClass]="{ 'new-posts-unseen': clubActivityService.roomHasNewPublication(room.id) }">
        {{ room.title }}
    </div>

    <p-badge
        *ngIf="!isSelected && countClubNotifications > 0"
        [value]="countClubNotifications"
        severity="danger"
        class="room-new-activity-badge"
    ></p-badge>
    <app-gear-edit-club
        (onClubDeleted)="roomDeleted($event)"
        (onRoomCreation)="roomCreation($event)"
        *ngIf="(clubRole === clubRoles.Admin || roomRole === clubRoles.Admin || (roomRole === clubRoles.Animator && !isMainRoom)) && isSelected"
        [isClubMode]="false"
        [club]="room"
        class="gear-edit-room"
    ></app-gear-edit-club>
</a>
