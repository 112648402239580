import { Component, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-pwa-update',
    templateUrl: './pwa-update.component.html',
})
export class PwaUpdateComponent {
    public onReload: EventEmitter<any> = new EventEmitter();

    constructor() {}

    reload() {
        this.onReload.emit();
    }
}
