<div class="col-12 mb-2">
    <div class="card box-shadow" (click)="navigateToEvent()" style="cursor: pointer">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-4 col-12">
                    <div class="img-container">
                        <img src="{{ event.pictureAccessLink }}" alt="" />
                    </div>
                </div>

                <div class="col-sm-8 col-12">
                    <div class="row h-100">
                        <div class="col-12">
                            <div class="h5 fw-b d-flex justify-content-between align-items-center">
                                <span class="event-name">{{ event.name }}</span>
                                <app-gear-edit-event [event]="event" (click)="$event.stopPropagation()"></app-gear-edit-event>
                            </div>
                            <div class="fw-b event-category">
                                {{ EventTypeEnumLabelMap[event.type] | wtranslate : 'eventTypes' }}
                            </div>
                            <div class="event-info" [innerHTML]="croppedDescription"></div>
                        </div>
                        <div class="col-12 mt-auto">
                            <div class="row d-flex align-items-center">
                                <div class="col-sm col-12">
                                    <div class="row">
                                        <ng-container *ngIf="eventJoinStatus === EventJoinStatus.Canceled">
                                            <div class="col-auto">
                                                <span class="canceled-label">
                                                    {{ 'events.eventDetails.isCanceled' | translate }}
                                                </span>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="eventJoinStatus === EventJoinStatus.Full">
                                            <div class="col-auto">
                                                <span class="full-label">
                                                    {{ 'events.eventDetails.isFull' | translate }}
                                                </span>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="eventJoinStatus === EventJoinStatus.Done">
                                            <div class="col-auto">
                                                <span class="fw-b event-info">
                                                    {{ 'events.eventDetails.isFinished' | translate }}
                                                </span>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="eventJoinStatus === EventJoinStatus.Ongoing">
                                            <div class="col-auto">
                                                <span class="fw-b event-info">
                                                    {{ 'events.eventDetails.isOngoing' | translate }}
                                                </span>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="eventJoinStatus === EventJoinStatus.CanJoin">
                                            <div class="col-auto">
                                                <div class="fw-b event-info">
                                                    {{ 'events.eventDetails.startDate' | translate }}
                                                </div>
                                                <div class="event-info">
                                                    {{ event.startDate | dateFormatted : 'short' }}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="fw-b event-info">
                                                    {{ 'events.eventDetails.endDate' | translate }}
                                                </div>
                                                <div class="event-info">
                                                    {{ event.endDate | dateFormatted : 'short' }}
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-auto ml-auto">
                                    <ng-container *ngIf="isUserParticipatingToEvent(); else participateButton">
                                        <a [routerLink]="['/events', event.id]" class="btn btn-secondary ml-1">
                                            <em class="icon icon-preview d-sm-inline d-md-none"></em>
                                            <span class="d-none d-sm-inline">{{ 'components.button.details' | translate }}</span>
                                        </a>
                                    </ng-container>

                                    <ng-template #participateButton>
                                        <a [routerLink]="['/events', event.id]" class="btn btn-secondary ml-1">
                                            <em class="icon icon-preview d-sm-inline d-md-none"></em>
                                            <span class="d-none d-sm-inline">{{ 'components.button.participate' | translate }}</span>
                                        </a>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
