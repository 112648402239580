<app-report-modal *ngIf="isReportModalVisible" [entityId]="event.id" [entityOwner]="eventOwnerFullName" [entityType]="'event'"></app-report-modal>

<button
    *ngIf="displayModal"
    type="button"
    pButton
    pRipple
    icon="icon-f-settings"
    label=""
    (click)="menu.toggle($event)"
    class="p-button-rounded p-button-text p-button-plain custom-gear"
    pTooltip="{{ 'events.pages.list.manageEvent' | translate }}"
    tooltipPosition="left"
></button>

<p-menu id="menu" #menu [popup]="true" [model]="items" styleClass="custom-menu"></p-menu>
