import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import * as $ from 'jquery';

@Directive()
export abstract class BaseFiltersComponent<TFiltersModel> {

    @Input()
    public areFiltersOpened: boolean;

    @Output()
    public closed = new EventEmitter();

    @Output()
    public ready = new EventEmitter();

    @Output()
    public selectionChanged = new EventEmitter();

    public closeFilters(): void {
        this.areFiltersOpened = false;
        this.closed.emit();
    }

    public abstract setSelectedFilters(filters: TFiltersModel): void;
    public abstract extractSelectedFilters(): TFiltersModel;

    @HostListener('window:scroll')
    scrollHandler() {
        this.manageFiltersScrolling();
    }

    private manageFiltersScrolling() {
        const topbarHeight = $('.top-bar').outerHeight();
        const visibleAreaHeight = $('body').outerHeight() - topbarHeight - $('.footer').outerHeight();

        const filters = $('.main-card-filters');
        const filtersHeight = filters.outerHeight();
        const filtersWidth = filters.outerWidth();

        const content = $('#content-list');
        const contentHeight = content.outerHeight();

        /*
         * Filters positioning:
         * - Sticky top when filters component height exceed visible area height ;
         * - Fixed bottom when scroll reach filters component block's end.
         *
         * Additionally manage the content height (results list) to not fix filters when content is smaller than filters.
         */
        if (filtersHeight > visibleAreaHeight) {
            filters.removeClass('top');

            if (
                $(window).scrollTop() + visibleAreaHeight >= filtersHeight + topbarHeight &&
                contentHeight > filtersHeight
            ) {
                filters.addClass('bottom');
                filters.width(filtersWidth);
            } else {
                filters.removeClass('bottom');
                filters.removeAttr('style');
            }
        } else {
            filters.removeClass('bottom');
            filters.addClass('top');
        }

        /*
         * Content positioning:
         * - Sticky top when content is smaller than visible area (to maximize visibility of list).
         */
        if (contentHeight > visibleAreaHeight) {
            content.removeClass('top');
        } else {
            content.addClass('top');
        }
    }
}
