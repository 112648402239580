<div class="row align-items-end" [formGroup]="form">
    <div class="col-12">
        <label class="fw-b primary-1">{{ label }} *</label>
        <div class="row">
            <div class="col-12 col-md-6 col-xl visibilite-button">
                <input type="checkbox" formControlName="organizationVisibility" id="visibilite-organisation" />
                <label class="btn btn-secondary btn-visibility" for="visibilite-organisation">
                    {{ 'shared.' + scopeSelection + '.myOrganization' | translate }}
                </label>
                <div class="target">
                    <small>{{ 'shared.' + scopeSelection + '.targetRange.restricted' | translate }}</small>
                    <div class="ciblage ciblage-restreint">
                        <span class="lvl1"></span>
                        <span class="lvl2"></span>
                        <span class="lvl3"></span>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-xl visibilite-select">
                <input type="checkbox" formControlName="myCommunitiesVisibility" />
                <label class="btn btn-secondary btn-visibility">
                    <div class="dropdown">
                        <button class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span>{{ 'shared.' + scopeSelection + '.myCommunities' | translate }} </span>
                            <span class="count">({{ form.getUserSelectedCommunitiesCount() }})</span>
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item">
                                <label>
                                    <input
                                        type="checkbox"
                                        (change)="onAllMyCommunitiesChange($event.target.checked)"
                                        [checked]="form.areAllUserCommunitiesSelected()"
                                    />
                                    {{ 'shared.' + scopeSelection + '.allMyCommunities' | translate }}
                                </label>
                            </a>
                            <a class="dropdown-item" *ngFor="let community of userCommunities">
                                <label>
                                    <input
                                        type="checkbox"
                                        (change)="form.selectCommunity(community)"
                                        [checked]="form.isCommunitySelected(community)"
                                    />
                                    {{ community?.displayName }}
                                </label>
                            </a>
                        </div>
                    </div>
                </label>
                <div class="target">
                    <small>{{ 'shared.' + scopeSelection + '.targetRange.medium' | translate }}</small>
                    <div class="ciblage ciblage-restreint ciblage-moyen">
                        <span class="lvl1"></span>
                        <span class="lvl2"></span>
                        <span class="lvl3"></span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl visibilite-button" [ngClass]="{ 'visibilite-button-disabled': hideAllCommunitiesVisibility }">
                <input
                    [attr.disabled]="hideAllCommunitiesVisibility ? true : null"
                    type="checkbox"
                    formControlName="allCommunitiesVisibility"
                    id="visibilite-toutes-communautes"
                />
                <label
                    [ngClass]="{
                        'btn-disabled visibilite-disable': hideAllCommunitiesVisibility,
                        'btn-secondary btn-visibility': showAllCommunitiesVisibility
                    }"
                    class="btn"
                    for="visibilite-toutes-communautes"
                >
                    <span
                        tooltipPosition="bottom"
                        pTooltip="{{ 'shared.' + scopeSelection + '.visibilityRange.disabledAllCommunitiesVisibility' | translate }}"
                        [tooltipDisabled]="showAllCommunitiesVisibility && canCreateAllCommunitiesProject"
                        tabindex="0"
                        tooltipEvent="focus"
                        style="outline: 0"
                    >
                        <ng-container>
                            {{ 'shared.' + scopeSelection + '.visible' | translate }}
                            <span>{{ 'shared.' + scopeSelection + '.wudoPlatform' | translate }} </span>
                            <span class="count"
                                >({{ allCommunities.length }}
                                {{
                                    (allCommunities.length > 1
                                        ? 'shared.' + scopeSelection + '.communities'
                                        : 'shared.' + scopeSelection + '.community'
                                    ) | translate
                                }})</span
                            >
                        </ng-container>
                        <em *ngIf="hideAllCommunitiesVisibility" class="icon-information float-right" style="font-size: 1.2em"></em>
                    </span>
                </label>
                <div class="target">
                    <small>{{ 'shared.' + scopeSelection + '.targetRange.large' | translate }}</small>
                    <div class="ciblage ciblage-restreint ciblage-moyen ciblage-large">
                        <span class="lvl1"></span>
                        <span class="lvl2"></span>
                        <span class="lvl3"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mt-1">
        <div *ngIf="form.invalid && formSubmitAttempt && form.errors?.required" class="alert alert-danger">
            <div *ngIf="form.errors.required">
                {{ 'shared.' + scopeSelection + '.required' | translate }}
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="alert alert-dark mb-0" role="alert">
            <div class="fw-b">
                {{ 'shared.' + scopeSelection + '.scope' | translate }}
                <em
                    class="d-none d-sm-inline-block icon-info"
                    data-toggle="tooltip"
                    [title]="'shared.' + scopeSelection + '.visibilityRange.completeTitle' | translate"
                    data-placement="top"
                    data-html="true"
                ></em>
                <em class="d-inline-block d-sm-none icon-info" data-toggle="modal" data-target="#infoModal"></em>
            </div>
            {{ getCurrentVisibilityRange() }}
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade bd-example-modal-lg" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="infoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                {{ 'shared.' + scopeSelection + '.visibilityRange.title' | translate }}
                <br />
                {{ 'shared.' + scopeSelection + '.visibilityRange.internal' | translate }}
                <br />
                {{ 'shared.' + scopeSelection + '.visibilityRange.external' | translate }}
                <br />
                {{ 'shared.' + scopeSelection + '.visibilityRange.public' | translate }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-primary" data-dismiss="modal">
                    {{ 'components.button.buttonClose' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
