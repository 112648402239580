<div class="container">
    <div class="card-body">
        <div class="row d-flex justify-content-around">
            <img
                src="./assets/images/no-signal.svg"
                class="card-img-center no-signal"
                alt="Aucune connexion disponible"
            />
        </div>
    </div>
</div>
