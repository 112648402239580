import { EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-color-selector',
    templateUrl: './color-selector.component.html',
    styleUrls: ['./color-selector.component.scss'],
})
export class ColorSelectorComponent {
    @Input()
    initialColor = '#fff';

    @Input()
    label = 'color';

    @Input()
    control: FormControl = new FormControl();

    @Output()
    selectedColor: EventEmitter<string> = new EventEmitter();

    onFileDrop($event: any): void {
        const event = {
            isTrusted: true,
            target: { accept: '*/*', files: $event },
        };

        this.control.setValue(event.target?.files[0]);
    }
}
