import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-event-form-stepper',
    templateUrl: './event-form-stepper.component.html'
})
export class EventFormStepperComponent {

    @Input()
    public currentStep: number;

    @Output()
    public stepOneClicked: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public stepTwoClicked: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public stepThreeClicked: EventEmitter<void> = new EventEmitter<void>();
}
