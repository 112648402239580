<div *ngIf="!isOnMobile">
    <a href="{{ link }}" target="_blank">
        <div class="link-preview p-grid p-mx-4">
            <div class="p-col-4 p-p-0 p-my-auto">
                <img class="link-preview-picture p-mx-auto" src="{{ alternativePicture }}" alt="document preview" />
            </div>
            <div class="p-col-8 link-preview-content">
                <p class="link-preview-title p-mb-2 p-mx-1">
                    {{ documentName }}
                </p>
                <!-- <p class="link-preview-description p-mb-2 p-mx-1">
                    {{ metadatas.description | truncateText : 150 }}
                </p> -->
                <!-- <p class="link-preview-url p-mb-auto">
                    {{ link }}
                </p> -->
            </div>
        </div>
    </a>
</div>
<div *ngIf="isOnMobile">
    <a href="{{ link }}" target="_blank">
        <div class="link-preview p-mx-2">
            <div class="">
                <img
                    class="link-preview-picture link-preview-picture-mobile p-mx-auto"
                    src="{{ alternativePicture }}"
                    alt="document preview"
                />
            </div>
            <div class="link-preview-content-mobile p-px-2">
                <p class="link-preview-title">
                    {{ documentName }}
                </p>
                <!-- <p class="link-preview-description">
                    {{ metadatas.description | truncateText : 150 }}
                </p> -->
                <!-- <p class="link-preview-url p-mb-0">{{ link }}</p> -->
            </div>
        </div>
    </a>
</div>
