import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class RouterHelper {
    public getAllRouteParams(route: ActivatedRoute): any {
        let params = { ...route.snapshot.params };
        let currentRoute = route;

        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
            Object.assign(params, currentRoute.snapshot?.params);
        }

        return params;
    }

    public reloadCurrentPage(router: Router) {
        let currentUrl = router.url;
        router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            router.navigate([currentUrl]);
        });
    }
}
