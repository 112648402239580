export class FiltersItem {
    key: string;
    value: string;
    checked: boolean;
    visible: boolean;
    parent: FiltersItem;
    // TODO : Renommer en children.
    groups: FiltersItem[];

    constructor(key: string, value: string, groups: FiltersItem[] = null) {
        this.key = key;
        this.value = value;
        this.visible = true;

        if (groups) {
            groups.forEach((group) => (group.parent = this));
            this.groups = groups;
        }
    }

    check() {
        this.checked = true;

        if (this.groups) {
            this.groups.forEach((group) => group.check());
        }

        if (this.parent && !this.parent.checked) {
            this.parent.checked = true;
        }
    }

    uncheck() {
        this.checked = false;

        if (this.groups) {
            this.groups.forEach((group) => group.uncheck());
        }

        if (this.parent && this.parent.groups.every((group) => !group.checked)) {
            this.parent.checked = false;
        }
    }
}
