import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root',
})
export class SpinnerHelper {
    constructor(private readonly spinner: NgxSpinnerService) {}

    showSpinner() {
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide();
        }, 10000);
    }

    hideSpinner() {
        this.spinner.hide();
    }
}
