import { AccountsService } from './../../../services/identity/accounts.service';
import { getUserStatus, UserStatusTypes } from './../../../models/identity/user-status-types.model';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-status-badge',
    templateUrl: './user-status-badge.component.html',
    styleUrls: ['./user-status-badge.component.scss'],
})
export class UserStatusBadgeComponent implements OnInit {
    @Input() currentUserStatusType: UserStatusTypes;
    userStatusTypes = UserStatusTypes;
    @Input() displayLabel = false;

    shouldDisplayUsersStatus: boolean;
    label: string;

    constructor(private accountService: AccountsService, private translate: TranslateService) {}

    ngOnInit(): void {
        this.shouldDisplayUsersStatus = this.accountService.displayUsersStatusAndInactiveUsers;
        if (this.displayLabel) this.label = getUserStatus(this.currentUserStatusType).libelle;
    }

    get tooltip(): string {
        if (!this.shouldDisplayUsersStatus) return null;

        return this.currentUserStatusType == UserStatusTypes.Active
            ? this.translate.instant('administration.global.pages.users.userBadge.userActivated')
            : this.currentUserStatusType == UserStatusTypes.Inactive
            ? this.translate.instant('administration.global.pages.users.userBadge.userInactivated')
            : this.translate.instant('administration.global.pages.users.userBadge.userDeactivated');
    }
}
