import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isoDuration',
})
export class IsoDurationPipe implements PipeTransform {
    transform(duration: string): string {
        const match = duration.match(/PT(?:(\d+)H)?(?:(\d+)M)?(\d+)S/);
        if (!match) {
            throw new Error('Invalid ISO 8601 duration format');
        }

        const hours = match[1] ? parseInt(match[1], 10) : 0;
        const minutes = match[2] ? parseInt(match[2], 10) : 0;
        const seconds = parseInt(match[3], 10);

        const formattedHours = hours > 0 ? `${hours}:` : '';
        const formattedMinutes = minutes < 10 && hours > 0 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
    }
}
