<h3 class="p-text-center import-instruction">
    Faites correspondre les colonnes de votre fichier avec les propriétés de contacts
</h3>
<p-table
    class="import-table"
    styleClass="p-mt-5 p-treetable-sm p-datatable-gridlines"
    [resizableColumns]="true"
    [value]="columnMatchingData?.csvColumnInformation"
    [scrollable]="true"
    scrollDirection="both"
    scrollHeight="50vh"
    #base
>
    <ng-template pTemplate="colgroup">
        <colgroup>
            <col style="width: 11ch" />
            <col style="width: 27ch" />
            <col style="width: 24ch" />
            <col style="width: 300px" />
        </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th pResizableColumn>Validation</th>
            <th pResizableColumn>Titre de colonne du fichier</th>
            <th pResizableColumn>Aperçu des informations</th>
            <th pResizableColumn>Propriété Wudo</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td class="centered">
                <div class="p-d-flex">
                    <div
                        [ngClass]="{
                            'validation-succeeded': item.valid,
                            'validation-failed': !item.valid && !item.skipped,
                            'validation-warning': item.skipped
                        }"
                    >
                        <i
                            class="pi"
                            [ngClass]="{
                                'pi-check': item.valid,
                                'pi-times': !item.valid && !item.skipped,
                                'pi-exclamation-circle': item.skipped
                            }"
                        ></i>
                    </div>
                </div>
            </td>
            <td>{{ item?.title }}</td>
            <td>
                <ng-container *ngFor="let item of item.sample"> {{ item }} <br /> </ng-container>
            </td>
            <td class="no-overflow-hidden-cell">
                <ng-container
                    [ngTemplateOutlet]="item.skipped ? skippedColumn : columnMatching"
                    [ngTemplateOutletContext]="{ item: item }"
                ></ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>

<ng-template #columnMatching let-item="item">
    <div class="p-d-flex">
        <p-dropdown
            [options]="item.availableWudoProperties"
            [(ngModel)]="item.selectedWudoProperty"
            placeholder="Sélectionner une propriété"
            [filter]="true"
            (onChange)="handleMatchingDropdownChange()"
            class="matching-dropdown"
            [autofocus]="true"
            [baseZIndex]="20000"
            [appendTo]="base"
        >
            <ng-template pTemplate="footer">
                <div class="dropdown-footer">
                    <div (click)="skipColumn(item.title)" class="skip-import">Ne pas importer la colonne</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
</ng-template>

<ng-template #skippedColumn let-item="item">
    <div>La colonne ne sera pas importée</div>
    <div class="undo-skip-import" (click)="undoSkipColumn(item.title)">
        {{ 'components.button.modify' | translate }}
    </div>
</ng-template>
