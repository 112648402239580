<div>
    <span class="fw-b primary-1">{{ filtersHeader }}</span>
    <p-badge *ngIf="selectedItems.length > 0" [value]="selectedItems.length" styleClass="p-ml-2 badge"></p-badge>
</div>

<div *ngIf="dataIsLoaded | async" class="p-fluid p-p-2">
    <div class="p-field">
        <p-multiSelect
            title="{{ filterTitle }}"
            [options]="items"
            [(ngModel)]="selectedItems"
            optionLabel="name"
            placeholder="{{ 'filters.search' | translate }}"
            [filter]="true"
            filterBy="name"
            (onChange)="onSelectionChanged()"
            resetFilterOnHide="true"
            [displaySelectedLabel]="false"
            appendTo="body"
            styleClass="multiSelect"
        >
            <ng-template let-item pTemplate="item">
                <div class="item p-d-flex p-flex-column">
                    <ng-container [ngSwitch]="filtersType">
                        <span *ngSwitchCase="'communities'">{{ item.displayName }}</span>
                        <span *ngSwitchCase="'interests'">{{ item.domainName }} {{ item.name }}</span>
                        <span *ngSwitchCase="'localisations'">{{ item.name }} ({{ item.id }})</span>
                    </ng-container>
                </div>
            </ng-template>
        </p-multiSelect>
    </div>

    <div class="p-field">
        <p-chip styleClass="chip p-m-1 p-d-flex" *ngFor="let item of selectedItems">
            <div class="selectedItems">
                <ng-container [ngSwitch]="filtersType">
                    <span *ngSwitchCase="'communities'">{{ item.displayName }}</span>
                    <span *ngSwitchDefault>{{ item.name }}</span>
                </ng-container>
            </div>
            <div class="p-ml-auto">
                <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-text removeButton"
                    (click)="unselectItem(item)"
                ></button>
            </div>
        </p-chip>
    </div>
</div>
