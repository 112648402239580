<p-dialog
    header="Header"
    [(visible)]="isModalVisible"
    [modal]="true"
    [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
>
    <ng-template pTemplate="header">
        <h5 class="font-bold">{{ 'clubs.navigation.additionalInformation' | translate }}</h5>
    </ng-template>

    <div class="container">
        <div class="row">
            <div class="col">
                <div class="p-d-inline-flex">
                    <div class="card">
                        <p-avatar [image]="club.profilePictureUrl" styleClass="mr-1" size="large" shape="circle"></p-avatar>
                    </div>
                    <div class="col">
                        <div class="col-auto">
                            <span class="club-name-modal">{{ club.title }}</span>
                        </div>
                        <div class="row p-ml-1">
                            <span class="col-auto club-several-infos">{{ getClubType(club) }}</span>
                        </div>
                    </div>
                </div>

                <div class="p-ml-2 p-mt-6">
                    <div class="row">
                        <em class="icon-calendar p-mr-1 p-my-auto"></em>
                        <div class="font-weight-bold">{{ 'clubs.page.tabs.about.creationDate' | translate }}</div>
                    </div>
                    <span class="row"> {{ club.creationDate | dateFormatted: 'longDate' }}</span>
                </div>

                <div class="p-ml-2 p-mt-6 p-mb-2">
                    <div class="row">
                        <em class="icon-lock p-mr-1 p-my-auto"></em>
                        <div class="font-weight-bold">Club privé</div>
                    </div>
                    <span class="row p-mr-2">{{ 'clubs.page.tabs.about.clubPrivateDescription' | translate }}</span>
                </div>
            </div>
            <div class="col">
                <div class="row font-weight-bold">{{ 'clubs.page.tabs.about.description' | translate }}</div>
                <div class="row" [innerHTML]="club?.description || '-'"></div>
            </div>
        </div>
    </div>

    <p-footer>
        <button type="button" class="btn btn-secondary" (click)="toggleModal()">
            {{ 'components.button.buttonClose' | translate }}
        </button>
    </p-footer>
</p-dialog>
