import * as Papa from 'papaparse';

export class CsvHelper {
    public static async parse(csvFile: File, encoding: string = null): Promise<Papa.ParseResult<unknown>> {
        return new Promise((resolve, reject) => {
            Papa.parse(csvFile, {
                header: true,
                dynamicTyping: true,
                encoding: encoding ?? 'utf-8',
                complete: (results) => {
                    resolve(results);
                },
                error: reject,
            });
        });
    }
}
