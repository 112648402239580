export class UserPermissions {
    public globalRigths: string[];
    public communitiesRights: { [communityId: string]: string[] };
    public organizationsRights: { [organizationId: string]: string[] };
    public admin = false;
    public canViewDocuments = false;
    public canViewContactsDirectory = false;
    public canPublishProject = false;
    public canEditOrganization = false;
    public canCreateLabServices = false;
    public canAccessCommunityManagementComponents = false;
    public canViewCetimClub = false;
}
