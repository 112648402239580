import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dateFormatted',
    pure: true,
})
export class DateFormattedPipe implements PipeTransform {
    constructor(private readonly datePipe: DatePipe, private readonly translate: TranslateService) {}

    transform(value: string, format: string): string {
        const locale = this.translate.currentLang.indexOf('fr') !== -1 ? 'fr-FR' : 'en-US';

        return this.datePipe.transform(value, format, '', locale);
    }
}
