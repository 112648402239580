<!-- NAV ADMIN -->
<nav class="navbar navbar-expand-lg navbar-light subnav justify-content-center">
    <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/administration/users']">Utilisateurs</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/administration/organizations']">{{ 'administration.global.nav.organizations' | translate }}</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/administration/projects']">{{ 'administration.global.nav.projects' | translate }}</a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="importUsersAvailable">
            <a class="nav-link" [routerLink]="['/administration/imports']">Imports en masse</a>
        </li>
    </ul>
</nav>
<!-- /nav admin -->
