import { SelectListItem } from "../shared/select-list-item.model";

export enum EventTypeEnum {
    Workshop = 1,
    Conference = 2,
    SocialEvent = 3,
    Meal = 4,
    Meeting = 5,
    Exhibition = 6,
    Seminar = 7,
    Visit = 8,
    Webinar = 9,
    Training = 10,
    Other = 11
}

export const EventTypeEnumLabelMap: Record<EventTypeEnum, string> = {
    [EventTypeEnum.Workshop]: "Atelier de travail",
    [EventTypeEnum.Conference]: "Conférence",
    [EventTypeEnum.SocialEvent]: "Événement social",
    [EventTypeEnum.Training]: "Formation",
    [EventTypeEnum.Meal]: "Repas",
    [EventTypeEnum.Meeting]: "Réunion",
    [EventTypeEnum.Exhibition]: "Salon",
    [EventTypeEnum.Seminar]: "Séminaire",
    [EventTypeEnum.Visit]: "Visite",
    [EventTypeEnum.Webinar]: "Webinaire",
    [EventTypeEnum.Other]: "Autre",
};

export function getEventTypeEnumSelectList(): SelectListItem<EventTypeEnum>[] {

    const orderedValues: EventTypeEnum[] = [
        EventTypeEnum.Workshop,
        EventTypeEnum.Conference,
        EventTypeEnum.SocialEvent,
        EventTypeEnum.Training,
        EventTypeEnum.Meal,
        EventTypeEnum.Meeting,
        EventTypeEnum.Exhibition,
        EventTypeEnum.Seminar,
        EventTypeEnum.Visit,
        EventTypeEnum.Webinar,
        EventTypeEnum.Other,
    ];

    const result: SelectListItem<EventTypeEnum>[] = [];

    for (const value of orderedValues) {
        result.push(new SelectListItem<EventTypeEnum>(value, EventTypeEnumLabelMap[value]));
    }

    return result;
}
