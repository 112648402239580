export const regexPatterns = {
    phoneNumber: /^(?:\+?\d{1,4}[\s.-]?)?(?:\(?\d{1,4}\)?[\s.-]?)?[\d\s.-]{4,14}$/,
    LinkedInProfileUrl: '^(https?://)?([a-z]{2,3}\\.)?linkedin\\.com/in/[\\w-~%]+/?$',
    LinkedInCompanyUrl: '^(https?://)?([a-z]{2,3}\\.)?linkedin\\.com/company/[\\w-~%]+/?$',
    pictureAuthorizeExtensions: /\.(jpe?g|png|gif)$/i,
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    fullUrl: /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.%]+$/i,
    guid: /^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/,
    youtube: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/,
};
