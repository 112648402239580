import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BusinessArea } from '../../models/identity/business-area.model';
import { CacheService } from '../shared/cache.service';

@Injectable({ providedIn: 'root' })
export class BusinessAreaService {
    private businessAreasApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.businessAreasApi.baseUrl}`;

    // Key of the business areas in the cache
    private businessAreasCacheKey = 'currentBusinessAreas';

    constructor(private http: HttpClient, private readonly cacheService: CacheService) {}

    getBusinessAreas(): Observable<BusinessArea[]> {
        return this.cacheService.get(
            this.businessAreasCacheKey,
            this.http.get<BusinessArea[]>(this.businessAreasApiUrl),
        );
    }
}
