import { Directive, OnInit } from '@angular/core';
import { FiltersItem } from '../../../models/shared/filters-item.model';
import { BaseFiltersItem } from '../base-filters-item/base-filters-item.directive';
import { WudoTranslatePipe } from '../../../pipes/wudo-translate.pipe';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class BaseFiltersCheckbox extends BaseFiltersItem implements OnInit {
    public filterItem: FiltersItem;

    constructor(protected readonly translate: TranslateService, protected readonly wtranslate: WudoTranslatePipe) {
        super();
    }

    public ngOnInit(): void {
        this.filterItem = this.createFilterItem();
        this.ready.emit();
    }

    public override resetFilters(): void {
        this.filterItem.checked = false;
    }

    protected abstract createFilterItem(): FiltersItem;
}
