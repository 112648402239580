import { Injectable } from '@angular/core';
import { Project } from '../../models/projects/project.model';

@Injectable({
    providedIn: 'root',
})
export class ProjectsBusinessService {
    public isUserParticipatingToProject(project: Project, userId: string): boolean {
        if (!userId || !project) {
            return false;
        }

        var isAttendee = project.attendees.some((attendee) => attendee.id === userId);

        if (isAttendee) {
            return isAttendee;
        }

        return project.pendingAttendees.some((attendee) => attendee.id === userId);
    }
}
