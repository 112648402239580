<div class="col-12">
    <div class="card box-shadow">
        <div class="card-body">
            <div class="row">
                <div class="col-md col-12">
                    <div class="fw-b primary-1">{{ 'projects.formAbstract.projectName' | translate }}</div>
                    <div>{{ projectCommand?.name }}</div>
                </div>

                <div class="col-md col-12">
                    <div class="fw-b primary-1">{{ 'projects.formAbstract.projectType' | translate }}</div>
                    <div>{{ getProjectTypeName(projectCommand?.projectTypeId) }}</div>
                </div>

                <div class="col-md col-12">
                    <div class="fw-b primary-1">{{ 'projects.formStep1.visibility' | translate }}</div>
                    <div>{{ getProjectVisibility() }}</div>
                </div>

                <!-- TODO 'Unable to convert "14/06/2020" into a date' for pipe 'DatePipe' => from Back JsonConverter(typeof(DateTimeConverter) -->
                <div class="col-md col-12">
                    <div class="fw-b primary-1">{{ 'projects.formAbstract.expectedStartDate' | translate }}</div>
                    <div>{{ projectCommand?.startDate | date: 'shortDate' }}</div>
                </div>

                <div class="col-md col-12">
                    <div class="fw-b primary-1">{{ 'projects.formAbstract.expectedEndDate' | translate }}</div>
                    <div>{{ projectCommand?.endDate | date: 'shortDate' }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
