import moment from 'moment';

export class DateHelper {
    /**
     * Calculate elpasedDay
     * @param date1 : first date ,
     * @param date2 : now by default.
     */
    static elaspsedDay(date1: Date, date2: Date = new Date()): number {
        let dMin;
        let dMax;
        if (date1 > date2) {
            dMin = moment(date2);
            dMax = moment(date1);
        } else {
            dMin = moment(date1);
            dMax = moment(date2);
        }
        return dMax.diff(dMin, 'days');
    }
}
