<span *ngIf="picture; else noPhotoBlock" [ngClass]="{ 'img-pointer': routerLink, gradient: disabled }">
    <img [src]="picture" alt="fullName" id="profilePic" class="avatar" [routerLink]="routerLink" />
</span>
<ng-template #noPhotoBlock>
    <span
        class="avatar  no-photo"
        [attr.title]="fullName"
        [style.color]="getColorFromString(fullName, 'darken')"
        [style.background-color]="getColorFromString(fullName, 'lighten')"
        [ngClass]="{ 'img-pointer': routerLink, gradient: disabled }"
        >{{ this.initials }}</span
    >
</ng-template>
