import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { AccountsService } from '../services/identity/accounts.service';

@Injectable({ providedIn: 'root' })
export class AccessToModuleClubsGuard implements CanActivate {
    constructor(private readonly router: Router, private readonly accountService: AccountsService) {}

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
        if (this.areClubsAvailable()) {
            return true;
        }

        this.router.navigate(['/']);
        return false;
    }

    canShowLink() {
        return this.areClubsAvailable();
    }

    private areClubsAvailable() {
        let currentUserIsInAuthorizedCommunity = this.accountService.currentUser.communities.some(
            (usercommunity) => !environment.features.clubDisabledCommunityIds.includes(usercommunity.id),
        );

        return environment.features.clubs && currentUserIsInAuthorizedCommunity;
    }

    showClubNavigation(): boolean {
        switch (this.router.url) {
            case '/events/create':
                return false;
            default:
                return true;
        }
    }
}
