import { RouterModule, Routes } from '@angular/router';
import { AccessToModuleClubsGuard } from './guards/access-to-module-clubs.guard';
import { AuthGuard } from './guards/auth.guard';
import { ActivateComponent } from './login/activate.component';
import { Oauth2DepartureComponent } from './login/oauth2/oauth2-departure/oauth2-departure.component';
import { Oauth2RedirectComponent } from './login/oauth2/oauth2-redirect/oauth2-redirect.component';

import { NgModule } from '@angular/core';
import { AppDmsRedirectComponent } from './dms/Redirections/app-dms-redirect/app-dms-redirect.component';
import { RessourceShareRedirection } from './guards/ressource-share-redirection.guard';
import { LoginComponent } from './login/login.component';
import { SamlRedirectComponent } from './login/saml/saml-redirect.component';
import { VerifyComponent } from './login/verify.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'welcome',
        data: {
            title: 'navMenu.welcome',
        },
        loadChildren: () => import('./welcome/welcome.module').then((m) => m.WelcomeModule),
    },
    {
        path: 'community',
        loadChildren: () => import('./community/community.module').then((m) => m.CommunityModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./data-dashboard/data-dashboard.module').then((m) => m.DataDashboardModule),
    },
    {
        path: 'directory',
        data: {
            title: 'navMenu.directoryTitle',
        },
        loadChildren: () => import('./directory/directory.module').then((m) => m.DirectoryModule),
    },
    {
        path: 'dms',
        loadChildren: () => import('./dms/dms.module').then((m) => m.DmsModule),
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    },
    {
        path: 'projects',
        data: {
            title: 'navMenu.projectsTitle',
            showBackButton: true,
        },
        loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
    },
    {
        path: 'organizations',
        loadChildren: () => import('./organizations/organizations.module').then((m) => m.OrganizationsModule),
    },
    {
        path: 'administration',
        loadChildren: () => import('./administration-global/administration-global.module').then((m) => m.AdministrationGlobalModule),
    },
    {
        path: 'administration-organizations',
        loadChildren: () =>
            import('./administration-organizations/administration-organizations.module').then((m) => m.AdministrationOrganizationsModule),
    },
    {
        path: 'clubs',
        data: {
            title: 'navMenu.clubsTitle',
            showBackButton: true,
        },
        canActivate: [AuthGuard, AccessToModuleClubsGuard],
        loadChildren: () => import('./clubs/clubs.module').then((m) => m.ClubsModule),
    },
    {
        path: 'events',
        data: {
            title: 'navMenu.eventsTitle',
            showBackButton: true,
        },
        loadChildren: () => import('./events/events.module').then((m) => m.EventsModule),
    },
    { path: 'login', component: LoginComponent },
    { path: 'verify', component: VerifyComponent },
    { path: 'activate/:token', component: ActivateComponent },
    { path: 'oauth2/redirect', component: Oauth2RedirectComponent },
    { path: 'oauth2/departure', component: Oauth2DepartureComponent },
    { path: 'saml', component: SamlRedirectComponent },
    {
        path: 'ressource/share/:clubId/:ressourceId',
        canActivate: [RessourceShareRedirection],
        component: AppDmsRedirectComponent,
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
