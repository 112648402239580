import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-basic-dropdown',
    templateUrl: './basic-dropdown.component.html',
    styleUrls: ['./basic-dropdown.component.scss'],
})
export class BasicDropdownComponent {
    @Input() public options: [];
    @Input() public selectedValue: any;
    @Input() placeholder: string;
    @Output() selectedValueChanged = new EventEmitter<any>();

    constructor() {}

    onChange() {
        this.selectedValueChanged.emit(this.selectedValue);
    }
}
