import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IStepOption, TourService } from 'ngx-ui-tour-ng-bootstrap';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class TutorialMobileService {
    private steps: IStepOption[] = [];

    public tourEnded$: Subject<void> = new Subject<void>();

    constructor(private readonly tourService: TourService, private readonly translateService: TranslateService) {}

    public startTour(): void {
        this.initializeSteps();

        this.tourService.initialize(this.steps, {
            enableBackdrop: true,
            prevBtnTitle: this.translateService.instant('components.button.buttonPrevious'),
            nextBtnTitle: this.translateService.instant('components.button.buttonNext'),
            endBtnTitle: this.translateService.instant('components.button.finish'),
        });

        this.tourService.end$.subscribe(() => {
            this.tourEnded$.next();
        });

        this.tourService.start();
    }

    private initializeSteps() {
        if (this.steps.length > 0) {
            return;
        }

        this.steps = [
            {
                anchorId: 'welcome-mobile',
                stepId: 'welcome',
                title: this.translateService.instant('tutorial.welcome.title'),
                content: this.translateService.instant('tutorial.welcome.content'),
                placement: 'top-before',
            },
            {
                anchorId: 'clubs-mobile',
                stepId: 'clubs',
                title: this.translateService.instant('tutorial.clubs.title'),
                content: this.translateService.instant('tutorial.clubs.content'),
                placement: 'top-before',
            },
            {
                anchorId: 'projects-mobile',
                stepId: 'projects',
                title: this.translateService.instant('tutorial.projects.title'),
                content: this.translateService.instant('tutorial.projects.content'),
                placement: 'top',
            },
            {
                anchorId: 'events-mobile',
                stepId: 'events',
                title: this.translateService.instant('tutorial.events.title'),
                content: this.translateService.instant('tutorial.events.content'),
                placement: 'top-after',
            },
            {
                anchorId: 'directory-mobile',
                stepId: 'directory',
                title: this.translateService.instant('tutorial.directory.title'),
                content: this.translateService.instant('tutorial.directory.content'),
                placement: 'before-bottom',
            },
            {
                anchorId: 'burger-menu-mobile',
                stepId: 'profile',
                title: this.translateService.instant('tutorial.mobile.burgerMenu.title'),
                content: this.translateService.instant('tutorial.mobile.burgerMenu.content'),
                placement: 'bottom-before',
            },
            {
                anchorId: 'complete-profile-mobile',
                stepId: 'complete-profile',
                title: this.translateService.instant('tutorial.complete-profile.title'),
                content: this.translateService.instant('tutorial.complete-profile.content'),
                placement: 'bottom-after',
            },
        ];
    }
}
