import { Reaction } from './../../models/clubs/reaction.model';
import { Injectable } from '@angular/core';
import { AccountsService } from './../identity/accounts.service';
import { Publication } from './../../models/clubs/publication.model';
import { environment } from './../../../environments/environment';
import { BaseSignalrService } from './base-signalr';

const PostRoutesName = {
    AddOrRemoveReaction: 'AddOrRemoveReaction',
    OnWritting: 'OnWritting',
    AddComment: 'AddComment',
};

@Injectable()
export class PostsSignalrService extends BaseSignalrService {
    constructor(protected override readonly accountsService: AccountsService) {
        super(accountsService);
        this.hubBaseUrl = this.apiBaseUrl + environment.microservices.realTime.postHub.baseUrl;
        if (environment.microservices.restrictionAccessApiKey)
            this.hubBaseUrl += '?restriction-access-api-key=' + environment.microservices.restrictionAccessApiKey;
    }

    isPostFocused(postId: string): boolean {
        return this.currentGroupsNames.includes(postId);
    }

    addReactionListener(groupName: string, callback: (...args: any[]) => void) {
        this.addListener(PostRoutesName.AddOrRemoveReaction, groupName, callback);
    }

    addOnWrittingListener(groupName: string, callback: (...args: any[]) => void) {
        this.addListener(PostRoutesName.OnWritting, groupName, callback);
    }

    addCommentListener(groupName: string, callback: (...args: any[]) => void) {
        this.addListener(PostRoutesName.AddComment, groupName, callback);
    }

    addOrRemoveReaction(groupName: string, reactionTypeId: string, previousReaction: Reaction = null) {
        this.sendMessage(PostRoutesName.AddOrRemoveReaction, groupName, reactionTypeId, previousReaction);
    }

    onWritting(groupName: string, isWritting: boolean) {
        this.sendMessage(PostRoutesName.OnWritting, groupName, isWritting);
    }

    addComment(groupName: string, comment: Publication) {
        this.sendMessage(PostRoutesName.AddComment, groupName, comment);
    }
}
