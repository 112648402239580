<div
    *ngIf="!interestsComponent.dataLoaded"
    class="spinner-border text-info"
    role="status"
>
    <span class="sr-only">Chargement des centres d'intérêt...</span>
</div>
<app-interests
    class="col-12 mt-4"
    context="project"
    [currentInterests]="projectCommand.associatedInterests"
    [maxInterests]="PROJECT_MAX_INTERESTS"
    (changed)="onChangedInterests($event)"
    [interestsDomainPlaceholder]="'projects.formStep3.interests.domainplaceholder' | translate"
    [interstsPlaceHolder]="'projects.formStep3.interests.interestsplaceholder' | translate"
    #interestsComponent
>
</app-interests>

<div
    *ngIf="!(skillsComponent.dataLoaded | async)"
    class="spinner-border text-info"
    role="status"
>
    <span class="sr-only">Chargement des compétences...</span>
</div>
<app-skills
    class="col-12 mt-4"
    context="project"
    [currentSkills]="projectCommand.searchedSkills"
    [maxSkills]="PROJECT_MAX_SKILLS"
    (changed)="onChangedSkills($event)"
    [showSpecificCommunitySkills]="preselectSkills"
    [skillsDomainPlaceholder]="'projects.formStep3.skills.domainplaceholder' | translate"
    [skillsPlaceHolder]="'projects.formStep3.skills.skillsplaceholder' | translate"
    #skillsComponent
>
</app-skills>

<div class="col-12 mt-2 footer-create-project">
    <div class="float-left">
        <button
            class="btn btn-secondary"
            type="button"
            (click)="goToPreviousStep()"
        >
            <em class="icon icon-arrow-right1 d-sm-inline d-md-none"></em>
            <span class="d-none d-sm-inline">{{ 'components.button.buttonPrevious' | translate }}</span>
        </button>
    </div>

    <div class="float-right">
        <!--Supprimer un projet-->
        <button
            *ngIf="isRemovable()"
            class="btn btn-danger"
            data-toggle="modal"
            data-target="#confirmDeleteModal"
            type="button"
        >
            <em class="icon icon-trash d-sm-inline d-md-none"></em>
            <span class="d-none d-sm-inline">{{ 'components.button.buttonDelete' | translate }}</span>
        </button>

        <!--Enregistrer un brouillon-->
        <button
            *ngIf="!isEditMode || (isEditMode && isDraft)"
            class="btn btn-secondary"
            type="button"
            (click)="openConfirmePopupSaveDraft()"
        >
            <em class="icon icon-save d-sm-inline d-md-none"></em>
            <span class="d-none d-sm-inline">{{ 'components.button.buttonSaveDraft' | translate }}</span>
        </button>

        <ng-container>
            <ng-container *ngIf="isEditMode && !isDraft">
                <!--Modifier un projet-->
                <button
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target="#confirmUpdateModal"
                >
                    <em class="icon icon-save d-sm-inline d-md-none"></em>
                    <span class="d-none d-md-inline">{{ 'components.button.modify' | translate }}</span>
                </button>

                <!--Publier un projet-->
                <button
                    *ngIf="!isPublished"
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target="#confirmPublishModal"
                >
                    <em class="icon icon-publish d-sm-inline d-md-none"></em>
                    <span class="d-none d-md-inline">{{ 'components.button.publish' | translate }}</span>
                </button>
            </ng-container>
        </ng-container>

        <!-- <ng-template #userActions>
      <!--Demander la publication d'un projet->
      <button
        *ngIf="!isEditMode || (isEditMode && isDraft)"
        class="btn btn-primary"
        (click)="onPublishRequestClick()"
      >
        <em class="icon icon-publish d-sm-inline d-md-none"></em>
        <span class="d-none d-md-inline">Demander la publication</span>
      </button>

      <!--Modifier un projet->
      <ng-container *ngIf="isEditMode && !isDraft">
        <button
          *ngIf="isPublished"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#confirmUpdateModal"
        >
          <em class="icon icon-save d-sm-inline d-md-none"></em>
          <span class="d-none d-md-inline">{{ 'components.button.modify' | translate }}</span>
        </button>

        <button
          *ngIf="!isPublished"
          class="btn btn-primary"
          (click)="onSubmit(false)"
        >
          <em class="icon icon-save d-sm-inline d-md-none"></em>
          <span class="d-none d-md-inline">{{ 'components.button.modify' | translate }}</span>
        </button>
      </ng-container>
    </ng-template> -->
    </div>
</div>

<!-- Publish Request Modal -->
<ng-template
    id="modal"
    #publishRequestModal
    let-modal
    class="modal fade"
>
    <div class="modal-content">
        <div class="modal-header">
            <h5
                class="modal-title"
                id="exampleModalLabel"
            >
                {{ 'projects.formStep3.publishRequestModal.title' | translate }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-image">
            <img
                src="{{ projectCommand.picturePreviewUrl }}"
                [alt]="'projects.formStep3.projectPictureAlt' | translate"
            />
        </div>
        <div class="modal-body">
            <p class="ta-c lead">
                <strong>{{ 'projects.formStep3.publishRequestModal.body' | translate }}</strong>
            </p>
            <!-- <p class="ta-c lead">
        <strong>
          Votre serez notifié dès que votre {{ dictionary.minProjet }} sera
          visible après validation par l’administrateur.
        </strong>
      </p> -->
            <p class="ta-c lead">
                {{ 'projects.formStep3.publishRequestModal.confirm' | translate }}
            </p>
        </div>
        <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                (click)="onClose()"
            >
                {{ 'components.button.buttonCancel' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-primary"
                (click)="onSubmit(true)"
            >
                {{ 'components.button.buttonYes' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<!-- Publish Modal -->
<div
    class="modal fade bd-example-modal-lg"
    id="confirmPublishModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirmPublishModalLabel"
    aria-hidden="true"
>
    <div
        class="modal-dialog modal-lg"
        role="document"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    id="exampleModalLabel"
                >
                    {{ 'projects.formStep3.publishModal.title' | translate }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-image">
                <img
                    src="{{ projectCommand.picturePreviewUrl }}"
                    [alt]="'projects.formStep3.projectPictureAlt' | translate"
                />
            </div>
            <div class="modal-body">
                <p class="ta-c lead">
                    {{ 'projects.formStep3.publishModal.confirm' | translate }}
                </p>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                >
                    {{ 'components.button.buttonCancel' | translate }}
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="onPublish()"
                    data-dismiss="modal"
                >
                    {{ 'components.button.buttonYes' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Edit Modal -->
<div
    class="modal fade bd-example-modal-lg"
    id="confirmUpdateModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirmUpdateModalLabel"
    aria-hidden="true"
>
    <div
        class="modal-dialog modal-lg"
        role="document"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    id="exampleModalLabel"
                >
                    {{ 'projects.formStep3.editModal.title' | translate }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-image">
                <img
                    src="{{ projectCommand.picturePreviewUrl }}"
                    [alt]="'projects.formStep3.projectPictureAlt' | translate"
                />
            </div>
            <div class="modal-body">
                <p
                    *ngIf="isPublished"
                    class="ta-c lead"
                >
                    <strong>
                        {{ 'projects.formStep3.editModal.warnEditPublishedProject' | translate }}<br />
                        <span *ngIf="!isAdmin">
                            Vos changements seront visibles après validation par l’administrateur.
                        </span>
                    </strong>
                </p>
                <p class="ta-c lead">
                    {{ 'projects.formStep3.editModal.confirm' | translate }}
                </p>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                >
                    {{ 'components.button.buttonCancel' | translate }}
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="onSubmit(true)"
                    data-dismiss="modal"
                >
                    {{ 'components.button.buttonYes' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!--Save draft-->
<ng-template
    id="modal"
    #draftStepOneModal4
    let-modal
    class="modal fade"
>
    <div class="modal-content">
        <div class="modal-header">
            <h5
                class="modal-title"
                id="exampleModalLabel"
            >
                {{ 'projects.formStep3.saveDraftModal.title' | translate }}
            </h5>
            <button
                type="button"
                class="close"
                (click)="onClose()"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div
            *ngIf="projectCommand.picturePreviewUrl"
            class="modal-image"
        >
            <img
                src="{{ projectCommand.picturePreviewUrl }}"
                [alt]="'projects.formStep3.projectPictureAlt' | translate"
            />
        </div>
        <div class="modal-body">
            <p class="ta-c lead">
                {{ 'projects.formStep3.saveDraftModal.confirm' | translate }}
            </p>
        </div>
        <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                (click)="onClose()"
            >
                {{ 'components.button.buttonCancel' | translate }}
            </button>
            <button
                type="button"
                class="btn btn-primary"
                (click)="onSave()"
            >
                {{ 'components.button.buttonYes' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<!-- Delete Modal -->
<div
    class="modal fade bd-example-modal-lg"
    id="confirmDeleteModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteConfirmeModalLabel"
    aria-hidden="true"
>
    <div
        class="modal-dialog modal-lg"
        role="document"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5
                    class="modal-title"
                    id="confirmeCloseProjectModalLabel"
                >
                    {{ 'projects.formStep3.deleteModal.title' | translate }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div
                class="modal-image"
                *ngIf="projectLight?.pictureAccessLink"
            >
                <img
                    src="{{ projectLight?.pictureAccessLink }}"
                    [alt]="'projects.formStep3.projectPictureAlt' | translate"
                />
            </div>
            <div class="modal-body">
                <p class="ta-c lead">
                    {{ 'projects.formStep3.deleteModal.confirm' | translate }}
                </p>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                >
                    {{ 'components.button.buttonCancel' | translate }}
                </button>
                <button
                    type="button"
                    class="btn btn-danger"
                    (click)="deleteProject()"
                    data-dismiss="modal"
                >
                    {{ 'components.button.buttonDelete' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
