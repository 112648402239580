export class UserStatus {
    public userStatusTypes: UserStatusTypes;
    public libelle: string;
}

export enum UserStatusTypes {
    Inactive = 0,
    Active = 1,
    Disabled = 2,
}


export function getAllUserStatus(): UserStatus[] {
    return [
        { userStatusTypes: UserStatusTypes.Active, libelle: 'Actif sur Wudo' },
        { userStatusTypes: UserStatusTypes.Inactive, libelle: 'Inactif sur Wudo' },
        { userStatusTypes: UserStatusTypes.Disabled, libelle: 'Désactivé sur Wudo' },
    ];
}

export function getUserStatus(userStatusType: UserStatusTypes): UserStatus {
    return getAllUserStatus().find((userStatus) => userStatus.userStatusTypes == userStatusType);
}
