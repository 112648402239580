import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-pdf-viewer-modal',
    templateUrl: './app-pdf-viewer-modal.component.html',
    styleUrls: ['./app-pdf-viewer-modal.component.scss'],
})
export class AppPdfPreviewModalComponent implements OnInit {
    @Input() pdfUrl: string;
    @Input() visibleModal = false;
    @Input() pdfName: string;
    safePdfUrl: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        const pdfWithToolbarDisabled = this.pdfUrl + '#toolbar=0';
        this.safePdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfWithToolbarDisabled);
    }

    openPdfModal() {
        this.visibleModal = true;
    }
    closePdfModal() {
        this.visibleModal = false;
    }
}
