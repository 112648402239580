import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as queryString from 'query-string';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RetrieveThemeCommand } from '../../models/identity/commands/retrieve-theme-command.model';

import { catchError } from 'rxjs/operators';
import { CommunityMailSender } from '../../models/shared/community-mail-sender';
import { PlatformLogo } from '../../models/shared/platform-logo.model';
import { Theme } from '../../models/shared/theme.model';
import { CurrentThemeStore } from '../../stores/current-theme.store';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private basethemesApiUrl = `${environment.microservices.identity.baseUrl}/api/v1/Themes`;
    private retrieveApiUrl = `${this.basethemesApiUrl}/Retrieve`;
    private retrievePotentialsMailSenderApiUrl = `${this.basethemesApiUrl}/RetrievePotentialsMailSender`;
    private saveApiUrl = `${this.basethemesApiUrl}/Save`;

    constructor(private readonly http: HttpClient, private readonly themeStore: CurrentThemeStore) {}

    public applyThemeToDocument(theme: Theme = null) {
        if (!theme) {
            return;
        }

        document.documentElement.style.setProperty('--primary-color', theme.primaryColor);
        document.documentElement.style.setProperty('--secondary-color', theme.secondaryColor);
        document.documentElement.style.setProperty('--button1-text-color', theme.button1TextColor);
        document.documentElement.style.setProperty('--button2-text-and-border-color', theme.button2TextAndBorderColor);
        document.documentElement.style.setProperty('--clickable-link-color', theme.clickableLinkColor);

        // extra colors
        document.documentElement.style.setProperty('--primary-color-shadow', theme.primaryColor + '80');
        document.documentElement.style.setProperty('--button2-color-shadow', theme.button2TextAndBorderColor + '80');

        this.themeStore.currentTheme = theme;
    }

    public retrieve(command: RetrieveThemeCommand): Observable<Theme> {
        return this.http.get<Theme>(`${this.retrieveApiUrl}?${queryString.stringify(command)}`);
    }

    public retrievePotentialsMailSender(communityId: string): Observable<CommunityMailSender[]> {
        return this.http.get<CommunityMailSender[]>(`${this.retrievePotentialsMailSenderApiUrl}/${communityId}`);
    }

    public save(command: FormData): Observable<Theme> {
        return this.http.post<Theme>(this.saveApiUrl, command);
    }

    public getThemeLogo(communityId: string): Observable<PlatformLogo> {
        return this.http.get<PlatformLogo>(`${this.basethemesApiUrl}/Community/Logo/${communityId}`).pipe(
            catchError((error) => {
                throw error;
            }),
        );
    }
}
