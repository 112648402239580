import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EventStatusFilterEnum } from '../../models/events/event-status-filter.enum';
import { MyEventsFilters } from '../../models/events/my-event-filters.model';
import { BaseFiltersCheckbox } from '../shared/base-filters-checkbox/base-filters-checkbox.directive';
import { BaseFiltersComponent } from '../shared/base-filters/base-filters.component';
import { JoinedEventsCanceledCheckboxComponent } from './components/joined-events-canceled-checkbox/joined-events-canceled-checkbox.component';
import { JoinedEventsComingCheckboxComponent } from './components/joined-events-coming-checkbox/joined-events-coming-checkbox.component';
import { JoinedEventsDoneCheckboxComponent } from './components/joined-events-done-checkbox/joined-events-done-checkbox.component';
import { JoinedEventsOngoingCheckboxComponent } from './components/joined-events-ongoing-checkbox/joined-events-ongoing-checkbox.component';
import { OwnedEventsCanJoinCheckboxComponent } from './components/owned-events-can-join-checkbox/owned-events-can-join-checkbox.component';
import { OwnedEventsCanceledCheckboxComponent } from './components/owned-events-canceled-checkbox/owned-events-canceled-checkbox.component';
import { OwnedEventsDoneCheckboxComponent } from './components/owned-events-done-checkbox/owned-events-done-checkbox.component';
import { OwnedEventsFullCheckboxComponent } from './components/owned-events-full-checkbox/owned-events-full-checkbox.component';
import { OwnedEventsOngoingCheckboxComponent } from './components/owned-events-ongoing-checkbox/owned-events-ongoing-checkbox.component';

@Component({
    selector: 'app-my-events-filters',
    templateUrl: './my-events-filters.component.html',
})
export class MyEventsFiltersComponent extends BaseFiltersComponent<MyEventsFilters> implements OnInit {
    environment = environment;

    @ViewChildren(BaseFiltersCheckbox)
    filtersCheckbox: QueryList<BaseFiltersCheckbox>;

    public ngOnInit(): void {
        this.ready.emit();
    }

    public onFiltersSelectionChanged() {
        this.selectionChanged.emit();
    }

    public resetFilters(): void {
        this.filtersCheckbox.forEach((c) => c.resetFilters());
        this.selectionChanged.emit();
    }

    public override extractSelectedFilters(): MyEventsFilters {
        const filters = new MyEventsFilters();

        for (const checkboxComponent of this.filtersCheckbox.filter((c) => c.filterItem.checked)) {
            // owned events
            if (checkboxComponent instanceof OwnedEventsCanJoinCheckboxComponent) {
                filters.ownedEventsStatus.push(EventStatusFilterEnum.CanJoin);
            } else if (checkboxComponent instanceof OwnedEventsCanceledCheckboxComponent) {
                filters.ownedEventsStatus.push(EventStatusFilterEnum.IsCanceled);
            } else if (checkboxComponent instanceof OwnedEventsFullCheckboxComponent) {
                filters.ownedEventsStatus.push(EventStatusFilterEnum.IsFull);
            } else if (checkboxComponent instanceof OwnedEventsDoneCheckboxComponent) {
                filters.ownedEventsStatus.push(EventStatusFilterEnum.IsDone);
            } else if (checkboxComponent instanceof OwnedEventsOngoingCheckboxComponent) {
                filters.ownedEventsStatus.push(EventStatusFilterEnum.IsOngoing);
            }
            // joined events
            else if (checkboxComponent instanceof JoinedEventsComingCheckboxComponent) {
                filters.joinedEventsStatus.push(EventStatusFilterEnum.IsComing);
            } else if (checkboxComponent instanceof JoinedEventsCanceledCheckboxComponent) {
                filters.joinedEventsStatus.push(EventStatusFilterEnum.IsCanceled);
            } else if (checkboxComponent instanceof JoinedEventsDoneCheckboxComponent) {
                filters.joinedEventsStatus.push(EventStatusFilterEnum.IsDone);
            } else if (checkboxComponent instanceof JoinedEventsOngoingCheckboxComponent) {
                filters.joinedEventsStatus.push(EventStatusFilterEnum.IsOngoing);
            }
        }

        return filters;
    }

    public override setSelectedFilters(filters: MyEventsFilters): void {
        for (const checkboxComponent of this.filtersCheckbox) {
            // owned events
            if (checkboxComponent instanceof OwnedEventsCanJoinCheckboxComponent) {
                checkboxComponent.filterItem.checked = filters.ownedEventsStatus.includes(EventStatusFilterEnum.CanJoin);
            } else if (checkboxComponent instanceof OwnedEventsCanceledCheckboxComponent) {
                checkboxComponent.filterItem.checked = filters.ownedEventsStatus.includes(EventStatusFilterEnum.IsCanceled);
            } else if (checkboxComponent instanceof OwnedEventsFullCheckboxComponent) {
                checkboxComponent.filterItem.checked = filters.ownedEventsStatus.includes(EventStatusFilterEnum.IsFull);
            } else if (checkboxComponent instanceof OwnedEventsDoneCheckboxComponent) {
                checkboxComponent.filterItem.checked = filters.ownedEventsStatus.includes(EventStatusFilterEnum.IsDone);
            } else if (checkboxComponent instanceof OwnedEventsOngoingCheckboxComponent) {
                checkboxComponent.filterItem.checked = filters.ownedEventsStatus.includes(EventStatusFilterEnum.IsOngoing);
            }
            // joined events
            else if (checkboxComponent instanceof JoinedEventsComingCheckboxComponent) {
                checkboxComponent.filterItem.checked = filters.joinedEventsStatus.includes(EventStatusFilterEnum.IsComing);
            } else if (checkboxComponent instanceof JoinedEventsCanceledCheckboxComponent) {
                checkboxComponent.filterItem.checked = filters.joinedEventsStatus.includes(EventStatusFilterEnum.IsCanceled);
            } else if (checkboxComponent instanceof JoinedEventsDoneCheckboxComponent) {
                checkboxComponent.filterItem.checked = filters.joinedEventsStatus.includes(EventStatusFilterEnum.IsDone);
            } else if (checkboxComponent instanceof JoinedEventsOngoingCheckboxComponent) {
                checkboxComponent.filterItem.checked = filters.joinedEventsStatus.includes(EventStatusFilterEnum.IsOngoing);
            }
        }
    }
}
