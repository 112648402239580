<div class="progress-bar-container p-pl-4 p-pr-4 p-pt-4 p-mt-6">
    <div
        class="progress-bar"
        [ngClass]="{ active: progress >= 1, inactive: progress < 1 }"
        style="width: 25%; height: 10px; margin-right: 5px"
    ></div>
    <div
        class="progress-bar"
        [ngClass]="{ active: progress >= 2, inactive: progress < 2 }"
        style="width: 25%; height: 10px; margin-right: 5px"
    ></div>
    <div
        class="progress-bar"
        [ngClass]="{ active: progress >= 3, inactive: progress < 3 }"
        style="width: 25%; height: 10px; margin-right: 5px"
    ></div>
    <div
        class="progress-bar"
        [ngClass]="{ active: progress >= 4, inactive: progress < 4 }"
        style="width: 25%; height: 10px"
    ></div>
</div>
