import { Component, Input } from '@angular/core';

@Component({
    selector: 'wudo-card',
    templateUrl: './wudo-card.component.html',
    styleUrls: ['./wudo-card.component.scss'],
})
export class WudoCardComponent {
    @Input()
    flatTop = false;

    @Input()
    flatBottom = false;

    @Input()
    marginTop = '5px';

    @Input()
    marginBottom = '0';
}
