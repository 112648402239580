export enum EventStatusFilterEnum {
    CanJoin = 1,
    IsFull = 2,
    IsComing = 3,
    IsCanceled = 4,
    IsDone = 5,
    IsOngoing = 6
}

export const EventStatusFilterEnumLabelMap: Record<EventStatusFilterEnum, string> = {
    [EventStatusFilterEnum.CanJoin]: "Inscriptions possibles",
    [EventStatusFilterEnum.IsFull]: "Complets",
    [EventStatusFilterEnum.IsComing]: "À venir",
    [EventStatusFilterEnum.IsCanceled]: "Annulés",
    [EventStatusFilterEnum.IsDone]: "Terminés",
    [EventStatusFilterEnum.IsOngoing]: "En cours",
};
