import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { messages } from '../../../constants/messages';
import { organizationManageCommunitySortModes } from '../../../constants/organization-list-filters';
import { CommunityFilter } from '../../../models/identity/community-filter.model';
import { ManageCommunityFilter } from '../../../models/identity/manage-community-filters.model';
import { Organization } from '../../../models/identity/organization.model';
import { AccountsService } from '../../../services/identity/accounts.service';
import { CommunityService } from '../../../services/identity/community.service';
import { environment } from './../../../../environments/environment';
import { regexPatterns } from './../../../constants/regex-patterns';
import { ToastrHelper } from './../../../helpers/toastr.helper';
import { GeographicalArea } from './../../../models/identity/geographical-area.model';
import { UpdateOrganizationCommand } from './../../../models/identity/update-organization-command.model';
import { GeographicalAreaService } from './../../../services/identity/geographical-area.service';
import { OrganizationService } from './../../../services/identity/organization.service';

@Component({
    selector: 'app-community-organizations',
    templateUrl: './community-organizations.component.html',
    styleUrls: ['./community-organizations.component.scss'],
})
export class CommunityOrganizationsComponent implements OnInit {
    public address1 = new FormControl(null, [Validators.maxLength(250), Validators.required]);
    public allSelectionMode = false;
    public availableGeographicalAreas: GeographicalArea[];
    public city = new FormControl(null, [Validators.maxLength(50), Validators.required]);
    public customSearch = '';
    public email = new FormControl(null, [Validators.maxLength(180), Validators.pattern(regexPatterns.email)]);
    public exportOrganizationsAvailable = false;
    public geographicalAreaId = new FormControl(null, [Validators.required]);
    public importOrganizationsAvailable = false;
    public isUpdateOrganizationsActivePropertyModalVisible = false;
    public linkedIn = new FormControl(null, [
        Validators.maxLength(100),
        Validators.pattern(regexPatterns.LinkedInProfileUrl),
    ]);
    public loading = true;
    public massEditOrganizationsAvailable = false;
    public messages = messages;
    public nbUsersTotalInCommunity: number;
    public newOrganizationStatusValue: boolean;
    // controls
    public organizationName = new FormControl(null, [Validators.maxLength(250), Validators.required]);
    public organizationSortModes = organizationManageCommunitySortModes;
    public organizations: Organization[] = [];
    public organizationsCurentlyEditedIds: string[] = [];
    public page = 1;
    public pageSize = 10;
    public phoneNumber = new FormControl(null, [
        Validators.maxLength(21),
        Validators.pattern(regexPatterns.phoneNumber),
    ]);
    public postalCode = new FormControl(null, [Validators.maxLength(50), Validators.required]);
    public selectedOrganizations: Organization[] = [];
    public sortMode: string = this.organizationSortModes.name;
    public sortOrder = 1;
    public totalRecords = 0;
    public webSite = new FormControl(null, [Validators.maxLength(2048), Validators.pattern(regexPatterns.fullUrl)]);

    public form = new FormGroup({
        id: new FormControl(),
        organizationName: this.organizationName,
        address1: this.address1,
        geographicalAreaId: this.geographicalAreaId,
        city: this.city,
        postalCode: this.postalCode,
        phoneNumber: this.phoneNumber,
        email: this.email,
        webSite: this.webSite,
        linkedIn: this.linkedIn,
    });

    constructor(
        protected readonly accountsService: AccountsService,
        private readonly communityService: CommunityService,
        private readonly organizationService: OrganizationService,
        private readonly toastrHelper: ToastrHelper,
        private readonly geographicalAreaService: GeographicalAreaService,
        private translate: TranslateService,
    ) {
        this.importOrganizationsAvailable = accountsService.isAdmin()
            ? environment.features.backOfficeAdminFeaturesAvailable.importOrganizations
            : environment.features.backOfficeCommunityManagerFeaturesAvailable.importOrganizations;
        this.exportOrganizationsAvailable = accountsService.isAdmin()
            ? environment.features.backOfficeAdminFeaturesAvailable.exportOrganizations
            : environment.features.backOfficeCommunityManagerFeaturesAvailable.exportOrganizations;
        this.massEditOrganizationsAvailable = accountsService.isAdmin()
            ? environment.features.backOfficeAdminFeaturesAvailable.massEditOrganizations
            : environment.features.backOfficeCommunityManagerFeaturesAvailable.massEditOrganizations;
    }

    //User can enable/disable organizations only if the new status will be a new one for all selected organizations
    public canEditActiveProperty(newValue: boolean) {
        return this.selectedOrganizations.every((organization) => organization.active != newValue);
    }

    public closeModal() {
        this.isUpdateOrganizationsActivePropertyModalVisible = false;
    }

    public isOrganizationCurentlyEdited(organizationId: string): boolean {
        return this.organizationsCurentlyEditedIds.includes(organizationId);
    }

    public loadData() {
        this.geographicalAreaService.getGeographicalAreas().subscribe((geographicalAreas) => {
            this.availableGeographicalAreas = geographicalAreas;
        });
    }

    public loadOrganizationsLazy(event: LazyLoadEvent) {
        this.pageSize = event.rows;
        this.page = event.first / this.pageSize + 1;
        this.sortMode = event.sortField ? event.sortField : this.organizationSortModes.name;
        this.sortOrder = event.sortOrder;
        this.loadOrganizations();
    }

    public ngOnInit(): void {
        this.accountsService.currentCommunityChange.subscribe(() => {
            this.loadOrganizations();
        });
        this.loadData();
    }

    public onAllSelectionClick() {
        this.allSelectionMode = !this.allSelectionMode;
        if (!this.allSelectionMode) {
            this.selectedOrganizations = [];
        }
    }

    public onCustomSearchTextChanged(text: string) {
        this.customSearch = text;

        setTimeout(() => {
            this.page = 1;
            if (text === this.customSearch) this.loadOrganizations();
        }, 1000);
    }

    public onRowEditCancel(organization: Organization, index: number) {
        this.organizationsCurentlyEditedIds = this.organizationsCurentlyEditedIds.filter((id) => id != organization.id);
    }

    public onRowEditInit(organization: Organization) {
        this.organizationsCurentlyEditedIds.push(organization.id);
    }

    public async onRowEditSave(organization: Organization) {
        this.form.controls.organizationName.setValue(organization.organizationName);
        this.form.controls.address1.setValue(organization.address1);
        this.form.controls.geographicalAreaId.setValue(organization.geographicalArea.id);
        this.form.controls.city.setValue(organization.city);
        this.form.controls.postalCode.setValue(organization.postalCode);
        this.form.controls.phoneNumber.setValue(organization.phoneNumber);
        this.form.controls.email.setValue(organization.email);
        this.form.controls.webSite.setValue(organization.webSite);
        this.form.controls.linkedIn.setValue(organization.linkedIn);

        if (!this.form.valid) {
            this.toastrHelper.showWarning(this.translate.instant('shared.forms.organization.formIncorrect'));
            return;
        }

        const organizationFormData: UpdateOrganizationCommand = {
            ...organization,
            organizationId: organization.id,
            organizationName: organization.organizationName,
            picture: null,
            primaryCommunityId: organization.primaryCommunity?.id,
            geographicalAreaId: organization.geographicalArea.id,
        };

        this.organizationService.updateOrganizationMinimalInfos(organizationFormData).subscribe(() => {
            this.toastrHelper.showSuccess(
                this.translate.instant('shared.forms.organization.organizationUpdateSuccess'),
            );
        });
        this.organizationsCurentlyEditedIds = this.organizationsCurentlyEditedIds.filter((id) => id != organization.id);
    }

    public openDeleteModal() {}

    public openEditModal() {}

    public openUpdateOrganizationsActivePropertyModal(newValue: boolean) {
        if (!this.canEditActiveProperty(newValue)) return;
        this.newOrganizationStatusValue = newValue;
        this.isUpdateOrganizationsActivePropertyModalVisible = true;
    }

    public updateOrganizationsActiveProperty() {
        if (!this.canEditActiveProperty(this.newOrganizationStatusValue)) return;
        const selectedOrganizationIds = this.selectedOrganizations.map((orga) => orga.id);
        this.organizationService
            .updateManyOrganizationsProperty({
                organizationsIds: selectedOrganizationIds,
                propertyName: 'Active',
                propertyValue: this.newOrganizationStatusValue,
            })
            .subscribe(
                (result) => {
                    this.toastrHelper.showSuccess(
                        this.translate.instant('community.pages.organizations.manyOrganizationsUpdateSuccess'),
                    );
                    this.organizations
                        .filter((organization) => selectedOrganizationIds.includes(organization.id))
                        .forEach((organization) => {
                            organization.active = this.newOrganizationStatusValue;
                        });
                    this.closeModal();
                },
                () => {
                    this.closeModal();
                },
            );
    }

    private loadOrganizations() {
        const filters = new ManageCommunityFilter();
        filters.page = this.page;
        filters.pageSize = this.pageSize;
        filters.sortMode = this.sortMode;
        filters.sortOrder = this.sortOrder;
        filters.userCustomSearch = this.customSearch;
        filters.community = new CommunityFilter(this.accountsService.currentCommunity.id, null);

        this.loading = true;

        this.organizations = [];
        this.selectedOrganizations = [];

        this.communityService
            .getOganizationsFromCommunity(this.accountsService.currentCommunity.id, filters)
            .subscribe((result) => {
                this.organizations = result.data;
                this.totalRecords = result.count;
                this.loading = false;
            });
    }
}
