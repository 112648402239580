import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { localStorageKeys } from '../../constants/local-storage-keys';
import { AuthenticatedUser } from '../../models/identity/authenticated-user.model';
import { AuthenticationCommand } from '../../models/identity/authentication-command.model';
import { AuthenticationResult } from '../../models/identity/authentication-result.model';
import { ConfirmEmailCommand } from '../../models/identity/commands/confirm-email-command.model';
import { COMMUNITY_MANAGER_ID, CommunityWithRoles } from '../../models/identity/community-with-roles.model';
import { PermissionsService } from '../../services/shared/permissions.service';
import { ThemeService } from '../shared/theme.service';
import { Event } from './../../models/events/event.model';
import { Community } from './../../models/identity/community.model';
import { OAuth2AuthenticationCommand } from './../../models/identity/oauth2-authentication-command.model';
import { ORGANIZATION_MANAGER_ID, OrganizationAsUserProperty } from './../../models/identity/organization-as-user-property.model';
import { UserPermissions } from './../../models/identity/user-permission.model';
import { ModifierTypes, User } from './../../models/identity/user.model';
import { Project } from './../../models/projects/project.model';
import { PermissionsState } from './../../states/permissions/permissions.state';

@Injectable({ providedIn: 'root' })
export class AccountsService {
    private apiAccountsBaseUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.accountsApi.baseUrl}`;
    private backupUser: AuthenticatedUser;
    private checkUserAccountUrl = `${this.apiAccountsBaseUrl}/${environment.microservices.identity.accountsApi.checkUserAccount}`;
    private confirmEmailUrl = `${this.apiAccountsBaseUrl}/${environment.microservices.identity.accountsApi.confirmEmail}`;
    private currentUserSubject: BehaviorSubject<AuthenticatedUser>;
    private loginUrl = `${this.apiAccountsBaseUrl}/${environment.microservices.identity.accountsApi.login}`;
    private logoutUrl = `${this.apiAccountsBaseUrl}/${environment.microservices.identity.accountsApi.logout}`;
    private oAuth2LoginUrl = `${this.apiAccountsBaseUrl}/${environment.microservices.identity.accountsApi.oAuth2Login}`;
    private permissions: UserPermissions;
    private refreshTokenUrl = `${this.apiAccountsBaseUrl}/${environment.microservices.identity.accountsApi.refreshToken}`;
    private renewConfirmEmailUrl = `${this.apiAccountsBaseUrl}/${environment.microservices.identity.accountsApi.renewConfirmEmailUrl}`;

    public currentUser$: Observable<AuthenticatedUser>;
    public currentCommunity: Community;
    public currentCommunityChange: Subject<Community> = new Subject<Community>();
    public currentOrganizationId = 'undefined';
    public currentOrganizationIdChange: Subject<string> = new Subject<string>();
    public displayUsersStatusAndInactiveUsers: boolean;
    public isRefreshingTokens$ = new Subject<boolean>();
    public onCanUsePlatform: EventEmitter<any> = new EventEmitter();
    public onCurrentUserChanged: EventEmitter<AuthenticatedUser> = new EventEmitter();
    @Select(PermissionsState.getPermissions)
    permissions$: Observable<UserPermissions>;

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly roleService: PermissionsService,
        private readonly themeService: ThemeService,
    ) {
        this.permissions$.subscribe((permissions) => {
            this.permissions = permissions;
        });
        this.currentUserSubject = new BehaviorSubject<AuthenticatedUser>(this.getCurrentUserFromStorage());
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.currentCommunityChange.subscribe((value) => {
            this.currentCommunity = value;
        });

        this.currentOrganizationIdChange.subscribe((value) => {
            this.currentOrganizationId = value;
        });
    }

    public get canUsePlatform(): boolean {
        return this.isFullConnected(this.currentUser);
    }

    public get currentUser(): AuthenticatedUser {
        return this.currentUserSubject.value;
    }

    public get isAuthenticated(): boolean {
        return !!this.getToken();
    }

    public get isRefreshingTokens(): boolean {
        return JSON.parse(localStorage.getItem(localStorageKeys.isRefreshingTokens)) ?? false;
    }

    public canModifyEvent(event: Event, eventOwner: User): ModifierTypes {
        if (this.permissions.admin) return ModifierTypes.Admin;

        const organizationsLeaded: OrganizationAsUserProperty[] = this.getOrganizationLeadedByUser();
        if (organizationsLeaded?.some((userOrganization) => userOrganization.id == eventOwner.organization.id)) {
            return ModifierTypes.OrganizationLeader;
        }

        const isEventVisibleForOwnerMainCommunity =
            event.allCommunitiesVisibility || event.communities.some((community) => community.id === eventOwner.primaryCommunity.id);

        if (isEventVisibleForOwnerMainCommunity) {
            const communitiesManaged: CommunityWithRoles[] = this.getCommunitiesManagedByUser();
            if (communitiesManaged?.some((userCommunity) => userCommunity.id === eventOwner.primaryCommunity.id)) {
                return ModifierTypes.CommunityManager;
            }
        }

        return ModifierTypes.None;
    }

    public canModifyProject(project: Project): ModifierTypes {
        if (this.permissions.admin) return ModifierTypes.Admin;

        const organizationsLeaded: OrganizationAsUserProperty[] = this.getOrganizationLeadedByUser();
        if (organizationsLeaded?.some((userOrganization) => userOrganization.id == project.organization?.id)) return ModifierTypes.OrganizationLeader;

        const communitiesManaged: CommunityWithRoles[] = this.getCommunitiesManagedByUser();

        if (communitiesManaged?.some((userCommunity) => project?.communities?.some((projectCommunity) => userCommunity.id == projectCommunity.id)))
            return ModifierTypes.CommunityManager;

        return ModifierTypes.None;
    }

    public canPreselectBoostFrenchFabSkills(): boolean {
        const primaryCommunityId = this.getUserPrimaryCommunity().id;
        return (
            environment.bffSkilsItems?.length > 0 &&
            (primaryCommunityId === environment.communitiesId.boostFrenchFabId || primaryCommunityId === environment.communitiesId.gifasId)
        );
    }

    public changeCurrentCommunity(newCommunity: Community) {
        this.currentCommunityChange.next(newCommunity);
    }

    public changeCurrentOrganizationId(newId: string) {
        this.currentOrganizationIdChange.next(newId);
    }

    public checkUserAccount(encryptedToken: string, activate: boolean) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };

        return this.http.post(
            `${this.checkUserAccountUrl}`,
            JSON.stringify({
                encryptedToken: encryptedToken,
                activate: activate,
            }),
            httpOptions,
        );
    }

    public confirmEmail(confirmCommand: ConfirmEmailCommand) {
        return this.http.post(this.confirmEmailUrl, confirmCommand).pipe(
            map(() => {
                return true;
            }),
        );
    }

    public generateOAuthStateParameter() {
        let state = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 50; i++) state += possible.charAt(Math.floor(Math.random() * possible.length));

        return state;
    }

    public getCommunitiesManagedByUser(): CommunityWithRoles[] {
        const communitiesTemp: CommunityWithRoles[] = this.currentUser.communities;

        const communitiesManaged: CommunityWithRoles[] = [];
        if ((communitiesTemp?.length ?? 0) > 0) {
            for (let i = 0; i < communitiesTemp.length; i++) {
                if ((communitiesTemp[i].roles?.length ?? 0) > 0 && communitiesTemp[i].roles.some((role) => role.id == COMMUNITY_MANAGER_ID)) {
                    communitiesManaged.push(communitiesTemp[i]);
                }
            }
        }
        return communitiesManaged;
    }

    public isUserManagingCommunity(primaryCommunity: CommunityWithRoles): boolean {
        const communitiesManaged: CommunityWithRoles[] = this.getCommunitiesManagedByUser();
        const isManaging = communitiesManaged.some((community) => community.id === primaryCommunity.id);
        return isManaging;
    }

    public getOrganizationLeadedByUser(): OrganizationAsUserProperty[] {
        const organizationsTemp: OrganizationAsUserProperty[] = [];
        organizationsTemp.push(this.currentUser.organization);

        const organizationsLeaded: OrganizationAsUserProperty[] = [];

        let isOrganizationManager = false;
        if (organizationsTemp && organizationsTemp.length > 0) {
            for (let i = 0; i < organizationsTemp.length; i++) {
                if (organizationsTemp[i].roles && organizationsTemp[i].roles.length > 0) {
                    isOrganizationManager = false;
                    for (let j = 0; j < organizationsTemp[i].roles.length; j++) {
                        if (organizationsTemp[i].roles[j].id == ORGANIZATION_MANAGER_ID) {
                            isOrganizationManager = true;
                            break;
                        }
                    }
                    if (isOrganizationManager) organizationsLeaded.push(organizationsTemp[i]);
                }
            }
        }
        return organizationsLeaded;
    }

    public getToken(): string {
        const currentUserInLocalStorage = this.getCurrentUserFromStorage();
        if (currentUserInLocalStorage !== null) {
            return currentUserInLocalStorage.accessToken;
        }

        return null;
    }

    public getUserPrimaryCommunity(): CommunityWithRoles {
        return this.currentUser?.communities?.find((x) => x.isPrimary === true);
    }

    public hasUserPrimaryCommunity(communityId: string) {
        return communityId === this.getUserPrimaryCommunity().id;
    }

    public isAdmin(): boolean {
        return this.permissions.admin;
    }

    public isInCommunities(idCommunities: any[]): boolean {
        for (const idCommunity of idCommunities) {
            if (this.currentUser.communities.some((community) => community.id == idCommunity.id)) return true;
        }

        return false;
    }

    public isStandardUser(): boolean {
        return !this.isAdmin();
    }

    public isUserCommunityManagerOfAtLeastOneCommunity(communitiesIds: string[]) {
        const managerCommunitiesIds = this.getCommunitiesManagedByUser().map((c) => c.id);

        if (managerCommunitiesIds === undefined || managerCommunitiesIds.length == 0) {
            return false;
        }

        return communitiesIds.some((item) => managerCommunitiesIds.includes(item));
    }

    public login(authenticationCommand: AuthenticationCommand) {
        return this.http.post<AuthenticationResult>(this.loginUrl, authenticationCommand).pipe(
            map((user) => {
                return this.saveUser(user);
            }),
        );
    }

    public logout(returnUrl: string = null, redirect = true) {
        localStorage.clear();

        if (this.currentUser != null) this.http.get(`${this.logoutUrl}/${this.currentUser.userId}`).subscribe();

        this.currentUserSubject.next(null);
        if (!redirect) return;
        this.router.navigate(['/login'], {
            queryParams: { returnUrl: returnUrl },
        });
    }

    public oAuth2Login(oAuth2AuthenticationCommand: OAuth2AuthenticationCommand) {
        return this.http.post<AuthenticationResult>(this.oAuth2LoginUrl, oAuth2AuthenticationCommand).pipe(
            map((user) => {
                return this.saveUser(user);
            }),
        );
    }

    public refreshCurrentUser() {
        this.currentUserSubject.next(this.getCurrentUserFromStorage());
        this.onCurrentUserChanged.emit();
        if (!this.isFullConnected(this.backupUser) && this.isFullConnected(this.currentUser)) {
            this.onCanUsePlatform.emit();
        }
    }

    public refreshToken() {
        const url: string = this.refreshTokenUrl;

        // As multiples tabs can be opened, we can't use this.currentUser to get currentUser
        // The currentUser contains the refreshToken, and each tabs contains a different instance of AccountsService
        // meaning this.currentUser from AccountsService can be a different instance with an old token
        // but not the currentUser in localStorage as it is replaced each time a refresh is done on any tab
        const currentUser = this.getCurrentUserFromStorage();

        if (currentUser?.refreshToken == null) {
            this.logout();
            this.isRefreshingTokens$.next(false);
            return new Observable();
        }

        const req: any = { userId: currentUser.userId, refreshToken: currentUser.refreshToken };
        return this.http.post<AuthenticationResult>(url, req).pipe(
            map((user) => {
                return this.saveUser(user);
            }),
            catchError((e) => {
                this.logout();
                return of(null);
            }),
        );
    }

    public renewConfirmEmail(email: string) {
        return this.http.post(`${this.renewConfirmEmailUrl}/${email}`, null).pipe(
            map(() => {
                return true;
            }),
        );
    }

    public saveCurrentUser() {
        this.backupUser = this.getCurrentUserFromStorage();
        localStorage.setItem(localStorageKeys.currentUser, JSON.stringify(this.currentUser));
    }

    public saveUser(user: AuthenticationResult): AuthenticatedUser {
        // login successful if there's jwt token in the response
        if (user.accessToken && user.currentUser) {
            user.currentUser.accessToken = user.accessToken;
            user.currentUser.refreshToken = user.refreshToken;

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(localStorageKeys.currentUser, JSON.stringify(user.currentUser));
            this.refreshCurrentUser();
            this.themeService.applyThemeToDocument(this.currentUser.theme);

            // Reset permissions to new user.
            this.roleService.assignPermissions();
        }
        this.setRefreshingToken(false);
        return user.currentUser;
    }

    public setDisplayUsersStatusAndInactiveUsers() {
        this.displayUsersStatusAndInactiveUsers = this.isAdmin() || this.getCommunitiesManagedByUser()?.length > 0;
    }

    public setRefreshingToken(newValue: boolean) {
        localStorage.setItem(localStorageKeys.isRefreshingTokens, JSON.stringify(newValue));
        this.isRefreshingTokens$.next(newValue);
    }

    public setUserInterests(user: User) {
        this.currentUser.interests = user.interests;
    }

    public setUserPersonalInfos(user: User) {
        this.currentUser.officePhone = user.officePhone;
        this.currentUser.mobilePhone = user.mobilePhone;
        this.currentUser.city = user.city;
        this.currentUser.geographicalAreaId = user.geographicalAreaId;
        this.currentUser.jobTitle = user.jobTitle;
        this.currentUser.linkedIn = user.linkedIn;
    }

    public setUserSkills(user: User) {
        this.currentUser.skills = user.skills;
    }

    public updateCommunityDisplayNameInLocalStorage(communityId: string, newDisplayName: string) {
        const index = this.currentUser.communities.findIndex((community) => community.id == communityId);
        this.currentUser.communities[index].displayName = newDisplayName;

        localStorage.setItem(localStorageKeys.currentUser, JSON.stringify(this.currentUser));
    }

    public isACommunityManager(): boolean {
        return this.isAdmin() || (this.getCommunitiesManagedByUser() && this.getCommunitiesManagedByUser().length > 0);
    }

    private getCurrentUserFromStorage(): AuthenticatedUser {
        return JSON.parse(localStorage.getItem(localStorageKeys.currentUser));
    }

    /**
     * Indicate that user can navigate on platform.
     * The user is authenticated, accepted the terms of use and completed his profil on hist first connection.
     */
    private isFullConnected(user: AuthenticatedUser): boolean {
        return user != null && !!user.accessToken && user?.termsOfUse && user.isOnBoardingProfileCompleted;
    }
}
