<!-- NAV ADMIN -->
<div class="container">
    <nav class="navbar navbar-expand-lg navbar-light subnav">
        <button
            class="navbar-toggler p-mt-2"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarToggler">
            <app-list-communities
                [communities]="communities"
                [selectedCommunityId]="accountsService.currentCommunity.id"
                (eventEmitter)="onChangeCommunity($event)"
            ></app-list-communities>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/community/description']">{{ 'community.pages.description.title' | translate }}</a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="createCommunitiesAvailable">
                    <a class="nav-link" [routerLink]="['/community/settings']" [state]="{ communities: communities }">{{
                        'community.nav.settings' | translate
                    }}</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/community/theme']">{{ 'community.nav.theme.title' | translate }}</a>
                </li>
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/community/members']">{{ 'community.nav.members.title' | translate }}</a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="createCommunitiesAvailable">
                    <a class="nav-link" [routerLink]="['/community/organizations']">{{ 'community.nav.organizations' | translate }}</a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="createCommunitiesAvailable">
                    <a class="nav-link" [routerLink]="['/community/projects']">{{ 'community.nav.projects' | translate }}</a>
                </li>
            </ul>
        </div>
    </nav>
</div>
<!-- /nav admin -->
