import { Injectable } from '@angular/core';
import { CreatePublicationCommand } from '../models/clubs/create-publication.command';
import { EditPublicationCommand } from '../models/clubs/edit-publication.command';
import { Publication } from '../models/clubs/publication.model';

@Injectable({
    providedIn: 'root',
})
export class PublicationHelper {
    public MAX_DEPTH = 3;

    public doesPostIdExistInPublicationsOrComments(postId: string, publications: Publication[]): boolean {
        for (const publication of publications) {
            if (publication.id === postId) {
                return true;
            }
            if (publication.comments && this.doesPostIdExistInPublicationsOrComments(postId, publication.comments)) {
                return true;
            }
        }
        return false;
    }

    public isElementVisible(element: HTMLElement): boolean {
        const elementRect = element.getBoundingClientRect();
        return (
            elementRect.top >= 0 &&
            elementRect.left >= 0 &&
            elementRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            elementRect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    public isPublicationMaxDepth(publication: Publication): boolean {
        return publication.depth >= this.MAX_DEPTH;
    }

    public static getFormData(command: CreatePublicationCommand | EditPublicationCommand): FormData {
        const formData = new FormData();
        formData.append('clubId', command.clubId);
        formData.append('userId', command.userId);
        formData.append('content', command.content);
        formData.append('picture', command.picture);
        if (command.attachedDocumentId) {
            formData.append('attachedDocumentId', command.attachedDocumentId);
        }

        if (command.attachedUrl) {
            formData.append('attachedUrl', command.attachedUrl);
        }

        if (command.parentPublicationId != undefined) formData.append('parentPublicationId', command.parentPublicationId);

        if ((command.mentionMembers || []).length > 0) {
            command.mentionMembers.forEach((mentionMember, index) => {
                formData.append(`mentionMembers[${index}][tagMember]`, mentionMember.tagMember);
                formData.append(`mentionMembers[${index}][userId]`, mentionMember.userId);
            });
        }

        formData.append('isAnAnnouncement', command.isAnAnnouncement ? String(command.isAnAnnouncement) : String(false));

        return formData;
    }
}
