import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { ReportPublicationCommand } from '../../../models/clubs/report-publication-command.model';
import { ReportEventCommand } from '../../../models/events/report-event-command.model';
import { ReportProjectCommand } from '../../../models/projects/report-project-command.model';
import { PublicationService } from '../../../services/clubs/publication.service';
import { EventsService } from '../../../services/events/events.service';
import { ProjectsService } from '../../../services/projects/projects.service';
@Component({
    selector: 'app-report-modal',
    templateUrl: './report-modal.component.html',
    styleUrls: ['./report-modal.component.scss'],
})
export class ReportModalComponent {
    public Report = 'Signaler';
    @Input() public entityId: string;
    @Input() public entityOwner: string;
    @Input() public entityType: 'publication' | 'project' | 'event';
    public isModalVisible = false;
    public reportContent: string;

    constructor(
        private publicationService: PublicationService,
        private projectsService: ProjectsService,
        private eventsService: EventsService,
        private readonly toastrHelper: ToastrHelper,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
    ) {}

    public closeModal() {
        this.isModalVisible = false;
    }

    public openModal() {
        this.isModalVisible = true;
    }

    public sendProjectReport() {
        this.projectsService.report(new ReportProjectCommand(this.entityId, this.reportContent)).subscribe({
            next: () => {
                this.showSuccessNotification();
                this.closeModal();
            },
        });
    }
    public sendEventReport() {
        this.eventsService.report(new ReportEventCommand(this.entityId, this.reportContent)).subscribe({
            next: () => {
                this.showSuccessNotification();
                this.closeModal();
                this.cdr.detectChanges();
            },
        });
    }

    public sendPublicationReport() {
        this.publicationService.report(new ReportPublicationCommand(this.entityId, this.reportContent)).subscribe({
            next: () => {
                this.showSuccessNotification();
                this.closeModal();
            },
        });
    }

    public sendReport() {
        if (this.entityType === 'publication') {
            this.sendPublicationReport();
        } else if (this.entityType === 'project') {
            this.sendProjectReport();
        } else if (this.entityType === 'event') {
            this.sendEventReport();
        }
    }

    public showSuccessNotification() {
        this.toastrHelper.showSuccess(this.translate.instant('shared.reportModal.reportPublished'));
    }
    public end() {
        this.showSuccessNotification();
        this.closeModal();
    }
}
