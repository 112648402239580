<div class="row">
    <!--
        ============================
        Gestion des compétences
        ============================
    -->
    <div class="col-12 mt-2" *ngIf="dataLoaded | async">
        <div class="row align-items-center">
            <div class="col-md-12 col-9" *ngIf="!hideTitle">
                <h2 class="h3 primary-1" [ngSwitch]="context">
                    <span *ngSwitchCase="'project'"> {{ 'projects.formStep3.skills.filterSkills' | translate }} </span>
                    <span *ngSwitchDefault> {{ 'projects.formStep3.skills.manageSkills' | translate }} </span>
                </h2>
            </div>
            <div class="col-auto d-block d-md-none ml-auto">
                <button
                    class="btn btn-add-remove add btn-primary mb-1"
                    data-toggle="modal"
                    data-target="#modalSkills"
                    type="button"
                >
                    <em>+</em>
                </button>
            </div>
        </div>

        <!-- Modal compétences (utilisé pour le mobile) -->
        <div
            class="modal fade bd-example-modal-lg"
            id="modalSkills"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalSkills"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <!-- Domaines de compétences -->
                    <div class="card-filter">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="h6 primary-1">
                                        {{ 'projects.formStep3.skills.skillsDomains' | translate }}
                                    </h3>
                                    <input
                                        type="search"
                                        [placeholder]="skillsDomainPlaceholder"
                                        class="form-control"
                                        (input)="onSkillDomainSearchTextChanged($event.target.value)"
                                    />
                                </div>
                            </div>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item" *ngFor="let skillDomain of visibleSkillDomains">
                                <input
                                    type="checkbox"
                                    aria-label="..."
                                    id="{{ skillDomain.key }}"
                                    [(ngModel)]="skillDomain.checked"
                                    (change)="onCheckedSkillDomain()"
                                />
                                <label for="{{ skillDomain.key }}" class="mb-0">{{ skillDomain.value }}</label>
                            </li>
                        </ul>
                    </div>
                    <!-- /domaines de compétences -->
                    <!-- Domaines de compétences -->
                    <div class="card-filter">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="h6 primary-1">{{ 'projects.formStep3.skills.skills' | translate }}</h3>
                                    <input
                                        type="search"
                                        [placeholder]="skillsPlaceHolder"
                                        class="form-control"
                                        (input)="onSkillSearchTextChanged($event.target.value)"
                                    />
                                </div>
                            </div>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item" *ngFor="let skill of visibleSkills">
                                <div class="row align-items-center">
                                    <div class="col-auto w-75">
                                        {{ skill.value }}
                                    </div>
                                    <div
                                        class="col-auto ml-auto"
                                        *ngIf="checkIfHasSkill(skill.key); else elseBlockSkills"
                                    >
                                        <button
                                            class="btn btn-primary btn-add-remove remove"
                                            type="button"
                                            (click)="removeSkill(skill.key)"
                                        >
                                            <em>-</em>
                                        </button>
                                    </div>
                                    <ng-template #elseBlockSkills>
                                        <div class="col-auto ml-auto">
                                            <button
                                                class="btn btn-primary btn-add-remove add"
                                                [disabled]="maximumSkills"
                                                type="button"
                                                (click)="addSkill(skill.key)"
                                            >
                                                <em>+</em>
                                            </button>
                                        </div>
                                    </ng-template>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- /domaines de compétences -->

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal" type="button">
                            {{ 'components.button.buttonClose' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /modal competences -->

        <div class="row">
            <!-- Selection competences -->
            <div class="col-md-8 d-none d-md-block">
                <div class="card box-shadow">
                    <div class="row no-gutters row-eq-height">
                        <!-- CENTRES DE COMPETENCES -->
                        <div class="col-6 gestion-selection">
                            <div class="card-body">
                                <h3 class="h6 primary-1">
                                    {{ 'projects.formStep3.skills.skillsDomains' | translate }}
                                </h3>
                                <input
                                    type="search"
                                    [placeholder]="skillsDomainPlaceholder"
                                    class="form-control"
                                    (input)="onSkillDomainSearchTextChanged($event.target.value)"
                                />
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item" *ngFor="let skillDomain of visibleSkillDomains">
                                    <input
                                        id="{{ skillDomain.key }}"
                                        type="checkbox"
                                        [(ngModel)]="skillDomain.checked"
                                        (change)="onCheckedSkillDomain()"
                                    />
                                    <label for="{{ skillDomain.key }}" class="mb-0">{{ skillDomain.value }}</label>
                                </li>
                            </ul>
                        </div>
                        <!-- /CENTRES DE COMPETENCES -->
                        <!-- COMPETENCES DISPONIBLES -->
                        <div class="col-md-6 col-12">
                            <div class="card-body">
                                <h3 class="h6 primary-1">
                                    {{ 'projects.formStep3.skills.availableSkills' | translate }}
                                </h3>
                                <input
                                    type="search"
                                    [placeholder]="skillsPlaceHolder"
                                    class="form-control"
                                    (input)="onSkillSearchTextChanged($event.target.value)"
                                />
                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item" *ngFor="let skill of visibleSkills">
                                    <div class="row align-items-center">
                                        <div class="col-auto w-75">
                                            {{ skill.value }}
                                        </div>
                                        <div
                                            class="col-auto ml-auto"
                                            *ngIf="checkIfHasSkill(skill.key); else elseBlockSkills"
                                        >
                                            <button
                                                class="btn btn-primary btn-add-remove add"
                                                type="button"
                                                [disabled]="true"
                                                (click)="addSkill(skill.key)"
                                            >
                                                <em>+</em>
                                            </button>
                                        </div>
                                        <ng-template #elseBlockSkills>
                                            <div class="col-auto ml-auto">
                                                <button
                                                    class="btn btn-primary btn-add-remove add"
                                                    type="button"
                                                    [disabled]="maximumSkills"
                                                    (click)="addSkill(skill.key)"
                                                >
                                                    <em>+</em>
                                                </button>
                                            </div>
                                        </ng-template>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- /COMPETENCES DISPONIBLES -->
                    </div>
                </div>
            </div>
            <!-- /selection compétences -->
            <!-- Mes compétences -->
            <div class="col-md-4">
                <div class="card card-project-active box-shadow">
                    <div class="card-body">
                        <h3 class="h6">
                            <ng-container [ngSwitch]="context">
                                <span *ngSwitchCase="'profile'">
                                    {{ 'projects.formStep3.skills.mySkills' | translate }}
                                </span>
                                <span *ngSwitchCase="'project'">
                                    {{ 'projects.formStep3.skills.searchedSkills' | translate }}
                                </span>
                                <span *ngSwitchDefault>
                                    {{ 'projects.formStep3.skills.selectedSkills' | translate }}
                                </span>
                            </ng-container>
                            <span class="float-right">{{ currentSkills?.length || 0 }}/{{ maxSkills }}</span>
                        </h3>
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngFor="let skill of currentSkills" [value]="skill.id">
                            <div class="row align-items-center">
                                <div class="col-auto w-75">
                                    {{ skill.displayName | wtranslate: 'skills' }}
                                </div>
                                <div class="col-auto ml-auto">
                                    <button
                                        class="btn btn-light btn-add-remove remove"
                                        type="button"
                                        (click)="removeSkill(skill.id)"
                                    >
                                        <em>-</em>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- /mes compétences -->
        </div>
    </div>
</div>
