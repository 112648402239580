<input
    #input
    type="file"
    hidden
    [attr.accept]="acceptedFormats ? acceptedFormats.join(',') : null"
    (change)="onFileChange($event)"
/>

<div appDragDrop (onFileDropped)="onFileDrop($event)" class="file-container">
    <ng-container *ngIf="!control?.value; else fileInformation">
        <div class="instruction instruction-bold">{{ 'components.imageCropModal.uploadFile' | translate }}</div>
        <div class="instruction">{{ 'components.imageCropModal.or' | translate }}</div>
        <button
            class="p-button p-button-rounded p-mx-auto p-button-sm"
            type="button"
            pButton
            pRipple
            (click)="input.click()"
            label="Ajouter un fichier"
        ></button>
        <div class="instruction instruction-sm instruction-bold p-mt-1">Format: csv</div>
    </ng-container>
</div>

<ng-template #fileInformation>
    <div class="p-d-flex p-flex-row p-m-auto">
        <div class="p-my-auto file-information">{{ control?.value?.name }}</div>
        <button
            type="button"
            pButton
            pRipple
            class="p-button p-button-sm p-button-rounded p-button-secondary p-button-text p-my-auto p-ml-1"
            icon="pi pi-times"
            (click)="clearFile()"
        ></button>
    </div>
    <div class="instruction instruction-sm instruction-bold p-mt-1 file-information">
        {{ control?.value?.size / 1000 }} Ko
    </div>
</ng-template>
