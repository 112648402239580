import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { trim } from 'jquery';
import { BehaviorSubject, Observable } from 'rxjs';
import { RetrieveThemeCommand } from '../../../models/identity/commands/retrieve-theme-command.model';
import { COMMUNITY_NAME_MAX_LENGTH, Community } from '../../../models/identity/community.model';
import { UpdateCommunityCommand } from '../../../models/identity/update-community-command.model';
import { Theme } from '../../../models/shared/theme.model';
import { CommunityService } from '../../../services/identity/community.service';
import { ThemeService } from '../../../services/shared/theme.service';
import { AccountsService } from './../../../services/identity/accounts.service';

@Component({
    selector: 'app-community-theme',
    templateUrl: './community-theme.component.html',
    styleUrls: ['./community-theme.component.scss'],
})
export class CommunityThemeComponent implements OnInit {
    private readonly _previewedTheme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(null);

    public communityNameForm = this.formBuilder.group({
        displayName: this.formBuilder.control(this.accountsService.currentCommunity.displayName, [
            Validators.required,
            Validators.maxLength(COMMUNITY_NAME_MAX_LENGTH),
        ]),
    });
    public displayPlatform: boolean;
    public externalLinksForm: FormArray;

    constructor(
        readonly formBuilder: FormBuilder,
        readonly accountsService: AccountsService,
        readonly themeService: ThemeService,
        readonly communityService: CommunityService,
    ) {}

    public get previewedTheme(): Theme {
        return this._previewedTheme.value;
    }

    get previewedTheme$(): Observable<Theme> {
        return this._previewedTheme.asObservable();
    }

    public changeCommunityInService(newCommunity: Community) {
        this.accountsService.changeCurrentCommunity(newCommunity);
    }

    public ngOnInit() {
        this.displayPlatform = true;

        this.themeService.retrieve(new RetrieveThemeCommand({ communityId: this.accountsService.currentCommunity.id })).subscribe((theme) => {
            this.handlePreviewedThemeUpdate(theme);
        });

        this.accountsService.currentCommunityChange.subscribe((community) => {
            this.themeService.retrieve(new RetrieveThemeCommand({ communityId: community.id })).subscribe((theme) => {
                this.handlePreviewedThemeUpdate(theme);
            });
        });
    }

    public onCommunityExternalLinksChange(externalLinksForm: FormArray) {
        this.externalLinksForm = externalLinksForm;
    }

    public onThemeChanged(theme: Theme) {
        this.handlePreviewedThemeUpdate(theme);
    }

    public switchPreview(value: boolean) {
        this.displayPlatform = value;
    }

    public updateCommunityName() {
        const oldCommunityName: string = this.accountsService.currentCommunity.displayName;
        const newCommunityName: string = trim(this.communityNameForm.controls.displayName.value);

        if (oldCommunityName != newCommunityName) {
            const command = new UpdateCommunityCommand(this.accountsService.currentCommunity);
            command.displayName = newCommunityName;

            this.communityService.updateCommunity(this.accountsService.currentCommunity.id, command).subscribe(() => {
                this.accountsService.currentCommunity.displayName = newCommunityName;
                this.accountsService.updateCommunityDisplayNameInLocalStorage(this.accountsService.currentCommunity.id, newCommunityName);
            });
        }
    }

    private handlePreviewedThemeUpdate(theme: Theme) {
        this.communityNameForm.controls.displayName.setValue(this.accountsService.currentCommunity.displayName);

        if (!theme.mailBannerUrl) {
            theme.mailBannerUrl = this.previewedTheme?.mailBannerUrl;
        }

        if (!theme.platformLogoUrl) {
            theme.platformLogoUrl = this.previewedTheme?.platformLogoUrl;
        }

        if (!theme.mailBannerUrl?.includes('blob:')) {
            theme.mailBannerUrl = theme.mailBannerUrl + '?' + new Date().getTime();
        }

        if (!theme.platformLogoUrl?.includes('blob:')) {
            theme.platformLogoUrl = theme.platformLogoUrl + '?' + new Date().getTime();
        }

        this._previewedTheme.next(theme);
        this.initializeExternalLinksForm(theme);
    }

    private initializeExternalLinksForm(theme: Theme) {
        this.externalLinksForm = this.formBuilder.array(
            theme.externalLinks.map((link) =>
                this.formBuilder.group({
                    name: [link.name, Validators.required],
                    url: [link.url, [Validators.required, Validators.pattern('https?://.+')]],
                }),
            ),
        );
    }
}
