import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AddressResult, GeoJsonPoint } from '../../../../azure/map/models/address.model';
import { AddressSearchService } from '../../../../azure/map/services/address.service';
import { regexPatterns } from '../../../../constants/regex-patterns';
import { ToastrHelper } from '../../../../helpers/toastr.helper';
import { GeographicalArea } from '../../../../models/identity/geographical-area.model';
import { User } from '../../../../models/identity/user.model';
import { AccountsService } from '../../../../services/identity/accounts.service';
import { GeographicalAreaService } from '../../../../services/identity/geographical-area.service';
import { UserInfosService } from '../../../../services/identity/user/user-infos.service';

@Component({
    selector: 'app-user-infos-form',
    templateUrl: './user-infos-form.component.html',
    styleUrls: ['./user-infos-form.component.scss'],
})
export class UserInfosFormComponent implements OnInit {
    public geographicalAreas: GeographicalArea[];
    selectedCountrySuggestion: any = null;
    @Input() public user: User;
    public userForm: FormGroup;

    constructor(
        private readonly accountService: AccountsService,
        private readonly formBuilder: FormBuilder,
        private readonly geographicalAreaService: GeographicalAreaService,
        private readonly toastrHelper: ToastrHelper,
        private readonly translate: TranslateService,
        private readonly userInfosService: UserInfosService,
        private readonly addressService: AddressSearchService,
    ) {
        this.userForm = this.formBuilder.group({
            jobTitle: [{ value: '' }, [Validators.maxLength(100)]],
            officePhone: [{ value: '' }, [Validators.maxLength(21), Validators.pattern(regexPatterns.phoneNumber)]],
            mobilePhone: [{ value: '' }, [Validators.maxLength(21), Validators.pattern(regexPatterns.phoneNumber)]],
            geographicalAreaId: [{ value: '' }, [Validators.required, Validators.maxLength(50)]],
            city: [{ value: '' }, [Validators.maxLength(50)]],
            location: this.formBuilder.group({
                type: ['Point'],
                coordinates: [[]],
            }),
            country: [{ value: '' }, [Validators.maxLength(50)]],
            linkedIn: [{ value: '' }, [Validators.maxLength(100), Validators.pattern(regexPatterns.LinkedInProfileUrl)]],
        });
    }

    public ngOnInit(): void {
        this.initializeUserForm();

        this.geographicalAreaService.getGeographicalAreas().subscribe(
            (geographicalAreas) => {
                this.geographicalAreas = geographicalAreas;
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
        );

        this.addressService.searchCountries(this.userForm.get('country').value).subscribe((countries) => {
            this.selectedCountrySuggestion = countries[0];
        });
    }

    private initializeUserForm() {
        this.userForm.controls.jobTitle.setValue(this.user.jobTitle);
        this.userForm.controls.officePhone.setValue(this.user.officePhone);
        this.userForm.controls.mobilePhone.setValue(this.user.mobilePhone);
        this.userForm.controls.geographicalAreaId.setValue(this.user.geographicalAreaId);
        this.userForm.controls.city.setValue(this.user.city);
        this.userForm.controls.country.setValue(this.user.country);
        this.userForm.controls.linkedIn.setValue(this.user.linkedIn);

        if (this.user?.location?.coordinates) {
            this.userForm.patchValue({
                location: this.user.location,
            });
        }
    }

    public async save(): Promise<boolean> {
        if (this.userForm.valid) {
            this.setPersonalInfos();

            try {
                await this.userInfosService.setPersonalInfos(this.user).toPromise();
                this.accountService.setUserPersonalInfos(this.user);
                this.accountService.saveCurrentUser();
                this.accountService.refreshCurrentUser();
                return true;
            } catch (error) {
                this.toastrHelper.showError(this.translate.instant('common.unknownError'));
                return false;
            }
        }
        return false;
    }

    private setPersonalInfos() {
        this.user.jobTitle = this.userForm.controls.jobTitle.value;
        this.user.officePhone = this.userForm.controls.officePhone.value;
        this.user.mobilePhone = this.userForm.controls.mobilePhone.value;
        this.user.geographicalAreaId = this.userForm.controls.geographicalAreaId.value;
        this.user.city = this.userForm.controls.city.value;
        this.user.country = this.userForm.controls.country.value;
        this.user.location = this.userForm.get('location').value;
        this.user.linkedIn = this.userForm.controls.linkedIn.value;
    }

    onCountrySelected(suggestion: any) {
        if (suggestion) {
            this.selectedCountrySuggestion = suggestion;
            this.userForm.get('city').setValue('');
        }
    }

    onCitySelected(city: AddressResult) {
        if (!city?.position?.lat || !city?.position?.lon) {
            return;
        }

        const geoJsonPoint: GeoJsonPoint = {
            type: 'Point',
            coordinates: [city.position.lon, city.position.lat],
        };

        this.userForm.patchValue({
            city: city.address.municipality,
            location: geoJsonPoint,
        });

        this.user.location = geoJsonPoint;
    }
}
