import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'wtranslate',
})
export class WudoTranslatePipe implements PipeTransform {
    readonly BASE_KEY: string = 'db.';

    /**
     * Pipe to use dynamic values from db and translate them
     */
    constructor(private translate: TranslateService) {}

    transform(content: string, selector: string): string {
        if (this.translate.currentLang.indexOf('fr') !== -1) {
            // leave text as is.
            return content;
        }

        const dynamicKey = this.getStringToKey(content);
        const key = this.BASE_KEY.concat(selector, '.', dynamicKey);

        return this.translate.instant(key);
    }

    private getStringToKey(content: string): string {
        const normalized = content.normalize('NFD');

        // 1. remove dots and special characters
        const withoutDot = normalized.replace(/\./g, '').replace('…', '');
        const withoutAccents = withoutDot.replace(/[\u0300-\u036f]/g, '');

        // 2. transform string to Camel case format
        const camelized = this.toCamelCase(withoutAccents);

        return camelized;
    }

    private toCamelCase(str: string): string {
        return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    }
}
