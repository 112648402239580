import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePasswordDefinitionComponent } from '../login/base-password-definition.component';
import { messages } from '../constants/messages';
import { PasswordHelper } from '../helpers/password.helper';
import { ToastrHelper } from '../helpers/toastr.helper';
import { ConfirmEmailCommand } from '../models/identity/commands/confirm-email-command.model';
import { AccountsService } from '../services/identity/accounts.service';
import { PasswordConst } from '../constants/password';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.scss'],
})
export class ActivateComponent extends BasePasswordDefinitionComponent implements OnInit {
    specialCharAuthorizedDisplayable = PasswordConst.specialCharAuthorizedDisplayable;

    constructor(
        public router: Router,
        private readonly route: ActivatedRoute,
        private readonly toastrHelper: ToastrHelper,
        private readonly accountsService: AccountsService,
    ) {
        super(router);
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => (this.token = params.get('token')));

        if (this.accountsService.isAuthenticated) {
            this.accountsService.logout(null, false);
        }

        this.accountsService.checkUserAccount(this.token, true).subscribe(
            () => {
                this.showPage = Promise.resolve(true);
            },
            () => {
                this.router.navigateByUrl('/');
            },
        );
    }

    onSavePassword() {
        this.submitted = true;
        this.showError = true;

        if (!this.form.valid) {
            this.submitted = false;
            if (this.password.invalid) {
                this.password.markAsTouched();
            }
            return;
        }

        this.showError = false;
        if (PasswordHelper.isPasswordValid(this.toastrHelper, this.password.value, this.confirmation.value, true)) {
            const command = {
                encryptedToken: this.token,
                activate: true,
                password: this.password.value,
            } as ConfirmEmailCommand;

            this.accountsService.confirmEmail(command).subscribe(
                () => {
                    this.isConfirmed = true;
                },
                (error) => {
                    this.submitted = false;
                    console.error(error);
                },
            );
        }
    }
}
