<div *ngIf="!hasError" class="center">
    <div class="spinner-border text-info">
        <span class="sr-only">Chargement en cours...</span>
    </div>
</div>

<div *ngIf="hasError" class="text-info" style="font-weight: bold">
    <a href="login">Votre connexion a échouée, cliquez ici pour revenir à la page de connexion.</a>
</div>

<ng-template #termsOfUseModal let-modal>
    <app-terms-of-use-modal [returnUrl]="returnUrl"></app-terms-of-use-modal>
</ng-template>
