import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrHelper } from '../../../../helpers/toastr.helper';
import { Club } from '../../../../models/clubs/club.model';
import { ClubsService } from '../../../../services/clubs/clubs.service';
import { AccountsService } from '../../../../services/identity/accounts.service';
import { ClubsStore } from '../../../clubs.store';

@Component({
    selector: 'app-delete-club-modal',
    templateUrl: './delete-club-modal.component.html',
    styleUrls: ['./delete-club-modal.component.scss'],
})
export class DeleteClubModalComponent {
    @Input()
    public clubToDelete: Club;
    @Input() public isClubMode: boolean;
    public isModalDeleteClubVisible = false;
    @Output()
    public shouldUpdateClubs = new EventEmitter<Club>();

    constructor(
        private readonly clubsStore: ClubsStore,
        private readonly clubService: ClubsService,
        private readonly toastrHelper: ToastrHelper,
        private accountsService: AccountsService,
        private translate: TranslateService,
    ) {}

    public closeModal() {
        this.isModalDeleteClubVisible = false;
    }

    public confirmClubDeletion() {
        this.clubService.deleteClub(this.clubToDelete.id).subscribe(
            () => {
                this.shouldUpdateClubs.emit(this.clubToDelete);
                this.isModalDeleteClubVisible = false;
            },
            () => {
                this.isModalDeleteClubVisible = false;
            },
        );
    }

    public openModal() {
        if (this.clubsStore.isAdminOfDisplayedClub) {
            if (
                this.clubToDelete != null &&
                (this.accountsService.isAdmin() || this.clubToDelete.parentClubId != null)
            ) {
                this.isModalDeleteClubVisible = true;
            }
        } else {
            const msg = this.isClubMode
                ? this.translate.instant('clubs.page.common.deleteClub.toast.unauthorizedRoomSettingsAccess')
                : this.translate.instant('clubs.page.common.deleteRoom.toast.unauthorizedRoomSettingsAccess');
            this.toastrHelper.showWarning(msg);
        }
    }
}
