import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from '../../environments/environment';
import { AdministrationGlobalComponent } from '../administration-global/administration-global.component';
import { ListProjectComponent } from '../administration-global/pages/projects/list-project/list-project.component';
import { AdministrationOrganizationsComponent } from '../administration-organizations/administration-organizations.component';
import { CommunityComponent } from '../community/community.component';
import { CommunityMembersComponent } from '../community/pages/community-members/community-list-members/community-members.component';
import { CommunityOrganizationsComponent } from '../community/pages/community-organizations/community-organizations.component';
import { CommunitySettingsComponent } from '../community/pages/community-settings/community-settings.component';
import { CommunityThemeComponent } from '../community/pages/community-theme/community-theme.component';
import { EditProjectComponent } from '../community/pages/projects/edit-project/edit-project.component';
import { userTypes } from '../constants/permissions';
import { DirectoryComponent } from '../directory/directory.component';
import { EventsComponent } from '../events/events.component';
import { CreateEventComponent } from '../events/pages/create-event/create-event.component';
import { EditEventComponent } from '../events/pages/edit-event/edit-event.component';
import { MyEventsListComponent } from '../events/pages/my-events-list/my-events-list.component';
import { UserPermissions } from '../models/identity/user-permission.model';
import { CreateProjectComponent } from '../projects/pages/create-project/create-project.component';
import { ProjectsComponent } from '../projects/projects.component';
import { AccountsService } from '../services/identity/accounts.service';
import { PermissionsService } from '../services/shared/permissions.service';
import { PermissionsStateModel } from '../states/permissions/permissions.state';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly accountsService: AccountsService,
        private readonly permissionsService: PermissionsService,
        private readonly store: Store,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.accountsService.isAuthenticated) {
            const queryParams: any = { returnUrl: state.url };

            // read community from route for sso
            const community = route.queryParams['community'];
            if (community) {
                queryParams['community'] = community;
            }

            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], {
                queryParams: queryParams,
            });
            return false;
        }

        if (!this.accountsService.currentUser.termsOfUse) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], {
                queryParams: { returnUrl: state.url },
            });
            return false;
        }

        if (!this.accountsService.currentUser.isOnBoardingProfileCompleted) {
            // profile is not complete so redirect to onboarding page!
            this.router.navigate(['/onboarding'], {
                queryParams: { returnUrl: state.url },
            });
            return false;
        }

        // Check access permissions according to roles and rights of the current user
        if (!this.hasAccess(route.parent.component, route.component)) {
            this.router.navigate(['/']);
            return false;
        }

        return true;
    }

    /**
     * Define access rules of component (module), according to permissions (role or rights) of the current user.
     *
     * @param component current component
     */
    private hasAccess(component: any | string | null, childComponent: any | string | null) {
        const permissions = this.store.selectSnapshot<PermissionsStateModel>((state) => state.permissions)?.permissions || new UserPermissions();

        if (permissions.admin) {
            return true;
        }

        switch (component) {
            case AdministrationGlobalComponent:
                return this.permissionsService.role === userTypes.admin;
            case AdministrationOrganizationsComponent:
                return permissions.canEditOrganization;
            case DirectoryComponent:
                return permissions.canViewContactsDirectory;

            case ProjectsComponent:
            case EventsComponent:
                const isManagingCommunityOrAdmin =
                    this.accountsService.isAdmin() || this.accountsService.isUserManagingCommunity(this.accountsService.currentUser.primaryCommunity);
                switch (childComponent) {
                    case CreateProjectComponent:
                        return this.accountsService.currentUser.primaryCommunity.canCreateProject || isManagingCommunityOrAdmin;
                    case MyEventsListComponent:
                    case CreateEventComponent:
                    case EditEventComponent:
                        return this.accountsService.currentUser.primaryCommunity.canCreateEvent || isManagingCommunityOrAdmin;
                }
                break;

            case CommunityComponent:
                switch (childComponent) {
                    case ListProjectComponent:
                    case EditProjectComponent:
                        return permissions.canAccessCommunityManagementComponents;
                    case CommunitySettingsComponent:
                    case CommunityMembersComponent:
                    case CommunityOrganizationsComponent:
                    case CommunityThemeComponent:
                    case CommunityComponent:
                        return (
                            (environment.features.backOfficeAdminFeaturesAvailable.access && this.accountsService.isAdmin()) ||
                            (environment.features.backOfficeCommunityManagerFeaturesAvailable.access &&
                                permissions.canAccessCommunityManagementComponents)
                        );
                }
        }

        return true;
    }
}
