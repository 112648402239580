<create-club-modal-button [isEditMode]="true" [club]="isEditMode ? club : null"> </create-club-modal-button>

<create-room-modal-button (shouldUpdateRooms)="roomCreation($event)" [isEditMode]="isEditMode" [club]="isEditMode ? club : null">
</create-room-modal-button>

<app-delete-club-modal (shouldUpdateClubs)="clubDeleted($event)" [clubToDelete]="club" [isClubMode]="isClubMode"> </app-delete-club-modal>

<div id="custom-edit-club" class="custom-edit-club" [ngClass]="isClubMode ? 'translatex-club' : 'translatex-room'">
    <button
        pButton
        pRipple
        class="p-button-rounded p-button-text p-button-plain custom-gear"
        icon="icon-f-settings"
        (click)="menu.toggle($event)"
    ></button>
    <p-menu id="menu" #menu appendTo="body" [popup]="true" [model]="items" styleClass="custom-edit-club-menu custom-edit-club"></p-menu>
</div>
