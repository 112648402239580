<div class="platform-theme-preview">
    <div class="topbar-preview">
        <img class="platform-logo-preview" [src]="theme.platformLogoUrl | safe: 'url'" />
        <div class="topbar-menu-preview">
            <ng-container *ngFor="let item of [true, false, false, false]">
                <div class="topbar-menu-item-preview-container">
                    <div [style.color]="item ? theme.primaryColor : '#a9a9a9'" class="topbar-menu-item-label">Menu</div>
                    <div
                        class="topbar-menu-item-indicator-preview"
                        [style.background-color]="item ? theme.secondaryColor : 'transparent'"
                    ></div>
                </div>
            </ng-container>
        </div>
        <div class="topbar-avatar-preview"></div>
    </div>
    <div class="body-preview">
        <div class="club-menu-preview-container">
            <ng-container *ngFor="let item of [0, 0, 0, 0]">
                <div class="club-item-preview"></div>
            </ng-container>
        </div>
        <div class="section1-preview">
            <div class="section1-title-preview" [style.color]="theme.primaryColor">Titre</div>
            <div class="button-container-preview">
                <div
                    class="button-preview"
                    [style.color]="theme.button2TextAndBorderColor"
                    [style.border-color]="theme.button2TextAndBorderColor"
                >
                    Bouton 2
                </div>
                <div
                    class="button-preview"
                    [style.color]="theme.button1TextColor"
                    [style.border-color]="theme.primaryColor"
                    [style.background-color]="theme.primaryColor"
                >
                    Bouton 1
                </div>
            </div>
        </div>
        <div class="section2-preview">
            <div class="section-2-menu-preview">
                <ng-container *ngFor="let item of [false, false, true, false]">
                    <div
                        class="section2-menu-item"
                        [style.border-color]="item ? theme.secondaryColor : 'none'"
                        [ngClass]="{ active: item }"
                    >
                        Menu
                    </div>
                </ng-container>
            </div>
            <ng-container *ngFor="let item of [0, 0]">
                <div class="card-preview">
                    <p-skeleton height="60px" width="100px" borderRadius="8px"></p-skeleton>
                    <div class="card-content-preview">
                        <div [style.background-color]="theme.primaryColor" class="line-preview1"></div>
                        <div class="line-preview2"></div>
                        <p-skeleton
                            styleClass="p-mt-1 p-mr-auto"
                            width="90%"
                            height="5px"
                            borderRadius="8px"
                        ></p-skeleton>
                        <p-skeleton
                            styleClass="p-mt-1 p-mr-auto"
                            width="80%"
                            height="5px"
                            borderRadius="8px"
                        ></p-skeleton>
                        <div
                            class="card-button-preview"
                            [style.color]="theme.button1TextColor"
                            [style.border-color]="theme.primaryColor"
                            [style.background-color]="theme.primaryColor"
                        ></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
