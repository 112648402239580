import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,ViewEncapsulation  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { AccountsService } from '../../../services/identity/accounts.service';
import { DeleteClubModalComponent } from '../../club-page/common/delete-club-modal/delete-club-modal.component';
import { CreateClubModalButtonComponent } from '../create-club-modal-button/create-club-modal-button.component';
import { Club } from './../../../models/clubs/club.model';
import { CreateRoomButtonModal } from './../../club-page/common/create-room-modal-button/create-room-modal-button.component';

@Component({
    selector: 'app-gear-edit-club',
    templateUrl: './gear-edit-club.component.html',
    styleUrls: ['./gear-edit-club.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GearEditClubComponent implements OnInit, OnChanges {
    @Input() public club: Club;
    @ViewChild(CreateClubModalButtonComponent)
    public createClubButtonModal: CreateClubModalButtonComponent;
    @ViewChild(CreateRoomButtonModal)
    public createRoomButtonModal: CreateRoomButtonModal;
    public createRoomItem: MenuItem;
    @ViewChild(DeleteClubModalComponent)
    public deleteClubButtonModal: DeleteClubModalComponent;
    @Input() public isClubMode: boolean;
    public isEditMode = false;
    public items: MenuItem[] = [];
    @Output()
    public onClubDeleted = new EventEmitter<Club>();
    @Output()
    public onRoomCreation = new EventEmitter();

    constructor(private translate: TranslateService, public accountsService: AccountsService, private readonly toastrService: ToastrService) {}

    public clubDeleted(clubToDelete: Club) {
        this.onClubDeleted.emit(clubToDelete);
    }

    public initMenuItems() {
        // TODO factoriser la création de MenuItem!
        this.items = [];

        if (this.isClubMode) {
            const updateClubItem: MenuItem = {
                label: this.translate.instant('clubs.gearEdit.clubParameters'),
                icon: 'icon-f-settings',
                command: () => {
                    this.isEditMode = true;
                    setTimeout(() => {
                        this.createClubButtonModal.openModal();
                    }, 300);
                },
            };
            this.items.push(updateClubItem);

            const deleteItem: MenuItem = {
                label: this.translate.instant('clubs.gearEdit.deleteClub'),
                icon: 'pi pi-trash',
                command: () => {
                    this.isEditMode = true;
                    if (this.club.communityId == null)
                        setTimeout(() => {
                            this.deleteClubButtonModal.openModal();
                        }, 300);
                    else this.toastrService.error(this.translate.instant('clubs.page.common.deleteClub.toast.communityClubCanNotBeDeleted'));
                },
                styleClass: 'menuItemDelete',
            };
            this.accountsService.isAdmin() ? this.items.push(deleteItem) : null;
        }

        if (!this.isClubMode) {
            const settingsItem: MenuItem = {
                label: this.translate.instant('clubs.gearEdit.roomParameters'),
                icon: 'icon-f-settings',
                command: () => {
                    this.isEditMode = true;
                    setTimeout(() => {
                        this.createRoomButtonModal.openModal();
                    }, 300);
                },
            };

            const deleteItem: MenuItem = {
                label: this.translate.instant('clubs.gearEdit.deleteRoom'),
                icon: 'pi pi-trash',
                command: () => {
                    this.isEditMode = true;
                    setTimeout(() => {
                        this.deleteClubButtonModal.openModal();
                    }, 300);
                },
                styleClass: 'menuItemDelete',
            };

            if (this.club.parentClubId !== null) {
                this.items.push(settingsItem);
                this.items.push(deleteItem);
            }
        }

        if (!this.club.parentClubId) {
            this.createRoomItem = {
                label: this.translate.instant('clubs.gearEdit.createRoom'),
                icon: 'icon-f-chat',
                command: () => {
                    this.isEditMode = false;
                    setTimeout(() => {
                        this.createRoomButtonModal.openModal();
                    }, 300);
                },
            };
            this.items.push(this.createRoomItem);
        }
    }

    //Called every time @Input() club is updated
    public ngOnChanges(changes: SimpleChanges) {
        this.initMenuItems();
    }

    public ngOnInit(): void {
        this.initMenuItems();
    }

    public roomCreation(newRoom: Club) {
        this.onRoomCreation.emit(newRoom);
    }
}
