import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerHelper } from '../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { CreateProjectCommand } from '../../../models/projects/create-project-command.model';
import { ProjectType } from '../../../models/projects/project-type.model';
import { CommunityService } from '../../../services/identity/community.service';
import { ProjectTypesService } from '../../../services/projects/project-types.service';
import { ProjectsService } from '../../../services/projects/projects.service';
import { ProjectFormStepOneComponent } from '../../components/project-form-step-one/project-form-step-one.component';
import { ProjectFormStepThreeComponent } from '../../components/project-form-step-three/project-form-step-three.component';
import { ProjectFormStepTwoComponent } from '../../components/project-form-step-two/project-form-step-two.component';
@Component({
    selector: 'app-create-project',
    templateUrl: './create-project.component.html',
})
export class CreateProjectComponent implements OnInit {
    @ViewChild('projectFormStepOne', { static: false })
    private projectFormStepOne: ProjectFormStepOneComponent;
    @ViewChild('projectFormStepThree', { static: false })
    private projectFormStepThree: ProjectFormStepThreeComponent;
    @ViewChild('projectFormStepTwo', { static: false })
    private projectFormStepTwo: ProjectFormStepTwoComponent;

    public canCreateAllCommunitiesProject = false;
    public createProjectCommand: CreateProjectCommand;
    public currentStep: number;
    public projectTypes: ProjectType[] = [];

    constructor(
        private readonly projectService: ProjectsService,
        private readonly projectTypeService: ProjectTypesService,
        private readonly toastrHelper: ToastrHelper,
        private readonly spinnerHelper: SpinnerHelper,
        private readonly communityService: CommunityService,
        private readonly router: Router,
        private translate: TranslateService,

        private location: Location,
    ) {}

    public goBack() {
        if (window.history.length > 1) {
            this.location.back();
        } else {
            this.router.navigate(['/projects']);
        }
    }

    public goToNextStep() {
        this.currentStep++;
        window.scrollTo(0, 0);
    }

    public goToPreviousStep() {
        this.currentStep--;
        window.scrollTo(0, 0);
    }

    public ngOnInit() {
        this.createProjectCommand = new CreateProjectCommand();
        this.currentStep = 1;
        this.projectTypeService.getProjectTypes().subscribe((result) => (this.projectTypes = result));

        this.communityService.checkIfCurrentUserIsIntoEnclosedCommunity().subscribe(({ visibility }) => {
            this.canCreateAllCommunitiesProject = visibility;
        });
    }

    public onPublishRequestClick() {
        if (this.currentStep === 3) {
            this.projectFormStepThree.onPublishRequestClick();
        }
    }

    public onSave() {
        this.updateProjectCommandFromStepComponents();
        this.spinnerHelper.showSpinner();
        this.projectService.draftProject(null, this.createProjectCommand).subscribe(
            (_) => {
                const msg = this.translate.instant('projects.pages.createProject.toast.projectSaved');
                this.toastrHelper.showSuccess(msg);
                setTimeout(() => {
                    this.router.navigate(['profile', 'my-projects'], {
                        fragment: 'draft',
                    });
                }, 2000);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }

    public onSaveDraft() {
        switch (this.currentStep) {
            case 1: {
                this.projectFormStepOne.openConfirmePopupSaveDraft();
                break;
            }
            case 2: {
                this.projectFormStepTwo.openConfirmePopupSaveDraft();
                break;
            }
            case 3: {
                this.projectFormStepThree.openConfirmePopupSaveDraft();
                break;
            }
        }
    }

    public onStepNext() {
        switch (this.currentStep) {
            case 1: {
                this.projectFormStepOne.onSubmit();
                break;
            }
            case 2: {
                this.projectFormStepTwo.onSubmit();
                break;
            }
            case 3: {
                this.projectFormStepThree.onSubmit(false);
                break;
            }
        }
    }

    public onStepOneSubmit() {
        this.goToNextStep();
    }

    public onStepThreeSubmit() {
        this.updateProjectCommandFromStepComponents();
        this.spinnerHelper.showSpinner();
        this.projectService.createAndPublishProject(this.createProjectCommand).subscribe(
            (_) => {
                const msg = this.translate.instant('projects.pages.createProject.toast.projectPublished');
                this.toastrHelper.showSuccess(msg);
                setTimeout(() => {
                    this.router.navigate(['profile', 'my-projects'], {
                        fragment: 'published',
                    });
                }, 2000);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }

    public onStepTwoSubmit() {
        this.goToNextStep();
    }

    public onWizardStepOne() {
        this.updateProjectCommandFromStepComponents();
        this.currentStep = 1;
        window.scrollTo(0, 0);
    }

    public onWizardStepThree() {
        this.projectFormStepTwo.onSubmit();
    }

    public onWizardStepTwo() {
        if (this.currentStep > 2) {
            this.goToPreviousStep();
        } else {
            this.projectFormStepOne.onSubmit();
        }
    }

    public updateProjectCommandFromStepComponents() {
        this.projectFormStepOne.updateProjectCommandFromStepOneComponent();
        this.projectFormStepTwo.updateProjectCommandFromStepTwoComponent();
    }
}
