import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ClubRoles } from '../../models/clubs/club-roles.model';
import { ClubMembers } from '../../models/clubs/club.model';
import { UpdateMembersCommand } from '../../models/clubs/update-members.command';
import { Member } from './../../models/clubs/member.model';

@Injectable({
    providedIn: 'root',
})
export class MemberApiService {
    private readonly addRole = `${environment.microservices.clubs.memberApi.addRole}`;
    private readonly clubsApiUrl = `${environment.microservices.clubs.baseUrl}/${environment.microservices.clubs.clubsApi.baseUrl}`;
    private readonly memberApiUrl = `${environment.microservices.clubs.baseUrl}/${environment.microservices.clubs.memberApi.baseUrl}`;
    private readonly membersApiUrl = `${environment.microservices.clubs.clubsApi.members}`;
    private readonly removeRole = `${environment.microservices.clubs.memberApi.removeRole}`;

    constructor(private http: HttpClient) {}

    public addClubRole(clubId: string, user: Member, role: ClubRoles) {
        const member = new Array<Member>();
        const room = user.clubs.find((club) => club.id == clubId);
        if (room) room.role = role;
        else {
            user.clubs.push({
                id: clubId,
                role: role,
                lastVisitDate: new Date(),
            });
        }

        member.push(user);
        const command = new UpdateMembersCommand();
        command.MembersToUpdate = member;
        return this.http.post(`${this.clubsApiUrl}/${clubId}/${this.membersApiUrl}/${this.addRole}`, command);
    }

    public completePresentation(memberId: string) {
        return this.http.post(`${this.memberApiUrl}/${memberId}/complete-presentation`, null);
    }

    public deleteClubRole(clubId: string, user: Member) {
        const member = new Array<Member>();
        member.push(user);
        const command = new UpdateMembersCommand();
        command.MembersToUpdate = member;
        return this.http.post(`${this.clubsApiUrl}/${clubId}/${this.membersApiUrl}/${this.removeRole}`, command);
    }

    public getMemberById(id: string): Observable<Member> {
        return this.http.get<Member>(`${this.memberApiUrl}/${id}`);
    }

    public getMembersByClubId(clubId: string): Observable<Array<ClubMembers>> {
        return this.http.get<Array<ClubMembers>>(`${this.clubsApiUrl}/${clubId}/${this.membersApiUrl}`);
    }

    public removeMemberInClub(clubId: string, memberId: string) {
        return this.http.post(`${this.clubsApiUrl}/${clubId}/${this.membersApiUrl}/${memberId}`, null);
    }

    public updateMemberAccessDate(memberId: string, clubId: string) {
        return this.http.put<Member>(`${this.memberApiUrl}/${memberId}/club/${clubId}`, null);
    }

    public updateMembers(clubId: string, membersToAdd: Array<Member>): Observable<Array<Member>> {
        const command = new UpdateMembersCommand();
        command.MembersToUpdate = membersToAdd;
        return this.http.put<Array<Member>>(`${this.clubsApiUrl}/${clubId}/${this.membersApiUrl}`, command);
    }
}
