import { Injectable } from '@angular/core';
import { Filters } from '../../models/identity/filters.model';
import { ProjectFilters } from '../../models/projects/project-filters.model';
import { OrganizationFilters } from '../../models/identity/organization-filters.model';
import { EventFilters } from '../../models/events/event-filters.model';
import { MyEventsFilters } from '../../models/events/my-event-filters.model';

@Injectable()
export class DataStorageService {
    organizationsFilters: OrganizationFilters = null;
    membersFilters: Filters = null;
    projectsFilters: ProjectFilters = null;
    eventFilters: EventFilters = null;
    myEventsFilters: MyEventsFilters = null;
}
