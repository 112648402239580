import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Club } from '../../models/clubs/club.model';
import { CreateClubCommand } from '../../models/clubs/create-club-command.model';
import { UpdateClubCommand } from '../../models/clubs/update-club-command.model';
import { ClubActivity, ClubNewActivityResponse } from './../../models/clubs/club-activity.model';

@Injectable({
    providedIn: 'root',
})
export class ClubsService {
    private addCoverPictureApiUrl = `${environment.microservices.clubs.clubsApi.addCoverPicture}`;
    private addProfilePictureApiUrl = `${environment.microservices.clubs.clubsApi.addProfilePicture}`;
    private clubsApiUrl = `${environment.microservices.clubs.baseUrl}/${environment.microservices.clubs.clubsApi.baseUrl}`;
    private getNewActivity = `${environment.microservices.clubs.clubsApi.getNewActivity}`;

    constructor(private http: HttpClient, private router: Router) {}

    public addCoverPicture(id: string, picture: File): Observable<any> {
        const formData = new FormData();
        formData.append('picture', picture);

        return this.http.post(`${this.clubsApiUrl}/${id}/${this.addCoverPictureApiUrl}`, formData);
    }

    public addProfilePicture(id: string, picture: File): Observable<any> {
        const formData = new FormData();
        formData.append('picture', picture);

        return this.http.post(`${this.clubsApiUrl}/${id}/${this.addProfilePictureApiUrl}`, formData);
    }

    public clubNameExists(name: string): Observable<boolean> {
        return this.http.head(`${this.clubsApiUrl}?name=${name}`).pipe(
            map(() => true),
            catchError(() => of(false)),
        );
    }

    public createClub(command: CreateClubCommand): Observable<Club> {
        return this.http.post<Club>(this.clubsApiUrl, command);
    }

    public deleteClub(clubIdToDelete: string): Observable<void> {
        return this.http.delete<void>(`${this.clubsApiUrl}/${clubIdToDelete}`);
    }

    public getAllClubsActivityForMember(): Observable<ClubNewActivityResponse> {
        return this.http.get<ClubNewActivityResponse>(`${this.clubsApiUrl}/${this.getNewActivity}`);
    }

    public getAllRoomsActivityByClubId(clubId: string): Observable<ClubActivity[]> {
        return this.http.get<ClubActivity[]>(`${this.clubsApiUrl}/${clubId}/${this.getNewActivity}`);
    }

    public getClubById(id: string): Observable<Club> {
        return this.http.get<Club>(`${this.clubsApiUrl}/${id}`);
    }

    public updateClub(command: UpdateClubCommand): Observable<Club> {
        return this.http.put<Club>(`${this.clubsApiUrl}`, command);
    }

    public openProject(club: Club) {
        if (club?.projectId) {
            this.router.navigate(['/projects', club.projectId]);
        } else {
            console.error('Project ID is missing for this club.');
        }
    }
}
