<span *ngIf="pictureAccessLink; else noPhotoBlock">
    <img
        [src]="pictureAccessLink"
        alt="organizationName"
        id="profilePic"
        [routerLink]="routerLink"
        class="img-pointer avatar"
        [ngClass]="{ 'img-pointer': routerLink }"
    />
</span>
<ng-template #noPhotoBlock>
    <span
        class="avatar no-photo"
        [attr.title]="organizationName"
        [style.color]="getColorFromString(organizationName, 'darken')"
        [style.background-color]="getColorFromString(organizationName, 'lighten')"
        [ngClass]="{ 'img-pointer': routerLink }"
        >{{ this.initials }}</span
    >
</ng-template>
