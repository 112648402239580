import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UpdateRecipientRequest } from '../../../models/notifications/commands/update-recipient-request.interface';
import { RecipientsService } from '../../../services/Notifications/recipients.service';
import { AccountsService } from '../../../services/identity/accounts.service';
import { MailGroupMappingService } from './mail-group-mapping.service';
import { MailGroup } from './mail-group.enum';
@Component({
    selector: 'app-notification-config',
    templateUrl: './notification-config.component.html',
    styleUrls: ['./notification-config.component.scss'],
})
export class NotificationConfigComponent implements OnInit {
    @Input() visible = false;
    @Output() visibleChange = new EventEmitter<boolean>();
    public notificationsConfigModalVisible: boolean;
    canActivateNewsletter: boolean;
    acceptedNotifications: MailGroup[] = [];
    isAnnouncementChecked: boolean;
    isNewsLetterChecked: boolean;
    isClubNotificationsChecked: boolean;
    mailGroupEnum = MailGroup;
    isProjectNotificationsChecked: boolean;
    isEventNotificationsChecked: boolean;

    constructor(
        private readonly recipientService: RecipientsService,
        private readonly accountsService: AccountsService,
        private readonly mailGroupMappingService: MailGroupMappingService,
    ) {}

    ngOnInit() {
        this.recipientService.getRecipient(this.accountsService.currentUser.userId).subscribe((recipient) => {
            this.acceptedNotifications = recipient.acceptedNotifications.map((notification) =>
                this.mailGroupMappingService.mapMailGroup(notification),
            );
            this.initializeSwitches();
        });

        this.canActivateNewsletter = this.accountsService.currentUser.communities.some((community) => community.hasActivateIntelligentNewsLetter);
    }
    initializeSwitches() {
        this.isAnnouncementChecked = this.acceptedNotifications.includes(MailGroup.AnnouncementGroup);
        this.isNewsLetterChecked = this.acceptedNotifications.includes(MailGroup.NewsletterGroup);
        this.isClubNotificationsChecked = this.acceptedNotifications.includes(MailGroup.ClubGroup);
        this.isProjectNotificationsChecked = this.acceptedNotifications.includes(MailGroup.ProjectGroup);
        this.isEventNotificationsChecked = this.acceptedNotifications.includes(MailGroup.EventGroup);
    }

    toggleModal(): void {
        this.visible = !this.visible;
        this.visibleChange.emit(this.visible);
    }

    onInputSwitchChange(event: any, mailGroup: MailGroup): void {
        if (event.checked) {
            this.acceptedNotifications.push(mailGroup);
        } else {
            this.acceptedNotifications = this.acceptedNotifications.filter((n) => n !== mailGroup);
        }
    }

    save(): void {
        const request: UpdateRecipientRequest = {
            clubGroup: this.isClubNotificationsChecked,
            announcementGroup: this.isAnnouncementChecked,
            newsletterGroup: this.isNewsLetterChecked,
            eventGroup: this.isEventNotificationsChecked,
            projectGroup: this.isProjectNotificationsChecked,
        };

        this.recipientService.updateRecipient(this.accountsService.currentUser.userId, request).subscribe(() => {
            this.toggleModal();
        });
    }
}
