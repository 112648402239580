<div class="d-flex align-items-center w-100">
    <p-autoComplete
        placeholder="{{ 'home.onboardingProfile.skills.search' | translate }}"
        field="name"
        scrollHeight="250px"
        [style]="{ width: '350px' }"
        [(ngModel)]="selectedSkill"
        [virtualScroll]="true"
        [suggestions]="filteredSkills"
        [dropdown]="true"
        [disabled]="user.skills.length === 10"
        [itemSize]="34"
        (onSelect)="addSkill()"
        (completeMethod)="filterSkills($event)"
    >
    </p-autoComplete>
    <span
        pTooltip="{{ 'home.onboardingProfile.skills.reason' | translate }}"
        tooltipPosition="top"
        class="custom-tooltip margin-left-auto link underline"
    >
        {{ 'home.onboardingProfile.whyImportant' | translate }}
    </span>
</div>

<div class="selected-skills-input p-p-1 p-mt-2">
    <div class="selected-skill-tag" *ngFor="let skill of user.skills; let i = index">
        {{ skill.displayName | wtranslate : 'skills' }}
        <i class="pi pi-times-circle remove-skill" (click)="removeSkill(i)"></i>
    </div>
</div>

<div class="subtitle p-mt-5 p-mb-2">
    {{ 'user.skills.topCommunitySkill' | translate }}
    <a class="link" (click)="showSelectSkillsModal()">{{ 'user.skills.linked' | translate }}</a>
</div>

<app-toggle-button-list [items]="topTenSkills" [selectedItems]="user.skills" [translatorKeyValue]="'skills'" (itemToggled)="toggleSkill($event)">
</app-toggle-button-list>

<p-dialog
    header="Header"
    [(visible)]="isSelectSkillsModalVisible"
    [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [style]="{ width: '50vw', 'max-height': '700px' }"
    appendTo="body"
>
    <ng-template pTemplate="header">
        <span class="font-weight-bold">{{ 'user.skills.linked' | translate }}</span>
    </ng-template>

    <ng-container *ngIf="user.skills.length > 0; else emptySkills">
        <app-grouped-button-list
            [items]="filteredAvailableSkills"
            [selectedItems]="user.skills"
            [translatorKeyValue]="'skills'"
            [translatorKeyDomain]="'skillDomains'"
            (itemToggled)="toggleValue($event)"
        ></app-grouped-button-list>
    </ng-container>
    <ng-template #emptySkills>
        <div class="empty-skills-message">
            <p class="text-center mb-3">{{ 'user.skills.noSkillSelected' | translate }}</p>
            <p class="text-center">{{ 'user.skills.pleaseSelectSkill' | translate }}</p>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button class="p-button" (click)="isSelectSkillsModalVisible = false">
            {{ 'components.button.buttonClose' | translate }}
        </button>
    </ng-template>
</p-dialog>
