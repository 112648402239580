import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClubMember } from '../../../models/clubs/club-member.model';
import { Community } from '../../../models/identity/community.model';
import { CommunityService } from '../../../services/identity/community.service';

@Component({
    selector: 'app-preview-community',
    templateUrl: './preview-community.component.html',
    styleUrls: ['./preview-community.component.scss'],
})
export class PreviewCommunityComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    public clubCreationDate?: Date;
    public clubLogoUrl?: string;
    public clubMembers?: ClubMember[];
    @Input() public community: Community;
    public isGenericLink = false;
    public isPdf = false;
    public isYouTubeVideo = false;
    public mainspring: string;
    public pdfModalVisible = false;
    public showDialogSubscription: Subscription;
    public videoSafeUrl: SafeResourceUrl;
    public visible = false;

    constructor(
        private readonly communityService: CommunityService,
        private readonly sanitizer: DomSanitizer,
        private readonly translate: TranslateService,
    ) {}

    public closeDialog() {
        this.visible = false;
        this.communityService.closePreviewCommunity();
    }

    public detectMediaType() {
        const link = this.community?.communityLink || '';
        if (link && link !== '') {
            this.isYouTubeVideo = link.includes('youtube.com/watch');
            this.isPdf = link.endsWith('.pdf');
            this.isGenericLink = !this.isYouTubeVideo && !this.isPdf;
            this.setVideoSafeUrl(link);
        }
    }

    public loadCommunityInfo() {
        if (!this.community?.id) return;

        this.communityService.getClubInfoByCommunityId(this.community.id).subscribe({
            next: ({ profilePictureUrl, creationDate, membersCount }) => {
                this.clubLogoUrl = profilePictureUrl;
                this.clubCreationDate = creationDate;
                this.clubMembers = membersCount;
            },
        });
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.showDialogSubscription.unsubscribe();
    }

    public ngOnInit() {
        this.subscribeToDialogEvents();
    }

    public setMainspring() {
        if (this.community?.mainspring) {
            this.mainspring = this.community.mainspring;
        } else {
            this.mainspring = this.translate.instant('community.pages.description.memberEmptyMainspring');
        }
    }

    public setVideoSafeUrl(link: string) {
        const video_id = link.split('v=')[1].substring(0, 11);

        const embedUrl = `https://www.youtube.com/embed/${video_id}`;

        this.videoSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    }

    public showDialog() {
        this.visible = true;
    }

    public subscribeToDialogEvents() {
        this.showDialogSubscription = this.communityService.showDescriptionModal$
            .pipe(takeUntil(this.destroy$))
            .subscribe((community) => {
                this.community = community;
                this.showDialog();
                this.loadCommunityInfo();
                this.setMainspring();
                this.detectMediaType();
            });
    }
}
