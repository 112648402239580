<div [formGroup]="parentForm" class="row-eq-height">
    <div class="project-list">
        <div class="row">
            <div class="col-12 col-md-3 mb-4" *ngFor="let projectType of projectTypes">
                <div
                    class="card box-shadow card-type-project"
                    [class.card-project-active]="projectType?.id === parentForm.controls.projectTypeId.value"
                >
                    <label>
                        <input
                            type="radio"
                            class="d-none"
                            formControlName="projectTypeId"
                            value="{{ projectType.id }}"
                        />

                        <div class="card-body">
                            <div class="picto-container">
                                <img
                                    src="./assets/images/project-icons/{{ projectTypeIconMapping[projectType?.id] }}"
                                    [alt]="'projects.tabs.projectTypeList.projectTypePictureAlt' | translate"
                                />
                            </div>

                            <div class="row title-type text-center">
                                <div class="col-12">
                                    <h3 class="h6 primary-1 tt-u ta-c">
                                        <b>{{ projectType?.label | wtranslate: 'projectTypes' | uppercase }}</b>
                                    </h3>
                                </div>
                            </div>

                            <div [innerHTML]="projectType?.description | wtranslate: 'projectDescriptions'"></div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
