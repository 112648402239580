import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserPermissions } from '../models/identity/user-permission.model';
import { AccountsService } from '../services/identity/accounts.service';
import { RecipientsService } from '../services/Notifications/recipients.service';
import { PwaService } from '../services/pwa/pwa.service';
import { PermissionsState } from '../states/permissions/permissions.state';
import { CurrentThemeStore } from '../stores/current-theme.store';
import { environment } from './../../environments/environment';
@Component({
    selector: 'app-sidebar-burger-menu',
    templateUrl: './sidebar-burger-menu.component.html',
    styleUrls: ['./sidebar-burger-menu.component.scss'],
})
export class SideBarBurgerMenuComponent implements OnInit {
    display = false;
    isProfileMenuOpen = false;

    @Select(PermissionsState.getPermissions)
    permissions$: Observable<UserPermissions>;
    isAnyExternalLinks = false;
    constructor(
        readonly currentThemeStore: CurrentThemeStore,
        private readonly accountsService: AccountsService,
        private readonly recipientService: RecipientsService,
        public readonly pwaService: PwaService,
    ) {}
    authenticatedUser = this.accountsService.currentUser;
    authenticatedUserFullName = `${this.authenticatedUser.lastName} ${this.authenticatedUser.firstName}`;
    public isEnvWuDo: boolean | undefined = environment.settings.code === 'WuDo' ? true : undefined;
    public notificationsConfigModalVisible = false;
    public get logoSrc() {
        return this.currentThemeStore.currentTheme.platformLogoUrl;
    }
    ngOnInit() {
        this.isAnyExternalLink();
    }
    toggleSidebar() {
        this.display = !this.display;
    }

    public toggleNotificationConfigModal() {
        this.notificationsConfigModalVisible = !this.notificationsConfigModalVisible;
    }

    private isAnyExternalLink() {
        this.currentThemeStore.currentTheme$.subscribe((theme) => {
            this.isAnyExternalLinks = theme.externalLinks.length >= 1;
        });
    }
    public logout() {
        this.accountsService.logout();
    }
    toggleProfileMenu() {
        this.isProfileMenuOpen = !this.isProfileMenuOpen;
    }
    closeMenu() {
        this.display = false;
    }
}
