import { SignalRGroupNameListener } from './../../models/shared/signalr-groupname-listener.model';
import { AccountsService } from './../identity/accounts.service';
import { Subject } from 'rxjs';
import { environment } from './../../../environments/environment';
import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    ILogger,
    LogLevel,
} from '@microsoft/signalr';

const BaseRoutesName = {
    JoinGroup: 'JoinGroup',
    LeaveGroup: 'LeaveGroup',
};

export class SignalRLogger implements ILogger {
    enableLogs = environment.features.realTime.enableLogs;
    log(logLevel: LogLevel, message: string) {
        if (this.enableLogs) console.log(logLevel + '-' + message);
    }
}
export class BaseSignalrService {
    currentGroupsNames: string[] = [];
    currentListeners: SignalRGroupNameListener[] = [];
    hubConnection: HubConnection;
    apiBaseUrl: string = environment.microservices.realTime.baseUrl + '/';
    hubBaseUrl: string;
    get isConnected(): boolean {
        return this.hubConnection?.state == HubConnectionState.Connected;
    }
    get isConnecting(): boolean {
        return this.hubConnection?.state == HubConnectionState.Connecting;
    }

    restartConnectionEvent = new Subject<any>();
    restartConnectionEvent$ = this.restartConnectionEvent.asObservable();
    signalRLogger: SignalRLogger = new SignalRLogger();

    constructor(protected readonly accountsService: AccountsService) {}

    async startConnection(): Promise<void> {
        if (!this.isConnected) {
            this.hubConnection = new HubConnectionBuilder()
                .withUrl(this.hubBaseUrl, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: () => this.accountsService.getToken(),
                })

                .withAutomaticReconnect()
                .configureLogging(this.signalRLogger)
                .build();

            this.hubConnection.onreconnected(() => {
                this.onShouldRestartConnection();
            });

            try {
                await this.hubConnection.start();
            } catch (err) {
                this.signalRLogger.log(4, `Probleme de connexion au hub  ${this.hubBaseUrl} `);
                this.signalRLogger.log(4, err);
            }
        }
    }

    stopConnection() {
        if (this.isConnected) {
            this.hubConnection.stop().then(() => {
                this.currentGroupsNames.forEach((groupName) => {
                    this.removeListeners(groupName);
                });
            });
        }
    }

    onShouldRestartConnection() {
        this.restartConnectionEvent.next();
    }

    async joinGroup(groupName: string, userId: string, exceptionGroupName: string = null) {
        if (!this.isConnected) {
            await this.startConnection();
        }
        if (!this.currentGroupsNames.includes(groupName)) {
            await this.hubConnection
                .send(BaseRoutesName.JoinGroup, groupName, userId, exceptionGroupName)
                .then(() => {
                    this.currentGroupsNames.push(groupName);
                    this.currentGroupsNames = this.currentGroupsNames.filter(
                        (name) => name == groupName || (!!exceptionGroupName && name == exceptionGroupName),
                    );
                })
                .catch((error: any) =>
                    this.signalRLogger.log(4, `Probleme de connexion au groupe ${groupName} : ${error}`),
                );
        }
    }

    async leaveGroup(groupName: string, userId: string) {
        if (!this.accountsService.isAuthenticated) {
            this.stopConnection();
        }

        if (!this.isConnected) return;
        if (this.currentGroupsNames.includes(groupName)) {
            await this.hubConnection
                .send(BaseRoutesName.LeaveGroup, groupName, userId)
                .then(() => {
                    this.currentGroupsNames = this.currentGroupsNames.filter((name) => name != groupName);
                })
                .catch((error: any) =>
                    this.signalRLogger.log(4, `Probleme de déconnexion au groupe ${groupName} : ${error}`),
                );
        }
        this.removeListeners(groupName);
    }

    protected addListener(methodName: string, groupName: string, callback: (...args: any[]) => void) {
        this.currentListeners
            .filter((currentListener) => !this.currentGroupsNames.includes(currentListener.groupName))
            .forEach((currentListener) => {
                this.hubConnection.off(methodName, currentListener.callbackFunction);
            });

        this.currentListeners
            .filter(
                (currentListener) => currentListener.groupName == groupName && currentListener.methodName == methodName,
            )
            .forEach((currentListener) => {
                this.hubConnection.off(methodName, currentListener.callbackFunction);
            });
        this.currentListeners = this.currentListeners.filter((currentListener) =>
            this.currentGroupsNames.includes(currentListener.groupName),
        );
        // this.hubConnection.off(methodName);
        this.hubConnection.on(methodName, callback);
        this.currentListeners.push({
            groupName: groupName,
            methodName: methodName,
            callbackFunction: callback,
        });
    }

    protected removeListeners(groupName: string) {
        this.currentListeners = this.currentListeners.filter(
            (currentListener) => groupName != currentListener.groupName,
        );
    }

    //Important : call this function with args in the same order as backend should receive them
    sendMessage(route: string, groupName: string, ...args: any[]) {
        this.hubConnection
            .send(route, groupName, ...args)
            .then(() => this.signalRLogger.log(1, `Message envoyée vers ${route}`))
            .catch((error: any) => this.signalRLogger.log(4, `Probleme envoi vers ${route} : ${error}`));
    }
}
