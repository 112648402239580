import { NgxSpinnerService } from 'ngx-spinner';

import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ClubsStore } from './clubs/clubs.store';
import { AccessToModuleClubsGuard } from './guards/access-to-module-clubs.guard';
import { RetrieveThemeCommand } from './models/identity/commands/retrieve-theme-command.model';
import { AccountsService } from './services/identity/accounts.service';
import { PwaService } from './services/pwa/pwa.service';
import { ThemeService } from './services/shared/theme.service';
import { WordingService } from './services/shared/wording.service';

@Component({
    selector: 'app-component',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    readonly loginBackground = '../assets/images/login-page-background';

    public currentUrl: string;
    public deferredPrompt: any;

    // BeforeInstallPromptEvent
    public isOffline = false;
    public isPublicPage = false;
    public showPage = false;

    constructor(
        private readonly router: Router,
        private readonly accountsService: AccountsService,
        private readonly pwaService: PwaService,
        private primengConfig: PrimeNGConfig,
        public readonly clubsStore: ClubsStore,
        private readonly themeService: ThemeService,
        private wording: WordingService,
        private readonly clubsGuard: AccessToModuleClubsGuard,
        protected readonly route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private readonly translate: TranslateService,
    ) {
        translate.addLangs(['fr_WUDO', 'fr_NV', 'fr_CETIM', 'fr_BFF', 'fr_UIMM']);
        translate.setDefaultLang('fr_WUDO');
        this.isOffline = !navigator.onLine;
        window.document.title = environment.settings.title;
    }

    public get canViewClubs(): boolean {
        return this.clubsGuard.canShowLink();
    }

    public get showClubNavigation(): boolean {
        return this.clubsGuard.showClubNavigation();
    }

    public isAuthenticatedAndCanUsePlatform() {
        return this.isAuthenticatedWithTermsOfUseAccepted() && !!this.accountsService.currentUser.isOnBoardingProfileCompleted;
    }

    public isAuthenticatedWithTermsOfUseAccepted() {
        return this.accountsService.isAuthenticated && this.accountsService.currentUser.termsOfUse;
    }

    public logout() {
        this.accountsService.logout();
    }

    public async ngOnInit() {
        this.accountsService.setRefreshingToken(false);
        // Show spinner until everything loads
        this.spinner.show();

        // Service Worker is only available in https env
        if (location.protocol === 'https:' && this.pwaService.isSwAvailable()) {
            // Checking if the service worker is ready to be used
            // We need that for Firefox
            // Some people have "Delete cookies and site data when Firefox is closed" settings enabled
            // Which will prevent the service worker to be ready even tho it is available
            Promise.resolve(navigator.serviceWorker.ready)
                .then(async (registration) => {
                    if (registration.active) {
                        // This will be catched if the users refresh the page by emptying the cache
                        // We did that because the app would not show up (spinner still spinning)
                        if (navigator.serviceWorker.controller === null) {
                            window.location.reload();
                        }

                        this.pwaService.checkForUpdate().subscribe((result) => {
                            // Results returns true if there is an update
                            // So we show the page when there is no update
                            // And the spinner hides when no update is available
                            // result = true -> when there is an update
                            // result = false -> when there is no update
                            // The controller in the pwaService will refresh the app if there is an update
                            this.showPage = !result;
                            if (!result) {
                                this.spinner.hide();
                            }
                        });
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        this.showPage = true;
                        this.spinner.hide();
                    }, 2000);
                });
        } else {
            // no spinner in localhost
            this.showPage = true;
            this.spinner.hide();
        }

        setTimeout(() => {
            // make sure that 'beforePromptInstalled' event it caugth !
            this.pwaService.init();
        }, 5000);

        // Congigure primeng ripple animations
        this.primengConfig.ripple = true;

        this.themeService.applyThemeToDocument(this.accountsService?.currentUser?.theme);
        if (this.accountsService?.getUserPrimaryCommunity()?.id)
            this.themeService
                .retrieve(new RetrieveThemeCommand({ communityId: this.accountsService?.getUserPrimaryCommunity()?.id }))
                .subscribe((theme) => {
                    this.themeService.applyThemeToDocument(theme);
                    const community = this.accountsService.getUserPrimaryCommunity();
                    this.wording.loadTranslationsForCommunity(community);
                });

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.currentUrl = event.urlAfterRedirects;
            this.isPublicPage = this.currentUrl.includes('public');
        });

        this.route.queryParams.subscribe((params) => {
            if (params['disconnect']) {
                //Logout user and get current url without any params (to remove disconnect param),
                //so the user will be automaticly redirected to this url after reconnexion
                this.accountsService.logout(this.router.url.split('?')[0]);
            }
        });
    }

    /**
     * Track when PWA is installed.
     */
    @HostListener('window:appinstalled', ['$event'])
    public onAppInstalled() {
        this.pwaService.trackInstalled();
    }

    /**
     * This event raised only if the Service Worker is ready and the PWA has never been installed on the device!
     *
     * @param event type of BeforeInstallPromptEvent
     */
    @HostListener('window:beforeinstallprompt', ['$event'])
    public onBeforeinstallprompt(event: any) {
        event.preventDefault();
        this.pwaService.promptEvent = event;
    }

    @HostListener('window:offline', ['$event'])
    public onOffline() {
        this.isOffline = true;
    }

    @HostListener('window:online', ['$event'])
    public onOnline() {
        this.isOffline = false;
        if (location.protocol === 'https:' && this.pwaService.isSwAvailable()) this.pwaService.checkForUpdate();
    }
}

let gtag: Function;
