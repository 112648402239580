import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { AccessToModuleClubsGuard } from '../guards/access-to-module-clubs.guard';
import { AuthenticatedUser } from '../models/identity/authenticated-user.model';
import { UserPermissions } from '../models/identity/user-permission.model';
import { UserStatusTypes } from '../models/identity/user-status-types.model';
import { AccountsService } from '../services/identity/accounts.service';
import { ProfileService } from '../services/identity/profile.service';
import { RecipientsService } from '../services/Notifications/recipients.service';
import { PwaService } from '../services/pwa/pwa.service';
import { ImageService } from '../services/shared/image.service';
import { NavigationService } from '../services/shared/navigation.service';
import { ThemeService } from '../services/shared/theme.service';
import { WordingService } from '../services/shared/wording.service';
import { WelcomeService } from '../services/welcome/welcome-service';
import { PermissionsState } from '../states/permissions/permissions.state';
import { CurrentThemeStore } from '../stores/current-theme.store';
import { environment } from './../../environments/environment';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    public UserStatusTypes = UserStatusTypes;
    public bernsteinSsoUrl: string | undefined = environment.sso.bernstein;
    public canViewClubs: boolean;
    public currentUser: AuthenticatedUser;
    // Current WuKoins balance of the user
    public currentUserBalance: number = undefined;
    // Dictionary
    public dictionary = environment.dictionary;
    public displayWelcomeMenu: boolean;
    public docusignSsoUrl: string | undefined = environment.sso.docusign;
    // Checking if the current environment allows documents
    public features = environment.features;
    public isAllOtherNotificationsChecked: boolean;
    isMobile = window.innerWidth <= 768;

    // Is this WuDo Env
    public isEnvWuDo: boolean | undefined = environment.settings.code === 'WuDo' ? true : undefined;
    @Input() public isPreviewMode: boolean;
    public isRecieveGroupOfAnnoucmentsChecked: boolean;
    public items: MenuItem[] = [];
    public notificationsConfigModalVisible: boolean;
    @Select(PermissionsState.getPermissions)
    permissions$: Observable<UserPermissions>;
    //photo update
    public profilePicture: string;
    routeTitle$: Observable<string>;
    public showBackButton$: Observable<boolean>;
    public unreadContribution = false;
    public welcomePercentage = 0;

    public shouldDisplayDashboard = false;

    constructor(
        private readonly profileService: ProfileService,
        private readonly accountsService: AccountsService,
        private readonly themeService: ThemeService,
        private readonly pwaService: PwaService,
        private readonly recipientService: RecipientsService,
        private readonly clubsGuard: AccessToModuleClubsGuard,
        public imageService: ImageService,
        public readonly router: Router,
        readonly currentThemeStore: CurrentThemeStore,
        private wording: WordingService,
        private readonly welcomeService: WelcomeService,
        private translate: TranslateService,
        private readonly navigationService: NavigationService,
    ) {}

    public get countExternalLinks() {
        return this.currentThemeStore.currentTheme.externalLinks.length;
    }

    public get firstExternalLink() {
        return this.currentThemeStore.currentTheme.externalLinks[0];
    }

    public get logoSrc() {
        return this.currentThemeStore.currentTheme.platformLogoUrl;
    }

    public get noExternalLinks() {
        return this.countExternalLinks === 1 && this.currentThemeStore.currentTheme?.externalLinks[0] === null;
    }

    public getDefaultYearbook() {
        if (this.accountsService.hasUserPrimaryCommunity(environment.communitiesId.boostFrenchFabId)) {
            this.router.navigate(['/directory/organizations']);
        } else {
            this.router.navigate(['/directory/members']);
        }
    }

    public goBack() {
        this.navigationService.goBack();
    }

    public async loadUserMenu() {
        this.permissions$.subscribe(async (data) => {
            this.currentThemeStore.currentTheme$.subscribe((theme) => {
                this.items = [];
                // if mainCommunity has link - add menu item to access external link
                if (this.currentThemeStore.currentTheme.externalLinks.length > 0) {
                    this.currentThemeStore.currentTheme.externalLinks.forEach((link) => {
                        this.items.push({
                            label: link.name.slice(0, 20),
                            url: link.url,
                            target: '_blank',
                        });
                    });
                }
            });
        });
    }

    public logout() {
        this.accountsService.logout();
    }

    public ngOnDestroy() {
        this._subscriptions.forEach((sub) => sub.unsubscribe());
    }

    public async ngOnInit() {
        this.checkDebugResetAccount();

        this.canViewClubs = this.clubsGuard.canShowLink();

        this.currentUser = this.accountsService.currentUser;

        this.shouldDisplayDashboard =
            this.accountsService.isAdmin() ||
            (this.accountsService.getCommunitiesManagedByUser()?.some((community) => community.hasAccessToDashboard) ?? false);

        this.welcomeService.steps$.subscribe(() => {
            this.welcomePercentage = this.welcomeService.getCompletedStepsPercentage();
        });

        const community = this.currentUser.primaryCommunity;
        this.wording.loadTranslationsForCommunity(community);

        const currentUserChanged$ = this.accountsService.onCurrentUserChanged.subscribe(() => {
            this.currentUser = this.accountsService.currentUser;
        });
        this._subscriptions.push(currentUserChanged$);

        this.accountsService.setDisplayUsersStatusAndInactiveUsers();
        await this.loadUserMenu();

        this.showBackButton$ = this.navigationService.shouldShowBackButton();
        this.routeTitle$ = this.navigationService.getRouteTitle();
    }

    public toggleNotificationConfigModal() {
        this.notificationsConfigModalVisible = !this.notificationsConfigModalVisible;
    }

    public toggleTheme() {
        // changement de theme pour les communautes Porteurs de projets industriels et Offreurs de solutions
        this.themeService.applyThemeToDocument(this.currentUser.theme);
        const community = this.currentUser.primaryCommunity;
        this.wording.loadTranslationsForCommunity(community);
    }

    private checkDebugResetAccount() {
        /* DEBUG ONLY (reset isOnBoardingProfileCompleted & termsOfUse of currentUser) */
        if (environment.features.debugResetAccount && /debug_reset/i.test(window.location.search || '')) {
            this.profileService.reset().subscribe(() => {
                this.accountsService.logout();
            });
        }
    }
}
