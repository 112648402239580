export class CropperParams {
    //Can be "center" or "left"
    image: File;
    urlImage: string;
    alignImage: string = 'center';
    //Should be "width / height" where width can be equal to height
    ratio: number = 1 / 1;
    isRatioFix: boolean = true;
    minWidth: number = 15;
    minHeight: number = 15;
    format: string = 'png';
    // maxSizeFile: number = 2097152;
    resizeToWidth: number = 200;
    resizeToHeight: number = 200;
    maxSizeString: string;

    CropperParams(_urlImage: string) {
        this.urlImage = _urlImage;
    }
}
