import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { DocumentsService } from '../services/dms/documents.service';

@Injectable({ providedIn: 'root' })
export class RessourceShareRedirection implements CanActivate {
    ressourceId: string;
    clubId: string;

    constructor(private readonly documentService: DocumentsService) {}

    async canActivate(route: ActivatedRouteSnapshot) {
        this.ressourceId = route.params.ressourceId;
        this.clubId = route.params.clubId;

        const url = await this.documentService.openDocument(this.ressourceId, this.clubId).toPromise();
        window.location.href = url;

        return true;
    }
}
