import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Community } from '../../../models/identity/community.model';
import { CommunityService } from '../../../services/identity/community.service';

@Directive({
    selector: '[appShowForCommunities]',
    inputs: ['appShowForCommunities'],
})
export class ShowForCommunitiesDirective {
    userCommunities: Community[];
    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainer: ViewContainerRef,
        private communityService: CommunityService,
    ) {}

    @Input() set appShowForCommunities(allowedCommunitiesIds: Array<string>) {
        this.communityService.getMyCommunities().subscribe((userCommunities: Community[]) => {
            this.userCommunities = userCommunities;
            this.changeVisibility(allowedCommunitiesIds);
        });
    }

    changeVisibility(allowedCommunitiesIds: Array<string>): void {
        // Search if the allowed communities find themselves in the user communities
        const userCommunitiesIds = this.userCommunities.map((userCommunity) => userCommunity.id);
        const shouldShow = allowedCommunitiesIds.some((allowCommynityId) =>
            userCommunitiesIds.includes(allowCommynityId),
        );

        if (shouldShow) {
            this._viewContainer.createEmbeddedView(this._templateRef);
        } else {
            this._viewContainer.clear();
        }
    }

}
