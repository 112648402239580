import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { serialize } from 'object-to-formdata';
import * as queryString from 'query-string';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CreateDocumentFromStorageInDirectoryCommand } from '../../models/dms/commands/create-document-from-storage-in-directory.command';
import { CreateDocumentFromUrlInDirectoryCommand } from '../../models/dms/commands/create-document-from-url-in-directory.command';
import { CreatePublicationDocumentRequest } from '../../models/dms/commands/create-publication-document/create-publication-document-request';
import { CreatePublicationDocumentResponse } from '../../models/dms/commands/create-publication-document/create-publication-document-response';
import { DeleteDocumentCommand } from '../../models/dms/commands/delete-document.command';
import { UpdateDocumentByRenamingCommand } from '../../models/dms/commands/update-document-by-renaming.command';
import { DmsDocument } from '../../models/dms/dms-document.model';
import { DmsDocumentFromStorage } from '../../models/dms/document-from-storage.model';
import { DmsDocumentFromUrl } from '../../models/dms/document-from-url.model';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    private readonly clubDmsApiUrl = `${environment.microservices.dms.baseUrl}/api/v1/Clubs`;
    private documentsApiUrl = `${environment.microservices.dms.baseUrl}/api/v1/Documents`;
    private openApiUrl = `${this.documentsApiUrl}/Open`;
    private accessDownloadUrl = `${this.documentsApiUrl}/AccessDownload`;
    private createPublicationDocumentUrl = `${this.documentsApiUrl}/CreatePublicationDocument`;
    private deleteApiUrl = `${this.documentsApiUrl}/Delete`;
    private renameApiUrl = `${this.documentsApiUrl}/Rename`;
    private shareRessourceUrl = `${environment.ressource.shareRedirectUrl}`;

    constructor(private readonly http: HttpClient) {}

    public createDocumentFromStorageInDirectoryV2(
        command: CreateDocumentFromStorageInDirectoryCommand,
        clubId: string,
    ): Observable<DmsDocumentFromStorage> {
        return this.http.post<DmsDocumentFromStorage>(`${this.clubDmsApiUrl}/${clubId}/DocumentsFromStorage`, serialize(command));
    }

    public createDocumentFromUrlInDirectoryV2(command: CreateDocumentFromUrlInDirectoryCommand, clubId: string): Observable<DmsDocumentFromUrl> {
        return this.http.post<DmsDocumentFromUrl>(`${this.clubDmsApiUrl}/${clubId}/DocumentsFromUrl`, serialize(command));
    }

    public createPublicationDocument(command: CreatePublicationDocumentRequest): Observable<CreatePublicationDocumentResponse> {
        return this.http.post<CreatePublicationDocumentResponse>(this.createPublicationDocumentUrl, serialize(command));
    }

    public deleteDocument(command: DeleteDocumentCommand): Observable<Document> {
        return this.http.delete<Document>(`${this.deleteApiUrl}?${queryString.stringify(command)}`);
    }

    public openDocument(documentId: string, clubId: string): Observable<string> {
        return this.http.get(`${this.openApiUrl}/${clubId}/${documentId}`, {
            responseType: 'text',
        });
    }

    public getDocument(documentId: string, clubId: string): Observable<DmsDocument> {
        return this.http.get<DmsDocument>(`${this.documentsApiUrl}/Retrieve/${clubId}/${documentId}`);
    }

    public getDownloadUrl(documentId: string, clubId: string): Observable<string> {
        return this.http.get(`${this.accessDownloadUrl}/${clubId}/${documentId}`, {
            responseType: 'text',
        });
    }

    public hasAcceptedFormat(type: string) {
        const acceptedFormats = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ];

        return acceptedFormats.includes(type);
    }

    public async openResource(ressourceId: string, clubId: string) {
        const url = await this.openDocument(ressourceId, clubId).toPromise();
        if (url) {
            window.open(url, '_blank');
        }
    }

    public rename(command: UpdateDocumentByRenamingCommand): Observable<Document> {
        return this.http.put<Document>(`${this.renameApiUrl}`, command);
    }

    public async uploadDocument(clubId: string, document: File) {
        const command = new CreatePublicationDocumentRequest(clubId, document);
        const response = await this.createPublicationDocument(command).toPromise();

        return response.documentId;
    }

    public getRessourceShareUrl(ressourceId: string, clubId: string) {
        return `${this.shareRessourceUrl}/${clubId}/${ressourceId}`;
    }
}
