export const SUBSCRIPTION_TYPE_UNDEFINED = 0;
export const SUBSCRIPTION_TYPE_OPEN = 1;
export const SUBSCRIPTION_TYPE_BUSINESS = 2;
export const SUBSCRIPTION_TYPE_GRID = 3;
export const SUBSCRIPTION_TYPE_ACCESS = 4;

export class SubscriptionType {
    public id: number;
    public label: string;
}
