import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InterestDomain } from '../../models/skills/interest-domain.model';
import { Interest } from '../../models/skills/interest.model';
import { CacheService } from '../shared/cache.service';

@Injectable({
    providedIn: 'root',
})
export class InterestsService {
    private interestDomainsApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.skills.interestDomainsApi.baseUrl}`;
    private interestsApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.skills.interestsApi.baseUrl}`;
    // Key of the interests in the cache
    private interestsCacheKey = 'currentInterests';
    // Key of the interests domains in the cache
    private interestsDomainsCacheKey = 'currentInterestsDomains';

    constructor(private http: HttpClient, private readonly cacheService: CacheService) {}

    public getAllInterestDomains(): Observable<InterestDomain[]> {
        return this.cacheService.get(this.interestsDomainsCacheKey, this.http.get<InterestDomain[]>(this.interestDomainsApiUrl));
    }

    public getAllInterests(): Observable<Interest[]> {
        return this.cacheService.get(this.interestsCacheKey, this.http.get<Interest[]>(this.interestsApiUrl));
    }

    public getTopInterestsByCommunity(communityId: string, limit: number): Observable<Interest[]> {
        return this.cacheService.get(
            'topCommunityInterests',
            this.http.get<Interest[]>(
                `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.communitiesApi.baseUrl}/${communityId}/interests/top?limit=${limit}`,
            ),
        );
    }
}
