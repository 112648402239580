<div *ngIf="!isStepLoaded" class="spinner-border text-info" role="status">
    <span class="sr-only">{{ 'projects.formStep1.loading' | translate }}</span>
</div>
<form *ngIf="stepForm" [formGroup]="stepForm" [hidden]="!isStepLoaded" (ngSubmit)="onSubmit()" class="col-12">
    <div class="card box-shadow overflow-visible">
        <div class="card-body">
            <app-step-header [user]="currentProfile"></app-step-header>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <span class="fw-b primary-1 d-block mb-2">{{ 'filters.event.accessTypes' | translate }} *</span>
                            <p-selectButton
                                [options]="eventAccessTypes"
                                formControlName="accessType"
                                optionLabel="label"
                                optionValue="value"
                            ></p-selectButton>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="stepForm.controls.accessType.value === EventAccessTypeEnum.Online" class="form-group">
                                <label class="fw-b primary-1" for="event-link">{{ 'events.formStep2.eventLink.label' | translate }} *</label>
                                <input type="text" formControlName="eventLink" id="event-link" class="form-control" />
                                <div *ngIf="showControlError(stepForm.controls.eventLink)" class="alert alert-danger">
                                    <div *ngIf="stepForm.controls.eventLink.errors.required">
                                        {{ 'events.formStep2.eventLink.required' | translate }}
                                    </div>
                                    <div *ngIf="stepForm.controls.eventLink.errors.maxlength">
                                        {{
                                            'events.formStep2.eventLink.length'
                                                | translate
                                                    : {
                                                          maxLength: 2048,
                                                          currLength: stepForm.controls.eventLink.value.length
                                                      }
                                        }}
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="stepForm.controls.accessType.value === EventAccessTypeEnum.Physical" class="form-group">
                                <app-address-autocomplete
                                    [formControl]="stepForm.controls.address"
                                    [isRequired]="true"
                                    (addressSelected)="onAddressSelected($event)"
                                ></app-address-autocomplete>
                                <div *ngIf="showControlError(stepForm.controls.address)" class="alert alert-danger">
                                    <div *ngIf="stepForm.controls.address.errors.required">
                                        {{ 'events.formStep2.address.required' | translate }}
                                    </div>
                                    <div *ngIf="stepForm.controls.address.errors.maxlength">
                                        {{
                                            'events.formStep2.address.length'
                                                | translate
                                                    : {
                                                          maxLength: 200,
                                                          currLength: stepForm.controls.address.value.length
                                                      }
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="stepForm.controls.accessType.value === EventAccessTypeEnum.Physical" class="form-group">
                                <label class="fw-b primary-1" for="event-address-complement">{{
                                    'events.formStep2.addressComplement.label' | translate
                                }}</label>
                                <input
                                    type="text"
                                    formControlName="addressComplement"
                                    id="event-address-complement"
                                    [placeholder]="'events.formStep2.addressComplement.placeholder' | translate"
                                    class="form-control"
                                />
                                <div *ngIf="showControlError(stepForm.controls.addressComplement)" class="alert alert-danger">
                                    <div *ngIf="stepForm.controls.addressComplement.errors.required">
                                        {{ 'events.formStep2.addressComplement.required' | translate }}
                                    </div>
                                    <div *ngIf="stepForm.controls.addressComplement.errors.maxlength">
                                        {{
                                            'events.formStep2.addressComplement.length'
                                                | translate
                                                    : {
                                                          maxLength: 200,
                                                          currLength: stepForm.controls.addressComplement.value.length
                                                      }
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group date" data-provide="datepicker">
                                <label class="fw-b primary-1" for="start-date">{{ 'events.formStep2.startDate.label' | translate }} *</label>
                                <input
                                    type="date"
                                    formControlName="startDate"
                                    id="start-date"
                                    class="form-control"
                                    min="{{ getMinDate() | date : 'yyyy-MM-dd' }}"
                                />
                                <div *ngIf="showControlError(stepForm.controls.startDate)" class="alert alert-danger">
                                    <div *ngIf="stepForm.controls.startDate.errors.required">
                                        {{ 'events.formStep2.startDate.required' | translate }}
                                    </div>
                                    <div *ngIf="stepForm.controls.startDate.errors.beforeNow">
                                        {{ 'events.formStep2.startDate.beforeNow' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label class="fw-b primary-1" for="start-time">{{ 'events.formStep2.startTime.label' | translate }} *</label>
                                <input #startTimeInput type="time" formControlName="startTime" id="start-time" class="form-control" />
                                <div *ngIf="showControlError(stepForm.controls.startTime)" class="alert alert-danger">
                                    <div *ngIf="stepForm.controls.startTime.errors.required">
                                        {{ 'events.formStep2.startTime.required' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group date" data-provide="datepicker">
                                <label class="fw-b primary-1" for="end-date">{{ 'events.formStep2.endDate.label' | translate }} *</label>
                                <input
                                    type="date"
                                    formControlName="endDate"
                                    id="end-date"
                                    class="form-control"
                                    min="{{ getMinEndDate() | date : 'yyyy-MM-dd' }}"
                                />
                                <div *ngIf="showControlError(stepForm.controls.endDate)" class="alert alert-danger">
                                    <div *ngIf="stepForm.controls.endDate.errors.required">
                                        {{ 'events.formStep2.endDate.required' | translate }}
                                    </div>
                                    <div *ngIf="stepForm.controls.endDate.errors.beforeNow">
                                        {{ 'events.formStep2.endDate.beforeNow' | translate }}
                                    </div>
                                    <div *ngIf="stepForm.controls.endDate.errors.beforeStartDate">
                                        {{ 'events.formStep2.endDate.beforeStartDate' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label class="fw-b primary-1" for="end-time">{{ 'events.formStep2.endTime.label' | translate }} *</label>
                                <input #endTimeInput type="time" formControlName="endTime" id="end-time" class="form-control" />
                                <div *ngIf="showControlError(stepForm.controls.endTime)" class="alert alert-danger">
                                    <div *ngIf="stepForm.controls.endTime.errors.required">
                                        {{ 'events.formStep2.endTime.required' | translate }}
                                    </div>
                                    <div *ngIf="stepForm.controls.endTime.errors.beforeStartTime">
                                        {{ 'events.formStep2.endTime.beforeStartTime' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-group">
                                <label class="fw-b primary-1" for="event-registration-link">{{
                                    'events.formStep2.registrationLink.label' | translate
                                }}</label>
                                <i class="pi pi-info-circle ml-1" [pTooltip]="'events.formStep2.registrationLink.tooltip' | translate"></i>
                                <input
                                    type="text"
                                    formControlName="registrationLink"
                                    id="event-registration-link"
                                    [placeholder]="'events.formStep2.registrationLink.placeholder' | translate"
                                    class="form-control"
                                />
                                <div *ngIf="showControlError(stepForm.controls.registrationLink)" class="alert alert-danger">
                                    <div *ngIf="stepForm.controls.registrationLink.errors.maxlength">
                                        {{
                                            'events.formStep2.registrationLink.length'
                                                | translate
                                                    : {
                                                          maxLength: 2048,
                                                          currLength: stepForm.controls.registrationLink.value.length
                                                      }
                                        }}
                                    </div>
                                    <div *ngIf="stepForm.controls.registrationLink.errors.pattern">
                                        {{ 'events.formStep2.registrationLink.urlValidation' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
