import { Injectable } from '@angular/core';
import { MailGroup } from './mail-group.enum';

@Injectable({
    providedIn: 'root',
})
export class MailGroupMappingService {
    mapMailGroup(mailGroupString: string): MailGroup {
        return MailGroup[mailGroupString as keyof typeof MailGroup];
    }
}
