import { CommunityWithRoles } from './../../../models/identity/community-with-roles.model';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-user-communities',
    templateUrl: './user-communities.component.html',
})
export class UserCommunitiesComponent {
    communitiesDialog: boolean;

    @Input() communities: Array<CommunityWithRoles>;

    showCommunitiesDialog() {
        this.communitiesDialog = true;
    }

    toogleCommunitiesModal() {
        this.communitiesDialog = !this.communitiesDialog;
    }
}
