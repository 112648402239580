import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { AuthenticatedUser } from '../../../models/identity/authenticated-user.model';
import { AccountsService } from '../../../services/identity/accounts.service';
import { ClubsStore } from '../../clubs.store';
import { ClubActivityService } from '../../services/club-activity.service';
import { Club } from './../../../models/clubs/club.model';

@Component({
    selector: 'club-navigation',
    styleUrls: ['./club-navigation.component.scss'],
    templateUrl: './club-navigation.component.html',
})
export class ClubNavigationComponent implements OnInit, OnDestroy {
    public canAccessClubExpore: boolean;
    public canCreateClub: boolean;
    public currentUser: AuthenticatedUser;
    public isCommunityManager: boolean;
    public routerSubscription: Subscription;

    constructor(
        readonly clubActivityService: ClubActivityService,
        readonly clubsStore: ClubsStore,
        private readonly accountService: AccountsService,
        private router: Router,
    ) {}

    public get clubsToDisplay(): Club[] {
        return this.clubsStore.member.fullClubs;
    }

    public get isOnClubRoute(): boolean {
        return this.router.url.includes('/clubs/');
    }

    public get selectedClubId(): string {
        return this.clubsStore.displayedClub?.id;
    }

    public getClub(): Club {
        return this.clubsStore.displayedClub;
    }

    public getClubTitle(): string {
        return this.clubsStore.displayedClub.title;
    }

    public ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    public async ngOnInit() {
        this.canAccessClubExpore = this.accountService.isAdmin();

        this.canCreateClub = this.accountService.currentUser.primaryCommunity.canCreateClub;
        this.isCommunityManager = this.accountService.isUserManagingCommunity(this.accountService.currentUser.primaryCommunity);

        this.clubsStore.loadMember();
        //Get clubs activity in start of app and then every 60 seconds
        this.clubActivityService.loadAllClubsActivity();
        this.routerSubscription = interval(60000).subscribe(() => {
            this.clubActivityService.loadAllClubsActivity();
        });
    }
}
