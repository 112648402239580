<button class="btn btn-light border-0 d-md-none burger-menu-primary" type="button" (click)="toggleSidebar()">
    <i class="pi pi-bars fs-5"></i>
</button>

<p-sidebar [(visible)]="display" [baseZIndex]="99999" [showCloseIcon]="false" [modal]="false" styleClass="d-md-none">
    <div class="index d-flex flex-column h-100">
        <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
            <a href="#" class="platform-logo-container">
                <ng-container *ngIf="currentThemeStore.currentTheme$ | async">
                    <img [src]="logoSrc" alt="WuDo Platform" height="30" />
                </ng-container>
            </a>
            <button class="btn btn-link p-2 text-dark" (click)="display = false">
                <i class="pi pi-times"></i>
            </button>
        </div>
        <div class="list-group list-group-flush flex-grow-1">
            <a class="list-group-item list-group-item-action border-0 py-3" (click)="toggleNotificationConfigModal()">
                <div class="d-flex align-items-center">
                    <i class="pi pi-bell mr-2 me-3"></i>
                    <span>{{ 'home.topBar.configureNotifications' | translate }}</span>
                </div>
            </a>

            <a
                *ngIf="!(permissions$ | async)?.admin && (permissions$ | async)?.canEditOrganization"
                routerLink="/administration-organizations/mine"
                routerLinkActive="true"
                class="list-group-item list-group-item-action border-0 py-3"
                (click)="closeMenu()"
            >
                <div class="d-flex align-items-center">
                    <i class="pi pi-building mr-2 me-3"></i>
                    <span>{{ 'home.topBar.manageMyOrganization' | translate }}</span>
                </div>
            </a>

            <a
                *ngIf="(permissions$ | async)?.canAccessCommunityManagementComponents"
                routerLink="/community/theme"
                routerLinkActive="true"
                class="list-group-item list-group-item-action border-0 py-3"
                (click)="closeMenu()"
            >
                <div class="d-flex align-items-center">
                    <i class="pi pi-users mr-2 me-3"></i>
                    <span>{{ 'home.topBar.manageMyCommunities' | translate }}</span>
                </div>
            </a>
            <a
                *ngIf="isAnyExternalLinks"
                routerLink="/externalLinks"
                routerLinkActive="active-topbar"
                class="list-group-item list-group-item-action border-0 py-3"
                (click)="closeMenu()"
            >
                <div class="d-flex align-items-center">
                    <i class="pi pi-file mr-2 me-3"></i>
                    <span>{{ 'community.nav.theme.externalLinkTitle' | translate }}</span>
                </div>
            </a>
            <a
                *ngIf="isEnvWuDo"
                href="https://wudo.io/aide/"
                target="_blank"
                routerLinkActive="active-topbar"
                class="list-group-item list-group-item-action border-0 py-3"
            >
                <div class="d-flex align-items-center">
                    <i class="pi pi-video mr-2 me-3"></i>
                    <span>{{ 'home.topBar.help' | translate }}</span>
                </div>
            </a>

            <a
                routerLink="/policies"
                routerLinkActive="active-topbar"
                class="list-group-item list-group-item-action border-0 py-3"
                (click)="closeMenu()"
            >
                <div class="d-flex align-items-center">
                    <i class="pi pi-file mr-2 me-3"></i>
                    <span>{{ 'home.topBar.termsOfService' | translate }}</span>
                </div>
            </a>

            <a
                routerLink="/contact"
                routerLinkActive="active-topbar"
                class="list-group-item list-group-item-action border-0 py-3"
                (click)="closeMenu()"
            >
                <div class="d-flex align-items-center">
                    <i class="pi pi-envelope mr-2 me-3"></i>
                    <span>{{ 'home.topBar.contactUs' | translate }}</span>
                </div>
            </a>
            <a class="list-group-item list-group-item-action border-0 py-3" (click)="pwaService.installPwa()">
                <div class="d-flex align-items-center">
                    <i class="pi pi-download mr-2 me-3"></i>
                    <span>{{ 'home.topBar.downloadPwa' | translate }}</span>
                </div>
            </a>
        </div>

        <div class="d-flex justify-content-between align-items-center border-top">
            <a
                class="justify-content-end border-top p-3 bg-white profile-container text-decoration-none"
                [routerLink]="['../profile/edit']"
                (click)="closeMenu()"
            >
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <div class="rounded-circle bg-light d-flex align-items-center justify-content-center me-3 mr-2">
                            <app-user-avatar
                                class="user-avatar-default"
                                [firstName]="authenticatedUser.firstName"
                                [lastName]="authenticatedUser.lastName"
                                [picture]="authenticatedUser.pictureAccessLink"
                                [routerLink]="['../profile/edit']"
                            >
                            </app-user-avatar>
                        </div>
                        <div>
                            <div class="fw-medium profilName">{{ authenticatedUserFullName }}</div>
                            <small class="text-muted">{{ authenticatedUser.jobTitle }}</small>
                        </div>
                    </div>
                </div>
            </a>
            <div class="dropdown dropstart">
                <button class="btn btn-link" type="button" (click)="toggleProfileMenu()" data-bs-auto-close="true" aria-expanded="false">
                    <i class="pi pi-ellipsis-v ellipsis-menu"></i>
                </button>
                <ul class="dropdown-menu burger-menu-logout" [ngClass]="{ show: isProfileMenuOpen }">
                    <li>
                        <a class="dropdown-item" (click)="logout()">{{ 'home.topBar.logout' | translate }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</p-sidebar>
<app-notification-config [(visible)]="notificationsConfigModalVisible"> </app-notification-config>
