import { EventEmitter } from '@angular/core';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-button-onboarding',
    styleUrls: ['./button-onboarding.component.scss'],
    templateUrl: './button-onboarding.component.html',
})
export class ButtonOnboardingComponent {
    @Input() title: string;
    @Input() sourceImage: string;
    @Input() isDisabled: false;
    click: EventEmitter<any> = new EventEmitter();

    handleClick() {
        this.click.emit();
    }
}
