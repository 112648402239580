<div class="preview-container">
    <div class="mail-preview">
        <div class="mail-banner-container">
            <img
                *ngIf="theme.mailBannerUrl"
                draggable="false"
                class="mail-banner"
                [src]="theme.mailBannerUrl | safe: 'url'"
            />
        </div>
        <div class="mail-body-container">
            <div class="mail-title" [style.color]="theme.primaryColor">Titre principal</div>
            <p-skeleton
                styleClass="p-mt-3"
                width="30%"
                height="10px"
                borderRadius="16px"
                class="preview-skeleton-button-color"
            ></p-skeleton>
            <p-skeleton
                styleClass="p-mt-4"
                height="10px"
                borderRadius="16px"
                class="preview-skeleton-button-color"
            ></p-skeleton>
            <p-skeleton
                styleClass="p-mt-2"
                height="10px"
                borderRadius="16px"
                class="preview-skeleton-button-color"
            ></p-skeleton>
            <p-skeleton
                styleClass="p-mt-2"
                height="10px"
                borderRadius="16px"
                class="preview-skeleton-button-color"
            ></p-skeleton>
            <p-skeleton
                styleClass="p-mt-4"
                width="40px"
                height="10px"
                borderRadius="16px"
                class="preview-skeleton-button-color"
            ></p-skeleton>
            <div class="signature">{{ theme.mailSignature }}</div>
        </div>
        <div class="bottom-bar"></div>
    </div>
</div>
