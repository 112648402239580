import { Community } from './community.model';

export class UpdateCommunityCommand {
    public benefits: string | '';
    public communityLink: string | '';
    public displayName: string | '';
    public expectedContributions: string | '';
    public id: string;
    public canCreateClub: boolean;
    public canCreateProject: boolean;
    public canCreateEvent: boolean;
    public hasAccessToDashboard: boolean;

    public hasActivateIntelligentNewsLetter: boolean;
    public mainspring: string | '';
    public parentsIds: string[] | [];

    constructor(community: Community) {
        this.displayName = community.displayName;
        this.id = community.id;
        this.canCreateClub = community.canCreateClub;
        this.canCreateProject = community.canCreateProject;
        this.canCreateEvent = community.canCreateEvent;
        this.parentsIds = community.parentsIds ?? [];
        this.mainspring = community.mainspring || '';
        this.benefits = community.benefits || '';
        this.expectedContributions = community.expectedContributions || '';
        this.communityLink = community.communityLink || '';
    }
}
