<div class="row">
    <div class="col">
        <h1 class="view-name">
            {{ 'events.pages.list.myEvents' | translate }}
        </h1>
    </div>
    <div class="col-auto d-block mt-3 mt-sm-1 action-buttons d-flex align-items-center">
        <button routerLink="/events" pButton class="p-button-outlined left-select-button">
            <span class="d-sm-inline">{{ 'events.pages.list.title' | translate }}</span>
        </button>
        <button routerLink="/events/create" pButton icon="pi pi-plus" class="right-select-button"></button>
    </div>
</div>

<div class="row mt-2" *ngIf="isLoaded">
    <app-my-events-filters
        class="col-sm-3"
        [ngClass]="{ 'events-filters scroller': isInitiated && !isOnMobile }"
        [areFiltersOpened]="areFiltersOpened"
        (closed)="onFiltersClosed()"
        (ready)="onFiltersReady()"
        (selectionChanged)="onFiltersSelectionChanged()"
        #filters
    >
    </app-my-events-filters>

    <div class="col-12 col-sm-9">
        <div id="content-list">
            <app-infinite-scroll (scrolled)="onScroll()">
                <app-event-details-card *ngFor="let item of items" [event]="item"> </app-event-details-card>
            </app-infinite-scroll>
        </div>

        <div class="row">
            <div *ngIf="!itemsLoaded" class="spinner-border text-info" role="status">
                <span class="sr-only">{{ 'events.pages.list.loadingMessage' | translate }}</span>
            </div>

            <app-no-result class="col-12" *ngIf="itemsLoaded && items.length === 0"></app-no-result>
        </div>
    </div>
</div>
