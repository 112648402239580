import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { userSortModes } from '../../../constants/user-list-filters';
import { UsersFiltersComponent } from '../../../filters/users-filters/users-filters.component';
import '../../../helpers/extensions/string.extensions';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { UserHelper } from '../../../helpers/user.helper';
import { Filters } from '../../../models/identity/filters.model';
import { User } from '../../../models/identity/user.model';
import { ProcessSearchPipe } from '../../../pipes/process-search.pipe';
import { ProfileService } from '../../../services/identity/profile.service';
import { DataStorageService } from '../../../services/shared/data-storage.service';
import { ImageService } from '../../../services/shared/image.service';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'app-select-users',
    templateUrl: './select-users.component.html',
    styleUrls: ['./select-users.component.scss'],
    providers: [ProcessSearchPipe],
})
export class SelectUsersComponent {
    @Input()
    currentClubMembersId: String[];
    @Input()
    searchInScopeUsers: boolean = false;
    @Input()
    scopeUsers: Array<User> = [];

    searchUsers: Array<User> = [];
    selectedUsersIds: Set<string> = new Set<string>();
    selectedUsers: Array<User> = new Array<User>();
    isSelected = (userId: string): boolean => this.selectedUsersIds.has(userId);

    loadProfilesSubscription: Subscription;
    isInitialized = false;
    isLoaded = false;

    sortMode = userSortModes.user;
    page = 1;
    pageSize = environment.settings.userPageSize;
    areFiltersOpened: boolean = false;
    nextPage = false;
    userCustomSearch = '';
    minimumProfilesLoading = 12;
    virtualScrollerRows = this.minimumProfilesLoading / 2;

    get savedFilters(): Filters {
        return this.dataStorage.membersFilters;
    }
    set savedFilters(value: Filters) {
        this.dataStorage.membersFilters = value;
    }
    get appCustomSearchPlaceHolder(): String {
        return this.searchInScopeUsers
            ? this.translate.instant('shared.selectUser.searchByPlaceholderWithoutOrganization')
            : this.translate.instant('shared.selectUser.searchByPlaceholder');
    }

    @ViewChild('userFilters', { static: false })
    private readonly filtersComponent: UsersFiltersComponent;

    constructor(
        private readonly profileService: ProfileService,
        private readonly toastrHelper: ToastrHelper,
        private readonly processSearchPipe: ProcessSearchPipe,
        public readonly userHelper: UserHelper,
        public imageService: ImageService,
        public dataStorage: DataStorageService,
        public translate: TranslateService,
    ) {}

    initialize() {
        this.isInitialized = false;
        this.deleteAll();
        this.resetFilters();
        this.resetSearchUsers();
        this.loadProfiles();
        this.isInitialized = true;
    }

    selectAll() {
        this.selectedUsersIds = new Set<string>([...this.searchUsers.map((user: User) => user.userId)]);
        this.selectedUsers = [...this.searchUsers];
    }

    toogleCheck(userId: string) {
        if (this.selectedUsersIds.has(userId)) {
            this.deleteOne(userId);
        } else {
            this.selectedUsersIds.add(userId);
            this.selectedUsers.push(this.searchUsers.filter((user: User) => user.userId == userId).shift());
        }
    }

    deleteAll() {
        this.selectedUsersIds.clear();
        this.selectedUsers = [];
    }

    deleteOne(userId: string) {
        this.selectedUsersIds.delete(userId);
        this.selectedUsers = this.selectedUsers.filter((user: User) => user.userId != userId);
    }

    openFilters() {
        this.areFiltersOpened = true;
    }

    onFiltersClosed() {
        this.areFiltersOpened = false;
    }

    onFiltersReady(event: any) {
        if (this.areFiltersOpened && !this.searchInScopeUsers) {
            this.onFiltersSelectionChanged();
        }
    }

    onFiltersSelectionChanged() {
        if (!this.searchInScopeUsers) {
            this.savedFilters = null;
            this.nextPage = false;
            this.userCustomSearch = '';
            this.resetSearchUsers();
            this.loadProfiles();
            window.scroll(0, 0);
        }
    }

    onUserSearchTextChanged(text: string) {
        this.userCustomSearch = text;

        setTimeout(() => {
            this.page = 1;
            if (text === this.userCustomSearch) {
                this.resetSearchUsers();
                this.loadProfiles();
            }
        }, 1000);
    }

    onScroll(event: LazyLoadEvent) {
        if (this.searchInScopeUsers) {
            this.loadFromScopeUsers();
            return;
        }

        if (!this.nextPage || !this.isLoaded) {
            return;
        }

        this.page++;

        let loadedUsers = this.searchUsers.slice(event.first, event.first + event.rows);
        Array.prototype.splice.apply(this.searchUsers, [...[event.first, event.rows], ...loadedUsers]);

        this.loadProfiles();
    }

    loadFromScopeUsers() {
        if (this.userCustomSearch?.length > 0) {
            this.searchUsers = this.scopeUsers.filter((user) =>
                this.userHelper.getFullName(user, true).toCaseInsensitive().includes(this.userCustomSearch.toCaseInsensitive()),
            );
        } else {
            this.searchUsers = this.scopeUsers;
        }

        this.isLoaded = true;
    }

    resetSearchUsers() {
        this.page = 1;
        this.searchUsers = [];
    }

    loadProfiles() {
        if (this.searchInScopeUsers) {
            this.loadFromScopeUsers();
            return;
        }

        if (this.loadProfilesSubscription && !this.isLoaded) {
            this.loadProfilesSubscription.unsubscribe();
        }

        this.isLoaded = false;

        const filters = this.loadFilters();

        if (filters.communities.length > 0) {
            this.loadProfilesSubscription = this.profileService.getFilteredProfiles(filters).subscribe(
                (users: User[]) => {
                    const nbResults = users.length;
                    users = users?.filter((user) => !this.currentClubMembersId.includes(user.userId)) || [];
                    this.nextPage = users?.length > 0;
                    this.searchUsers = [...this.searchUsers, ...users];

                    if (nbResults == this.pageSize && this.searchUsers.length < this.minimumProfilesLoading) {
                        this.page++;
                        this.loadProfiles();
                    } else {
                        this.virtualScrollerRows = users.length;
                    }
                },
                (error) => {
                    this.toastrHelper.showGenericError(error);
                },
                () => {
                    this.isLoaded = true;
                },
            );
        }
    }

    loadFilters(): Filters {
        let filters;

        if (this.savedFilters) {
            filters = this.savedFilters;
            this.sortMode = filters.sortMode;
            this.filtersComponent.setSelectedFilters(filters);
        } else {
            filters = this.filtersComponent.extractSelectedFilters();
            filters.sortMode = this.sortMode;
            this.savedFilters = filters;
        }

        filters.page = this.page;
        filters.pageSize = this.pageSize;
        filters.userCustomSearch = this.processSearchPipe.transform(this.userCustomSearch);

        return filters;
    }

    resetFilters() {
        if (this.areFiltersOpened) {
            this.filtersComponent.resetFilters();
        }
    }

    isSelectAllButtonAvailable() {
        return !(this.searchUsers.length == this.selectedUsers.length);
    }
}
