<p-dialog
    [header]="
        isClubMode
            ? ('clubs.page.common.deleteClub.title' | translate: { clubName: clubToDelete.title })
            : ('clubs.page.common.deleteRoom.title' | translate: { roomName: clubToDelete.title })
    "
    [(visible)]="isModalDeleteClubVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    appendTo="body"
    styleClass="custom-delete-room-modal"
>
    {{
        isClubMode
            ? ('clubs.page.common.deleteClub.body' | translate: { clubName: clubToDelete.title })
            : ('clubs.page.common.deleteRoom.body' | translate: { roomName: clubToDelete.title })
    }}
    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="closeModal()"
        ></button>

        <button
            pButton
            class="p-button p-button-sm p-button-custom-submit p-button-custom-validate"
            type="button"
            label="{{ 'components.button.buttonConfirm' | translate }}"
            (click)="confirmClubDeletion()"
        ></button>
    </p-footer>
</p-dialog>
