<app-nav-organizations></app-nav-organizations>
<div class="container" *ngIf="accountsService.currentOrganizationId != 'undefined'">
    <!-- Inutile pour le moment, utile lorsqu'un utilisateur = plusieurs orgas -->
    <!-- <div class="container" *ngIf="organizations && organizations.length > 0">
    <app-list-organizations
      [organizations]="organizations"
      [selectedOrganizationId]="accountsService.currentOrganizationId"
      (eventEmitter)="onChangeOrganization($event)"
    ></app-list-organizations>
  </div> -->
    <router-outlet></router-outlet>
</div>
