import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SamlAuthenticateRequest } from '../../models/identity/saml-authentication-command.model';
import { AccountsService } from '../../services/identity/accounts.service';
import { ProfileService } from '../../services/identity/profile.service';
import { WordingService } from '../../services/shared/wording.service';
import { BaseLoginComponent } from '../base-login.component';

@Component({
    selector: 'app-saml-redirect',
    templateUrl: './saml-redirect.component.html',
    styleUrls: ['./saml-redirect.component.scss'],
})
export class SamlRedirectComponent extends BaseLoginComponent implements OnInit {
    public hasError = false;

    constructor(
        protected override readonly route: ActivatedRoute,
        protected override readonly router: Router,
        protected override readonly accountsService: AccountsService,
        protected override readonly modalService: NgbModal,
        protected readonly translateService: TranslateService,
        wording: WordingService,
        private readonly profileService: ProfileService,
    ) {
        super(route, router, accountsService, modalService, translateService, wording);
    }

    public ngOnInit(): void {
        this.returnUrl = this.getReturnUrl();

        this.route.queryParams.subscribe((params) => {
            if (params['authentication']) {
                this.samlLogin(params['authentication']);
            } else {
                this.router.navigate(['login']);
            }
        });
    }

    public samlLogin(authentication: string) {
        const authenticationResult = new SamlAuthenticateRequest(authentication);
        this.accountsService.saveUser(authenticationResult.authenticationResult);

        this.profileService.getCurrentProfile().subscribe(
            (user) => {
                const authenticationResult = new SamlAuthenticateRequest(authentication, user);
                const authenticatedUser = this.accountsService.saveUser(authenticationResult.authenticationResult);

                if (authenticatedUser) {
                    this.onLogedinSuccessfully(authenticatedUser);
                } else {
                    this.hasError = true;
                }
            },
            () => {
                this.hasError = true;
            },
        );
    }
}
