import { Directive, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseEventCommand } from "../../models/events/base-event-command.model";
import { EventFormStepOneComponent } from "../components/event-form-step-one/event-form-step-one.component";
import { EventFormStepThreeComponent } from "../components/event-form-step-three/event-form-step-three.component";
import { EventFormStepTwoComponent } from "../components/event-form-step-two/event-form-step-two.component";

@Directive()
export abstract class BaseEventFormPage<TCommand extends BaseEventCommand> {

    constructor(
        protected readonly modalService: NgbModal,
    ) { }

    @ViewChild('eventFormStepOne', { static: false })
    private eventFormStepOne: EventFormStepOneComponent;

    @ViewChild('eventFormStepTwo', { static: false })
    private eventFormStepTwo: EventFormStepTwoComponent;

    @ViewChild('eventFormStepThree', { static: false })
    private eventFormStepThree: EventFormStepThreeComponent;

    @ViewChild('publishModal', { static: false })
    public publishModal: TemplateRef<HTMLDivElement>;

    // TODO: update name in HTML
    public eventCommand: TCommand;

    public currentStep = 1;

    public closePublishModal(): void {
        this.modalService.dismissAll();
    }

    private goToNextStep() {
        this.currentStep++;
        window.scrollTo(0, 0);
    }

    public goToPreviousStep() {
        this.currentStep--;
        window.scrollTo(0, 0);
    }

    public onStepOneSubmitted() {
        this.goToNextStep();
    }

    public onStepTwoSubmitted() {
        this.goToNextStep();
    }

    public onStepThreeSubmitted() {
        this.modalService.open(this.publishModal, { size: 'lg', scrollable: false, backdrop: 'static' });
    }

    public onWizardStepOne() {
        this.updateEventCommandFromStepComponents();
        this.currentStep = 1;
        window.scrollTo(0, 0);
    }

    public onWizardStepTwo() {
        if (this.currentStep > 2) {
            this.goToPreviousStep();
        } else {
            this.eventFormStepOne.onSubmit();
        }
    }

    public onWizardStepThree() {
        this.eventFormStepTwo.onSubmit();
    }

    private updateEventCommandFromStepComponents() {
        this.eventFormStepOne.updateEventCommandFromStepComponent();
        this.eventFormStepTwo.updateEventCommandFromStepComponent();
        this.eventFormStepThree.updateEventCommandFromStepComponent();
    }

    public onStepNext() {
        switch (this.currentStep) {
            case 1: {
                this.eventFormStepOne.onSubmit();
                break;
            }
            case 2: {
                this.eventFormStepTwo.onSubmit();
                break;
            }
        }
    }

    public onPublishRequestClick() {
        if (this.currentStep === 3) {
            this.eventFormStepThree.onSubmit();
        }
    }

    public abstract onPublishConfirmed(): Promise<void>;
}
