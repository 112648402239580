<span class="p-input-icon-left full-width">
    <i class="pi pi-search"></i>
    <input
        type="text"
        pInputText
        class="full-width"
        placeholder="{{ placeholderText }}"
        (input)="onSearchTextChanged($event.target.value)"
        [value]="userInput"
        [disabled]="disabled"
    />
</span>
