import { UserStatusTypes } from '../identity/user-status-types.model';
import { User } from '../identity/user.model';
import { Organization } from './../identity/organization.model';
import { Club } from './club.model';
import { MemberClub } from './member-club.model';

export class Member {
    id: string;

    email: string;

    lastName: string;

    firstName: string;

    jobTitle: string;

    organization: Organization;

    organizationName: string;

    pictureAccessLink: string;

    clubs: MemberClub[];

    fullClubs: Club[];

    active?: boolean;

    status: UserStatusTypes;
    hasCompletedCommunityPresentation: boolean;

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get searchFullName(): string {
        return '';
    }

    public constructor(object?: Partial<Member>) {
        Object.assign(this, object);
    }

    public static sortByFullName = (a: Member, b: Member) => `${a?.firstName} ${a?.lastName}`.localeCompare(`${b?.firstName} ${b?.lastName}`);

    public static createFromUser(user: User): Member {
        const { userId, firstName, lastName, email, pictureAccessLink, active } = user;
        return new Member({
            id: userId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            pictureAccessLink: pictureAccessLink,
            active: active,
        });
    }
}
