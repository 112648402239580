import { FormGroup } from '@angular/forms';

declare module '@angular/forms/' {
    interface FormGroup {
        resetFormCleanly(this: FormGroup): void;
    }
}

FormGroup.prototype.resetFormCleanly = function (this: FormGroup) {
    Object.keys(this.controls).forEach((key) => {
        const control = this.controls[key];
        control.markAsPristine();
        control.markAsUntouched();
        control.setValue(null);
    });
};
