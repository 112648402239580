<div class="d-flex align-items-center w-100">
    <p-autoComplete
        placeholder="{{ 'home.onboardingProfile.interests.search' | translate }}"
        field="name"
        scrollHeight="250px"
        [style]="{ width: '350px' }"
        [(ngModel)]="selectedInterest"
        [virtualScroll]="true"
        [suggestions]="filteredInterests"
        [dropdown]="true"
        [disabled]="user.interests.length === 10"
        [itemSize]="34"
        (onSelect)="addInterest()"
        (completeMethod)="filterInterests($event)"
    >
    </p-autoComplete>
    <span pTooltip="{{ 'home.onboardingProfile.interests.reason' | translate }}" tooltipPosition="top" class="margin-left-auto link underline">
        {{ 'home.onboardingProfile.whyImportant' | translate }}
    </span>
</div>

<div class="selected-interests-input p-p-1 p-mt-2">
    <div class="selected-interest-tag" *ngFor="let interest of user.interests; let i = index">
        {{ interest.name | wtranslate : 'interests' }}
        <i class="pi pi-times-circle remove-interest" (click)="removeInterest(i)"></i>
    </div>
</div>

<div class="subtitle p-mt-5 p-mb-2">
    {{ 'user.interests.topCommunityInterest' | translate }}
    <a class="link" (click)="showSelectInterestsModal()">{{ 'user.interests.linked' | translate }}</a>
</div>

<app-toggle-button-list
    [items]="topTenInterests"
    [selectedItems]="user.interests"
    [translatorKeyValue]="'interests'"
    (itemToggled)="toggleInterest($event)"
>
</app-toggle-button-list>

<p-dialog
    header="Header"
    [(visible)]="isSelectInterestsModalVisible"
    [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [style]="{ width: '50vw', 'max-height': '700px' }"
    appendTo="body"
>
    <ng-template pTemplate="header">
        <span class="font-weight-bold">{{ 'user.interests.linked' | translate }}</span>
    </ng-template>

    <ng-container *ngIf="user.interests.length > 0; else emptyInterests">
        <app-grouped-button-list
            [items]="filteredAvailableInterests"
            [selectedItems]="user.interests"
            [translatorKeyValue]="'interests'"
            [translatorKeyDomain]="'interestDomains'"
            (itemToggled)="toggleValue($event)"
        ></app-grouped-button-list>
    </ng-container>
    <ng-template #emptyInterests>
        <div class="empty-interests-message">
            <p class="text-center mb-3">{{ 'user.interests.noInterestSelected' | translate }}</p>
            <p class="text-center">{{ 'user.interests.pleaseSelectInterest' | translate }}</p>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <button class="p-button" (click)="isSelectInterestsModalVisible = false">
            {{ 'components.button.buttonClose' | translate }}
        </button>
    </ng-template>
</p-dialog>
