<p-confirmPopup [styleClass]="'custom-confirmPopup'"></p-confirmPopup>
<p-dialog
    header="{{ headerTitle }}"
    [(visible)]="isModalDisplayed"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    styleClass="custom-create-room-modal"
    appendTo="body"
>
    <form>
        <div class="input-information-frame" [ngClass]="{ 'disabled-div': !isTitleEditable }">
            <label class="content-label" for="name">{{ 'clubs.createRoomButton.roomName' | translate }}</label>
            <div class="length-information">
                {{ form.controls.title?.value?.length || '0' }}/{{ CLUB_NAME_MAX_LENGTH }}
            </div>
        </div>
        <div
            class="resource-frame"
            [ngClass]="{ 'disabled-div': !isTitleEditable }"
            pTooltip="{{ 'toastMessages.inputUnmodifiable' | translate }}"
            tooltipPosition="top"
            [tooltipDisabled]="isTitleEditable"
        >
            <input
                type="text"
                name="title"
                id="title"
                [placeholder]="'clubs.page.common.createRoomButton.roomNamePlaceholder' | translate"
                class="resource-textarea"
                [formControl]="form.controls.title"
            />
        </div>
        <div
            *ngIf="form.controls.title.invalid && (form.controls.title.dirty || form.controls.title.touched)"
            class="d-block alert alert-danger"
        >
            <div *ngIf="form.controls.title.errors.required">
                {{ 'clubs.page.common.createRoomButton.roomNameRequired' | translate }}
            </div>
            <div *ngIf="form.controls.title.errors.maxlength">
                {{
                    'clubs.page.common.createRoomButton.roomTitleLength'
                        | translate: { maxLength: CLUB_NAME_MAX_LENGTH }
                }}
            </div>
        </div>

        <div class="input-information-frame" [ngClass]="{ 'disabled-div': !isVisibilityEditable }">
            <label class="visibility-label p-mr-2">{{ 'clubs.createRoomButton.hiddenRoom' | translate }}</label>
            <i class="icon-f-mask visibility-icon p-mt-1"></i>
        </div>
        <div
            class="input-information-frame p-mt-0"
            [ngClass]="{ 'disabled-div': !isVisibilityEditable }"
            pTooltip="{{ 'toastMessages.inputUnmodifiable' | translate }}"
            tooltipPosition="top"
            [tooltipDisabled]="isVisibilityEditable"
        >
            <label class="visibility-details-label">{{ visibilityMessage }}</label>
            <p-inputSwitch
                (onChange)="changeVisibilityValue()"
                [(ngModel)]="isHidden"
                name="visibility"
                [disabled]="!isVisibilityEditable"
            ></p-inputSwitch>
        </div>
        <div *ngIf="!isEditMode || !isMainRoom">
            <div class="input-information-frame">
                <label class="visibility-label p-mr-2">{{ 'clubs.createRoomButton.limitPost' | translate }}</label>
                <i class="icon-f-chat visibility-icon p-mt-1"></i>
            </div>
            <div class="input-information-frame p-mt-0">
                <label class="visibility-details-label">{{ limitPublicationsMessage }}</label>
                <p-inputSwitch
                    (onChange)="changePublicationSettingValue()"
                    [(ngModel)]="publicationsAreLimited"
                    name="publicationSettings"
                ></p-inputSwitch>
            </div>
        </div>
    </form>
    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="closeModal()"
        ></button>

        <button
            pButton
            [disabled]="form.invalid"
            class="p-button p-button-sm p-button-custom-submit"
            type="button"
            label="{{ (isEditMode ? 'components.button.save' : 'components.button.create') | translate }}"
            (click)="visibilityChangedToHidden ? confirm($event) : handleOnSubmit()"
        ></button>
    </p-footer>
</p-dialog>
