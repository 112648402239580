import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastrHelper } from '../helpers/toastr.helper';
import { AuthenticationCommand } from '../models/identity/authentication-command.model';
import { OAuth2IdentityProviders } from '../models/oauth2/constants/oauth2-identity-providers';
import { AccountsService } from '../services/identity/accounts.service';
import { WordingService } from '../services/shared/wording.service';
import { BaseLoginComponent } from './base-login.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseLoginComponent implements OnInit {
    public cetimOAuthLoginUrl: string;
    public gifasOAuthLoginUrl: string;
    public loginForm: FormGroup;
    public mail = '';
    public maintenance = false;
    public psiOAuthLoginUrl: string;
    public submitted = false;
    public title = environment.settings.title;

    constructor(
        readonly formBuilder: FormBuilder,
        override readonly route: ActivatedRoute,
        override readonly router: Router,
        readonly toastrHelper: ToastrHelper,
        override readonly accountsService: AccountsService,
        override readonly modalService: NgbModal,
        override readonly translate: TranslateService,
        wordingService: WordingService,
    ) {
        super(route, router, accountsService, modalService, translate, wordingService);
    }

    // convenience getter for easy access to form fields
    public get f() {
        return this.loginForm.controls;
    }

    public checkResetPasswordEligibility() {
        const emailControl = this.f.email;

        if (emailControl.hasError('required')) {
            this.toastrHelper.showError('Veuillez saisir une adresse mail afin de demander un nouveau mot de passe.');
        }

        if (emailControl.hasError('email')) {
            this.toastrHelper.showError("L'adresse mail saisie n'est pas valide.");
        }

        this.mail = emailControl.value;
    }

    public isEligibleForPasswordResetting(): boolean {
        return this.f.email.valid;
    }

    public ngOnInit() {
        this.checkMaintenance();

        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });

        // get return url from route parameters or default to '/' (null)
        this.initReturnUrl();

        // If user is already logged in, redirect to returnUrl or home page
        if (this.accountsService.isAuthenticated) {
            if (this.returnUrl) {
                this.router.navigateByUrl(this.returnUrl);
            } else {
                this.router.navigateByUrl('/');
            }
        }

        this.generateOAuth2Providers();
        this.generateOAuth2StateParameter();
        this.gifasOAuthLoginUrl = this.defineGifasOAuth2LoginUrl();
        this.cetimOAuthLoginUrl = this.defineCetimOAuth2LoginUrl();
        this.psiOAuthLoginUrl = this.definePSIOAuthLoginUrl();

        // read provider from route
        const provider = this.route.snapshot.queryParams['community'];

        if (provider) {
            this.getAuthLoginUrlForSsoUsers(provider);
        }

        return false;
    }

    public onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            this.submitted = false;
            return;
        }

        const authenticationCommand = new AuthenticationCommand();
        authenticationCommand.email = this.f.email.value.toLowerCase();
        authenticationCommand.password = this.f.password.value;

        this.accountsService
            .login(authenticationCommand)
            .pipe(first())
            .subscribe(
                (user) => {
                    this.onLogedinSuccessfully(user);
                },
                (error) => {
                    this.submitted = false;
                },
            );
    }

    public shouldDisplayOAuthLoginOptions() {
        return Object.keys(this.oAuthProviders).length > 0;
    }

    private checkMaintenance() {
        this.maintenance = environment.features.maintenance;
        if (this.maintenance && /goto_wudo@alona/i.test(window.location.search || '')) {
            this.maintenance = false;
        }
    }

    private getAuthLoginUrlForSsoUsers(provider: string) {
        const providerToUrlMap = {
            [OAuth2IdentityProviders.CETIM.valueOf()]: this.cetimOAuthLoginUrl,
            [OAuth2IdentityProviders.GIFAS.valueOf()]: this.gifasOAuthLoginUrl,
            [OAuth2IdentityProviders.PSI.valueOf()]: this.psiOAuthLoginUrl,
            [OAuth2IdentityProviders.IKIGAI.valueOf()]: 'https://www.ikigai-ecampus.com/login/index.php',
            [OAuth2IdentityProviders.DELFINGEN.valueOf()]: 'https://portal.delfingen.com/',
        };

        const url = providerToUrlMap[provider.toUpperCase()];
        if (url) {
            window.location.href = url;
        }
    }

    private showMessageRecentColorsChange() {
        this.toastrHelper.showAnnouncement('👋 Bienvenue, \nWudo change de couleurs ! Découvrez la nouvelle version de la plateforme');
    }
}
