import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { Project } from '../../../models/projects/project.model';
import { ProjectsService } from '../../../services/projects/projects.service';
import { ImageService } from '../../../services/shared/image.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-project-list-tab',
    templateUrl: './project-list-tab.component.html',
})
export class ProjectListTabComponent implements OnInit, OnChanges {
    @Input() status: string;
    @Input() community: string;
    @Input() organization: string;

    projects: Project[];
    projectsLoaded = false;

    page: number = 1;
    nextPage: boolean = false;
    dictionary = environment.dictionary;

    constructor(
        private readonly projectService: ProjectsService,
        private readonly toastrHelper: ToastrHelper,
        public imageService: ImageService,
    ) {}

    ngOnInit() {
        this.loadProjects(false);
    }

    ngOnChanges(changes: SimpleChanges) {
        // changes.prop contains the old and the new value..
        if (
            changes.community &&
            changes.community.currentValue &&
            changes.community.previousValue &&
            changes.community.currentValue != changes.community.previousValue
        ) {
            this.projects = [];
            this.loadProjects(false);
        }
    }

    onScroll() {
        if (!this.nextPage || !this.projectsLoaded) {
            return;
        }

        this.page++;
        this.loadProjects(true);
    }

    loadProjects(isPushMode: boolean) {
        if (!isPushMode) {
            this.page = 1;
            this.projects = [];
        }

        var fn2Call = () =>
            this.community
                ? this.projectService.getProjectsByCommunity(this.status, this.page, this.community)
                : this.organization
                ? this.projectService.getProjectsByOrganization(this.status, this.page, this.organization)
                : this.projectService.getProjects(this.status, this.page);

        this.projectsLoaded = false;
        fn2Call().subscribe(
            (data) => {
                this.nextPage = data?.length > 0;

                data?.forEach((project) => {
                    if (this.projects?.findIndex((existingProject) => existingProject.id == project.id) < 0)
                        this.projects.push(project);
                });
                this.projectsLoaded = true;
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
        );
    }
}
