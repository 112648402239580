import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ClubActivityService } from '../../../services/club-activity.service';
import { ClubRoles } from './../../../../models/clubs/club-roles.model';
import { ClubRoom } from './../../../../models/clubs/club-room.model';
import { Club } from './../../../../models/clubs/club.model';
import { CLUB_VISIBILITIES } from './../../../../models/clubs/constants/club-visibilities';
import { ClubsStore } from './../../../clubs.store';

@Component({
    selector: 'room-navigation-item',
    templateUrl: './room-navigation-item.component.html',
    styleUrls: ['./room-navigation-item.component.scss'],
})
export class RoomNavigationItemComponent implements OnInit {
    public CLUB_VISIBILITIES = CLUB_VISIBILITIES;
    @Input() public clubRole: ClubRoles;
    public clubRoles = ClubRoles;
    public countClubNotifications: number = 0;
    @Input() public isMainRoom: boolean;
    @Input() public isSelected: boolean;
    @Output()
    public onRoomCreation = new EventEmitter();
    @Output()
    public onRoomDeleted = new EventEmitter<Club>();
    @Input()
    public room: ClubRoom;
    @Input() public roomRole: ClubRoles;

    constructor(readonly clubActivityService: ClubActivityService, public readonly clubsStore: ClubsStore) {}

    public get hasCategory() {
        return !!this.room.category;
    }

    public ngOnInit(): void {
        this.clubActivityService.memberClubsActivity$.subscribe((clubsActivity) => {
            const clubActivity = clubsActivity.find((clubActivity) => clubActivity.clubId === this.room.id);
            if (clubActivity) {
                this.countClubNotifications = this.clubActivityService.newActivityCountByClubId(this.room.id, true);
            }
        });
    }

    public onRoomSelected() {
        this.clubsStore.isOnePostPageDisplayed.emit(false);
    }

    public roomCreation(newRoom: Club) {
        this.onRoomCreation.emit(newRoom);
    }

    public roomDeleted(roomToDelete: Club) {
        this.onRoomDeleted.emit(roomToDelete);
    }
}
