import { AccountsService } from './../services/identity/accounts.service';
import { OrganizationAsUserProperty } from './../models/identity/organization-as-user-property.model';
import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './administration-organizations.component.html',
})
export class AdministrationOrganizationsComponent implements OnInit {
    public organizations: OrganizationAsUserProperty[] = [];

    constructor(public accountsService: AccountsService) {}

    ngOnInit() {
        this.organizations = this.accountsService.getOrganizationLeadedByUser();
        if (this.organizations && this.organizations.length > 0)
            this.changeOrganizationIdInService(this.organizations[0].id);
    }

    onChangeOrganization(event: any) {
        this.accountsService.currentOrganizationId = event;
        for (var organization of this.organizations) {
            if (organization.id == this.accountsService.currentOrganizationId) {
                this.changeOrganizationIdInService(organization.id);
                return;
            }
        }
    }

    changeOrganizationIdInService(newId: string) {
        this.accountsService.changeCurrentOrganizationId(newId);
    }
}
