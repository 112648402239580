import { Community } from '../identity/community.model';
import { OrganizationBase } from '../identity/organization-base.model';
import { Interest } from '../skills/interest.model';
import { Skill } from '../skills/skill.model';
import { CollaborationPlatformTypeEnum } from './collaboration-platform-type.enum.model';

export class BaseProjectCommand {
    name: string;
    startDate: Date;
    endDate: Date;
    projectTypeId: string;
    synthesis: string;
    context: string;
    goals: string[];
    contributions: string;
    description: string;
    organizationVisibility: boolean;
    organization: OrganizationBase;
    allCommunitiesVisibility: boolean;
    communities: Community[];
    searchedSkills: Skill[];
    associatedInterests: Interest[];
    picture: File;
    picturePreviewUrl: string | ArrayBuffer;
    collaborationPlatformType: CollaborationPlatformTypeEnum;
    collaborationPlatformUrl: string;
    isAnonymous: boolean;
    isPublic: boolean;
    publicJoinUrl: string;
}
