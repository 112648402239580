import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrHelper } from '../../../../../helpers/toastr.helper';
import { ProfessionalAttribute } from '../../../../../models/skills/professional-attribute.model';

@Component({
    selector: 'app-toggle-button-list',
    template: `
        <div class="items-container">
            <button
                *ngFor="let item of items"
                (click)="toggleValue(item)"
                [ngClass]="{
                    'selected-item': isSelected(item),
                    'available-item': !isSelected(item)
                }"
                class="item-button"
            >
                {{ translatorKeyValue === '' ? item.name : (item.name | wtranslate : translatorKeyValue) }}
            </button>
        </div>
    `,
    styleUrls: ['./toggle-button-list.component.scss'],
})
export class ToggleButtonListComponent {
    @Output()
    public itemToggled = new EventEmitter<ProfessionalAttribute>();
    @Input()
    public items: ProfessionalAttribute[];
    public maxSelectedItems = 10;
    @Input()
    public selectedItems: ProfessionalAttribute[];

    @Input()
    public translatorKeyValue: string = '';

    constructor(private readonly toastrHelper: ToastrHelper) {}

    public get canSelectItem(): boolean {
        return this.selectedItems.length < this.maxSelectedItems;
    }

    public isSelected(item: ProfessionalAttribute): boolean {
        return this.selectedItems.some((s) => s.id === item.id);
    }

    public toggleValue(item: ProfessionalAttribute) {
        if (this.canSelectItem || this.isSelected(item)) {
            this.itemToggled.emit(item);
        } else {
            this.toastrHelper.showWarning("Nombre maximum d'éléments sélectionnés.");
        }
    }
}
