import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { FiltersItem } from '../../../models/shared/filters-item.model';
import { InterestDomain } from '../../../models/skills/interest-domain.model';
import { Interest } from '../../../models/skills/interest.model';
import { InterestsService } from '../../../services/skills/interests.service';
import { delay } from 'rxjs/operators';
import { WudoTranslatePipe } from '../../../pipes/wudo-translate.pipe';

@Component({
    selector: 'app-interests',
    templateUrl: './interests.component.html',
    styleUrls: ['./interests.component.scss'],
})
export class InterestsComponent implements OnInit {
    // Set this variable to 'profile' or 'project' to change component titles.
    @Input() context = 'default';

    @Input() hideTitle = false;
    @Input() currentInterests: Interest[] = [];
    @Input() maxInterests = 10;

    @Input() interestsDomainPlaceholder = '';
    @Input() interstsPlaceHolder = '';

    @Output() changed = new EventEmitter<Interest[]>();

    dataLoaded: Promise<boolean>;
    get maximumInterests(): boolean {
        return this.currentInterests?.length >= this.maxInterests;
    }

    private interests: Interest[];
    interestsFilterItems: FiltersItem[];
    private interestDomains: InterestDomain[];
    interestDomainsFilterItems: FiltersItem[];

    get visibleInterests(): FiltersItem[] {
        return this.interestsFilterItems.filter((option) => option.visible);
    }

    get visibleInterestDomains(): FiltersItem[] {
        return this.interestDomainsFilterItems.filter((option) => option.visible);
    }

    constructor(
        private readonly toastrHelper: ToastrHelper,
        private readonly interestService: InterestsService,
        private readonly wtranslate: WudoTranslatePipe,
    ) {}

    ngOnInit() {
        forkJoin(this.interestService.getAllInterestDomains(), this.interestService.getAllInterests())
            .pipe(delay(500))
            .subscribe(
                ([interestsDomain, interests]) => {
                    this.interestDomains = interestsDomain;
                    this.interestDomainsFilterItems = this.interestDomains.map(
                        (domain) =>
                            new FiltersItem(domain.id, this.wtranslate.transform(domain.name, 'interestDomains')),
                    );
                    this.interestDomainsFilterItems.forEach((item) => (item.visible = true));

                    this.interests = interests;
                    this.interestsFilterItems = this.interests.map(
                        (interest) =>
                            new FiltersItem(interest.id, this.wtranslate.transform(interest.name, 'interests')),
                    );

                    this.dataLoaded = Promise.resolve(true);
                },
                (error) => {
                    this.toastrHelper.showGenericError(error);
                },
            );
    }
    onInterestDomainSearchTextChanged(searchText: string) {
        this.interestDomainsFilterItems.forEach((interestDomain) => {
            interestDomain.visible =
                !searchText ||
                interestDomain.checked ||
                interestDomain.value.trim().toLowerCase().includes(searchText.trim().toLowerCase());
        });
    }

    onInterestSearchTextChanged(searchText: string) {
        if (this.interestDomainsFilterItems.filter((option) => option.checked).length === 0) {
            this.interestsFilterItems.forEach((item) => {
                item.visible = item.value.trim().toLowerCase().includes(searchText.trim().toLowerCase()) || !searchText;
            });
        } else {
            this.interestsFilterItems.forEach((item) => {
                item.visible =
                    this.interestDomainsFilterItems
                        .filter((option) => option.checked)
                        .map((domain) => domain.key)
                        .includes(this.interests.find((interest) => interest.id === item.key).domainId) &&
                    item.value.trim().toLowerCase().includes(searchText.trim().toLowerCase());
            });
        }
    }

    onCheckedInterestDomain() {
        if (this.interestDomainsFilterItems.filter((option) => option.checked).length === 0) {
            this.interestsFilterItems.forEach((item) => (item.visible = true));
        } else {
            this.interestsFilterItems.forEach((item) => {
                item.visible = this.interestDomainsFilterItems
                    .filter((option) => option.checked)
                    .map((domain) => domain.key)
                    .includes(this.interests.find((interest) => interest.id === item.key).domainId);
            });
        }
    }

    checkIfHasInterest(interestId: string): boolean {
        return this.currentInterests?.some((interest) => interest.id === interestId) || false;
    }

    addInterest(interestId: string) {
        const interestFound = this.interests.find((interest) => interest.id === interestId);
        if (interestFound) {
            if (!this.currentInterests) {
                this.currentInterests = [];
            }
            this.currentInterests.push(interestFound);
            this.currentInterests.sort((a, b) => a.name.localeCompare(b.name));
            this.changed.emit(this.currentInterests);
        }
    }

    removeInterest(interestId: string) {
        this.currentInterests = this.currentInterests?.filter((interest) => interest.id !== interestId) || [];
        this.changed.emit(this.currentInterests);
    }
}
