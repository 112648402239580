import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AddressResult, GeoJsonPoint } from '../../../azure/map/models/address.model';
import { AddressSearchService } from '../../../azure/map/services/address.service';
import { regexPatterns } from '../../../constants/regex-patterns';
import { FieldDisablerHelper, FieldType } from '../../../helpers/fieldDisabler.helper';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { GeographicalArea } from '../../../models/identity/geographical-area.model';
import { Organization } from '../../../models/identity/organization.model';
import { UpdateProfileCommand } from '../../../models/identity/update-profile-command.model';
import { User } from '../../../models/identity/user.model';
import { GeographicalAreaService } from '../../../services/identity/geographical-area.service';
import { OrganizationService } from '../../../services/identity/organization.service';

@Component({
    selector: 'app-user-personal-informations',
    templateUrl: './user-personal-informations.component.html',
})
export class UserPersonalInformationsComponent implements OnInit {
    public FieldType = FieldType;
    @Output() public formReady = new EventEmitter<FormGroup>();
    public geographicalAreas: GeographicalArea[];
    selectedCountrySuggestion: any = null;
    public loadingText = 'Chargement ...';
    public notFilledInText = this.translate.instant('shared.notFilledIn');
    public organization: Organization;
    @Input() public profileCommand: UpdateProfileCommand;
    @Input() public title: string;
    @Input() public user: User;

    public userForm = this.formBuilder.group({
        jobTitle: [{ value: '' }, [Validators.maxLength(100)]],
        officePhone: [{ value: '' }, [Validators.maxLength(21), Validators.pattern(regexPatterns.phoneNumber)]],
        mobilePhone: [{ value: '' }, [Validators.maxLength(21), Validators.pattern(regexPatterns.phoneNumber)]],
        geographicalAreaId: [{ value: '' }, [Validators.maxLength(50)]],
        city: [{ value: '' }, [Validators.maxLength(50)]],
        location: this.formBuilder.group({
            type: ['Point'],
            coordinates: [[]],
        }),
        country: [{ value: '' }, [Validators.maxLength(50)]],
        linkedIn: [{ value: '' }, [Validators.maxLength(100), Validators.pattern(regexPatterns.LinkedInProfileUrl)]],
        description: [{ value: '' }, [Validators.maxLength(2600)]],
    });

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly toastrHelper: ToastrHelper,
        private readonly geographicalAreaService: GeographicalAreaService,
        private readonly organizationService: OrganizationService,
        public readonly fieldDisablerHelper: FieldDisablerHelper,
        private translate: TranslateService,
        private addressService: AddressSearchService,
    ) {}

    public formatData() {
        if (this.userForm.controls.officePhone.value === '') {
            this.userForm.controls.officePhone.setValue(null);
        }
        if (this.userForm.controls.mobilePhone.value === '') {
            this.userForm.controls.mobilePhone.setValue(null);
        }
        if (this.userForm.controls.jobTitle.value === '') {
            this.userForm.controls.jobTitle.setValue(null);
        }
        if (this.userForm.controls.linkedIn.value === '') {
            this.userForm.controls.linkedIn.setValue(null);
        }
        if (this.userForm.controls.description.value === '') {
            this.userForm.controls.description.setValue(null);
        }
        if (this.userForm.controls.city.value === '') {
            this.userForm.controls.city.setValue(null);
        }
        if (this.userForm.controls.country.value === '') {
            this.userForm.controls.country.setValue(null);
        }
    }

    public isValid(): boolean {
        const errors = new Array<string>();

        if (this.userForm.controls.jobTitle.value?.length > 100) {
            errors.push('Veuillez vérifier votre saisie (maximum 100 caractères pour la fonction).');
        }
        if (this.userForm.controls.city.value?.length > 50) {
            errors.push('Veuillez vérifier votre saisie (maximum 50 caractères pour la ville).');
        }
        if (this.userForm.controls.country.value?.length > 50) {
            errors.push('Veuillez vérifier votre saisie (maximum 50 caractères pour le pays).');
        }
        if (this.userForm.controls.officePhone.value?.length > 21) {
            errors.push('Veuillez vérifier votre saisie (maximum 21 caractères pour le n° de téléphone bureau).');
        }
        if (this.userForm.controls.officePhone.invalid) {
            errors.push('Veuillez vérifier votre saisie (le format du n° de téléphone bureau est invalide).');
        }
        if (this.userForm.controls.mobilePhone.value?.length > 21) {
            errors.push('Veuillez vérifier votre saisie (maximum 21 caractères pour le n° de téléphone mobile).');
        }
        if (this.userForm.controls.mobilePhone.invalid) {
            errors.push('Veuillez vérifier votre saisie (le format du n° de téléphone mobile est invalide ).');
        }
        if (this.userForm.controls.linkedIn.value?.length > 100) {
            errors.push('Veuillez vérifier votre saisie (le format du lien LinkedIn est invalide).');
        }
        if (this.userForm.controls.description.value?.length > 2600) {
            errors.push('Veuillez vérifier votre saisie (maximum 2600 caractères pour la description).');
        }

        errors.forEach((errorMsgs) => this.toastrHelper.showError(errorMsgs));
        if (errors.length === 0) {
            this.formatData();
        }
        return errors.length === 0;
    }

    public ngOnInit() {
        this.userForm.controls.officePhone.setValue(this.loadingText);
        this.userForm.controls.mobilePhone.setValue(this.loadingText);
        this.userForm.controls.city.setValue(this.loadingText);
        this.userForm.controls.country.setValue(this.loadingText);
        this.userForm.controls.jobTitle.setValue(this.loadingText);
        this.userForm.controls.linkedIn.setValue(this.loadingText);
        this.userForm.controls.description.setValue(this.loadingText);

        this.geographicalAreaService.getGeographicalAreas().subscribe(
            (geographicalAreas) => {
                this.geographicalAreas = geographicalAreas;

                this.userForm.controls.officePhone.setValue(this.profileCommand.officePhone);
                this.userForm.controls.mobilePhone.setValue(this.profileCommand.mobilePhone);
                this.userForm.controls.city.setValue(this.profileCommand.city);
                this.userForm.controls.country.setValue(this.profileCommand.country);
                this.userForm.controls.geographicalAreaId.setValue(this.profileCommand.geographicalAreaId);
                this.userForm.controls.jobTitle.setValue(this.profileCommand.jobTitle);
                this.userForm.controls.linkedIn.setValue(this.profileCommand.linkedIn);
                this.userForm.controls.description.setValue(this.profileCommand.description);

                if (this.profileCommand?.location?.coordinates) {
                    this.userForm.patchValue({
                        location: this.profileCommand.location,
                    });
                }

                this.formReady.emit(this.userForm);

                this.user.geographicalArea = (this.geographicalAreas || []).find(
                    (geographicalArea) => geographicalArea.id === this.user.geographicalAreaId,
                );

                this.addressService.searchCountries(this.userForm.get('country').value).subscribe((countries) => {
                    this.selectedCountrySuggestion = countries[0];
                });
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
        );

        if (this.user?.organization?.id) {
            this.organizationService.getOrganizationById(this.user.organization.id).subscribe(
                (data) => {
                    this.organization = data;
                },
                (error) => {
                    console.error(error);
                    this.toastrHelper.showGenericError(error);
                },
            );
        }
    }

    onCitySelected(city: AddressResult) {
        if (!city?.position?.lat || !city?.position?.lon) {
            return;
        }

        const geoJsonPoint: GeoJsonPoint = {
            type: 'Point',
            coordinates: [city.position.lon, city.position.lat],
        };

        this.userForm.patchValue({
            city: city.address.municipality,
            location: geoJsonPoint,
        });

        this.profileCommand.city = city.address.municipality;
        this.profileCommand.location = geoJsonPoint;
    }

    onCountrySelected(suggestion: any) {
        if (suggestion) {
            this.selectedCountrySuggestion = suggestion;
            this.userForm.get('city').setValue('');
        }
    }
}
