import { Directive, EventEmitter, Output } from "@angular/core";

@Directive()
export abstract class BaseFiltersItem {
    @Output() ready = new EventEmitter();
    @Output() selectionChanged = new EventEmitter();

    public abstract resetFilters(): void;

    public onSelectionChanged() {
        this.selectionChanged.emit();
    }
}
