<!-- NAV Organizations -->
<nav class="navbar navbar-expand-lg navbar-light subnav justify-content-center">
    <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/administration-organizations/mine']">{{
                'administration.organizations.nav.myOrganization' | translate
            }}</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/administration-organizations/projects']">{{
                'administration.organizations.nav.myOrganizationProjects' | translate
            }}</a>
        </li>
    </ul>
</nav>
<!-- /nav Organizations -->
