<div class="card-body">
    <div class="row align-items-end">
        <div class="col-auto">
            <img src="./assets/images/pwa-install.svg" class="card-img-top" alt="Ajouter WuDo à l'écran d'accueil" />
        </div>
        <div class="col">
            <p>Souhaitez-vous ajouter {{ applicationCode }} sur l'écran d'accueil de votre téléphone?</p>
        </div>
    </div>
    <div *ngIf="isIos" class="row align-items-start">
        <div class="col">
            <p>
                Cliquez sur le bouton partager
                <img
                    class="icon-ios"
                    src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy5
                    3My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMC44OCAyNy4yNSI+PGRlZnM+PHN0eWxlPi
                    5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiMyMzFmMjA7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5TY
                    WZhcmlfU2hhcmU8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTMuMTMg
                    OCAyMC4zOCA4IDIwLjM4IDI2Ljc1IDAuNSAyNi43NSAwLjUgOCA3LjUgOCIvPjxsaW5lIGNsYXN
                    zPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMTciIHgyPSIxMC40NCIvPjxsaW5lIGNsYXNzPSJjbH
                    MtMSIgeDE9IjEwLjQ4IiB5MT0iMC4zOCIgeDI9IjE1LjI4IiB5Mj0iNS4xOCIvPjxsaW5lIGNsY
                    XNzPSJjbHMtMSIgeDE9IjEwLjQ0IiB5MT0iMC4zOCIgeDI9IjUuNjQiIHkyPSI1LjE4Ii8+PC9z
                    dmc+"
                    alt="Bouton partager"
                />de votre navigateur, puis sur le bouton "Sur l'écran d'accueil"
                <img
                    class="icon-ios"
                    src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIiB4bWxucz0iaHR0cDovL3d3dy5
                    3My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNSAzNSI+PHRpdGxlPlNhZmFyaV9BMkhTPC
                    90aXRsZT48cmVjdCB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIHJ4PSI4IiByeT0iOCIgc3R5bGU9I
                    mZpbGw6IzhmOGY4ZiIvPjxsaW5lIHgxPSIyNC43NSIgeTE9IjE3LjUiIHgyPSIxMC4yNSIgeTI9
                    IjE3LjUiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjx
                    saW5lIHgxPSIxNy41IiB5MT0iMTAuMjUiIHgyPSIxNy41IiB5Mj0iMjQuNzUiIHN0eWxlPSJmaW
                    xsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLXdpZHRoOjJweCIvPjwvc3ZnPg=="
                    alt="Bouton sur l'écran d'accueil"
                />
            </p>
        </div>
    </div>
    <div class="d-flex justify-content-end mt-2">
        <button *ngIf="maxPrompt" type="button" (click)="cancelAddToHomeScreen()" class="btn btn-secondary">
            Ne plus demander
        </button>
        <button *ngIf="!maxPrompt" type="button" (click)="cancelAddToHomeScreen()" class="btn btn-secondary">
            Plus tard
        </button>
        <button *ngIf="!isIos" type="button" (click)="addToHomeScreen()" class="btn btn-primary ml-1">Oui</button>
        <button *ngIf="isIos" type="button" (click)="trackInstalled()" class="btn btn-primary ml-1">
            J'ai compris
        </button>
    </div>
</div>
