import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CommunityFiltersComponent } from './community-filters/community-filters.component';
import { ExperienceFiltersDropdownComponent } from './community-filters/components/experience-filters-dropdown/experience-filters-dropdown.component';
import { InterestsFiltersDropdownComponent } from './community-filters/components/interests-filters-dropdown/interests-filters-dropdown.component';
import { LocationFiltersDropdownComponent } from './community-filters/components/location-filters-dropdown/location-filters-dropdown.component';
import { SelectFiltersDropdownComponent } from './community-filters/components/select-filters-dropdown/select-filters-dropdown.component';
import { EventOnlineAccessTypeCheckboxComponent } from './event-filters/components/event-online-access-type-checkbox/event-online-access-type-checkbox.component';
import { EventPhysicalAccessTypeCheckboxComponent } from './event-filters/components/event-physical-access-type-checkbox/event-physical-access-type-checkbox.component';
import { EventTypesFiltersDropdownComponent } from './event-filters/components/event-types-filters-dropdown/event-types-filters-dropdown.component';
import { EventFiltersComponent } from './event-filters/event-filters.component';
import { JoinedEventsCanceledCheckboxComponent } from './my-events-filters/components/joined-events-canceled-checkbox/joined-events-canceled-checkbox.component';
import { JoinedEventsComingCheckboxComponent } from './my-events-filters/components/joined-events-coming-checkbox/joined-events-coming-checkbox.component';
import { JoinedEventsDoneCheckboxComponent } from './my-events-filters/components/joined-events-done-checkbox/joined-events-done-checkbox.component';
import { JoinedEventsOngoingCheckboxComponent } from './my-events-filters/components/joined-events-ongoing-checkbox/joined-events-ongoing-checkbox.component';
import { OwnedEventsCanJoinCheckboxComponent } from './my-events-filters/components/owned-events-can-join-checkbox/owned-events-can-join-checkbox.component';
import { OwnedEventsCanceledCheckboxComponent } from './my-events-filters/components/owned-events-canceled-checkbox/owned-events-canceled-checkbox.component';
import { OwnedEventsDoneCheckboxComponent } from './my-events-filters/components/owned-events-done-checkbox/owned-events-done-checkbox.component';
import { OwnedEventsFullCheckboxComponent } from './my-events-filters/components/owned-events-full-checkbox/owned-events-full-checkbox.component';
import { OwnedEventsOngoingCheckboxComponent } from './my-events-filters/components/owned-events-ongoing-checkbox/owned-events-ongoing-checkbox.component';
import { MyEventsFiltersComponent } from './my-events-filters/my-events-filters.component';
import { BusinessAreaFiltersDropdownComponent } from './organizations-filters/components/business-area-filters-dropdown/business-area-filters-dropdown.component';
import { GeographicalFiltersDropdownComponent } from './organizations-filters/components/geographical-filters-dropdown/geographical-filters-dropdown.component';
import { OrganizationsFiltersComponent } from './organizations-filters/organizations-filters.component';
import { InterestsFiltersCheckboxComponent } from './project-filters/components/interests-filters-checkbox/interests-filters-checkbox.component';
import { OrganizationSkillsFiltersCheckboxComponent } from './project-filters/components/organization-skills-filters-checkbox/organization-skills-filters-checkbox.component';
import { ProjectParticipateFiltersDropdownComponent } from './project-filters/components/project-participate-filters-dropdown/project-participate-filters-dropdown.component';
import { ProjectTypesFiltersDropdownComponent } from './project-filters/components/project-types-filters-dropdown/project-types-filters-dropdown.component';
import { SkillsFiltersCheckboxComponent } from './project-filters/components/skills-filters-checkbox/skills-filters-checkbox.component';
import { ProjectFiltersComponent } from './project-filters/project-filters.component';
import { AllCommunitiesFilterCheckboxComponent } from './shared/all-communities-filter-checkbox/all-communities-filter-checkbox.component';
import { CommunitiesFiltersDropdownComponent } from './shared/communities-filters-dropdown/communities-filters-dropdown.component';
import { CustomSearchFilterComponent } from './shared/custom-search-filter/custom-search-filter.component';
import { FiltersCheckboxItemComponent } from './shared/filters-checkbox-item/filters-checkbox-item.component';
import { FiltersDropdownItemComponent } from './shared/filters-dropdown-item/filters-dropdown-item.component';
import { OrganizationFiltersCheckboxComponent } from './shared/organization-filters-checkbox/organization-filters-checkbox.component';
import { SkillsFiltersDropdownComponent } from './shared/skills-filters-dropdown/skills-filters-dropdown.component';
import { UsersFiltersComponent } from './users-filters/users-filters.component';

@NgModule({
    declarations: [
        ProjectFiltersComponent,
        OrganizationFiltersCheckboxComponent,
        InterestsFiltersCheckboxComponent,
        SkillsFiltersCheckboxComponent,
        CommunitiesFiltersDropdownComponent,
        ProjectTypesFiltersDropdownComponent,
        ProjectParticipateFiltersDropdownComponent,
        CommunityFiltersComponent,
        UsersFiltersComponent,
        OrganizationsFiltersComponent,
        ExperienceFiltersDropdownComponent,
        InterestsFiltersDropdownComponent,
        LocationFiltersDropdownComponent,
        SkillsFiltersDropdownComponent,
        FiltersDropdownItemComponent,
        FiltersCheckboxItemComponent,
        OrganizationSkillsFiltersCheckboxComponent,
        GeographicalFiltersDropdownComponent,
        BusinessAreaFiltersDropdownComponent,
        AllCommunitiesFilterCheckboxComponent,
        CustomSearchFilterComponent,
        SelectFiltersDropdownComponent,
        EventFiltersComponent,
        EventTypesFiltersDropdownComponent,
        EventPhysicalAccessTypeCheckboxComponent,
        EventOnlineAccessTypeCheckboxComponent,
        MyEventsFiltersComponent,
        OwnedEventsCanJoinCheckboxComponent,
        OwnedEventsCanceledCheckboxComponent,
        OwnedEventsFullCheckboxComponent,
        OwnedEventsDoneCheckboxComponent,
        JoinedEventsComingCheckboxComponent,
        JoinedEventsCanceledCheckboxComponent,
        JoinedEventsDoneCheckboxComponent,
        JoinedEventsOngoingCheckboxComponent,
        OwnedEventsOngoingCheckboxComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RadioButtonModule,
        MultiSelectModule,
        ChipModule,
        ButtonModule,
        BadgeModule,
        InputTextModule,
        TranslateModule,
    ],
    exports: [
        CommunityFiltersComponent,
        UsersFiltersComponent,
        OrganizationsFiltersComponent,
        ProjectFiltersComponent,
        CustomSearchFilterComponent,
        SelectFiltersDropdownComponent,
        EventFiltersComponent,
        MyEventsFiltersComponent,
    ],
})
export class FiltersModule {}
