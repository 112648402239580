import { Component, OnInit } from '@angular/core';
import { Filters } from '../models/identity/filters.model';
import { DataStorageService } from '../services/shared/data-storage.service';

@Component({
    selector: 'app-directory',
    templateUrl: './directory.component.html',
})
export class DirectoryComponent implements OnInit {
    activeFragment: string;

    get savedFilters(): Filters {
        return this.dataStorage.membersFilters;
    }
    set savedFilters(value: Filters) {
        this.dataStorage.membersFilters = value;
    }

    constructor(public dataStorage: DataStorageService) {}
    ngOnInit() {
        // Deleting filters if any is stored when users enters any directory page
        this.savedFilters = null;
    }
}
