import { ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-file-selector',
    templateUrl: './file-selector.component.html',
    styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent {
    @Input() buttonLabel = 'Add';

    @Input() information: string[] = [];

    @Input() acceptedFormats: string[] = [];

    @Input() control = new FormControl();

    @Output() fileChanged: EventEmitter<File> = new EventEmitter();

    @ViewChild('input') input: ElementRef;

    onFileChange($event: any): void {
        this.control.setValue($event.target.files[0]);
        $event.preventDefault();
        this.input.nativeElement.value = '';
    }

    onFileDrop($event: any): void {
        const event = {
            isTrusted: true,
            target: { accept: '*/*', files: $event },
        };
        this.control.setValue(event.target[0]);
        $event.preventDefault();
        this.input.nativeElement.value = '';
    }
}
