<div class="card-body pl-0 pr-0" [formGroup]="userForm">
    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label for="jobTitle">{{ 'user.personalInfo.position' | translate }}</label>
                <input formControlName="jobTitle" type="text" class="form-control" id="jobTitle" placeholder="Product designer" />
                <div
                    *ngIf="userForm.controls.jobTitle.invalid && (userForm.controls.jobTitle.dirty || userForm.controls.jobTitle.touched)"
                    class="alert alert-danger"
                >
                    <div *ngIf="userForm.controls.jobTitle.errors.maxlength">
                        {{ 'user.personalInfo.positionLength' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label for="officePhone">{{ 'user.personalInfo.officePhone.title' | translate }}</label>
                <input formControlName="officePhone" type="text" class="form-control" id="officePhone" />
                <div
                    *ngIf="userForm.controls.officePhone.invalid && (userForm.controls.officePhone.dirty || userForm.controls.officePhone.touched)"
                    class="alert alert-danger"
                >
                    <div *ngIf="userForm.controls.officePhone.errors.pattern">
                        {{ 'user.personalInfo.officePhone.formatError' | translate }}
                    </div>
                    <div *ngIf="userForm.controls.officePhone.errors.maxlength">
                        {{ 'user.personalInfo.officePhone.length' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label for="mobilePhone">{{ 'user.personalInfo.mobilePhone.title' | translate }}</label>
                <input formControlName="mobilePhone" type="text" class="form-control" id="mobilePhone" />
                <div
                    *ngIf="userForm.controls.mobilePhone.invalid && (userForm.controls.mobilePhone.dirty || userForm.controls.mobilePhone.touched)"
                    class="alert alert-danger"
                >
                    <div *ngIf="userForm.controls.mobilePhone.errors.pattern">
                        {{ 'user.personalInfo.mobilePhone.formatError' | translate }}
                    </div>
                    <div *ngIf="userForm.controls.mobilePhone.errors.maxlength">
                        {{ 'user.personalInfo.mobilePhone.length' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <label for="geographicalArea">{{ 'user.personalInfo.department' | translate }}</label>
                <select formControlName="geographicalAreaId" class="form-control" name="geographicalAreaId" id="geographicalArea" required>
                    <option *ngFor="let geographicalArea of geographicalAreas" [value]="geographicalArea.id">
                        {{ geographicalArea.name }} ({{ geographicalArea.id }})
                    </option>
                </select>
                <div
                    *ngIf="
                        userForm.controls.geographicalAreaId.invalid &&
                        (userForm.controls.geographicalAreaId.dirty || userForm.controls.geographicalAreaId.touched)
                    "
                    class="alert alert-danger"
                >
                    <div *ngIf="userForm.controls.geographicalAreaId.errors.required">
                        {{ 'user.personalInfo.geographicSectorRequired' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6">
            <app-country-autocomplete
                formControlName="country"
                [isRequired]="true"
                (countrySelected)="onCountrySelected($event)"
            ></app-country-autocomplete>
            <div
                *ngIf="userForm.controls.country.invalid && (userForm.controls.country.dirty || userForm.controls.country.touched)"
                class="alert alert-danger"
            >
                <div *ngIf="userForm.controls.country.errors?.maxlength">
                    {{ 'user.personalInfo.country.length' | translate }}
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6">
            <div class="form-group">
                <app-city-autocomplete
                    formControlName="city"
                    [isRequired]="true"
                    [selectedCountry]="selectedCountrySuggestion"
                    (citySelected)="onCitySelected($event)"
                >
                </app-city-autocomplete>
                <div *ngIf="userForm.controls.city.invalid" class="alert alert-danger">
                    <div *ngIf="userForm.controls.city.errors.maxlength">
                        {{ 'user.personalInfo.cityLength' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label for="linkedIn">{{ 'user.personalInfo.linkedIn' | translate }}</label>
                <div class="p-inputgroup">
                    <input formControlName="linkedIn" id="linkedIn" type="text" pInputText class="p-inputtext p-component" />
                    <span class="p-inputgroup-addon">
                        <em class="icon icon-linkedin"></em>
                    </span>
                </div>
                <div *ngIf="userForm.controls.linkedIn.invalid" class="alert alert-danger">
                    <div *ngIf="userForm.controls.linkedIn.errors.maxlength">
                        {{ 'user.personalInfo.linkedInLength' | translate }}
                    </div>
                    <div *ngIf="userForm.controls.linkedIn.errors.pattern">
                        {{ 'user.personalInfo.linkedInFormat' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
