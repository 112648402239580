import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { statusEndpoints } from '../../constants/project-status';
import { MatchingProject } from '../../home/models/matching-project.model';
import { BaseProjectCommand } from '../../models/projects/base-project-command.model';
import { CollaborationPlatformTypeEnum } from '../../models/projects/collaboration-platform-type.enum.model';
import { CreateProjectCommand } from '../../models/projects/create-project-command.model';
import { DraftProjectCommand } from '../../models/projects/draft-project-command.model';
import { ProjectJoinResponse } from '../../models/projects/popup-join-project.model';
import { ProjectFilters } from '../../models/projects/project-filters.model';
import { Project } from '../../models/projects/project.model';
import { RemoveAttendeeCommand } from '../../models/projects/remove-attendee-request';
import { ReportProjectCommand } from '../../models/projects/report-project-command.model';
import { UpdateProjectCommand } from '../../models/projects/update-project-command.model';
import { UpdateProjectOwnerRequest } from '../../models/projects/update-project-owner-request';

@Injectable({ providedIn: 'root' })
export class ProjectsService {
    private idParam = `${environment.microservices.projects.params.id}`;

    private projectsApiUrl = `${environment.microservices.projects.baseUrl}/${environment.microservices.projects.projectsApi.baseUrl}`;
    private filteredProjectsApiUrl = `${this.projectsApiUrl}/${environment.microservices.projects.projectsApi.filters}`;
    private draftProjectsApiUrl = `${this.projectsApiUrl}/${environment.microservices.projects.projectsApi.draft}`;
    private publishProjectApiUrl = `${this.projectsApiUrl}/${this.idParam}/${environment.microservices.projects.projectsApi.publish}`;
    private closeProjectApiUrl = `${this.projectsApiUrl}/${this.idParam}/${environment.microservices.projects.projectsApi.close}`;

    private myProjectsApiUrl = `${environment.microservices.projects.baseUrl}/${environment.microservices.projects.projectsApi.myProjects}`;
    private myInvolvedProjectsApiUrl = `${this.myProjectsApiUrl}/${environment.microservices.projects.projectsApi.involved}`;

    private userInvolvedProjectsApiUrl = `${environment.microservices.projects.baseUrl}/${environment.microservices.projects.projectsApi.userProjects}/${environment.microservices.projects.projectsApi.involved}`;

    private publicProjectApiUrl = `${environment.microservices.projects.baseUrl}/${environment.microservices.projects.publicProjectsApi.baseUrl}`;
    constructor(private http: HttpClient) {}

    getProjects(status: string, page: number): Observable<Project[]> {
        return this.http.get<Project[]>(`${this.projectsApiUrl}/${statusEndpoints[status]}`, {
            params: {
                page: page.toString(),
            },
        });
    }

    getProjectsByOrganization(status: string, page: number, organizationId: string): Observable<Project[]> {
        return this.http.get<Project[]>(`${this.projectsApiUrl}/${statusEndpoints[status]}`, {
            params: {
                page: page.toString(),
                organizationId: organizationId,
            },
        });
    }

    getProjectsByCommunity(status: string, page: number, communityId: string): Observable<Project[]> {
        return this.http.get<Project[]>(`${this.projectsApiUrl}/${statusEndpoints[status]}`, {
            params: {
                page: page.toString(),
                communityId: communityId,
            },
        });
    }

    getFilteredProjects(filters: ProjectFilters): Observable<Project[]> {
        return this.http.post<Project[]>(`${this.filteredProjectsApiUrl}`, filters);
    }

    getUserInvolvedProjects(userId: string, status: string): Observable<Project[]> {
        return this.http.get<Project[]>(`${this.userInvolvedProjectsApiUrl.replace(this.idParam, userId)}/${statusEndpoints[status]}`);
    }

    getMyProjects(status: string): Observable<Project[]> {
        return this.http.get<Project[]>(`${this.myProjectsApiUrl}/${statusEndpoints[status]}`);
    }

    getMyInvolvedProjects(status: string): Observable<Project[]> {
        return this.http.get<Project[]>(`${this.myInvolvedProjectsApiUrl}/${statusEndpoints[status]}`);
    }

    getMatchedProjects(): Observable<MatchingProject[]> {
        return this.http.get<MatchingProject[]>(`${this.myInvolvedProjectsApiUrl}/Match`);
    }

    getProjectById(id: string) {
        return this.http.get<Project>(`${this.projectsApiUrl}/${id}`);
    }

    joinProject(id: string, motivation: string) {
        return this.http.post(`${this.projectsApiUrl}/${id}/Join`, {
            motivation: motivation,
        });
    }

    canJoinProject(id: string) {
        return this.http.get<ProjectJoinResponse>(`${this.projectsApiUrl}/${id}/CanJoin`);
    }

    unjoinProject(id: string) {
        return this.http.post(`${this.projectsApiUrl}/${id}/Unjoin`, null);
    }

    cancelJoinProject(id: string) {
        return this.http.post(`${this.projectsApiUrl}/${id}/CancelJoin`, null);
    }

    acceptAttendee(projectId: string, attendeeId: string) {
        return this.http.post(`${this.projectsApiUrl}/${projectId}/Attendees/${attendeeId}/Accept`, null);
    }

    denyAttendee(projectId: string, attendeeId: string) {
        return this.http.post(`${this.projectsApiUrl}/${projectId}/Attendees/${attendeeId}/Deny`, null);
    }

    UpdateProjectOwner(command: UpdateProjectOwnerRequest) {
        return this.http.put(`${this.projectsApiUrl}/owner`, command);
    }

    RemoveAttendeeFromProject(command: RemoveAttendeeCommand) {
        return this.http.post(`${this.projectsApiUrl}/Attendees/RemoveAttendeeFromProject`, command);
    }

    createProject(command: CreateProjectCommand) {
        return this.http.post(this.projectsApiUrl, this.getFormData(command));
    }

    createAndPublishProject(command: CreateProjectCommand) {
        return this.http.post(this.projectsApiUrl + '/publish', this.getFormData(command));
    }

    draftProject(id: string, command: DraftProjectCommand) {
        const formData = this.getFormData(command);

        if (id) {
            formData.append('id', id);
        }

        return this.http.post(this.draftProjectsApiUrl, formData);
    }

    updateProject(command: UpdateProjectCommand, myProjects: boolean) {
        const formData = this.getFormData(command);
        formData.append('id', command.id);

        if (myProjects) {
            formData.append('myProjects', myProjects.toString());
        }

        if (command.asCommunityManager) {
            formData.append('asCommunityManager', 'true');
        }

        if (command.asOrganizationLeader) {
            formData.append('asOrganizationLeader', 'true');
        }

        return this.http.put(this.projectsApiUrl, formData);
    }

    updateAndPublishProject(command: UpdateProjectCommand) {
        const formData = this.getFormData(command);
        formData.append('id', command.id);

        if (command.asCommunityManager) {
            formData.append('asCommunityManager', 'true');
        }

        if (command.asOrganizationLeader) {
            formData.append('asOrganizationLeader', 'true');
        }

        if (command.asAdmin) {
            formData.append('asAdmin', 'true');
        }

        if (command.asProjectOwner) {
            formData.append('asProjectOwner', 'true');
        }

        return this.http.post(`${this.projectsApiUrl}/UpdateAndPublish`, formData);
    }

    publishProject(id: string) {
        return this.http.post(this.publishProjectApiUrl.replace(this.idParam, id), '');
    }

    closeProject(id: string, rate: number) {
        return this.http.post(this.closeProjectApiUrl.replace(this.idParam, id), rate);
    }

    disableProject(id: string) {
        return this.http.post(`${this.projectsApiUrl}/${id}/disable`, null);
    }

    deleteProject(id: string) {
        return this.http.delete(`${this.projectsApiUrl}/${id}`);
    }

    getFormData(command: BaseProjectCommand): FormData {
        const formData = new FormData();

        formData.append('name', command.name);
        formData.append('startDate', command.startDate.toString());
        formData.append('endDate', command.endDate.toString());
        formData.append('isAnonymous', String(command.isAnonymous));
        formData.append('organizationVisibility', command.organizationVisibility.toString());

        if (command.projectTypeId) {
            formData.append('projectTypeId', command.projectTypeId);
        }

        if (command.organization) {
            formData.append(`organization.id`, command.organization.id);
            formData.append(`organization.name`, command.organization.name);
        }

        formData.append('allCommunitiesVisibility', command.allCommunitiesVisibility.toString());

        if (command.communities && command.communities.length > 0) {
            command.communities.forEach((community, index) => {
                formData.append(`communities[${index}][id]`, community.id);
                formData.append(`communities[${index}][name]`, community.name);
                formData.append(`communities[${index}][displayName]`, community.displayName);
            });
        }

        if (command.synthesis) {
            formData.append('synthesis', command.synthesis);
        }

        if (command.context) {
            formData.append('context', command.context);
        }

        if (command.contributions) {
            formData.append('contributions', command.contributions);
        }

        if (command.description) {
            formData.append('description', command.description);
        }

        if (command.collaborationPlatformType) {
            formData.append('collaborationPlatformType', CollaborationPlatformTypeEnum[command.collaborationPlatformType]);
        }

        if (command.collaborationPlatformUrl) {
            formData.append('collaborationPlatformUrl', command.collaborationPlatformUrl);
        }

        if (command.goals?.length > 0) {
            command.goals.forEach((goal) => formData.append('goals', goal));
        }

        if (command.picture) {
            formData.append('picture', command.picture);
        }

        if (command.searchedSkills && command.searchedSkills.length > 0) {
            command.searchedSkills.forEach((skill, index) => {
                formData.append(`searchedSkills[${index}][id]`, skill.id);
                formData.append(`searchedSkills[${index}][name]`, skill.name);
                formData.append(`searchedSkills[${index}][displayName]`, skill.displayName);
                formData.append(`searchedSkills[${index}][domainId]`, skill.domainId);
                formData.append(`searchedSkills[${index}][domainName]`, skill.domainName);
                formData.append(`searchedSkills[${index}][domainDisplayName]`, skill.domainDisplayName);
            });
        }

        if (command.associatedInterests && command.associatedInterests.length > 0) {
            command.associatedInterests.forEach((interest, index) => {
                formData.append(`associatedInterests[${index}][id]`, interest.id);
                formData.append(`associatedInterests[${index}][name]`, interest.name);
                formData.append(`associatedInterests[${index}][domainId]`, interest.domainId);
                formData.append(`associatedInterests[${index}][domainName]`, interest.domainName);
            });
        }

        formData.append('isPublic', command.isPublic.toString());

        if (command.publicJoinUrl) {
            formData.append('publicJoinUrl', command.publicJoinUrl);
        }
        return formData;
    }

    public report(command: ReportProjectCommand): Observable<string> {
        return this.http.post<string>(`${this.projectsApiUrl}/report`, command);
    }

    public getPublicProject(id: string) {
        return this.http.get<Project>(`${this.publicProjectApiUrl}/${id}`);
    }
}
