<p-dialog
    [header]="'clubs.page.common.removeMember.header' | translate: { clubName: club.title }"
    [(visible)]="isModalRemoveMemberVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    styleClass="custom-remove-member-modal"
    appendTo="body"
>
    {{
        'clubs.page.common.removeMember.body'
            | translate
                : {
                      firstName: memberToRemove?.firstName,
                      lastName: memberToRemove?.lastName,
                      clubName: club.title,
                      deletedDays: 14
                  }
    }}
    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="closeModal()"
        ></button>

        <button
            pButton
            class="p-button p-button-sm p-button-custom-submit p-button-custom-validate"
            type="button"
            label="{{ 'components.button.buttonConfirm' | translate }}"
            (click)="confirmRoomDeletion()"
        ></button>
    </p-footer>
</p-dialog>
