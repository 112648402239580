import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AddressResult } from '../../../azure/map/models';
import { AddressSearchService } from '../../../azure/map/services/address.service';

@Component({
    selector: 'app-country-autocomplete',
    template: `
        <div class="form-group position-relative">
            <label for="country_user">{{ 'user.personalInfo.country.title' | translate }}<span *ngIf="isRequired">*</span></label>
            <input
                type="text"
                [formControl]="searchControl"
                (focus)="isOpen = true"
                class="form-control"
                id="country_user"
                [required]="isRequired"
                [placeholder]="'user.personalInfo.country.placeholder' | translate"
            />
            <ul *ngIf="isOpen && suggestions$ | async as suggestions" class="suggestions-list">
                <li *ngFor="let suggestion of suggestions" (click)="selectCountry(suggestion)">
                    {{ suggestion.address.country }}
                </li>
            </ul>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CountryAutocompleteComponent,
            multi: true,
        },
    ],
    styles: [
        `
            .form-group {
                width: 100%;
            }

            .form-control {
                width: 100%;
            }

            .suggestions-list {
                list-style: none;
                padding: 0;
                margin: 0;
                border: 1px solid #ddd;
                border-top: none;
                max-height: 200px;
                overflow-y: auto;
                position: absolute;
                background: white;
                z-index: 1000;
                width: 100%;
                left: 0;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }

            li {
                padding: 8px 12px;
                cursor: pointer;
                border: none;
                background: white;
            }

            li:hover {
                background-color: #f5f5f5;
            }
        `,
    ],
})
export class CountryAutocompleteComponent implements OnInit, ControlValueAccessor {
    @Output() countrySelected = new EventEmitter<AddressResult>();
    isOpen = true;
    searchControl = new FormControl('');
    @Input() isRequired: boolean = false;
    suggestions$ = this.searchControl.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        filter((query) => query?.length >= 2 || !query),
        switchMap((query) => (query ? this.addressService.searchCountries(query) : of([]))),
    );

    constructor(private addressService: AddressSearchService) {}

    ngOnInit(): void {}

    onChange = (_: any) => {};
    onTouched = () => {};

    writeValue(value: any): void {
        if (value) {
            this.searchControl.setValue(value, { emitEvent: false });
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
        this.searchControl.valueChanges.subscribe(fn);
    }

    selectCountry(suggestion: AddressResult): void {
        const address = suggestion.address;
        this.searchControl.setValue(address.country);
        this.isOpen = false;
        this.onChange(address.country);

        this.countrySelected.emit(suggestion);
    }
}
