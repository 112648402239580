import { Component, Input, ViewChild } from '@angular/core';
import { CommunityFilter } from '../../models/identity/community-filter.model';
import { CommunityWithRoles } from '../../models/identity/community-with-roles.model';
import { Filters } from '../../models/identity/filters.model';
import { GeographicalArea } from '../../models/identity/geographical-area.model';
import { Interest } from '../../models/skills/interest.model';
import { SelectFiltersDropdownComponent } from '../community-filters/components/select-filters-dropdown/select-filters-dropdown.component';
import { BaseFiltersComponent } from '../shared/base-filters/base-filters.component';

@Component({
    selector: 'app-users-filters',
    templateUrl: './users-filters.component.html',
    styleUrls: ['./users-filters.component.scss'],
})
export class UsersFiltersComponent extends BaseFiltersComponent<Filters> {
    @Input() projectsDirectory: boolean;

    @ViewChild('communitiesFilters', { static: false })
    private readonly communitiesFilters: SelectFiltersDropdownComponent;
    areCommunitiesFiltersReady = false;

    @ViewChild('interestsFilters', { static: false })
    private readonly interestsFilters: SelectFiltersDropdownComponent;
    areInterestsFiltersReady = false;

    @ViewChild('locationFilters', { static: false })
    private readonly locationFilters: SelectFiltersDropdownComponent;
    areLocationFiltersReady = false;

    onFiltersSelectionChanged() {
        this.selectionChanged.emit();
    }

    onCommunitiesFiltersReady() {
        this.areCommunitiesFiltersReady = true;
        this.checkFiltersReadiness();
    }

    onInterestsFiltersReady() {
        this.areInterestsFiltersReady = true;
        this.checkFiltersReadiness();
    }

    onLocationFiltersReady() {
        this.areLocationFiltersReady = true;
        this.checkFiltersReadiness();
    }

    checkFiltersReadiness() {
        if (this.areCommunitiesFiltersReady && this.areInterestsFiltersReady && this.areLocationFiltersReady) {
            this.ready.emit();
        }
    }

    resetFilters(): void {
        this.communitiesFilters.resetFilters();
        this.interestsFilters.resetFilters();
        this.locationFilters.resetFilters();
        this.selectionChanged.emit();
    }

    override extractSelectedFilters(): Filters {
        const filters = new Filters();

        filters.communities = (this.communitiesFilters.selectedItems as CommunityWithRoles[]).map(
            (item) =>
                new CommunityFilter(
                    item.id,
                    (item.communitiesGroups || []).map((_) => _.id),
                ),
        );

        filters.interestsIds = (this.interestsFilters.selectedItems as Interest[]).map((interest) => interest.id);

        filters.geographicalAreasIds = (this.locationFilters.selectedItems as GeographicalArea[]).map(
            (area) => area.id,
        );

        return filters;
    }

    override setSelectedFilters(filters: Filters) {
        this.communitiesFilters.setSelectedFiltersCommunities(filters.communities);
        this.locationFilters.setSelectedFilters(filters.geographicalAreasIds);
        this.interestsFilters.setSelectedFilters(filters.interestsIds);
    }
}
