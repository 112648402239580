import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { Project } from '../../../models/projects/project.model';
import { ReportModalComponent } from '../../../shared/components/report-modal/report-modal.component';
import { AccountsService } from './../../../services/identity/accounts.service';
import { PwaService } from './../../../services/pwa/pwa.service';

@Component({
    selector: 'app-gear-edit-project',
    templateUrl: './gear-edit-project.component.html',
    styleUrls: ['./gear-edit-project.component.scss'],
    providers: [ConfirmationService],
})
export class GearEditProjectComponent implements OnInit {
    public ceOrCette: string;
    public editPost = false;
    public isOnMobile = false;
    public isReportModalVisible = false;
    public items: MenuItem[] = [];
    public leOrLa: string;
    public postType: string;
    @Input() public project: Project;
    public projectOwnerFullName = '';
    public reportItem: MenuItem;

    @ViewChild(ReportModalComponent)
    public reportModal: ReportModalComponent;
    public showGear = false;
    public copyItem: MenuItem;

    constructor(
        private accountsService: AccountsService,
        private readonly pwaService: PwaService,
        private translate: TranslateService,
        private readonly toastrHelper: ToastrHelper,
    ) {}

    public initiatePrimeNg() {
        if (this.accountsService.currentUser.userId !== this.project.owner.id) {
            this.reportItem = {
                label: `${this.translate.instant('projects.pages.list.actions.report')}`,
                icon: 'icon-f-flag',
                command: () => {
                    this.isReportModalVisible = true;
                    this.reportModal.openModal();
                },
                styleClass: 'menuItemReport',
            };
            this.items.push(this.reportItem);
        }

        if (this.project.isPublic) {
            this.copyItem = {
                label: 'Copier le lien du projet',
                icon: 'icon-copy',
                command: () => {
                    this.isReportModalVisible = true;
                    this.copyProjectUrl();
                },
                styleClass: 'menuItemReport',
            };
            this.items.push(this.copyItem);
        }
    }

    copyProjectUrl() {
        const url = window.location.href + `/${this.project.id}`;
        navigator.clipboard.writeText(url).then(() => {
            const msg = 'Lien copié dans le presse-papier';
            this.toastrHelper.showSuccess(msg);
            return false;
        });
    }

    public ngOnInit() {
        this.initiatePrimeNg();
        this.isOnMobile = this.pwaService.isMobile;
        if (this.project && this.project.owner) {
            this.projectOwnerFullName = `${this.project.owner.firstName} ${this.project.owner.lastName}`;
        }

        this.showGear = this.items.length > 0;
    }
}
