import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ClubMember } from '../../models/clubs/club-member.model';
import { AllCommunitiesVisibility } from '../../models/identity/all-communities-visibility.model';
import { Community, CommunityCompute } from '../../models/identity/community.model';
import { Filters } from '../../models/identity/filters.model';
import { ManageCommunityFilter } from '../../models/identity/manage-community-filters.model';
import { Organization } from '../../models/identity/organization.model';
import { CommunityClubService } from '../clubs/clubs-community.service';
import { CacheService } from '../shared/cache.service';
import { CommunityWithRoles } from './../../models/identity/community-with-roles.model';
import { CreateCommunityCommand } from './../../models/identity/create-community-command.model';
import { UpdateCommunityCommand } from './../../models/identity/update-community-command.model';
import { ResponseFromAccountActivationFollowUp, User } from './../../models/identity/user.model';

@Injectable({ providedIn: 'root' })
export class CommunityService {
    private backOfficeApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.backOfficeApi.baseUrl}`;
    private communitiesApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.communitiesApi.baseUrl}`;
    // Key of the communities in the cache
    private communitiesCacheKey = 'currentCommunities';
    private showDescriptionModalSubject = new Subject<Community>();
    showDescriptionModal$ = this.showDescriptionModalSubject.asObservable();
    private previewEndedSubject = new Subject<void>();
    previewEnded$ = this.previewEndedSubject.asObservable();

    constructor(private http: HttpClient, private readonly cacheService: CacheService, private readonly communityClubService: CommunityClubService) {}

    public CheckIfUserIsIntoEnclosedCommunity(userId: string) {
        return this.http.get<AllCommunitiesVisibility>(`${this.communitiesApiUrl}/CheckIfUserIsIntoEnclosedCommunity/${userId}`);
    }

    public checkIfCurrentUserIsIntoEnclosedCommunity() {
        return this.http.get<AllCommunitiesVisibility>(`${this.communitiesApiUrl}/CheckIfCurrentUserIsIntoEnclosedCommunity`);
    }

    public createCommunity(command: CreateCommunityCommand): Observable<Community> {
        return this.http.post<Community>(`${this.communitiesApiUrl}`, command);
    }

    public createUserByCommunityManager(user: User): Observable<User> {
        return this.http.post<User>(`${this.backOfficeApiUrl}/${environment.microservices.identity.backOfficeApi.members}`, user);
    }

    public getClubInfoByCommunityId(
        communityId: string,
    ): Observable<{ profilePictureUrl: string | undefined; creationDate: Date; membersCount: ClubMember[] }> {
        return this.communityClubService.get(communityId).pipe(
            map((club) => ({
                profilePictureUrl: club.profilePictureUrl,
                creationDate: club.creationDate,
                membersCount: club.members,
            })),
        );
    }

    public getCommunities(): Observable<Community[]> {
        return this.cacheService.get(this.communitiesCacheKey, this.http.get<Community[]>(this.communitiesApiUrl));
    }

    public getCommunityCompute(id: string): Observable<CommunityCompute> {
        return this.http.get<CommunityCompute>(`${this.communitiesApiUrl}/${id}/${environment.microservices.identity.communitiesApi.compute}`);
    }

    public getMyCommunities(): Observable<CommunityWithRoles[]> {
        return this.http.get<CommunityWithRoles[]>(`${this.communitiesApiUrl}/Mine`);
    }

    public getOganizationsFromCommunity(
        communityId: string,
        filters: ManageCommunityFilter,
    ): Observable<GetEntitiesByCommunityIdResult<Organization>> {
        return this.http.post<GetEntitiesByCommunityIdResult<Organization>>(
            `${this.communitiesApiUrl}/${communityId}/${environment.microservices.identity.communitiesApi.organizations}`,
            filters,
        );
    }

    public getSelectedCommunityMembersDetailsForCSV(communityId: string) {
        return this.http.get<Blob>(`${this.communitiesApiUrl}/${communityId}/ExportMembersDetails/CSV`, {
            responseType: 'blob' as 'json',
        });
    }

    public getUserCommunities(userId: string): Observable<CommunityWithRoles[]> {
        return this.http.get<CommunityWithRoles[]>(`${this.communitiesApiUrl}/His/${userId}`);
    }

    public getUsersByCommunityId(communityId: string, filters: Filters): Observable<GetEntitiesByCommunityIdResult<User>> {
        return this.http.post<GetEntitiesByCommunityIdResult<User>>(
            `${this.communitiesApiUrl}/${communityId}/${environment.microservices.identity.communitiesApi.users}`,
            filters,
        );
    }

    public openPreviewCommunity(community: Community) {
        this.showDescriptionModalSubject.next(community);
    }

    public closePreviewCommunity() {
        this.previewEndedSubject.next();
    }

    public sendAccountActivationFollowUpNotification(communityId: string, usersIds: string[]) {
        return this.http.post<ResponseFromAccountActivationFollowUp>(
            `${this.backOfficeApiUrl}/${environment.microservices.identity.backOfficeApi.members}/AccountActivationFollowUp/${communityId}`,
            usersIds,
        );
    }

    public updateCommunity(id: string, command: UpdateCommunityCommand): Observable<Community> {
        return this.http.post<Community>(`${this.communitiesApiUrl}/${id}`, command);
    }

    public getCommunityById(id: string): Observable<Community> {
        return this.http.get<Community>(`${this.communitiesApiUrl}/${id}`);
    }

    public getPrimanryCommunityByUserId(id: string): Observable<Community> {
        return this.http.get<Community>(`${this.communitiesApiUrl}/user/primary/${id}`);
    }
}

export class GetEntitiesByCommunityIdResult<TEntity> {
    public count: number;
    public data: TEntity[];
}
