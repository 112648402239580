<div *ngIf="!isStepLoaded" class="spinner-border text-info" role="status">
    <span class="sr-only">{{ 'projects.formStep1.loading' | translate }}</span>
</div>
<form *ngIf="stepOneForm" [formGroup]="stepOneForm" (ngSubmit)="onSubmit()" [hidden]="!isStepLoaded" class="col-12">
    <div class="card box-shadow overflow-visible">
        <div class="card-body">
            <div class="row align-items-end">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="fw-b primary-1" for="project-name">{{ 'projects.formStep1.projectName' | translate }} *</label>
                        <input
                            type="text"
                            formControlName="name"
                            id="project-name"
                            [placeholder]="'projects.formStep1.insertName' | translate"
                            class="form-control"
                        />
                        <div
                            *ngIf="
                                stepOneForm.controls.name.invalid &&
                                (formSubmitAttempt || stepOneForm.controls.name.dirty || stepOneForm.controls.name.touched)
                            "
                            class="alert alert-danger"
                        >
                            <div *ngIf="stepOneForm.controls.name.errors.uniqueClubName">
                                {{ 'projects.formStep1.projectNameExists' | translate }}
                            </div>
                            <div *ngIf="stepOneForm.controls.name.errors.required">
                                {{ 'projects.formStep1.projectNameRequired' | translate }}
                            </div>
                            <div *ngIf="stepOneForm.controls.name.errors.maxlength">
                                {{
                                    'projects.formStep1.projectNameLength'
                                        | translate : { maxLength: 50, currLength: stepOneForm.controls.name.value.length }
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="form-group date" data-provide="datepicker">
                        <label class="fw-b primary-1" for="start-date">{{ 'projects.formStep1.expectedStartDate' | translate }} *</label>
                        <input
                            type="date"
                            formControlName="startDate"
                            id="start-date"
                            class="form-control"
                            min="{{ getDefaultStartDate() | date : 'yyyy-MM-dd' }}"
                            max="{{ getMaxDate() | date : 'yyyy-MM-dd' }}"
                        />
                        <div
                            *ngIf="
                                stepOneForm.controls.startDate.invalid &&
                                (formSubmitAttempt || stepOneForm.controls.startDate.dirty || stepOneForm.controls.startDate.touched)
                            "
                            class="alert alert-danger"
                        >
                            <div *ngIf="stepOneForm.controls.startDate.errors.required">
                                {{ 'projects.formStep1.projectStartDateRequired' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3">
                    <div class="form-group date" data-provide="datepicker">
                        <label class="fw-b primary-1" for="end-date">{{ 'projects.formStep1.expectedEndDate' | translate }} *</label>
                        <input
                            type="date"
                            formControlName="endDate"
                            id="end-date"
                            class="form-control"
                            min="{{ getMinEndDate() }}"
                            max="{{ getMaxDate() }}"
                        />
                        <div
                            *ngIf="
                                stepOneForm.controls.endDate.invalid &&
                                (formSubmitAttempt || stepOneForm.controls.endDate.dirty || stepOneForm.controls.endDate.touched)
                            "
                            class="alert alert-danger"
                        >
                            <div *ngIf="stepOneForm.controls.endDate.errors.required">
                                {{ 'projects.formStep1.projectEndDateRequired' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 col-md-6" style="margin-left: 15px">
                    <div class="form-group row is-anonymous">
                        <label class="fw-b primary-1" for="project-is-anonymous">{{ 'projects.formStep1.publicProject' | translate }}</label>
                        <div class="custom-anonymous-switch">
                            <p-inputSwitch id="project-is-anonymous" formControlName="isPublic"></p-inputSwitch>
                        </div>

                        <div class="p-2">
                            <p-dialog
                                header="Information"
                                [(visible)]="displayIsPublicInfoModal"
                                [modal]="true"
                                [style]="{ width: '30vw' }"
                                [breakpoints]="{ '960px': '75vw' }"
                                [draggable]="false"
                                [resizable]="false"
                            >
                                <div>
                                    {{ 'projects.formStep1.publicProjectInfo' | translate }}
                                </div>
                            </p-dialog>
                        </div>

                        <i class="pi pi-info-circle ml-2 mt-1" (click)="displayIsPublicInfoModal = true"></i>
                    </div>
                </div>
                <div class="col-6 col-md-5">
                    <div class="form-group row is-anonymous">
                        <label class="fw-b primary-1" for="project-is-anonymous">{{ 'projects.formStep1.anonymous.title' | translate }}</label>
                        <div class="custom-anonymous-switch">
                            <p-inputSwitch id="project-is-anonymous" formControlName="isAnonymous"></p-inputSwitch>
                        </div>
                        <div class="p-2">
                            <p-dialog
                                header="Information"
                                [(visible)]="displayIsAnonymousInfoModal"
                                [modal]="true"
                                [style]="{ width: '30vw' }"
                                [breakpoints]="{ '960px': '75vw' }"
                                [draggable]="false"
                                [resizable]="false"
                            >
                                <div>
                                    {{ 'projects.formStep1.anonymous.enabledDescription' | translate }}
                                </div>
                                <br />
                                <div>
                                    {{ 'projects.formStep1.anonymous.disabledDescription' | translate }}
                                </div>
                            </p-dialog>
                        </div>

                        <i class="pi pi-info-circle ml-2 mt-1" (click)="displayIsAnonymousInfoModal = true"></i>
                    </div>
                </div>
            </div>

            <div class="row">
                <span class="p-input-icon-right col-12" *ngIf="stepOneForm.controls.isPublic.value">
                    <i style="width: 3%" class="pi pi-info-circle" pTooltip="{{ 'projects.formStep1.addJoinLinkTooltip' | translate }}"></i>
                    <input
                        type="text"
                        formControlName="publicJoinUrl"
                        id="project-name"
                        [placeholder]="'projects.formStep1.joinLinkPlaceholder' | translate"
                        class="form-control"
                        style="width: 100%"
                    />
                </span>
            </div>

            <app-scope-selection
                label="{{ 'projects.formStep1.visibility' | translate }}"
                [form]="visibility"
                [joinableType]="JoinableType.Project"
                [formSubmitAttempt]="formSubmitAttempt"
                [userCommunities]="userCommunities"
                [allCommunities]="allCommunities"
                [canCreateAllCommunitiesProject]="canCreateAllCommunitiesProject"
                [hideAllCommunitiesVisibility]="hideAllCommunitiesVisibility"
                [showAllCommunitiesVisibility]="showAllCommunitiesVisibility"
            ></app-scope-selection>
        </div>
    </div>

    <div class="mt-2 project-type-list">
        <h2 *ngIf="categoryProjectTypeAvailable" class="h3 primary-1">
            {{ 'projects.formStep1.categories.title' | translate }}
        </h2>
        <ul *ngIf="categoryProjectTypeAvailable" class="nav nav-tabs mb-3" id="productTypeCategoriesTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link"
                    id="{{ projectTypeCategories.all }}-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    routerLink="./"
                    fragment="{{ projectTypeCategories.all }}"
                    [class.active]="activeFragment === projectTypeCategories.all"
                    >{{ 'projects.formStep1.categories.all' | translate }}</a
                >
            </li>
            <li class="nav-item" *appHideForCommunities="uimmCommunitiesIds">
                <a
                    class="nav-link"
                    id="{{ projectTypeCategories.innovation }}-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    routerLink="./"
                    fragment="{{ projectTypeCategories.innovation }}"
                    [class.active]="activeFragment === projectTypeCategories.innovation"
                    >{{ 'projects.formStep1.categories.innovation' | translate }}</a
                >
            </li>
            <li class="nav-item" *appHideForCommunities="uimmCommunitiesIds">
                <a
                    class="nav-link"
                    id="{{ projectTypeCategories.business }}-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    routerLink="./"
                    fragment="{{ projectTypeCategories.business }}"
                    [class.active]="activeFragment === projectTypeCategories.business"
                    >{{ 'projects.formStep1.categories.business' | translate }}</a
                >
            </li>
            <li class="nav-item" *appHideForCommunities="uimmCommunitiesIds">
                <a
                    class="nav-link"
                    id="{{ projectTypeCategories.learning }}-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    routerLink="./"
                    fragment="{{ projectTypeCategories.learning }}"
                    [class.active]="activeFragment === projectTypeCategories.learning"
                    >{{ 'projects.formStep1.categories.educational' | translate }}</a
                >
            </li>
            <li class="nav-item" *appShowForCommunities="bffCommunitiesIds">
                <a
                    class="nav-link"
                    id="{{ projectTypeCategories.francerelance }}-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    routerLink="./"
                    fragment="{{ projectTypeCategories.francerelance }}"
                    [class.active]="activeFragment === projectTypeCategories.francerelance"
                    >{{ 'projects.formStep1.categories.bff' | translate }}</a
                >
            </li>
            <li class="nav-item" *appShowForCommunities="nvCommunitiesIds">
                <a
                    class="nav-link"
                    id="{{ projectTypeCategories.nv }}-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    routerLink="./"
                    fragment="{{ projectTypeCategories.nv }}"
                    [class.active]="activeFragment === projectTypeCategories.nv"
                    >{{ 'projects.formStep1.categories.nv' | translate }}</a
                >
            </li>
            <li class="nav-item" *appShowForCommunities="ifgpCommunitiesIds">
                <a
                    class="nav-link"
                    id="{{ projectTypeCategories.ifgp }}-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    routerLink="./"
                    fragment="{{ projectTypeCategories.ifgp }}"
                    [class.active]="activeFragment === projectTypeCategories.ifgp"
                    >{{ 'projects.formStep1.categories.publicAction' | translate }}</a
                >
            </li>
            <li class="nav-item" *appShowForCommunities="uimmCommunitiesIds">
                <a
                    class="nav-link"
                    id="{{ projectTypeCategories.rechercher }}-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    routerLink="./"
                    fragment="{{ projectTypeCategories.rechercher }}"
                    [class.active]="activeFragment === projectTypeCategories.rechercher"
                    >{{ 'projects.formStep1.categories.search' | translate }}</a
                >
            </li>
            <li class="nav-item" *appShowForCommunities="uimmCommunitiesIds">
                <a
                    class="nav-link"
                    id="{{ projectTypeCategories.proposer }}-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    routerLink="./"
                    fragment="{{ projectTypeCategories.proposer }}"
                    [class.active]="activeFragment === projectTypeCategories.proposer"
                    >{{ 'projects.formStep1.categories.suggest' | translate }}</a
                >
            </li>
        </ul>
        <div class="tab-content">
            <div
                class="tab-pane fade show"
                [class.active]="activeFragment === projectTypeCategories.all"
                id="{{ projectTypeCategories.all }}"
                role="tabpanel"
            >
                <app-project-type-list-tab
                    [projectTypes]="getProjectsTypesForCategory(projectTypeCategories.all)"
                    [parentForm]="stepOneForm"
                ></app-project-type-list-tab>
            </div>
        </div>

        <div class="tab-content">
            <div
                class="tab-pane fade show"
                [class.active]="activeFragment === projectTypeCategories.innovation"
                id="{{ projectTypeCategories.innovation }}"
                role="tabpanel"
            >
                <app-project-type-list-tab
                    [projectTypes]="getProjectsTypesForCategory(projectTypeCategories.innovation)"
                    [parentForm]="stepOneForm"
                ></app-project-type-list-tab>
            </div>
        </div>

        <div class="tab-content">
            <div
                class="tab-pane fade show"
                [class.active]="activeFragment === projectTypeCategories.business"
                id="{{ projectTypeCategories.business }}"
                role="tabpanel"
            >
                <app-project-type-list-tab
                    [projectTypes]="getProjectsTypesForCategory(projectTypeCategories.business)"
                    [parentForm]="stepOneForm"
                ></app-project-type-list-tab>
            </div>
        </div>

        <div class="tab-content">
            <div
                class="tab-pane fade show"
                [class.active]="activeFragment === projectTypeCategories.learning"
                id="{{ projectTypeCategories.learning }}"
                role="tabpanel"
            >
                <app-project-type-list-tab
                    [projectTypes]="getProjectsTypesForCategory(projectTypeCategories.learning)"
                    [parentForm]="stepOneForm"
                ></app-project-type-list-tab>
            </div>
        </div>

        <div class="tab-content">
            <div
                class="tab-pane fade show"
                [class.active]="activeFragment === projectTypeCategories.francerelance"
                id="{{ projectTypeCategories.francerelance }}"
                role="tabpanel"
            >
                <app-project-type-list-tab
                    [projectTypes]="getProjectsTypesForCategory(projectTypeCategories.francerelance)"
                    [parentForm]="stepOneForm"
                ></app-project-type-list-tab>
            </div>
        </div>

        <div class="tab-content">
            <div
                class="tab-pane fade show"
                [class.active]="activeFragment === projectTypeCategories.nv"
                id="{{ projectTypeCategories.nv }}"
                role="tabpanel"
            >
                <app-project-type-list-tab
                    [projectTypes]="getProjectsTypesForCategory(projectTypeCategories.nv)"
                    [parentForm]="stepOneForm"
                ></app-project-type-list-tab>
            </div>
        </div>

        <div class="tab-content">
            <div
                class="tab-pane fade show"
                [class.active]="activeFragment === projectTypeCategories.ifgp"
                id="{{ projectTypeCategories.ifgp }}"
                role="tabpanel"
            >
                <app-project-type-list-tab
                    [projectTypes]="getProjectsTypesForCategory(projectTypeCategories.ifgp)"
                    [parentForm]="stepOneForm"
                ></app-project-type-list-tab>
            </div>
        </div>

        <div class="tab-content">
            <div
                class="tab-pane fade show"
                [class.active]="activeFragment === projectTypeCategories.rechercher"
                id="{{ projectTypeCategories.rechercher }}"
                role="tabpanel"
            >
                <app-project-type-list-tab
                    [projectTypes]="getProjectsTypesForCategory(projectTypeCategories.rechercher)"
                    [parentForm]="stepOneForm"
                ></app-project-type-list-tab>
            </div>
        </div>

        <div class="tab-content">
            <div
                class="tab-pane fade show"
                [class.active]="activeFragment === projectTypeCategories.proposer"
                id="{{ projectTypeCategories.proposer }}"
                role="tabpanel"
            >
                <app-project-type-list-tab
                    [projectTypes]="getProjectsTypesForCategory(projectTypeCategories.proposer)"
                    [parentForm]="stepOneForm"
                ></app-project-type-list-tab>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12 footer-create-project">
            <div class="float-right">
                <!--Supprimer un projet-->
                <button *ngIf="isRemovable()" class="btn btn-danger" data-toggle="modal" data-target="#confirmDeleteModal" type="button">
                    <em class="icon icon-trash d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonDelete' | translate }}</span>
                </button>
                <button *ngIf="!isEditMode || (isEditMode && isDraft)" class="btn btn-secondary" type="button" (click)="openConfirmePopupSaveDraft()">
                    <em class="icon icon-save d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonSaveDraft' | translate }}</span>
                </button>
                <button class="btn btn-primary" type="submit">
                    <em class="icon icon-arrow-left1 d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonNext' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</form>

<!--Save draft-->
<ng-template id="modal" #draftStepOneModal2 let-modal class="modal fade">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
                {{ 'projects.formStep2.saveDraftModal.title' | translate }}
            </h5>
            <button type="button" class="close" (click)="onClose()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div *ngIf="projectCommand.picturePreviewUrl" class="modal-image">
            <img src="{{ projectCommand.picturePreviewUrl }}" [alt]="'projects.formStep1.projectPictureAlt' | translate" />
        </div>
        <div class="modal-body">
            <p class="ta-c lead">
                {{ 'projects.formStep1.confirmSaveDraft' | translate }}
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onClose()">
                {{ 'components.button.buttonCancel' | translate }}
            </button>
            <button type="button" class="btn btn-primary" (click)="onSave()">
                {{ 'components.button.buttonYes' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<!-- Delete Modal -->
<div
    class="modal fade bd-example-modal-lg"
    id="confirmDeleteModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteConfirmeModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmeCloseProjectModalLabel">
                    {{ 'projects.formStep1.deleteModal.title' | translate }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-image" *ngIf="projectLight?.pictureAccessLink">
                <img src="{{ projectLight?.pictureAccessLink }}" [alt]="'projects.formStep1.projectPictureAlt' | translate" />
            </div>
            <div class="modal-body">
                <p class="ta-c lead">{{ 'projects.formStep1.deleteModal.confirm' | translate }}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{ 'components.button.buttonCancel' | translate }}
                </button>
                <button type="button" class="btn btn-danger" (click)="deleteProject()" data-dismiss="modal">
                    {{ 'components.button.buttonDelete' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
