import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrHelper } from '../../../../helpers/toastr.helper';
import { User } from '../../../../models/identity/user.model';
import { Interest } from '../../../../models/skills/interest.model';
import { AccountsService } from '../../../../services/identity/accounts.service';
import { UserInterestsService } from '../../../../services/identity/user/user-interests.service';
import { InterestsService } from '../../../../services/skills/interests.service';

@Component({
    selector: 'app-user-interests-form',
    templateUrl: './user-interests-form.component.html',
    styleUrls: ['./user-interests-form.component.scss'],
})
export class UserInterestsFormComponent implements OnInit {
    public availableInterests: Interest[];
    public filteredInterests: Interest[];
    public filteredAvailableInterests: Interest[] = [];
    public selectedInterest: Interest | null;
    public topTenInterests: Interest[];
    public isSelectInterestsModalVisible: boolean;

    @Input() public user: User;

    constructor(
        private readonly accountService: AccountsService,
        private readonly userInterestsService: UserInterestsService,
        private readonly toastrHelper: ToastrHelper,
        private readonly translate: TranslateService,
        private readonly interestService: InterestsService,
    ) {}
    public addInterest() {
        if (this.selectedInterest) {
            this.availableInterests = this.availableInterests.filter(
                (interest) => interest.name !== this.selectedInterest?.name,
            );
            this.user.interests.push(this.selectedInterest);
            this.updateFilteredAvailableInterests();
            this.selectedInterest = null;
        }
    }

    public filterInterests(event: any) {
        const query = event.query.toLowerCase();
        this.filteredInterests = this.availableInterests.filter((interest) =>
            interest.name.toLowerCase().includes(query),
        );
    }

    public ngOnInit(): void {
        this.interestService.getAllInterests().subscribe((interests) => {
            this.availableInterests = interests;
            this.filteredInterests = interests;
            this.updateFilteredAvailableInterests();
        });

        this.interestService
            .getTopInterestsByCommunity(this.user.primaryCommunity.id, 10)
            .subscribe((interests) => (this.topTenInterests = interests));
    }

    public removeInterest(index: number) {
        this.availableInterests.push(this.user.interests[index]);
        this.user.interests.splice(index, 1);
    }

    public async save(): Promise<boolean> {
        if (this.user.interests.length > 0) {
            try {
                await this.userInterestsService.addUserInterests(this.user).toPromise();
                this.accountService.setUserInterests(this.user);
                this.accountService.saveCurrentUser();
                this.accountService.refreshCurrentUser();
                return true;
            } catch (error) {
                this.toastrHelper.showError(this.translate.instant('common.unknownError'));
                return false;
            }
        }
        return true;
    }

    public toggleInterest(interest: Interest) {
        const index = this.user.interests.findIndex((i) => i.id === interest.id);
        if (index !== -1) {
            this.removeInterest(index);
        } else {
            this.selectedInterest = interest;
            this.addInterest();
        }
    }

    public showSelectInterestsModal() {
        this.isSelectInterestsModalVisible = true;
    }

    private updateFilteredAvailableInterests(): void {
        const selectedDomains = this.user.interests.map((interest) => interest.domainName);
        this.filteredAvailableInterests = this.availableInterests.filter((interest) =>
            selectedDomains.includes(interest.domainName),
        );
    }

    public toggleValue(interest: Interest) {
        const index = this.user.interests.findIndex((i) => i.id === interest.id);
        if (index !== -1) {
            this.user.interests.splice(index, 1);
        } else {
            this.user.interests.push(interest);
        }
        this.updateFilteredAvailableInterests();
    }
}
