import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrHelper } from '../../helpers/toastr.helper';
import { BaseEventCommand } from '../../models/events/base-event-command.model';
import { User } from '../../models/identity/user.model';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class BaseEventStep implements OnDestroy {
    @Input()
    eventCommand: BaseEventCommand;

    @Input()
    currentProfile: User;

    @Input()
    editMode: boolean;

    @Input()
    isCurrentUserOwner: boolean;

    public formSubmitAttempt = false;
    public isStepLoaded = false;
    public stepForm: FormGroup;

    protected subscription: Subscription = new Subscription();

    @Output()
    public submitted = new EventEmitter();

    constructor(protected readonly toastrHelper: ToastrHelper, protected translate: TranslateService) {}

    public showControlError(control: AbstractControl): boolean {
        return control.invalid && (this.formSubmitAttempt || control.dirty || control.touched);
    }

    public onSubmit(): void {
        if (this.checkFormValidity()) {
            this.updateEventCommandFromStepComponent();
            this.submitted.emit();
        }
    }

    private checkFormValidity(): boolean {
        this.formSubmitAttempt = true;

        if (this.stepForm.invalid) {
            const msg = this.translate.instant('shared.forms.errors.checkAndRetry');
            this.toastrHelper.showError(msg);
            return false;
        }

        return true;
    }

    protected requiredIfValidator(condition: () => boolean): ValidatorFn {
        return (control: AbstractControl) => {
            if (condition()) {
                return Validators.required(control);
            }

            return null;
        };
    }

    public abstract updateEventCommandFromStepComponent(): void;
    protected abstract createForm(): void;

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
