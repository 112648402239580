<div class="p-grid p-jc-between p-my-2">
    <div class="p-col-5">
        <h2 class="p-my-auto p-mr-auto">{{ 'community.pages.organizations.title' | translate }}</h2>
    </div>
</div>

<div>
    <div class="p-grid p-jc-between p-my-2">
        <div>
            <app-custom-search
                [placeholderText]="'community.pages.organizations.searchOrganization' | translate"
                customSearchName="filterOrganisationsByName"
                [userInput]="customSearch"
                class="search-bar"
                (searchTextChanged)="onCustomSearchTextChanged($event)"
            ></app-custom-search>
        </div>
        <div class="p-col-3 p-text-bold p-text-right list-header-group">
            {{ totalRecords }}
            <span [ngPlural]="organizations.length">
                <ng-template ngPluralCase="=0">résultat</ng-template>
                <ng-template ngPluralCase="=1">résultat</ng-template>
                <ng-template ngPluralCase="other">résultats</ng-template>
            </span>
        </div>
    </div>
    <ng-container [ngTemplateOutlet]="table"></ng-container>
</div>
<ng-template #table>
    <p-table
        #dt
        [value]="organizations"
        [totalRecords]="totalRecords"
        [(selection)]="selectedOrganizations"
        dataKey="id"
        [rowHover]="true"
        [(rows)]="pageSize"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [paginator]="true"
        [filterDelay]="500"
        [scrollable]="true"
        [style]="{ width: '100%' }"
        class="community-table"
        [resizableColumns]="true"
        [lazy]="true"
        (onLazyLoad)="loadOrganizationsLazy($event)"
        styleClass="p-datatable-header-gridlines"
        editMode="row"
    >
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup *ngIf="selectedOrganizations.length === 0">
                <col class="list-col-icon" *ngIf="massEditOrganizationsAvailable" />
                <col class="list-col-small" *ngIf="massEditOrganizationsAvailable" />
                <col class="list-col-medium" />
                <col class="list-col-small" />
                <col class="list-col-large" />
                <col class="list-col-small" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-large" />
            </colgroup>
            <colgroup *ngIf="selectedOrganizations.length > 0">
                <col class="list-col-icon" *ngIf="massEditOrganizationsAvailable" />
                <col class="list-col-small" *ngIf="massEditOrganizationsAvailable" />
                <col class="list-col-medium" />
                <col class="list-col-small" />
                <col class="list-col-large" />
                <col class="list-col-small" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-large" />
            </colgroup>
        </ng-template>

        <ng-template pTemplate="header">
            <tr *ngIf="selectedOrganizations.length === 0">
                <th class="checkbox" *ngIf="massEditOrganizationsAvailable" scope="col">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th class="checkbox" *ngIf="massEditOrganizationsAvailable" scope="col"></th>
                <th pSortableColumn="{{ organizationSortModes.name }}" pResizableColumn scope="col" scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Nom
                        <p-sortIcon field="{{ organizationSortModes.name }}"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="{{ organizationSortModes.active }}" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Status
                        <p-sortIcon field="{{ organizationSortModes.active }}"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="{{ organizationSortModes.address }}" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Adresse
                        <p-sortIcon field="{{ organizationSortModes.address }}"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="{{ organizationSortModes.postalCode }}" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Code postal
                        <p-sortIcon field="{{ organizationSortModes.postalCode }}"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="{{ organizationSortModes.city }}" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Ville
                        <p-sortIcon field="{{ organizationSortModes.city }}"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="{{ organizationSortModes.contact }}" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        E-mail de contact
                        <p-sortIcon field="{{ organizationSortModes.contact }}"></p-sortIcon>
                    </div>
                </th>
                <th pResizableColumn scope="col">Communauté principale</th>
                <th pSortableColumn="{{ organizationSortModes.phoneNumber }}" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'user.personalInfo.phonePlaceholder' | translate }}
                        <p-sortIcon field="{{ organizationSortModes.phoneNumber }}"></p-sortIcon>
                    </div>
                </th>
                <th pResizableColumn scope="col">Département</th>
                <th pSortableColumn="{{ organizationSortModes.webSite }}" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        URL
                        <p-sortIcon field="{{ organizationSortModes.webSite }}"></p-sortIcon>
                    </div>
                </th>
            </tr>

            <tr *ngIf="selectedOrganizations.length > 0">
                <th class="checkbox borderless" scope="col">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th class="borderless" scope="col">
                    <div class="p-d-flex p-jc-between selected-label">
                        {{
                            selectedOrganizations.length
                                | i18nPlural
                                    : {
                                          '=1': '# sélectionné',
                                          other: '# sélectionnés'
                                      }
                        }}
                    </div>
                </th>
                <th class="borderless" scope="col">
                    <div
                        class="p-d-flex p-jc-between p-ai-center edit-label"
                        *ngIf="selectedOrganizations.length > 0"
                        (click)="openUpdateOrganizationsActivePropertyModal(true)"
                        [ngClass]="{ 'disabled-div': !canEditActiveProperty(true) }"
                    >
                        Activer
                    </div>
                </th>
                <th class="borderless" scope="col">
                    <div
                        class="p-d-flex p-jc-between p-ai-center edit-label"
                        *ngIf="selectedOrganizations.length > 0"
                        (click)="openUpdateOrganizationsActivePropertyModal(false)"
                        [ngClass]="{ 'disabled-div': !canEditActiveProperty(false) }"
                    >
                        Désactiver
                    </div>
                </th>
                <th colspan="8" class="borderless" scope="col"></th>
            </tr>
            <tr *ngIf="selectedOrganizations.length === pageSize">
                <th colspan="12" class="all-selection" scope="col">
                    {{
                        'community.pages.organizations.selectedOrganizations'
                            | translate: { number: allSelectionMode ? totalRecords : pageSize }
                    }}
                    <a (click)="onAllSelectionClick()" *ngIf="!allSelectionMode">
                        {{
                            'community.pages.organizations.selectAllOrganizations' | translate: { number: totalRecords }
                        }}
                    </a>
                    <a (click)="onAllSelectionClick()" *ngIf="allSelectionMode"> Effacer la sélection</a>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-organization let-editing="editing">
            <tr class="p-selectable-row" [pEditableRow]="organization">
                <td class="checkbox list-col-icon" *ngIf="massEditOrganizationsAvailable">
                    <p-tableCheckbox [value]="organization"></p-tableCheckbox>
                </td>
                <td style="text-align: center" *ngIf="massEditOrganizationsAvailable">
                    <button
                        *ngIf="!editing"
                        pButton
                        pRipple
                        type="button"
                        pInitEditableRow
                        icon="pi pi-pencil"
                        (click)="onRowEditInit(organization)"
                        class="p-button-rounded p-button-text"
                    ></button>
                    <button
                        *ngIf="editing"
                        pButton
                        pRipple
                        type="button"
                        pSaveEditableRow
                        icon="pi pi-check"
                        (click)="onRowEditSave(organization)"
                        class="p-button-rounded p-button-text p-button-success p-mr-1"
                    ></button>
                    <button
                        *ngIf="editing"
                        pButton
                        pRipple
                        type="button"
                        pCancelEditableRow
                        icon="pi pi-times"
                        (click)="onRowEditCancel(organization, ri)"
                        class="p-button-rounded p-button-text p-button-danger"
                    ></button>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                pInputText
                                type="text"
                                [(ngModel)]="organization.organizationName"
                                [style]="{ width: '100%' }"
                            />
                        </ng-template>
                        <ng-template pTemplate="output">{{ organization.organizationName }} </ng-template>
                    </p-cellEditor>
                </td>
                <td>{{ organization.active ? 'Activée' : 'Désactivée' }}</td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                pInputText
                                type="text"
                                [(ngModel)]="organization.address1"
                                [style]="{ width: '100%' }"
                            />
                        </ng-template>
                        <ng-template pTemplate="output">{{ organization.address1 }} </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                pInputText
                                type="text"
                                [(ngModel)]="organization.postalCode"
                                [style]="{ width: '100%' }"
                            />
                        </ng-template>
                        <ng-template pTemplate="output">{{ organization.postalCode }} </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="organization.city" [style]="{ width: '100%' }" />
                        </ng-template>
                        <ng-template pTemplate="output">{{ organization.city }} </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                pInputText
                                type="text"
                                [(ngModel)]="organization.email"
                                [style]="{ width: '100%' }"
                            />
                        </ng-template>
                        <ng-template pTemplate="output">{{ organization.email }} </ng-template>
                    </p-cellEditor>
                </td>
                <td>{{ organization.primaryCommunity?.displayName }}</td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                pInputText
                                type="text"
                                [(ngModel)]="organization.phoneNumber"
                                [style]="{ width: '100%' }"
                            />
                        </ng-template>
                        <ng-template pTemplate="output">{{ organization.phoneNumber }} </ng-template>
                    </p-cellEditor>
                </td>
                <td [ngClass]="{ 'overflow-visible': isOrganizationCurentlyEdited(organization.id) }">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown
                                [options]="availableGeographicalAreas"
                                [(ngModel)]="organization.geographicalArea"
                                [style]="{ width: '100%' }"
                                optionLabel="name"
                                [appendTo]="dt"
                            ></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output"> {{ organization.geographicalArea?.name }} </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                pInputText
                                type="text"
                                [(ngModel)]="organization.webSite"
                                [style]="{ width: '100%' }"
                            />
                        </ng-template>
                        <ng-template pTemplate="output">{{ organization.webSite }} </ng-template>
                    </p-cellEditor>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="10">
                    {{ 'community.pages.organizations.noOrganization' | translate }}
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>

<p-dialog
    header="{{ newOrganizationStatusValue ? 'Activer' : 'Désactiver' }} {{
        selectedOrganizations.length
    }} organisation(s)"
    *ngIf="isUpdateOrganizationsActivePropertyModalVisible"
    [(visible)]="isUpdateOrganizationsActivePropertyModalVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    class=""
    [closable]="false"
    appendTo="body"
>
    <div *ngIf="newOrganizationStatusValue">
        {{
            'community.pages.organizations.enableOrganization'
                | translate: { nbOrganizations: selectedOrganizations.length }
        }}
    </div>
    <div *ngIf="!newOrganizationStatusValue">
        {{
            'community.pages.organizations.disableOrganization'
                | translate: { nbOrganizations: selectedOrganizations.length }
        }}
    </div>
    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="closeModal()"
        ></button>

        <button
            pButton
            class="p-button p-button-sm p-button-custom-submit p-button-custom-validate"
            type="button"
            label="{{ 'components.button.buttonConfirm' | translate }}"
            (click)="updateOrganizationsActiveProperty()"
        ></button>
    </p-footer>
</p-dialog>
