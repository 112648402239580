import { environment } from '../../environments/environment';

export const projectStatus = {
    draft: 'draft',
    unpublished: 'unpublished',
    published: 'published',
    finished: 'finished',
    all: 'all',
};

export const statusEndpoints = {
    draft: environment.microservices.projects.projectsApi.draft,
    unpublished: environment.microservices.projects.projectsApi.unpublished,
    published: environment.microservices.projects.projectsApi.published,
    finished: environment.microservices.projects.projectsApi.finished,
    all: '',
};

export const projectTypeCategories = {
    all: 'all',
    innovation: 'innovation',
    business: 'business',
    learning: 'learning',
    francerelance: 'francerelance',
    ifgp: 'ifgp',
    nv: 'nv',
    rechercher: 'rechercher',
    proposer: 'proposer',
};
