import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { UserHelper } from '../../../helpers/user.helper';
import { ClubRoles } from '../../../models/clubs/club-roles.model';
import { Club } from '../../../models/clubs/club.model';
import { CLUB_VISIBILITIES } from '../../../models/clubs/constants/club-visibilities';
import { Member } from '../../../models/clubs/member.model';
import { RemoveMemberModalComponent } from '../../club-page/common/remove-member-modal/remove-member-modal.component';
import { ClubsStore } from '../../clubs.store';
import { PwaService } from './../../../services/pwa/pwa.service';
import { EditMemberRoleModalComponent } from './../../club-page/common/edit-member-role-modal/edit-member-role-modal.component';

@Component({
    selector: 'app-gear-edit-member',
    templateUrl: './gear-edit-member.component.html',
    styleUrls: ['./gear-edit-member.component.scss'],
})
export class GearEditMemberComponent implements OnInit {
    @Input()
    public club: Club;
    @Input()
    public currentUserRole: ClubRoles;
    @ViewChild('editMemberRoleModalComponent', { static: false })
    public editMemberRoleModalComponent: EditMemberRoleModalComponent;
    public isOnMobile: boolean;
    public items: MenuItem[] = [];
    @Input()
    public member: Member;
    @Input()
    public memberCurrentRole: ClubRoles;
    @Input() public memberIsHovered: boolean;
    @ViewChild('removeMemberModalComponent', { static: false })
    public removeMemberModalComponent: RemoveMemberModalComponent;
    @Output()
    public shouldRemoveMember = new EventEmitter<string>();

    @Output()
    public shouldUpdateMembers = new EventEmitter<{
        memberId: string;
        newRole: ClubRoles;
    }>();

    constructor(
        public readonly userHelper: UserHelper,
        protected readonly clubsStore: ClubsStore,
        private readonly pwaService: PwaService,
        private translate: TranslateService,
    ) {
        this.isOnMobile = this.pwaService.isMobile;
    }

    public get shouldDisplayGearButton(): boolean {
        return this.items?.length > 0;
    }

    public initiatePrimeNg() {
        if (this.currentUserRole == ClubRoles.Admin) {
            if (this.clubsStore.isMainRoom && !this.clubsStore.isACommunityClub && !this.clubsStore.isAProjectClub) {
                if (this.memberCurrentRole == ClubRoles.Admin) {
                    this.items.push({
                        label: this.translate.instant('administration.global.pages.users.manageRole.withdrawAdmin'),
                        icon: 'icon-shield1',
                        command: () => {
                            setTimeout(() => {
                                this.editMemberRoleModalComponent.openModal(ClubRoles.Admin);
                            }, 300);
                        },
                    });
                } else {
                    this.items.push({
                        label: this.translate.instant('administration.global.pages.users.manageRole.nameAdmin'),
                        icon: 'icon-shield1',
                        command: () => {
                            setTimeout(() => {
                                this.editMemberRoleModalComponent.openModal(ClubRoles.Admin);
                            }, 300);
                        },
                    });
                }
            }
        }

        if (
            this.currentUserRole == ClubRoles.Admin ||
            (this.currentUserRole == ClubRoles.Animator && !this.clubsStore.isMainRoom && this.club.visibility == CLUB_VISIBILITIES.HIDDEN)
        ) {
            if (this.memberCurrentRole == ClubRoles.Animator) {
                this.items.push({
                    label: this.translate.instant('administration.global.pages.users.manageRole.withdrawAnim'),
                    icon: 'icon-shield1',
                    command: () => {
                        setTimeout(() => {
                            this.editMemberRoleModalComponent.openModal(ClubRoles.Animator);
                        }, 300);
                    },
                });
            } else {
                if (this.memberCurrentRole != ClubRoles.Admin) {
                    this.items.push({
                        label: this.translate.instant('administration.global.pages.users.manageRole.nameAnim'),
                        icon: 'icon-shield1',
                        command: () => {
                            setTimeout(() => {
                                this.editMemberRoleModalComponent.openModal(ClubRoles.Animator);
                            }, 300);
                        },
                    });
                }
            }
        }

        if (
            (this.clubsStore.isMainRoom || this.clubsStore.displayedRoom.visibility == CLUB_VISIBILITIES.HIDDEN) &&
            !this.clubsStore.isACommunityClub &&
            !this.clubsStore.isAProjectClub
        ) {
            this.items.push({
                label: this.translate.instant('administration.global.pages.users.manageRole.deleteMember'),
                icon: 'pi pi-trash',
                command: () => {
                    setTimeout(() => {
                        this.removeMemberModalComponent.openModal();
                    }, 300);
                },
                styleClass: 'menuItemDelete',
            });
        }
    }

    public ngOnInit(): void {
        this.initiatePrimeNg();
    }

    public onRemoveMember($event: any) {
        this.shouldRemoveMember.emit($event);
    }

    public onUpdateMemberRole($event: { memberId: string; newRole: ClubRoles }) {
        this.shouldUpdateMembers.emit($event);
    }
}
