import { ExternalLink } from './external-link.model';

export class Theme {
    public button1TextColor: string;
    public button2TextAndBorderColor: string;
    public clickableLinkColor: string;
    public communityId: string;
    public mailBannerUrl: string;
    public mailSenderConfigName: string;
    public mailSenderName: string;
    public mailSignature: string;
    public platformLogoUrl: string;
    public primaryColor: string;
    public secondaryColor: string;
    public externalLinks: ExternalLink[];

    public constructor(attributes?: Partial<Theme>) {
        Object.assign(this, attributes);
    }
}
