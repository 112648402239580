import { Directive, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccountsService } from '../../../services/identity/accounts.service';

@Directive({
    selector: '[appTokenRefresher]',
})
export class TokenRefresherDirective implements OnInit {
    public constructor(private _accountsService: AccountsService) {}

    public ngOnInit(): void {
        // If token refresh state has changed
        this._accountsService.isRefreshingTokens$
            .pipe(
                // And token is slated to be refreshed
                filter((value) => value),
            )
            .subscribe(() => {
                // Start refresh request
                this._accountsService.refreshToken().subscribe({
                    next: () => {
                        // If successful, errored or cancelled, unblock requests
                        this._accountsService.setRefreshingToken(false);
                    },
                    error: (error) => {
                        // If an error occured, unblock request, logout and throw an error
                        this._accountsService.setRefreshingToken(false);
                        this._accountsService.logout();
                        return throwError(error);
                    },
                });
            });
    }
}
