import { User } from '../../identity/user.model';
import { ProfileProgressItem } from './profile-progress-item';

export class ProfileProgressList {
    public items: ProfileProgressItem[];
    public score: number;

    constructor(private readonly user: User) {
        this.user = user;

        if (user) {
            this.initializeItems();
            this.calculateScore();
        }
    }

    private calculateScore() {
        let score = 0;

        this.items
            .filter((i) => i.completed)
            .forEach((i) => {
                score += i.points;
            });

        this.score = score;
    }

    private initializeItems() {
        this.items = [
            {
                label: 'user.completionScore.profileProgresslabels.skills',
                points: 52,
                completed: this.user.skills.length >= 3,
                inputName: 'skills',
            },
            {
                label: 'user.completionScore.profileProgresslabels.interests',
                points: 25,
                completed: this.user.interests.length >= 2,
                inputName: 'interests',
            },
            {
                label: 'user.completionScore.profileProgresslabels.mobilePhone',
                points: 10,
                completed: !!this.user.mobilePhone,
                inputName: 'mobilePhone',
            },
            {
                label: 'user.completionScore.profileProgresslabels.jobTitle',
                points: 8,
                completed: !!this.user.jobTitle,
                inputName: 'jobTitle',
            },
            {
                label: 'user.completionScore.profileProgresslabels.officePhone',
                points: 3,
                completed: !!this.user.officePhone,
                inputName: 'officePhone',
            },
            {
                label: 'user.completionScore.profileProgresslabels.pictureAccessLink',
                points: 2,
                completed: !!this.user.pictureAccessLink,
                inputName: 'pictureAccessLink',
            },
        ];
    }
}
