import { Component } from '@angular/core';

@Component({
    selector: 'app-app-dms-redirect',
    templateUrl: './app-dms-redirect.component.html',
    styleUrls: ['./app-dms-redirect.component.scss'],
})
export class AppDmsRedirectComponent {
    constructor() {}
}
