import { UpdateCommunityGroupCommand } from './../../models/identity/update-community-group-command.model';
import { CreateCommunityGroupCommand } from './../../models/identity/create-community-group-command.model';
import { CommunityGroup } from './../../models/identity/community-group.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CommunityGroupService {
    private communityGroupsApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.communityGroupsApi.baseUrl}`;

    constructor(private http: HttpClient) {}

    getCommunityGroupsByCommunityId(communityId: string): Observable<CommunityGroup[]> {
        return this.http.get<CommunityGroup[]>(`${this.communityGroupsApiUrl}/${communityId}`);
    }

    createCommunityGroup(command: CreateCommunityGroupCommand): Observable<CommunityGroup> {
        return this.http.post<CommunityGroup>(`${this.communityGroupsApiUrl}`, command);
    }

    updateCommunityGroup(id: string, command: UpdateCommunityGroupCommand): Observable<CommunityGroup> {
        return this.http.post<CommunityGroup>(`${this.communityGroupsApiUrl}/${id}`, command);
    }

    deleteCommunityGroup(id: string): Observable<CommunityGroup> {
        return this.http.delete<CommunityGroup>(`${this.communityGroupsApiUrl}/${id}`);
    }
}
