<p-dropdown
    [options]="communities"
    [(ngModel)]="selectedCommunity"
    (ngModelChange)="ngModelChange($event)"
    optionLabel="displayName"
    [filter]="true"
    filterBy="displayName"
    placeholder="Sélectionnez une communauté"
    appendTo="body"
>
    <ng-template pTemplate="selectedItem">
        <div class="community-item community-item-value" *ngIf="selectedCommunity">
            <div>{{ selectedCommunity.displayName }}</div>
        </div>
    </ng-template>
    <ng-template let-community pTemplate="item">
        <div class="community-item">
            <div>{{ community.displayName }}</div>
        </div>
    </ng-template>
</p-dropdown>
