<div class="p-grid p-jc-between p-my-2">
    <div>
        <h2 class="p-my-auto p-mr-auto p-mx-2">{{ 'community.nav.members.title' | translate }}</h2>
    </div>
</div>
<div>
    <div class="p-grid p-jc-between p-mt-3">
        <div class="p-jc-between p-mb-1">
            <span>
                <input
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'customFilterName')"
                    placeholder="{{ 'community.pages.members.searchByPlaceholder' | translate }}"
                    pTooltip="{{ 'community.pages.members.searchByPlaceholder' | translate }}"
                    class="search-bar p-mx-2"
                />
                <span style="font-size: 16px">{{ 'community.nav.members.among' | translate }}</span>
                <app-list-communities
                    [communities]="communities"
                    [selectedCommunityId]="selectedCommunityId"
                    (eventEmitter)="onChangeCommunity($event)"
                    class="p-mx-2"
                ></app-list-communities>
            </span>
        </div>
        <div>
            <button
                pButton
                class="p-mb-2 p-ml-2 p-mr-0"
                label="{{ 'community.nav.members.createUser' | translate }}"
                [routerLink]="['/community/members/create/']"
                routerLinkActive="active"
            ></button>
            <button
                pButton
                type="button"
                icon="pi icon-more"
                class="btn p-button-outlined p-mb-2 p-mx-2"
                (click)="menu.toggle($event)"
            ></button>
            <p-menu #menu [popup]="true" [model]="manageUsersItems"></p-menu>
        </div>
    </div>
    <p-table
        #dt
        [value]="members"
        [totalRecords]="nbUsersTotalInCommunity"
        [(selection)]="selectedMembers"
        dataKey="userId"
        [rowHover]="true"
        [(rows)]="pageSize"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [paginator]="true"
        [filterDelay]="500"
        [scrollable]="true"
        [style]="{ width: '100%' }"
        class="community-table"
        [resizableColumns]="true"
        [lazy]="true"
        (onLazyLoad)="loadMembersLazy($event)"
        editMode="row"
        styleClass="p-datatable-header-gridlines"
    >
        <ng-template pTemplate="colgroup" let-columns>
            <colgroup *ngIf="selectedMembers.length === 0">
                <col class="list-col-icon" *ngIf="massEditUsersAvailable" />
                <col class="list-col-small" />
                <col class="list-col-small" />
                <col class="list-col-small" />
                <col class="list-col-small" />
                <col class="list-col-medium" />
                <col class="list-col-small" />
                <col class="list-col-large" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
            </colgroup>
            <colgroup *ngIf="selectedMembers.length > 0">
                <col class="list-col-icon" *ngIf="massEditUsersAvailable" />
                <col class="list-col-small" />
                <col class="list-col-medium" />
                <col class="list-col-small" />
                <col class="list-col-small" />
                <col class="list-col-medium" />
                <col class="list-col-small" />
                <col class="list-col-large" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                <col class="list-col-medium" />
                a
                <col class="list-col-medium" />
            </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th class="checkbox-width" scope="col"></th>
                <th pSortableColumn="status" scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Statut
                        <p-sortIcon field="status"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="firstName" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'community.nav.members.firstname' | translate }}
                        <p-sortIcon field="firstName"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="lastName" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'community.nav.members.lastname' | translate }}
                        <p-sortIcon field="lastName"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="email" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'community.nav.members.email' | translate }}
                        <p-sortIcon field="email"></p-sortIcon>
                    </div>
                </th>
                <th pSortableColumn="subscriptionType" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'community.nav.members.plan' | translate }}
                        <p-sortIcon field="availableSubscriptionType[subscriptionType].label"></p-sortIcon>
                    </div>
                </th>
                <th pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        <span pTooltip="Communauté principale">{{
                            'community.nav.members.primaryCommunity' | translate
                        }}</span>
                    </div>
                </th>
                <th pSortableColumn="organization.name" pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'community.nav.members.organization' | translate }}
                        <p-sortIcon field="organization.name"></p-sortIcon>
                    </div>
                </th>
                <th pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'community.nav.members.communityRole' | translate }}
                    </div>
                </th>
                <th pResizableColumn scope="col">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        {{ 'community.nav.members.organizationRole' | translate }}
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-member let-editing="editing">
            <tr class="p-selectable-row" [pEditableRow]="member">
                <td class="checkbox-width">
                    <p-tableCheckbox [value]="member" (click)="addManageMenuItems()"></p-tableCheckbox>
                </td>
                <td class="content-center">
                    <app-user-status-badge [currentUserStatusType]="member.status"></app-user-status-badge>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="member.firstName" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ member.firstName }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText type="text" [(ngModel)]="member.lastName" />
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ member.lastName }}
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td>
                    {{ member.email }}
                </td>
                <td [ngClass]="{ 'overflow-visible': isUserCurentlyEdited(member.userId) }">
                    <p-cellEditor *ngIf="isUserPrimaryCommunityId(member.primaryCommunity.id)">
                        <ng-template pTemplate="input">
                            <p-dropdown
                                [options]="availableSubscriptionType"
                                [(ngModel)]="member.subscriptionType"
                                [style]="{ width: '100%' }"
                                optionLabel="label"
                                optionValue="id"
                                [appendTo]="dt"
                            ></p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{ getUserSubscriptionTypeLibelle(member.subscriptionType) }}
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf="!isUserPrimaryCommunityId(member.primaryCommunity.id)">
                        {{ getUserSubscriptionTypeLibelle(member.subscriptionType) }}</span
                    >
                </td>
                <td>
                    {{ member.primaryCommunity.displayName }}
                </td>
                <td>
                    {{ member.organization?.name }}
                </td>
                <td [ngClass]="{ 'overflow-visible': isUserCurentlyEdited(member.userId) }">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-multiSelect
                                [options]="getUserTypesCategorized(roleTypes.Community)"
                                [(ngModel)]="getUserCommunitySelected(member.communities).roles"
                                [style]="{ width: '100%' }"
                                optionLabel="libelle"
                                [appendTo]="dt"
                                display="chip"
                                [maxSelectedLabels]="1"
                                [selectedItemsLabel]="'{0} items selected'"
                            ></p-multiSelect>
                        </ng-template>
                        <ng-template pTemplate="output"
                            ><span *ngFor="let roles of getUserCommunitySelected(member.communities).roles">
                                {{ getUserTypeLibelle(roles.id) }}
                            </span>
                        </ng-template>
                    </p-cellEditor>
                </td>
                <td [ngClass]="{ 'overflow-visible': isUserCurentlyEdited(member.userId) }">
                    <p-cellEditor *ngIf="isUserPrimaryCommunityId(member.primaryCommunity.id)">
                        <ng-template pTemplate="input">
                            <p-multiSelect
                                [options]="getUserTypesCategorized(roleTypes.Organization)"
                                [(ngModel)]="member.organization.roles"
                                [style]="{ width: '100%' }"
                                optionLabel="libelle"
                                display="chip"
                                [appendTo]="dt"
                            ></p-multiSelect>
                        </ng-template>
                        <ng-template pTemplate="output"
                            ><span *ngFor="let roles of member.organization?.roles">
                                {{ getUserTypeLibelle(roles.id) }}
                            </span>
                        </ng-template>
                    </p-cellEditor>
                    <span *ngIf="!isUserPrimaryCommunityId(member.primaryCommunity.id)">
                        <span *ngFor="let roles of member.organization?.roles">
                            {{ getUserTypeLibelle(roles.id) }}
                        </span></span
                    >
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">Aucun membre.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<app-fullscreen-dialog [(visible)]="dialogVisible">
    <ng-container body [ngSwitch]="importStep">
        <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="importStep1"></ng-container>
        <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="importStep2"></ng-container>
    </ng-container>
    <ng-container footer>
        <div class="p-ml-auto p-ml-auto p-mr-6">
            <button
                pButton
                pRipple
                type="button"
                label="{{ 'components.button.buttonCancel' | translate }}"
                class="p-button-rounded p-button-secondary p-button-text"
                (click)="handleDialogCancelButtonClick()"
            ></button>
            <button
                pButton
                pRipple
                type="button"
                [label]="nextButtonLabel"
                class="p-button-rounded p-ml-1"
                [disabled]="nextStepButtonDisabled"
                (click)="handleNextStepButtonClick()"
            ></button>
        </div>
    </ng-container>
</app-fullscreen-dialog>

<ng-template #importStep1>
    <div class="modal-title p-mx-auto">Importer vers Wudo</div>
    <a class="download-import-model-text p-mx-auto" [href]="memberImportFileUrl" *ngIf="importUsersAvailable"
        >Télécharger le modèle d'import</a
    >
    <div class="p-mx-auto p-text-center instructions-text p-mt-6">
        Vérifiez que votre fichier soit
        <a href="https://fr.wikipedia.org/wiki/Comma-separated_values" target="_blank" class="ready-to-be-importer-text"
            >prêt à être importé</a
        >
        avant le charger
    </div>

    <form novalidate class="p-mx-auto p-mt-2 csv-picker" ngDefaultControl (change)="handleCsvFileFormChange()">
        <app-csv-file-picker #csvPicker [acceptedFormats]="['.csv']" [control]="csvfileForm.controls.file">
        </app-csv-file-picker>
    </form>
</ng-template>

<ng-template #importStep2>
    <app-import-member-column-matching
        class="import-member-column-matching-zone p-mx-auto"
        [file]="csvfileForm.controls.file.value"
        (userChanges)="updateCsvUsers($event)"
    >
    </app-import-member-column-matching>
</ng-template>

<p-dialog
    header="Mail de relance"
    [(visible)]="allUsersFollowUpModalVisible"
    modal="true"
    draggable="false"
    resizable="false"
    styleClass="custom-edit-member-role-modal"
    appendTo="body"
>
    <div>
        <p>
            Vous allez envoyer le mail de relance à
            <span class="allUsersFollowUpTextModal">tous ces utilisateurs :</span>
        </p>
    </div>
    <ul>
        <li>Tous les utilisateurs avec le statut “Inactif”</li>
        <li>Tous les utilisateurs avec le statut “CGU non acceptés”</li>
        <li>Tous les utilisateurs avec le statut “Profil non paramétré”</li>
    </ul>

    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="this.closeAllUsersFollowUpModal()"
        ></button>

        <button
            pButton
            class="p-button p-button-sm p-button-custom-submit"
            type="button"
            label="{{ 'components.button.buttonConfirm' | translate }}"
            (click)="this.closeAllUsersFollowUpModal(); this.sendAccountActivationFollowUpNotification(true)"
        ></button>
    </p-footer>
</p-dialog>

<p-dialog
    header="Mail de relance"
    [(visible)]="selectedUsersFollowUpModalVisible"
    modal="true"
    draggable="false"
    resizable="false"
    styleClass="custom-edit-member-role-modal"
    appendTo="body"
>
    <div>
        <p>
            Vous allez envoyer le mail de relance aux
            <span class="allUsersFollowUpTextModal">utilisateurs selectionnés </span> avec les conditions suivantes :
        </p>
    </div>
    <ul>
        <li>Tous les utilisateurs avec le statut “Inactif”</li>
        <li>Tous les utilisateurs avec le statut “CGU non acceptés”</li>
        <li>Tous les utilisateurs avec le statut “Profil non paramétré”</li>
    </ul>

    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="this.closeSelectedUsersFollowUpModal()"
        ></button>

        <button
            pButton
            class="p-button p-button-sm p-button-custom-submit"
            type="button"
            label="{{ 'components.button.buttonConfirm' | translate }}"
            (click)="this.closeSelectedUsersFollowUpModal(); this.sendAccountActivationFollowUpNotification(false)"
        ></button>
    </p-footer>
</p-dialog>
