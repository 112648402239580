<app-edit-member-role-modal
    #editMemberRoleModalComponent
    [member]="member"
    [memberCurrentRole]="memberCurrentRole"
    [club]="club"
    (shouldUpdateMembers)="onUpdateMemberRole($event)"
></app-edit-member-role-modal>
<app-remove-member-modal #removeMemberModalComponent [memberToRemove]="member" [club]="club" (shouldRemoveMember)="onRemoveMember($event)">
</app-remove-member-modal>

<div class="custom-edit-member" *ngIf="(memberIsHovered || isOnMobile || menu.visible) && shouldDisplayGearButton">
    <button pButton pRipple class="p-button-rounded p-button-text p-button-plain" icon="icon-f-settings" (click)="menu.toggle($event)"></button>
</div>
<p-menu #menu [popup]="true" [model]="items" appendTo="body" styleClass="custom-edit-member-menu"></p-menu>
