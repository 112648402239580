import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { messages } from '../constants/messages';
import { AccountsService } from '../services/identity/accounts.service';
import { ErrorHelper } from './error.helper';
import { ToastrHelper } from './toastr.helper';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private readonly accountsService: AccountsService,
        private readonly toastrHelper: ToastrHelper,
        private readonly errorHelper: ErrorHelper,
        private readonly router: Router,
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            this.accountsService.isAuthenticated &&
            !request.url.includes(environment.microservices.identity.accountsApi.login) &&
            !request.url.includes(environment.microservices.identity.profilesApi.resetPassword)
        ) {
            request = this.addToken(request, this.accountsService.getToken());
        }

        request = this.addRestrictionAccessApiKey(request);
        const redirectUrl = this.router.url;

        return next.handle(request).pipe(
            catchError((error) => {
                return this.handleResponseError(redirectUrl, error, request, next);
            }),
        );
    }

    public handleResponseError(
        redirectUrl: string,
        error: HttpErrorResponse,
        request?: HttpRequest<unknown>,
        next?: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        // Traitement spécifique pour les formulaires de l'administration et l'édition de profil.
        this.handleAdminAndEditProfileRequest(error);

        switch (error.status) {
            case 400:
                this.errorHelper.showError(error);
                break;
            case 401:
                // Retry request after refreshing token, if possible
                return this.retryStrategy(redirectUrl, request, next);
            case 403:
                this.toastrHelper.showWarning(messages.httpForbiddenMessage);
                break;
            case 409:
                this.errorHelper.showError(error);
                break;
        }

        return throwError(this.errorHelper.getError(error));
    }

    public retryStrategy(redirectUrl: string, request?: HttpRequest<any>, next?: HttpHandler) {
        // If a 401 occurred, try to refresh token or wait for an already running refresh to complete
        return this.refreshToken().pipe(
            switchMap(() => {
                // Once refresh is done, trigger the blocked request again, with the new token
                return next.handle(this.addToken(request, this.accountsService.getToken()));
            }),
            // If the refresh itself errored
            catchError((error) => {
                // If the request isn't a 401, handle the new error again since they may need special processing
                if (error.status !== 401) {
                    return this.handleResponseError(redirectUrl, error);
                } else {
                    // If the error is also a 401, logout user and throw error
                    this.accountsService.logout(redirectUrl);
                    return throwError(next);
                }
            }),
        );
    }

    public handleAdminAndEditProfileRequest(httpResponse: any): Observable<HttpEvent<any>> {
        if ((this.router.url.includes('/administration') || this.router.url.includes('/profile/edit')) && httpResponse.status != 401) {
            this.errorHelper.showError(httpResponse.error);

            return throwError(this.errorHelper.getError(httpResponse.statusText));
        }
    }

    private refreshToken(): Observable<unknown> {
        // If app is not currently refreshing tokens
        if (!this.accountsService.isRefreshingTokens) {
            // Start refreshing tokens
            this.accountsService.setRefreshingToken(true);
        }

        // Lock request until refreshing token is finished
        return this.accountsService.isRefreshingTokens$.pipe(
            // Only emits when refreshToken is done
            filter((value) => !value),
            // To prevent uncleaned subscription garbage in memory,
            // complete once the refreshToken request finished one time only
            take(1),
        );
    }

    public addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        // Add the jwt token to the Authorization header
        return request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
        });
    }

    public addRestrictionAccessApiKey(request: HttpRequest<any>): HttpRequest<any> {
        // Add the restriction key on the restriction-access-api-key header
        if (environment.microservices.restrictionAccessApiKey) {
            return request.clone({
                setHeaders: { 'restriction-access-api-key': environment.microservices.restrictionAccessApiKey },
            });
        }

        return request;
    }
}
