import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommunityWithRoles } from '../../models/identity/community-with-roles.model';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class WordingService {
    constructor(private translate: TranslateService) {}

    public loadTranslationsForCommunity(community: CommunityWithRoles) {
        const selectedWording = environment.wordingPerCommunity[community?.id];
        if (selectedWording) {
            this.translate.use(selectedWording);
        } else {
            this.translate.use(environment.wordingPerCommunity.default);
        }
    }
}
