import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../../../services/identity/profile.service';

@Component({
    selector: 'app-reset-password-modal',
    templateUrl: './reset-password-modal.component.html',
})
export class ResetPasswordModalComponent {
    @Input() email: string;

    constructor(private readonly profileService: ProfileService, private readonly toastrService: ToastrService) {}

    resetPassword() {
        const errorMessage =
            'Une erreur est survenue lors de la demande de mot de passe. Veuillez réessayer ultérieurement et/ou vérifier votre adresse mail.';
        const successMessage =
            'Vous allez recevoir très prochainement un email pour réinitialiser votre mot de passe.';

        this.profileService.resetPassword(this.email).subscribe(
            (_) => this.toastrService.success(successMessage),
            (_) => this.toastrService.error(errorMessage),
        );
    }
}
