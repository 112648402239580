import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { User } from '../../../models/identity/user.model';

@Injectable({ providedIn: 'root' })
export class UserSkillsService {
    constructor(private http: HttpClient) {}

    public addUserSkills(user: User): Observable<any> {
        return this.http.post(
            `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.usersApi.baseUrl}/${user.userId}/skills`,
            { skills: user.skills.map((userSkill) => userSkill.id) },
        );
    }
}
