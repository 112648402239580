import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../../app-routing.module';
import { FiltersModule } from '../../../filters/filters.module';
import { SharedModule } from '../../../shared/shared.module';
import { UserModule } from '../../../user/user.module';
import { CompleteProfilEndComponent } from './complete-profile-end/complete-profile-end.component';
import { CompleteProfileStepOneComponent } from './complete-profile-step-one/complete-profile-step-one.component';
import { CompleteProfileStepThreeComponent } from './complete-profile-step-three/complete-profile-step-three.component';
import { CompleteProfileStepTwoComponent } from './complete-profile-step-two/complete-profile-step-two.component';
import { OnBoardingCompleteProfileComponent } from './onboarding-complete-profile.component';

const moduleItems = [
    OnBoardingCompleteProfileComponent,
    CompleteProfileStepOneComponent,
    CompleteProfileStepTwoComponent,
    CompleteProfileStepThreeComponent,
    CompleteProfilEndComponent,
];

@NgModule({
    declarations: moduleItems,
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        AppRoutingModule,
        FormsModule,
        FiltersModule,
        SharedModule,
        UserModule,
    ],
    providers: [],
    exports: moduleItems,
})
export class OnBoardingCompleteProfileModule {}
