import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'processSearch',
    pure: true,
})
export class ProcessSearchPipe implements PipeTransform {
    transform(content: string): string {
        return (
            content
                ?.trim()
                .split(/[\s\-']/)
                .join('* ') + '*'
        );
    }
}
