export enum RoleType {
    Global = 0,
    Community = 1,
    Organization = 2,
}

export class UserType {
    public id: string;
    public code: string;
    public roleMyCrowdCompany: string;
    public libelle: string;
    public roleType: RoleType;
}
