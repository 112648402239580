import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concat, from, interval, Observable, of, Subscription } from 'rxjs';
import { catchError, first, mapTo, switchMap, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { localStorageKeys } from '../../constants/local-storage-keys';
import { DateHelper } from '../../helpers/date.helper';
import { PwaStatus } from '../../models/identity/pwa-status.enum.model';
import { PwaStore } from '../../models/identity/pwa-store.model';
import { PwaA2hsComponent } from '../../shared/components/pwa-a2hs/pwa-a2hs.component';
import { PwaUpdateComponent } from '../../shared/components/pwa-update/pwa-update.component';
import { AccountsService } from '../identity/accounts.service';
import { ProfileService } from '../identity/profile.service';
type MobileType = 'ios' | 'other';

enum PromptStatusEnum {
    None = 0,
    NotSupported = 1, // No mobile device
    Display = 2, // Can display "Add To Home Screen" (until max retry "PWA_A2HS_MAX_RETRY")
    Hide = 3, // User refuse to install PWA
}

const PWA_A2HS_PROMPT_ELAPSED_DAYS = 7; // Number of elapsed days before show again the prompt to install PWA.
const PWA_A2HS_MAX_RETRY = 3; // Maximum of retry to propose the prompt to install PWA.
const PWA_CHECK_UPDATE_EVERY_DAYS = 1;

// Detects if device is a mobile
const isMobile = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|android|windows phone|blackberry/i.test(userAgent);
};

// Detects if device is on Android
const isAndroid = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/i.test(userAgent);
};

// Detects if device is on iOS
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad/i.test(userAgent);
};

const isTabletScreenSize = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /ipad/i.test(userAgent) || (window.innerWidth > 767 && window.innerWidth < 1024);
};

const isLaptopScreenSize = () => {
    return window.innerWidth >= 1200;
};

// Detects if device is in standalone mode
const isStandalone = () => {
    return ('standalone' in window.navigator && (window.navigator as any).standalone) || /utm_source=pwa/i.test(window.location.search || ''); // // <=> manifest.webmanifest / "start_url");
};

@Injectable({ providedIn: 'root' })
export class PwaService {
    private profilesApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.profilesApi.baseUrl}`;
    private pwaStatusApiUrl = this.profilesApiUrl;
    private _promptEvent: any; // BeforeInstallPromptEvent
    public get promptEvent() {
        return this._promptEvent;
    }
    public set promptEvent(event: any) {
        this._promptEvent = event;
        if (event) {
            this.isInitiliazed = false; // force to initiliaze (beforePromptEvent received after first initiliazation)
            this.init();
        }
    }

    public get isMobile(): boolean {
        return isMobile() || this.isSmallScreenSize;
    }

    public get isSmallScreenSize(): boolean {
        return window.innerWidth < 600;
    }

    public get isMediumScreenSize(): boolean {
        return window.innerWidth > 601 && window.innerWidth < 1024;
    }

    public get isTablet(): boolean {
        return isTabletScreenSize() || this.isMediumScreenSize;
    }

    public get shouldDisplayNavMenu(): boolean {
        return !isLaptopScreenSize() && !isTabletScreenSize();
    }

    private onCanUsePlatformPwaA2hs$: Subscription;
    private onCanUsePlatformPwaUpdate$: Subscription;
    private onInstalled$: Subscription;
    private onInstallCancelled$: Subscription;
    private onReload$: Subscription;

    private isInitiliazed = false;
    private promptStatus = PromptStatusEnum.None; // Current prompt status (according to mobile device and last retry)
    private maxPrompt = false; // Indicate that the prompt, to install PWA, is display the last time! (user reach max retry);
    private pwaStore: PwaStore;

    constructor(
        private readonly appRef: ApplicationRef,
        private readonly swUpdate: SwUpdate,
        private readonly modalService: NgbModal,
        private readonly accountsService: AccountsService,
        private readonly profileService: ProfileService,
    ) {}

    public init() {
        if (this.swUpdate.isEnabled) {
            this.appRef.isStable
                .pipe(
                    first((isStable) => isStable === true),
                    switchMap(() => this.swUpdate.available),
                )
                .subscribe(() => {
                    this.swUpdate.activateUpdate().then(() => document.location.reload());
                });
        }

        if (this.isInitiliazed) {
            return;
        }

        this.isInitiliazed = true;
        this.initPromptPwaA2hs();
        this.subscribeToUpdateApp();
    }

    /**
     * Detect the the user not want to install to PWA
     */
    public cancelInstall() {
        const pwaA2hs = this.pwaStore;
        pwaA2hs.a2hsLastPrompt = new Date();
        pwaA2hs.a2hsRetry = (pwaA2hs.a2hsRetry || 0) + 1;
        this.savePwaStore(pwaA2hs);
        this.refreshPwaStore();

        this.modalService.dismissAll();
    }

    /**
     * Detect that the PWA is installed to hide pop up and update local storage
     */
    public trackInstalled() {
        const pwaA2hs = this.pwaStore;
        pwaA2hs.isInstalled = true;
        this.savePwaStore(pwaA2hs);
        this.refreshPwaStore();

        this.modalService.dismissAll();
    }

    checkForUpdate(): Observable<boolean> {
        const waitFor = 1000;

        const available$ = this.swUpdate.available.pipe(
            mapTo(true),
            timeout(waitFor),
            catchError(() => {
                return of(false);
            }),
        );

        return from(this.swUpdate.checkForUpdate()).pipe(switchMap(() => available$));
    }

    isSwAvailable(): boolean {
        return 'serviceWorker' in navigator && this.swUpdate != null && this.swUpdate.isEnabled;
    }

    /**
     * Check if new version of PWA is available (ex : when device becomes online)
     */
    // public checkForUpdate() {
    //     if (!this.canPromptToUpdate()) {
    //         return;
    //     }
    //     this.swUpdate.checkForUpdate();
    // }

    /**
     *  Update the PWA and reload current page.
     */
    public downloadNewVersion() {
        this.swUpdate.activateUpdate().then(() => {
            document.location.reload();
        });
    }

    private initPromptPwaA2hs() {
        if (isMobile() && !isIos() && this.promptEvent) {
            this.showPromptPwaA2hs('other');
        }

        if (isMobile() && isIos() && !isStandalone()) {
            this.showPromptPwaA2hs('ios');
        }
    }

    private showPromptPwaA2hs(mobileType: MobileType) {
        this.setPromptPwaA2hsStatus();
        if (
            this.promptStatus !== PromptStatusEnum.Display ||
            this.accountsService.currentUser?.pwaStatus === PwaStatus.Installed ||
            this.accountsService.currentUser?.pwaStatus === PwaStatus.Dismissed
        ) {
            return;
        }

        if (!this.accountsService.canUsePlatform) {
            this.onCanUsePlatformPwaA2hs$ = this.accountsService.onCanUsePlatform.subscribe(() => {
                this.openPromptPwaA2hs(mobileType);
                this.onCanUsePlatformPwaA2hs$.unsubscribe();
            });
        } else {
            this.openPromptPwaA2hs(mobileType);
        }
    }

    private openPromptPwaA2hs(mobileType: MobileType) {
        const modalRef = this.modalService.open(PwaA2hsComponent, { size: 'sm', centered: true, scrollable: false });
        const pwaA2hsComponent = modalRef.componentInstance as PwaA2hsComponent;
        pwaA2hsComponent.deferredPrompt = this.promptEvent;
        pwaA2hsComponent.maxPrompt = this.maxPrompt;
        pwaA2hsComponent.isIos = mobileType === 'ios';
        this.onInstallCancelled$ = pwaA2hsComponent.onInstallCancelled.subscribe(() => {
            this.cancelInstall();
            this.onInstallCancelled$.unsubscribe();
        });
        this.onInstalled$ = pwaA2hsComponent.onInstalled.subscribe(() => {
            this.trackInstalled();
            this.onInstalled$.unsubscribe();
        });
    }
    public installPwa() {
        if (isIos()) {
            this.openPromptPwaA2hs('ios');
        } else if (this.promptEvent) {
            this.promptEvent.prompt();
            this.promptEvent.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    this.profileService.setPwaStatus(PwaStatus.Installed).subscribe();
                    this.trackInstalled();
                }
            });
        }
    }
    /**
     * Indicate if prompt, to download new version, can be display (PWA context).
     */
    private canPromptToUpdate(): boolean {
        return environment.features.pwa && this.swUpdate.isEnabled && isMobile() && isStandalone();
    }

    /**
     * Subcribe to check if new version of PWA is available.
     */
    private subscribeToUpdateApp() {
        if (!this.canPromptToUpdate()) {
            return;
        }

        // Polling for PWA updates every "PWA_CHECK_UPDATE_EVERY_DAYS" days!
        const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
        const everyXDays$ = interval(PWA_CHECK_UPDATE_EVERY_DAYS * 24 * 60 * 60 * 1000);
        const everyXDaysOnceAppIsStable$ = concat(appIsStable$, everyXDays$);
        everyXDaysOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());

        //Subscribe to new version of PWA available
        this.swUpdate.available.subscribe(() => {
            this.showPromptPwaUpdate();
        });
    }

    private showPromptPwaUpdate() {
        // Display prompt only if user can use platform (authenticate + accept terms of user + complete onboarding profile)
        if (!this.accountsService.canUsePlatform) {
            this.onCanUsePlatformPwaUpdate$ = this.accountsService.onCanUsePlatform.subscribe(() => {
                this.openPromptPwaUpdate();
                this.onCanUsePlatformPwaUpdate$.unsubscribe();
            });
        } else {
            this.openPromptPwaUpdate();
        }
    }

    private openPromptPwaUpdate() {
        const modalRef = this.modalService.open(PwaUpdateComponent, {
            size: 'sm',
            centered: true,
            scrollable: false,
            backdrop: 'static',
        });
        const pwaUpdateComponent = modalRef.componentInstance as PwaUpdateComponent;
        this.onReload$ = pwaUpdateComponent.onReload.subscribe(() => {
            this.downloadNewVersion();
            this.modalService.dismissAll();
            this.onReload$.unsubscribe();
        });
    }

    /**
     * Indicate that the "Add To Home Screen" button can be display or not.
     * Rules :
     *   - the current device is a mobile,
     *   - the last date of decline, the PWA install, is greather than "PWA_A2HS_PROMPT_ELAPSED_DAYS" days,
     *   - the user hasn't decline definitly, the PWA install, after refused more than "PWA_A2HS_MAX_RETRY" times!
     */
    private setPromptPwaA2hsStatus() {
        this.refreshPwaStore();
        const currentRetry = this.pwaStore.a2hsRetry || 0;

        this.promptStatus = PromptStatusEnum.None;
        this.maxPrompt = currentRetry === PWA_A2HS_MAX_RETRY;

        if (!environment.features.pwa || !isMobile()) {
            this.promptStatus = PromptStatusEnum.NotSupported;
            return;
        }

        if (this.pwaStore.isInstalled || false) {
            this.promptStatus = PromptStatusEnum.Hide;
            return;
        }

        if (currentRetry > PWA_A2HS_MAX_RETRY) {
            this.promptStatus = PromptStatusEnum.Hide;
            return;
        }

        if (!this.pwaStore.a2hsLastPrompt || DateHelper.elaspsedDay(this.pwaStore.a2hsLastPrompt) >= PWA_A2HS_PROMPT_ELAPSED_DAYS) {
            this.promptStatus = PromptStatusEnum.Display;
            return;
        }
    }

    /**
     * Indicate if a pop up has already prompted to prevent the current user to install the native mobile application from mobile store.
     *
     * @param appId id of external app
     */
    private isExternalAppPrompted(appId: string): boolean {
        this.refreshPwaStore();
        return this.pwaStore.promptExternalAppStoreIds?.some((id) => id === appId);
    }
    /**
     * Acknowledge the status of prompted pop up, to install native mobile application from mobile store.
     *
     * @param appId id of external app
     */
    private setExternalAppPrompted(appId: string) {
        this.refreshPwaStore();
        if (!this.pwaStore.promptExternalAppStoreIds) {
            this.pwaStore.promptExternalAppStoreIds = [];
        }
        this.pwaStore.promptExternalAppStoreIds.push(appId);
        this.savePwaStore(this.pwaStore);
    }

    /**
     * Store 'pwa' informations in localStorage (key 'pwa')
     *
     * @param value new value or PwaStore
     */
    private savePwaStore(value: PwaStore) {
        localStorage.setItem(localStorageKeys.pwa, JSON.stringify(value));
        this.pwaStore = value;
    }

    /**
     * Load 'pwa' informations from localStorage (key 'pwa') and refresh the local variable 'this.pwaStore'.
     * If the localStorage isn't exist, a new PwaStore object is instanciate.
     */
    private refreshPwaStore() {
        const store = localStorage.getItem(localStorageKeys.pwa);
        this.pwaStore = store ? JSON.parse(store) : new PwaStore();
    }
}
