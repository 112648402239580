<div class="row row-eq-height">
    <app-infinite-scroll (scrolled)="onScroll()">
        <div class="project-list">
            <app-project-details-card
                *ngFor="let project of projects"
                [project]="project"
                isAdmin="true"
            ></app-project-details-card>
        </div>
    </app-infinite-scroll>

    <div *ngIf="!projectsLoaded" class="spinner-border text-info" role="status">
        <span class="sr-only">{{ 'projects.tabs.projectList.loadingMessage' | translate }}</span>
    </div>

    <app-no-result class="col-12" *ngIf="projectsLoaded && projects.length == 0" reduced="true"></app-no-result>
</div>
