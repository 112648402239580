import { Observable } from 'rxjs';

import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { environment } from '../../environments/environment';
import { ClubActivityService } from '../clubs/services/club-activity.service';
import { UserPermissions } from '../models/identity/user-permission.model';
import { ExternalLink } from '../models/shared/external-link.model';
import { PwaService } from '../services/pwa/pwa.service';
import { PermissionsState } from '../states/permissions/permissions.state';
import { CurrentThemeStore } from '../stores/current-theme.store';
import { AccountsService } from './../services/identity/accounts.service';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styles: [
        `
            :host ::ng-deep .club-new-activity-badge {
                position: absolute;
                right: 21%;
                top: -10%;
                .p-badge {
                    min-width: 1rem;
                    height: 1rem;
                }
            }
        `,
    ],
})
export class NavMenuComponent {
    defaultDirectory: string;

    // Importing public dictionary for environment's lexical
    dictionary = environment.dictionary;
    externalLinks: ExternalLink[];

    // Checking if the current environment allows documents
    public features = environment.features;
    @Select(PermissionsState.getPermissions)
    permissions$: Observable<UserPermissions>;
    public sideBarVisible = false;

    constructor(
        public readonly pwaService: PwaService,
        readonly currentThemeStore: CurrentThemeStore,
        private readonly accountsService: AccountsService,
        readonly clubActivityService: ClubActivityService,
    ) {
        this.currentThemeStore.currentTheme$.subscribe((theme) => {
            // if mainCommunity has link - add menu item to access external link
            if (this.currentThemeStore.currentTheme.externalLinks.length > 0) {
                this.externalLinks = this.currentThemeStore.currentTheme?.externalLinks;
            }
        });
        if (this.accountsService.hasUserPrimaryCommunity(environment.communitiesId.boostFrenchFabId)) {
            this.defaultDirectory = 'organizations';
        } else {
            this.defaultDirectory = 'members';
        }
    }

    public closeSideBar() {
        this.sideBarVisible = false;
    }

    public noExternalLinks() {
        if (this.externalLinks === undefined) return true;

        return this.externalLinks?.length === 1 && this.currentThemeStore.currentTheme?.externalLinks[0] === null;
    }

    public openSideBar() {
        this.sideBarVisible = true;
    }
}
