import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import '../../../helpers/extensions/formgroup.extension';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { RetrieveThemeCommand } from '../../../models/identity/commands/retrieve-theme-command.model';
import { CommunityMailSender } from '../../../models/shared/community-mail-sender';
import { ExternalLink } from '../../../models/shared/external-link.model';
import { Theme } from '../../../models/shared/theme.model';
import { AccountsService } from '../../../services/identity/accounts.service';
import { ThemeService } from '../../../services/shared/theme.service';
import { CurrentThemeStore } from '../../../stores/current-theme.store';

@Component({
    selector: 'app-theme-form',
    templateUrl: './theme-form.component.html',
    styleUrls: ['./theme-form.component.scss'],
})
export class ThemeFormComponent implements OnInit {
    @Input() public communityExternalLinks: FormArray | undefined;
    public communityPotentialMailSender: CommunityMailSender[];
    public form = this.formBuilder.group({
        platformLogo: [null],
        primaryColor: [null, Validators.required],
        secondaryColor: [null, Validators.required],
        button1TextColor: [null, Validators.required],
        button2TextAndBorderColor: [null, Validators.required],
        clickableLinkColor: [null, Validators.required],
        mailBanner: [null],
        mailSenderName: [null, [Validators.required, Validators.maxLength(60)]],
        mailSignature: [null, [Validators.required, Validators.maxLength(60)]],
    });
    public selectedMailSender: CommunityMailSender;
    @Output()
    public themeChanged: EventEmitter<Theme> = new EventEmitter();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly accountService: AccountsService,
        private readonly themeService: ThemeService,
        private readonly themeStore: CurrentThemeStore,
        private readonly toastrHelper: ToastrHelper,
    ) {}

    public ngOnInit() {
        this.themeService.retrievePotentialsMailSender(this.accountService.currentCommunity.id).subscribe((communityPotentialMailSender) => {
            this.communityPotentialMailSender = communityPotentialMailSender;

            this.themeService
                .retrieve(new RetrieveThemeCommand({ communityId: this.accountService.currentCommunity.id }))
                .subscribe((theme) => this.populateForm(theme));
        });

        this.accountService.currentCommunityChange.subscribe(() => {
            this.themeService.retrievePotentialsMailSender(this.accountService.currentCommunity.id).subscribe((communityPotentialMailSender) => {
                this.communityPotentialMailSender = communityPotentialMailSender;

                this.themeService
                    .retrieve(new RetrieveThemeCommand({ communityId: this.accountService.currentCommunity.id }))
                    .subscribe((theme) => this.populateForm(theme));
            });
        });
    }

    public onChange() {
        this.themeChanged.emit(
            new Theme({
                platformLogoUrl: this.form.controls.platformLogo?.value
                    ? URL.createObjectURL(this.form.controls.platformLogo.value).toString()
                    : null,
                primaryColor: this.form.controls.primaryColor.value,
                secondaryColor: this.form.controls.secondaryColor.value,
                button1TextColor: this.form.controls.button1TextColor.value,
                button2TextAndBorderColor: this.form.controls.button2TextAndBorderColor.value,
                clickableLinkColor: this.form.controls.clickableLinkColor.value,
                mailBannerUrl: this.form.controls.mailBanner?.value ? URL.createObjectURL(this.form.controls.mailBanner.value).toString() : null,
                mailSenderName: this.form.controls.mailSenderName.value,
                mailSignature: this.form.controls.mailSignature.value,
                externalLinks: this.communityExternalLinks?.value,
            }),
        );
    }

    public onSubmit() {
        if (this.form.invalid || this.communityExternalLinks?.invalid) {
            this.toastrHelper.showWarning('Veuillez vérifier votre saisie avant de sauvegarder.');
            return;
        }

        const formData = new FormData();
        Object.keys(this.form.controls).forEach((key) => {
            formData.append(key, this.form.get(key).value);
        });

        this.communityExternalLinks?.value.forEach((link: ExternalLink, index: number) => {
            formData.append(`externalLinks[${index}].name`, link.name);
            formData.append(`externalLinks[${index}].url`, link.url);
        });
        formData.append('communityId', this.accountService.currentCommunity.id);
        formData.append('mailSenderConfigName', this.selectedMailSender.mailSenderConfigName);

        this.themeService.save(formData).subscribe((theme) => {
            if (this.accountService.getUserPrimaryCommunity().id == theme.communityId) {
                this.themeStore.currentTheme = theme;
                this.themeService.applyThemeToDocument(theme);
                this.form.resetFormCleanly();
                this.populateForm(theme);
            }
            this.toastrHelper.showSuccess('Thème sauvegardé');
        });
    }

    public populateForm(theme: Theme) {
        this.form.controls.platformLogo.setValue(null);
        this.form.controls.mailBanner.setValue(null);
        this.form.controls.primaryColor.setValue(theme.primaryColor);
        this.form.controls.secondaryColor.setValue(theme.secondaryColor);
        this.form.controls.button1TextColor.setValue(theme.button1TextColor);
        this.form.controls.button2TextAndBorderColor.setValue(theme.button2TextAndBorderColor);
        this.form.controls.clickableLinkColor.setValue(theme.clickableLinkColor);
        this.form.controls.mailSenderName.setValue(theme.mailSenderName);
        this.form.controls.mailSignature.setValue(theme.mailSignature);

        this.selectedMailSender = this.communityPotentialMailSender.find(
            (mailSender) => mailSender.mailSenderConfigName === theme.mailSenderConfigName,
        );

        this.communityExternalLinks = this.formBuilder.array(
            theme.externalLinks.map((link) =>
                this.formBuilder.group({
                    name: [link.name, Validators.required],
                    url: [link.url, [Validators.required, Validators.pattern('https?://.+')]],
                }),
            ),
        );

        this.onChange();
    }
}
