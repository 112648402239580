<p-dialog
    header="{{ headerTitle }}"
    [(visible)]="modalVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    styleClass="custom-edit-member-role-modal"
    appendTo="body"
>
    {{ message }}
    <p-footer>
        <button
            pButton
            type="button"
            class="p-button-text p-button p-button-sm p-button-custom-cancel"
            label="{{ 'components.button.buttonCancel' | translate }}"
            (click)="closeModal()"
        ></button>

        <button
            pButton
            class="p-button p-button-sm p-button-custom-submit"
            type="button"
            label="{{ 'components.button.buttonConfirm' | translate }}"
            (click)="handleOnSubmit()"
        ></button>
    </p-footer>
</p-dialog>
