import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { BaseEventCommand } from '../../../models/events/base-event-command.model';
import { EventRegistrationPolicyEnum } from '../../../models/events/event-registration-policy.enum.model';
import { Community } from '../../../models/identity/community.model';
import { WudoApplicationType } from '../../../models/identity/enumerations/wudoApplicationType';
import { OrganizationBase } from '../../../models/identity/organization-base.model';
import { JoinableType } from '../../../models/shared/joinable-type.model';
import { CommunityService } from '../../../services/identity/community.service';
import { OrganizationService } from '../../../services/identity/organization.service';
import { ScopeSelectionFormGroup } from '../../../shared/forms/scope-selection.form';
import { BaseEventStep } from '../../directives/base-event-step.directive';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-event-form-step-three',
    templateUrl: './event-form-step-three.component.html',
    styleUrls: ['./event-form-step-three.component.scss'],
})
export class EventFormStepThreeComponent extends BaseEventStep implements OnInit {
    public JoinableType = JoinableType;

    allCommunities: Community[] = [];
    userCommunities: Community[] = [];

    userOrganization: OrganizationBase;

    EventRegistrationPolicyEnum = EventRegistrationPolicyEnum;
    canCreateAllCommunitiesProject: boolean;

    public get visibility(): ScopeSelectionFormGroup {
        return this.stepForm.get('visibility') as ScopeSelectionFormGroup;
    }

    constructor(
        toastrHelper: ToastrHelper,
        translate: TranslateService,
        private readonly formBuilder: FormBuilder,
        private readonly communityService: CommunityService,
        private readonly organizationService: OrganizationService,
    ) {
        super(toastrHelper, translate);
    }

    ngOnInit() {
        forkJoin([
            this.communityService.getCommunities(),
            this.editMode && !this.isCurrentUserOwner
                ? this.communityService.getUserCommunities(this.currentProfile.userId)
                : this.communityService.getMyCommunities(),
            this.editMode && !this.isCurrentUserOwner
                ? this.organizationService.getUserOrganization(this.currentProfile.userId)
                : this.organizationService.getMyOrganization(),
        ]).subscribe(
            ([allCommunities, userCommunities, organization]: [Community[], Community[], OrganizationBase]) => {
                this.allCommunities = allCommunities.filter(
                    (community) => !community.applicationAccesses.includes(WudoApplicationType.Mobile),
                );
                this.userCommunities = userCommunities.filter(
                    (community) => !community.applicationAccesses.includes(WudoApplicationType.Mobile),
                );
                this.userOrganization = organization;

                this.createForm();

                this.isStepLoaded = true;
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
        );

        this.communityService.checkIfCurrentUserIsIntoEnclosedCommunity().subscribe(({ visibility }) => {
            this.canCreateAllCommunitiesProject = visibility;
        });
    }

    protected override createForm(): void {
        this.stepForm = this.formBuilder.group({
            registrationPolicy: [EventRegistrationPolicyEnum.Unlimited, Validators.required],
            maxAttendeesCount: [
                null,
                [
                    this.requiredIfValidator(
                        () => this.stepForm?.controls?.registrationPolicy.value === EventRegistrationPolicyEnum.Limited,
                    ),
                    Validators.min(1),
                    Validators.max(99999),
                ],
            ],
            visibility: new ScopeSelectionFormGroup(this.formBuilder, this.userCommunities),
        });

        const registrationPolicySubscription = this.stepForm.controls.registrationPolicy.valueChanges.subscribe(() => {
            this.stepForm.controls.maxAttendeesCount.setValue(null, { emitEvent: false });
            this.stepForm.controls.maxAttendeesCount.updateValueAndValidity({ emitEvent: false });
        });
        const maxAttendeesCountSubscription = this.stepForm.controls.maxAttendeesCount.valueChanges.subscribe(() => {
            this.stepForm.controls.registrationPolicy.setValue(EventRegistrationPolicyEnum.Limited, {
                emitEvent: false,
            });
            this.stepForm.controls.registrationPolicy.updateValueAndValidity({ emitEvent: false });
        });

        this.subscription.add(registrationPolicySubscription);
        this.subscription.add(maxAttendeesCountSubscription);

        if (this.editMode) {
            this.patchForm();
        }
    }

    private patchForm(): void {
        this.stepForm.patchValue(
            {
                registrationPolicy:
                    this.eventCommand.maxAttendeesCount !== null
                        ? EventRegistrationPolicyEnum.Limited
                        : EventRegistrationPolicyEnum.Unlimited,
                maxAttendeesCount: this.eventCommand.maxAttendeesCount,
            },
            { emitEvent: false },
        );

        this.visibility.selectedCommunities = this.eventCommand.communities ?? [];
        this.visibility.organizationVisibility.setValue(this.eventCommand.organizationVisibility);
        this.visibility.myCommunitiesVisibility.setValue(this.visibility.getUserSelectedCommunitiesCount() > 0);
        this.visibility.allCommunitiesVisibility.setValue(this.eventCommand.allCommunitiesVisibility);
    }

    public override updateEventCommandFromStepComponent(): void {
        const command: BaseEventCommand = this.stepForm.value;

        this.eventCommand.maxAttendeesCount = command.maxAttendeesCount;

        this.eventCommand.organizationVisibility = this.visibility.organizationVisibility.value;
        this.eventCommand.organization = this.visibility.organizationVisibility.value ? this.userOrganization : null;
        this.eventCommand.allCommunitiesVisibility = this.visibility.allCommunitiesVisibility.value;
        this.eventCommand.communities = this.visibility.selectedCommunities;
    }

    public onMaxAttendeeKeydown(event: KeyboardEvent): boolean {
        return !this.hasInvalidCharacters(event.key);
    }

    public onMaxAttendeePaste(event: ClipboardEvent): boolean {
        return !this.hasInvalidCharacters(event.clipboardData.getData('text/plain'));
    }

    private hasInvalidCharacters(value: string): boolean {
        // eslint-disable-next-line no-useless-escape
        const matches = value.match(/[\+\-\,\.]/gi);
        if (matches !== null) {
            return true;
        }

        return false;
    }
}
