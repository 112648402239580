import { TranslateService } from '@ngx-translate/core';
import { SelectListItem } from '../shared/select-list-item.model';

export enum EventAccessTypeEnum {
    Physical = 1,
    Online = 2,
}

export const EventAccessTypeEnumLabelMap: Record<EventAccessTypeEnum, string> = {
    [EventAccessTypeEnum.Physical]: 'events.formStep2.access.physical',
    [EventAccessTypeEnum.Online]: 'events.formStep2.access.online',
};

export function getEventAccessTypeEnumSelectList(translate: TranslateService): SelectListItem<EventAccessTypeEnum>[] {
    const orderedValues: EventAccessTypeEnum[] = [EventAccessTypeEnum.Physical, EventAccessTypeEnum.Online];

    const result: SelectListItem<EventAccessTypeEnum>[] = [];

    for (const value of orderedValues) {
        result.push(
            new SelectListItem<EventAccessTypeEnum>(value, translate.instant(EventAccessTypeEnumLabelMap[value])),
        );
    }

    return result;
}
