import { OnChanges, Output } from '@angular/core';
import { Component, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-fullscreen-dialog',
    templateUrl: './fullscreen-dialog.component.html',
    styleUrls: ['./fullscreen-dialog.component.scss'],
})
export class FullscreenDialogComponent implements OnChanges {
    @Output() visibleChange: EventEmitter<any> = new EventEmitter();

    @Input() visible: boolean;

    ngOnChanges() {
        document.getElementsByTagName('body')[0].style.overflow = this.visible ? 'hidden' : 'auto';
    }

    close(event: Event) {
        this.visibleChange.emit(false);
        event.preventDefault();
    }
}
