<ul class="row list-community">
    <li *ngFor="let community of communities || [] | slice : 0 : 5">
        <span class="badge badge-primary mr-1">{{ community.displayName }} </span>
        <!-- <span *ngIf="community.groups">({{ community.groups.join(', ') }})</span> -->
        <em
            *ngIf="community.isPrimary"
            class="icon icon-star favorite"
            data-toggle="tooltip"
            data-placement="top"
            [title]="'Communauté principale'"
        ></em>
        <ng-container *ngFor="let role of community?.roles || []">
            <em
                class="icon"
                [ngClass]="{
                    'icon-role-manager': role.code === 'CommunityManager',
                    'icon-role-wizard': role.code === 'CommunityAnimator'
                }"
                data-toggle="tooltip"
                data-placement="top"
                [title]="role.libelle"
            >
            </em>
        </ng-container>
    </li>
    <button
        pButton
        pRipple
        *ngIf="communities.length > 5"
        type="button"
        (click)="showCommunitiesDialog()"
        label="Voir plus"
        class="p-py-0 p-mt-1 p-button-sm"
    ></button>
</ul>

<!-- Modal Communautés -->
<p-dialog
    header="{{ 'filters.shared.communitiesFilters' | translate }}"
    [(visible)]="communitiesDialog"
    [style]="{ width: '25rem' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    appendTo="body"
>
    <ul class="list-group list-community">
        <li *ngFor="let community of communities || []">
            <span class="badge badge-primary mr-1">{{ community.displayName }} </span>
            <em
                *ngIf="community.isPrimary"
                class="icon icon-star favorite"
                data-toggle="tooltip"
                data-placement="top"
                [title]="'Communauté principale'"
            ></em>
            <ng-container *ngFor="let role of community?.roles || []">
                <em
                    class="icon"
                    [ngClass]="{
                        'icon-role-manager': role.code === 'CommunityManager',
                        'icon-role-wizard': role.code === 'CommunityAnimator'
                    }"
                    data-toggle="tooltip"
                    data-placement="top"
                    [title]="role.libelle"
                >
                </em>
            </ng-container>
        </li>
    </ul>
    <p-footer>
        <button type="button" class="btn btn-secondary" (click)="toogleCommunitiesModal()" data-dismiss="modal">
            {{ 'components.button.buttonClose' | translate }}
        </button>
    </p-footer>
</p-dialog>
