import { ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-csv-file-picker',
    templateUrl: './csv-file-picker.component.html',
    styleUrls: ['./csv-file-picker.component.scss'],
})
export class CsvFilePickerComponent {
    @Input()
    acceptedFormats: string[] = [];

    @Input()
    control = new FormControl();

    @Output()
    fileChanged: EventEmitter<File> = new EventEmitter();

    @ViewChild('input') input: ElementRef;

    onFileChange(event: any): void {
        this.control.setValue(event.target.files[0]);
        event.preventDefault();
        this.input.nativeElement.value = '';
    }

    onFileDrop(event: any): void {
        const customEvent = {
            isTrusted: true,
            target: { accept: '*/*', files: event },
        };
        this.control.setValue(customEvent.target[0]);
        event.preventDefault();
        this.input.nativeElement.value = '';
    }

    clearFile() {
        this.control.setValue(null);
        this.control.markAsUntouched();
        this.control.markAsPristine();
    }
}
