import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Theme } from '../models/shared/theme.model';

@Injectable({ providedIn: 'root' })
export class CurrentThemeStore {
    private readonly _currentTheme = new BehaviorSubject<Theme>(null);
    private readonly _currentCommunityId = new BehaviorSubject<string>(null);

    public get currentCommunityId$() {
        return this._currentCommunityId.asObservable();
    }

    public get currentCommunityId() {
        return this._currentCommunityId.value;
    }

    public set currentCommunityId(id: string) {
        this._currentCommunityId.next(id);
    }

    public get currentTheme$() {
        return this._currentTheme.asObservable();
    }

    public get currentTheme() {
        return this._currentTheme.value;
    }

    public set currentTheme(theme: Theme) {
        theme.platformLogoUrl = `${theme.platformLogoUrl}?${new Date().getTime()}`;
        this._currentTheme.next(theme);
    }
}
