import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClubRoles } from '../../../../models/clubs/club-roles.model';
import { ToastrHelper } from './../../../../helpers/toastr.helper';
import { Club } from './../../../../models/clubs/club.model';
import { CLUB_VISIBILITIES } from './../../../../models/clubs/constants/club-visibilities';
import { Member } from './../../../../models/clubs/member.model';
import { MemberApiService } from './../../../../services/clubs/member.api.service';
import { ClubsStore } from './../../../clubs.store';

@Component({
    selector: 'app-edit-member-role-modal',
    templateUrl: './edit-member-role-modal.component.html',
    styleUrls: ['./edit-member-role-modal.component.scss'],
})
export class EditMemberRoleModalComponent implements OnInit {
    @Output()
    shouldUpdateMembers = new EventEmitter<{
        memberId: string;
        newRole: ClubRoles;
    }>();

    @Input()
    memberCurrentRole: ClubRoles;
    @Input()
    member: Member;

    @Input()
    club: Club;
    @Input()
    modalVisible: boolean;
    roleToSet: ClubRoles;

    public get headerTitle(): string {
        if (this.roleToSet === ClubRoles.Admin) {
            if (this.memberCurrentRole == ClubRoles.Admin) {
                return this.translate.instant('clubs.page.common.editRole.fireAdmin');
            } else {
                return this.translate.instant('clubs.page.common.editRole.appointAdmin');
            }
        }
        if (this.roleToSet === ClubRoles.Animator) {
            if (this.memberCurrentRole == ClubRoles.Animator) {
                return this.translate.instant('clubs.page.common.editRole.fireAnimator');
            } else {
                return this.translate.instant('clubs.page.common.editRole.appointAnimator');
            }
        }
    }

    public get message(): string {
        if (this.roleToSet === ClubRoles.Admin) {
            if (this.memberCurrentRole == ClubRoles.Admin) {
                return this.translate.instant('clubs.page.common.editRole.fireAdminMsg', {
                    firstName: this.member.firstName,
                    lastName: this.member.lastName,
                    clubName: this.club.title,
                });
            } else {
                return this.translate.instant('clubs.page.common.editRole.appointAdminMsg', {
                    firstName: this.member.firstName,
                    lastName: this.member.lastName,
                    clubName: this.club.title,
                });
            }
        }
        if (this.roleToSet === ClubRoles.Animator) {
            if (this.memberCurrentRole == ClubRoles.Animator) {
                return this.translate.instant('clubs.page.common.editRole.fireAnimatorMsg', {
                    firstName: this.member.firstName,
                    lastName: this.member.lastName,
                    clubName: this.club.title,
                });
            } else {
                return this.translate.instant('clubs.page.common.editRole.appointAnimatorMsg', {
                    firstName: this.member.firstName,
                    lastName: this.member.lastName,
                    clubName: this.club.title,
                });
            }
        }
    }
    constructor(
        private readonly toastrHelper: ToastrHelper,
        private readonly clubsStore: ClubsStore,
        private readonly memberService: MemberApiService,
        private translate: TranslateService,
    ) {
        this.roleToSet = ClubRoles.Member;
    }

    ngOnInit(): void {}

    async handleOnSubmit() {
        if (this.roleToSet === ClubRoles.Admin) {
            this.memberCurrentRole == ClubRoles.Admin ? this.deleteRole() : this.addNewRole(ClubRoles.Admin);
        }
        if (this.roleToSet === ClubRoles.Animator) {
            this.memberCurrentRole == ClubRoles.Animator ? this.deleteRole() : this.addNewRole(ClubRoles.Animator);
        }

        this.closeModal();
    }

    openModal(role: ClubRoles) {
        //Visible room can not be managed
        //Admins can manage in clubs and hidden rooms
        //Animators can manage in hidden rooms only
        //TODO : IL faut vraiment centraliser quelque part les droits !!

        if (
            this.clubsStore.isAdminOfDisplayedClub ||
            (this.clubsStore.isAnimatorOfDisplayedRoom && this.clubsStore.displayedRoom.visibility == CLUB_VISIBILITIES.HIDDEN)
        ) {
            this.modalVisible = true;
            this.roleToSet = role;
        } else {
            const msg = this.translate.instant('clubs.page.common.editRole.toast.unauthorizedRoomCreation');
            this.toastrHelper.showWarning(msg);
        }
    }

    closeModal() {
        this.modalVisible = false;
    }

    deleteRole() {
        this.memberService.deleteClubRole(this.club.id, this.member).subscribe(() => {
            this.shouldUpdateMembers.emit({
                memberId: this.member.id,
                newRole: ClubRoles.Member,
            });
            this.memberCurrentRole = ClubRoles.Member;
        });
    }

    addNewRole(newRole: ClubRoles) {
        this.memberService.addClubRole(this.club.id, this.member, newRole).subscribe(() => {
            this.shouldUpdateMembers.emit({
                memberId: this.member.id,
                newRole: newRole,
            });
            this.memberCurrentRole = newRole;
        });
    }
}
