import { AfterViewInit, Component, ElementRef, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-html-content-truncate',
    template: `
        <div class="publication-message" #contentContainer>
            <div [class.truncated]="isTruncated" [innerHTML]="sanitizedContent"></div>
            <a *ngIf="isContentTruncatable" href="javascript:void(0)" class="show-more-link" (click)="toggleTruncate()">
                <ng-container *ngIf="isTruncated">... plus</ng-container>
            </a>
        </div>
    `,
    styles: [
        `
            .publication-message {
                word-break: break-word;
                white-space: pre-line;
                text-align: left;
                @media (min-width: 992px) {
                    font-size: 1rem;
                }

                letter-spacing: 0px;
                color: var(--club-gray-dark);
                opacity: 1;
                margin-top: 15px;
                margin-left: 15px;
                margin-right: 15px;
            }

            .truncated {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                max-height: 4.5em;
            }

            .show-more-link {
                color: rgba(0, 0, 0, 0.6);
                text-decoration: none;
                font-weight: 600;
                cursor: pointer;
            }

            .show-more-link:hover {
                text-decoration: underline;
            }
        `,
    ],
})
export class HtmlContentTruncateComponent implements AfterViewInit {
    @Input() content: string = '';
    @Input() maxHeight: number = 120; // Default max height in pixels (approximately 5 lines)

    sanitizedContent: any;
    isTruncated: boolean = true;
    isContentTruncatable: boolean = false;

    constructor(
        private sanitizer: DomSanitizer,
        private elementRef: ElementRef,
    ) {}

    ngOnInit() {
        // Sanitize the HTML content
        this.sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML, this.content);
    }

    ngAfterViewInit() {
        // Check if content needs truncation
        setTimeout(() => {
            this.checkTruncation();
        });
    }

    private checkTruncation() {
        const element = this.elementRef.nativeElement.querySelector('.publication-message > div');
        if (element) {
            // Compare scroll height vs client height to determine if content is truncatable
            this.isContentTruncatable = element.scrollHeight > this.maxHeight;

            // If content doesn't need truncation, remove truncated class
            if (!this.isContentTruncatable) {
                this.isTruncated = false;
            }
        }
    }

    toggleTruncate() {
        this.isTruncated = !this.isTruncated;
    }
}
