<div>
    <div class="add-users-filters-header">
        <div class="row align-items-center">
            <div class="col-auto">
                <span class="fw-b primary-1">{{ 'filters.shared.titleFilters' | translate }}</span>
            </div>
            <div class="col-auto ml-auto">
                <button
                    pButton
                    pRipple
                    type="button"
                    label="{{ 'filters.shared.resetFilters' | translate }}"
                    class="p-button-secondary p-button-text"
                    (click)="resetFilters()"
                    icon="pi pi-times"
                    iconPos="right"
                ></button>
            </div>
        </div>
    </div>

    <div class="mt-1 card card-filters">
        <app-select-filters-dropdown
            (ready)="onCommunitiesFiltersReady()"
            (selectionChanged)="onFiltersSelectionChanged()"
            filtersHeader="{{ 'filters.shared.communitiesFilters' | translate }}"
            filterTitle="{{ 'filters.shared.communitiesFilters' | translate }}"
            filtersType="communities"
            #communitiesFilters
        >
        </app-select-filters-dropdown>
    </div>

    <div class="mt-1 card card-filters">
        <app-select-filters-dropdown
            (ready)="onInterestsFiltersReady()"
            (selectionChanged)="onFiltersSelectionChanged()"
            filtersHeader="{{ 'filters.shared.interestsCenterFilters' | translate }}"
            filterTitle="Intérêt"
            filtersType="interests"
            #interestsFilters
        >
        </app-select-filters-dropdown>
    </div>

    <div class="mt-1 card card-filters">
        <app-select-filters-dropdown
            (ready)="onLocationFiltersReady()"
            (selectionChanged)="onFiltersSelectionChanged()"
            filtersHeader="{{ 'filters.shared.locationFilters' | translate }}"
            filterTitle="{{ 'filters.shared.locationFilters' | translate }}"
            filtersType="localisations"
            #locationFilters
        >
        </app-select-filters-dropdown>
    </div>
</div>
