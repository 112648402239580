import { Pipe, PipeTransform } from '@angular/core';
import { MentionMember } from '../models/clubs/mention-member.model';
import { PwaService } from '../services/pwa/pwa.service';

@Pipe({
    name: 'renderMentionMembers',
    pure: true,
})
export class MentionMembersPipe implements PipeTransform {
    constructor(private readonly pwaService: PwaService) {}

    /**
     * Transform all tag member, in content, as link to member card
     */
    transform(content: any, mentionMembers?: any): any {
        if (!mentionMembers || (mentionMembers || []).length === 0) return content;

        const target = this.pwaService.isMobile ? '_self' : '_blank';

        let memberOccurencesMap: Map<string, number> = new Map<string, number>();

        let result: string = content.toString();
        ((mentionMembers as Array<MentionMember>) || []).forEach((member: MentionMember) => {
            //beginIndex is used to know which mention in the result string we have to modificate
            //if at the end of the process beginIndex is equal to zero, it means that we can modify the first mention we find
            //otherwise, it means that it will not be the first (the number will be memberOccurencesMap.get(member.tagMember) + 1)
            var beginIndex = 0;
            if (memberOccurencesMap.has(member.tagMember)) {
                beginIndex = MentionMembersPipe.getNthIndexOfString(
                    result,
                    member.tagMember,
                    memberOccurencesMap.get(member.tagMember) + 1,
                );
            }
            const link = MentionMembersPipe.linkToMemberCard(member, target);

            result = `${result.substring(0, beginIndex)}${result
                .substring(beginIndex)
                .replace(member.tagMember, link)}`;
            memberOccurencesMap = MentionMembersPipe.addMemberOccurence(member.tagMember, memberOccurencesMap);
        });

        return result;
    }

    private static linkToMemberCard = (member: MentionMember, target: string): string =>
        `<a class="publication-message-link nostyle"
        [routerLink]="['/directory/profile',${member.userId}]" pTooltip="Voir le profil" tooltipPosition="top"
        href="/directory/profile/${member.userId}"
        target="${target}">${member.tagMember}</a>`;

    private static addMemberOccurence(
        tagMember: string,
        memberOccurencesMap: Map<string, number>,
    ): Map<string, number> {
        if (memberOccurencesMap.has(tagMember))
            memberOccurencesMap.set(tagMember, memberOccurencesMap.get(tagMember) + 1);
        else memberOccurencesMap.set(tagMember, 1);

        return memberOccurencesMap;
    }

    //Get the nth index of substring in string
    private static getNthIndexOfString(entireString: string, subString: string, nth: number): number {
        return entireString.split(subString, nth).join(subString).length;
    }
}
