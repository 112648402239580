import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OrderModule } from 'ngx-order-pipe';
import { QuillModule } from 'ngx-quill';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { RemoveMemberModalComponent } from '../clubs/club-page/common/remove-member-modal/remove-member-modal.component';
import { FiltersModule } from '../filters/filters.module';
import { OrganizationAvatarComponent } from '../organizations/components/organization-avatar/organization-avatar.component';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { DateFormattedPipe } from '../pipes/date-formatted.pipe';
import { IsoDurationPipe } from '../pipes/iso-duration-pipe.pipe';
import { LinkifyPipe } from '../pipes/linkify.pipe';
import { MentionMembersPipe } from '../pipes/mention-members.pipe';
import { Nl2BrPipe } from '../pipes/nl2br.pipe';
import { ProcessSearchPipe } from '../pipes/process-search.pipe';
import { QuillMentionMembersPipe } from '../pipes/quill-mention-members.pipe';
import { SafePipe } from '../pipes/safe.pipe';
import { TruncateTextPipe } from '../pipes/truncate-text.pipe';
import { WudoTranslatePipe } from '../pipes/wudo-translate.pipe';
import { SideBarBurgerMenuComponent } from '../sidebar-burger-menu/sidebar-burger-menu.component';
import { TopBarPublicComponent } from '../top-bar-public/top-bar-public.component';
import { TopBarComponent } from '../top-bar/top-bar.component';
import { UserAvatarComponent } from '../user/components/user-avatar/user-avatar.component';
import { EditMemberRoleModalComponent } from './../clubs/club-page/common/edit-member-role-modal/edit-member-role-modal.component';
import { GearEditMemberComponent } from './../clubs/components/gear-edit-member/gear-edit-member.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { AppPdfPreviewModalComponent } from './components/app-pdf-viewer-modal/app-pdf-viewer-modal.component';
import { AppYoutubePlayerModalComponent } from './components/app-youtube-player-modal/app-youtube-player-modal.component';
import { AddressAutocompleteComponent } from './components/autocompletion/address-autocomplete/address-autocomplete.component';
import { CityAutocompleteComponent } from './components/autocompletion/city-autocomplete/city-autocomplete.component';
import { BasicDropdownComponent } from './components/basic-dropdown/basic-dropdown.component';
import { ButtonOnboardingComponent } from './components/button-onboarding/button-onboarding.component';
import { GroupedButtonListComponent } from './components/buttons/grouped-button-list/grouped-button-list.component';
import { LargeButtonComponent } from './components/buttons/large-button/large-button.component';
import { ToggleButtonListComponent } from './components/buttons/toggle-button-list/toggle-button-list/toggle-button-list.component';
import { TransparentActionButtonComponent } from './components/buttons/transparent-action-button/transparent-action-button.compoent';
import { CheckIconComponent } from './components/check-icon/check-icon/check-icon.component';
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';
import { PreviewCommunityComponent } from './components/community/preview-community.component';
import { CountryAutocompleteComponent } from './components/country-autocomplete/country-autocomplete.component';
import { CsvFilePickerComponent } from './components/csv-file-picker/csv-file-picker.component';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { OrganizationFormComponent } from './components/forms/organization-form/organization-form.component';
import { FullscreenDialogComponent } from './components/fullscreen-dialog/fullscreen-dialog.component';
import { HtmlContentTruncateComponent } from './components/html-content-truncate/html-content-truncate.component';
import { ImageCropModalComponent } from './components/image-crop-modal/image-crop-modal.component';
import { ImageCropperOldComponent } from './components/image-crop-package/component/image-cropper.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { ComposeMessageInputComponent } from './components/inputs/compose-message-input/compose-message-input.component';
import { InterestsComponent } from './components/interests/interests.component';
import { LinkPreviewComponent } from './components/link-preview/link-preview.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner/loading-spinner.component';
import { MailThemePreviewComponent } from './components/mail-theme-preview/mail-theme-preview.component';
import { NoResultComponent } from './components/no-result/no-result.component';
import { NotificationConfigComponent } from './components/notification-config/notification-config.component';
import { PlatformThemePreviewComponent } from './components/platform-theme-preview/platform-theme-preview.component';
import { PointMarkerComponent } from './components/point-marker/point-marker.component';
import { ReportModalComponent } from './components/report-modal/report-modal.component';
import { ResponsiveTextComponent } from './components/responsive-text/responsive-text.component';
import { ScopeSelectionComponent } from './components/scope-selection/scope-selection.component';
import { SelectUsersComponent } from './components/select-users/select-users.component';
import { SkillsComponent } from './components/skills/skills.component';
import { StepProgressBarComponent } from './components/step-progress-bar/step-progress-bar.component';
import { UserStatusBadgeComponent } from './components/user-status-badge/user-status-badge.component';
import { UserCompletionScoreComponent } from './components/user/user-completion-score/user-completion-score.component';
import { UserInfosFormComponent } from './components/user/user-infos-form/user-infos-form.component';
import { UserInterestsFormComponent } from './components/user/user-interests-form/user-interests-form.component';
import { UserSkillsFormComponent } from './components/user/user-skills-form/user-skills-form.component';
import { DragDropDirective } from './directives/drag-drop/drag-drop.directive';
import { HideForCommunitiesDirective } from './directives/hide-for-communities/hide-for-communities.directive';
import { ShowForCommunitiesDirective } from './directives/show-for-communities/show-for-communities.directive';
import { TokenRefresherDirective } from './directives/token-refresher/token-refresher.directive';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FileTypeExtensionPipe } from './pipes/file-type-extension.pipe';
import { WudoButtonComponent } from './wudo-components/wudo-button/wudo-button.component';
import { WudoCardComponent } from './wudo-components/wudo-card/wudo-card.component';
import { WudoImageCropperComponent } from './wudo-components/wudo-image-cropper/wudo-image-cropper.component';
import { WudoModalComponent } from './wudo-components/wudo-modal/wudo-modal.component';
import { WudoSlidePanelComponent } from './wudo-components/wudo-slide-panel/wudo-slide-panel.component';
import { WudoSpinnerComponent } from './wudo-components/wudo-spinner/wudo-spinner.component';
const moduleItems = [
    AlertMessageComponent,
    ButtonOnboardingComponent,
    ComposeMessageInputComponent,
    DateAgoPipe,
    IsoDurationPipe,
    TruncateTextPipe,
    DragDropDirective,
    TokenRefresherDirective,
    FileSizePipe,
    ProcessSearchPipe,
    FileTypeExtensionPipe,
    ImageCropModalComponent,
    ImageCropperOldComponent,
    InfiniteScrollComponent,
    InterestsComponent,
    NoResultComponent,
    OrganizationAvatarComponent,
    OrganizationFormComponent,
    SelectUsersComponent,
    ShowForCommunitiesDirective,
    HideForCommunitiesDirective,
    SkillsComponent,
    UserAvatarComponent,
    WudoButtonComponent,
    WudoCardComponent,
    WudoImageCropperComponent,
    WudoModalComponent,
    WudoSpinnerComponent,
    WudoSlidePanelComponent,
    GearEditMemberComponent,
    EditMemberRoleModalComponent,
    RemoveMemberModalComponent,
    MentionMembersPipe,
    LinkPreviewComponent,
    DocumentPreviewComponent,
    ResponsiveTextComponent,
    UserStatusBadgeComponent,
    MailThemePreviewComponent,
    PlatformThemePreviewComponent,
    SafePipe,
    LinkifyPipe,
    TopBarComponent,
    SideBarBurgerMenuComponent,
    TopBarPublicComponent,
    FullscreenDialogComponent,
    CsvFilePickerComponent,
    FileSelectorComponent,
    ColorSelectorComponent,
    ScopeSelectionComponent,
    CityAutocompleteComponent,
    AddressAutocompleteComponent,
    CountryAutocompleteComponent,
    WudoTranslatePipe,
    DateFormattedPipe,
    Nl2BrPipe,
    AppYoutubePlayerModalComponent,
    StepProgressBarComponent,
    UserInfosFormComponent,
    UserSkillsFormComponent,
    UserInterestsFormComponent,
    GroupedButtonListComponent,
    ToggleButtonListComponent,
    UserCompletionScoreComponent,
    CheckIconComponent,
    LoadingSpinnerComponent,
    PreviewCommunityComponent,
    AppPdfPreviewModalComponent,
    ReportModalComponent,
    QuillMentionMembersPipe,
    BasicDropdownComponent,
    PointMarkerComponent,
    TransparentActionButtonComponent,
    LargeButtonComponent,
    NotificationConfigComponent,
    HtmlContentTruncateComponent,
];

const primeNgModules = [
    FormsModule,
    AvatarModule,
    ButtonModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    MenuModule,
    ProgressSpinnerModule,
    RippleModule,
    ScrollPanelModule,
    SkeletonModule,
    SplitterModule,
    ToggleButtonModule,
    TooltipModule,
    ChipsModule,
    InputTextareaModule,
    InputMaskModule,
    ToastModule,
    CardModule,
    MultiSelectModule,
    InputSwitchModule,
    DropdownModule,
    SliderModule,
    EditorModule,
    ToolbarModule,
    PanelModule,
    OverlayPanelModule,
    AccordionModule,
    PanelMenuModule,
    BadgeModule,
    AvatarModule,
    AvatarGroupModule,
    InputTextModule,
    VirtualScrollerModule,
    SkeletonModule,
    ConfirmPopupModule,
    DividerModule,
    FieldsetModule,
    SplitButtonModule,
    TableModule,
    OrderListModule,
    AutoCompleteModule,
    SelectButtonModule,
    ProgressBarModule,
    TabViewModule,
    SidebarModule,
    CalendarModule,
    CascadeSelectModule,
    ChartModule,
];

@NgModule({
    declarations: [...moduleItems],
    imports: [
        TourNgBootstrapModule.forRoot(),
        CommonModule,
        RouterModule,
        HttpClientModule,
        FiltersModule,
        FormsModule,
        QuillModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbModule,
        ImageCropperModule,
        ...primeNgModules,
        TranslateModule,
        NgxSpinnerModule,
    ],
    exports: [...moduleItems, NgbModule, ImageCropperModule, OrderModule, ...primeNgModules, TranslateModule],
    providers: [DatePipe, WudoTranslatePipe],
})
export class SharedModule {}
