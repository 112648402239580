<app-nav-admin-community *ngIf="communities" [communities]="communities"></app-nav-admin-community>

<div class="container community-container">
    <ng-template #loading>
        <wudo-spinner></wudo-spinner>
    </ng-template>
    <div *ngIf="communities && communities.length > 0; else loading">
        <router-outlet></router-outlet>
    </div>
</div>
