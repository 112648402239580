import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-terms-of-use-content',
    templateUrl: './terms-of-use-content.component.html',
    styleUrls: ['terms-of-use-content.component.scss'],
})
export class TermsOfUseContentComponent implements OnInit {
    // Importing environment's lexical
    dictionary = environment.dictionary;

    // Importing emails list
    emails = environment.settings.societyDetails.emails;

    // Society's details
    societyDetails = environment.settings.societyDetails;

    locale: string;

    constructor(private readonly translate: TranslateService) {}

    ngOnInit() {
        this.locale = this.translate.currentLang.substring(0, 2);
    }
}
