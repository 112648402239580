import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AddressResult } from '../../../azure/map/models';
import { regexPatterns } from '../../../constants/regex-patterns';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { BaseEventCommand } from '../../../models/events/base-event-command.model';
import { EventAccessTypeEnum, getEventAccessTypeEnumSelectList } from '../../../models/events/event-access-type.enum.model';
import { EventLight } from '../../../models/events/event-light.model';
import { SelectListItem } from '../../../models/shared/select-list-item.model';
import { BaseEventStep } from '../../directives/base-event-step.directive';
import { getDateString } from '../../helpers/date.helper';

@Component({
    selector: 'app-event-form-step-two',
    templateUrl: './event-form-step-two.component.html',
    styleUrls: ['./event-form-step-two.component.scss'],
})
export class EventFormStepTwoComponent extends BaseEventStep implements OnInit {
    EventAccessTypeEnum = EventAccessTypeEnum;
    @ViewChild('endTimeInput', { static: false })
    endTimeInput: ElementRef<HTMLInputElement>;
    public eventAccessTypes: SelectListItem<EventAccessTypeEnum>[] = getEventAccessTypeEnumSelectList(this.translate);
    @Input() eventLight: EventLight;
    @ViewChild('startTimeInput', { static: false })
    public startTimeInput: ElementRef<HTMLInputElement>;

    constructor(toastrHelper: ToastrHelper, translate: TranslateService, private readonly formBuilder: FormBuilder) {
        super(toastrHelper, translate);
    }

    public getMinDate() {
        const date = new Date();
        date.setDate(date.getDate() + 1);

        return getDateString(date);
    }

    public getMinEndDate() {
        if (this.stepForm.controls.startDate.value === '') {
            return this.getMinDate();
        }

        const startDate = new Date(Date.parse(this.stepForm.controls.startDate.value));
        const endDate = new Date(Date.parse(this.stepForm.controls.endDate.value));

        if (endDate < startDate) {
            this.stepForm.controls.endDate.setValue(getDateString(startDate));
        }

        return getDateString(startDate);
    }

    onAddressSelected(address: AddressResult) {
        if (!address?.position?.lat || !address?.position?.lon) {
            return;
        }

        this.stepForm.patchValue({
            address: address.address.freeformAddress,
        });
    }

    public ngOnInit(): void {
        this.createForm();
        this.isStepLoaded = true;
    }

    public override updateEventCommandFromStepComponent(): void {
        const command: BaseEventCommand = this.stepForm.value;

        this.eventCommand.startDate = `${command.startDate}T${this.stepForm.controls.startTime.value}`;
        this.eventCommand.endDate = `${command.endDate}T${this.stepForm.controls.endTime.value}`;

        this.eventCommand.accessType = command.accessType;
        this.eventCommand.eventLink = command.eventLink;
        this.eventCommand.address = command.address;
        this.eventCommand.addressComplement = command.addressComplement;
        this.eventCommand.registrationLink = command.registrationLink;
    }

    protected override createForm(): void {
        this.stepForm = this.formBuilder.group({
            startDate: ['', [Validators.required, this.afterTodayDateValidator()]],
            endDate: ['', [Validators.required, this.afterTodayDateValidator(), this.afterStartDateValidator()]],
            startTime: ['', Validators.required],
            endTime: ['', [Validators.required, this.afterStartTimeValidator()]],
            accessType: [EventAccessTypeEnum.Physical, Validators.required],
            eventLink: [
                null,
                [
                    this.requiredIfValidator(() => this.stepForm?.controls?.accessType?.value === EventAccessTypeEnum.Online),
                    Validators.maxLength(2048),
                ],
            ],
            address: [
                null,
                [
                    this.requiredIfValidator(() => this.stepForm?.controls?.accessType?.value === EventAccessTypeEnum.Physical),
                    Validators.maxLength(200),
                ],
            ],
            addressComplement: [null, Validators.maxLength(200)],
            registrationLink: [null, [Validators.pattern(regexPatterns.fullUrl), Validators.maxLength(2048)]],
        });

        const accessTypeConditionalControl: AbstractControl[] = [
            this.stepForm.controls.eventLink,
            this.stepForm.controls.address,
            this.stepForm.controls.addressComplement,
        ];

        const endTimeSubscription = this.stepForm.controls.startTime.valueChanges.subscribe(() =>
            this.stepForm.controls.endTime.updateValueAndValidity(),
        );
        const accessTypeSubscription = this.stepForm.controls.accessType.valueChanges.subscribe(() => {
            for (const control of accessTypeConditionalControl) {
                control.reset();
            }
        });
        const startDateSubscription = this.stepForm.controls.startDate.valueChanges.subscribe(() =>
            this.stepForm.controls.endTime.updateValueAndValidity(),
        );
        const endDateSubscription = this.stepForm.controls.endDate.valueChanges.subscribe(() =>
            this.stepForm.controls.endTime.updateValueAndValidity(),
        );

        this.subscription.add(endTimeSubscription);
        this.subscription.add(accessTypeSubscription);
        this.subscription.add(startDateSubscription);
        this.subscription.add(endDateSubscription);

        if (this.editMode) {
            this.patchForm();
        }
    }

    private afterStartDateValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value === '' || this.stepForm.controls.startDate.value === '') {
                return null;
            }

            const startDate = new Date(Date.parse(this.stepForm.controls.startDate.value));
            const endDate = new Date(Date.parse(control.value));

            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            if (endDate < startDate) {
                return { beforeStartDate: true };
            }

            return null;
        };
    }

    private afterStartTimeValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value === '' || this.stepForm.controls.startTime.value === '') {
                return null;
            }

            if (this.stepForm.controls.startDate.value !== this.stepForm.controls.endDate.value) {
                // Only check if the event starts and ends on the same day
                return null;
            }

            const startTimeAsNumber: number = this.startTimeInput?.nativeElement?.valueAsNumber ?? null;
            const endTimeAsNumber: number = this.endTimeInput?.nativeElement?.valueAsNumber ?? null;

            if (startTimeAsNumber === null || endTimeAsNumber === null) {
                return null;
            }

            if (endTimeAsNumber <= startTimeAsNumber) {
                return { beforeStartTime: true };
            }

            return null;
        };
    }

    private afterTodayDateValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value === '') {
                return null;
            }

            const controlDate = new Date(Date.parse(control.value));
            const today = new Date();
            today.setDate(today.getDate() + 1);

            controlDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            if (!this.eventLight && controlDate < today) {
                return { beforeNow: true };
            }

            return null;
        };
    }

    private patchForm(): void {
        this.stepForm.patchValue({
            startDate: this.eventCommand.startDate.split(' ')[0],
            endDate: this.eventCommand.endDate.split(' ')[0],
            startTime: this.eventCommand.startDate.split(' ')[1],
            endTime: this.eventCommand.endDate.split(' ')[1],
            accessType: this.eventCommand.accessType,
            eventLink: this.eventCommand.eventLink,
            address: this.eventCommand.address,
            addressComplement: this.eventCommand.addressComplement,
            registrationLink: this.eventCommand.registrationLink,
        });
    }
}
