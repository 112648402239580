import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrHelper } from '../../../../helpers/toastr.helper';
import { User } from '../../../../models/identity/user.model';
import { Skill } from '../../../../models/skills/skill.model';
import { AccountsService } from '../../../../services/identity/accounts.service';
import { UserSkillsService } from '../../../../services/identity/user/user-skills.service';
import { SkillsService } from '../../../../services/skills/skills.service';

@Component({
    selector: 'app-user-skills-form',
    templateUrl: './user-skills-form.component.html',
    styleUrls: ['./user-skills-form.component.scss'],
})
export class UserSkillsFormComponent implements OnInit {
    public availableSkills: Skill[];
    public filteredAvailableSkills: Skill[] = [];
    public filteredSkills: Skill[];
    public isSelectSkillsModalVisible: boolean;
    public selectedSkill: Skill | null;
    public topTenSkills: Skill[];

    @Input() public user: User;

    constructor(
        private readonly accountService: AccountsService,
        private readonly userSkillsService: UserSkillsService,
        private readonly toastrHelper: ToastrHelper,
        private readonly translate: TranslateService,
        private readonly skillService: SkillsService,
    ) {}

    public addSkill() {
        if (this.selectedSkill) {
            this.filteredSkills = this.filteredSkills.filter((skill) => skill.name !== this.selectedSkill?.name);
            this.user.skills.push(this.selectedSkill);
            this.updateFilteredAvailableSkills();
            this.selectedSkill = null;
        }
    }

    public filterSkills(event: any) {
        const query = event.query.toLowerCase();
        this.filteredSkills = this.availableSkills.filter(
            (skill) => skill.name.toLowerCase().includes(query) && !this.user.skills.some((s) => s.id === skill.id),
        );
    }

    public ngOnInit(): void {
        this.skillService.getAllSkills().subscribe((skills) => {
            this.availableSkills = skills;
            this.filteredSkills = skills;
            this.updateFilteredAvailableSkills();
        });

        this.skillService.getTopSkillsByCommunity(this.user.primaryCommunity.id, 10).subscribe((skills) => (this.topTenSkills = skills));
    }

    public removeSkill(index: number) {
        this.user.skills.splice(index, 1);
        this.updateFilteredAvailableSkills();
    }

    public async save(): Promise<boolean> {
        if (this.user.skills.length > 0) {
            try {
                await this.userSkillsService.addUserSkills(this.user).toPromise();
                this.accountService.setUserSkills(this.user);
                this.accountService.saveCurrentUser();
                this.accountService.refreshCurrentUser();
                return true;
            } catch (error) {
                this.toastrHelper.showError(this.translate.instant('common.unknownError'));
                return false;
            }
        }
        return true;
    }

    public showSelectSkillsModal() {
        this.isSelectSkillsModalVisible = true;
    }

    public toggleValue(skill: Skill) {
        const index = this.user.skills.findIndex((s) => s.id === skill.id);
        if (index !== -1) {
            this.user.skills.splice(index, 1);
        } else {
            this.user.skills.push(skill);
        }
        this.updateFilteredAvailableSkills();
    }

    private updateFilteredAvailableSkills(): void {
        const selectedDomains = this.user.skills.map((skill) => skill.domainName);
        this.filteredAvailableSkills = this.availableSkills.filter((skill) => selectedDomains.includes(skill.domainName));
    }

    public toggleSkill(skill: Skill) {
        const index = this.user.skills.findIndex((i) => i.id === skill.id);
        if (index !== -1) {
            this.removeSkill(index);
        } else {
            this.selectedSkill = skill;
            this.addSkill();
        }
    }
}
