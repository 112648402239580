import { UpdateProfileCommand } from './update-profile-command.model';
import { UserStatusTypes } from './user-status-types.model';
import { User } from './user.model';

export class UpdateCompleteProfileCommand extends UpdateProfileCommand {
    profilePicture: string;
    pictureHasChanged: boolean;
    isOnBoardingProfileCompleted: boolean;
    status: UserStatusTypes;

    constructor(user: User) {
        super(user);
        this.profilePicture = user.profilePicture;
        this.isOnBoardingProfileCompleted = user.isOnBoardingProfileCompleted;
        this.status = user.status;
    }
}
