<div class="login-container full-height">
    <img id="login-logo" class="space-bottom" alt="WuDo Platform" />
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!maintenance">
        <div class="form-group">
            <label class="login-text" for="username">Email</label>
            <input
                type="email"
                formControlName="email"
                class="form-control form-login"
                [ngClass]="{ 'is-invalid': f.email.errors && f.email.touched }"
                aria-describedby="email"
                placeholder="Email"
                value="{{ mail }}"
            />
            <div *ngIf="f.email.errors && f.email.touched" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email requis</div>
                <div *ngIf="f.email.errors.email">L'adresse email doit être valide</div>
            </div>
        </div>
        <div class="form-group">
            <div class="d-flex justify-content-between">
                <div>
                    <label class="login-text" for="password">Mot de passe</label>
                </div>
                <div class="space-top">
                    <a
                        class="-text"
                        href="#"
                        data-toggle="modal"
                        data-target="#resetPasswordModal"
                        (click)="checkResetPasswordEligibility()"
                        >Mot de passe oublié ?</a
                    >
                </div>
            </div>
            <input
                type="password"
                formControlName="password"
                class="form-control form-login"
                [ngClass]="{ 'is-invalid': f.password.errors && f.password.touched }"
                aria-describedby="userPassword"
                placeholder="Mot de passe"
            />
            <div *ngIf="f.password.errors && f.password.touched" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Mot de passe requis</div>
            </div>
        </div>
        <button
            [disabled]="loginForm.invalid || submitted"
            type="submit"
            class="btn btn-primary space-top"
            [ngClass]="{ 'in-progress-button': submitted }"
        >
            <span *ngIf="!submitted">{{ 'login.loginInButtonText' | translate }}</span>
            <span *ngIf="submitted">{{ 'login.loginInButtonLoadingText' | translate }}</span>
        </button>
    </form>

    <div *ngIf="shouldDisplayOAuthLoginOptions()" class="separator-oauth-connexion">
        <div class="separator"><span class="login-text p-p-1">ou</span></div>
    </div>

    <!-- TODO => factoriser les logos & wording -->
    <div class="p-mb-6">
        <a *ngIf="oAuthProviders['cetim']" href="{{ cetimOAuthLoginUrl }}">
            <button type="submit" class="btn btn-oauth space-top btn-oauth-text">
                <img src="assets/images/cetim-logo.png" alt="cetim-logo" class="cetim-logo-size" />
                <span *ngIf="true" class="flex-grow-1 btn-oauth-text">{{
                    'login.component.ssoCetim' | translate
                }}</span>
            </button>
        </a>
        <a *ngIf="oAuthProviders['psi']" href="{{ psiOAuthLoginUrl }}">
            <button type="submit" class="btn btn-oauth space-top btn-oauth-text">
                <img src="assets/images/psi-logo.svg" alt="psi-logo" class="psi-logo-size" />
                <span *ngIf="true" class="flex-grow-1 btn-oauth-text">{{ 'login.component.ssoPSI' | translate }}</span>
            </button>
        </a>
        <a *ngIf="oAuthProviders['gifas']" href="{{ gifasOAuthLoginUrl }}">
            <button type="submit" class="btn btn-oauth space-top btn-oauth-text">
                <img src="assets/images/gifas-logo.png" alt="gifas-logo" class="gifas-logo-size" />
                <span *ngIf="true" class="flex-grow-1">{{ 'login.component.ssoGifas' | translate }}</span>
            </button>
        </a>
    </div>

    <ng-template #termsOfUseModal let-modal>
        <app-terms-of-use-modal [returnUrl]="returnUrl"></app-terms-of-use-modal>
    </ng-template>
</div>

<div class="card box-shadow maintenance" *ngIf="maintenance">
    <div class="card-body">
        <div class="card-title">
            <h1 class="primary-1">Maintenance en cours 🚨</h1>
        </div>
        <br />
        <div class="card-text">La plateforme {{ title }} est momentanément indisponible.</div>
        <br />
        <div class="card-text">
            Notre maintenance d’été est en cours d’éxecution. Nous fermons l’accès à la plateforme du mercredi 11 au
            vendredi 13 août inclus. Nous vous remercions pour votre compréhension.
        </div>
        <br />
        <div class="card-text">Bien cordialement, 👋</div>
        <div class="card-text">L'équipe {{ title }}</div>
    </div>
    <!-- <div class="title">
        <h1 class="primary-1">Maintenance en cours 🚨</h1>
    </div>
    <div class="text">
        La plateforme {{title}} est momentanément indisponible.
    </div>
    <div class="text">
        Nous réalisons des tests techniques avant une mise à jour
        majeure prévue pour la semaine prochaine. Nous vous remercions pour votre compréhension, vous pourrez retenter
        de vous connecter dans quelques heures 😉
    </div>
    <br>
    <div class="text">Bien cordialement, 👋</div>
    <div class="text">L'équipe {{title}}</div> -->
</div>

<app-reset-password-modal *ngIf="isEligibleForPasswordResetting()" [email]="mail" HEAD></app-reset-password-modal>
