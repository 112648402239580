import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProfessionalAttribute } from '../../../../models/skills/professional-attribute.model';

@Component({
    selector: 'app-grouped-button-list',
    template: `
        <div *ngFor="let domain of groupedItems | keyvalue">
            <p>{{ translatorKeyDomain === '' ? domain.key : (domain.key | wtranslate: translatorKeyDomain) }}</p>

            <app-toggle-button-list
                [items]="domain.value"
                [selectedItems]="selectedItems"
                [translatorKeyValue]="translatorKeyValue"
                (itemToggled)="toggleValue($event)"
            ></app-toggle-button-list>
        </div>
    `,
})
export class GroupedButtonListComponent {
    @Input()
    public items: ProfessionalAttribute[];

    @Input()
    public selectedItems: ProfessionalAttribute[];

    @Input()
    public translatorKeyValue: string = '';

    @Input()
    public translatorKeyDomain: string = '';

    @Output()
    public itemToggled = new EventEmitter<ProfessionalAttribute>();

    constructor() {}

    public get groupedItems(): { [key: string]: ProfessionalAttribute[] } {
        return this.items.reduce(
            (group, professionalAttribute) => {
                if (!group[professionalAttribute.domainName]) {
                    group[professionalAttribute.domainName] = [];
                }
                group[professionalAttribute.domainName].push(professionalAttribute);
                return group;
            },
            {} as { [key: string]: ProfessionalAttribute[] },
        );
    }

    public toggleValue(item: ProfessionalAttribute) {
        this.itemToggled.emit(item);
    }
}
