import { Member } from './../../../../models/clubs/member.model';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrHelper } from './../../../../helpers/toastr.helper';
import { Club } from './../../../../models/clubs/club.model';
import { MemberApiService } from './../../../../services/clubs/member.api.service';
import { ClubsStore } from './../../../clubs.store';

@Component({
    selector: 'app-remove-member-modal',
    templateUrl: './remove-member-modal.component.html',
    styleUrls: ['./remove-member-modal.component.scss'],
})
export class RemoveMemberModalComponent implements OnInit {
    @Input()
    memberToRemove: Member;

    @Input()
    club: Club;

    @Output()
    shouldRemoveMember = new EventEmitter<string>();

    isModalRemoveMemberVisible = false;

    constructor(
        private readonly clubsStore: ClubsStore,
        private readonly memberService: MemberApiService,
        private readonly toastrHelper: ToastrHelper,
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {}

    openModal() {
        if (this.clubsStore.isAnimatorOfDisplayedRoom) {
            if (this.memberToRemove.id != null) {
                this.isModalRemoveMemberVisible = true;
            }
        } else {
            const msg = this.translate.instant('clubs.page.common.removeMember.toast.unauthorizedRoomSettingsAccess');
            this.toastrHelper.showWarning(msg);
        }
    }

    closeModal() {
        this.isModalRemoveMemberVisible = false;
    }

    confirmRoomDeletion() {
        this.memberService
            .removeMemberInClub(this.club.id, this.memberToRemove.id)
            .toPromise()
            .then(() => {
                this.isModalRemoveMemberVisible = false;
                this.shouldRemoveMember.emit(this.memberToRemove.id);
            });
    }
}
