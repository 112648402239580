import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountsService } from '../../../services/identity/accounts.service';
import { ProfileService } from '../../../services/identity/profile.service';

@Component({
    selector: 'app-terms-of-use-modal',
    templateUrl: './terms-of-use-modal.component.html',
    styleUrls: ['terms-of-use-modal.component.scss'],
})
export class TermsOfUseModalComponent {
    @Input() returnUrl: string;

    constructor(
        private readonly modalService: NgbModal,
        private readonly accountsService: AccountsService,
        private readonly router: Router,
        private readonly profileService: ProfileService,
    ) {}

    accept(): void {
        this.accountsService.currentUser.termsOfUse = true;

        this.profileService.updateProfile(this.accountsService.currentUser).subscribe(() => {
            this.accountsService.saveCurrentUser();
            this.accountsService.refreshCurrentUser();

            this.modalService.dismissAll();
            this.router.navigate(['/']);
        });
    }

    decline(): void {
        this.modalService.dismissAll();
        this.accountsService.logout();
    }
}
