import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ToastrHelper } from '../../../../helpers/toastr.helper';
import { CommunityFilter } from '../../../../models/identity/community-filter.model';
import { CommunityWithRoles } from '../../../../models/identity/community-with-roles.model';
import { GeographicalArea } from '../../../../models/identity/geographical-area.model';
import { FiltersItem } from '../../../../models/shared/filters-item.model';
import { Interest } from '../../../../models/skills/interest.model';
import { CommunityService } from '../../../../services/identity/community.service';
import { GeographicalAreaService } from '../../../../services/identity/geographical-area.service';
import { InterestsService } from '../../../../services/skills/interests.service';
import { WudoTranslatePipe } from '../../../../pipes/wudo-translate.pipe';

@Component({
    selector: 'app-select-filters-dropdown',
    templateUrl: './select-filters-dropdown.component.html',
    styleUrls: ['./select-filters-dropdown.component.scss'],
})
export class SelectFiltersDropdownComponent implements OnInit {
    @Output()
    ready = new EventEmitter();

    @Output()
    selectionChanged = new EventEmitter();

    @Input()
    filtersHeader = '';

    @Input()
    filterTitle = '';

    @Input()
    filtersType = '';

    dataIsLoaded: Promise<boolean>;

    public items: CommunityWithRoles[] | Interest[] | GeographicalArea[];
    public selectedItems: CommunityWithRoles[] | Interest[] | GeographicalArea[];

    itemsFilterItems: FiltersItem[] = [];

    constructor(
        private readonly communityService: CommunityService,
        private readonly interestService: InterestsService,
        private readonly geographicalAreaService: GeographicalAreaService,
        private readonly toastrHelper: ToastrHelper,
        private readonly router: Router,
        private readonly wtranslate: WudoTranslatePipe,
    ) {}

    ngOnInit(): void {
        this.selectedItems = [];

        switch (this.filtersType) {
            case 'communities':
                this.loadCommunities();
                break;
            case 'interests':
                this.loadInterests();
                break;
            case 'localisations':
                this.loadGeographicalAreas();
                break;
        }
    }

    // Loading Filters according to the type of Filter got on Input
    loadCommunities() {
        forkJoin([this.communityService.getCommunities(), this.communityService.getMyCommunities()]).subscribe(
            ([allCommunities, userCommunities]) => {
                this.items = userCommunities;

                let primaryCommunityIndex = this.items.findIndex(
                    (community: CommunityWithRoles) => community.isPrimary,
                );

                if (this.router.url.includes('/clubs/')) {
                    // Removing communities which can't access clubs from items
                    environment.features.clubDisabledCommunityIds.forEach((id) => {
                        let communityIndexToRemove = this.items.findIndex((community) => community.id == id);

                        if (communityIndexToRemove > -1) {
                            this.items.splice(communityIndexToRemove, 1);
                        }
                    });
                }

                this.itemsFilterItems = (this.items as CommunityWithRoles[]).map((community) => {
                    return new FiltersItem(
                        community.id,
                        community.displayName,
                        community.groups
                            ? allCommunities
                                  .find((c) => c.id === community.id)
                                  .groups.map((groupName) => new FiltersItem(this.getGuid(), groupName))
                            : null,
                    );
                });

                const primaryCommunity = this.items.splice(primaryCommunityIndex, 1);

                this.items.splice(0, 0, primaryCommunity[0]);
                this.itemsFilterItems.forEach((item) => item.check());
                this.selectedItems = [...this.items];

                this.dataIsLoaded = Promise.resolve(true);
                this.ready.emit();
            },
            (error: any) => {
                this.toastrHelper.showGenericError(error);
            },
        );
    }

    loadInterests() {
        forkJoin([this.interestService.getAllInterests()]).subscribe(
            ([interests]) => {
                this.items = interests.sort((a, b) =>
                    a.domainName > b.domainName ? 1 : b.domainName > a.domainName ? -1 : 0,
                );
                this.itemsFilterItems = (this.items as Interest[]).map(
                    (interest) =>
                        new FiltersItem(
                            interest.id,
                            `${this.wtranslate.transform(
                                this.wtranslate.transform(interest.name, 'interests'),
                                'interests',
                            )} (${interest.id})`,
                        ),
                );

                this.dataIsLoaded = Promise.resolve(true);
                this.ready.emit();
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
        );
    }

    loadGeographicalAreas() {
        forkJoin([this.geographicalAreaService.getGeographicalAreas()]).subscribe(
            ([data]) => {
                this.items = data;
                this.itemsFilterItems = (this.items as GeographicalArea[]).map(
                    (area) => new FiltersItem(area.id, `${area.name} (${area.id})`),
                );

                this.dataIsLoaded = Promise.resolve(true);
                this.ready.emit();
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
        );
    }

    // Filters Methods
    setSelectedFilters(itemsIds: string[]) {
        this.itemsFilterItems.forEach((item) => (item.checked = itemsIds.includes(item.key)));
    }

    // Filtering method for communities
    setSelectedFiltersCommunities(communityFilters: CommunityFilter[]) {
        communityFilters.forEach((communityFilter) => {
            const associatedFilterItem = this.itemsFilterItems.find((filter) => filter.key === communityFilter.id);
            associatedFilterItem.checked = true;

            // remark : filters on all community groups (not on specific community groups of the current usser)
            // communityFilter.groups <=> user community groups
            // associatedFilterItem.groups <=> community groups

            if (associatedFilterItem.groups) {
                communityFilter.groupsIds = associatedFilterItem.groups.map((group) => group.key);
            }
        });
    }

    resetFilters(): void {
        if (this.filtersType !== 'communities') {
            this.itemsFilterItems.forEach((item) => (item.checked = false));
            this.selectedItems.splice(0, this.selectedItems.length);
        } else {
            this.itemsFilterItems.forEach((item) => item.check());
            this.selectedItems = [...this.items];
        }
    }

    onSelectionChanged() {
        this.selectionChanged.emit();
    }

    unselectItem(item: any) {
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        this.onSelectionChanged();
    }

    // Private Methods
    private getGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}
