import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '../filters/filters.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserPersonalInformationsComponent } from './components/user-personal-informations/user-personal-informations.component';
import { UserCommunitiesComponent } from './components/user-communities/user-communities.component';
import { SharedModule } from '../shared/shared.module';

const moduleItems = [UserPersonalInformationsComponent, UserCommunitiesComponent];

@NgModule({
    declarations: moduleItems,
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        FiltersModule,
    ],
    exports: moduleItems,
})
export class UserModule {}
