import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerHelper } from '../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { CreateEventCommand } from '../../../models/events/create-event-command.model';
import { EventsService } from '../../../services/events/events.service';
import { AccountsService } from '../../../services/identity/accounts.service';
import { BaseEventFormPage } from '../../directives/base-event-form-page.directive';

@Component({
    selector: 'app-create-event',
    templateUrl: './create-event.component.html',
})
export class CreateEventComponent extends BaseEventFormPage<CreateEventCommand> implements OnInit {
    constructor(
        public readonly accountService: AccountsService,
        private readonly eventService: EventsService,
        private location: Location,
        modalService: NgbModal,
        private readonly router: Router,
        private readonly spinnerHelper: SpinnerHelper,
        private readonly toastrHelper: ToastrHelper,
        private translate: TranslateService,
    ) {
        super(modalService);
    }

    public ngOnInit(): void {
        this.eventCommand = new CreateEventCommand();
    }

    public override async onPublishConfirmed(): Promise<void> {
        this.closePublishModal();
        this.spinnerHelper.showSpinner();

        try {
            await this.eventService.createAndPublishEvent(this.eventCommand).toPromise();

            this.spinnerHelper.hideSpinner();

            const msg = this.translate.instant('events.pages.createEvent.toast.eventPublished');
            this.toastrHelper.showSuccess(msg);

            setTimeout(() => {
                this.router.navigate(['events', 'mine']);
            }, 2000);
        } catch (error: any) {
            this.spinnerHelper.hideSpinner();
            this.toastrHelper.showGenericError(error);
        }
    }

    public goBack() {
        if (window.history.length > 1) {
            this.location.back();
        } else {
            this.router.navigate(['/events']);
        }
    }
}
