import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClubRoom } from '../../../../models/clubs/club-room.model';
import { CLUB_VISIBILITIES } from '../../../../models/clubs/constants/club-visibilities';
import { ClubsService } from '../../../../services/clubs/clubs.service';
import { ClubsStore } from '../../../clubs.store';
import { ClubMoreInfoModalComponent } from '../../../components/app-club-more-info-modal/app-club-more-info-modal.component';
import { ClubRoomCategory } from './../../../../models/clubs/club-room-category.model';
import { Club } from './../../../../models/clubs/club.model';

@Component({
    selector: 'room-navigation',
    templateUrl: './room-navigation.component.html',
    styleUrls: ['./room-navigation.component.scss'],
})
export class RoomNavigationComponent implements OnInit {
    @ViewChild(ClubMoreInfoModalComponent)
    public clubMoreInfoModal: ClubMoreInfoModalComponent;
    @Input()
    public displayClubTitle: string;
    public displayReloadButton: boolean;
    @Input()
    public displayedClub: Club;
    @Input()
    public rooms: ClubRoom[];
    public selectedRoomId: string;

    constructor(
        public readonly clubsStore: ClubsStore,
        private readonly router: Router,
        public readonly translate: TranslateService,
        private clubService: ClubsService,
    ) {}

    public get categories() {
        return this.rooms
            .filter((room) => !!room.category)
            .map((room) => room.category)
            .filter((category, index, categories) => categories.indexOf(category) === index);
    }

    public get mainRooms() {
        if (this.rooms) {
            return this.rooms.filter((room) => !room.category);
        }
    }

    public getClubNumberOfMember() {
        return this.displayedClub.members.length;
    }

    public getClubType() {
        if (this.displayedClub.communityId) {
            return this.translate.instant('clubs.page.common.clubTypes.communityClub');
        }

        if (this.displayedClub.projectId) {
            return this.translate.instant('clubs.page.common.clubTypes.projectClub');
        }

        return this.translate.instant('clubs.page.common.clubTypes.simpleClub');
    }

    public getNumberOfHiddenRooms() {
        return this.displayedClub.rooms.filter((room) => room.visibility === CLUB_VISIBILITIES.HIDDEN).length;
    }

    public getNumberOfOpenRooms() {
        return this.displayedClub.rooms.filter((room) => room.visibility === CLUB_VISIBILITIES.VISIBLE).length;
    }

    public getRoomsOfCategory(category: ClubRoomCategory) {
        return this.rooms.filter((room) => !!room.category && room.category.id === category.id);
    }

    public ngOnInit(): void {
        this.clubsStore.isOnePostPageDisplayed.subscribe((value) => {
            this.displayReloadButton = value;
        });
    }

    public onClubDeleted(clubToDelete: Club) {
        if (clubToDelete.parentClubId == null) this.clubsStore.onClubDeleted(clubToDelete);
        else this.clubsStore.addOrUpdateRoomInClub(clubToDelete, true);
    }

    public reloadRoom() {
        this.clubsStore.isOnePostPageDisplayed.emit(false);
        this.router.navigate(['clubs', this.clubsStore.displayedRoomId]);
    }

    public roomCreation(newRoom: Club) {
        this.clubsStore.addOrUpdateRoomInClub(newRoom);
    }

    public toggleMoreInfoModal() {
        this.clubMoreInfoModal.toggleModal();
    }
}
