import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ProjectType } from '../../../models/projects/project-type.model';

@Component({
    selector: 'app-project-type-list-tab',
    templateUrl: './project-type-list-tab.component.html',
    styleUrls: ['./project-type-list-tab.component.scss'],
})
export class ProjectTypeListTabComponent {
    @Input()
    projectTypes: ProjectType[];

    @Input()
    parentForm: FormGroup;

    dictionary = environment.dictionary;

    projectTypeIconMapping = {
        // Wudo
        '5e53c768fe05b5081c7217e7': 'retex.svg',
        '5e53c78e1f06512968e9e90d': 'methodologie.svg',
        '5e53c7abb928ab300c106b8b': 'solution.svg',
        '5e53c7c4b928ab300c106b8c': 'research.svg',
        '5e53c7d7b928ab300c106b8d': 'financement.svg',
        '5e53c7eab928ab300c106b8e': 'prototype.svg',
        '5e53c7fbb928ab300c106b8f': 'test.svg',
        '5e53c818b928ab300c106b90': 'new.svg',
        '5e53c839b928ab300c106b91': 'etudiant-v-entreprises.svg',
        '5e53c84bb928ab300c106b92': 'entreprise-v-etudiants.svg',
        '5e53c862b928ab300c106b93': 'other.svg',
        // Nuclear Valley
        '5f5b79f77bade60ca0029f91': 'other.svg',
        '5f5b7a857bade60ca0029f92': 'research.svg',
        '5f5b7ad87bade60ca0029f93': 'test.svg',
        '5f5b7b267bade60ca0029f94': 'prototype.svg',
        '5f5b7b617bade60ca0029f95': 'methodologie.svg',
        '5f5b7b937bade60ca0029f96': 'offres-marches.svg',
        '5f5b7be87bade60ca0029f97': 'international.svg',
        '5f5b7c86bf1bb82060c6b47e': 'etudiant-v-entreprises.svg',
        '5f5b7cb5bf1bb82060c6b47f': 'formation.svg',
        '5f5b7cebbf1bb82060c6b480': 'emploi.svg',
        '5f5b7d23bf1bb82060c6b481': 'retex.svg',
        '5f5b7d40bf1bb82060c6b482': 'autres.svg',
        // France Relance
        '5fa0032909ea2e2998280cd8': 'supply-chain.svg',
        '5fa003cccc6d092f0c3a47b5': 'offres-marches.svg',
        '5fa00422cc6d092f0c3a47b6': 'international-business.svg',
        '5fa00467cc6d092f0c3a47b7': 'porteurs-de-projets-industriels.svg',
        // IFGP
        '5fa00467cc6d092f0c3a47c1': 'ifgp-cadre_de_vie.svg',
        '5fa00467cc6d092f0c3a47c2': 'ifgp-cooperation_institutionnelle.svg',
        '5fa00467cc6d092f0c3a47c3': 'ifgp-democratie_locale.svg',
        '5fa00467cc6d092f0c3a47c4': 'ifgp-developpement_eco.svg',
        '5fa00467cc6d092f0c3a47c5': 'ifgp-developpement.svg',
        '5fa00467cc6d092f0c3a47c6': 'ifgp-education.svg',
        '5fa00467cc6d092f0c3a47c7': 'ifgp-emploi.svg',
        '5fa00467cc6d092f0c3a47c8': 'ifgp-environnement.svg',
        '5fa00467cc6d092f0c3a47c9': 'ifgp-logement.svg',
        '5fa00467cc6d092f0c3a47d0': 'ifgp-organisation_services.svg',
        '5fa00467cc6d092f0c3a47d1': 'ifgp-sante.svg',
        '5fa00467cc6d092f0c3a47d2': 'ifgp-securite.svg',
        '5fa00467cc6d092f0c3a47d3': 'ifgp-services_personne.svg',
        '5fa00467cc6d092f0c3a47d4': 'ifgp-solidarite.svg',
        '5fa00467cc6d092f0c3a47d5': 'ifgp-tourisme.svg',
        '5fa00467cc6d092f0c3a47d6': 'ifgp-transformation.svg',
        // UIMM
        "5fa00467cc6d092f0c3a47f3" : 'equipement.png',
        "5fa00467cc6d092f0c3a47f5" : 'partenariat.png',
        "5fa00467cc6d092f0c3a47f2" : 'savoir-faire.png',
        "5fa00467cc6d092f0c3a47f7" : 'partager.png',
        "5fa00467cc6d092f0c3a47f8" : 'evenement.png'       
    };
}
