<input
    #input
    type="file"
    hidden
    [attr.accept]="acceptedFormats ? acceptedFormats.join(',') : null"
    (change)="onFileChange($event)"
/>

<div appDragDrop (onFileDropped)="onFileDrop($event)" class="file-container">
    <button class="button" (click)="input.click()">{{ buttonLabel }}</button>
    <div class="information">
        <div *ngFor="let item of information" class="information-item">
            {{ item }}
        </div>
    </div>
</div>
