<div class="top-bar position-fixed" [ngClass]="{ 'position-fixed': !isPreviewMode }" *ngIf="currentUser.status === UserStatusTypes.Active">
    <div class="row align-items-center h-100 pr-2">
        <div class="col-2">
            <div>
                <a href="#" class="platform-logo-container d-none d-md-block">
                    <ng-container *ngIf="currentThemeStore.currentTheme$ | async">
                        <img [src]="logoSrc" alt="WuDo Platform" height="30" />
                    </ng-container>
                </a>
                <div class="d-block d-md-none d-none align-items-center">
                    <button *ngIf="showBackButton$ | async" (click)="goBack()" class="btn btn-link p-0 back-arrow">
                        <i class="pi pi-arrow-left"></i>
                    </button>
                    <app-sidebar-burger-menu *ngIf="!(showBackButton$ | async)" tourAnchor="burger-menu-mobile"></app-sidebar-burger-menu>
                </div>
            </div>
        </div>
        <div class="col text-center primary-title d-block d-sm-none">
            <span class="fw-bold">{{ routeTitle$ | async | translate }}</span>
        </div>
        <div class="col col-auto ml-auto">
            <div class="row align-items-center sm-mr-3">
                <div class="d-none d-md-block" tourAnchor="welcome">
                    <div class="col col-auto d-flex" title="{{ 'navMenu.welcomeTitle' | translate }}">
                        <button type="button" class="btn btn-link p-0" routerLink="/">
                            <span class="d-none d-md-inline d-lg-inline d-xl-inline nav-button" [class.active-topbar]="router.url === '/'">{{
                                'navMenu.welcomeTitle' | translate
                            }}</span>
                        </button>
                        <span *ngIf="router.url === '/' || isPreviewMode" class="active-button-badge"></span>
                    </div>
                </div>
                <div *ngIf="(permissions$ | async)?.canViewCetimClub" class="d-none d-md-block">
                    <div class="col col-auto d-flex" title="Espace CETIM">
                        <button type="button" class="btn btn-link p-0">
                            <a class="nav-button" href="https://platform.wudo.io/clubs/60b49b81112100a1ba8a7556" target="_blank">
                                {{ 'home.topBar.cetimSpaceTopBar' | translate }}
                            </a>
                        </button>
                    </div>
                </div>
                <div class="d-none d-md-block" *ngIf="(permissions$ | async)?.canViewContactsDirectory" tourAnchor="directory">
                    <div class="col col-auto d-flex" title="{{ 'home.topBar.directoryTitle' | translate }}">
                        <button type="button" class="btn btn-link p-0" (click)="getDefaultYearbook()">
                            <span
                                class="d-none d-md-inline d-lg-inline d-xl-inline nav-button"
                                [class.active-topbar]="router.url.includes('/directory')"
                                >{{ 'home.topBar.directoryTitle' | translate }}</span
                            >
                        </button>
                        <span *ngIf="router.url.includes('/directory')" class="active-button-badge"></span>
                    </div>
                </div>
                <div class="d-none d-md-block" tourAnchor="projects">
                    <div class="col col-auto d-flex" [title]="'home.topBar.projects' | translate">
                        <button routerLink="/projects" type="button" class="btn btn-link p-0">
                            <span class="d-none d-md-inline d-lg-inline d-xl-inline nav-button" [class.active-topbar]="router.url === '/projects'">{{
                                'home.topBar.projects' | translate
                            }}</span>
                        </button>
                        <span *ngIf="router.url === '/projects'" class="active-button-badge"></span>
                    </div>
                </div>
                <div class="d-none d-md-block" tourAnchor="events">
                    <div class="col col-auto d-flex" [title]="'home.topBar.events' | translate">
                        <button routerLink="/events" type="button" class="btn btn-link p-0">
                            <span class="d-none d-md-inline d-lg-inline d-xl-inline nav-button" [class.active-topbar]="router.url === '/events'">{{
                                'home.topBar.events' | translate
                            }}</span>
                        </button>
                        <span *ngIf="router.url === '/events'" class="active-button-badge"></span>
                    </div>
                </div>
                <div class="d-none d-md-block" *ngIf="(permissions$ | async)?.admin">
                    <div class="col col-auto d-flex" [title]="Administration">
                        <button routerLink="/administration" type="button" class="btn btn-link p-0">
                            <span
                                class="d-none d-md-inline d-lg-inline d-xl-inline nav-button"
                                [class.active-topbar]="router.url.includes('/administration')"
                                >Administration</span
                            >
                        </button>
                        <span *ngIf="router.url.includes('/administration')" class="active-button-badge"></span>
                    </div>
                </div>

                <div class="d-none d-md-block" *ngIf="shouldDisplayDashboard">
                    <div class="col col-auto d-flex" [title]="dashboard">
                        <button routerLink="/dashboard/members" type="button" class="btn btn-link p-0">
                            <span
                                class="d-none d-md-inline d-lg-inline d-xl-inline nav-button"
                                [class.active-topbar]="router.url.includes('/dashboard')"
                                >Dashboard</span
                            >
                        </button>
                        <span *ngIf="router.url.includes('/dashboard/members')" class="active-button-badge"></span>
                    </div>
                </div>

                <!-- External link menu if only one link -->
                <div *ngIf="countExternalLinks === 1 && !noExternalLinks && items.length < 2" class="d-none d-md-block">
                    <div class="col col-auto d-flex" title="{{ firstExternalLink?.name }}">
                        <button type="button" class="btn btn-link p-0">
                            <a class="nav-button" href="{{ firstExternalLink?.url }}" target="_blank">
                                {{ firstExternalLink?.name }}
                            </a>
                        </button>
                    </div>
                </div>

                <!-- External links submenu if more than one link -->
                <div class="d-none d-md-block" *ngIf="items.length > 1">
                    <div class="col col-auto d-flex">
                        <button type="button" class="btn btn-link p-0" (click)="menu.toggle($event)">
                            <span class="d-none d-md-inline d-lg-inline d-xl-inline nav-button"
                                >{{ 'community.nav.theme.externalLinkTitle' | translate }} <em class="pi pi-angle-down"></em
                            ></span>
                        </button>
                        <p-menu #menu appendTo="body" [popup]="true" [model]="items"></p-menu>
                    </div>
                </div>

                <div [tourAnchor]="isMobile ? 'complete-profile-mobile' : 'complete-profile'">
                    <div class="col col-auto d-flex p-p-0 mr-2" title="Score de découverte de la plateforme">
                        <button routerLink="/welcome" type="button" class="btn btn-link p-0">
                            <span class="d-flex nav-button" [class.active-topbar]="router.url === '/welcome'">
                                <i class="icon-trophy trophy-menu-icon"></i>
                                <span class="p-pl-2 d-none d-md-block {{ welcomePercentage < 100 ? 'welcome-score-bad' : '' }}"
                                    >{{ welcomePercentage }}%</span
                                >
                            </span>
                        </button>
                        <span *ngIf="router.url === '/welcome'" class="active-button-badge"></span>
                    </div>
                </div>
                <!-- User menu -->
                <div class="col col-auto d-none d-md-flex" title="{{ 'home.topBar.myProfile' | translate }}" tourAnchor="profile">
                    <div class="dropdown user-info">
                        <button
                            type="button avatar-button"
                            class="btn btn-link p-0"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <app-user-avatar
                                [firstName]="currentUser.firstName"
                                [lastName]="currentUser.lastName"
                                [picture]="currentUser.pictureAccessLink"
                            ></app-user-avatar>
                            <i class="pi pi-angle-down nav-button profile"></i>
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" routerLink="/profile/edit" routerLinkActive="active-topbar">
                                {{ 'home.topBar.myProfile' | translate }}
                            </a>
                            <a class="dropdown-item" (click)="toggleNotificationConfigModal()">
                                {{ 'home.topBar.configureNotifications' | translate }}
                            </a>
                            <a
                                *ngIf="!(permissions$ | async)?.admin && (permissions$ | async)?.canEditOrganization"
                                class="dropdown-item"
                                routerLink="/administration-organizations/mine"
                                routerLinkActive="true"
                                >{{ 'home.topBar.manageMyOrganization' | translate }}</a
                            >
                            <a
                                *ngIf="(permissions$ | async)?.canAccessCommunityManagementComponents"
                                class="dropdown-item"
                                routerLink="/community/theme"
                                routerLinkActive="true"
                                >{{ 'home.topBar.manageMyCommunities' | translate }}</a
                            >
                            <a *ngIf="isEnvWuDo" class="dropdown-item" href="https://wudo.io/aide/" target="_blank" routerLinkActive="active-topbar">
                                {{ 'home.topBar.help' | translate }}
                            </a>
                            <a class="dropdown-item" routerLink="/policies" routerLinkActive="active-topbar">{{
                                'home.topBar.termsOfService' | translate
                            }}</a>
                            <a class="dropdown-item" routerLink="/contact" routerLinkActive="active-topbar">{{
                                'home.topBar.contactUs' | translate
                            }}</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" (click)="logout()">{{ 'home.topBar.logout' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-notification-config [(visible)]="notificationsConfigModalVisible"> </app-notification-config>
