import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { Community } from "../../models/identity/community.model";

export class ScopeSelectionFormGroup extends FormGroup {

    get organizationVisibility(): FormControl {
        return this.get('organizationVisibility') as FormControl;
    }

    get myCommunitiesVisibility(): FormControl {
        return this.get('myCommunitiesVisibility') as FormControl;
    }

    get allCommunitiesVisibility(): FormControl {
        return this.get('allCommunitiesVisibility') as FormControl;
    }

    public selectedCommunities: Community[] = [];
    private readonly userCommunities: Community[] = [];

    constructor(formBuilder: FormBuilder, userCommunities: Community[]) {
        const formGroup = formBuilder.group(
            {
                organizationVisibility: [false],
                myCommunitiesVisibility: [false],
                allCommunitiesVisibility: [false],
            });

        super(formGroup.controls, ScopeSelectionFormGroup.requiredOneValidator());

        this.userCommunities = userCommunities;

        this.organizationVisibility.valueChanges.subscribe((visible) => {
            // Uncheck "My communities" and "All communities" because it's inconsistent with "My organization" unchecked.
            if (!visible) {
                this.myCommunitiesVisibility.setValue(false);
                this.allCommunitiesVisibility.setValue(false);
                this.selectedCommunities = [];
            }
        });

        this.myCommunitiesVisibility.valueChanges.subscribe((visible) => {
            // Check "My organization" with "My communities" checked.
            if (visible) {
                this.organizationVisibility.setValue(true);
            } else {
                this.allCommunitiesVisibility.setValue(false);
            }
        });

        this.allCommunitiesVisibility.valueChanges.subscribe((visible) => {
            // Check every visibility modes with "All communities" checked.
            if (visible) {
                this.organizationVisibility.setValue(true);
                this.myCommunitiesVisibility.setValue(true);
                this.selectAllUserCommunities();
            }
        });
    }

    private static requiredOneValidator(): ValidatorFn {
        return (formGroup: AbstractControl) => {
            const controls = (formGroup as FormGroup).controls;
            return Object.keys(controls).find((key) => formGroup.get(key).value) ? null : { required: true };
        };
    }

    public selectCommunity(community: Community) {
        if (this.isCommunitySelected(community)) {
            this.selectedCommunities = this.selectedCommunities.filter(
                (selectedCommunity) => selectedCommunity.id != community.id,
            );
            this.allCommunitiesVisibility.setValue(false);
        } else {
            this.selectedCommunities.push(community);
        }

        this.myCommunitiesVisibility.setValue(this.selectedCommunities.length > 0);
    }

    public selectAllUserCommunities() {
        this.myCommunitiesVisibility.setValue(true);
        this.selectedCommunities = this.userCommunities;
    }

    public deselectAllUserCommunities() {
        this.myCommunitiesVisibility.setValue(false);
        this.selectedCommunities = [];
    }

    public getUserSelectedCommunities(): Community[] {
        return this.getSelectedCommunities(this.userCommunities);
    }

    public areAllUserCommunitiesSelected(): boolean {
        return this.areAllCommunitiesSelected(this.userCommunities);
    }

    public getUserSelectedCommunitiesCount(): number {
        return this.getSelectedCommunitiesCount(this.userCommunities);
    }

    private isCommunitySelected(community: Community): boolean {
        return this.selectedCommunities.some((selectedCommunity) => selectedCommunity.id === community.id);
    }

    private areAllCommunitiesSelected(communities: Community[]): boolean {
        return communities.every((community) => this.isCommunitySelected(community));
    }

    private getSelectedCommunities(communities: Community[]): Community[] {
        return communities.filter((community) => this.isCommunitySelected(community));
    }

    private getSelectedCommunitiesCount(communities: Community[]): number {
        return this.getSelectedCommunities(communities).length;
    }
}
