import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../models/identity/user.model';
import { InterestDomain } from '../../models/skills/interest-domain.model';
import { Interest } from '../../models/skills/interest.model';
import { SkillDomain } from '../../models/skills/skill-domain.model';
import { Skill } from '../../models/skills/skill.model';
import { Community } from './../../models/identity/community.model';
import { UpdateUserCommand } from './../../models/identity/user.command';
import { ResponseFromBatchInsertUser } from './../../models/identity/user.model';

@Injectable({ providedIn: 'root' })
export class UserService {
    private userApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.usersApi.baseUrl}`;
    private userBatchInsertApiUrl = `${environment.microservices.identity.baseUrl}/${environment.microservices.identity.usersBatchInsertApi.baseUrl}`;

    public isCreate = false;

    constructor(private http: HttpClient) {}

    public batchInsertUser(
        users: User[],
        communities: Community[],
        skills: Skill[],
        interests: Interest[],
        skillDomains: SkillDomain[],
        interestDomains: InterestDomain[],
    ): Observable<ResponseFromBatchInsertUser> {
        const body: string = this.createBatchInsertUserBody(users, communities, skills, interests, skillDomains, interestDomains);
        return this.http.post<ResponseFromBatchInsertUser>(this.userBatchInsertApiUrl, JSON.parse(body));
    }

    public createUser(user: User): Observable<User> {
        return this.http.post<User>(this.userApiUrl, user);
    }

    public getUserById(id: string) {
        return this.http.get<User>(this.userApiUrl + '/' + id);
    }

    public getUserFromInput(sort: string, page: number, input: string, findDisabledUsers: boolean = false) {
        return this.http.get<User[]>(this.userApiUrl + '/getUserFromInput', {
            params: {
                sort: sort,
                page: page.toString(),
                input: input,
                active: String(findDisabledUsers),
            },
        });
    }

    public getUsers(sort: string, page: number, findDisabledUsers: boolean = false): Observable<User[]> {
        return this.http.get<User[]>(this.userApiUrl, {
            params: {
                sort: sort,
                page: page.toString(),
                pageSize: environment.settings.userPageSize.toString(),
                active: String(findDisabledUsers),
            },
        });
    }

    public updateUser(user: UpdateUserCommand): Observable<User> {
        return this.http.put<User>(this.userApiUrl, user);
    }

    private createBatchInsertUserBody(
        users: User[],
        communities: Community[],
        skills: Skill[],
        interests: Interest[],
        skillDomains: SkillDomain[],
        interestDomains: InterestDomain[],
    ): string {
        return JSON.stringify({
            Users: users,
            Communities: communities,
            Skills: skills,
            Interests: interests,
            SkillDomains: skillDomains,
            InterestDomains: interestDomains,
        });
    }
}
