import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, Validators } from '@angular/forms';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { BaseEventCommand } from '../../../models/events/base-event-command.model';
import { QuillEditorHelper } from '../../../helpers/quill-editor.helper';
import { EVENT_DESCRIPTION_MAX_LENGTH, EVENT_TITLE_MAX_LENGTH } from '../../../models/events/event.model';
import { EventTypeEnum, getEventTypeEnumSelectList } from '../../../models/events/event-type.enum.model';
import { SelectListItem } from '../../../models/shared/select-list-item.model';
import { CropperParams } from '../../../models/shared/cropper-params.model';
import { ImageService } from '../../../services/shared/image.service';
import { BaseEventStep } from '../../directives/base-event-step.directive';
import { EventsService } from '../../../services/events/events.service';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { HttpStatusCode } from '@angular/common/http';
import { Error } from '../../../models/shared/error.model';
import { EventLight } from '../../../models/events/event-light.model';
import { TranslateService } from '@ngx-translate/core';
import { ImageCropModalComponent } from '../../../shared/components/image-crop-modal/image-crop-modal.component';

@Component({
    selector: 'app-event-form-step-one',
    templateUrl: './event-form-step-one.component.html',
    styleUrls: ['./event-form-step-one.component.scss'],
})
export class EventFormStepOneComponent extends BaseEventStep implements OnInit {
    // Used when in edit mode
    @Input() savedPicturePreviewUrl: string;
    @Input() eventLight: EventLight;

    @ViewChild(ImageCropModalComponent) public imageCropModal: ImageCropModalComponent;

    public cropperParams: CropperParams = new CropperParams();

    public get picturePreviewUrl(): string {
        return this.stepForm.controls.picturePreviewUrl.value;
    }

    descriptionCounter = 0;
    eventTypes: SelectListItem<EventTypeEnum>[] = getEventTypeEnumSelectList();

    // Initial name of the event when in edit mode
    private initialEventName: string | null = null;

    EVENT_DESCRIPTION_MAX_LENGTH = EVENT_DESCRIPTION_MAX_LENGTH;
    EVENT_TITLE_MAX_LENGTH = EVENT_TITLE_MAX_LENGTH;
    constructor(
        toastrHelper: ToastrHelper,
        translate: TranslateService,
        public readonly quillEditorHelper: QuillEditorHelper,
        public readonly imageService: ImageService,
        private readonly formBuilder: FormBuilder,
        private readonly eventService: EventsService,
    ) {
        super(toastrHelper, translate);
    }

    ngOnInit(): void {
        this.initialEventName = this.eventCommand.name;
        this.createForm();
        this.isStepLoaded = true;

        this.descriptionCounter = this.quillEditorHelper.getPlainTextLength(this.eventCommand.description);

        this.cropperParams.ratio = 1080 / 300;
        this.cropperParams.isRatioFix = true;
        this.cropperParams.resizeToHeight = 300;
        this.cropperParams.resizeToWidth = 1080;
        this.cropperParams.urlImage = this.stepForm.controls.picturePreviewUrl.value;
        this.cropperParams.maxSizeString = this.imageService.getMaxSizeInString();
    }

    protected override createForm(): void {
        this.stepForm = this.formBuilder.group({
            name: [
                '',
                {
                    validators: [Validators.required, Validators.maxLength(EVENT_TITLE_MAX_LENGTH)],
                    asyncValidators: [this.uniqueNameValidator()],
                },
            ],
            description: ['', Validators.required],
            type: [null, Validators.required],
            picture: [null, []],
            picturePreviewUrl: [null, Validators.required],
        });

        if (this.editMode) {
            this.patchForm();
        }
    }

    private patchForm(): void {
        this.stepForm.patchValue({
            name: this.eventCommand.name,
            description: this.eventCommand.description,
            type: this.eventCommand.type,
            picturePreviewUrl: this.savedPicturePreviewUrl,
        });
    }

    private uniqueNameValidator(): AsyncValidatorFn {
        return (control: AbstractControl) =>
            of(control.value).pipe(
                delay(500),
                switchMap((value: string) => {
                    if (this.editMode && this.initialEventName === value) {
                        // Name hasn't changed
                        return of(null);
                    }

                    return this.eventService.doesEventExist(value).pipe(
                        map((response) => {
                            if (response.status === HttpStatusCode.Ok) {
                                return { unique: true };
                            }

                            return null;
                        }),
                        catchError((err) => {
                            const error = err as Error;

                            if (error?.code === HttpStatusCode.NotFound.toString()) {
                                return of(null);
                            }

                            return of({ error: err });
                        }),
                    );
                }),
            );
    }

    public override updateEventCommandFromStepComponent(): void {
        const command: BaseEventCommand = this.stepForm.value;

        this.eventCommand.name = command.name.trim();
        this.eventCommand.description = command.description?.trim();
        this.eventCommand.type = command.type;
        this.eventCommand.picture = command.picture;
    }

    public onDescriptionChanged(editor: any) {
        this.descriptionCounter = this.quillEditorHelper.getPlainTextLength(editor.container.innerHTML);
        this.quillEditorHelper.setQuillControlError(this.stepForm.controls.description, this.descriptionCounter, EVENT_DESCRIPTION_MAX_LENGTH, true);
    }

    public savePicture() {
        const format = this.imageService.getFormatFromBase64(this.imageService.croppedImage as string);

        if (this.imageService.isInBadFormat(format)) {
            this.toastrHelper.showError(this.translate.instant('toastMessages.pictureExtensionError'));
            return;
        }

        // 625000 is the number of bits in 5Mb.
        if (this.imageService.isExceedingPictureMaxSize(this.imageService.getImageSizeFromBase64(this.imageService.croppedImage as string))) {
            this.toastrHelper.showError(this.translate.instant('toastMessages.profilePictureSizeError'));
            return;
        }

        this.stepForm.controls.picturePreviewUrl.setValue(this.imageService.croppedImage as string);
        this.stepForm.controls.picture.setValue(this.imageService.getImageInFile());
        this.imageService.initCropperValues();
    }
}
