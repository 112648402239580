import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrHelper } from '../helpers/toastr.helper';
import { ClubRoom } from '../models/clubs/club-room.model';
import { Club, ClubMembers } from '../models/clubs/club.model';
import { Member } from '../models/clubs/member.model';
import { Publication } from '../models/clubs/publication.model';
import { UserStatusTypes } from '../models/identity/user-status-types.model';
import { AccountsService } from '../services/identity/accounts.service';
import { ClubRoles } from './../models/clubs/club-roles.model';
import { CLUB_PRIVACIES } from './../models/clubs/constants/club-privacies';
import { CLUB_VISIBILITIES } from './../models/clubs/constants/club-visibilities';
import { ClubsService } from './../services/clubs/clubs.service';
import { MemberApiService } from './../services/clubs/member.api.service';
import { PublicationService } from './../services/clubs/publication.service';

@Injectable({ providedIn: 'root' })
export class ClubsStore {
    private readonly _displayRooms = new BehaviorSubject<ClubRoom[]>([]);
    // displayed club publications
    private readonly _displayedClubPublications = new BehaviorSubject<Publication[]>(null);

    private _member = new BehaviorSubject<Member>(null);
    private _members: { [key: string]: Array<Member> } = {};

    public addMembersClickEvent$ = new EventEmitter();
    public club: Club = null;
    public displayedClubId: string;
    public displayedRoomId: string;
    // member
    public isLoadingMembers: boolean;
    public member$ = this._member.asObservable();
    public memberReadyEvent$ = new EventEmitter();
    public membersLoadedEvent$ = new EventEmitter();
    public parentClub: Club;
    public isOnePostPageDisplayed: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private readonly router: Router,
        private readonly accountsService: AccountsService,
        private readonly clubsService: ClubsService,
        private readonly memberService: MemberApiService,
        private readonly publicationService: PublicationService,
        private translate: TranslateService,
        private toastrHelper: ToastrHelper,
    ) {
        this.loadMember();
    }

    public get displayRooms(): ClubRoom[] {
        return this._displayRooms.getValue();
    }

    public set displayRooms(data: ClubRoom[]) {
        this._displayRooms.next(data);
    }

    public get displayedClub(): Club {
        this.club = this.member?.fullClubs?.find((club) => club.id === this.displayedClubId);
        if (!this.club) {
            this.parentClub = this.member?.fullClubs?.find((club) => club.rooms.find((room) => room.id === this.displayedClubId));
            this.club = this.parentClub?.rooms?.find((room) => room.id === this.displayedClubId);

            if (!this.club && this.accountsService.isAdmin()) {
                return this.club;
            }
        }

        return this.club;
    }

    public get canUserPublish(): boolean {
        if (this.displayedRoom?.publicationsAreLimited) {
            if (this.isAdminOfDisplayedClub || this.isAnimatorOfDisplayedRoom) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    get displayedClub$(): Observable<Club> {
        return this.member$.pipe(
            map((member) => {
                this.club = member?.fullClubs?.find((club) => club.id === this.displayedClubId);
                if (!this.club) {
                    this.parentClub = member?.fullClubs?.find((club) => club.rooms?.find((room) => room.id === this.displayedClubId));
                    this.club = this.parentClub?.rooms?.find((room) => room.id === this.displayedClubId);
                }
                return this.club;
            }),
        );
    }

    public get displayedClubPublications(): Publication[] {
        return this._displayedClubPublications.getValue();
    }

    public set displayedClubPublications(data: Publication[]) {
        this._displayedClubPublications.next(data);
    }

    public get displayedRoom(): Club {
        const displayedRoom = this.displayedClub.rooms.find((room) => room.id == this.displayedRoomId);
        if (!displayedRoom) {
            return this.displayedClub;
        }
        return displayedRoom;
    }

    public get flatMembers(): Array<Member> {
        const result: Member[] = [];
        this.roles.forEach((role) => {
            if (this._members[role] !== undefined) result.push(...this._members[role]);
        });

        return result;
    }

    public get isACommunityClub(): boolean {
        return this.isMainRoom ? this.displayedClub.communityId !== null : this.displayedRoom.communityId !== null;
    }

    public get isAProjectClub(): boolean {
        return this.isMainRoom ? this.displayedClub.projectId !== null : this.displayedRoom.projectId !== null;
    }

    public get isAdminOfDisplayedClub(): boolean {
        return this.accountsService.isAdmin() || this.roleInDisplayedClub == ClubRoles.Admin;
    }

    public get isAnimatorOfDisplayedClub(): boolean {
        return this.roleInDisplayedClub == ClubRoles.Animator;
    }

    public get isAnimatorOfDisplayedRoom(): boolean {
        return this.roleInDisplayedRoom == ClubRoles.Animator || this.roleInDisplayedRoom == ClubRoles.Admin || this.isAdminOfDisplayedClub;
    }

    public get isMainRoom() {
        return this.displayedClubId == this.displayedRoomId;
    }

    public get member(): Member {
        return this._member.getValue();
    }

    public set member(data: Member) {
        this._member.next(data);
    }

    public get members(): { [key: string]: Array<Member> } {
        return this._members;
    }

    public get presentationRoomId(): string {
        const presentationRoom = this.displayedClub.rooms.find((room) => room.isCommunityPresentationRoom);
        if (presentationRoom) {
            presentationRoom.id;
        }
        return this.displayedClubId;
    }

    public get roleInDisplayedClub(): ClubRoles {
        return this.displayedClub?.members?.find((member) => member.id == this.accountsService.currentUser.userId)?.role ?? ClubRoles.Member;
    }

    public get roleInDisplayedRoom(): ClubRoles {
        return this.isMainRoom
            ? this.roleInDisplayedClub
            : this.displayedRoom.members.find((member) => member.id == this.accountsService.currentUser.userId)?.role ?? ClubRoles.Member;
    }

    // all members by role
    private get roles(): Array<string | ClubRoles> {
        return Object.values(ClubRoles).filter((x) => typeof x === 'number');
    }

    public addOrUpdateRoomInClub(room: Club, deleteMode = false) {
        const clubIndex = this.member.fullClubs.findIndex((club) => club.id == room.parentClubId);
        const club: Club = this.member.fullClubs[clubIndex];

        if (deleteMode) {
            club.rooms = club.rooms.filter((currentRoom) => currentRoom.id != room.id);

            this.displayRooms = this.displayRooms.filter((currentRoom) => currentRoom.id != room.id);

            this.router.navigate(['clubs/', room.parentClubId]);

            return;
        }

        if (club != null) {
            const roomIndex = club.rooms.findIndex((currentRooms) => currentRooms.id == room.id);
            if (roomIndex >= 0) club.rooms[roomIndex] = room;
            else club.rooms.push(room);

            this.member.fullClubs[clubIndex] = club;
        }

        const newClubRoom: ClubRoom = {
            id: room.id,
            parentClubId: room.parentClubId,
            title: room.title,
            notificationsCount: 0,
            visibility: room.visibility,
            privacy: room.privacy,
            publicationsAreLimited: room.publicationsAreLimited,
            isCommunityPresentationRoom: room.isCommunityPresentationRoom,
        };

        const roomIndex = this.displayRooms.findIndex((currentRooms) => currentRooms.id == room.id);

        if (roomIndex >= 0) this.displayRooms[roomIndex] = newClubRoom;
        else this.displayRooms.push(newClubRoom);

        this.setDisplayedClub(room.id);

        if (roomIndex >= 0) window.location.reload();
        else this.router.navigate(['clubs/', room.id]);
    }

    public canManageResources() {
        return this.isAdminOfDisplayedClub || this.isAnimatorOfDisplayedRoom;
    }

    public emitNewEventIfMembersAreLoaded() {
        if (this.flatMembers != null && this.flatMembers.length > 0) {
            this.membersLoadedEvent$.emit();
        }
    }

    public getClubPublicationsByPages(clubId: string, publicationsFlowType: number, lastVisitDate: Date, page: number): Observable<Publication[]> {
        return this.publicationService.getClubPublicationsByPages(clubId, publicationsFlowType, lastVisitDate, page);
    }

    public getMemberRoleByMemberId(memberId: string): ClubRoles {
        return (
            this.displayedRoom?.members.find((member) => member.id == memberId)?.role ??
            this.displayedClub.members.find((member) => member.id == memberId)?.role
        );
    }

    public getRooms(club: Club): ClubRoom[] {
        const rooms: ClubRoom[] = [];

        rooms.push({
            id: club.id,
            title: this.translate.instant('clubs.default.defaultRoomName'),
            parentClubId: club.id,
            notificationsCount: 0,
            visibility: CLUB_VISIBILITIES.VISIBLE,
            privacy: CLUB_PRIVACIES.OPEN,
            publicationsAreLimited: false,
            isCommunityPresentationRoom: club.isCommunityPresentationRoom,
        });

        club.rooms?.forEach((el) => {
            rooms.push({
                id: el.id,
                title: el.title,
                parentClubId: el.parentClubId,
                notificationsCount: 0,
                visibility: el.visibility,
                privacy: el.privacy,
                publicationsAreLimited: el.publicationsAreLimited,
                isCommunityPresentationRoom: el.isCommunityPresentationRoom,
            });
        });
        return rooms;
    }
    public isPresentationRoom(): boolean {
        const presentationRoom = this.displayedClub.rooms.find((room) => room.isCommunityPresentationRoom);

        if (presentationRoom) {
            return presentationRoom.id === this.displayedRoomId;
        }

        return this.isMainRoom;
    }

    public async loadClub(id: string) {
        try {
            const updatedClub = await this.clubsService.getClubById(id).toPromise();

            // save current member state
            const member = this.member;
            this.member = null;

            const index = member.fullClubs.findIndex((club) => club.id === id);

            if (index !== -1) {
                member.fullClubs[index] = updatedClub;
            } else {
                if (updatedClub.parentClubId == null) {
                    member.fullClubs.push(updatedClub);
                } else {
                    const parentClub = await this.clubsService.getClubById(updatedClub.parentClubId).toPromise();
                    this.displayedClubId = parentClub.id;
                    member.fullClubs.push(parentClub);
                }
            }

            // update member subject behavior
            this.member = member;
            await this.router.navigate(['clubs', id]);
        } catch (error) {
            const msg = this.translate.instant('clubs.navigation.toast.clubNotFound');
            this.toastrHelper.showError(msg);
            this.router.navigate(['clubs/', this.member.fullClubs[0].id]);
        }
    }

    public async loadMembers() {
        this._members = {};
        this.isLoadingMembers = true;
        this.memberService.getMembersByClubId(this.displayedRoomId).subscribe(
            (membersByRole: Array<ClubMembers>) => {
                this.roles.forEach((role) => {
                    this._members[role] = this.filterByRole(membersByRole, role as ClubRoles);
                });
                this.membersLoadedEvent$.emit();
                this.isLoadingMembers = false;
            },
            () => {
                console.error('Error raised during get members of club');
                this.isLoadingMembers = false;
            },
        );
    }

    public async loadPublicationsOfClub(id: string) {
        this.displayedClubPublications = await this.publicationService.getPublicationsByClubId(id).toPromise();
    }

    public async loadRooms() {
        if (this.displayedClub?.parentClubId) {
            // displayedClub is a room
            return;
        }

        // displayedClub is a club
        this.displayRooms = this.getRooms(this.displayedClub);
    }

    public onClubDeleted(club: Club) {
        this.member.fullClubs = this.member.fullClubs.filter((memberClub) => memberClub.id != club.id);
        if (this.member.fullClubs != null && this.member.fullClubs.length > 0) this.router.navigate(['clubs/', this.member.fullClubs[0].id]);
        else this.router.navigate(['/']);
    }

    public clubByCommunityId(communityId: string): Club {
        return this.member.fullClubs.find((c) => c.communityId === communityId);
    }

    public presentationRoomIdByCommunityId(communityId: string): string {
        const mainCommunityClub = this.clubByCommunityId(communityId);

        const presentationRoom = mainCommunityClub?.rooms.find((room) => room.isCommunityPresentationRoom);

        if (presentationRoom) {
            return presentationRoom.id;
        }

        return mainCommunityClub.id;
    }

    public async setDisplayedClub(id: string) {
        const member = this.member;
        const index = member.fullClubs.findIndex((club) => club.id === id);

        if (index >= 0) {
            this.displayedClubId = id;
        } else {
            const currentClub = this.member.fullClubs.find((club) => club.rooms.find((room) => room.id == id) != undefined);

            if (currentClub != null) {
                this.displayedClubId = currentClub.id;
            } else {
                if (this.accountsService.isAdmin()) {
                    this.displayedClubId = id;
                    this.displayedRoomId = id;
                    await this.loadClub(id);
                    await this.loadPublicationsOfClub(id);
                    await this.loadRooms();
                    return;
                } else {
                    const msg = this.translate.instant('clubs.navigation.toast.restrictedAccess');
                    this.toastrHelper.showError(msg);
                    this.router.navigate(['/']);
                }
            }
        }

        this.displayedRoomId = id;

        await this.loadRooms();

        await this.loadMembers();
    }

    public updateMemberRole(memberId: string, newRole: ClubRoles) {
        if (memberId === this.member.id) {
            this.loadClub(this.displayedClubId);
            this.loadRooms();
            this.loadMembers();
            return;
        }

        switch (newRole) {
            case ClubRoles.Admin: {
                let memberToMove = this.members[ClubRoles.Member].find((member) => member.id == memberId);
                if (memberToMove == null) memberToMove = this.members[ClubRoles.Animator].find((member) => member.id == memberId);
                if (memberToMove != null) {
                    setTimeout(() => {
                        this.members[ClubRoles.Member] = this.members[ClubRoles.Member].filter((member) => member.id != memberId);
                        this.members[ClubRoles.Animator] = this.members[ClubRoles.Animator].filter((member) => member.id != memberId);
                    }, 300);

                    setTimeout(() => {
                        this.members[ClubRoles.Admin].push(memberToMove);
                    }, 500);
                }
                break;
            }
            case ClubRoles.Animator: {
                let memberToMove = this.members[ClubRoles.Member].find((member) => member.id == memberId);
                if (memberToMove == null) memberToMove = this.members[ClubRoles.Admin].find((member) => member.id == memberId);
                if (memberToMove != null) {
                    setTimeout(() => {
                        this.members[ClubRoles.Member] = this.members[ClubRoles.Member].filter((member) => member.id != memberId);
                        this.members[ClubRoles.Admin] = this.members[ClubRoles.Admin].filter((member) => member.id != memberId);
                    }, 300);

                    setTimeout(() => {
                        this.members[ClubRoles.Animator].push(memberToMove);
                    }, 500);
                }
                break;
            }
            case ClubRoles.Member: {
                let memberToMove = this.members[ClubRoles.Admin].find((member) => member.id == memberId);
                if (memberToMove == null) memberToMove = this.members[ClubRoles.Animator].find((member) => member.id == memberId);
                if (memberToMove != null) {
                    setTimeout(() => {
                        this.members[ClubRoles.Admin] = this.members[ClubRoles.Admin].filter((member) => member.id != memberId);
                        this.members[ClubRoles.Animator] = this.members[ClubRoles.Animator].filter((member) => member.id != memberId);
                    }, 300);

                    setTimeout(async () => {
                        this.members[ClubRoles.Member].push(memberToMove);
                    }, 500);
                }
                break;
            }
        }
    }

    private filterByRole(allMembers: Array<ClubMembers>, role: ClubRoles): Array<Member> {
        const filteredMembers = (allMembers || []).filter((a: ClubMembers) => a.role === role);

        if (filteredMembers?.length !== 1) return new Array<Member>();

        let members = filteredMembers[0].members;

        // filter inactive members if the user is neither admin nor community manager.
        if (!this.accountsService.displayUsersStatusAndInactiveUsers) {
            members = members.filter((member) => member.status == UserStatusTypes.Active);
        }

        return members.sort(Member.sortByFullName);
    }

    public loadMember() {
        if (!this.accountsService.currentUser) {
            return;
        }

        this.memberService.getMemberById(this.accountsService.currentUser.userId).subscribe((member) => {
            this.member = null;
            this.member = member;
            this.movePrimaryCommunityAtFirst();

            // special case for admin to load the club if the member is updated and the is admin is not a member of the club
            if (this.displayedClubId && !this.displayedClub && this.accountsService.isAdmin) {
                this.setDisplayedClub(this.displayedClubId);
            }

            //When member is loaded send an event so we can display clubs
            this.memberReadyEvent$.emit();
        });
    }

    private movePrimaryCommunityAtFirst() {
        const indexOfPrimaryCommunity = this.member.fullClubs.findIndex(
            (club) => club.communityId != null && club.communityId === this.accountsService.currentUser.primaryCommunity.id,
        );
        const clubOfPrimaryCommunity = this.member.fullClubs.splice(indexOfPrimaryCommunity, 1)[0];
        this.member.fullClubs.unshift(clubOfPrimaryCommunity);
    }
}
