import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { projectStatus } from '../../../constants/project-status';
import { Project } from '../../../models/projects/project.model';
import { AccountsService } from '../../../services/identity/accounts.service';
import { ProjectsBusinessService } from '../../services/projects-business.service';
@Component({
    selector: 'app-project-details-card',
    styleUrls: ['./project-details-card.component.scss'],
    templateUrl: './project-details-card.component.html',
})
export class ProjectDetailsCardComponent {
    @Input() project: Project;
    @Input() isAdmin = false;
    @Input() isOwner = false;

    constructor(
        private readonly accountsService: AccountsService,
        private readonly projectsBusinessService: ProjectsBusinessService,
        private router: Router,
    ) {}
    isEditable() {
        return (this.isOwner || this.isAdmin) && this.isStatusEligibleForEdit();
    }

    isStatusEligibleForEdit(): boolean {
        return this.project.status !== projectStatus.finished;
    }

    isUserParticipatingToProject(): boolean {
        return this.projectsBusinessService.isUserParticipatingToProject(this.project, this.accountsService.currentUser.userId);
    }

    public navigateToProject(): void {
        this.router.navigate(['/projects', this.project.id]);
    }
}
