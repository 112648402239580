import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ExternalLink } from '../../../models/shared/external-link.model';

@Component({
    selector: 'app-community-links-form',
    templateUrl: './community-links-form.component.html',
    styleUrls: ['./community-links-form.component.scss'],
})
export class CommunityLinksFormComponent implements OnInit, OnChanges {
    @Input() public communityExternalLinks: ExternalLink[] = [];
    @Output() public communityExternalLinksChange = new EventEmitter<FormArray>();
    public form = this.formBuilder.group({
        externalLinks: this.formBuilder.array([]),
    });

    constructor(private readonly formBuilder: FormBuilder) {}

    public get externalLinks() {
        return this.form.get('externalLinks') as FormArray;
    }

    public addExternalLink() {
        if (this.externalLinks.length < 5) {
            this.externalLinks.push(
                this.formBuilder.group({
                    name: ['', Validators.required],
                    url: ['', [Validators.required, Validators.pattern('https?://.+')]],
                }),
            );
            this.emitChanges();
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.communityExternalLinks) {
            this.populateExternalLinks();
        }
    }

    public ngOnInit(): void {
        this.populateExternalLinks();
    }

    public onChange() {
        this.emitChanges();
    }

    public removeExternalLink(index: number) {
        this.externalLinks.removeAt(index);
        this.emitChanges();
    }

    private emitChanges(): void {
        // emit the external links but remove all empty values as formarray
        const externalLinksToSave = this.externalLinks.controls.filter((control) => !!control.value.name && !!control.value.url);
        this.communityExternalLinksChange.emit(new FormArray(externalLinksToSave));
    }

    private populateExternalLinks() {
        this.externalLinks.clear();

        if (this.communityExternalLinks.length > 0) {
            this.communityExternalLinks.forEach((link: ExternalLink) => {
                this.externalLinks.push(
                    this.formBuilder.group({
                        name: [link.name, Validators.required],
                        url: [link.url, [Validators.required, Validators.pattern('https?://.+')]],
                    }),
                );
            });
        }
    }
}
