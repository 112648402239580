import { Component, Input } from '@angular/core';
import { Theme } from '../../../models/shared/theme.model';

@Component({
    selector: 'app-platform-theme-preview',
    templateUrl: './platform-theme-preview.component.html',
    styleUrls: ['./platform-theme-preview.component.scss'],
})
export class PlatformThemePreviewComponent {
    @Input() theme: Theme;
}
