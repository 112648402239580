import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';

@Injectable({
    providedIn: 'root',
})
export class QuillEditorHelper {
    // formats input contains what is allowed within a Quill editor.
    // For exemple, while copy/pasting rich text from another website,
    // formatting can be removed if it is not in this whitelist.
    public readonly formatsWithoutImage = ['bold', 'italic', 'underline', 'list', 'link'];

    public readonly formats = [...this.formatsWithoutImage, 'image'];
    // Modules.toolbar correspond to what buttons are displayed on the editor.

    public readonly modulesWithoutImage = {
        toolbar: [['bold', 'italic', 'underline'], [{ color: [] as any[] }, { background: [] as any[] }], ['link']],
    };

    public modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
        ],
    };

    constructor() {}

    public getPlainTextLength(htmlContent: string): number {
        const textContent = this.getPlainText(htmlContent);

        return [...textContent].length;
    }
    getPlainText(htmlContent: string): string {
        if (!htmlContent) {
            return '';
        }

        const div = document.createElement('div');
        div.innerHTML = htmlContent;

        // Extract text content and handle edge cases (invisible characters)
        let textContent = div.textContent || div.innerText || '';

        // Normalize spaces (convert non-breaking spaces and other invisible characters)
        textContent = textContent.replace(/\s+/g, ' ').replace(/[\u200B-\u200D\uFEFF]/g, ''); // Removes zero-width characters

        return textContent.trim();
    }

    getPlainTextWithLineBreaks(htmlContent: string): string {
        if (!htmlContent) {
            return '';
        }

        const div = document.createElement('div');
        div.innerHTML = htmlContent;

        // Replace <p> and <br> with explicit line breaks before extracting text
        div.innerHTML = div.innerHTML
            .replace(/<p\s*\/?>/gi, '') // Remove opening <p> tags
            .replace(/<\/p>/gi, '\r\n') // Replace closing </p> with \r\n
            .replace(/<br\s*\/?>/gi, '\r\n'); // Replace <br> tags with \r\n

        // Extract text content and handle edge cases (like invisible characters)
        let textContent = div.textContent || div.innerText || '';

        // Normalize spaces and remove zero-width characters
        textContent = textContent.replace(/[\u200B-\u200D\uFEFF]/g, ''); // Remove zero-width characters

        return textContent.trim();
    }

    public setQuillControlError(control: AbstractControl, actualLength: number, maxLength: number, isRequired: boolean = false) {
        if (actualLength > maxLength) {
            control.setErrors({ maxlength: true });
        } else if (isRequired && actualLength <= 0) {
            control.setErrors({ required: true });
        } else {
            control.setErrors(null);
        }
    }

    public setQuillControlErrorNotRequired(control: AbstractControl, actualLength: number, maxLength: number) {
        if (actualLength > maxLength) {
            control.setErrors({ maxlength: true });
        } else {
            control.setErrors(null);
        }
    }

    public getCaretPositionQuill(editorComponent: QuillEditorComponent): number {
        const editor = editorComponent.quillEditor;

        editor.focus();

        if (!editor) {
            console.error('Quill editor instance is required');
            return 0;
        }

        // Make sure the editor is focused before getting the selection
        const range = editor.getSelection();

        if (range) {
            return range.index; // Return the caret position
        } else {
            console.error('No selection found or editor is not focused');
            // Optionally, force focus on the editor
            editor.focus(); // Ensure the editor is focused
            return 0;
        }
    }

    public setCaretPositionQuill(editorComponent: QuillEditorComponent, pos: number) {
        const editor = editorComponent.quillEditor;

        if (!editor) {
            console.error('Quill editor instance is required');
            return;
        }

        // Focus on the editor first
        editor.focus();

        // Use Quill's setSelection method to move the caret
        editor.setSelection(pos, 0); // Set the caret position to 'pos'
    }
}
