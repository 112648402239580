export const environment = {
    production: false,
    mapFeaturesFlag: true,
    appInsights: {
        connectionString:
            'InstrumentationKey=4ee6a38f-a172-49e7-a86a-5172a413d2c1;IngestionEndpoint=https://francecentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://francecentral.livediagnostics.monitor.azure.com/;ApplicationId=440c4792-7b15-4875-8b8f-36075eded66c',
    },
    settings: {
        title: 'Wudo',
        code: 'WuDo',
        userPageSize: 150,
        disabledProperties: {
            user: [''],
            organization: ['organizationName', 'name'],
        },
        societyDetails: {
            name: 'Wudo',
            website: 'www.wudo.io',
            emails: {
                support: 'support@wudo.io',
                rgpd: 'rgpd@wudo.io',
            },
        },
        defaultLanguage: 'fr_WUDO',
    },
    ressource: {
        shareRedirectUrl: 'https://platform-staging.wudo.io/ressource/share',
    },
    sso: {
        docusign:
            'https://account-d.docusign.com/organizations/685b271e-ff05-4270-b230-8a894be28493/saml2/login/sp/d0c78c9a-7913-4437-8b59-4f28247f93fd',
        bernstein: 'https://app.bernstein.io/api/sso/v1/partners/f293028d-0299-4628-ba8c-7da325f2e8ea/saml/login',
        oAuth: {
            redirectUrl: 'https://platform-staging.wudo.io/oauth2/redirect',
            providers: [
                {
                    name: 'gifas',
                    enable: true,
                    authenticationUrl: 'https://test.industriedufutur-gifas.fr/oauth2/auth',
                    clientId: 're1uGDLUOqkg8qGjwmynKt41kztf',
                },
                {
                    name: 'cetim',
                    enable: true,
                    authenticationUrl: 'https://cetim.preview-helloid.com/oauth2/v2/connect/authorize',
                    clientId: 'e3ad0d7d-0437-415a-aae6-7f7c380f4fba',
                },
                {
                    name: 'psi',
                    enable: true,
                    authenticationUrl: 'https://test.parcours-sud-industrie.com/oauth2/auth',
                    clientId: 're1uGDLUOqkg8qGjwmynKt41kztf',
                },
                {
                    name: 'ikigai',
                    enable: true,
                    authenticationUrl: 'https://api-i.hosco.com/ikigai/authorize',
                    clientId: '',
                },
            ],
            verifier: {
                ikigai: {
                    userPoolId: 'eu-central-1_CbcRNwq3w',
                    clientId: '6d0t00hfojtnqj73qd83dna31m',
                },
            },
        },
    },
    features: {
        newProjectsUI: true,
        maintenance: false,
        pwa: true,
        debugResetAccount: true,
        juridicalDocumentsAvailable: true,
        formationVideosAvailable: true,
        organizationSkillsAvailable: true,
        organizationDirectoryAvailable: true,
        categoryProjectTypeAvailable: true,
        clubs: true,
        events: true,
        myEventsFilter: true,
        clubDisabledCommunityIds: ['84639cd2-fe12-4d70-84a1-8d0415a0706d'],
        realTime: {
            enableLogs: true,
        },
        clubProjectDisabledCommunitiesIds: [
            'f1904054-ba71-4b90-96e8-e534d7049b65',
            '5300a929-a9fc-4bc3-81f4-0f1c3661806b',
            '126c9ddf-941b-4ab0-b47c-f2906f659069',
            'b9ea8807-b7e3-4884-9c9e-5c6309406a69',
            'e6c31fcf-d262-479e-9ec9-7dbf5aee1656',
            '90bfd164-33a2-46a7-90f9-7ea43673a8ea',
            'f0549e9f-41e8-4d5e-a744-307d6362cf60',
            '21b66eee-5224-44fb-a420-6efb1c2c9535',
            '94ad4e07-afb4-446b-955b-4bff1a21e3b9',
            '2694a068-a188-4dfe-a450-f170e0e77764',
            'df9d6147-9e6d-4900-8f9c-00537e49e224',
            'c143681e-8847-493e-8c1c-aa3aaf1303c5',
            'df7dcdbe-d5ae-4439-b891-35edb94353e4',
            '8c2ef1e5-0b1a-4997-889d-170e5841f537',
            '55c55784-9e67-419c-95a1-a55fa1203f17',
            '57cb7a43-525d-4836-96ff-6fea0f7f6c7c',
            '89545333-8a90-4349-a575-e2de7ffa199d',
            'f47c5066-2e6e-432d-895f-fd1996e30960',
            '072f352b-ff4c-41c9-8292-cb97e8fb51ed',
            '63dd8400-71a9-4565-bab4-0eac5249a70f',
            '3c559d49-5691-450d-b91c-cc93ad591585',
            'faf515e3-049b-4e83-aba6-d2645cd59d0d',
            'cfd39a96-7cad-47b1-852e-46e82e2056d9',
        ],
        projectTypeFranceRelanceCommunitiesIdsAvailable: ['d4f3ec28-6b2a-4427-a04b-a4a3d0a426ed', '25cccc7d-9797-4c5d-923d-26f0c8955ad4'],
        projectTypeIfgpCommunitiesIdsAvailable: ['ec382e43-136a-47f8-bbf7-75380c9ab92f', '67af23d9-a322-482f-9899-19de7e15b7e9'],
        projectTypeNvCommunitiesIdsAvailable: ['ed4b7861-19de-4784-a290-7979c45e7c7b'],
        communitiesUimmIds: [
            'a8923155-341b-4d7d-8a1d-00175f3e4d92',
            '8d43f060-6ebd-4b13-a2cb-64514dd4a09e',
            '01daa437-2646-454c-b3b9-e3f5323d267e',
            'e288a40b-0343-4cac-877f-db01bc091da1',
            'cba0eb0d-20c3-4e7c-ac77-06e1a0d08720',
            'ffee24d0-7943-416f-b87c-89c0d2fec78e',
            '52f96f5c-af64-4ad1-999f-c6cb42c3193f',
            'c23fd574-6ab8-4c65-8631-3784c2d752f8',
            '0359775d-2094-4f50-b349-8b65d9cf6923',
        ],
        importUsersAvailable: true,
        backOfficeAdminFeaturesAvailable: {
            access: true,
            createCommunities: true,
            importUsers: true,
            exportUsers: true,
            massEditUsers: true,
            importOrganizations: false,
            exportOrganizations: true,
            massEditOrganizations: true,
        },
        backOfficeCommunityManagerFeaturesAvailable: {
            access: true,
            createCommunities: false,
            importUsers: false,
            exportUsers: false,
            massEditUsers: false,
            importOrganizations: false,
            exportOrganizations: false,
            massEditOrganizations: false,
        },
        topBarCreateButton: false,
    },
    communitiesId: {
        boostFrenchFabId: '25cccc7d-9797-4c5d-923d-26f0c8955ad4',
        tiId: '0168764a-59bf-4644-a075-08a8e7cd1624',
        tiLptId: '9be79a74-de95-4d98-8a7d-99c69504e0a3',
        pvfId: ['1ef6ef50-64ff-40e2-b334-d837e9993ffb', '09e8a437-5ae1-4e79-aa6d-b0ec6b4e44b3'],
        cetimId: '0b414740-6c3d-44e8-84f2-5b3bd9f8762a',
        gifasId: 'd4f3ec28-6b2a-4427-a04b-a4a3d0a426ed',
        adnfc: '9c96329c-a97a-4161-bce5-6a1bdc804320',
        hdfid: '826b4bf4-9abb-465e-aa39-ae7fed82c698',
    },
    modelsConst: {
        ORGANIZATION_SKILLS_MAX: 10,
    },
    communityConst: {
        BOOST_FRENCH_FAB: 'Boost French Fab',
    },
    bffSkilsItems: [
        {
            id: '5228f4ed-9129-450e-84bf-f677d357a9b8',
            name: 'Conduite du changement',
            displayName: 'Démarches et outils de conduite du changement',
            domainId: '58f85ca1-dfde-47ab-bdbd-eb9b85703298',
            domainName: 'Autre',
            domainDisplayName: 'Autre',
        },
        {
            id: 'b802ec82-bbe8-47cf-80b4-d9fb02fa3e74',
            name: 'Innovation ouverte et collaborative',
            displayName: 'Innovation ouverte et collaborative',
            domainId: '58f85ca1-dfde-47ab-bdbd-eb9b85703298',
            domainName: 'Autre',
            domainDisplayName: 'Autre',
        },
        {
            id: '533d570e-6bc0-4d0d-a0f6-bde46be6aa9d',
            name: 'Alliances technologiques ou géographiques',
            displayName: 'Alliances technologiques ou géographiques',
            domainId: '58f85ca1-dfde-47ab-bdbd-eb9b85703298',
            domainName: 'Autre',
            domainDisplayName: 'Autre',
        },
        {
            id: 'a9ce080f-9922-44c0-9e12-5f8315cda4d1',
            name: 'Démarches et outils Lean',
            displayName: 'Démarches et outils Lean',
            domainId: '58f85ca1-dfde-47ab-bdbd-eb9b85703298',
            domainName: 'Autre',
            domainDisplayName: 'Autre',
        },
        {
            id: 'fe2bc072-f985-4f5c-afcf-35fa4ab4a85b',
            name: 'Nouveaux outils de management et d`empowerment',
            displayName: "Nouveaux outils de management et d'empowerment",
            domainId: '58f85ca1-dfde-47ab-bdbd-eb9b85703298',
            domainName: 'Autre',
            domainDisplayName: 'Autre',
        },
        {
            id: '68dbb428-4eab-4a74-a77b-44f163cefb5b',
            name: 'Développement du capital immatériel',
            displayName: 'Développement du capital immatériel',
            domainId: '58f85ca1-dfde-47ab-bdbd-eb9b85703298',
            domainName: 'Autre',
            domainDisplayName: 'Autre',
        },
        {
            id: 'ac4a2653-958b-493b-ade3-3a979d502ee1',
            name: 'Chaînes de valeur et offres du futur',
            displayName: 'Chaînes de valeur et offres du futur',
            domainId: '58f85ca1-dfde-47ab-bdbd-eb9b85703298',
            domainName: 'Autre',
            domainDisplayName: 'Autre',
        },
        {
            id: '734c6e98-ca46-44e8-a8a5-14ec6cd0d31d',
            name: 'Économie circulaire',
            displayName: 'Économie circulaire',
            domainId: '67425dd0-68d9-4d32-aa86-5198b0628ef1',
            domainName: 'Commerciales',
            domainDisplayName: 'Commerciales',
        },
        {
            id: 'd834dbaa-f0ba-41a1-8967-98beeba0cd93',
            name: 'Nouvelles relations économiques',
            displayName: 'Nouvelles relations économiques',
            domainId: '67425dd0-68d9-4d32-aa86-5198b0628ef1',
            domainName: 'Commerciales',
            domainDisplayName: 'Commerciales',
        },
        {
            id: 'ce4f3e6a-82f5-4462-9446-6dbc8a066542',
            name: 'Import & export',
            displayName: 'Développement international, import, export',
            domainId: '67425dd0-68d9-4d32-aa86-5198b0628ef1',
            domainName: 'Commerciales',
            domainDisplayName: 'Commerciales',
        },
        {
            id: '082b9c50-2b88-4e82-b451-27d4a9974f20',
            name: 'Adaptation de la consommation d`énergie',
            displayName: "Adaptation de la consommation d'énergie",
            domainId: 'b94c2b51-5eca-48ac-826e-97595fc1aad9',
            domainName: 'Energie',
            domainDisplayName: 'Energie',
        },
        {
            id: '28605442-3033-49a6-9e89-8f464cb922be',
            name: 'Technologies de réduction de la consommation énergétique',
            displayName: 'Technologies de réduction de la consommation énergétique',
            domainId: 'b94c2b51-5eca-48ac-826e-97595fc1aad9',
            domainName: 'Energie',
            domainDisplayName: 'Energie',
        },
        {
            id: 'a4e69410-a647-4566-b200-7fe6440513e0',
            name: 'Eco conception',
            displayName: 'Ecoconception',
            domainId: '99f6ec97-bbd0-4ada-8868-40b6c343fc3c',
            domainName: 'Environnement',
            domainDisplayName: 'Environnement',
        },
        {
            id: 'b18b49c3-3fc0-4004-b77c-9bcfb03670e0',
            name: 'Gestion des déchets',
            displayName: 'Gestion et valorisation des déchets',
            domainId: '99f6ec97-bbd0-4ada-8868-40b6c343fc3c',
            domainName: 'Environnement',
            domainDisplayName: 'Environnement',
        },
        {
            id: 'dcec564b-0a2a-4fe2-b3af-d1ce6dd2eed4',
            name: 'Fin de vie du produit',
            displayName: 'Fin de vie du produit',
            domainId: '99f6ec97-bbd0-4ada-8868-40b6c343fc3c',
            domainName: 'Environnement',
            domainDisplayName: 'Environnement',
        },
        {
            id: '41791065-65a0-4aa9-98e3-91c33e31c862',
            name: 'Internet industriel',
            displayName: 'Internet industriel',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '96e7d037-3941-4deb-a4ba-2b088423437f',
            name: 'Réseaux industriels sans fil',
            displayName: 'Réseaux industriels sans fil',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '59f1de90-2d04-4798-8c16-1765f61b82d3',
            name: 'Réseaux industriels traditionnels',
            displayName: 'Réseaux industriels traditionnels',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'fac083e8-3e6c-495f-b46e-8e9e2de0980b',
            name: 'Réseaux d`entreprises et sites intégrés',
            displayName: "Réseaux d'entreprises et sites intégrés",
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '90f5dfe9-5183-4a2b-a04c-247bf8d4d393',
            name: 'Fonctionnalisation de surface',
            displayName: 'Fonctionnalisation de surface',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'e288baaf-71eb-4ed7-ac89-98476462ae2d',
            name: 'Formage et usinage innovants ou optimisés',
            displayName: 'Formage et usinage innovants ou optimisés',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'b21fcb47-1a0a-4132-886b-031523419db6',
            name: 'Microfabrication',
            displayName: 'Microfabrication',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'c5abc58e-edf3-4c39-a527-66c827c4a105',
            name: 'Procédés Near Net Shape',
            displayName: 'Procédés Near Net Shape',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'b00dd9a3-fb8a-486c-8201-ab9e1188c566',
            name: 'Technologies de soudage à hautes performances',
            displayName: 'Technologies de soudage à hautes performances',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '1f978ccd-142b-4b72-9530-60072f2309c8',
            name: 'Assemblage innovant',
            displayName: 'Assemblage innovant',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '7038cd94-5aa4-4e19-a44d-31d6816ac1b0',
            name: 'Procédés propres',
            displayName: 'Procédés propres',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'f5a346ab-0d34-477c-a625-f75935866c04',
            name: 'Fiabilité des systèmes mécatroniques',
            displayName: 'Fiabilité des systèmes mécatroniques',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '6f41121d-97d8-4a0c-ba9f-705afb6e424c',
            name: 'Machines intelligentes',
            displayName: 'Machines intelligentes',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '53890c19-bee4-4079-b235-8867a33c3af3',
            name: 'Machines programmables innovantes ou optimisées',
            displayName: 'Machines programmables innovantes ou optimisées',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'c785fab3-14a3-432b-b80b-f7cb83a6d583',
            name: 'Composants intelligents',
            displayName: 'Composants intelligents',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'e141fff3-d48a-41e7-8be7-2cd8d678352f',
            name: 'Surveillance à distance',
            displayName: 'Surveillance à distance',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'c52554eb-cc17-4a25-972e-c8133ac9b91e',
            name: 'Mesure et analyse de données',
            displayName: 'Mesure et analyse de données',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '86f9d412-cfae-4edd-a023-2af22fee46f1',
            name: 'Optimisation de la maintenance, maintenance prédictive',
            displayName: 'Optimisation de la maintenance, maintenance prédictive',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'e35ae082-da98-4ce9-a119-8f730866cb61',
            name: 'Systèmes numériques de contrôle commande',
            displayName: 'Systèmes numériques de contrôle commande',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '14f54b9d-63ae-43fa-a770-6c165d110701',
            name: 'Communication et agilité des machines',
            displayName: 'Communication et agilité des machines',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '013aa667-43b6-4fc2-9041-1088e2eaabfb',
            name: 'Environnement ouvert',
            displayName: 'Environnement ouvert',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '557787a3-c2bf-4055-9413-41572e521c54',
            name: 'Intégration du facteur humain',
            displayName: 'Intégration du facteur humain',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'ff789ce6-b3c7-41f0-981d-f923ac87c96e',
            name: 'Assistance au gestes et dépénibilisation',
            displayName: 'Assistance au gestes et dépénibilisation',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '7715586f-bf70-44f7-aefd-f700d31b3089',
            name: 'Conception et qualification virtuelle des systèmes de production',
            displayName: 'Conception et qualification virtuelle des systèmes de production',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'a5e3784b-c8dd-48b3-b10f-5b2b76fbd682',
            name: 'CND innovants',
            displayName: 'CND innovants',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '3d643eb6-a3b3-451d-9b67-83267dd9226c',
            name: 'Préparation du travail en temps réel à la demande',
            displayName: 'Préparation du travail en temps réel à la demande',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '545cecd6-131a-4e6c-b73d-2e9349d0a91f',
            name: 'Gestion et pilotage de la production',
            displayName: 'Gestion et pilotage de la production',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '5b371784-eafa-4269-ab09-80cc47e1696e',
            name: 'Maquette numérique de l`usine',
            displayName: "Maquette numérique de l'usine",
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '057f07d9-55e8-4baa-b060-0b175a92e08b',
            name: 'Conception et simulation du produit',
            displayName: 'Conception et simulation du produit',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '887b2167-a144-48db-a9c4-c66b3d055310',
            name: 'Infrastructure de simulation',
            displayName: 'Infrastructure de simulation',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '555555e5-babb-4c41-85b6-a9726119ae75',
            name: 'Automatisme',
            displayName: 'Automatisation, transitique, robotique de process',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: 'b09c24dd-3e96-457a-b679-e9ed5bf3b287',
            name: 'Qualité',
            displayName: 'Gestion de la qualité produit',
            domainId: '4e525ad8-74d7-4271-a7c5-206d98eca428',
            domainName: 'Industrie',
            domainDisplayName: 'Industrie',
        },
        {
            id: '26236553-d8e0-47a4-9956-63691de15b94',
            name: 'Big Data',
            displayName: 'Cloud et big data',
            domainId: '785294e5-0e8a-41d7-93f0-794059683acd',
            domainName: 'Informatique',
            domainDisplayName: 'Informatique',
        },
        {
            id: 'd722b9e3-e2f5-4776-9534-92e18b818a16',
            name: 'Cybersecurité',
            displayName: 'Cybersecurité',
            domainId: '785294e5-0e8a-41d7-93f0-794059683acd',
            domainName: 'Informatique',
            domainDisplayName: 'Informatique',
        },
        {
            id: '74f3fd74-241a-4c06-b8ac-4527ebb7145a',
            name: 'Applications industrielles nomades',
            displayName: 'Applications industrielles nomades',
            domainId: '785294e5-0e8a-41d7-93f0-794059683acd',
            domainName: 'Informatique',
            domainDisplayName: 'Informatique',
        },
        {
            id: '9c40c7ef-84a7-4546-8f09-f9090afe061a',
            name: 'Logiciels de simulation de procédés',
            displayName: 'Logiciels de simulation de procédés',
            domainId: '785294e5-0e8a-41d7-93f0-794059683acd',
            domainName: 'Informatique',
            domainDisplayName: 'Informatique',
        },
        {
            id: '00c75431-69a7-4367-aefd-b8f464730e39',
            name: 'Infrastructures Big Data et calculs distribués',
            displayName: 'Infrastructures Big Data et calculs distribués',
            domainId: '785294e5-0e8a-41d7-93f0-794059683acd',
            domainName: 'Informatique',
            domainDisplayName: 'Informatique',
        },
        {
            id: 'bfec495b-422e-4564-aede-81ad178253fb',
            name: 'Électronique',
            displayName: 'Électronique, sous-systèmes et composants',
            domainId: '2339fa95-3401-4efa-8af3-0bfe0500bec5',
            domainName: 'Ingénierie et R&D',
            domainDisplayName: 'Ingénierie et R&D',
        },
        {
            id: '2d46b4f7-f7bd-4adc-a64d-ca476d21aabe',
            name: 'Impression 3D',
            displayName: 'Fabrication additive, impression 3D',
            domainId: '2339fa95-3401-4efa-8af3-0bfe0500bec5',
            domainName: 'Ingénierie et R&D',
            domainDisplayName: 'Ingénierie et R&D',
        },
        {
            id: '287aa8db-c39a-4e22-9e53-7ec4faf81daf',
            name: 'Matériaux intelligents et adaptronique, matériaux fonctionnels',
            displayName: 'Matériaux intelligents et adaptronique, matériaux fonctionnels',
            domainId: '2339fa95-3401-4efa-8af3-0bfe0500bec5',
            domainName: 'Ingénierie et R&D',
            domainDisplayName: 'Ingénierie et R&D',
        },
        {
            id: '09d90d84-6318-4617-b812-b2dd9c6bc1dc',
            name: 'Matériaux non métalliques et fluides biosourcés',
            displayName: 'Matériaux non métalliques et fluides biosourcés',
            domainId: '2339fa95-3401-4efa-8af3-0bfe0500bec5',
            domainName: 'Ingénierie et R&D',
            domainDisplayName: 'Ingénierie et R&D',
        },
        {
            id: '84507f1b-386d-4d75-ad0c-792e9915df3b',
            name: 'Composites à fort volumes',
            displayName: 'Composites à fort volumes',
            domainId: '2339fa95-3401-4efa-8af3-0bfe0500bec5',
            domainName: 'Ingénierie et R&D',
            domainDisplayName: 'Ingénierie et R&D',
        },
        {
            id: '1a2ece87-d80b-4435-abd8-5ec23064bb13',
            name: 'Acier à très haute performance, alliages et superalliages',
            displayName: 'Acier à très haute performance, alliages et superalliages',
            domainId: '2339fa95-3401-4efa-8af3-0bfe0500bec5',
            domainName: 'Ingénierie et R&D',
            domainDisplayName: 'Ingénierie et R&D',
        },
        {
            id: '8d596bf3-9ac2-4841-a1e6-df233571fc32',
            name: 'Cobotique et  exosquelettes',
            displayName: 'Cobotique et  exosquelettes',
            domainId: '2339fa95-3401-4efa-8af3-0bfe0500bec5',
            domainName: 'Ingénierie et R&D',
            domainDisplayName: 'Ingénierie et R&D',
        },
        {
            id: '3f4ba977-402b-4ccf-9c07-a41e1a62ed34',
            name: 'Réalité virtuelle',
            displayName: 'Réalité virtuelle',
            domainId: '2339fa95-3401-4efa-8af3-0bfe0500bec5',
            domainName: 'Ingénierie et R&D',
            domainDisplayName: 'Ingénierie et R&D',
        },
        {
            id: 'c1d50acd-a2eb-4ed8-9da6-49aab37b0b8f',
            name: 'Réalité augmentée',
            displayName: 'Réalité augmentée',
            domainId: '2339fa95-3401-4efa-8af3-0bfe0500bec5',
            domainName: 'Ingénierie et R&D',
            domainDisplayName: 'Ingénierie et R&D',
        },
        {
            id: '6a98e66e-ed02-4b92-a72b-825e03d5220d',
            name: 'Digitalisation de la supply chain',
            displayName: 'Digitalisation de la supply chain',
            domainId: 'd857a9ad-bf9d-49be-9621-b7bca7a6c633',
            domainName: 'Logistique',
            domainDisplayName: 'Logistique',
        },
        {
            id: '03d929df-7ec3-4c8f-ac97-329f05650919',
            name: 'Ruptures marketing et ruptures stratégiques',
            displayName: 'Ruptures marketing et ruptures stratégiques',
            domainId: '505f9bef-ee12-4006-b08e-6899a6b8bdf0',
            domainName: 'Marketing',
            domainDisplayName: 'Marketing',
        },
        {
            id: 'e72e9362-7f3e-4553-a493-9e03ac214546',
            name: 'Nouveaux outils de gestion des connaissances et des compétences',
            displayName: 'Nouveaux outils de gestion des connaissances et des compétences',
            domainId: '745b6d14-a204-4b5f-991f-d8782e298aa1',
            domainName: 'RH',
            domainDisplayName: 'RH',
        },
    ],
    microservices: {
        restrictionAccessApiKey: 'siv4Pp8u1Z40HewBbJR3DmrNHYNAXZP7IoKFHZVuJwA=',
        identity: {
            baseUrl: 'https://api-identity-staging.wudo.io',
            params: {
                id: '###id###',
            },
            usersApi: {
                baseUrl: 'api/v1/Users',
            },
            usersBatchInsertApi: {
                baseUrl: 'api/v1/batch/users/BatchInsert',
            },
            organizationsBatchInsertApi: {
                baseUrl: 'api/v1/batch/organizations/BatchInsert',
            },
            organizationsApi: {
                baseUrl: 'api/v1/Organizations',
                editMinimalInfos: 'minimal-infos',
                filters: 'Filters',
                getOrganizationsByIds: 'Some',
                updateManyOrganizationsProperty: 'Batch-Update-Property',
            },
            organizationTypesApi: {
                baseUrl: 'api/v1/OrganizationTypes',
            },
            userTypesApi: {
                baseUrl: 'api/v1/UserTypes',
            },
            profilesApi: {
                baseUrl: 'api/v1/Profiles',
                me: 'Me',
                basicProfile: 'Basic',
                multipleProfiles: 'Batch',
                communitiesMembers: 'MyCommunities',
                resetPassword: 'Password/Reset',
                completeProfile: 'Complete',
                pwaStatus: 'pwaStatus',
                profilePicture: 'Picture',
            },
            communitiesApi: {
                baseUrl: 'api/v1/Communities',
                compute: 'Compute',
                users: 'Users',
                organizations: 'Organizations',
            },
            backOfficeApi: {
                baseUrl: 'api/v1/community',
                members: 'Member',
            },
            communityGroupsApi: {
                baseUrl: 'api/v1/CommunityGroups',
            },
            geographicalAreasApi: {
                baseUrl: 'api/v1/GeographicalAreas',
            },
            businessAreasApi: {
                baseUrl: 'api/v1/BusinessAreas',
            },
            countriesApi: {
                baseUrl: 'api/v1/Countries',
            },
            accountsApi: {
                baseUrl: 'api/v1/Accounts',
                login: 'Authenticate',
                oAuth2Login: 'OAuth2Authenticate',
                samlLogin: 'SamlAuthenticate',
                refreshToken: 'RefreshToken',
                logout: 'Logout',
                checkUserAccount: 'CheckUserAccount',
                confirmEmail: 'ConfirmEmail',
                renewConfirmEmailUrl: 'RenewConfirmEmail',
                accountActivationFollowUpUrl: 'AccountActivationFollowUp',
            },
            projectsApi: {
                baseUrl: 'api/v1/Projects',
            },
            addressApi: {
                baseUrl: 'api/v1/Addresses',
                search: 'Search',
            },
            azureMapsApi: {
                baseUrl: 'api/v1/AzureMaps',
                cities: 'cities',
                countries: 'countries',
                addresses: 'addresses',
            },
        },
        documents: {
            baseUrl: 'https://api-clubs-staging.wudo.io',
            params: {
                id: '###id###',
            },
            metaDatasApi: {
                baseUrl: 'api/v1/Metadatas',
                getMetadatas: 'getMetaDatas',
            },
        },
        skills: {
            params: {
                id: '###id###',
            },
            skillsApi: {
                baseUrl: 'api/v1/Skills',
            },
            skillDomainsApi: {
                baseUrl: 'api/v1/SkillDomains',
            },
            interestsApi: {
                baseUrl: 'api/v1/Interests',
            },
            interestDomainsApi: {
                baseUrl: 'api/v1/InterestDomains',
            },
        },
        projects: {
            baseUrl: 'https://api-projects-staging.wudo.io',
            params: {
                id: '###id###',
            },
            projectsApi: {
                baseUrl: 'api/v1/Projects',
                draft: 'Drafts',
                unpublished: 'Unpublished',
                published: 'Published',
                finished: 'Finished',
                filters: 'Filters',
                userProjects: 'api/v1/Users/###id###/Projects',
                myProjects: 'api/v1/Me/Projects',
                involved: 'Involved',
                publish: 'Publish',
                close: 'Close',
                wording: 'Wording',
            },
            projectTypesApi: {
                baseUrl: 'api/v1/ProjectTypes',
            },
            eventsApi: {
                baseUrl: 'api/v1/Events',
                myEventsBaseUrl: 'api/v1/Me/Events',
                exist: 'exist',
                filters: 'filters',
                close: 'close',
            },
            publicProjectsApi: {
                baseUrl: 'api/v1/public/projects',
            },
        },
        notifications: {
            baseUrl: 'https://api-notifications-staging.wudo.io',
            recipientApi: {
                baseUrl: 'api/v1/Recipients',
            },
        },
        clubs: {
            settings: {
                publicationsPageSize: 5,
                commentsPageSize: 50,
                previewCommentsPageSize: 2,
            },
            baseUrl: 'https://api-clubs-staging.wudo.io',
            clubsApi: {
                apiVersion: 'api/v1',
                baseUrl: 'api/v1/Clubs',
                addProfilePicture: 'Picture/Profile',
                addCoverPicture: 'Picture/Cover',
                getPublicationsByClubId: 'Posts',
                getClubPublicationsByPages: 'PostsByPages',
                getResourcesByClubId: 'Resources',
                getNewActivity: 'new-activity',
                members: 'Members',
                rooms: 'Rooms',
            },
            publicationApi: {
                baseUrl: 'api/v1/Posts',
                createMultiplePosts: 'createPosts',
                getPublicationsByParentId: 'Parent',
                getPublicationCommentsByPages: 'PublicationCommentsByPages',
                getPublicationReactions: 'Reactions',
                repostAsAnnouncement: 'RepostPostAsAnnouncement',
            },
            commentApi: {
                baseUrl: 'api/v1/Comments',
            },
            reactionTypeApi: {
                baseUrl: 'api/v1/ReactionTypes',
            },
            memberApi: {
                baseUrl: 'api/v1/Members',
                removeRole: 'RemoveRole',
                addRole: 'AddRole',
            },
            resourceApi: {
                baseUrl: 'api/v1/Resources',
                folders: 'Folders',
            },
            clubRoomApi: {
                baseUrl: 'api/v1/Rooms',
            },
        },
        realTime: {
            settings: {},
            baseUrl: 'https://api-realtime-staging.wudo.io',
            postHub: { baseUrl: 'PostHub' },
        },
        dms: {
            baseUrl: 'https://api-dms-staging.wudo.io',
        },
        analytics: {
            baseUrl: 'https://api-events-analytics-staging.wudo.io',
            identityStats: {
                baseUrl: 'api/v1/IdentityStats',
            },
            clubStats: {
                baseUrl: 'api/v1/ClubsStats',
            },
            publications: {
                baseUrl: 'api/v1/Publications',
            },
        },
    },
    scoopItTopics: [
        {
            idTopic: 11706682,
            isPublic: true,
            seePublicTopics: true,
            endpointTopic: 'wudo-industry-4-0',
            nameTopic: '[Wudo] Industry 4.0',
            idCommunautes: [
                {
                    id: '85718ce7-cc44-4cb4-ada6-6891ca6e5959',
                    default: true,
                },
                {
                    id: '25cccc7d-9797-4c5d-923d-26f0c8955ad4',
                    default: true,
                },
                {
                    id: 'd4f3ec28-6b2a-4427-a04b-a4a3d0a426ed',
                    default: true,
                },
                {
                    id: '0f7656c4-12a4-4187-bba4-eabb26290ace',
                    default: false,
                },
                {
                    id: '4722b4b6-6364-4669-98ec-39a42fb961e5',
                    default: true,
                },
                {
                    id: '6120e4bf-78b6-4f79-a472-b2e4affafb8c',
                    default: false,
                },
                {
                    id: '32ad5be3-d780-48e0-bbca-26de18a6d75d',
                    default: true,
                },
                {
                    id: '073fbf49-2afd-4447-97f3-a1689c012419',
                    default: true,
                },
                {
                    id: 'bcbe1c6b-d26e-46de-a6bd-e8614dd16cc6',
                    default: true,
                },
                {
                    id: '72cba922-fa4d-422d-847a-e608f1864775',
                    default: false,
                },
                {
                    id: '68ff1b6a-84d1-46d3-b1f3-57e575c6335d',
                    default: true,
                },
                {
                    id: '72b8e650-2f79-4700-8b96-17d483b3b31a',
                    default: true,
                },
                {
                    id: '42355def-9661-4aed-a760-0618931e8001',
                    default: true,
                },
            ],
            selected: false,
        },
        {
            idTopic: 2191065,
            seePublicTopics: true,
            isPublic: true,
            endpointTopic: 'wudo-corporate-transformation',
            nameTopic: '[Wudo] Corporate Transformation',
            idCommunautes: [
                {
                    id: '32fb3ceb-fa64-471e-8ee5-c9d8e4299cf7',
                    default: true,
                },
                {
                    id: '11fc8e5b-0b1d-4715-a65d-f4557e581d51',
                    default: false,
                },
                {
                    id: '25cccc7d-9797-4c5d-923d-26f0c8955ad4',
                    default: false,
                },
                {
                    id: 'd4f3ec28-6b2a-4427-a04b-a4a3d0a426ed',
                    default: false,
                },
                {
                    id: '0f7656c4-12a4-4187-bba4-eabb26290ace',
                    default: false,
                },
                {
                    id: '4722b4b6-6364-4669-98ec-39a42fb961e5',
                    default: false,
                },
                {
                    id: '72cba922-fa4d-422d-847a-e608f1864775',
                    default: false,
                },
            ],
            selected: false,
        },
        {
            idTopic: 13184960,
            isPublic: false,
            seePublicTopics: true,
            endpointTopic: 'communaute-hydrogene-bfc',
            nameTopic: 'PVF - Filière Hydrogène',
            idCommunautes: [
                {
                    id: '1ef6ef50-64ff-40e2-b334-d837e9993ffb',
                    default: true,
                },
                {
                    id: '09e8a437-5ae1-4e79-aa6d-b0ec6b4e44b3',
                    default: true,
                },
            ],
            selected: false,
        },
        {
            idTopic: 11784805,
            isPublic: true,
            seePublicTopics: true,
            endpointTopic: 'wudo-corporate-social-responsibility',
            nameTopic: '[Wudo] Corporate Social Responsibility',
            idCommunautes: [
                {
                    id: '25cccc7d-9797-4c5d-923d-26f0c8955ad4',
                    default: false,
                },
                {
                    id: 'd4f3ec28-6b2a-4427-a04b-a4a3d0a426ed',
                    default: false,
                },
                {
                    id: '0f7656c4-12a4-4187-bba4-eabb26290ace',
                    default: false,
                },
                {
                    id: '4722b4b6-6364-4669-98ec-39a42fb961e5',
                    default: false,
                },
                {
                    id: '72cba922-fa4d-422d-847a-e608f1864775',
                    default: false,
                },
            ],
            selected: false,
        },
        {
            idTopic: 13716507,
            isPublic: false,
            seePublicTopics: false,
            endpointTopic: 'cotisants-cetim',
            nameTopic: 'Univers CETIM',
            idCommunautes: [
                {
                    id: '0b414740-6c3d-44e8-84f2-5b3bd9f8762a',
                    default: true,
                },
                {
                    id: '25cccc7d-9797-4c5d-923d-26f0c8955ad4',
                    default: false,
                },
                {
                    id: 'd4f3ec28-6b2a-4427-a04b-a4a3d0a426ed',
                    default: false,
                },
            ],
            selected: false,
        },
        {
            idTopic: 13819933,
            isPublic: false,
            seePublicTopics: true,
            endpointTopic: 'actualites-de-la-communaute-des-acteurs-economiques-du-nord-franche-comte',
            nameTopic: 'Actualités de la Communauté des acteurs économiques du Nord Franche-Comté',
            idCommunautes: [
                {
                    id: '9c96329c-a97a-4161-bce5-6a1bdc804320',
                    default: true,
                },
                {
                    id: 'ec57622f-b52a-4e68-bab8-31b6c2174af9',
                    default: true,
                },
                {
                    id: '7a617d67-1bff-492b-bd00-7557be90ac11',
                    default: true,
                },
            ],
            selected: false,
        },
        {
            idTopic: 13827249,
            isPublic: false,
            seePublicTopics: true,
            endpointTopic: 'res-hauts-de-france-business',
            nameTopic: "Rés'Hauts-de-France Business",
            idCommunautes: [
                {
                    id: '826b4bf4-9abb-465e-aa39-ae7fed82c698',
                    default: true,
                },
                {
                    id: '231a5629-377c-441e-b9c9-f3a4a95c2193',
                    default: true,
                },
                {
                    id: '0c0063cd-e3e9-45e7-b370-71600cd491d1',
                    default: true,
                },
                {
                    id: '0f7656c4-12a4-4187-bba4-eabb26290ace',
                    default: true,
                },
                {
                    id: '5c65d957-0789-456e-bfa0-421e43d4099e',
                    default: true,
                },
                {
                    id: 'ba2e17f3-e5a4-4233-a4d4-fa4fd44d7abb',
                    default: true,
                },
                {
                    id: '556211ec-b259-4d7e-beae-126d08533716',
                    default: true,
                },
                {
                    id: '0252b380-f6fa-44e9-8206-8c772a13cffd',
                    default: true,
                },
                {
                    id: 'b2aec1b6-a613-4211-b99c-b62586cca600',
                    default: true,
                },
                {
                    id: 'b49eb4fb-1e4d-408e-a065-0a40ec81275c',
                    default: true,
                },
                {
                    id: '2167b699-2240-4646-97df-49f405743db6',
                    default: true,
                },
                {
                    id: '90e9ff64-b2d7-47d8-8fdb-2c81fe56ef04',
                    default: true,
                },
                {
                    id: 'b83e4b09-0b17-4498-bfd7-e562f4fc56d3',
                    default: true,
                },
                {
                    id: '275c7c31-f52b-41ad-9080-8f933ede1ac1',
                    default: true,
                },
                {
                    id: '7e97a35c-aca5-4a86-a971-fc9bf38bfd0c',
                    default: true,
                },
                {
                    id: 'eff40885-969e-477d-a722-768f8e5344db',
                    default: true,
                },
                {
                    id: '3caf3fab-71c2-4796-a378-e321a81d124f',
                    default: true,
                },
                {
                    id: '15cb2acf-3955-4213-bc91-ac1778c49edb',
                    default: true,
                },
            ],
            selected: false,
        },
        {
            idTopic: 14885021,
            isPublic: false,
            seePublicTopics: true,
            endpointTopic: 'Materalia',
            nameTopic: 'MATERALIA',
            idCommunautes: [
                {
                    id: '53ac4f49-65d7-41fd-90c2-f8f12a0c3e62',
                    default: true,
                },
            ],
            selected: false,
        },
        {
            idTopic: 14889174,
            isPublic: false,
            seePublicTopics: false,
            endpointTopic: 'Réseau collaboratif des élus',
            nameTopic: 'Réseau collaboratif des élus',
            idCommunautes: [
                {
                    id: '67af23d9-a322-482f-9899-19de7e15b7e9',
                    default: true,
                },
                {
                    id: 'ec382e43-136a-47f8-bbf7-75380c9ab92f',
                    default: true,
                },
            ],
            selected: false,
        },
        {
            idTopic: 15123056,
            isPublic: false,
            seePublicTopics: true,
            endpointTopic: 'uimm',
            nameTopic: "L'industrie connecte",
            idCommunautes: [
                {
                    id: 'a8923155-341b-4d7d-8a1d-00175f3e4d92',
                    default: true,
                },
                {
                    id: '8d43f060-6ebd-4b13-a2cb-64514dd4a09e',
                    default: true,
                },
                {
                    id: '01daa437-2646-454c-b3b9-e3f5323d267e',
                    default: true,
                },
                {
                    id: 'e288a40b-0343-4cac-877f-db01bc091da1',
                    default: true,
                },
                {
                    id: 'cba0eb0d-20c3-4e7c-ac77-06e1a0d08720',
                    default: true,
                },
                {
                    id: 'ffee24d0-7943-416f-b87c-89c0d2fec78e',
                    default: true,
                },
                {
                    id: '52f96f5c-af64-4ad1-999f-c6cb42c3193f',
                    default: true,
                },
                {
                    id: 'c23fd574-6ab8-4c65-8631-3784c2d752f8',
                    default: true,
                },
                {
                    id: 'ae1dc615-af37-42ee-aca9-ffed3f694c52',
                    default: true,
                },
                {
                    id: 'c28258b2-7c53-475d-a2aa-58c8005fca9c',
                    default: true,
                },
            ],
            selected: false,
        },
        {
            idTopic: 15541594,
            isPublic: false,
            seePublicTopics: false,
            endpointTopic: 'Infos Autonomie & Inclusion',
            nameTopic: 'Infos Services à la personne et Bien-être',
            idCommunautes: [
                {
                    id: '64a86db9-7d8f-46b8-9e64-398641945aab',
                    default: true,
                },
                {
                    id: '6f788dec-8c8b-4ddc-8677-3cb44053db9a',
                    default: true,
                },
                {
                    id: '96beb1e3-8cbe-4b59-8e98-dddd88f9f1e2',
                    default: true,
                },
            ],
            selected: false,
        },
        {
            idTopic: 15753338,
            endpointTopic: 'animation-communautaire',
            seePublicTopics: true,
            isPublic: true,
            nameTopic: '[Wudo] Animation communautaire',
            idCommunautes: [{ id: 'b9dfd27c-da8f-48ae-8d5e-69eb28ce5aeb', default: true }],
            selected: false, //Laisser false
        },
        {
            idTopic: 16724320,
            isPublic: false,
            seePublicTopics: false,
            endpointTopic: 'DELFINGEN News',
            nameTopic: 'DELFINGEN News',
            idCommunautes: [{ id: '72cba922-fa4d-422d-847a-e608f1864775', default: true }],
            selected: false, //Laisser false
        },
        {
            idTopic: 16724378,
            isPublic: false,
            seePublicTopics: false,
            endpointTopic: 'Compétences et véhicules du futur',
            nameTopic: 'Compétences et véhicules du futur',
            idCommunautes: [
                {
                    id: '6120e4bf-78b6-4f79-a472-b2e4affafb8c',
                    default: true,
                },
                {
                    id: '48f8638e-8ec3-4c23-8ede-bfbca6e56d34',
                    default: true,
                },
                {
                    id: '8b418e52-829f-4f45-91e8-6ea22dcc4c33',
                    default: true,
                },
            ],
            selected: false, //Laisser false
        },
        {
            idTopic: 16724382,
            isPublic: false,
            seePublicTopics: false,
            endpointTopic: 'ikigai-hrt',
            nameTopic: 'IKIGAÏ',
            idCommunautes: [{ id: 'dd1095f6-8065-49b5-a108-ae2fd5bc9284', default: true }],
            selected: false, //Laisser false
        },
        {
            idTopic: 13178169,
            isPublic: false,
            seePublicTopics: false,
            endpointTopic: 'nuclear-valley',
            nameTopic: 'Nuclear Valley',
            idCommunautes: [
                {
                    id: 'ed4b7861-19de-4784-a290-7979c45e7c7b',
                    default: true,
                },
            ],
            selected: false,
        },
    ],
    scoopItAdminUrl: 'https://wudo.scoop.it/',
    dictionary: {
        // Minuscule
        minProjet: 'projet',
        minProjets: 'projets',
        minAppelCTBT: '',
        minAppelsCTBT: '',
        minCeProjet: 'ce projet',
        minDeProjet: 'de projet',
        minDeProjets: 'de projets',
        minDesProjets: 'des projets',
        minDuProjet: 'du projet',
        minLeProjet: 'le projet',
        minAuProjet: 'au projet',
        minCeBeauProjet: 'ce beau projet',
        minNouveauProjet: 'nouveau projet',

        // Majuscule
        majProjet: 'Projet',
        majProjets: 'Projets',
        majAppelCTBT: '',
        majAppelsCTBT: '',
        majCeProjet: 'Ce projet',
        majDeProjet: 'De projet',
        majDuProjet: 'Du projet',
        majLeProjet: 'Le projet',
        majAuProjet: 'Au projet',
        majCeBeauProjet: 'Ce beau projet',
        majNouveauProjet: 'Nouveau projet',
    },
    wordingPerCommunity: {
        '25cccc7d-9797-4c5d-923d-26f0c8955ad4': 'fr_BFF',
        '0b414740-6c3d-44e8-84f2-5b3bd9f8762a': 'fr_CETIM',
        'a8923155-341b-4d7d-8a1d-00175f3e4d92': 'fr_UIMM',
        '8d43f060-6ebd-4b13-a2cb-64514dd4a09e': 'fr_UIMM',
        '01daa437-2646-454c-b3b9-e3f5323d267e': 'fr_UIMM',
        'e288a40b-0343-4cac-877f-db01bc091da1': 'fr_UIMM',
        'cba0eb0d-20c3-4e7c-ac77-06e1a0d08720': 'fr_UIMM',
        'ffee24d0-7943-416f-b87c-89c0d2fec78e': 'fr_UIMM',
        '52f96f5c-af64-4ad1-999f-c6cb42c3193f': 'fr_UIMM',
        '0359775d-2094-4f50-b349-8b65d9cf6923': 'fr_UIMM',
        '5c0f5910-5adf-482f-8211-d7124ca443e5': 'fr_UIMM',
        'ad8067d5-00bb-47d8-8768-5abbe760a88f': 'fr_UIMM',
        '06ece0ab-e154-4703-916a-bd9e5c352f53': 'fr_UIMM',
        'c23fd574-6ab8-4c65-8631-3784c2d752f8': 'fr_UIMM',
        '352c50fa-7664-4414-9d37-4a4e83f9635a': 'fr_UIMM',
        'd3b6034f-d4ee-42f7-ae39-4195e4234c43': 'fr_UIMM',
        '89b6d6cb-4791-48e1-b8df-6debe5020584': 'en_WUDO',
        'ed4b7861-19de-4784-a290-7979c45e7c7b': 'fr_NV',
        default: 'fr_WUDO',
    } as Record<string, string>,
    memberImportFileUrl: "https://wudosaplatforms.blob.core.windows.net/$root/Modèle d'import de membres WuDo.csv",
};
