import { PrimengDropdownItem } from '../../../shared/interfaces/primeng-dropdown-item.interface';

interface CsvColumnInformation {
    valid: boolean;
    title: string;
    sample: string[];
    selectedWudoProperty: PrimengDropdownItem;
    availableWudoProperties: PrimengDropdownItem[];
    skipped: boolean;
}

export class ColumnMatchingData {
    csvColumnInformation: CsvColumnInformation[];

    wudoProperties: string[];

    csvRecords: unknown[];

    public constructor(object?: Partial<ColumnMatchingData>) {
        Object.assign(this, object);
    }
}
