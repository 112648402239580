<div *ngIf="visible" class="fullscreen-dialog-container">
    <div class="fullscreen-dialog-header">
        <div class="button-container">
            <button
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-text p-button-plain p-button-lg"
                (click)="close($event)"
            ></button>
        </div>
    </div>
    <div class="fullscreen-dialog-body">
        <ng-content select="[body]"></ng-content>
    </div>
    <div class="fullscreen-dialog-footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
