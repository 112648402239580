export const organizationSortModes = {
    organizationName: 'organizationName',
};

export const organizationManageCommunitySortModes = {
    organizationName: 'organizationName',
    name: 'name',
    address: 'address1',
    postalCode: 'postalCode',
    contact: 'email',
    phoneNumber: 'phoneNumber',
    city: 'city',
    webSite: 'webSite',
    active: 'active',
};
