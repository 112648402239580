import { messages } from './../../../../constants/messages';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { CLUB_PRIVACIES } from './../../../../models/clubs/constants/club-privacies';
import { CLUB_VISIBILITIES } from './../../../../models/clubs/constants/club-visibilities';
import { Club, DESCRIPTION_MAX_LENGTH, CLUB_NAME_MAX_LENGTH } from './../../../../models/clubs/club.model';
import { User } from '../../../../models/identity/user.model';
import { ClubsStore } from '../../../clubs.store';
import { FormBuilder, Validators } from '@angular/forms';
import { SpinnerHelper } from '../../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../../helpers/toastr.helper';
import { AccountsService } from '../../../../services/identity/accounts.service';
import { ClubRoomsService } from '../../../../services/clubs/clubRooms.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'create-room-modal-button',
    styleUrls: ['./create-room-modal-button.component.scss'],
    templateUrl: './create-room-modal-button.component.html',
})
export class CreateRoomButtonModal implements OnInit {
    @Input() isEditMode: boolean;
    @Input() club: Club;

    @Output()
    shouldUpdateRooms = new EventEmitter();

    messages = messages;
    CLUB_VISIBILITIES = CLUB_VISIBILITIES;
    CLUB_NAME_MAX_LENGTH = CLUB_NAME_MAX_LENGTH;
    DESCRIPTION_MAX_LENGTH = DESCRIPTION_MAX_LENGTH;

    currentUser: User;
    action: string;
    isModalDisplayed: boolean;
    visibilityChangedToHidden: boolean = false;
    isTitleEditable = true;
    isVisibilityEditable = false;
    isPublicationSettingEditable = false;

    // form
    form = this.fb.group({
        title: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(CLUB_NAME_MAX_LENGTH)]],
        description: [{ value: '' }, [Validators.maxLength(DESCRIPTION_MAX_LENGTH)]],
        visibility: [{ value: 1, disabled: false }, [Validators.required]],
        isHidden: [false, [Validators.required]],
        publicationsAreLimited: [false, [Validators.required]],
        privacy: [1, [Validators.required]],
        parentClubId: [null, []],
        userId: [null, []],
        id: [null, []],
    });
    isHidden: boolean = false;
    publicationsAreLimited: boolean = false;

    // getters
    get headerTitle() {
        return this.isEditMode
            ? this.translate.instant('clubs.page.common.createRoomButton.roomParameters', { roomName: this.club.title })
            : this.form.controls.isHidden.value
            ? this.translate.instant('clubs.page.common.createRoomButton.createHiddenRoom')
            : this.translate.instant('clubs.page.common.createRoomButton.createRoom');
    }

    get visibilityMessage(): string {
        return this.form.controls.isHidden.value == true
            ? this.translate.instant('clubs.page.common.createRoomButton.visibilityEnabled')
            : this.translate.instant('clubs.page.common.createRoomButton.visibilityDisabled');
    }

    get limitPublicationsMessage(): string {
        return this.form.controls.publicationsAreLimited.value
            ? this.translate.instant('clubs.page.common.createRoomButton.clubLimitPublicationSettingEnabled')
            : this.translate.instant('clubs.page.common.createRoomButton.clubLimitPublicationSettingDisabled');
    }

    get isMainRoom(): boolean {
        return this.clubsStore.isMainRoom;
    }

    constructor(
        private readonly accountService: AccountsService,
        private readonly clubRoomsService: ClubRoomsService,
        private readonly toastrHelper: ToastrHelper,
        private readonly spinnerHelper: SpinnerHelper,
        private readonly fb: FormBuilder,
        private readonly clubsStore: ClubsStore,
        private confirmationService: ConfirmationService,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        this.currentUser = this.accountService.currentUser;
        this.form.controls.userId.setValue(this.currentUser.userId);
    }

    async handleOnSubmit() {
        if (
            !(
                this.clubsStore.isAdminOfDisplayedClub ||
                (this.isEditMode &&
                    (this.clubsStore.isAdminOfDisplayedClub || this.clubsStore.isAnimatorOfDisplayedRoom))
            )
        ) {
            const msg = this.translate.instant(
                'clubs.page.common.createRoomButton.toast.unauthorizedRoomSettingsAccess',
            );
            this.toastrHelper.showWarning(msg);
        }

        if (!this.isFormValid()) return;

        this.spinnerHelper.showSpinner();

        try {
            this.form.controls.parentClubId.setValue(this.clubsStore.displayedClubId);
            this.form.controls.privacy.setValue(
                this.form.controls.visibility.value == CLUB_VISIBILITIES.VISIBLE
                    ? CLUB_PRIVACIES.OPEN
                    : CLUB_PRIVACIES.PRIVATE,
            );

            this.club = await this.createOrUpdateRoomAsync();
            this.shouldUpdateRooms.emit(this.club);
            this.closeModal();
            this.spinnerHelper.hideSpinner();
        } catch (error) {}
    }

    public openModal() {
        if (
            !(
                this.clubsStore.isAdminOfDisplayedClub ||
                (this.isEditMode &&
                    (this.clubsStore.isAdminOfDisplayedClub || this.clubsStore.isAnimatorOfDisplayedRoom))
            )
        ) {
            const msg = this.translate.instant('clubs.page.common.createRoomButton.unauthorizedRoomSettingsAccess');
            this.toastrHelper.showWarning(msg);
            return;
        }

        if (this.isEditMode && this.club != null) {
            this.form.controls.title.setValue(this.club.title);
            this.form.controls.visibility.setValue(this.club.visibility);
            this.form.controls.isHidden.setValue(this.club.visibility == CLUB_VISIBILITIES.HIDDEN);
            this.form.controls.publicationsAreLimited.setValue(this.club.publicationsAreLimited);
            this.isHidden = this.club.visibility == CLUB_VISIBILITIES.HIDDEN;
            this.publicationsAreLimited = this.club.publicationsAreLimited;
        }

        this.isTitleEditable = !this.isEditMode || !this.isMainRoom;
        if (!this.isTitleEditable) this.form.controls.title.disable();
        this.isVisibilityEditable =
            !this.isEditMode ||
            (!this.isMainRoom &&
                this.club.visibility != CLUB_VISIBILITIES.HIDDEN &&
                this.clubsStore.isAdminOfDisplayedClub);
        if (!this.isVisibilityEditable) this.form.controls.visibility.disable();

        this.isModalDisplayed = true;
    }

    closeModal() {
        this.isModalDisplayed = false;
        //this.form.reset(); // TODO better?
        this.form.controls.title.setValue('');
        this.form.controls.publicationsAreLimited.setValue(false);
        this.publicationsAreLimited = false;
    }

    changeVisibilityValue() {
        this.form.controls.isHidden.setValue(!this.form.controls.isHidden.value);
        this.form.controls.visibility.setValue(
            this.form.controls.visibility.value == CLUB_VISIBILITIES.HIDDEN
                ? CLUB_VISIBILITIES.VISIBLE
                : CLUB_VISIBILITIES.HIDDEN,
        );
        this.visibilityChangedToHidden = this.form.controls.isHidden.value;
        this.form.controls.privacy.setValue(
            this.form.controls.privacy.value == CLUB_PRIVACIES.PRIVATE ? CLUB_PRIVACIES.OPEN : CLUB_PRIVACIES.PRIVATE,
        );
    }

    changePublicationSettingValue() {
        this.form.controls.publicationsAreLimited.setValue(!this.form.controls.publicationsAreLimited.value);
    }

    confirm(event: Event) {
        this.confirmationService.confirm({
            target: event.target,
            message: this.translate.instant('toastMessages.warningRoomHiddenVisibilityEnabled'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: this.translate.instant('components.button.buttonConfirm'),
            rejectLabel: this.translate.instant('components.button.buttonCancel'),
            accept: () => {
                this.handleOnSubmit();
            },
            reject: () => {},
        });
    }

    private isFormValid(): boolean {
        this.spinnerHelper.hideSpinner();

        if (this.form.invalid) {
            let warningMessages = [];

            if (this.form.controls.title.errors.required) {
                const msg = this.translate.instant('clubs.page.common.createRoomButton.roomNameRequired', {
                    maxLength: CLUB_NAME_MAX_LENGTH,
                });
                warningMessages.push(msg);
            }

            if (this.form.controls.title.errors.maxlength) {
                const msg = this.translate.instant('clubs.page.common.createRoomButton.roomTitleLength', {
                    maxLength: CLUB_NAME_MAX_LENGTH,
                });
                warningMessages.push(msg);
            }

            warningMessages.forEach((msg) => {
                this.toastrHelper.showWarning(msg);
            });
            return warningMessages.length == 0;
        }

        return true;
    }

    private async createOrUpdateRoomAsync() {
        let newRoom: Club;

        if (this.isEditMode) {
            this.form.controls.id.setValue(this.club.id);
            newRoom = await this.clubRoomsService.updateClubRoom(this.club.id, this.form.getRawValue()).toPromise();
            const msg = this.translate.instant('clubs.page.common.createRoomButton.toast.roomUpdated');
            this.toastrHelper.showSuccess(msg);
        } else {
            newRoom = await this.clubRoomsService.createClubRoom(this.form.getRawValue()).toPromise();
            const msg = this.translate.instant('clubs.page.common.createRoomButton.toast.roomCreated');
            this.toastrHelper.showSuccess(msg);
        }

        return newRoom;
    }
}
