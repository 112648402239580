import { Component, Input } from '@angular/core';

@Component({
    selector: 'point-marker',
    template: ` <div class="dot" [style.background-color]="color" [style.width.px]="width" [style.height.px]="width"></div> `,
    styles: [
        `
            .dot {
                border-radius: 50%;
            }
        `,
    ],
})
export class PointMarkerComponent {
    @Input() width: number = 15;
    @Input() color: string = 'var(--primary-color)';
}
