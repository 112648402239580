<div class="modal-header">
    <h5 class="modal-title">{{ 'onboarding.termsOfService' | translate }}</h5>
    <button type="button" class="close" (click)="decline()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <app-terms-of-use-content></app-terms-of-use-content>
</div>

<div class="modal-footer">
    <button pButton type="button" class="btn p-button-text" (click)="decline()">
        {{ 'components.button.buttonRejection' | translate }}
    </button>
    <button id="terms-of-use-validate" type="button" class="btn btn-primary" (click)="accept()">
        {{ 'components.button.buttonValidation' | translate }}
    </button>
</div>
