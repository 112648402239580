import { OAuth2IdentityProviders } from './../oauth2/constants/oauth2-identity-providers';

export class OAuth2AuthenticationCommand {
    identityProvider: OAuth2IdentityProviders;
    code: string;

    constructor(identityProvider: OAuth2IdentityProviders, code: string) {
        this.identityProvider = identityProvider;
        this.code = code;
    }
}
