import { AuthenticationResult } from './authentication-result.model';
import { User } from './user.model';

export class SamlAuthenticateRequest {
    authenticationResult: AuthenticationResult;

    constructor(authentication: string, user?: User) {
        const decodedAuthentication = atob(authentication);
        this.authenticationResult = JSON.parse(decodedAuthentication);

        this.authenticationResult.currentUser = user ?? new User();

        this.authenticationResult.currentUser.accessToken = this.authenticationResult.accessToken;
        this.authenticationResult.currentUser.refreshToken = this.authenticationResult.refreshToken;
    }
}
