import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-app-youtube-player-modal',
    templateUrl: './app-youtube-player-modal.component.html',
    styleUrls: ['./app-youtube-player-modal.component.scss'],
})
export class AppYoutubePlayerModalComponent implements OnInit {
    @Input() visible: false;
    @Input() link: string;

    @Output() public modalHidden: EventEmitter<void> = new EventEmitter<void>();

    videoSafeUrl: SafeResourceUrl;

    constructor(private sanitizer: DomSanitizer) {}
    ngOnInit(): void {
        this.setVideoSafeUrl();
    }

    setVideoSafeUrl() {
        const video_id = this.getVideoId();
        const embedUrl = `https://www.youtube.com/embed/${video_id}`;

        this.videoSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    }

    getVideoId() {
        const url = new URL(this.link);
        const path = url.pathname;

        if (url.hostname === 'youtu.be') {
            const segments = path.split('/');
            return segments[segments.length - 1];
        } else {
            return this.link.split('v=')[1].substring(0, 11);
        }
    }

    onHide() {
        this.modalHidden.emit();
    }
}
