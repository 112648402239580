
/**
 * Format a date in the 'yyyy-mm-dd HH:MM' format to a 'dd/mm/yyyy à HHhMM' string.
 * @param date The date in 'yyyy-mm-dd HH:MM' format.
 * @returns A string ready to display, in the 'dd/mm/yyyy à HHhMM' format.
 */
export function formatDate(date: string): string {
    const dateSplitted = date.split(" ")[0].split("-");
    const dateString = `${dateSplitted[2]}/${dateSplitted[1]}/${dateSplitted[0]}`;

    const timeSplitted = date.split(" ")[1].split(":");
    const timeString = `${timeSplitted[0]}h${timeSplitted[1]}`;

    return `${dateString} à ${timeString}`;
}

/**
 * Convert a date to a string in the 'yyyy-mm-dd' format.
 * @param date The date to convert.
 * @returns The string.
 */
export function getDateString(date: Date): string {
    // Months start from 0 not 1.
    const month = date.getMonth() + 1;
    return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${date.getDate() < 10 ? '0' : ''
        }${date.getDate()}`;
}
