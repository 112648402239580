import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { localStorageKeys } from './../../../constants/local-storage-keys';
import { OAuth2AuthenticationCommand } from './../../../models/identity/oauth2-authentication-command.model';
import { OAuth2IdentityProviders } from './../../../models/oauth2/constants/oauth2-identity-providers';
import { AccountsService } from './../../../services/identity/accounts.service';
import { BaseLoginComponent } from './../../base-login.component';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TokenVerifierService } from '../../../services/identity/token-verifier.service';
import { WordingService } from '../../../services/shared/wording.service';

@Component({
    selector: 'app-oauth2-redirect',
    templateUrl: './oauth2-redirect.component.html',
    styleUrls: ['./oauth2-redirect.component.scss'],
})
export class Oauth2RedirectComponent extends BaseLoginComponent implements OnInit {
    public hasError: boolean;

    constructor(
        route: ActivatedRoute,
        router: Router,
        accountsService: AccountsService,
        modalService: NgbModal,
        wording: WordingService,
        translateService: TranslateService,
        private readonly tokenVerifierService: TokenVerifierService,
    ) {
        super(route, router, accountsService, modalService, translateService, wording);
    }

    public async isTokenValid(token: string): Promise<boolean> {
        return await this.tokenVerifierService.isTokenValid(token);
    }

    public ngOnInit(): void {
        this.returnUrl = this.getReturnUrl();

        this.route.queryParams.subscribe((params: Params) => {
            if (this.isValidState(params)) {
                this.generateOAuth2StateParameter();

                if (params['code']) {
                    this.handleOAuth2LoginRequest(params['provider'], params['code']);
                } else if (params['token']) {
                    this.isTokenValid(params['token']).then((isValid) => {
                        if (isValid) {
                            this.handleOAuth2LoginRequest('ikigai', params['token']);
                        } else {
                            this.failWithMessage('invalid token');
                        }
                    });
                } else {
                    this.failWithMessage('invalid param');
                }
            } else {
                this.failWithMessage('invalid state');
            }
        });
    }

    public oAuth2Login(identityProvider: OAuth2IdentityProviders, code: string) {
        const oAuth2AuthenticationCommand = new OAuth2AuthenticationCommand(identityProvider, code);

        this.accountsService.oAuth2Login(oAuth2AuthenticationCommand).subscribe(
            (user) => {
                this.onLogedinSuccessfully(user);
            },
            () => {
                this.hasError = true;
            },
        );
    }

    private failWithMessage(message: string) {
        this.onLoggedInFail();
    }

    private handleOAuth2LoginRequest(providerName: any, code: string) {
        try {
            const provider: keyof typeof OAuth2IdentityProviders = providerName.toUpperCase();
            if (!!provider && provider in OAuth2IdentityProviders) {
                this.oAuth2Login(OAuth2IdentityProviders[provider], code);
            } else {
                this.failWithMessage('invalid provider');
            }
        } catch (e) {
            this.failWithMessage('server error');
        }
    }

    private isValidState(params: Params) {
        return params['state'] && params['state'] == localStorage.getItem(localStorageKeys.oAuthStateParameter);
    }
}
