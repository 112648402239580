import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Club } from '../../models/clubs/club.model';
import { CreateRoomCommand } from '../../models/clubs/create-room-command';

@Injectable({
    providedIn: 'root',
})
export class ClubRoomsService {
    private clubsApiUrl = `${environment.microservices.clubs.baseUrl}/${environment.microservices.clubs.clubsApi.baseUrl}`;
    private clubRoomsApiUrl = `${environment.microservices.clubs.baseUrl}/${environment.microservices.clubs.clubRoomApi.baseUrl}`;

    private getRoomsApiUrl = `${environment.microservices.clubs.clubsApi.rooms}`;

    constructor(private http: HttpClient) {}

    getClubRoomById(id: string) {
        return this.http.get<Club>(`${this.clubRoomsApiUrl}/${id}`);
    }

    getClubRoomsByClubId(id: string): Observable<Club[]> {
        return this.http.get<Club[]>(`${this.clubsApiUrl}/${id}/${this.getRoomsApiUrl}`);
    }

    createClubRoom(command: CreateRoomCommand): Observable<Club> {
        return this.http.post<Club>(this.clubRoomsApiUrl, command);
    }

    updateClubRoom(id: string, command: CreateRoomCommand): Observable<Club> {
        return this.http.put<Club>(`${this.clubRoomsApiUrl}/${id}`, command);
    }
}
