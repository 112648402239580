<p-dialog
    header="{{ 'community.previewCommunity.title' | translate }}"
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '80vw', maxWidth: '1100px', height: '80vh', minHeight: '60vh' }"
>
    <div class="container mt-2">
        <div class="row w-100 m-0">
            <div class="col-md-4">
                <div class="p-card mb-2 card-style">
                    <div class="card-body">
                        <div class="d-flex flex-row">
                            <img *ngIf="clubLogoUrl" [src]="clubLogoUrl" alt="logo" class="rounded-circle" style="width: 64px; height: 64px" />

                            <div class="d-flex flex-column justify-content-center ml-2">
                                <h5 class="card-title m-0">{{ community?.displayName }}</h5>
                                <div class="d-flex flex-row align-items-center mt-1">
                                    <span class="text-muted">
                                        {{ 'shared.createdOn' | translate }} {{ clubCreationDate | date: 'shortDate' }}
                                        &nbsp;&middot;&nbsp;
                                        {{ clubMembers?.length }} {{ 'community.previewCommunity.members' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body flex-grow-1 d-flex flex-column" style="padding: 0">
                    <ng-container *ngIf="isYouTubeVideo">
                        <iframe class="embed-responsive-item w-100 h-100" [src]="videoSafeUrl" allowfullscreen></iframe>
                    </ng-container>
                    <ng-container *ngIf="isPdf"
                        ><app-pdf-viewer-modal [pdfUrl]="community.communityLink" [pdfName]="community.displayName" [visible]="pdfModalVisible">
                        </app-pdf-viewer-modal
                    ></ng-container>
                    <ng-container *ngIf="isGenericLink">
                        <a href="{{ community.communityLink }}" class="d-block w-100 h-100 text-decoration-none">
                            <div class="card border-0 mb-2 shadow-sm rounded">
                                <div class="card-body d-flex flex-row align-items-center">
                                    <div class="mr-2">
                                        <i class="pi pi-link fs-3"></i>
                                    </div>
                                    <div>
                                        <span class="fw-bold">{{ 'community.pages.description.communityPresentation' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-8 w-100">
                <div class="card">
                    <div class="card-body flex">
                        <p-tabView class="tabView">
                            <p-tabPanel header="{{ 'community.pages.description.mainspringLabel' | translate }}">
                                <div style="white-space: pre-wrap">{{ mainspring }}</div>
                            </p-tabPanel>
                            <p-tabPanel header="{{ 'community.pages.description.benefitsLabel' | translate }}">
                                <div style="white-space: pre-wrap">{{ community?.benefits }}</div>
                            </p-tabPanel>
                            <p-tabPanel header="{{ 'community.pages.description.expectedContributionsLabel' | translate }}">
                                <div style="white-space: pre-wrap">{{ community?.expectedContributions }}</div>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <button type="button" class="btn btn-primary" (click)="closeDialog()">
            {{ 'components.button.buttonClose' | translate }}
        </button>
    </p-footer>
</p-dialog>
