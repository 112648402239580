<div>
    <p-dropdown
        [style]="{ width: '100%' }"
        [options]="options"
        [(ngModel)]="selectedValue"
        optionLabel="name"
        appendTo="body"
        (onChange)="onChange()"
        placeholder="{{ placeholder }}"
    ></p-dropdown>
</div>
