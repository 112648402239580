import { Component, Input, OnInit } from '@angular/core';
import { ClubsStore } from '../../../clubs/clubs.store';
import { DocumentsService } from '../../../services/dms/documents.service';
import { PwaService } from '../../../services/pwa/pwa.service';

@Component({
    selector: 'app-document-preview',
    templateUrl: './document-preview.component.html',
    styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent implements OnInit {
    public alternativePicture = 'assets/images/document-preview-alternative-picture.jpg';
    @Input() public documentId: string;
    public documentName: string;
    public isOnMobile = false;
    public link: string;
    public title: string;

    constructor(
        private readonly pwaService: PwaService,
        private readonly documentService: DocumentsService,
        private readonly clubsStore: ClubsStore,
    ) {}

    public async ngOnInit() {
        this.isOnMobile = this.pwaService.isMobile;

        this.documentService.getDocument(this.documentId, this.clubsStore.displayedRoomId).subscribe((dmsDocument) => {
            this.documentName = dmsDocument.displayName + dmsDocument.fileExtension;
        });
        this.documentService.openDocument(this.documentId, this.clubsStore.displayedRoomId).subscribe((accessUrl) => (this.link = accessUrl));
    }
}
