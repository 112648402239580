import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FieldDisablerHelper {
    fieldIsDisabled(type: FieldType, propertyName: string): boolean {
        return environment.settings.disabledProperties[type]?.includes(propertyName) || null;
    }
}

export enum FieldType {
    user = 'user',
    organization = 'organization',
}
