<div class="row form-header pb-2">
    <div class="col-12">
        <div class="row">
            <button (click)="goBack()" class="btn btn-link btn-back">
                <em class="icon-arrow-right1"></em>
                <span class="d-inline">{{ 'components.button.back' | translate }}</span>
            </button>
        </div>
        <div class="row wizard">
            <div
                class="col wizard__item"
                style="padding-left: 0"
                [class.wizard__item-active]="currentStep === 1"
                [class.wizard__item-past]="currentStep === 2"
                [class.wizard__item-finish]="currentStep === 3"
                [class.wizard__item-pointer]="currentStep > 1"
            >
                <a class="wizard__node" (click)="currentStep > 1 && onWizardStepOne()">
                    <div *ngIf="currentStep < 2">1</div>
                    <em *ngIf="currentStep > 1" class="icon-check"></em>
                </a>
            </div>

            <div
                class="col wizard__item"
                [class.wizard__item-active]="currentStep === 2"
                [class.wizard__item-past]="currentStep === 3"
                [class.wizard__item-pointer]="currentStep !== 2"
            >
                <a class="wizard__node" (click)="currentStep !== 2 && onWizardStepTwo()">
                    <div *ngIf="currentStep < 3">2</div>
                    <em *ngIf="currentStep > 2" class="icon-check"></em>
                </a>
            </div>
            <div class="col wizard__item" [class.wizard__item-active]="currentStep === 3" [class.wizard__item-pointer]="currentStep === 2">
                <a class="wizard__node" (click)="currentStep === 2 && onWizardStepThree()">3</a>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h1 class="view-name">{{ 'projects.pages.createProject.newProject' | translate }}</h1>
            </div>

            <div class="col-auto mr-auto mt-4">
                <button class="btn btn-secondary" type="button" (click)="onSaveDraft()">
                    <em class="icon icon-save d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonSaveDraft' | translate }}</span>
                </button>
                <button *ngIf="currentStep < 3" class="btn btn-primary" type="button" (click)="onStepNext()">
                    <em class="icon icon-arrow-left1 d-sm-inline d-md-none"></em>
                    <span class="d-none d-sm-inline">{{ 'components.button.buttonNext' | translate }}</span>
                </button>
                <!--Demander la publication d'un projet-->
                <button *ngIf="currentStep === 3" class="btn btn-primary" (click)="onPublishRequestClick()">
                    <em class="icon icon-publish d-sm-inline d-md-none"></em>
                    <span class="d-none d-md-inline">{{ 'components.button.publish' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<app-project-form-step-one
    class="row"
    [hidden]="currentStep !== 1"
    [projectTypes]="projectTypes"
    [projectCommand]="createProjectCommand"
    [canCreateAllCommunitiesProject]="canCreateAllCommunitiesProject"
    (saved)="onSave()"
    (submitted)="onStepOneSubmit()"
    #projectFormStepOne
>
</app-project-form-step-one>

<app-project-form-abstract
    class="row"
    *ngIf="createProjectCommand && currentStep > 1"
    [projectCommand]="createProjectCommand"
    [projectTypes]="projectTypes"
>
</app-project-form-abstract>

<app-project-form-step-two
    class="row"
    [hidden]="currentStep !== 2"
    [projectCommand]="createProjectCommand"
    [projectTypes]="projectTypes"
    (saved)="onSave()"
    (submitted)="onStepTwoSubmit()"
    (previousStep)="goToPreviousStep()"
    #projectFormStepTwo
>
</app-project-form-step-two>

<app-project-form-step-three
    class="row"
    [hidden]="currentStep !== 3"
    [projectCommand]="createProjectCommand"
    [projectTypes]="projectTypes"
    (saved)="onSave()"
    (submitted)="onStepThreeSubmit()"
    (previousStep)="goToPreviousStep()"
    #projectFormStepThree
>
</app-project-form-step-three>
