import { Component, Input } from '@angular/core';

@Component({
    selector: 'large-button',
    template: `
        <button pButton class="mt-1 col-12 text-center flex justify-center items-center" [ngClass]="{ 'p-button-outlined': outlined }">
            <span class="w-100">
                {{ label }}
                <em *ngIf="icon" class="ml-2 {{ icon }}"></em>
            </span>
        </button>
    `,
})
export class LargeButtonComponent {
    @Input() public icon: string;
    @Input() public label: string;
    @Input() public outlined: boolean = false;
}
