import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Metadatas } from './../../../models/shared/metadatas.model';
import { MetadatasService } from './../../../services/documents/metadatas.service';
import { PwaService } from './../../../services/pwa/pwa.service';

@Component({
    selector: 'app-link-preview',
    templateUrl: './link-preview.component.html',
    styleUrls: ['./link-preview.component.scss'],
})
export class LinkPreviewComponent implements OnInit, OnChanges {
    public alternativePicture = 'assets/images/link-preview-alternative-picture.jpg';
    public hasError: boolean;
    public isOnMobile = false;
    @Input() public link: string;
    public loaded: boolean;
    public metadatas: Metadatas = null;
    @Input() public showLoadingsProgress = true;
    public isYoutubeModalOpen = false;

    constructor(private metadatasService: MetadatasService, private readonly pwaService: PwaService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.metadatas || changes.link.currentValue !== this.metadatas.baseUrl) {
            this.metadatas = null;
            this.getMetadatas();
        }
    }

    public async ngOnInit() {
        this.isOnMobile = this.pwaService.isMobile;
    }

    private getMetadatas() {
        if (this.link && this.link !== 'null' && !this.metadatas) {
            this.metadatasService.getMetaDatas(this.link).subscribe(
                (value) => {
                    this.metadatas = value;
                },
                (_error) => {
                    this.hasError = true;
                },
                () => (this.loaded = true),
            );
        }
    }

    public onPreviewClicked(url: string) {
        if (this.isYouTubeUrl(url)) {
            this.toggleYoutubeVideoModal();
            return;
        }
        window.open(url, '_blank');
    }

    toggleYoutubeVideoModal() {
        this.isYoutubeModalOpen = !this.isYoutubeModalOpen;
    }

    private isYouTubeUrl(url: string): boolean {
        try {
            const parsedUrl = new URL(url);
            return parsedUrl.hostname === 'www.youtube.com' || parsedUrl.hostname === 'youtube.com' || parsedUrl.hostname === 'youtu.be';
        } catch (e) {
            return false;
        }
    }

    decodeHTMLEntities(text: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        return doc.documentElement.textContent || '';
    }
}
