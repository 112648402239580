import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerHelper } from '../../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../../helpers/toastr.helper';
import { Community } from '../../../../models/identity/community.model';
import { ProjectLight } from '../../../../models/projects/project-light.model';
import { ProjectType } from '../../../../models/projects/project-type.model';
import { Project } from '../../../../models/projects/project.model';
import { UpdateProjectCommand } from '../../../../models/projects/update-project-command.model';
import { Attendee } from '../../../../models/shared/attendee.model';
import { ProjectFormStepOneComponent } from '../../../../projects/components/project-form-step-one/project-form-step-one.component';
import { ProjectFormStepThreeComponent } from '../../../../projects/components/project-form-step-three/project-form-step-three.component';
import { ProjectFormStepTwoComponent } from '../../../../projects/components/project-form-step-two/project-form-step-two.component';
import { AccountsService } from '../../../../services/identity/accounts.service';
import { CommunityService } from '../../../../services/identity/community.service';
import { ProjectTypesService } from '../../../../services/projects/project-types.service';
import { ProjectsService } from '../../../../services/projects/projects.service';

@Component({
    selector: 'app-edit-project',
    templateUrl: './edit-project.component.html',
    styleUrls: ['./edit-project.component.scss'],
})
export class EditProjectComponent implements OnInit {
    currentStep: number;
    updateProjectCommand: UpdateProjectCommand;
    projectTypes: ProjectType[] = [];
    projectOwner: Attendee;
    projectPictureLink: string;
    project: Project;
    projectLight: ProjectLight;
    canCreateAllCommunitiesProject = false;
    private COMMUNITY_MANAGER_ID = '3';

    @ViewChild('projectFormStepOne', { static: false })
    private readonly projectFormStepOne: ProjectFormStepOneComponent;

    @ViewChild('projectFormStepTwo', { static: false })
    private readonly projectFormStepTwo: ProjectFormStepTwoComponent;

    @ViewChild('projectFormStepThree', { static: false })
    private readonly projectFormStepThree: ProjectFormStepThreeComponent;

    constructor(
        private readonly accountsService: AccountsService,
        private readonly communityService: CommunityService,
        private readonly projectService: ProjectsService,
        private readonly projectTypeService: ProjectTypesService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly spinnerHelper: SpinnerHelper,
        private readonly toastrHelper: ToastrHelper,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.projectService.getProjectById(params.get('id')).subscribe((project) => {
                if (!project.communities || project.communities.length <= 0 || !this.canEdit(project.communities))
                    this.router.navigate(['/community', 'projects']);

                this.updateProjectCommand = new UpdateProjectCommand();
                this.updateProjectCommand.id = project.id;
                this.updateProjectCommand.name = project.name;
                this.updateProjectCommand.startDate = project.startDate;
                this.updateProjectCommand.endDate = project.endDate;
                this.updateProjectCommand.isAnonymous = project.isAnonymous;
                this.updateProjectCommand.projectTypeId = project.projectType?.id;
                this.updateProjectCommand.synthesis = project.synthesis;
                this.updateProjectCommand.context = project.context;
                this.updateProjectCommand.goals = project.goals;
                this.updateProjectCommand.contributions = project.contributions;
                this.updateProjectCommand.description = project.description;
                this.updateProjectCommand.organizationVisibility = project.organizationVisibility;
                this.updateProjectCommand.allCommunitiesVisibility = project.allCommunitiesVisibility;
                this.updateProjectCommand.communities = project.communities;
                this.updateProjectCommand.searchedSkills = project.searchedSkills;
                this.updateProjectCommand.associatedInterests = project.associatedInterests;
                this.updateProjectCommand.collaborationPlatformType = project.collaborationPlatformType;
                this.updateProjectCommand.collaborationPlatformUrl = project.collaborationPlatformUrl;
                this.updateProjectCommand.isPublic = project.isPublic;
                this.updateProjectCommand.publicJoinUrl = project.publicJoinUrl;

                this.project = project;
                this.projectPictureLink = project.pictureAccessLink;
                this.projectOwner = project.attendees.find((attendee) => attendee.isOwner);
                this.projectLight = Project.toProjectLight(project);

                this.communityService.CheckIfUserIsIntoEnclosedCommunity(this.projectOwner.id).subscribe(({ visibility }) => {
                    this.canCreateAllCommunitiesProject = visibility;
                });
            });
        });

        this.currentStep = 1;
        this.projectTypeService.getProjectTypes().subscribe((result) => (this.projectTypes = result));
    }

    canEdit(projectCommunities: Community[]): boolean {
        for (const projectCommunity of projectCommunities)
            for (const userCommunity of this.accountsService.currentUser.communities)
                if (projectCommunity.id == userCommunity.id) {
                    if (!userCommunity.roles || userCommunity.roles.length <= 0) return false;
                    for (const role of userCommunity.roles) if (role.id == this.COMMUNITY_MANAGER_ID) return true;

                    return false;
                }
        return false;
    }
    onStepOneSubmit() {
        this.goToNextStep();
    }

    onStepTwoSubmit() {
        this.goToNextStep();
    }

    onStepThreeSubmit(withPublish: boolean) {
        this.updateProjectCommandFromStepComponents();
        this.updateProjectCommand.id = this.project.id;
        this.updateProjectCommand.asCommunityManager = true;
        this.spinnerHelper.showSpinner();

        const apiAction = this.projectService.updateProject(this.updateProjectCommand, false);

        apiAction.subscribe(
            () => {
                const msg = withPublish
                    ? this.translate.instant('community.pages.projects.edit.toast.projectModifiedPublished')
                    : this.translate.instant('community.pages.projects.edit.toast.projectModified');
                this.toastrHelper.showSuccess(msg);

                setTimeout(() => {
                    this.router.navigate(['community', 'projects']);
                }, 2000);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }

    onStepNext() {
        switch (this.currentStep) {
            case 1: {
                this.projectFormStepOne.onSubmit();
                break;
            }
            case 2: {
                this.projectFormStepTwo.onSubmit();
                break;
            }
            case 3: {
                this.projectFormStepThree.onSubmit(false);
                break;
            }
        }
    }

    onSubmit(withPublish: boolean) {
        if (this.currentStep === 3) {
            this.projectFormStepThree.onSubmit(withPublish);
        }
    }

    onUpdate() {
        this.onSubmit(true);
    }

    onUpdateAndPublish() {
        this.onSubmit(true);
    }

    onSave() {
        this.updateProjectCommandFromStepComponents();
        this.spinnerHelper.showSpinner();
        this.projectService.draftProject(this.project.id, this.updateProjectCommand).subscribe(
            (_) => {
                const msg = this.translate.instant('community.pages.projects.edit.toast.projectSaved');
                this.toastrHelper.showSuccess(msg);

                this.router.navigate(['community', 'projects']);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }

    updateProjectCommandFromStepComponents() {
        this.projectFormStepOne.updateProjectCommandFromStepOneComponent();
        this.projectFormStepTwo.updateProjectCommandFromStepTwoComponent();
    }

    checkFormValidity() {
        return (
            this.projectFormStepOne.checkFormValidity() &&
            this.projectFormStepTwo.checkFormValidity() &&
            this.projectFormStepThree.checkFormValidity()
        );
    }

    onPublish() {
        // TODO => save if changed?!
        if (!this.checkFormValidity()) {
            return;
        }

        this.spinnerHelper.showSpinner();
        this.projectService.publishProject(this.project.id).subscribe(
            () => {
                const msg = this.translate.instant('community.pages.projects.edit.toast.projectPublished');
                this.toastrHelper.showSuccess(msg);
                setTimeout(() => {
                    this.router.navigate(['community', 'projects']);
                }, 2000);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }

    goToNextStep() {
        this.currentStep++;
        window.scrollTo(0, 0);
    }

    goToPreviousStep() {
        this.currentStep--;
        window.scrollTo(0, 0);
    }

    onWizardStepOne() {
        this.updateProjectCommandFromStepComponents();
        this.currentStep = 1;
        window.scrollTo(0, 0);
    }

    onWizardStepTwo() {
        if (this.currentStep > 2) {
            this.goToPreviousStep();
        } else {
            this.projectFormStepOne.onSubmit();
        }
    }

    onWizardStepThree() {
        if (this.currentStep < 2) {
            this.projectFormStepOne.onSubmit();
        }
        this.projectFormStepTwo.onSubmit();
    }

    deleteProject() {
        this.spinnerHelper.showSpinner();
        this.projectService.disableProject(this.project.id).subscribe(
            () => {
                const msg = this.translate.instant('community.pages.projects.edit.toast.projectDeleted');
                this.toastrHelper.showSuccess(msg);
                this.router.navigate(['/community', 'projects']);
            },
            (error) => {
                this.toastrHelper.showGenericError(error);
            },
            () => {
                this.spinnerHelper.hideSpinner();
            },
        );
    }
}
