<div class="p-d-flex d-md-flex d-none d-md-none">
    <div>
        <nav class="card club-navigation-component p-ml-3 p-my-3" tourAnchor="clubs">
            <ul>
                <li class="club-item" [pTooltip]="'clubs.navigation.browseClubs' | translate">
                    <a class="explore-clubs" [routerLink]="['/clubs']">
                        <div class="label">
                            <i class="icon-f-compass"></i>
                        </div>
                    </a>
                </li>

                <ng-container *ngIf="clubsStore.member$ | async; else loading">
                    <li
                        *ngFor="let club of clubsToDisplay"
                        class="club-item-li"
                        [pTooltip]="club?.title"
                        [class.active]="club?.id === clubsStore?.displayedClub?.id"
                    >
                        <div class="club-item">
                            <a class="select-club" [routerLink]="'/clubs/' + [club.id]" style="position: relative">
                                <img
                                    *ngIf="club.profilePictureUrl; else textLabel"
                                    [src]="club.profilePictureUrl"
                                    alt="profile"
                                    routerLinkActive="active"
                                    [ngClass]="{
                                        'club-item-li-not-selected': isOnClubRoute && selectedClubId !== club?.id
                                    }"
                                />
                                <p-badge
                                    *ngIf="clubActivityService.newActivityCountByClubId(club.id, true) > 0"
                                    [value]="clubActivityService.newActivityCountByClubId(club.id, true)"
                                    severity="danger"
                                    class="club-new-activity-badge"
                                ></p-badge>
                                <p-badge
                                    *ngIf="
                                        !clubActivityService.newActivityCountByClubId(club.id, true) && clubActivityService.isNewClub(club.id, false)
                                    "
                                    [value]="'clubs.navigation.newClubBadge' | translate"
                                    severity="danger"
                                    class="club-new-activity-badge"
                                ></p-badge>
                                <ng-template #textLabel>
                                    <div
                                        class="p-d-flex"
                                        [style]="{ width: '100%' }"
                                        [ngClass]="{
                                            'club-item-li-not-selected': isOnClubRoute && selectedClubId !== club?.id
                                        }"
                                    >
                                        <div class="p-m-auto" [style]="{ color: 'var(--white)' }">
                                            {{ club?.title.slice(0, 2) }}
                                        </div>
                                    </div>
                                </ng-template>
                            </a>
                        </div>
                    </li>
                </ng-container>

                <li *ngIf="canCreateClub || isCommunityManager" class="club-item" [pTooltip]="'clubs.navigation.createClub' | translate">
                    <create-club-modal-button class="add-club" [club]="null"></create-club-modal-button>
                </li>
            </ul>
        </nav>

        <ng-template #loading>
            <wudo-spinner></wudo-spinner>
        </ng-template>
    </div>
    <div *ngIf="isOnClubRoute">
        <room-navigation
            *ngIf="clubsStore.displayedClub$ | async as club"
            class="room-navigation"
            [rooms]="clubsStore.displayRooms"
            [displayClubTitle]="getClubTitle()"
            [displayedClub]="getClub()"
        ></room-navigation>
    </div>
</div>
