import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { ClubsService } from '../../services/clubs/clubs.service';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';

export function clubNameExistsValidator(clubsService: ClubsService): AsyncValidatorFn {
    return (control: AbstractControl) => {
        return control.valueChanges.pipe(
            debounceTime(500),
            switchMap((value) => clubsService.clubNameExists(value)),
            map((exists) => (exists ? { uniqueClubName: true } : null)),
            first(),
        );
    };
}
