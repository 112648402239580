import { Component, EventEmitter, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { PwaStatus } from '../../../models/identity/pwa-status.enum.model';
import { ProfileService } from '../../../services/identity/profile.service';

@Component({
    selector: 'app-pwa-a2hs',
    templateUrl: './pwa-a2hs.component.html',
    styleUrls: ['./pwa-a2hs.component.scss'],
})
export class PwaA2hsComponent {
    @Input() deferredPrompt: any; // BeforeInstallPromptEvent
    @Input() maxPrompt = false; // Indicate that the prompt, to install PWA, is display the last time! (user reach max retry)
    @Input() isIos: boolean; // Indicate if the current device is an iOS.

    public applicationCode = environment.settings.code;

    public onInstalled: EventEmitter<any> = new EventEmitter();
    public onInstallCancelled: EventEmitter<any> = new EventEmitter();

    constructor(private readonly modalService: NgbModal, private readonly profileService: ProfileService) {}

    /**
     * Catch choice of user to install or no the PWA
     */
    public addToHomeScreen() {
        this.modalService.dismissAll();

        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult: any) => {
            if (choiceResult.outcome !== 'accepted') {
                this.profileService.setPwaStatus(PwaStatus.Dismissed).subscribe();
                this.onInstallCancelled.emit();
            }

            this.deferredPrompt = null;
        });
    }

    /**
     * Save "cancel" choice of the user (no PWA install).
     * Increment "retry" locally on device ; and save last prompt date!
     */
    public cancelAddToHomeScreen() {
        this.profileService.setPwaStatus(PwaStatus.Dismissed).subscribe();
        this.onInstallCancelled.emit();
    }

    /**
     * Track manually installation on iOS device
     */
    public trackInstalled() {
        this.profileService.setPwaStatus(PwaStatus.Installed).subscribe();
        this.onInstalled.emit();
    }
}
