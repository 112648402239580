import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../shared/cache.service';
import { Metadatas } from './../../models/shared/metadatas.model';

@Injectable({ providedIn: 'root' })
export class MetadatasService {
    private getMetaDatasApiUrl = `${environment.microservices.documents.baseUrl}/${environment.microservices.documents.metaDatasApi.baseUrl}`;
    private metadataCacheKey = 'metadata_';

    constructor(private http: HttpClient, private cacheService: CacheService) {}

    public getMetaDatas(url: string): Observable<Metadatas> {
        return this.cacheService.get(
            `${this.metadataCacheKey}${url}`,
            this.http.get<Metadatas>(`${this.getMetaDatasApiUrl}/GetMetaDatas?url=${url}`),
        );
    }
}
