import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ClubActivity } from '../../models/clubs/club-activity.model';
import { ClubsService } from '../../services/clubs/clubs.service';
import { AccountsService } from '../../services/identity/accounts.service';
import { ClubsStore } from '../clubs.store';

@Injectable({
    providedIn: 'root',
})
export class ClubActivityService {
    private memberClubsActivitySubject = new BehaviorSubject<ClubActivity[]>([]);

    public hasClubActivityNotifications = new BehaviorSubject<boolean>(false);
    public memberClubsActivity$ = this.memberClubsActivitySubject.asObservable();

    constructor(
        private readonly accountsService: AccountsService,
        private readonly clubsRepository: ClubsService,
        private readonly clubsStore: ClubsStore,
    ) {
        this.memberClubsActivity$.subscribe((clubsActivity) => {
            this.hasClubActivityNotifications.next(
                clubsActivity.some((clubActivity) => clubActivity.newAnnouncements > 0 || clubActivity.newMentions > 0),
            );
        });
    }

    public set memberClubsActivity(value: ClubActivity[]) {
        this.memberClubsActivitySubject.next(value);
    }

    public isNewClub(clubId: string, checkRooms = false): boolean {
        const clubActivity = this.memberClubsActivitySubject.value.find((clubAct) => clubAct.clubId == clubId);
        const isNewClub = clubActivity?.isNewClub ?? false;

        if (!checkRooms) return isNewClub;
        const rooms = this.clubsStore.member.fullClubs.find((club) => club.id == clubId)?.rooms;

        if (rooms == null) return isNewClub;

        for (const room of rooms) {
            if (this.isNewClub(room.id, false)) return true;
        }
        return isNewClub;
    }

    public async loadAllClubsActivity() {
        await this.clubsRepository
            .getAllClubsActivityForMember()
            .toPromise()
            .then((response) => {
                if (!response) return;
                this.memberClubsActivity = response.activities;
                for (const club of response.allClubs) {
                    //If the year value of club.lastVisitDate is equal to 1 (so year 1 after JC), it means that user never entered in this club
                    //So the club is new for the user
                    if (new Date(club.lastVisitDate).getFullYear() == 1) {
                        const index = this.memberClubsActivitySubject.value.findIndex((clubAct) => clubAct.clubId == club.id);
                        const newActivity: ClubActivity = {
                            clubId: club.id,
                            newAnnouncements: 0,
                            newMentions: 0,
                            newPosts: 0,
                            isNewClub: true,
                        };
                        if (index < 0) {
                            this.memberClubsActivitySubject.value.push(newActivity);
                        } else if (this.newActivityCountByClubId(club.id, true) <= 0) {
                            this.memberClubsActivitySubject.value[index] = newActivity;
                        }
                    }
                }

                // Special case when admins are opening clubs they dont belong to
                if (this.clubsStore.member === null && this.accountsService.isAdmin) {
                    return;
                }

                if (this.clubsStore.member?.clubs?.length != response.allClubs?.length) this.clubsStore.loadMember();
            })
            .catch((err) => {});
    }

    public newActivityCountByClubId(clubId: string, checkRooms = false): number {
        const clubActivity = this.memberClubsActivitySubject.value.find((clubAct) => clubAct.clubId == clubId);

        let count = 0;
        if (clubActivity != null) count = clubActivity.newAnnouncements + clubActivity.newMentions;

        if (!checkRooms) return count;
        const rooms = this.clubsStore.member?.fullClubs?.find((club) => club.id == clubId)?.rooms;

        if (rooms == null) return count;

        for (const room of rooms) {
            count += this.newActivityCountByClubId(room.id);
        }
        return count;
    }

    public roomHasNewPublication(roomId: string): boolean {
        return (
            this.memberClubsActivitySubject.value?.find((clubAct) => clubAct.clubId == roomId)?.newPosts > 0 ||
            this.newActivityCountByClubId(roomId) > 0
        );
    }
}
