import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ClubsStore } from '../../clubs/clubs.store';
@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private history: string[] = [];

    constructor(private router: Router, private location: Location, private activatedRoute: ActivatedRoute, readonly clubsStore: ClubsStore) {
        // Track route changes
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.history.push(event.urlAfterRedirects);
        });
    }

    public clearHistory(): void {
        this.history = [];
    }

    public getRouteTitle(): Observable<string> {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => {
                const route = this.findDeepestRouteWithData(this.activatedRoute.snapshot);
                return route?.data?.['title'] || '';
            }),
        );
    }

    public goBack(): void {
        const route = this.findDeepestRouteWithData(this.activatedRoute.snapshot);
        const currentUrl = this.router.url;
        const defaultBack = route?.data?.['defaultBack'];
        if (this.handleSpecialCases(currentUrl)) {
            return;
        }
        // If defaultBack is specified, use it
        if (defaultBack) {
            // Replace any parameters in the defaultBack path
            if (defaultBack.includes(':')) {
                const params = this.findRouteParams(this.activatedRoute.snapshot);
                const processedDefaultBack = this.replaceUrlParams(defaultBack, params);
                this.router.navigateByUrl(processedDefaultBack);
            } else {
                this.router.navigateByUrl(defaultBack);
            }
            return;
        }

        // If no defaultBack is specified, try history
        if (this.history.length > 1) {
            this.history.pop();
            const previousRoute = this.history[this.history.length - 1];
            this.router.navigateByUrl(previousRoute);
        } else {
            // No history and no defaultBack, try browser history or go to root
            if (window.history.length > 1) {
                this.location.back();
            } else {
                this.router.navigateByUrl('/');
            }
        }
    }
    private handleSpecialCases(currentUrl: string): boolean {
        if (currentUrl.includes('/clubs/') && this.clubsStore.displayRooms?.length === 1) {
            this.router.navigate(['/clubs']);
            return true;
        }
        return false;
    }
    public shouldShowBackButton(): Observable<boolean> {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => {
                // Check all route segments for showBackButton configuration
                const route = this.findDeepestRouteWithData(this.activatedRoute.snapshot);
                return route?.data?.['showBackButton'] ?? false;
            }),
        );
    }

    private findDeepestRouteWithData(route: any): any {
        let currentRoute = route;

        // Traverse down the route tree
        while (currentRoute.firstChild) {
            if (currentRoute.firstChild.data?.['showBackButton'] !== undefined || currentRoute.firstChild.data?.['defaultBack'] !== undefined) {
                currentRoute = currentRoute.firstChild;
            } else {
                // If child doesn't have relevant data, keep parent's data but continue checking children
                const nextRoute = currentRoute.firstChild;
                nextRoute.data = { ...currentRoute.data, ...nextRoute.data };
                currentRoute = nextRoute;
            }
        }

        return currentRoute;
    }

    private findRouteParams(route: any): { [key: string]: string } {
        let params: { [key: string]: string } = {};
        let currentRoute = route;

        // Traverse the route tree and collect all params
        while (currentRoute) {
            if (currentRoute.params) {
                params = { ...params, ...currentRoute.params };
            }
            currentRoute = currentRoute.firstChild;
        }

        return params;
    }

    private replaceUrlParams(url: string, params: { [key: string]: string }): string {
        let resultUrl = url;
        Object.keys(params).forEach((paramKey) => {
            resultUrl = resultUrl.replace(`:${paramKey}`, params[paramKey]);
        });
        return resultUrl;
    }
}
