import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerHelper } from '../../../helpers/spinner.helper';
import { ToastrHelper } from '../../../helpers/toastr.helper';
import { EventLight } from '../../../models/events/event-light.model';
import { UpdateEventCommand } from '../../../models/events/update-event-command.model';
import { Attendee } from '../../../models/shared/attendee.model';
import { EventsService } from '../../../services/events/events.service';
import { BaseEventFormPage } from '../../directives/base-event-form-page.directive';
import { Event } from '../../../models/events/event.model';
import { ProfileService } from '../../../services/identity/profile.service';
import { ModifierTypes, User } from '../../../models/identity/user.model';
import { AccountsService } from '../../../services/identity/accounts.service';

@Component({
    templateUrl: './edit-event.component.html',
})
export class EditEventComponent extends BaseEventFormPage<UpdateEventCommand> implements OnInit {
    public event: Event;
    public eventLight: EventLight;
    public eventOwner: Attendee;
    public eventOwnerProfile: User;
    public eventPictureLink: string;
    public modifierType: ModifierTypes = ModifierTypes.None;
    public isCurrentUserOwner: boolean;

    constructor(
        private readonly accountsService: AccountsService,
        private readonly eventService: EventsService,
        private readonly toastrHelper: ToastrHelper,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly translate: TranslateService,
        private readonly spinnerHelper: SpinnerHelper,
        private readonly profileService: ProfileService,
        modalService: NgbModal,
    ) {
        super(modalService);
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(async (params) => {
            this.event = await this.eventService.getEventById(params.get('id')).toPromise();

            if (!Event.canBeEdited(this.event)) {
                this.router.navigate(['events']);
            }

            this.eventCommand = new UpdateEventCommand();

            this.eventCommand.id = this.event.id;
            this.eventCommand.name = this.event.name;
            this.eventCommand.startDate = this.event.startDate;
            this.eventCommand.endDate = this.event.endDate;
            this.eventCommand.description = this.event.description;
            this.eventCommand.type = this.event.type;
            this.eventCommand.accessType = this.event.accessType;
            this.eventCommand.eventLink = this.event.eventLink;
            this.eventCommand.address = this.event.address;
            this.eventCommand.addressComplement = this.event.addressComplement;
            this.eventCommand.maxAttendeesCount = this.event.maxAttendeesCount;

            this.eventCommand.organizationVisibility = this.event.organizationVisibility;
            this.eventCommand.allCommunitiesVisibility = this.event.allCommunitiesVisibility;
            this.eventCommand.communities = this.event.communities;

            this.eventCommand.registrationLink = this.event.registrationLink;
            this.eventPictureLink = this.event.pictureAccessLink;
            this.eventOwner = this.event.attendees.find((attendee) => attendee.isOwner);
            this.eventOwnerProfile = await this.profileService.getUserProfileById(this.eventOwner.id).toPromise();
            this.modifierType = this.accountsService.canModifyEvent(this.event, this.eventOwnerProfile);
            this.isCurrentUserOwner = this.eventOwnerProfile.userId === this.accountsService.currentUser.userId;

            const canEdit = this.modifierType !== ModifierTypes.None || this.isCurrentUserOwner;

            if (!canEdit) {
                this.router.navigate(['events']);
            }

            this.eventLight = Event.toEventLight(this.event);
        });
    }

    public override async onPublishConfirmed(): Promise<void> {
        this.closePublishModal();
        this.spinnerHelper.showSpinner();

        try {
            await this.eventService.updateEvent(this.eventCommand).toPromise();

            this.spinnerHelper.hideSpinner();

            const msg = this.translate.instant('events.pages.editEvent.toast.eventModified');
            this.toastrHelper.showSuccess(msg);

            setTimeout(() => {
                this.router.navigate(['events', 'mine']);
            }, 2000);
        } catch (error: any) {
            this.spinnerHelper.hideSpinner();
            this.toastrHelper.showGenericError(error);
        }
    }
}
