import { GeoJsonPoint } from '../../azure/map/models/address.model';
import { Theme } from '../shared/theme.model';
import { Interest } from '../skills/interest.model';
import { Skill } from '../skills/skill.model';
import { CommunityWithRoles } from './community-with-roles.model';
import { GeographicalArea } from './geographical-area.model';
import { OrganizationAsUserProperty } from './organization-as-user-property.model';
import { PwaStatus } from './pwa-status.enum.model';
import { UserPermissions } from './user-permission.model';
import { UserStatusTypes } from './user-status-types.model';
import { UserType } from './user-type.model';

export interface IdentityUser {
    communities: CommunityWithRoles[];
    interests: Interest[];
    organization: OrganizationAsUserProperty;
    permissions: UserPermissions;
    skills: Skill[];
    subscriptionType: SubscriptionTypes;
}

export class ResponseFromAccountActivationFollowUp {
    public errors: string;
    public success: string;
}

export class ResponseFromBatchInsertUser {
    public errors: { [key: string]: string } = {};
    public rapport: string;
    public success: { [key: string]: string } = {};
}

export class User {
    public active: boolean;
    public city: string;
    public country: string;
    public location: GeoJsonPoint;
    public communities: CommunityWithRoles[];
    public description: string;
    public email: string;
    public emailConfirmed: boolean;
    public firstName: string;
    public geographicalArea: GeographicalArea;
    public geographicalAreaId: string;
    public interests: Interest[];
    public isExternal: boolean;
    public isOnBoardingProfileCompleted: boolean;
    public jobTitle: string;
    public lastName: string;
    public linkedIn: string;
    public mobilePhone: string;
    public officePhone: string;
    public organization: OrganizationAsUserProperty;
    public password: string;
    public permissions: UserPermissions;
    public pictureAccessLink: string;
    public primaryCommunity: CommunityWithRoles;
    public profilePicture: string;
    public skills: Skill[];
    public status: UserStatusTypes;
    public subscriptionType: SubscriptionTypes;
    public termsOfUse: boolean;
    public theme: Theme;
    public userId: string;
    public userPrincipalName: string;
    public userType: UserType;
    public userTypeId: string;
    public isProductTourCompleted: boolean | null;

    public pwaStatus: PwaStatus;
    public hasViewedCommunityInformation: boolean;

    constructor(object?: Partial<User>) {
        Object.assign(this, object);
    }

    public static sortByFullName = (a: User, b: User) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
}

export enum ModifierTypes {
    CommunityManager = 'CommunityManager',
    OrganizationLeader = 'OrganizationLeader',
    Admin = 'Admin',
    None = 'None',
}

export enum SubscriptionTypes {
    Nothing = 0,
    Open = 1,
    Business = 2,
    Grid = 3,
}

export function partialCopy(user: User): IdentityUser {
    return {
        subscriptionType: user.subscriptionType | SubscriptionTypes.Nothing,
        communities: user.communities,
        organization: user.organization,
        skills: user.skills,
        interests: user.interests,
        permissions: user.permissions,
    } as IdentityUser;
}

export const USER_SKILLS_MAX = 10;
export const USER_INTERESTS_MAX = 10;
