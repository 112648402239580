export enum MailGroup {
    WelcomeGroup = 'WelcomeGroup',
    AccountGroup = 'AccountGroup',
    OrganizationGroup = 'OrganizationGroup',
    ClubGroup = 'ClubGroup',
    ProjectGroup = 'ProjectGroup',
    EventGroup = 'EventGroup',
    AnnouncementGroup = 'AnnouncementGroup',
    NewsletterGroup = 'NewsletterGroup',
    ContentReportedGroup = 'ContentReportedGroup',
}
