import { Component, Input } from '@angular/core';
import { BaseProjectCommand } from '../../../models/projects/base-project-command.model';
import { ProjectType } from '../../../models/projects/project-type.model';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-project-form-abstract',
    templateUrl: './project-form-abstract.component.html',
    styleUrls: ['./project-form-abstract.component.scss'],
})
export class ProjectFormAbstractComponent {
    @Input() projectCommand: BaseProjectCommand;
    @Input() projectTypes: ProjectType[];

    dictionary = environment.dictionary;

    constructor(private translate: TranslateService) {}

    getProjectTypeName(projectTypeId: string) {
        return this.projectTypes?.find((projectType) => projectType.id === projectTypeId)?.label;
    }

    getProjectVisibility() {
        if (this.projectCommand?.allCommunitiesVisibility) {
            return 'Toutes les communautés';
        }

        let visibility = '';
        if (this.projectCommand?.organizationVisibility) {
            visibility += this.translate.instant('projects.formAbstract.myOrganization');
        }

        if (this.projectCommand?.communities?.length > 0) {
            this.projectCommand.communities.forEach((community) => {
                visibility += ` | ${community.displayName}`;
            });

            if (!this.projectCommand?.organizationVisibility) {
                visibility = visibility.substring(2, visibility.length);
            }
        }

        return visibility;
    }
}
