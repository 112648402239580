import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { WordingService } from '../../../services/shared/wording.service';
import { OAuth2IdentityProviders } from './../../../models/oauth2/constants/oauth2-identity-providers';
import { AccountsService } from './../../../services/identity/accounts.service';
import { BaseLoginComponent } from './../../base-login.component';

@Component({
    selector: 'app-oauth2-departure',
    templateUrl: './oauth2-departure.component.html',
    styleUrls: ['./oauth2-departure.component.scss'],
})
export class Oauth2DepartureComponent extends BaseLoginComponent implements OnInit {
    oAuthLoginUrl: string;

    constructor(
        route: ActivatedRoute,
        router: Router,
        accountsService: AccountsService,
        modalService: NgbModal,
        translateService: TranslateService,
        wording: WordingService,
        @Inject(DOCUMENT) document: Document,
    ) {
        super(route, router, accountsService, modalService, translateService, wording);

        this.route.queryParams.subscribe((params) => {
            if (params['provider']) {
                const provider: string = params['provider'].toUpperCase();
                this.generateOAuth2Providers();
                this.generateOAuth2StateParameter();
                this.defineOAuth2LoginUrl(provider);

                document.defaultView.location.href = this.oAuthLoginUrl;
            } else {
                this.router.navigate(['login']);
            }
        });
    }

    ngOnInit(): void {}

    defineOAuth2LoginUrl(providerName: string) {
        switch (providerName) {
            case OAuth2IdentityProviders.GIFAS:
                this.oAuthLoginUrl = this.defineGifasOAuth2LoginUrl();
                break;

            case OAuth2IdentityProviders.CETIM:
                this.oAuthLoginUrl = this.defineCetimOAuth2LoginUrl();
                break;

            case OAuth2IdentityProviders.PSI:
                this.oAuthLoginUrl = this.definePSIOAuthLoginUrl();
                break;

            case OAuth2IdentityProviders.IKIGAI:
                this.oAuthLoginUrl = this.defineIkigaiOAuth2LoginUrl();
                break;

            default:
                this.router.navigate(['login']);
                break;
        }
    }
}
