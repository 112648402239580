import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from './logging.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    constructor(private monitoringService: MonitoringService) {
        super();
    }

    override handleError(error: Error) {
        this.monitoringService.logException(error); // Manually log exception
    }
}
