import { CommunityFilter } from './community-filter.model';

export class Filters {
    public page: number;
    public pageSize: number;
    public sortMode: string;
    public sortOrder: number;
    public skillsIds: string[];
    public skillDomainsIds: string[];
    public interestsIds: string[];
    public interestDomainsIds: string[];
    public businessAreasIds: string[];
    public organizationsIds: string[];
    public geographicalAreasIds: string[];
    public communities: CommunityFilter[];
    public isMyOrganizationChecked: boolean;
    public isAllCommunitiesChecked: boolean;
    public userCustomSearch: string;
}
