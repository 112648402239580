import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Community } from './../../../models/identity/community.model';
import { AccountsService } from './../../../services/identity/accounts.service';

@Component({
    selector: 'app-nav-admin-community',
    templateUrl: './nav-admin-community.component.html',
    styleUrls: ['./nav-admin-community.component.scss'],
})
export class NavAdminCommunityComponent implements OnInit {
    @Input() public communities: Community[] = [];
    public createCommunitiesAvailable = false;

    constructor(public readonly accountsService: AccountsService) {
        if (accountsService.isAdmin()) {
            this.createCommunitiesAvailable = true;
            return;
        }

        if (environment.features.backOfficeCommunityManagerFeaturesAvailable.access) {
            this.createCommunitiesAvailable = false;
        }
    }

    public changeCommunityInService(newCommunity: Community) {
        this.accountsService.changeCurrentCommunity(newCommunity);
    }

    public ngOnInit(): void {
        if (this.communities && this.communities.length > 0) this.changeCommunityInService(this.communities[0]);
        this.accountsService.currentCommunityChange.subscribe((newCommunity) => {
            const index = this.communities.findIndex((community) => community.id == newCommunity.id);

            if (index > -1) {
                this.communities[index].displayName = newCommunity.displayName;
                this.communities[index].communitiesGroups = newCommunity.communitiesGroups;
            } else {
                this.communities.push({
                    ...newCommunity,
                });
            }
        });
    }

    public onChangeCommunity(event: string) {
        const community = this.communities.find((community) => community.id == event);

        if (community != null) {
            this.changeCommunityInService(community);
        }
    }
}
