import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-nav-admin',
    templateUrl: './nav-admin.component.html',
})
export class NavAdminComponent {
    isExpanded = false;
    importUsersAvailable: boolean | undefined = environment.features.importUsersAvailable;

    dictionary = environment.dictionary;

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}
