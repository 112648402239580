<!-- notification-config.component.html -->
<p-dialog [(visible)]="visible" [modal]="true" [style]="{ width: '50rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
    <ng-template pTemplate="header">
        <h5 class="font-bold">{{ 'home.configureNotifications.title' | translate }}</h5>
    </ng-template>

    <div class="p-m-2">
        <span>{{ 'home.configureNotifications.leadText' | translate }}</span>
    </div>

    <div class="container d-flex align-items-center">
        <p-inputSwitch [(ngModel)]="isClubNotificationsChecked" (onChange)="onInputSwitchChange($event, mailGroupEnum.ClubGroup)"></p-inputSwitch>
        <div class="container align-items-start p-m-2 p-pl-1">
            <div>{{ 'home.configureNotifications.allNotifications.text1' | translate }}</div>
        </div>
    </div>

    <div class="container d-flex align-items-center">
        <p-inputSwitch
            class="ml-3"
            [(ngModel)]="isAnnouncementChecked"
            (onChange)="onInputSwitchChange($event, mailGroupEnum.AnnouncementGroup)"
        ></p-inputSwitch>
        <div class="container d-flex align-items-start">
            <div>{{ 'home.configureNotifications.groupOfAnnouncements.title' | translate }}</div>
            <div pTooltip="{{ 'home.configureNotifications.groupOfAnnouncements.tooltip' | translate }}" class="p-ml-2">
                <i class="icon-info"></i>
            </div>
        </div>
    </div>

    <div *ngIf="canActivateNewsletter" class="container d-flex align-items-center">
        <p-inputSwitch
            class="ml-3"
            [(ngModel)]="isNewsLetterChecked"
            (onChange)="onInputSwitchChange($event, mailGroupEnum.NewsletterGroup)"
        ></p-inputSwitch>
        <div class="container d-flex align-items-start">
            <div>{{ 'home.configureNotifications.newsletter.title' | translate }}</div>
            <div pTooltip="{{ 'home.configureNotifications.newsletter.tooltip' | translate }}" class="p-ml-2">
                <i class="icon-info"></i>
            </div>
        </div>
    </div>

    <div class="container d-flex align-items-center">
        <p-inputSwitch [(ngModel)]="isEventNotificationsChecked" (onChange)="onInputSwitchChange($event, mailGroupEnum.EventGroup)"></p-inputSwitch>
        <div class="container align-items-start p-m-2 p-pl-1">
            <div>{{ 'home.configureNotifications.allNotifications.text2' | translate }}</div>
        </div>
    </div>

    <div class="container d-flex align-items-center">
        <p-inputSwitch
            [(ngModel)]="isProjectNotificationsChecked"
            (onChange)="onInputSwitchChange($event, mailGroupEnum.ProjectGroup)"
        ></p-inputSwitch>
        <div class="container align-items-start p-m-2 p-pl-1">
            <div>{{ 'home.configureNotifications.allNotifications.text3' | translate }}</div>
        </div>
    </div>

    <div class="p-m-2">
        <span>{{ 'home.configureNotifications.footerText' | translate }}</span>
    </div>

    <p-footer>
        <button type="button" class="btn btn-light" (click)="toggleModal()">
            {{ 'components.button.buttonClose' | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="save()">
            {{ 'components.button.buttonValidation' | translate }}
        </button>
    </p-footer>
</p-dialog>
