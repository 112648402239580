import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CognitoJwtVerifier } from 'aws-jwt-verify';

@Injectable({
    providedIn: 'root',
})
export class TokenVerifierService {
    async isTokenValid(token: string): Promise<boolean> {
        if (!token) {
            return false;
        }

        // Verifier that expects valid access tokens:
        const verifier = CognitoJwtVerifier.create({
            userPoolId: environment.sso.oAuth.verifier.ikigai.userPoolId,
            tokenUse: 'id',
            clientId: environment.sso.oAuth.verifier.ikigai.clientId,
        });

        try {
            await verifier.verify(token);
            // could be improved by checking some values in the payload.
            return true;
        } catch (e) {
            // token is not valid
            return false;
        }
    }
}
