<div class="container">
    <div class="row">
        <div class="col">
            <h1 class="view-name">
                {{ 'administration.global.pages.projects.listProject.title' | translate }}
            </h1>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mt-4">
            <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                <li class="nav-item">
                    <a
                        class="nav-link"
                        id="draft-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="draft"
                        aria-selected="false"
                        routerLink="./"
                        fragment="draft"
                        [class.active]="activeFragment === projectStatus.draft"
                        >{{ 'projects.status.draft' | translate }}</a
                    >
                </li>
                <!-- <li class="nav-item">
          <a
            class="nav-link"
            id="unpublished-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="unpublished"
            aria-selected="true"
            routerLink="./"
            fragment="unpublished"
            [class.active]="activeFragment === projectStatus.unpublished"
            >En attente de publication</a
          >
        </li> -->
                <li class="nav-item">
                    <a
                        class="nav-link"
                        id="published-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="published"
                        aria-selected="false"
                        routerLink="./"
                        fragment="published"
                        [class.active]="activeFragment === projectStatus.published"
                        >{{ 'projects.status.published' | translate }}</a
                    >
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        id="finished-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="finished"
                        aria-selected="false"
                        routerLink="./"
                        fragment="finished"
                        [class.active]="activeFragment === projectStatus.finished"
                        >{{ 'projects.status.finished' | translate }}</a
                    >
                </li>
            </ul>

            <div class="tab-content">
                <div
                    class="tab-pane fade show"
                    [class.active]="activeFragment === projectStatus.draft"
                    id="draft"
                    role="tabpanel"
                    aria-labelledby="draft-tab"
                >
                    <app-project-list-tab [status]="projectStatus.draft"></app-project-list-tab>
                </div>

                <!-- <div class="tab-pane fade show" [class.active]="activeFragment === projectStatus.unpublished" id="unpublished" role="tabpanel" aria-labelledby="unpublished-tab">
                    <app-project-list-tab [status]="projectStatus.unpublished"></app-project-list-tab>
                </div> -->

                <div
                    class="tab-pane fade show"
                    [class.active]="activeFragment === projectStatus.published"
                    id="published"
                    role="tabpanel"
                    aria-labelledby="published-tab"
                >
                    <app-project-list-tab [status]="projectStatus.published"></app-project-list-tab>
                </div>

                <div
                    class="tab-pane fade show"
                    [class.active]="activeFragment === projectStatus.finished"
                    id="finished"
                    role="tabpanel"
                    aria-labelledby="finished-tab"
                >
                    <app-project-list-tab [status]="projectStatus.finished"></app-project-list-tab>
                </div>
            </div>
        </div>
    </div>
</div>
