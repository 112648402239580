<div
    *ngIf="status !== AlertStatusEnum.None"
    [ngClass]="{
        'alert-success': status === AlertStatusEnum.Success,
        'alert-danger': status === AlertStatusEnum.Error,
        'alert-info': status === AlertStatusEnum.Info
    }"
    class="alert mt-1"
>
    {{ message }}
</div>
