import { Community } from '../identity/community.model';
import { OrganizationBase } from '../identity/organization-base.model';
import { EventAccessTypeEnum } from './event-access-type.enum.model';
import { EventTypeEnum } from './event-type.enum.model';

export class BaseEventCommand {
    name: string;
    startDate: string;
    endDate: string;
    description: string;
    type: EventTypeEnum;
    accessType: EventAccessTypeEnum;
    eventLink?: string;
    address?: string;
    addressComplement?: string;
    maxAttendeesCount?: number;
    picture: File;
    organizationVisibility: boolean;
    organization: OrganizationBase;
    allCommunitiesVisibility: boolean;
    communities: Community[];
    registrationLink?: string;
}
