<div class="room-navigation">
    <nav class="p-flex-column p-px-2 p-py-4" *ngIf="clubsStore.member$ | async; else loading" style="height: 80%">
        <p-card [style]="{ width: '100%', 'border-top': ' 3px solid var(--primary-color)' }">
            <div class="row">
                <div class="col-12">
                    <div class="club-header">
                        <a [routerLink]="['/clubs']" class="mt-1">
                            <em class="icon icon-arrow-right1 mr-2"></em>
                        </a>
                        <span class="club-name">{{ displayClubTitle }}</span>
                        <app-gear-edit-club
                            *ngIf="clubsStore.isAdminOfDisplayedClub"
                            (onRoomCreation)="roomCreation($event)"
                            (onClubDeleted)="onClubDeleted($event)"
                            [isClubMode]="true"
                            [club]="displayedClub"
                            class="gear-edit-club p-ml-4"
                        ></app-gear-edit-club>
                    </div>
                    <div>
                        <span class="club-several-infos">{{ getClubType() }}</span>
                    </div>
                    <div class="row d-flex">
                        <div class="col-9">
                            <div title="{{ 'clubs.page.common.overview.nbMembers' | translate }}" class="p-d-inline-flex p-mr-3">
                                <em class="icon-user p-mr-1 p-my-auto"></em>
                                <div>{{ getClubNumberOfMember() }}</div>
                            </div>
                            <div title="{{ 'clubs.page.common.overview.nbOpenRoom' | translate }}" class="p-d-inline-flex p-mr-3">
                                <em class="icon-f-open p-mr-1 p-my-auto"></em>
                                <div>{{ getNumberOfOpenRooms() }}</div>
                            </div>
                            <div title="{{ 'clubs.page.common.overview.nbPrivateRoom' | translate }}" class="p-d-inline-flex p-mr-3">
                                <em class="icon-f-mask p-mr-1 p-my-auto"></em>
                                <div>{{ getNumberOfHiddenRooms() }}</div>
                            </div>
                        </div>
                        <div class="col-3 justify-content-end d-flex">
                            <div class="p-mr-3">
                                <i
                                    *ngIf="this.displayedClub?.projectId"
                                    (click)="this.clubService.openProject(displayedClub)"
                                    tooltipPosition="top"
                                    pTooltip="{{ 'clubs.page.explore.goToProjectTooltip' | translate }}"
                                    class="pi pi-directions primary-icon"
                                ></i>
                            </div>
                            <div>
                                <i (click)="toggleMoreInfoModal()" class="icon-info primary-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-card>

        <div *ngIf="displayReloadButton" class="reload-room-discussion-button" (click)="reloadRoom()">
            {{ 'clubs.navigation.reloadRoom' | translate }}
        </div>

        <ul class="p-mt-2">
            <li
                *ngFor="let room of mainRooms"
                class="room-navigation-item-li"
                [ngClass]="room.id === this.clubsStore.displayedRoomId ? 'selected-room' : 'room'"
            >
                <room-navigation-item
                    (onRoomCreation)="roomCreation($event)"
                    (onRoomDeleted)="onClubDeleted($event)"
                    [room]="room"
                    [isSelected]="room.id === this.clubsStore.displayedRoomId"
                    [roomRole]="clubsStore.roleInDisplayedRoom"
                    [clubRole]="clubsStore.roleInDisplayedClub"
                    [isMainRoom]="clubsStore.isMainRoom"
                ></room-navigation-item>
            </li>
        </ul>
    </nav>
</div>

<app-club-more-info-modal [club]="clubsStore.displayedClub"></app-club-more-info-modal>

<ng-template #loading>
    <wudo-spinner></wudo-spinner>
</ng-template>
