import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IStepOption, TourService } from 'ngx-ui-tour-ng-bootstrap';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class TutorialService {
    private steps: IStepOption[] = [];

    public tourEnded$: Subject<void> = new Subject<void>();

    constructor(private readonly tourService: TourService, private readonly translateService: TranslateService) {}

    public startTour(): void {
        this.initializeSteps();

        this.tourService.initialize(this.steps, {
            enableBackdrop: true,
            prevBtnTitle: this.translateService.instant('components.button.buttonPrevious'),
            nextBtnTitle: this.translateService.instant('components.button.buttonNext'),
            endBtnTitle: this.translateService.instant('components.button.finish'),
        });

        this.tourService.end$.subscribe(() => {
            this.tourEnded$.next();
        });

        this.tourService.start();
    }

    private initializeSteps() {
        if (this.steps.length > 0) {
            return;
        }

        this.steps = [
            {
                anchorId: 'welcome',
                stepId: 'welcome',
                title: this.translateService.instant('tutorial.welcome.title'),
                content: this.translateService.instant('tutorial.welcome.content'),
                placement: 'bottom',
            },
            {
                anchorId: 'clubs',
                stepId: 'clubs',
                title: this.translateService.instant('tutorial.clubs.title'),
                content: this.translateService.instant('tutorial.clubs.content'),
                placement: 'right',
            },
            {
                anchorId: 'directory',
                stepId: 'directory',
                title: this.translateService.instant('tutorial.directory.title'),
                content: this.translateService.instant('tutorial.directory.content'),
                placement: 'bottom',
            },
            {
                anchorId: 'projects',
                stepId: 'projects',
                title: this.translateService.instant('tutorial.projects.title'),
                content: this.translateService.instant('tutorial.projects.content'),
                placement: 'bottom',
            },
            {
                anchorId: 'events',
                stepId: 'events',
                title: this.translateService.instant('tutorial.events.title'),
                content: this.translateService.instant('tutorial.events.content'),
                placement: 'bottom',
            },

            {
                anchorId: 'complete-profile',
                stepId: 'complete-profile',
                title: this.translateService.instant('tutorial.complete-profile.title'),
                content: this.translateService.instant('tutorial.complete-profile.content'),
                placement: 'auto',
            },
            {
                anchorId: 'profile',
                stepId: 'profile',
                title: this.translateService.instant('tutorial.profile.title'),
                content: this.translateService.instant('tutorial.profile.content'),
                placement: 'before-top',
            },
        ];
    }
}
