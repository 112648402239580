<div class="login-container full-height" *ngIf="showPage | async; else loading">
    <img id="login-logo" class="space-bottom" alt="WuDo Platform" />
    <form *ngIf="!isConfirmed" [formGroup]="form" (ngSubmit)="onSavePassword()">
        <div class="space-light">
            <p>Pour finaliser l'activation de votre compte, veuillez définir votre mot de passe.</p>
        </div>
        <div class="space-light">
            <p>{{ 'toastMessages.passwordRules' | translate }}</p>
            <p>
                {{
                    'toastMessages.passwordSpecialsCharactersAuthorized'
                        | translate: { authorizedCharacters: specialCharAuthorizedDisplayable }
                }}
            </p>
        </div>
        <div class="form-group">
            <label for="password">Mot de passe</label>
            <input
                type="password"
                formControlName="password"
                class="form-control"
                placeholder="Mot de passe"
                required
            />
            <div
                *ngIf="showError && password.invalid && (password.dirty || password.touched)"
                class="d-block alert alert-danger"
            >
                <div *ngIf="password.errors.required">Mot de passe requis</div>
            </div>
        </div>
        <div class="form-group">
            <label for="confirm_password">Saisir à nouveau votre mot de passe</label>
            <input formControlName="confirmation" type="password" placeholder="Mot de passe" class="form-control" />
        </div>
        <button
            type="submit"
            class="btn btn-primary space-top"
            [disabled]="!isPasswordValid || submitted"
            [ngClass]="{ 'in-progress-button': submitted }"
        >
            Activer votre compte
        </button>
    </form>
    <div *ngIf="isConfirmed" class="space-light">
        <p>Votre compte a bien été activé.</p>
        <a class="btn btn-primary space-top login-link" [routerLink]="['/']" routerLinkActive="active">
            Se connecter
        </a>
    </div>
</div>

<ng-template #loading>
    <wudo-spinner></wudo-spinner>
</ng-template>
