import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(text: string): string {
        if (!text) {
            return '';
        }

        const urlRegex = /(https?:\/\/[^\s]+)/g;

        const linkedText = text.replace(urlRegex, (url) => {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });

        return linkedText;
    }
}
