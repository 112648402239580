<!-- <app-no-data></app-no-data> -->
<ng-template #noData>{{ 'shared.notFilledIn' | translate }}</ng-template>
<hr />
<!-- Données personnelles -->
<div class="card-body pl-0 pr-0">
    <h4 class="primary-1">{{ 'user.personalInfo.title' | translate }}</h4>

    <div class="row">
        <div class="col-md-4">
            <span class="place-icon mr-2" title="{{ 'directory.pages.userProfile.businessArea' | translate }}">
                <em class="icon icon-activity"></em>
            </span>
            <span *ngIf="!!organization?.businessArea?.name && organization?.businessArea?.name !== notFilledInText; else noData">{{
                organization?.businessArea?.name
            }}</span>
        </div>
        <div class="col-md-4">
            <span class="place-icon mr-2" title="{{ 'filters.shared.locationFilters' | translate }}">
                <em class="icon icon-marker"></em>
            </span>
            <span *ngIf="!!organization?.geographicalArea?.name && organization?.geographicalArea?.name !== notFilledInText; else noData">{{
                organization?.geographicalArea?.name
            }}</span>
        </div>
        <div class="col-md-4" [routerLink]="['/organizations/', user?.organization?.id]">
            <span class="place-icon mr-2" [title]="'user.personalInfo.organizationTitle' | translate">
                <em class="icon icon-organization"></em>
            </span>
            <span class="text-link">{{ user?.organization?.name }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="d-flex">
                <span class="place-icon mr-2" title="{{ 'user.personalInfo.communities' | translate }}">
                    <em class="icon icon-community"></em>
                </span>
                <div class="community-list-container">
                    <app-user-communities [communities]="user.communities"></app-user-communities>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <!-- TODO LES COLLABORATEURS DE L'ORGANISATION!! -->
        </div>
    </div>

    <hr class="mt-3" />

    <div class="card-body pl-0 pr-0" [formGroup]="userForm">
        <h4 class="primary-1">{{ 'user.personalInfo.description' | translate }}</h4>
        <div class="form-group mt-2">
            <textarea
                rows="3"
                class="form-control"
                id="user_description"
                aria-describedby="userDescription"
                placeholder="{{ 'user.personalInfo.descriptionPlaceholder' | translate }}"
                formControlName="description"
            ></textarea>
            <div class="text-right mt-1">
                <small class="text-muted"> {{ userForm.get('description').value?.length || 0 }} / 2600 </small>
            </div>
        </div>
        <div
            *ngIf="userForm.controls.description.invalid && (userForm.controls.description.dirty || userForm.controls.description.touched)"
            class="alert alert-danger"
        >
            <div *ngIf="userForm.controls.description.errors.maxlength">
                {{ 'user.personalInfo.descriptionLength' | translate }}
            </div>
        </div>
    </div>

    <hr />

    <!-- Edition -->
    <div class="card-body pl-0 pr-0" [formGroup]="userForm">
        <h4 class="primary-1">{{ 'user.personalInfo.contactDetails' | translate }}</h4>
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="firstname_user">{{ 'user.personalInfo.firstname' | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="firstname_user"
                        aria-describedby="userFirstname"
                        placeholder="Prénom de l'utilisateur"
                        [value]="user.firstName ? user.firstName : loadingText"
                        disabled
                    />
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="name_user">{{ 'user.personalInfo.lastname' | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="name_user"
                        aria-describedby="userName"
                        placeholder="Nom de l'utilisateur"
                        [value]="user.lastName ? user.lastName : loadingText"
                        disabled
                    />
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="email_user">{{ 'user.personalInfo.email' | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        id="email_user"
                        aria-describedby="userEmail"
                        placeholder="Email"
                        [value]="user?.email ? user.email : loadingText"
                        disabled
                    />
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="fonction_user">{{ 'user.personalInfo.position' | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        formControlName="jobTitle"
                        id="jobTitle"
                        aria-describedby="userFonction"
                        placeholder="{{ 'user.personalInfo.position' | translate }}"
                        maxlength="150"
                        [attr.disabled]="fieldDisablerHelper.fieldIsDisabled(FieldType.user, 'jobTitle')"
                    />
                    <div
                        *ngIf="userForm.controls.jobTitle.invalid && (userForm.controls.jobTitle.dirty || userForm.controls.jobTitle.touched)"
                        class="alert alert-danger"
                    >
                        <div *ngIf="userForm.controls.jobTitle.errors.maxlength">
                            {{ 'user.personalInfo.positionLength' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="phone_user">{{ 'user.personalInfo.officePhone.title' | translate }}</label>
                    <input
                        type="text"
                        class="form-control"
                        formControlName="officePhone"
                        id="officePhone"
                        aria-describedby="userTel"
                        placeholder="{{ 'user.personalInfo.phonePlaceholder' | translate }}"
                        maxlength="21"
                        [attr.disabled]="fieldDisablerHelper.fieldIsDisabled(FieldType.user, 'officePhone')"
                    />
                    <div
                        *ngIf="
                            userForm.controls.officePhone.invalid && (userForm.controls.officePhone.dirty || userForm.controls.officePhone.touched)
                        "
                        class="alert alert-danger"
                    >
                        <div *ngIf="userForm.controls.officePhone.errors.pattern">
                            {{ 'user.personalInfo.officePhone.formatError' | translate }}
                        </div>
                        <div *ngIf="userForm.controls.officePhone.errors.maxlength">
                            {{ 'user.personalInfo.officePhone.length' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="mobile_user">{{ 'user.personalInfo.mobilePhone.title' | translate }}</label>
                    <input
                        type="text"
                        formControlName="mobilePhone"
                        class="form-control"
                        id="mobilePhone"
                        aria-describedby="userTel"
                        placeholder="{{ 'user.personalInfo.phonePlaceholder' | translate }}"
                        maxlength="21"
                        [attr.disabled]="fieldDisablerHelper.fieldIsDisabled(FieldType.user, 'mobilePhone')"
                    />
                    <div
                        *ngIf="
                            userForm.controls.mobilePhone.invalid && (userForm.controls.mobilePhone.dirty || userForm.controls.mobilePhone.touched)
                        "
                        class="alert alert-danger"
                    >
                        <div *ngIf="userForm.controls.mobilePhone.errors.pattern">
                            {{ 'user.personalInfo.mobilePhone.formatError' | translate }}
                        </div>
                        <div *ngIf="userForm.controls.mobilePhone.errors.maxlength">
                            {{ 'user.personalInfo.mobilePhone.length' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="geographicalArea">{{ 'user.personalInfo.geographicSector' | translate }}</label>
                    <select
                        class="form-control"
                        name="geographicalAreaId"
                        id="geographicalArea"
                        formControlName="geographicalAreaId"
                        required
                        [attr.disabled]="fieldDisablerHelper.fieldIsDisabled(FieldType.user, 'geographicalAreaId')"
                    >
                        <option *ngFor="let geographicalArea of geographicalAreas" [value]="geographicalArea.id">
                            {{ geographicalArea.name }} ({{ geographicalArea.id }})
                        </option>
                    </select>
                    <div
                        *ngIf="
                            userForm.controls.geographicalAreaId.invalid &&
                            (userForm.controls.geographicalAreaId.dirty || userForm.controls.geographicalAreaId.touched)
                        "
                        class="alert alert-danger"
                    >
                        <div *ngIf="userForm.controls.geographicalAreaId.errors.required">
                            {{ 'user.personalInfo.geographicSectorRequired' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <app-country-autocomplete
                    formControlName="country"
                    [isRequired]="true"
                    (countrySelected)="onCountrySelected($event)"
                    [attr.disabled]="fieldDisablerHelper.fieldIsDisabled(FieldType.user, 'country')"
                ></app-country-autocomplete>
                <div
                    *ngIf="userForm.controls.country.invalid && (userForm.controls.country.dirty || userForm.controls.country.touched)"
                    class="alert alert-danger"
                >
                    <div *ngIf="userForm.controls.country.errors?.maxlength">
                        {{ 'user.personalInfo.country.length' | translate }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <app-city-autocomplete
                    formControlName="city"
                    [isRequired]="true"
                    [selectedCountry]="selectedCountrySuggestion"
                    (citySelected)="onCitySelected($event)"
                >
                </app-city-autocomplete>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="linkedin_user">{{ 'user.personalInfo.linkedIn' | translate }}</label>
                    <input
                        type="text"
                        formControlName="linkedIn"
                        class="form-control"
                        id="linkedin_user"
                        aria-describedby="userLinkedIn"
                        placeholder="{{ 'user.personalInfo.linkedIn' | translate }}"
                        maxlength="100"
                        [attr.disabled]="fieldDisablerHelper.fieldIsDisabled(FieldType.user, 'linkedIn')"
                    />
                    <div *ngIf="userForm.controls.linkedIn.invalid" class="alert alert-danger">
                        <div *ngIf="userForm.controls.linkedIn.errors.maxlength">
                            {{ 'user.personalInfo.linkedInLength' | translate }}
                        </div>
                        <div *ngIf="userForm.controls.linkedIn.errors.pattern">
                            {{ 'user.personalInfo.linkedInFormat' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
