import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { messages } from '../constants/messages';
import { PasswordHelper } from '../helpers/password.helper';

@Component({
    templateUrl: './activate.component.html',
})
export class BasePasswordDefinitionComponent implements OnInit {
    protected token: string;

    public messages = messages;
    public showError = false;
    public submitted = false;

    public isConfirmed: boolean;

    public showPage: Promise<boolean>;

    public password = new FormControl(null, [Validators.required]);
    public confirmation = new FormControl(null, [Validators.required]);
    public form = new FormGroup({
        password: this.password,
        confirmation: this.confirmation,
    });

    constructor(public router: Router) {}

    ngOnInit() {}

    get isPasswordValid(): boolean {
        return (
            this.password?.value &&
            this.confirmation?.value &&
            PasswordHelper.isPasswordValid(null, this.password.value, this.confirmation.value, false)
        );
    }
}
