import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UpdateRecipientRequest } from '../../models/notifications/commands/update-recipient-request.interface';
import { Recipient } from '../../shared/components/notification-config/recipient.models';

@Injectable({ providedIn: 'root' })
export class RecipientsService {
    private apiRecipientsBaseUrl = `${environment.microservices.notifications.baseUrl}/${environment.microservices.notifications.recipientApi.baseUrl}`;

    constructor(private http: HttpClient) {}

    getRecipient(recipientId: string) {
        return this.http.get<Recipient>(`${environment.microservices.notifications.baseUrl}/api/v1/recipients/${recipientId}`);
    }

    updateRecipient(userId: string, request: UpdateRecipientRequest) {
        return this.http.put<void>(`${this.apiRecipientsBaseUrl}/${userId}`, request);
    }
}
