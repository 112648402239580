import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FiltersItem } from '../../../models/shared/filters-item.model';

@Component({
    selector: 'app-filters-checkbox-item',
    templateUrl: './filters-checkbox-item.component.html',
})
export class FiltersCheckboxItemComponent {
    @Input() title: string;
    @Input() option: FiltersItem;

    @Output() selectionChange = new EventEmitter<FiltersItem>();

    constructor() {}

    onChecked() {
        this.selectionChange.emit(this.option);
    }
}
