import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dateAgo',
    pure: true,
})
export class DateAgoPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(value: Date, _args?: any): any {
        if (!value) return '';

        const isMobile = window.matchMedia('only screen and (max-width: 728px)').matches;

        let formattedDate = '';

        const date = new Date(value);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        if (this.translate.currentLang.indexOf('fr') !== -1) {
            const monthsFr = [
                'janvier',
                'février',
                'mars',
                'avril',
                'mai',
                'juin',
                'juillet',
                'août',
                'septembre',
                'octobre',
                'novembre',
                'décembre',
            ];

            if (isMobile) {
                formattedDate = `${day} ${monthsFr[monthIndex]}, ${hours}:${minutes}`;
            } else {
                formattedDate = `Le ${day} ${monthsFr[monthIndex]} ${year} à ${hours}:${minutes}`;
            }
        } else if (this.translate.currentLang.indexOf('fr') == -1) {
            const monthsEn = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ];
            if (isMobile) {
                formattedDate = `${day} ${monthsEn[monthIndex]}, ${hours}:${minutes}`;
            } else {
                formattedDate = `${monthsEn[monthIndex]} ${day}, ${year} at ${hours}:${minutes}`;
            }
        }
        return formattedDate;
    }
}
