<ng-template #templateUserFilters>
    <app-users-filters
        [areFiltersOpened]="areFiltersOpened"
        (closed)="onFiltersClosed()"
        (ready)="onFiltersReady($event)"
        (selectionChanged)="onFiltersSelectionChanged()"
        #userFilters
    >
    </app-users-filters>
</ng-template>

<ng-template #loading>
    <wudo-spinner></wudo-spinner>
</ng-template>
<ng-template #noResult>
    <app-no-result class="col-12 mt-2"></app-no-result>
</ng-template>

<p-dialog *ngIf="areFiltersOpened" [(visible)]="areFiltersOpened" [modal]="true" [draggable]="false" [resizable]="false" appendTo="body">
    <ng-container *ngTemplateOutlet="templateUserFilters"></ng-container>
</p-dialog>

<div class="p-grid">
    <div [ngClass]="searchInScopeUsers ? '' : 'p-d-none p-d-md-flex p-md-3'" *ngIf="!searchInScopeUsers && !areFiltersOpened">
        <p-scrollPanel [style]="{ height: '450px' }">
            <ng-container *ngTemplateOutlet="templateUserFilters"></ng-container>
        </p-scrollPanel>
    </div>
    <div *ngIf="isInitialized; else loading" [ngClass]="searchInScopeUsers ? 'p-col-12' : 'p-col-12 p-md-9'" style="height: inherit">
        <div class="p-grid" style="height: inherit">
            <div class="p-col-12 p-md-8">
                <button class="btn btn-primary btn-open-filters p-d-sm-inline p-d-md-none mb-2" (click)="openFilters()" *ngIf="!searchInScopeUsers">
                    <em class="icon icon-filter"></em>
                </button>
                <app-custom-search
                    [placeholderText]="appCustomSearchPlaceHolder"
                    customSearchName="filterUserByName"
                    userInput="{{ userCustomSearch }}"
                    (searchTextChanged)="onUserSearchTextChanged($event)"
                >
                </app-custom-search>

                <div class="p-d-flex p-flex-column">
                    <ng-container *ngIf="searchUsers.length > 0">
                        <div class="p-d-flex p-pt-2 p-jc-between">
                            <button
                                *ngIf="isSelectAllButtonAvailable()"
                                pButton
                                type="button"
                                label="{{ 'filters.selectAll' | translate }}"
                                class="p-button-text p-button-secondary p-button-sm"
                                (click)="selectAll()"
                            ></button>
                        </div>
                        <!-- scrollHeight="flex" -->
                        <!-- scrollHeight="450px" -->
                        <p-virtualScroller
                            [value]="searchUsers"
                            scrollHeight="450px"
                            [itemSize]="75"
                            [lazy]="true"
                            [rows]="virtualScrollerRows"
                            (onLazyLoad)="onScroll($event)"
                        >
                            <ng-template let-searchUser pTemplate="item" let-i="index">
                                <div class="p-d-flex p-mb-2" [class.card-disabled]="!searchUser.active">
                                    <p-avatar
                                        image="{{ searchUser.pictureAccessLink }}"
                                        size="xlarge"
                                        label="{{ searchUser.pictureAccessLink ? '' : userHelper.initials(searchUser) }}"
                                        shape="circle"
                                        styleClass="p-mr-2"
                                        [style]="{
                                            'background-color': userHelper.getColorFromString(
                                                searchUser.firstName + ' ' + searchUser.lastName,
                                                'lighten'
                                            ),
                                            color: userHelper.getColorFromString(searchUser.firstName + ' ' + searchUser.lastName, 'darken')
                                        }"
                                    >
                                    </p-avatar>

                                    <div class="p-d-flex p-flex-column">
                                        <h5 class="p-mb-0 p-mt-2">
                                            {{ searchUser.firstName }} {{ searchUser.lastName }}

                                            <app-user-status-badge [currentUserStatusType]="searchUser.status"> </app-user-status-badge>
                                        </h5>
                                        <p class="jobTitle">
                                            {{ searchUser.jobTitle }}
                                            <span *ngIf="(searchUser.organization?.name || '').length > 0">
                                                {{ 'filters.shared.userPosition' | translate : { organizationName: searchUser.organization.name } }}
                                            </span>
                                        </p>
                                    </div>

                                    <div class="p-as-center p-mb-3 p-ml-auto">
                                        <input
                                            id="{{ searchUser.userId }}"
                                            type="checkbox"
                                            [checked]="isSelected(searchUser.userId)"
                                            (change)="toogleCheck(searchUser.userId)"
                                        />
                                    </div>
                                </div>
                            </ng-template>
                        </p-virtualScroller>
                    </ng-container>
                    <ng-container *ngIf="isLoaded; else loading"></ng-container>
                </div>
            </div>
            <div class="p-d-none p-d-md-flex p-md-4">
                <p-scrollPanel [style]="{ height: '450px' }" styleClass="custom-scrollbar">
                    <div class="p-grid p-formgrid">
                        <div class="p-col-12">
                            <div class="p-d-flex p-jc-between">
                                <p>
                                    {{
                                        selectedUsers.length
                                            | i18nPlural
                                                : {
                                                      '=0': 'filters.selected.none' | translate,
                                                      '=1': 'filters.selected.one' | translate,
                                                      other: 'filters.selected.x' | translate
                                                  }
                                    }}
                                </p>
                                <div style="flex: 1">
                                    <button
                                        pButton
                                        type="button"
                                        label="{{ 'filters.removeAll' | translate }}"
                                        class="p-button-secondary p-button-text float-right"
                                        icon="pi pi-times"
                                        iconPos="right"
                                        (click)="deleteAll()"
                                    ></button>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12">
                            <ul class="p-m-0 p-p-0" *ngIf="selectedUsers?.length > 0">
                                <li class="p-mb-2" *ngFor="let selectedUser of selectedUsers; let i = index">
                                    <div class="p-d-flex p-jc-between" [class.card-disabled]="!selectedUser.active">
                                        <p-avatar
                                            image="{{ selectedUser.pictureAccessLink }}"
                                            size="large"
                                            label="{{ selectedUser.pictureAccessLink ? '' : userHelper.initials(selectedUser) }}"
                                            shape="circle"
                                            styleClass="p-mr-2"
                                            [style]="{
                                                'background-color': userHelper.getColorFromString(
                                                    selectedUser.firstName + ' ' + selectedUser.lastName,
                                                    'lighten'
                                                ),
                                                color: userHelper.getColorFromString(selectedUser.firstName + ' ' + selectedUser.lastName, 'darken')
                                            }"
                                        >
                                        </p-avatar>
                                        <div style="flex: 1">
                                            <div class="p-d-flex p-flex-column">
                                                <h5 class="p-mb-0 p-mt-2">
                                                    {{ userHelper.getFullName(selectedUser, false) }}
                                                    <button class="btn btn-reset btn-sm float-right" (click)="deleteOne(selectedUser.userId)">
                                                        <em class="icon icon-close"></em>
                                                    </button>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </p-scrollPanel>
            </div>
        </div>
    </div>
</div>
