import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

export enum PanelPositions {
    CENTER = 'CENTER',
    RIGHT = 'RIGHT',
}

@Component({
    selector: 'wudo-slide-panel',
    templateUrl: './wudo-slide-panel.component.html',
    styleUrls: ['./wudo-slide-panel.component.scss'],
    animations: [
        trigger('slide', [
            state(PanelPositions.CENTER, style({ transform: 'translateX(0)' })),
            state(PanelPositions.RIGHT, style({ transform: 'translateX(-50%)' })),
            transition('* => *', animate(150)),
        ]),
    ],
})
export class WudoSlidePanelComponent implements OnInit {
    public activePanelPosition = PanelPositions.CENTER;

    public animationEnabled: boolean;

    ngOnInit(): void {
        this.animationEnabled = true;
    }

    public slideToCenter() {
        if(this.activePanelPosition != PanelPositions.CENTER){        
            this.activePanelPosition = PanelPositions.CENTER;
        }
    }

    public slideToRight() {
        if(this.activePanelPosition != PanelPositions.RIGHT){
            this.activePanelPosition = PanelPositions.RIGHT;
        }
    }
}
