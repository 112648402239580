<nav class="navcontainer nav-menu container-fluid-md d-md-none">
    <ul class="navcontainer-nav justify-content-between-md">
        <li class="mainnav-item order-2 order-md-2" tourAnchor="welcome-mobile">
            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="mainnav-item-link">
                <em class="icon-star"></em>
                <span class="link-text">{{ 'navMenu.welcomeTitle' | translate }}</span>
            </a>
        </li>
        <li class="mainnav-item order-3 order-md-3" tourAnchor="clubs-mobile">
            <a routerLink="/clubs" routerLinkActive="active" class="mainnav-item-link" style="position: relative">
                <div style="position: relative; display: inline-block">
                    <em class="icon-users"></em>
                    <p-badge
                        *ngIf="clubActivityService.hasClubActivityNotifications | async"
                        severity="danger"
                        class="club-new-activity-badge"
                    ></p-badge>
                </div>
                <span class="link-text">{{ 'navMenu.clubsTitle' | translate }}</span>
            </a>
        </li>
        <li class="mainnav-item order-4 order-md-4">
            <a routerLink="/projects" routerLinkActive="active" class="mainnav-item-link" tourAnchor="projects-mobile">
                <em class="icon-idea-filled"></em>
                <span class="link-text">
                    {{ 'navMenu.projectsTitle' | translate }}
                </span>
            </a>
        </li>

        <li class="mainnav-item order-5 order-md-5">
            <a routerLink="/events" routerLinkActive="active" class="mainnav-item-link" tourAnchor="events-mobile">
                <em class="icon-calendar"></em>
                <span class="link-text">
                    {{ 'navMenu.eventsTitle' | translate }}
                </span>
            </a>
        </li>
        <li class="mainnav-item order-6" *ngIf="(permissions$ | async)?.canViewContactsDirectory" tourAnchor="directory-mobile">
            <a routerLink="/directory/{{ defaultDirectory }}" routerLinkActive="active" class="mainnav-item-link">
                <em class="icon-annuaire-filled"></em>
                <span class="link-text">{{ 'navMenu.directoryTitle' | translate }}</span>
            </a>
        </li>
    </ul>
</nav>
